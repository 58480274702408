import React, { useState, useMemo } from "react";
import { product } from "../../assets/img";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { sub_menu, sub_menu_overview } from "../../redux/api/DummyJson";
import { CheckBox } from "@mui/icons-material";

const VendorPricingComp = ({ types }) => {
  const [customer, setCustomer] = useState(false);
  const [reseller, setReseller] = useState(false);
  const [decorativeShow, setDecorativeShow] = useState(false);

  const [ISCount, setISCount] = useState("3");
  const [color1, setColor1] = useState("100");
  const [color2, setColor2] = useState("200");
  const [color3, setColor3] = useState("300");
  const [check, setCheck] = useState([]);

  const [marginShow, SetMarginShow] = useState(false);
  const [decorativeMethod, setDecorativeMethod] = useState(
    "mobile Phones, Note Book, Bottels"
  );

  const [show, setShow] = useState(false);
  const [boxShow, setBoxShow] = useState(0);

  const checkBox = (ind) => {
    if (check.includes(ind)) {
      const indtemp = check.indexOf(ind);
      const temp = [...check];
      temp.splice(ind);
      setCheck(temp);
    } else {
      setCheck([...check, ind]);
    }
    console.log(check);
  };
  return (
    <div className="h-auto mt-4">
      <p class="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary mt-5">
        Vendor Pricing
      </p>
      <div className="">
        <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-3">
          Charles Supplier
        </p>
        <div className="overflow-scroll">
          <table className="w-100">
            <tr className="mt-4">
              <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Qty
              </th>
              <div className="my-3">
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2">
                      <input
                        disabled={types == "edit" ? false : true}
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="25"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        disabled={types == "edit" ? false : true}
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="50"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        disabled={types == "edit" ? false : true}
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="100"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        disabled={types == "edit" ? false : true}
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="150"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        disabled={types == "edit" ? false : true}
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="250"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        disabled={types == "edit" ? false : true}
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="500"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        disabled={types == "edit" ? false : true}
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="1000"
                      />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
            <tr>
              <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Net
              </th>
              <div className="mt-2">
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2">
                      <input
                        disabled={types == "edit" ? false : true}
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="number"
                        placeholder="₹90.00"
                        maxlength={3}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        disabled={types == "edit" ? false : true}
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="₹85.00"
                        type="number"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        disabled={types == "edit" ? false : true}
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="₹85.00"
                        type="number"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        disabled={types == "edit" ? false : true}
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="₹75.00"
                        type="number"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        disabled={types == "edit" ? false : true}
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="₹75.00"
                        type="number"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        disabled={types == "edit" ? false : true}
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="₹75.00"
                        type="number"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        disabled={types == "edit" ? false : true}
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="₹75.00"
                        type="number"
                      />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
};

export default VendorPricingComp;
