import React, { useEffect, useRef, useState } from "react";
import { emptyImg, product, profilePic, uploadIcon } from "../../assets/img";
import Carousel from "react-bootstrap/Carousel";
import { productDetailImg } from "../../redux/api/constants";
import { EditAttributes } from "@mui/icons-material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { owl_List, product_image } from "../../redux/api/DummyJson";
import ImageCroper from "../Popup/ImageCroper";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import { useLazyProductImgDeleteQuery } from "../../redux/api/api";
import { toast } from "react-hot-toast";

const RightComp = ({
  colorRef,
  edit,
  colorsData,
  setColorsData,
  selColors,
  setSmallImage,
  setSmallMockImage,
  smallimage,
  smallMockimage,
  productImg,
  setProductImg,
  productImgErr,
  setProductImgErr,
  morkupImg,
  setMorkupImg,
  morkupImgErr,
  setMorkupImgErr,
  colorsImg,
  setColorsImg,
  colorImgErr,
  setColorImgErr,
  viewData,
  deleteImg,
  getViewProduct,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [colorImg, setColorImg] = useState(null);
  const [bigImage, setBigImage] = useState({
    image: "",
    ind: "",
    is_default: "",
    id: "",
  });
  const [mockImage, setMockImage] = useState({
    image: "",
    ind: "",
    is_default: "",
    id: "",
  });
  const [fistShow, setFirstShow] = useState(false);
  const [fistShows, setFirstShows] = useState(false);
  // console.log(vendor);
  const [logo, setLogo] = useState({});
  const [logo1, setLogo1] = useState({});
  const [textShow, setTextShow] = useState(true);
  const [textShows, setTextShows] = useState(true);
  const [indexs, setIndexs] = useState(0);
  // console.log(vendor2);

  const proImgRef = useRef();
  const morkupImgRef = useRef();
  const colorImgRef = useRef();
  const [proIndex, setProIndex] = useState(0);
  const [morkupIndex, setMorkupIndex] = useState(0);
  const [colorIndex, setColorIndex] = useState(0);

  // RTK QUERY
  const [productImgDeleteApi] = useLazyProductImgDeleteQuery();

  const deleteImgFun = (id) => {
    productImgDeleteApi(id)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const onColorImgUpload = (img) => {
    // console.log("img", img);
    let temp = [...colorsData];
    temp[indexs] = {
      ...temp[indexs],
      image: img,
    };
    setColorsData(temp);
    setColorImg(img);
    setColorsImg(img);
  };

  const imageSelector = (item, ind, type, id, is_default) => {
    if (type == "mock") {
      setMockImage({
        image: item,
        ind: ind,
        is_default: is_default,
        id: id,
      });
    } else if (type == "product") {
      setBigImage({
        image: item,
        ind: ind,
        is_default: is_default,
        id: id,
      });
    }
  };

  const removeImg = (ind, type, is_default, id) => {
    // console.log("is_default", is_default);
    if (is_default == 1) {
      toast.error("Selected Image is Set Defaulted");
    } else {
      if (id) {
        deleteImgFun(id);
      }

      if (type == "product") {
        let temp = [...smallimage];

        temp.splice(ind, 1);
        setSmallImage(temp);
        if (temp?.length - 1 === 0) {
          setBigImage({
            image: temp[temp.length - 1]?.image,
            ind: temp.length - 1,
            is_default: temp[temp.length - 1]?.is_default,
            id: temp[temp.length - 1]?.id,
          });
        } else {
          setBigImage({
            image: temp[ind - 1]?.image,
            ind: ind - 1,
            is_default: temp[ind - 1]?.is_default,
            id: temp[ind - 1]?.id,
          });
        }
      } else if (type == "mock") {
        let temps = [...smallMockimage];

        temps.splice(ind, 1);
        setSmallMockImage(temps);
        if (temps?.length - 1 === 0) {
          setMockImage({
            image: temps[temps.length - 1]?.image,
            ind: temps.length - 1,
            is_default: temps[temps.length - 1]?.is_default,
            id: temps[temps.length - 1]?.id,
          });
        } else {
          setMockImage({
            image: temps[ind - 1]?.image,
            ind: ind - 1,
            is_default: temps[ind - 1]?.is_default,
            id: temps[ind - 1]?.id,
          });
        }
      }
    }
  };

  const getView = () => {
    let temp = [...viewData?.productImage];
    let temps = [...viewData?.morkupImage];

    let finder = temp.find((i) => i?.is_default == 1);
    const index = temp.findIndex((item) => item.id === finder?.id);

    let finders = temps.find((i) => i?.is_default == 1);
    const indexs = temps.findIndex((item) => item.id === finders?.id);

    if (finder?.id) {
      setBigImage({
        image: finder?.image_url,
        ind: index,
        is_default: finder?.is_default,
        id: finder?.id,
      });
    } else {
      setBigImage({
        image: temp[temp.length - 1]?.image_url,
        ind: temp.length - 1,
        is_default: temp[temp.length - 1]?.is_default,
        id: temp[temp.length - 1]?.id,
      });
    }

    if (finders?.id) {
      setMockImage({
        image: finders?.image_url,
        ind: indexs,
        is_default: finders?.is_default,
        id: finders?.id,
      });
    } else {
      setMockImage({
        image: temps[temps.length - 1]?.image_url,
        ind: temps.length - 1,
        is_default: temps[temps.length - 1]?.is_default,
        id: temps[temps.length - 1]?.id,
      });
    }

    if (temp?.length > 0) {
      setTextShow(false);
      setFirstShow(true);
    }

    if (temps?.length > 0) {
      setTextShows(false);
      setFirstShows(true);
    }
  };

  // PRODUCT IMAGE
  const handleProductImg = (event, ind) => {
    let temp = [...smallimage];

    if (event?.name) {
      temp[ind] = {
        ...temp[ind],
        image: event,
        is_default: 0,
      };

      setSmallImage(temp);
      setBigImage({
        image: temp[temp.length - 1]?.image,
        ind: temp.length - 1,
        is_default: 0,
        id: "",
      });
      setProductImg(event);
      setFirstShow(true);
      setTextShow(false);
    }
  };

  // MORKUP IMAGE
  const handleMorkupImg = (event, ind) => {
    let temp = [...smallMockimage];

    if (event?.name) {
      temp[ind] = {
        ...temp[ind],
        image: event,
        is_default: 0,
      };

      setSmallMockImage(temp);
      setMockImage({
        image: temp[temp.length - 1]?.image,
        ind: temp.length - 1,
        is_default: 0,
        id: "",
      });
      setMorkupImg(event);
      setFirstShows(true);
      setTextShows(false);
    }
  };

  // COLOR IMAGE
  const handleColorImg = (event, ind) => {
    let temp = [...colorsData];

    if (event?.name) {
      temp[ind] = {
        ...temp[ind],
        image: event,
      };

      setColorsData(temp);
    }
  };

  const isDeFaultChanger = (item, type) => {
    if (type == "mock") {
      let temp = [...smallMockimage];
      let final = [];

      for (let i = 0; i < temp.length; i++) {
        if (item?.ind == i) {
          let obj = (temp[i] = {
            ...temp[i],
            is_default: 1,
          });
          final.push(obj);
        } else {
          let obj = (temp[i] = {
            ...temp[i],
            is_default: 0,
          });
          final.push(obj);
        }

        setMockImage({ ...mockImage, is_default: 1 });

        setSmallMockImage(final);
      }
    } else {
      let temp = [...smallimage];
      let final = [];

      for (let i = 0; i < temp.length; i++) {
        if (item?.ind == i) {
          let obj = (temp[i] = {
            ...temp[i],
            is_default: 1,
          });
          final.push(obj);
        } else {
          let obj = (temp[i] = {
            ...temp[i],
            is_default: 0,
          });
          final.push(obj);
        }

        setBigImage({ ...bigImage, is_default: 1 });

        setSmallImage(final);
      }
    }

    toast.success("Default Image Set");
  };

  useEffect(() => {
    if (location?.state?.data?.id && viewData?.id) {
      getView();
    }
  }, [viewData]);

  // COLOR IMAGE
  const toDataURL = async (url) => {
    const blob = await fetch(url).then((res) => res.blob());
    return URL.createObjectURL(blob);
  };
  const downloadImage = async (url) => {
    const a = document.createElement("a");
    a.href = await toDataURL(url);
    a.download = "myImage.png";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // console.log("bigImage", bigImage);
  // console.log("mockImage", mockImage);

  return (
    <>
      <div className="d-flex gap-3 ac-jc w-100 res-img">
        <div>
          <p class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
            Product Image (185 x 205)
          </p>
          <div>
            <div className="">
              <div
                className="position-relative"
                onClick={() => {
                  if (!fistShow) {
                    setProIndex(0);
                    proImgRef?.current?.click();
                  }
                }}
              >
                <img
                  className={"img_up_cont"}
                  src={
                    bigImage?.image?.name
                      ? URL.createObjectURL(bigImage?.image)
                      : bigImage?.image
                      ? bigImage?.image
                      : uploadIcon
                  }
                />
                {!textShow && (
                  <div>
                    {smallimage?.length > 1 && !edit ? (
                      <ul
                        className={`${"image-hide"}  edit-image image-show d-flex justify-content-between border_img`}
                      >
                        <li
                          className="set-default-image"
                          role={"button"}
                          onClick={() => isDeFaultChanger(bigImage, "product")}
                        >
                          Set&nbsp;Default
                        </li>
                        <li
                          className="set-default-image"
                          onClick={() =>
                            removeImg(
                              bigImage?.ind,
                              "product",
                              bigImage?.is_default,
                              bigImage?.id
                            )
                          }
                          role="button"
                        >
                          Remove
                        </li>
                      </ul>
                    ) : bigImage?.is_default == 1 ? (
                      <ul
                        className={`${"image-hide"}  edit-image image-show d-flex justify-content-between border_img`}
                      >
                        <li className="set-default-image" role={"button"}>
                          Default Image
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
              <div className="d-flex ac-jc gap-3 w-100">
                {fistShow && (
                  <div
                    class=" mt-3 gap-3 d-flex ac-jc pb-4 ps-5"
                    style={{ width: "300px", overflow: "scroll" }}
                  >
                    {smallimage?.map((item, ind) => {
                      return (
                        <div className="gap-3 d-flex ac-j w-100">
                          <div
                            class="add_img d-flex ac-jc"
                            onClick={() =>
                              imageSelector(
                                item?.image,
                                ind,
                                "product",
                                item?.id,
                                item?.is_default
                              )
                            }
                          >
                            <img
                              src={
                                item?.image?.name
                                  ? URL.createObjectURL(item?.image)
                                  : item?.image
                              }
                              class="cp add_img object-fit-fill"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {smallimage?.length > 0 && !edit && (
                  <label
                    className={`add_img d-flex ac-jc cp f3 primary fs-3 ${
                      smallimage?.length == 0 && "mt-3"
                    }`}
                    onClick={() => {
                      setProIndex(smallimage?.length);
                      proImgRef?.current?.click();
                    }}
                  >
                    {" "}
                    +
                  </label>
                )}
              </div>
            </div>
          </div>
          {productImg?.length == 0 && productImgErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Upload Product Image</p>
            </div>
          )}
        </div>

        <div>
          <p class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
            Mockup Image
          </p>

          <div>
            {" "}
            <div className="">
              <div
                className="position-relative"
                onClick={() => {
                  if (!fistShows) {
                    setMorkupIndex(0);
                    morkupImgRef?.current?.click();
                  }
                }}
              >
                <img
                  className={"img_up_cont"}
                  src={
                    mockImage?.image?.name
                      ? URL.createObjectURL(mockImage?.image)
                      : mockImage?.image
                      ? mockImage?.image
                      : uploadIcon
                  }
                />
                {!textShows && (
                  <div>
                    {smallMockimage?.length > 1 && !edit ? (
                      <ul
                        className={`${"image-hide"}  edit-image image-show d-flex justify-content-between border_img`}
                      >
                        <li
                          className="set-default-image"
                          role={"button"}
                          onClick={() => isDeFaultChanger(mockImage, "mock")}
                        >
                          Set&nbsp;Default
                        </li>

                        <li
                          className="set-default-image"
                          onClick={() =>
                            removeImg(
                              mockImage?.ind,
                              "mock",
                              mockImage?.is_default,
                              mockImage?.id
                            )
                          }
                        >
                          Remove
                        </li>
                      </ul>
                    ) : mockImage?.is_default == 1 ? (
                      <ul
                        className={`${"image-hide"}  edit-image image-show d-flex justify-content-between border_img`}
                      >
                        {" "}
                        <li className="set-default-image" role={"button"}>
                          Default Image
                        </li>
                      </ul>
                    ) : null}
                  </div>
                )}
              </div>
              <div className="d-flex ac-jc gap-3 w-100">
                {fistShows && (
                  <div
                    class=" mt-3 gap-3 d-flex ac-jc pb-4 ps-5"
                    style={{ width: "300px", overflow: "scroll" }}
                  >
                    {smallMockimage?.map((item, ind) => {
                      return (
                        <div className="gap-3 d-flex ac-j w-100">
                          <div
                            class="add_img d-flex ac-jc"
                            onClick={() =>
                              imageSelector(
                                item?.image,
                                ind,
                                "mock",
                                item?.id,
                                item?.is_default
                              )
                            }
                          >
                            {" "}
                            <img
                              src={
                                item?.image?.name
                                  ? URL.createObjectURL(item?.image)
                                  : item?.image
                              }
                              class="cp add_img object-fit-fill"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {smallMockimage?.length > 0 && !edit && (
                  <label
                    className={`add_img d-flex ac-jc cp f3 primary fs-3 ${
                      smallMockimage?.length == 0 && "mt-3"
                    }`}
                    onClick={() => {
                      setMorkupIndex(smallMockimage?.length);
                      morkupImgRef?.current?.click();
                    }}
                  >
                    +
                  </label>
                )}
              </div>
            </div>
          </div>
          {morkupImg?.length == 0 && morkupImgErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Upload Mockup Image</p>
            </div>
          )}
        </div>

        {colorsData?.length > 0 && (
          <div>
            <p class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
              Color Image (312 x 312)
            </p>
            <div
              className="d-flex gap-3 mt-3 ac-jc flex-wrap"
              style={{ width: "350px" }}
              ref={colorRef}
            >
              {colorsData?.map((item, ind) => {
                return (
                  <div className="d-flex flex-column ac-jc">
                    {item?.image ? (
                      <div className="position-relative for_hover">
                        <img
                          alt="icon"
                          src={
                            item?.image?.name
                              ? URL.createObjectURL(item?.image)
                              : item?.image
                          }
                          className="add_img object-fit-fill"
                          onClick={() => {
                            if (!edit) {
                              setColorIndex(ind);
                              colorImgRef.current.click();
                            }
                          }}
                        />
                        <div className="d-flex w-100 justify-content-between position-absolute in_hover text-white">
                          {edit && (
                            <p
                              className="text-white"
                              role={"button"}
                              onClick={() => downloadImage(item?.image)}
                            >
                              {" "}
                              <FileDownloadOutlinedIcon
                                style={{ width: "70%" }}
                              />
                            </p>
                          )}
                          {/* <ClearOutlinedIcon /> */}
                        </div>
                      </div>
                    ) : (
                      <label
                        className="add_img d-flex ac-jc cp f3 primary fs-3"
                        onClick={() => {
                          setColorIndex(ind);
                          colorImgRef.current.click();
                        }}
                      >
                        +
                      </label>
                    )}
                    <p className="f3" style={{ color: item?.hex_code }}>
                      {item?.name}
                    </p>
                    {item?.image?.length == 0 && colorImgErr && (
                      <div className="d-flex gap-1 mt-2">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">Upload Color Image</p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>

      <input
        type="file"
        accept=".pdf,.doc,.jpeg,.docx,.png,.jpg"
        onChange={(e) => handleProductImg(e.target.files[0], proIndex)}
        className="d-none"
        ref={proImgRef}
      />

      <input
        type="file"
        accept=".pdf,.doc,.jpeg,.docx,.png,.jpg"
        onChange={(e) => handleMorkupImg(e.target.files[0], morkupIndex)}
        className="d-none"
        ref={morkupImgRef}
      />

      <input
        type="file"
        accept=".pdf,.doc,.jpeg,.docx,.png,.jpg"
        onChange={(e) => handleColorImg(e.target.files[0], colorIndex)}
        className="d-none"
        ref={colorImgRef}
      />
    </>
  );
};

export default RightComp;
