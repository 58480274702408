import React, { useState } from "react";
import { Search } from "@mui/icons-material";
import CachedIcon from "@mui/icons-material/Cached";
import {
  animatedImg,
  noLogo,
  searchIcon,
  toggleOff,
  toggleOn,
} from "../../assets/img";
import { useLocation, useNavigate } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ProductListComp = ({
  loc,
  place,
  products,
  listCat,
  getMapList,
  setMapPage,
  mapCat,
  mapStatus,
  mapPageRow,
  mapSearch,
  mapPagination,
  listMap,
}) => {
  // const [productDetail, setProductDetail] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  // console.log("location", location?.state);
  // console.log(loc, place, "lshfidjbghj");
  return (
    <>
      <div className="mt-5 banner-left">
        <div className="d-flex ac-jb flex-column flex-md-row">
          <div className="d-flex gap-3">
            <select
              className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3"
              onChange={(e) =>
                getMapList(mapSearch, mapPageRow, mapStatus, e.target.value)
              }
              value={mapCat}
            >
              <option value={""}>Select Categories</option>
              {listCat?.map((item, ind) => {
                return (
                  <option key={ind} value={item?.id}>
                    {item?.name}
                  </option>
                );
              })}
            </select>
            <select
              className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3"
              onChange={(e) =>
                getMapList(mapSearch, mapPageRow, e.target.value, mapCat)
              }
              value={mapStatus}
            >
              <option value={""}>Select Status</option>
              <option value={1}>Request</option>
              <option value={2}>Inprocess</option>
              <option value={5}>Rejected</option>
            </select>
          </div>
          <div className="d-flex ac-jb flex-column flex-md-row gap-3 justify-content-md-end">
            <div className="d-flex search_new_design mx-2">
              <img src={searchIcon} className="searchiConImg" />
              <input
                className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 w-100 p-1"
                placeholder="Search"
                onChange={(e) =>
                  getMapList(e.target.value, mapPageRow, mapStatus, mapCat)
                }
                value={mapSearch}
              />
            </div>
            <div className="d-flex ac-jc gap-3">
              <button
                className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
                onClick={(e) => getMapList("", "", "", "")}
              >
                <CachedIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select
            className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary"
            onChange={(e) =>
              getMapList(mapSearch, e.target.value, mapStatus, mapCat)
            }
            value={mapPageRow}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont">
            Total Count : {mapPagination?.total}
          </p>
        </div>
      </div>
      <div className="w-100 d-flex mt-5 flex-wrap">
        {listMap?.map((item, ind) => {
          return (
            <div
              className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
              onClick={() =>
                navigate("/map-product-detail", {
                  state: {
                    data: location?.state?.data,
                    item: item,
                    type: "map",
                  },
                })
              }
              key={ind}
            >
              <div className="prod-list-box">
                <div className="cust-btn d-flex ac-jc rounded-3">
                  <img
                    src={
                      item?.defaultImage
                        ? item?.defaultImage
                        : item?.productImage?.[0]?.image_url
                        ? item?.productImage?.[0]?.image_url
                        : noLogo
                    }
                    alt=""
                    className="custom-w-h rounded-3"
                  />
                </div>
                <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                  {item?.name}
                </p>
                <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
                  {item?.primaryCategory?.name}
                </p>
                <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
                  {item?.sku_code}
                </p>
                {/* <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 mt-2">
              Status
              <span>
                <img src={toggleOff} className="toggleOnDes" alt="icon" />
              </span>
            </p> */}
                <div className="d-flex mb-4">
                  <p className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 mt-2 parag mb-0 Regected-text d-flex ac-jb">
                    {item?.vendorRequestDetails?.status}
                    {item?.vendorRequestDetails?.status
                      ?.toLowerCase()
                      .startsWith("reje") && (
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        overlay={(props) => (
                          <Tooltip {...props}>
                            {item?.vendorRequestDetails?.notes}
                          </Tooltip>
                        )}
                        placement="bottom"
                      >
                        <InfoOutlinedIcon
                          className="fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-1 parag"
                          style={{ color: "#06679c" }}
                        />
                      </OverlayTrigger>
                    )}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ProductListComp;
