import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { productTableData } from "../redux/api/DummyJson";
import { editIcon, product } from "../assets/img";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ErrorIcon from "@mui/icons-material/Error";
import {
  useCategoryProductViewNewMutation,
  useLazyCategoriesQuery,
  useLazyNewCategoryViewQuery,
  useLazyPresetPackViewQuery,
} from "../redux/api/api";

const OrderedViewDetails = () => {
  const location = useLocation();
  // console.log(location);
  const OderId = location?.state?.data?.project_request_id;
  const packs = location?.state?.data?.no_of_packs;

  const [data, setData] = useState({});
  const [action, setAction] = useState(true);
  const navigate = useNavigate();
  const dummy_data_ecom = data?.productName;
  const [catogory, setCatogory] = useState("");
  const [dropDown, setDropDown] = useState(null);
  const [searchValueCategory, setSearchValueCategory] = useState("");
  const [list, setList] = useState([]);
  const [lists, setLists] = useState([]);
  const [categoryID, setCategoryID] = useState("");
  const [categoryIDErr, setCategoryIDErr] = useState(false);
  const [category_id, setCategory_id] = useState("");
  const [parentCategory, setParentCategory] = useState("");
  const [productName, setProductName] = useState([]);
  const [prods, setProds] = useState("");
  const [productID, setProductID] = useState("");
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCategoryLists, setSubCategoryLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [productLists, setProductLists] = useState([]);
  const [skuCodeList, setSkuCode] = useState([]);
  const [skuSearch, setSearch] = useState("");
  const [sku, setSku] = useState("");
  // const [productID, setProductID] = useState("");
  const [skuID, setSkuID] = useState("");
  const [productsErr, setProductsErr] = useState(false);
  const [skuErr, setSkuErr] = useState(false);
  // const [productName, setProductName] = useState([]);
  const [subcatogory, setSubcatogory] = useState(false);
  const [catogoryShow2, setCatogoryShow2] = useState(null);
  const [searchValueSubCategory, setSearchSubValueCategory] = useState({});
  const [catogoryName, setCatogoryName] = useState({});
  const [searchValuePro, setSearchValuePro] = useState("");
  const [secondaryCategoryId, setSecondaryCategoryId] = useState("");
  const [productImg, setProductImg] = useState("");
  const [productId, setProductId] = useState("");
  const [skuList, setSkuList] = useState([]);
  const [qty, setQty] = useState(1);
  // const [pack, setPack] = useState(packs);
  const [packPrice, setPackPrice] = useState({
    25: "",
    50: "",
    100: "",
    150: "",
    250: "",
    500: "",
    1000: "",
  });
  const [totalPrice, setTotalPrice] = useState("");
  const [perItem, setPerItem] = useState("");

  //RTK
  const [eventCategoryViewApi] = useLazyPresetPackViewQuery();
  const [listCategory] = useLazyCategoriesQuery();
  const [viewCategoryApi] = useLazyNewCategoryViewQuery();
  const [viewCategory] = useCategoryProductViewNewMutation();
  const getList = () => {
    listCategory()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.categories);
          setLists(res?.categories);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  useEffect(() => {
    getList();
  }, []);

  const getData = () => {
    let local = location.state?.data;
    let temp = {};
    temp.catType = local?.catType;
    temp.price = local?.price;
    temp.productName = local?.productName;
    temp.qty = local?.qty;
    temp.id = location?.state?.ind;

    setData(temp);
  };
  useEffect(() => {
    getData();
  }, [location]);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setData((val) => ({ ...val, [name]: value }));
  };
  const searchSku = (event) => {
    let temp = [...skuCodeList];
    let search = temp?.filter((value) =>
      value?.variant_sku?.toLowerCase()?.includes(event?.toLowerCase())
    );
    if (event?.length > 0) {
      setSkuCode(search);
    } else {
      setSkuCode(skuCodeList);
    }
    setSearch(event);
  };

  const onSelectSku = (event, id, type, item) => {
    // setCodeName(event);
    // setSku((val) => ({ ...val, [ind]: item }));
    setSearch("");
    // setSkuid(id);
    setDropDown(null);
    // getSkuProduct(event);
    setSkuID(id);
  };

  // SEARCH CATEGROY
  const searchCategory = (event) => {
    let temp = [...lists];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setList(search);
    } else {
      setList(lists);
    }

    setSearchValueCategory(event);
  };
  // SELECT CATEGORY
  const onCategorySelct = (item) => {
    setCategoryID(item?.id);
    setCatogory(item?.name);
    setSubcatogory(true);
    getCategoryId(item?.id, "category");
    setList(lists);
    setDropDown(null);
    setSkuCode(skuCodeList);
    // setParentCategory(item?.id);
  };

  // GET PARENT ID FOR SUB CATEGORY LIST
  const getCategoryId = (id, type, indx, index, data) => {
    if (type == "category") {
      setCategory_id(id);
      setParentCategory(id);
      getView(id, "", "", type, "");
    } else {
      // console.log("lll");
      if (index == 0) {
        setCategory_id(id);
        // console.log("kkkkk");
      }
      onSubCatSelect(id, indx, index, data);
    }
    setProductName([]);

    // setProds((val) => ({ ...val, [ind]: {} }));
    setProductID("");
  };
  // SLICE ARRAY
  const onSubCatSelect = (id, ind, index, data) => {
    if (index < data?.length - 1) {
      let temp = [...data];
      let slicer = temp.slice(0, index + 1);
      setSubCategoryList(slicer);

      let temps = [...subCategoryLists];
      let slicers = temps.slice(0, index + 1);
      setSubCategoryLists(slicers);

      getView(id, ind, index, "sub_category", slicer);
    } else {
      getView(id, ind, index, "", data);
    }
  };
  // SUB CATEGORY SEARCH
  const searchSubCatFun = (event, ind) => {
    let temp = [...subCategoryLists];
    let tempX = temp[ind];
    let temps = [...subCategoryList];
    let tempXs = temps[ind];
    let search = tempX?.child_categories?.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    // console.log("search", search);

    tempXs = search;

    let obj = {
      ...subCategoryList[ind],
      child_categories: tempXs,
    };

    let arr = [...subCategoryList];
    arr[ind] = obj;

    // console.log("tempXs", obj, arr);

    if (event?.length > 0) {
      setSubCategoryList(arr);
    } else {
      // console.log("jjj");
      setSubCategoryList(subCategoryLists);
    }

    setSearchSubValueCategory((val) => ({ ...val, [ind]: event }));
  };
  // SELECT CHILD CATEGORY
  const onSubCategorySelect = (subItem, indx, inx) => {
    setSearchSubValueCategory((val) => ({ ...val, [inx]: "" }));
    let temp = [...subCategoryList];
    // console.log("temp[inx]", temp[inx], subCategoryLists[inx]);
    temp[inx] = subCategoryLists[inx];
    // console.log("temp", temp);
    setSubCategoryList(temp);
    setCatogoryShow2(null);
    getCategoryId(subItem?.id, "sub_category", indx, inx, temp);
    setCatogoryName((val) => ({
      ...val,
      [subItem?.category?.id]: subItem?.name,
    }));

    setProductName([]);

    if (inx == 0) {
      setSecondaryCategoryId(subItem?.id);
    }
    // setProds((val) => ({ ...val, [ind]: {} }));
    setProductID("");
  };
  // CHILD CATEGORY VIEW API AND 2D DIMENTION ARRAY CREATE
  const getView = (id, inx, index, type, data) => {
    setLoading(true);
    viewCategoryApi(id)
      .unwrap()
      .then((res) => {
        let child = [];
        res?.child_categories?.map((item, ind) => {
          child.push({
            ...item,
            category: {
              ...res?.category,
            },
          });
        });

        let responce = {
          category: res?.category,
          child_categories: child,
        };

        let temp = [];
        let temps = [];
        if (data?.length > 0) {
          temp = [...data];
          temps = [...data];
        } else if (type !== "category") {
          temp = [...subCategoryList];
          temps = [...subCategoryLists];
        }

        if (type == "subSumit") {
          let temX = responce?.child_categories;

          let obj = {
            ...temp[index],
            child_categories: temX,
          };

          let final = subCategoryList?.filter((i) => i?.category?.id !== id);
          final.splice(index, 0, obj);

          let finals = subCategoryLists?.filter((i) => i?.category?.id !== id);
          finals.splice(index, 0, obj);

          setSubCategoryList(final);
          setSubCategoryLists(finals);

          let productsArr = [];

          finals?.map((item, ind) => {
            item?.child_categories?.map((chi, indx) => {
              chi?.product?.map((pro, inx) => {
                productsArr.push(pro);
              });
            });
          });
          // console.log("finals", finals);
          setProductLists(productsArr);
          setProductList(productsArr);

          setLoading(false);
        } else {
          let finder = temp.indexOf(id);

          if (temp?.includes(responce)) {
            temp.slice(0, 1);
            temp.push(responce);
          } else {
            temp.push(responce);
          }

          if (temps?.includes(responce)) {
            temps.slice(0, 1);
            temps.push(responce);
          } else {
            temps.push(responce);
          }

          setSubCategoryList(temp);
          setSubCategoryLists(temps);

          let productsArr = [];

          temps?.map((item, ind) => {
            item?.child_categories?.map((chi, indx) => {
              chi?.product?.map((pro, inx) => {
                productsArr.push(pro);
              });
            });
          });
          console.log("temps", temps);
          setProductLists(productsArr);
          setProductList(productsArr);
          setLoading(false);
          getPro(id);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
    setProductName([]);

    // setProds((val) => ({ ...val, [ind]: {} }));
    setProductID("");
  };

  const searchPro = (event) => {
    let temp = [...productLists];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setProductList(search);
    } else {
      setProductList(productLists);
    }

    setSearchValuePro(event);
  };

  const getPro = (id) => {
    console.log("id", id);
    if (categoryID && secondaryCategoryId) {
      getProducts(categoryID, secondaryCategoryId, id);
    }
  };
  //PRODUCT
  const getProducts = (primary_category_id, secondary_category_id, id) => {
    let formdata = new FormData();
    formdata.append("primary_category_id", primary_category_id);
    formdata.append("secondary_category_id", secondary_category_id);
    viewCategory({ id: id, payload: formdata })
      .unwrap()
      .then((res) => {
        setProductLists(res?.products);
        setProductList(res?.products);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const onProSelect = (event, id, type, item) => {
    setProductId(id);
    setProds(event);
    setProductImg(item?.defaultImage);
    let name = [];
    item?.productSku?.map((skulist, ind) => {
      name?.push(skulist);
    });
    setSkuList(name);
    setPackPrice({
      25: item?.for_25,
      50: item?.for_50,
      100: item?.for_100,
      150: item?.for_150,
      250: item?.for_250,
      500: item?.for_500,
      1000: item?.for_1000,
    });
  };
  // console.log(packPrice["for_25"], "skuList");

  const handleChangePricre = (e) => {
    let newvalue = e.target.value;
    if (newvalue === "" || (newvalue >= 1 && newvalue <= 10)) {
      setQty(newvalue);
    }
    let coustomPack = +newvalue * +packs;
    if (+coustomPack >= 1 && +coustomPack <= 25) {
      setPerItem(packPrice["25"]);
      setTotalPrice(packPrice["25"] * +coustomPack);
    } else if (+coustomPack > 25 && +coustomPack <= 50) {
      setPerItem(packPrice["50"]);
      setTotalPrice(packPrice["50"] * coustomPack);
    } else if (+coustomPack > 50 && +coustomPack <= 100) {
      setPerItem(packPrice["100"]);
      setTotalPrice(packPrice["100"] * coustomPack);
    } else if (+coustomPack > 100 && +coustomPack <= 150) {
      setPerItem(packPrice["150"]);
      setTotalPrice(packPrice["150"] * coustomPack);
    } else if (+coustomPack > 150 && +coustomPack <= 250) {
      setPerItem(packPrice["250"]);
      setTotalPrice(packPrice["250"] * coustomPack);
    } else if (+coustomPack > 250 && +coustomPack <= 500) {
      setPerItem(packPrice["500"]);
      setTotalPrice(packPrice["500"] * coustomPack);
    } else {
      setPerItem(packPrice["1000"]);
      setTotalPrice(packPrice["1000"] * coustomPack);
    }
    // console.log(coustomPack,perItem,perItem*coustomPack, "coustomPack");
  };
  // console.log(perItem,perItem, "coustomPack");

  return (
    <div className="dashRightView p-5 home_section trans">
      <p
        className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon /> Ordered Product Details
      </p>
      <div className="w-100 searchField mt-5 bg-white">
        <div className="container mx-md-4">
          <div className="d-flex justify-content-end me-4 mt-1">
            {dummy_data_ecom != undefined && (
              <button
                className="cust-btn addbtn  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
                onClick={() => setAction(!action)}
              >
                Edit
              </button>
            )}
          </div>
          <div className="w-100 ac-jc d-flex flex-xxl-row flex-xl-row flex-lg-row flex-column">
            <div className="w-100 d-flex flex-column">
              <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
                <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Request Order ID:
                </p>
                {/* <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                MNS0001
              </p> */}
                <div className="w-md-50 w-100">
                  <input
                    placeholder=""
                    className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={OderId}
                    name="id"
                    onChange={handleChange}
                    disabled
                  />
                </div>
              </div>
              {/* <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
                <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Category:
                </p>
                <div className="w-md-50 w-100">
                  <input
                    placeholder=""
                    className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={
                      dummy_data_ecom != undefined
                        ? data?.catType
                        : "Category Title"
                    }
                    name="catType"
                    onChange={handleChange}
                    disabled={action ? true : false}
                  />
                </div>
              </div> */}
              <>
                <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
                  <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    Category
                  </p>
                  <div className="w-md-50 w-100 position-relative">
                    <input
                      placeholder="Catogory"
                      type="text"
                      value={
                        catogory?.length > 0
                          ? catogory?.toString()
                          : "Select Catogory"
                      }
                      onClick={() => {
                        setDropDown(1);
                      }}
                      className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      disabled={location?.state?.type == "view" ? true : false}
                    />
                    <button
                      className="drop_down cust-btn"
                      onClick={() => {
                        setDropDown(1);
                      }}
                      // disabled={location?.state?.type == "view" ? true : false}
                    >
                      <KeyboardArrowDownIcon />
                    </button>
                    {dropDown == 1 && (
                      <div
                        className="invisible-cont2 z-0"
                        onClick={() => setDropDown(null)}
                      />
                    )}
                    <div
                      className={`${
                        dropDown == 1 && "submenu_1 py-2"
                      } submenu_cont_1 overflow-scroll z-3`}
                    >
                      <div className="d-flex gap-1">
                        <input
                          className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                          placeholder="Search..."
                          onChange={(e) => searchCategory(e.target.value)}
                          value={searchValueCategory}
                        />
                      </div>
                      {list?.map((item, ind) => {
                        return (
                          <button
                            className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                            onClick={() => {
                              onCategorySelct(item);
                            }}
                            key={ind}
                          >
                            <button className="px-2 cust-btn text-start py-1 w-100 ">
                              {item?.name}
                            </button>
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  {categoryID?.length == 0 && categoryIDErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Select Category</p>
                    </div>
                  )}
                </div>
              </>
              {subcatogory && (
                <>
                  {subCategoryList?.map((item, ind) => {
                    return (
                      <div
                        className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center"
                        key={ind}
                      >
                        <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                          {item?.categories
                            ? item?.categories?.name
                            : item?.category?.name}
                        </p>
                        <div className="w-md-50 w-100 position-relative">
                          <input
                            placeholder="Required Field"
                            className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                            value={
                              catogoryName?.[
                                item?.categories
                                  ? item?.categories?.id
                                  : item?.category?.id
                              ]
                            }
                            // onChange={onCatogory2}
                            onClick={() => setCatogoryShow2(ind)}
                            readOnly
                          />
                          <button
                            className="drop_down cust-btn"
                            onClick={() => {
                              setCatogoryShow2(ind);
                              setDropDown(null);
                            }}
                          >
                            <KeyboardArrowDownIcon />
                          </button>
                          {catogoryShow2 == ind && (
                            <div
                              className="invisible-cont2 z-0"
                              onClick={() => setCatogoryShow2(null)}
                            />
                          )}
                          <div
                            className={`${
                              catogoryShow2 == ind && "submenu_1 py-2"
                            } submenu_cont_1 overflow-scroll z-3`}
                          >
                            <div className="d-flex gap-1">
                              <input
                                className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                                placeholder="Search..."
                                onChange={(e) =>
                                  searchSubCatFun(e.target.value, ind)
                                }
                                value={searchValueSubCategory[ind]}
                              />
                            </div>
                            {item?.child_categories?.map((subItem, indx) => {
                              return (
                                <button
                                  className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                                  onClick={() => {
                                    onSubCategorySelect(subItem, indx, ind);
                                  }}
                                  key={ind}
                                >
                                  <button className="px-2 cust-btn text-start py-1 w-100 ">
                                    {subItem?.name}
                                  </button>
                                </button>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
              <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
                <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Product Name
                </p>
                <div className="w-md-50 w-100 position-relative">
                  <input
                    placeholder="Product Name"
                    type="text"
                    value={prods ? prods : "Select Product Name"}
                    onClick={() => {
                      setDropDown(2);
                    }}
                    className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  />
                  <button
                    className="drop_down cust-btn"
                    onClick={() => {
                      setDropDown(2);
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                  {dropDown == 2 && (
                    <div
                      className="invisible-cont2 z-0"
                      onClick={() => {
                        setDropDown(null);
                        setCatogoryShow2(null);
                      }}
                    />
                  )}
                  <div
                    className={`${
                      dropDown == 2 && "submenu_1 py-2"
                    } submenu_cont_1 overflow-scroll z-3`}
                  >
                    <div className="d-flex gap-1">
                      <input
                        className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                        placeholder="Search..."
                        onChange={(e) => searchPro(e.target.value)}
                        value={searchValuePro}
                      />
                    </div>
                    {productList?.map((item, inx) => {
                      return (
                        <button
                          className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                          key={inx}
                        >
                          <button
                            className="px-2 cust-btn text-start py-1 w-100 "
                            onClick={() => {
                              onProSelect(item?.name, item?.id, "", item);
                            }}
                          >
                            {item?.name}
                          </button>
                        </button>
                      );
                    })}
                  </div>
                </div>

                {productID?.length == 0 && productsErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Select Product</p>
                  </div>
                )}
              </div>
              <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
                <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  SKU Code:
                </p>
                <div className="w-md-50 w-100">
                  <select
                    className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    onClick={(e) => setSku(e.target.value)}
                  >
                    <option value={""}>Select SKU</option>
                    {skuList?.length > 0 &&
                      skuList?.map((skuCode, ind) => (
                        <option
                          className="px-2 cust-btn text-start py-1 w-100"
                          value={skuCode?.id}
                        >
                          {skuCode?.variant_sku}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              {/* <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
                <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Sub Category Code:
                </p>
                <div className="w-md-50 w-100">
                  <input
                    placeholder=""
                    className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={
                      dummy_data_ecom != undefined
                        ? data?.catType
                        : "Sub Category Code"
                    }
                    name="catType"
                    onChange={handleChange}
                    disabled={action ? true : false}
                  />
                </div>
              </div> */}
              {/* <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
                <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Product Name:
                </p>
                <div className="w-md-50 w-100">
                  <input
                    placeholder=""
                    className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={
                      dummy_data_ecom != undefined
                        ? data?.productName
                        : "Product Name"
                    }
                    name="productName"
                    onChange={handleChange}
                    disabled={action ? true : false}
                  />
                </div>
              </div> */}

              <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
                <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Quantity:
                </p>
                <div className="w-md-50 w-100">
                  <input
                    type="number"
                    placeholder=""
                    className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={qty}
                    name="qty"
                    onChange={(e) => handleChangePricre(e)}

                    // disabled={action ? true : false}
                  />
                  {qty < 1 ||
                    (qty > 10 && (
                      <div className="d-flex gap-1 mt-2">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">Select Quantity</p>
                      </div>
                    ))}
                </div>
              </div>
              <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
                <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Pack:
                </p>
                <div className="w-md-50 w-100">
                  <input
                    placeholder=""
                    className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={packs}
                    name="qty"
                    // onChange={(e) => handelChangePack(e)}
                    readOnly
                    // disabled={action ? true : false}
                  />
                </div>
              </div>
              <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
                <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Price For Item:
                </p>
                <div className="w-md-50 w-100">
                  <input
                    placeholder=""
                    className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={perItem}
                    name="price"
                    // onChange={handleChange}
                    // disabled={action ? true : false}
                  />
                </div>
              </div>
              {/* <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
                <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Price For pack:
                </p>
                <div className="w-md-50 w-100">
                  <input
                    placeholder=""
                    className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={+qty * +perItem}
                    name="price"
                    // onChange={handleChange}
                    // disabled={action ? true : false}
                  />
                </div>
              </div> */}
              <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
                <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Total Price:
                </p>
                <div className="w-md-50 w-100">
                  <input
                    placeholder=""
                    className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={totalPrice}
                    name="price"
                    onChange={handleChange}
                    // disabled={action ? true : false}
                  />
                </div>
              </div>

              {/* <div className="w-100 d-flex ac-js mt-3">
              <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Order Status:
              </p>
              <select
                placeholder=""
                className="black w-15 editBtnSelect f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 rounded-3"
                name=""
                id=""
              >
                <option>Paid</option>
                <option>Operation</option>
                <option>Shipping Ready</option>
                <option>Shipment</option>
                <option>Deliver</option>
              </select>
            </div> */}
            </div>
            <div className="w-md-50 w-100">
              <img src={productImg ? productImg : product} className="w-100" />
            </div>
             
          </div>

          <div className="w-90 ac-jb flex-md-row flex-column d-flex mt-5">
            <button
              onClick={() => {
                navigate(-1);
              }}
              className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
            >
              Back
            </button>
            {dummy_data_ecom != undefined && (
              <button
                className=" cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Submit Details
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderedViewDetails;
