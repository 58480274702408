import React, { useEffect, useRef, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Col } from "react-bootstrap";
import { editIcon, product, viewIcon } from "../../assets/img";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { saveImage } from "../../redux/slice/imageSave";
import { CloseOutlined } from "@mui/icons-material";
import { color } from "@mui/system";
import Modal from "react-bootstrap/Modal";

const AddFilesPopupCopy = ({
  toggleShowPopup,
  popupType,
  setImageUploadData,
  imageUploadData,
}) => {
  const dispatch = useDispatch();
  const [btn, setBtn] = useState(0);
  const [tab, setTab] = useState(0);
  const [upload, setUpload] = useState(false);
  const [progress_Bar, setProgress_Bar] = useState(false);
  const [image, setImage] = useState();
  const [imageUrl, setImageUrl] = useState(false);
  const [btnFiles, setBtnFiles] = useState(0);
  const [show, setShow] = useState(false);

  // PROGRESS BAR
  const [countOfProgess, setCountOfProgess] = useState(0);

  useEffect(() => {
    if (progress_Bar) {
      const timer = setInterval(() => {
        setCountOfProgess((oldProgress) => {
          if (100 == oldProgress) {
            setProgress_Bar(false);
            setImageUrl(true);
            return 0;
          }
          return Math.min(oldProgress + Math.random() * 10, 100);
        });
      }, 499);

      return () => {
        clearInterval(timer);
      };
    }
  }, [progress_Bar]);

  const handleImage = (file) => {
    setImage(file);
    if (file?.name) {
      dispatch(saveImage(URL.createObjectURL(file)));
    } else {
      dispatch(saveImage(file));
    }
  };

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <div>
            <span className="d-flex justify-content-end">
              <button onClick={() => setShow(false)} className="cust-btn">
                <CloseOutlined class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium primary f5 fs-2 css-i4bv87-MuiSvgIcon-root" />
              </button>
            </span>
            <div>
              <div className="w-100">
                <p className=" f3 fs-15 fs-xs-11 me-0 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2">
                  Type
                </p>
                <select className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2">
                  <option hidden>Select</option>
                  <option value={1}>Product Deck</option>
                  <option value={2}>Accounting Documents</option>
                  <option value={3}>Customer PO</option>
                  <option value={4}>Material Information</option>
                  <option value={5}>Artwork</option>
                  <option value={6}>Notes and Attachment</option>
                  <option value={7}>Others</option>
                </select>
              </div>
              <div className="w-100">
                <p className=" f3 fs-15 fs-xs-11 me-0 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2">
                  Image
                </p>
                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                  type={"file"}
                />
              </div>
              <div className="d-flex mt-3 justify-content-center m-2">
                <button
                  onClick={() => setShow(false)}
                  className="mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {show == false && (
        <div className="add-files-overiew-popup">
          <div style={{ overflow: "auto" }}>
            <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
              Select File
              <span>
                <button onClick={() => toggleShowPopup()} className="cust-btn">
                  {/* <HighlightOffIcon class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium primary f5 fs-2 css-i4bv87-MuiSvgIcon-root" /> */}
                  <CloseOutlined class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium primary f5 fs-2 css-i4bv87-MuiSvgIcon-root" />
                </button>
              </span>
            </p>
            <div class="w-100 my-3 d-flex ac-jb w-100 flex-column flex-md-row">
              <div className="d-flex ac-jb w-100 align-items-center justify-content-sm-start">
                <button
                  onClick={() => setBtn(0)}
                  class={
                    btn == 0
                      ? "border_click_cust bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 "
                      : "cust-btn  bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3"
                  }
                >
                  Project Files
                </button>
                <button
                  onClick={() => setBtn(1)}
                  class={
                    btn == 1
                      ? "border_click_cust bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 "
                      : "cust-btn  bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3"
                  }
                >
                  Client Files
                </button>
              </div>
              {btn == 1 && (
                <select className="editBtn mt-4 mt-md-0 w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust">
                  <option>All Files</option>
                </select>
              )}
            </div>
            <div className="d-flex">
              {/* <Col lg={8} md={8}>
              <div className="d-flex over_files_pop">
                <button
                  className={btn == 0 && "btn_overview_line"}
                  onClick={() => setBtn(0)}
                >
                  Project Files
                </button>
                <button
                  className={btn == 1 && "btn_overview_line"}
                  onClick={() => setBtn(1)}
                >
                  Client Files
                </button>
              </div>
            </Col> */}
              <div>
                {/* {btn == 1 && (
                <select className="w-40 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-1 mt-2">
                  <option>All Files</option>
                </select>
              )} */}

                {popupType == "addClient" && (
                  <button
                    onClick={() => setUpload(!upload)}
                    className="w-100 cust-btn addbtn ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
                  >
                    Upload
                  </button>
                )}
              </div>
            </div>
            {/* <div className="layer_scroll"> */}
            {btn == 0 && (
              <div className="d-flex mt-1 justify-content-start m-2">
                <button
                  onClick={() => setShow(true)}
                  className="mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                >
                  + Add
                </button>
              </div>
            )}
            {btn == 0 && (
              <>
                <div className="d-flex ae-jb w-100">
                  <button
                    className={`${
                      btnFiles == 0 ? "border_click_cust" : "cust-btn "
                    } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                    onClick={() => setBtnFiles(0)}
                  >
                    Product Deck
                  </button>
                  <button
                    className={`${
                      btnFiles == 1 ? "border_click_cust" : "cust-btn "
                    } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                    onClick={() => setBtnFiles(1)}
                  >
                    Accounting Documents
                  </button>
                  <button
                    className={`${
                      btnFiles == 2 ? "border_click_cust" : "cust-btn "
                    } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                    onClick={() => setBtnFiles(2)}
                  >
                    Customer PO
                  </button>
                  <button
                    className={`${
                      btnFiles == 3 ? "border_click_cust" : "cust-btn "
                    } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                    onClick={() => setBtnFiles(3)}
                  >
                    Material Information
                  </button>
                  <button
                    className={`${
                      btnFiles == 4 ? "border_click_cust" : "cust-btn "
                    } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                    onClick={() => setBtnFiles(4)}
                  >
                    Artwork
                  </button>
                  <button
                    className={`${
                      btnFiles == 5 ? "border_click_cust" : "cust-btn "
                    } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                    onClick={() => setBtnFiles(5)}
                  >
                    Notes and Attachment
                  </button>
                  <button
                    className={`${
                      btnFiles == 6 ? "border_click_cust" : "cust-btn "
                    } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                    onClick={() => setBtnFiles(6)}
                  >
                    Others
                  </button>
                </div>
                {btnFiles >= 0 && btnFiles <= 5 && (
                  <div className="d-flex flex-wrap layer_scroll">
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map((item, ind) => {
                      return (
                        <div className="files_pop_pro me-3" key={ind}>
                          <button
                            className="over_img bg-transparent mx-3"
                            // onClick={() => setImageUploadData(!imageUploadData)}
                          >
                            <img src={product} className="w-100" />
                            <div className="files_btnn d-flex justify-content-between">
                              <button
                                className=""
                                onClick={() => setShow(true)}
                              >
                                Edit
                              </button>
                              <button className="">Delete</button>
                              <button className="px-2 px-md-3 py-0 mx-1">
                                <FileDownloadIcon className="f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 text-light" />
                              </button>
                            </div>
                          </button>
                          <p className="im1 mt-3 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">
                            Jobs1.jpeg
                          </p>
                          <p className="im2 f1 fs-12 fs-xs-11 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                            Updated 14 October 2023
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
                {btnFiles == 6 && (
                  <div className="d-flex layer_scroll">
                    <div className="files_pop_pro me-3">
                      <button
                        className="over_img bg-transparent mx-3"
                        onClick={() => setImageUploadData(!imageUploadData)}
                      >
                        <img src={product} className="w-100" />
                        <div className="files_btnn d-flex justify-content-between">
                          <div className="d-flex">
                            <div className="files_view_icon me-1 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 ">
                              <a>
                                <RemoveRedEyeIcon className="primary" />
                              </a>
                            </div>
                            <div className="files_view_icon me-1 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 ">
                              <a>
                                <DriveFileRenameOutlineIcon className="primary" />
                              </a>
                            </div>
                          </div>
                          <button className="px-2 px-md-3 py-0 mx-1">
                            <FileDownloadIcon className="f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 text-light" />
                          </button>
                        </div>
                      </button>
                      <p className="im1 mt-3 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">
                        Jobs1.jpeg
                      </p>
                      <p className="im2 f1 fs-12 fs-xs-11 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                        Updated 14 October 2023
                      </p>
                    </div>
                    <div className="files_pop_pro me-3">
                      <div className="over_img">
                        <img src={product} className="w-100" />
                        <div className="files_btn d-flex-ac-jc">
                          <button className="px-2 px-md-3 py-0 mx-1">
                            <FileDownloadIcon className="f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 text-light" />
                          </button>
                        </div>
                      </div>
                      <p className="im1 mt-3 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">
                        Jobs1.jpeg
                      </p>
                      <p className="im2 f1 fs-12 fs-xs-11 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                        Updated 14 October 2023
                      </p>
                    </div>
                    <div className="files_pop_pro me-3">
                      <div className="over_img">
                        <img src={product} className="w-100" />
                        <div className="files_btn d-flex-ac-jc">
                          <button className="px-2 px-md-3 py-0 mx-1">
                            <FileDownloadIcon className="f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 text-light" />
                          </button>
                        </div>
                      </div>
                      <p className="im1 mt-3 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">
                        Jobs1.jpeg
                      </p>
                      <p className="im2 f1 fs-12 fs-xs-11 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                        Updated 14 October 2023
                      </p>
                    </div>
                    <div className="files_pop_pro me-3">
                      <div className="over_img">
                        <img src={product} className="w-100" />
                        <div className="files_btn d-flex-ac-jc">
                          <button className="px-2 px-md-3 py-0 mx-1">
                            <FileDownloadIcon className="f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 text-light" />
                          </button>
                        </div>
                      </div>
                      <p className="im1 mt-3 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">
                        Jobs1.jpeg
                      </p>
                      <p className="im2 f1 fs-12 fs-xs-11 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                        Updated 14 October 2023
                      </p>
                    </div>
                    {image && (
                      <div className="files_pop_pro mx-2">
                        <div className="over_img">
                          <img
                            src={
                              image?.name ? URL.createObjectURL(image) : image
                            }
                            className="w-100 object-fit-contain"
                          />
                          <div className="files_btn d-flex-ac-jc">
                            <button className="px-2 px-md-3 py-0 mx-1">
                              <FileDownloadIcon className="f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 text-light" />
                            </button>
                          </div>
                        </div>
                        <p className="im1 mt-3 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">
                          Jobs1.jpeg
                        </p>
                        <p className="im2 f1 fs-12 fs-xs-11 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                          Updated 14 October 2023
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
            {/* </div> */}
            <div className="layer_cont">
              {btn == 1 && (
                <div className="d-flex justify-content-start ms-2 align-items-center gap-4">
                  <div className="files_pop_pro d-flex flex-column ac-jb">
                    <div className="over_img">
                      <img src={product} className="w-100" />
                      <div className="files_btn d-flex-ac-jc">
                        <button className="px-2 px-md-3 py-0 mx-1">
                          <FileDownloadIcon className="f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 text-light" />
                        </button>
                      </div>
                    </div>
                    <div className="w-100">
                      <p className="im1 mt-3 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">
                        Jobs1.jpeg
                      </p>
                      <p className="im2 f1 fs-12 fs-xs-11 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                        Updated 14 October 2023
                      </p>
                    </div>
                  </div>
                  <div className="files_pop_pro d-flex flex-column ac-jb">
                    <div className="over_img">
                      <img src={product} className="w-100" />
                      <div className="files_btn d-flex-ac-jc">
                        <button className="px-2 px-md-3 py-0 mx-1">
                          <FileDownloadIcon className="f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 text-light" />
                        </button>
                      </div>
                    </div>
                    <div className="w-100">
                      <p className="im1 mt-3 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">
                        Jobs1.jpeg
                      </p>
                      <p className="im2 f1 fs-12 fs-xs-11 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                        Updated 14 October 2023
                      </p>
                    </div>
                  </div>
                  <div className="files_pop_pro d-flex flex-column ac-jb">
                    <div className="over_img">
                      <img src={product} className="w-100" />
                      <div className="files_btn d-flex-ac-jc">
                        <button className="px-2 px-md-3 py-0 mx-1">
                          <FileDownloadIcon className="f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-20 text-light" />
                        </button>
                      </div>
                    </div>
                    <div className="w-100 text-md-start text-center">
                      <p className="im1 mt-3 f3 fs-10 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark">
                        Jobs1.jpeg
                      </p>
                      <p className="im2 f1 fs-12 fs-xs-11 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                        Updated 14 October 2023
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="d-flex mt-3 justify-content-end m-2">
              <button
                onClick={() => toggleShowPopup()}
                className="mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
      {upload && (
        <div className="upload-file-popup">
          <div>
            <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
              Upload File
              <span>
                <button onClick={() => setUpload(!upload)} className="cust-btn">
                  <HighlightOffIcon />
                </button>
              </span>
            </p>

            <div className="d-flex over_files_pop">
              <button
                className={tab == 0 && "btn_overview_line"}
                onClick={() => setTab(0)}
              >
                From Computer
              </button>
              <button
                className={tab == 1 && "btn_overview_line"}
                onClick={() => setTab(1)}
              >
                From Web
              </button>
            </div>
            {tab == 1 ? (
              <div className="mt-3">
                <div className="d-flex">
                  <input
                    type={"url"}
                    className="w-50"
                    placeholder="http://www..."
                    onChange={(e) => handleImage(e.target.value)}
                  />
                  <button
                    className={
                      image?.length > 0
                        ? "cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
                        : "cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded opacity-50"
                    }
                    onClick={() => setProgress_Bar(true)}
                    disabled={image?.length > 0 ? false : true}
                  >
                    Select
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="text-center fileUp-load-box  mt-3">
                  <label className="mt-3 text-info">
                    Drag or click to upload file
                    <input
                      type={"file"}
                      className="d-none"
                      accept="image/png, image/jpeg"
                      onChange={(e) => handleImage(e.target.files[0])}
                    />
                  </label>
                </div>
                <div className="mt-2 text-end">
                  {image?.name && (
                    <button
                      className="btn btn-primary mx-2"
                      onClick={() => setProgress_Bar(true)}
                    >
                      Select
                    </button>
                  )}
                </div>
              </>
            )}

            {progress_Bar && (
              <div className="d-block w-50 mt-2">
                <ProgressBar now={countOfProgess} />
                <div className="text-end">
                  <span>{parseInt(countOfProgess)} %</span>
                </div>
              </div>
            )}
            {imageUrl && (
              <div onClick={() => setUpload(false)}>
                <img
                  src={image?.name ? URL.createObjectURL(image) : image}
                  className="mt-2 w-25"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AddFilesPopupCopy;
