import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { noLogo } from "../../assets/img";
import ErrorIcon from "@mui/icons-material/Error";

const AddAttributesPopup = ({
  toggleShowPopup,
  popupType,
  toggleImagePopup,
  name,
  setName,
  image,
  btn,
  nameErr,
  submitHandle,
}) => {
  return (
    <div className="add-category-popup">
      <div>
        <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
          {popupType === "edit" ? "Edit" : "Add"} Attribute
          <span>
            <button onClick={toggleShowPopup} className="cust-btn">
              <HighlightOffIcon />
            </button>
          </span>
        </p>

        <div className="d-flex ac-jc add-content">
          <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
            <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Attribute Name
            </p>
            <div className="w-100 d-flex w-xs-100 mt-xs-5 position-relative">
              <div className="w-100  w-xs-100 mt-xs-5 position-relative">
                <input
                  className="w-80 cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                  placeholder="Attribute Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
                {name?.length == 0 && nameErr && (
                  <div className="d-flex gap-1 mt-2 position-absolute">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Title</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="w-50 d-flex ac-jb mt-4 flex-wrap"
            onClick={() => toggleImagePopup()}
          >
            <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Image (Optional)
            </p>
            <div className="w-100 d-flex w-xs-100 mt-xs-5 position-relative">
              <input
                className="cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                placeholder="Search"
                value={image ? "Image Uploaded Successfuly" : "Upload File"}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
          {image && (
            <img
              src={
                image?.name
                  ? URL.createObjectURL(image)
                  : image
                  ? image
                  : noLogo
              }
              style={{ width: "100px" }}
            />
          )}
        </div>

        <div className="d-flex mt-3 justify-content-end m-2">
          <button
            onClick={submitHandle}
            disabled={btn}
            className={`mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
              btn && "opacity-50"
            }`}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddAttributesPopup;
