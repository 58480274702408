import React, { useEffect, useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import { useLocation, useNavigate } from "react-router-dom";
import OverviewTab from "../components/MapProduct/OverView";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import RejectPopup from "../components/Popup/RejectPopup";
import { listCurrencies } from "../redux/api/DummyJson";
import {
  useAdminVendorPriceStatusChangeMutation,
  useLazyProSpecificVendorPriceQuery,
  useLazyProVendorPriceReqShowQuery,
} from "../redux/api/api";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";

const MapRequestView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.data;
  const [curTab, setCurTab] = useState("overview");
  const [viewData, setViewData] = useState({});
  const [viewPrice, setViewPrice] = useState({});
  const [country, setCountry] = useState("");
  const [status, setStatus] = useState("");
  const [status2, setStatus2] = useState("");
  const [notes, setNotes] = useState("");
  const [notesErr, setNotesErr] = useState("");
  const [show, setShow] = useState(false);
  const [btn, setBtn] = useState(false);
  const [vendorProductCode, setVendorProductCode] = useState("");
  const dispatch = useDispatch();

  // RTK QUERY
  const [productVendorPriceReqApi] = useLazyProVendorPriceReqShowQuery();
  const [productVendorPriceStatusApi] =
    useAdminVendorPriceStatusChangeMutation();

  // console.log("location", location?.state?.data);

  const getPriceList = (id) => {
    productVendorPriceReqApi(id)
      .unwrap()
      .then((res) => {
        setViewData(res?.product_details);
        setViewPrice(res?.vendor_price_details);
        let finder = listCurrencies?.find(
          (i) => i?.name == res?.product_details?.country_origin
        );
        setCountry(finder);
        setStatus(res?.request_status);
        setStatus2(res?.request_status);
        setVendorProductCode(res?.vendor_price_details?.vendor_product_code);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const changeStatus = (event) => {
    let formdata = new FormData();
    formdata.append("vendor_product_price_id", location?.state?.item?.id);
    formdata.append("request_status", event);

    if (notes) {
      formdata.append("notes", notes);
    }

    let count = 1;
    if (event == 5 && notes?.length == 0) {
      setNotesErr(true);
      count = count + 1;
    }

    if (count == 1) {
      dispatch(saveLoader(true));
      setBtn(true);
      productVendorPriceStatusApi(formdata)
        .unwrap()
        .then((res) => {
          setShow(false);
          dispatch(saveLoader(false));
          setBtn(false);
          toast.success(res?.message);
          if (location?.state?.map == "map") {
            navigate("/product-feed", {
              state: { type: location?.state?.map },
            });
          } else {
            navigate("/product-feed", { state: { type: "price" } });
          }
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  const onStatusChange = (num) => {
    setStatus(num);
    if (num == 5) {
      setShow(true);
    } else {
      changeStatus(num);
    }
  };

  const onShowPopupHander = () => {
    setShow(!show);
    setNotes("");
    setNotesErr(false);
    setStatus(status2);
  };

  useEffect(() => {
    if (location?.state?.item?.id) {
      getPriceList(location?.state?.item?.id);
    }
  }, []);

  const navgitor = () => {
    if (location?.state?.method == "vendor") {
      navigate("/product-vendor-details", {
        state: { data: location?.state?.data, tab: 3, productList: "price" },
      });
    } else if (location?.state?.map == "map") {
      navigate("/product-feed", { state: { type: location?.state?.map } });
    } else {
      navigate("/product-feed", { state: { type: "price" } });
    }
  };

  // console.log("status", status);

  return (
    <div className="dashRightView p-5 home_section trans">
      {show && (
        <RejectPopup
          onShowPopupHander={onShowPopupHander}
          notes={notes}
          notesErr={notesErr}
          setNotes={setNotes}
          changeStatus={changeStatus}
          status={status}
          btn={btn}
        />
      )}
      <ProductSearchHeader />
      <div className="w-100 top_head_cust py-3 d-flex ac-jb px-3">
        <h5 class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
          {data?.product?.name}
        </h5>
        <h5 class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 black">
          Status :{" "}
          <span>
            {/* Requested */}
            {location?.state?.method == "vendor" ? (
              <select
                name="status"
                className="primary p-1 f2 fs-18"
                style={{ border: "1px solid #07679c", borderRadius: "10px" }}
                value={status}
                disabled
              >
                <option disabled hidden value="">
                  Select
                </option>
                <option value={1}>Request</option>
                <option value={6}>In Process</option>
                <option value={4}>Accept</option>
                <option value={7}>Reject</option>
              </select>
            ) : location?.state?.map == "map" ? (
              <select
                name="status"
                className="primary p-1 f2 fs-18"
                style={{ border: "1px solid #07679c", borderRadius: "10px" }}
                onChange={(e) => onStatusChange(e.target.value)}
                value={status}
              >
                <option disabled hidden value="">
                  Select
                </option>
                <option
                  value={1}
                  disabled={
                    status == 2
                      ? true
                      : status == 4
                      ? true
                      : status == 5
                      ? true
                      : false
                  }
                >
                  Request
                </option>
                <option value={2}>In Process</option>
                <option value={4}>Accept</option>
                <option value={5}>Reject</option>
              </select>
            ) : (
              <select
                name="status"
                className="primary p-1 f2 fs-18"
                style={{ border: "1px solid #07679c", borderRadius: "10px" }}
                onChange={(e) => onStatusChange(e.target.value)}
                value={status}
              >
                <option disabled value="">
                  Select
                </option>
                <option
                  value={1}
                  disabled={
                    status == 6
                      ? true
                      : status == 4
                      ? true
                      : status == 7
                      ? true
                      : false
                  }
                >
                  Request
                </option>
                <option value={6}>In Process</option>
                <option value={4}>Accept</option>
                <option value={7}>Reject</option>
              </select>
            )}
          </span>
        </h5>
      </div>

      <div className="d-flex mt-2 ac-jb flex-column flex-md-row w-100">
        <div className="d-flex mt-3 ac-jc">
          <div>
            <p
              className="f4 fs-xs-10 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary"
              role={"button"}
              onClick={() => navgitor()}
            >
              <KeyboardBackspaceIcon />
            </p>
          </div>
          <div
            onClick={() => {
              setCurTab("overview");
            }}
            style={{ cursor: "pointer" }}
            className={`${
              curTab === "overview"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-4 text-center`}
          >
            <p
              className={`${
                curTab === "overview" ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center `}
            >
              Overview
            </p>
          </div>
          <div
            onClick={() => {
              setCurTab("pricing");
            }}
            style={{ cursor: "pointer" }}
            className={`${
              curTab === "pricing"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-4  text-center`}
          >
            <p
              className={`${
                curTab === "pricing" ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center `}
            >
              Pricing
            </p>
          </div>
        </div>
        <div className="mt-md-4 mt-3">
          <h5 class="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 w-100 text-dark">
            Currency :
            <span className="primary f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25">
              {" "}
              {/* {country?.currency?.code} */}
              INR
            </span>
          </h5>
        </div>
      </div>

      {curTab === "overview" && (
        <OverviewTab
          viewData={viewData}
          vendorProductCode={vendorProductCode}
        />
      )}
      {curTab === "pricing" && (
        <div className="">
          <p className="mt-5 f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
            Vendor Pricing
          </p>
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-3">
            {data?.vendor?.name}
          </p>
          <div className="overflow-scroll">
            <table className="w-100">
              <tr className="mt-4">
                <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Qty
                </th>
                <div className="my-3">
                  <td>
                    <div className="d-flex w-100">
                      <div className="mx-2">
                        <input
                          class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="button"
                          value="25"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="button"
                          value="50"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="button"
                          value="100"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="button"
                          value="150"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="button"
                          value="250"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="button"
                          value="500"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="button"
                          value="1000"
                        />
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
              <tr>
                <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Net
                </th>
                <div className="mt-2">
                  <td>
                    <div className="d-flex w-100">
                      <div className="mx-2">
                        <input
                          class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="number"
                          placeholder="₹25.00"
                          value={viewPrice?.distribute_price?.for_25}
                          disabled
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹50.00"
                          type="number"
                          value={viewPrice?.distribute_price?.for_50}
                          disabled
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹100.00"
                          type="number"
                          value={viewPrice?.distribute_price?.for_100}
                          disabled
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹150.00"
                          type="number"
                          value={viewPrice?.distribute_price?.for_150}
                          disabled
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹250.00"
                          type="number"
                          value={viewPrice?.distribute_price?.for_250}
                          disabled
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹500.00"
                          type="number"
                          value={viewPrice?.distribute_price?.for_500}
                          disabled
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹1000.00"
                          type="number"
                          value={viewPrice?.distribute_price?.for_1000}
                          disabled
                        />
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default MapRequestView;
