import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { vendorSuccessImg } from "../../assets/img";

const VendorSuccessPopup = ({ showPopupHander }) => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/vendor-home");
    }, 3000);
  }, []);
  return (
    <div className="modal-popup">
      <div onClick={showPopupHander} className="back-close" />
      <div className="center-content">
        <div className="d-flex ac-jc">
          <img src={vendorSuccessImg} className="mailImg" />
        </div>
        <div className="d-flex p-4 ac-jc">
          <p className="f2 fs-xs-14 fs-sm-15 fs-md-16 fs-lg-17 fs-xl-18 fs-xxl-19">
            Sent Successfully
          </p>
        </div>
        <div className="d-flex ac-jc">
          <button
            onClick={() => navigate("/vendor-home")}
            className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Back
          </button>
        </div>
      </div>
      <CloseIcon className="white f2 radiusVendDesIcon fs-xs-28 fs-sm-28 fs-md-28 fs-lg-28 fs-xl-28 fs-xxl-28 mt-1" />
    </div>
  );
};

export default VendorSuccessPopup;
