import React, { useEffect, useState } from "react";
import { emptyImg, product, profilePic, uploadIcon } from "../../assets/img";
import Carousel from "react-bootstrap/Carousel";
import { productDetailImg } from "../../redux/api/constants";
import { EditAttributes } from "@mui/icons-material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { owl_List, product_image } from "../../redux/api/DummyJson";

import ErrorIcon from "@mui/icons-material/Error";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ImageCroper from "../Popup/ImageCroper";
import { useLocation } from "react-router-dom";

const RightComp = ({
  edit,

  colorsData,
  setColorsData,

  viewData,
  setSmallImage,
  setSmallMockImage,
  smallimage,
  smallMockimage,
  productImg,
  setProductImg,
  productImgErr,
  setProductImgErr,
  morkupImg,
  setMorkupImg,
  morkupImgErr,
  setMorkupImgErr,
  colorsImg,
  setColorsImg,
  colorImgErr,
  setColorImgErr,
}) => {
  const location = useLocation();
  const [colorImg, setColorImg] = useState(null);
  const [croperImage, setCropImage] = useState();
  const [croper, setCroper] = useState(false);
  const [bigImage, setBigImage] = useState({ image: "", ind: "" });
  const [mockImage, setMockImage] = useState({ image: "", ind: "" });
  const [fistShow, setFirstShow] = useState(false);
  const [fistShows, setFirstShows] = useState(false);
  const [croperCol, setCroperCol] = useState(0);
  // console.log(vendor);
  const [logo, setLogo] = useState({});
  const [logo1, setLogo1] = useState({});
  const [textShow, setTextShow] = useState(true);
  const [textShows, setTextShows] = useState(true);
  const [indexs, setIndexs] = useState(0);
  // console.log(vendor2);

  const onColorImgUpload = (img) => {
    // console.log("img", img);
    let temp = [...colorsData];
    temp[indexs] = {
      ...temp[indexs],
      image: img,
    };
    setColorsData(temp);
    setColorImg(img);
    setColorsImg(img);
  };

  const imageSelector = (item, ind, type) => {
    if (type == "mock") {
      setMockImage({ image: item, ind: ind });
    } else if (type == "product") {
      setBigImage({ image: item, ind: ind });
    }
  };

  const dualToggele = (type, ind) => {
    if (type == "color") {
      setCroperCol(1);
      toggleImagePopup();
      setIndexs(ind);
    } else if (type == "product") {
      setCroperCol(0);
      toggleImagePopup();
    } else {
      setCroperCol(2);
      toggleImagePopup();
    }
  };

  useEffect(() => {
    if (croperCol == 0 && croperImage) {
      setTextShow(false);
      setFirstShow(true);

      let temp = [...smallimage];

      if (croperImage) {
        temp.push(croperImage);
      }

      fetch(croperImage)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "File name", { type: "image/png" });
          setSmallImage([...smallimage, file]);
          setProductImg(file);
        });

      // setSmallImage(temp);
      setBigImage({ image: temp[temp.length - 1], ind: temp.length - 1 });
      setCropImage("");
    } else if (croperCol == 1 && croperImage) {
      fetch(croperImage)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "File name", { type: "image/png" });
          onColorImgUpload(file);
        });
    } else if (croperCol == 2 && croperImage) {
      setTextShows(false);
      setFirstShows(true);

      let temp = [...smallMockimage];

      if (croperImage) {
        temp.push(croperImage);
      }

      fetch(croperImage)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "File name", { type: "image/png" });
          setSmallMockImage([...smallMockimage, file]);
          setMorkupImg(file);
        });

      // setSmallMockImage(temp);
      setMockImage({ image: temp[temp.length - 1], ind: temp.length - 1 });
      setCropImage("");
    }
  }, [croperImage]);

  const removeImg = (ind, type) => {
    if (type == "product") {
      let temp = [...smallimage];

      temp.splice(ind, 1);
      setSmallImage(temp);
      if (temp?.length - 1 === 0) {
        setBigImage({ image: temp[temp.length - 1], ind: temp.length - 1 });
      } else {
        setBigImage({ image: temp[ind - 1], ind: ind - 1 });
      }
    } else if (type == "mock") {
      let temps = [...smallMockimage];

      temps.splice(ind, 1);
      setSmallMockImage(temps);
      if (temps?.length - 1 === 0) {
        setMockImage({ image: temps[temps.length - 1], ind: temps.length - 1 });
      } else {
        setMockImage({ image: temps[ind - 1], ind: ind - 1 });
      }
    }
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  const getView = () => {
    if (Array.isArray(viewData?.product_details?.productImage)) {
      let temp = [...viewData?.product_details?.productImage];

      let finder = temp.find((i) => i?.is_default == 1);
      let findInx = temp.findIndex((i) => i?.id == finder?.id);

      if (finder?.id) {
        setBigImage({
          image: finder?.image_url,
          ind: findInx,
        });
      } else {
        setBigImage({
          image: temp[temp?.length - 1]?.image_url,
          ind: temp?.length - 1,
        });
      }
    }

    if (Array.isArray(viewData?.product_details?.morkupImage)) {
      let temp = [...viewData?.product_details?.morkupImage];

      let finder = temp.find((i) => i?.is_default == 1);
      let findInx = temp.findIndex((i) => i?.id == finder?.id);

      if (finder?.id) {
        setMockImage({
          image: finder?.image_url,
          ind: findInx,
        });
      } else {
        setMockImage({
          image: temp[temp?.length - 1]?.image_url,
          ind: temp?.length - 1,
        });
      }
    }

    setTextShow(false);
    setFirstShow(true);
    setTextShows(false);
    setFirstShows(true);
  };

  useEffect(() => {
    if (location?.state?.data?.id && viewData?.product_details?.id) {
      getView();
    }
  }, [viewData]);

  // console.log("colorsData", colorsData);

  // Slide
  return (
    <>
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <div className="d-flex gap-3 ac-jc w-100 res-img">
        <div>
          <p class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
            Product Image (185 x 205)
          </p>
          <div>
            <div className="">
              <div
                className="position-relative"
                onClick={() => !fistShow && dualToggele("product")}
              >
                <img
                  className={"img_up_cont"}
                  src={
                    bigImage?.image?.name
                      ? URL.createObjectURL(bigImage?.image)
                      : bigImage?.image
                      ? bigImage?.image
                      : uploadIcon
                  }
                />
                {!textShow && (
                  <div>
                    {/* <ul
                    className={`${"image-hide"}  edit-image image-show d-flex justify-content-between border_img`}
                  >
                    <li className="set-default-image">Set&nbsp;Default</li>
                    {smallimage?.length > 1 && (
                      <li
                        className="set-default-image"
                        onClick={() => removeImg(bigImage?.ind, "product")}
                      >
                        Remove
                      </li>
                    )}
                  </ul> */}
                  </div>
                )}
              </div>
              <div className="d-flex ac-jc gap-3 w-100">
                {fistShow && (
                  <div
                    class=" mt-3 gap-3 d-flex ac-jc pb-4 ps-5"
                    style={{ width: "300px", overflow: "scroll" }}
                  >
                    {smallimage?.map((item, ind) => {
                      return (
                        <div className="gap-3 d-flex ac-j w-100">
                          <div
                            class="add_img d-flex ac-jc"
                            onClick={() => imageSelector(item, ind, "product")}
                          >
                            <img
                              src={
                                item?.name ? URL.createObjectURL(item) : item
                              }
                              class="cp add_img object-fit-fill"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {/* {smallimage?.length > 0 && (
                <label
                  className={`add_img d-flex ac-jc cp f3 primary fs-3 ${
                    smallimage?.length == 0 && "mt-3"
                  }`}
                  onClick={() => dualToggele("product")}
                >
                  {" "}
                  +
                </label>
              )} */}
              </div>
            </div>
          </div>
          {productImg?.length == 0 && productImgErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Upload Product Image</p>
            </div>
          )}
        </div>

        {/* <div>
          <p class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
            Mockup Image
          </p>

          <div>
            {" "}
            <div className="">
              <div
                className="position-relative"
                onClick={() => !fistShows && dualToggele("mock")}
              >
                <img
                  className={"img_up_cont"}
                  src={
                    mockImage?.image?.name
                      ? URL.createObjectURL(mockImage?.image)
                      : mockImage?.image
                      ? mockImage?.image
                      : uploadIcon
                  }
                />
                {!textShows && (
                  <div>
                    {/* <ul
                    className={`${"image-hide"}  edit-image image-show d-flex justify-content-between border_img`}
                  >
                    <li className="set-default-image">Set&nbsp;Default</li>
                    {smallMockimage?.length > 1 && (
                      <li
                        className="set-default-image"
                        onClick={() => removeImg(mockImage?.ind, "mock")}
                      >
                        Remove
                      </li>
                    )}
                  </ul> 
                  </div>
                )}
              </div>
              <div className="d-flex ac-jc gap-3 w-100">
                {fistShows && (
                  <div
                    class=" mt-3 gap-3 d-flex ac-jc pb-4 ps-5"
                    style={{ width: "300px", overflow: "scroll" }}
                  >
                    {smallMockimage?.map((item, ind) => {
                      return (
                        <div className="gap-3 d-flex ac-j w-100">
                          <div
                            class="add_img d-flex ac-jc"
                            onClick={() => imageSelector(item, ind, "mock")}
                          >
                            {" "}
                            <img
                              src={
                                item?.name ? URL.createObjectURL(item) : item
                              }
                              class="cp add_img object-fit-fill"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {/* {smallMockimage?.length > 0 && (
                <label
                  className={`add_img d-flex ac-jc cp f3 primary fs-3 ${
                    smallMockimage?.length == 0 && "mt-3"
                  }`}
                  onClick={() => dualToggele("mock")}
                >
                  +
                </label>
              )} 
              </div>
            </div>
          </div>
          {morkupImg?.length == 0 && morkupImgErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Upload Mockup Image</p>
            </div>
          )}
        </div> */}
        <div>
          <p class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
            Color Image (312 x 312)
          </p>
          <div
            className="d-flex gap-3 mt-3 ac-jc flex-wrap"
            style={{ width: "350px" }}
          >
            {colorsData?.map((item, ind) => {
              return (
                <div className="d-flex flex-column ac-jc">
                  {item?.image ? (
                    <div className="position-relative for_hover">
                      <img
                        alt="icon"
                        src={
                          item?.image?.name
                            ? URL.createObjectURL(item?.image)
                            : item?.image
                        }
                        className="add_img object-fit-fill"
                        // onClick={() => dualToggele("color", ind)}
                      />
                      {/* <div className="d-flex w-100 justify-content-between position-absolute in_hover text-white">
                        <FileDownloadOutlinedIcon />
                        <ClearOutlinedIcon />
                      </div> */}
                    </div>
                  ) : (
                    <label
                      className="add_img d-flex ac-jc cp f3 primary fs-3"
                      // onClick={() => dualToggele("color", ind)}
                    >
                      +
                    </label>
                  )}
                  <p className="f3" style={{ color: item?.hex_code }}>
                    {item?.name}
                  </p>
                </div>
              );
            })}
          </div>
          {colorImgErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Upload Color Image</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RightComp;
