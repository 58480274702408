import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";

const PositionPopup = ({
  showPopupHander,
  positionNumber,
  setPoistionNumber,
  positionData,
  positionNumberErr,
  setPositionData,
  changePosition,
  positionBtn,
}) => {
  // console.log("positionNumber", positionNumber);

  return (
    <div className="modal-popup">
      <div
        onClick={() => {
          showPopupHander();
          setPoistionNumber(null);
        }}
        className="back-close"
      />
      <div className="center-content-contact3 w-40 p-4">
        <div className="w-100 d-flex ac-jb">
          <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Positions
          </p>
          <CloseIcon
            onClick={() => {
              showPopupHander();
              setPoistionNumber(null);
            }}
            className="pointerView white p-1 rounded-5 f2 bg-primar fs-xs-28 fs-sm-28 fs-md-28 fs-lg-28 fs-xl-28 fs-xxl-25"
          />
        </div>
        <div className="mt-4 w-xl-100 w-lg-100 w-md-100 w-sm-100 w-xs-100">
          <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
            Change Position of{" "}
            <span className="gray f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              {positionData?.first_name + " " + positionData?.last_name}
            </span>
          </p>
          <select
            placeholder=""
            className="w-100 editBtnSelect mt-4 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 mt-2"
            name=""
            id=""
            onChange={(e) => setPoistionNumber(e.target.value)}
            value={positionNumber}
          >
            <option value={null}>Select Position</option>
            <option value={1}>Primary</option>
            <option value={2}>Secondary</option>
          </select>
          {positionNumber?.length == 0 && positionNumberErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Select Position</p>
            </div>
          )}
        </div>
        <div className="d-flex mt-4 ac-jc">
          <button
            disabled={positionBtn}
            className={`cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
              positionBtn && "opacity-50"
            }`}
            onClick={() => changePosition()}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default PositionPopup;
