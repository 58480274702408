import React, { useEffect, useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import { uploadIcon } from "../assets/img";
import FormComp from "../components/AddNewProductComps/FormComp";
import ImageUplodeComp from "../components/AddNewProductComps/ImageUplodeComp";
import { useLocation, useNavigate } from "react-router-dom";
import RequestFormComp from "../components/CreateNewRequest/RequestFormComp";
import RequestImageUplodeComp from "../components/CreateNewRequest/RequestImageUplodeComp";
import PricingBoxComp from "../components/MyProductListComp/PricingBoxComp";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  useCategoryProductViewShowMutation,
  useLazyCategoriesQuery,
  useLazyCategoryViewQuery,
} from "../redux/api/api";

const CreateNewRequestScreen = () => {
  const navigat = useNavigate();
  const [parentCategoryList, setParentCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [parentCategory, setParentCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");

  // RTK QUERY
  const [listCategory] = useLazyCategoriesQuery();
  const [viewCategory] = useLazyCategoryViewQuery();

  // GET PARENT CATEGORY LIST
  const getParentCategoryList = () => {
    listCategory()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setParentCategoryList(res?.categories);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  useEffect(() => {
    getParentCategoryList();
  }, []);

  // GET PARENT ID FOR SUB CATEGORY LIST
  const getCategoryId = (id, type, ind, index) => {
    if (type == "category") {
      setParentCategory(id);
      getView(id, "", "", type, "");
    } else {
      setSubCategory(id);
      onSubCatSelect(id, ind, index);
    }
  };

  const onSubCatSelect = (id, ind, index) => {
    if (index < subCategoryList?.length - 1) {
      let temp = [...subCategoryList];
      let slicer = temp.slice(0, index + 1);
      setSubCategoryList(slicer);

      getView(id, ind, index, "sub_category", slicer);
    } else {
      getView(id, ind, index);
    }

    // ADD MULTIPLE
    // addMultiple(item, index);
  };

  const getView = (id, ind, index, type, data) => {
    viewCategory(id)
      .unwrap()
      .then((res) => {
        let temp = [];
        if (data?.length > 0) {
          temp = [...data];
        } else if (type !== "category") {
          temp = [...subCategoryList];
        }

        if (type == "subSumit") {
          let temX = res?.child_categories;

          let obj = {
            ...temp[index],
            child_categories: temX,
          };

          let final = subCategoryList?.filter((i) => i?.category?.id !== id);
          final.splice(index, 0, obj);

          setSubCategoryList(final);
        } else {
          let finder = temp.indexOf(id);
          if (temp?.includes(res)) {
            temp.slice(0, 1);
            temp.push(res);
          } else {
            temp.push(res);
          }

          setSubCategoryList(temp);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      <ProductSearchHeader />
      <p
        className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigat(-1)}
      >
        <KeyboardBackspaceIcon /> Create New Request
      </p>
      {/* <div className='d-flex as-jb w-100 mt-5 res-flex gap-5'> */}
      <RequestFormComp
        parentCategoryList={parentCategoryList}
        getCategoryId={getCategoryId}
        subCategoryList={subCategoryList}
        setSubCategoryList={setSubCategoryList}
        setParentCategory={setParentCategory}
      />
      <div>
        <PricingBoxComp />
      </div>
      <div className="d-flex ac-jb w-80">
        <button
          className="cust-btn addbtn mt-sm-3 align-selfe-center mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          onClick={() => navigat(-1)}
        >
          Back
        </button>
        <button
          className="cust-btn addbtn mt-sm-3 align-selfe-center mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          onClick={() => navigat(-1)}
        >
          Save
        </button>
      </div>
      {/* </div> */}
    </div>
  );
};

export default CreateNewRequestScreen;
