import {
  Filter,
  Filter1Outlined,
  FilterAlt,
  Search,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import manImage from "../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import DropdownTap from "../components/ProductComp/ProductDropdownTap";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import ProductListComp from "../components/ProductComp/ProductListComp";
import ProductLibraryComp from "../components/ProductLibrary/ProductLibraryComp";
import ProductListLibraryComp from "../components/ProductLibrary/ProductListLibraryComp";
import { animatedImg } from "../assets/img";
import CachedIcon from "@mui/icons-material/Cached";
import { product as productImg } from "../assets/img";
import ProductRequest from "../components/ProductFeed/ProductRequest";
import ProductMap from "../components/ProductFeed/ProductMap";
import PriceChange from "../components/ProductFeed/PriceChange";
import NewProductSuggest from "../components/ProductFeed/NewProductSuggest";
import {
  useLazyCategoriesQuery,
  usePriceListMutation,
  useProductPriceListMutation,
  useProductReqListMutation,
} from "../redux/api/api";

const ProductFeed = () => {
  const navigate = useNavigate();
  const Path = useLocation();
  const type = Path?.state?.type;
  // console.log(type, "type");
  const [activeTab, setActiveTab] = useState("product");

  const [mapProductList, setMapProductList] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [listCat, setListCat] = useState([]);

  //PRICE CHANGE LIST
  const [priceChangeList, setPriceChangeList] = useState([]);
  const [priceChangePagination, setMapProductPagination] = useState({});
  const [search, setSearch] = useState("");
  const [pageChange, setPageChange] = useState(1);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setTodate] = useState("");
  const [fromDateMap, setFromDateMap] = useState("");
  const [toDateMap, setTodateMap] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [catValue, setCatValue] = useState("");
  const [statusValuePrice, setStatusValuePrice] = useState("");
  const [catValuePrice, setCatValuePrice] = useState("");

  // RTK QUERY
  const [productReqApi] = useProductReqListMutation();
  const [priceListApi] = usePriceListMutation();
  const [listCategory] = useLazyCategoriesQuery();

  const getList = () => {
    listCategory()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setListCat(res?.categories);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getProPriceList = (event, row, status, category) => {
    let formdata = new FormData();
    let params = `?page=${1}`;

    formdata.append("status", 1);

    if (row) {
      formdata.append("rows", row);
    }

    if (event?.length > 0) {
      formdata.append("search", event);
    }

    setSearchValue(event);

    if (status) {
      formdata.append("type_status", status);
    }

    setStatusValue(status);

    if (category) {
      formdata.append("category_id", category);
    }

    setCatValue(category);

    if (fromDateMap && toDateMap) {
      formdata.append("from_date", fromDateMap);
      formdata.append("to_date", toDateMap);
    }

    productReqApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        setMapProductList(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getPriceList = (event, row, status, category) => {
    let formdata = new FormData();
    let params = `?page=${pageChange}`;
    // formdata.append("status", 1);

    setSearchValue(event);

    if (status) {
      formdata.append("status", status);
    }

    setStatusValuePrice(status);

    if (category) {
      formdata.append("category_id", category);
    }

    setCatValuePrice(category);

    if (event?.length > 0) {
      formdata.append("search", event);
    }
    if (fromDate && toDate) {
      formdata.append("from_date", fromDate);
      formdata.append("to_date", toDate);
    }
    setSearch(event);

    priceListApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        // console.log(res);
        setPriceChangeList(res?.lists);
        setMapProductPagination(res?.pagination_meta);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getProPriceList(searchValue, 12, statusValue, catValue);
  }, [page, fromDateMap, toDateMap]);

  useEffect(() => {
    getPriceList();
  }, [pageChange, fromDate, toDate]);

  useEffect(() => {
    if (fromDate && toDate) {
      getPriceList();
    }
  }, []);
  const onTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (type) {
      setActiveTab(type);
    }

    getList();
  }, []);

  return (
    <div className="dashRightView  p-5 home_section trans">
      <ProductSearchHeader />
      <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-2">
        Vendor Request List
      </p>
      <div className="d-flex mb-4 ac-jb flex-wrap me-md-3 rk2">
        <div className="d-flex mt-4 rk">
          <button
            onClick={() => onTabClick("product")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "product"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "product" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Product Request
            </p>
          </button>
          <button
            onClick={() => onTabClick("map")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "map"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "map" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Map Request
            </p>
          </button>
          <button
            onClick={() => onTabClick("price")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "price"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "price" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Price Change
            </p>
          </button>
          <button
            onClick={() => onTabClick("new_product_suggest")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "new_product_suggest"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "new_product_suggest" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              New Project Suggestion
            </p>
          </button>
        </div>
      </div>

      {activeTab === "product" && <ProductRequest listCat={listCat} />}
      {activeTab === "map" && (
        <ProductMap
          mapProductList={mapProductList}
          setPage={setPage}
          page={page}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          getProPriceList={getProPriceList}
          listCat={listCat}
          statusValue={statusValue}
          catValue={catValue}
          fromDateMap={fromDateMap}
          setFromDateMap={setFromDateMap}
          toDateMap={toDateMap}
          setTodateMap={setTodateMap}
        />
      )}
      {activeTab === "price" && (
        <PriceChange
          priceChangeList={priceChangeList}
          setPriceChangeList={setPriceChangeList}
          search={search}
          setSearch={setSearch}
          pageChange={pageChange}
          setPageChange={setPageChange}
          setFromDate={setFromDate}
          setTodate={setTodate}
          toDate={toDate}
          fromDate={fromDate}
          getPriceList={getPriceList}
          listCat={listCat}
          catValuePrice={catValuePrice}
          statusValuePrice={statusValuePrice}
          priceChangePagination={priceChangePagination}
        />
      )}
      {activeTab === "new_product_suggest" && (
        <NewProductSuggest onTabClick={onTabClick} listCat={listCat} />
      )}
    </div>
  );
};

export default ProductFeed;
