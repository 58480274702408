import { animatedImg, emptyImg, uploadIcon } from "../assets/img";
import { Search } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import {
  VendoroffertypeList,
  categoryList,
  dummyDashboardData,
} from "../redux/api/DummyJson";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ProductVendor from "../components/Vendor/ProductVendor";
import { useLocation, useNavigate } from "react-router-dom";
import ServiceVendor from "../components/Vendor/ServiceVendor";
import ImportListPopup from "../components/Popup/ImportListPopup";
import BothVendor from "../components/Vendor/BothVendor";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import GetAppIcon from "@mui/icons-material/GetApp";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import UploadIcon from "@mui/icons-material/Upload";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  useDownloadSampleExcelsMutation,
  useDownloadVendorCreationMutation,
  useDownloadVendorMutation,
  useLazyProfile_statusQuery,
  useLazyVendorStatusListQuery,
  useLazyVendorsViewQuery,
  useVendorExportMutation,
  useVendorsEditMutation,
  useVendorsListMutation,
} from "../redux/api/api";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AllVendor from "../components/Vendor/AllVendor";

const VendorDashboard = () => {
  const [tab, setTab] = useState(4);
  const [prodVendor, setProdVendor] = useState(false);
  const [serVendor, setSerVendor] = useState(false);
  const [serBoth, setSerBoth] = useState(false);
  const [importList, setImportList] = useState(false);
  const [bothLis, setBothLis] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [list, setList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageRow, setPageRow] = useState(10);
  const [page, setPage] = useState(1);
  const [pagenation, setPagination] = useState({});
  const [vendorOfferType, setVendorOfferType] = useState("");
  const [offerShow, setOfferShow] = useState(false);
  const [vendorCatogory, setVendorCatogory] = useState("");
  const [Vendorofferty, setVendoroffertypes] = useState([]);
  const [searchValueOffering, setSearchValueOffering] = useState("");
  const [searchValueCategory, setSearchValueCategory] = useState("");
  const [dropDown, setDropDown] = useState(null);
  const [selectALLSub, setSelectALLSub] = useState(false);
  const [vendorOfferTypeErr, setVendorOfferTypeErr] = useState(false);
  const [lists, setLists] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [categoryNameErr, setCategoryNameErr] = useState(false);
  const [vendorCategories, setVendorCategories] = useState([]);

  // Vendor Offering Type
  const searchVendorOffering = (event) => {
    let temp = [...VendoroffertypeList];
    let search = temp.filter((value) =>
      value?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setVendoroffertypes(search);
    } else {
      setVendoroffertypes(VendoroffertypeList);
    }

    setSearchValueOffering(event);
  };

  // SEARCH CATEGROY
  const searchCategory = (event) => {
    let temp = [...categoryList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setList(search);
    } else {
      setList(categoryList);
    }

    setSearchValueCategory(event);
  };

  const showPopupHander = () => {
    setImportList(false);
  };

  useEffect(() => {
    if (location?.state?.type === "service") {
      setSerVendor(true);
    } else {
      setProdVendor(true);
    }
  }, []);

  // RTK QUERY
  const [vendorListApi] = useVendorsListMutation();
  const [vendorEditApi] = useVendorsEditMutation();
  const [vendorViewApi] = useLazyVendorsViewQuery();
  const [vendorStatusApi] = useLazyVendorStatusListQuery();
  const [importApi] = useDownloadSampleExcelsMutation();
  const [uploadApi] = useDownloadVendorCreationMutation();
  const [statusApi] = useLazyProfile_statusQuery();
  const [exportApi] = useVendorExportMutation();

  const fileRef = useRef();
  const [file, setFile] = useState("");
  // console.log(file?.type);

  const [role, setRole] = useState("");
  const [roleErr, setRoleErr] = useState(false);
  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);

  const getList = (event, row, type, page) => {
    let formdata = new FormData();
    let params = `?page=${page}`;
    setPage(page);

    formdata.append("offering_type", type);
    setTab(type);
    setSearchValue(event);

    if (event?.length > 0) {
      formdata.append("search", event);
    }

    if (row) {
      setPageRow(row);
      formdata.append("rows", row);
    }

    vendorListApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          let temp = [];
          res?.lists?.map((item, ind) => {
            temp.push({
              ...item,
              offering_type: type,
            });
          });
          setList(res?.lists);
          setPagination(res?.pagination_meta);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };
  // EXPORT EXCEL
  const getAdminExports = () => {
    let formdata = new FormData();
    formdata.append("offering type", tab);
    exportApi(formdata)
      .unwrap()
      .then((res) => {
        if (res?.download_link) {
          const link = document.createElement("a");
          link.href = res?.download_link;
          link.setAttribute("download", "");
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const getStatus = (id, type, status, pageNo) => {
    // vendorStatusApi(id) // for vendor
    statusApi(id)
      .unwrap()
      .then((res) => {
        if (status == 1) {
          toast.success("The vendor Has Been De Activated");
        } else {
          toast.success("The vendor Has Been Activated");
        }

        getList("", "", type, pageNo);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getList("", "", tab, page);
  }, [page]);

  const allSelectSub = () => {
    let catListId = [];
    let catListName = [];
    if (selectALLSub) {
      categoryList?.map((item, ind) => {
        catListId.push(item?.id);
        catListName.push(item?.name);
        setCategoryName(item?.name);
      });

      setVendorCategories(catListId);
      setVendorCatogory(catListName);
    } else {
      setVendorCategories(catListId);
      setVendorCatogory(catListName);
      setCategoryName("");
    }
  };

  // SELECT CATEGORY
  const onCategorySelct = (e, id, type) => {
    let temp = [...vendorCategories];
    let temx = [...vendorCatogory];
    let inx = temp.indexOf(id);
    if (vendorCategories?.includes(id)) {
      temp.splice(inx, 1);
    } else {
      temp.push(id);
    }

    setVendorCategories(temp);

    if (vendorCatogory.includes(e)) {
      const listtemp = vendorCatogory.indexOf(e);
      const list = [...vendorCatogory];
      list.splice(listtemp, 1);
      setVendorCatogory(list);
    } else {
      setVendorCatogory([...vendorCatogory, e]);
    }

    setCategoryName(e);

    setList(categoryList);
    setSearchValueCategory("");
    if (type !== "check") {
      setDropDown(null);
    }
    // setParentCategory(item?.id);
  };

  // IMPORT EXCEL
  const getAdminImports = () => {
    let formdata = new FormData();
    formdata.append("type", 2);
    importApi(formdata)
      .unwrap()
      .then((res) => {
        if (res?.download_link) {
          const link = document.createElement("a");
          link.href = res?.download_link;
          link.setAttribute("download", "");
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
        }
        console.log("res", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // UPLOAD EXCEL
  const uploadExcel = () => {
    if (image?.length == 0) {
      setImageErr(true);
    }
    // else if (vendorOfferType?.length == 0) {
    //   setVendorOfferTypeErr(true);
    //   categoryNameErr(true);
    // }
    else {
      let formdata = new FormData();
      if (image?.name) {
        formdata.append("file", image);
      }

      setBtn(true);
      dispatch(saveLoader(true));
      uploadApi(formdata)
        .unwrap()
        .then((res) => {
          toast.success("Uploaded Successfully");
          setBtn(false);
          dispatch(saveLoader(false));
          console.log("reslist", res);
          setImage("");
          setImportList(false);
          setShowPopup(false);
        })
        .catch((err) => {
          setBtn(false);
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    }
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      {importList && (
        <div className="modal-popup">
          <div onClick={showPopupHander} className="back-close" />
          <div className="center-content p-4">
            <div className=" d-flex justify-content-end">
              <span>
                <button
                  onClick={() => {
                    showPopupHander();
                    setImage("");
                    setImageErr("");
                    setVendorOfferTypeErr("");
                    setOfferShow("");
                  }}
                  className="cust-btn"
                >
                  <HighlightOffIcon />
                </button>
              </span>
            </div>
            <div>
              <button
                className={`mb-3 cust-btn addbtn  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded `}
                onClick={() => getAdminImports()}
                // disabled={page == pagenation?.last_page ? true : false}
              >
                Sample Download
              </button>
            </div>
            {/* <div className="d-flex ac-jc">
              <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 me-2">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
                  Vendor Offering Type*
                </p>

                <div className="position-relative">
                  <input
                    placeholder="Vendor Offering Type"
                    type="text"
                    value={
                      vendorOfferType?.length > 0
                        ? vendorOfferType?.toString()
                        : "Select Vendor Offering Type"
                    }
                    onClick={() => {
                      setOfferShow(!offerShow);
                    }}
                    className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                  <button
                    className="drop_down cust-btn"
                    onClick={() => {
                      setOfferShow(!offerShow);
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                  {offerShow && (
                    <div
                      className="invisible-cont2 z-0"
                      onClick={() => setOfferShow(false)}
                    />
                  )}
                  <div
                    className={`${offerShow && "w-50 submenu_1 h-auto py-2"
                      } submenu_cont_1 overflow-scroll z-3`}
                  >
                    <div className="d-flex gap-1">
                      <input
                        className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                        placeholder="Search..."
                        onChange={(e) => searchVendorOffering(e.target.value)}
                        value={searchValueOffering}
                      />
                    </div>
                    {Vendorofferty?.map((item, ind) => {
                      return (
                        <button
                          className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                          onClick={() => {
                            setVendorOfferType(item);
                            setOfferShow(false);
                          }}
                        >
                          <button className="px-2 cust-btn text-start py-1 w-100 ">
                            {item}
                          </button>
                        </button>
                      );
                    })}
                  </div>
                </div>
                {vendorOfferType?.length == 0 && vendorOfferTypeErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Vendor Offer Type </p>
                  </div>
                )}
              </div>

              {(vendorOfferType == "Product" || vendorOfferType == "Both") && (
                <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                  <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                    Vendor Category*
                  </p>
                  <div className="position-relative">
                    <input
                      placeholder="Catogory"
                      type="text"
                      value={
                        vendorCatogory?.length > 0
                          ? vendorCatogory?.toString()
                          : "Select Category"
                      }
                      onClick={() => {
                        setDropDown(1);
                      }}
                      className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    />
                    <button
                      className="drop_down cust-btn"
                      onClick={() => {
                        setDropDown(1);
                      }}
                    >
                      <KeyboardArrowDownIcon />
                    </button>
                    {dropDown == 1 && (
                      <div
                        className="invisible-cont2 z-0"
                        onClick={() => setDropDown(null)}
                      />
                    )}
                    <div
                      className={`${dropDown == 1 && "submenu_1 py-2"
                        } submenu_cont_1 overflow-scroll z-3`}
                    >
                      <div className="d-flex gap-1">
                        <input
                          className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                          placeholder="Search..."
                          onChange={(e) => searchCategory(e.target.value)}
                          value={searchValueCategory}
                        />
                        <button
                          className="border-0 bg-transparent"
                          onClick={() => {
                            setSelectALLSub(!selectALLSub);
                            allSelectSub();
                          }}
                        >
                          All
                        </button>
                      </div>
                      {lists?.map((item, ind) => {
                        return (
                          <button
                            className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                            key={ind}
                          >
                            <button
                              className="px-2 cust-btn text-start py-1 w-100 "
                              onClick={() => {
                                onCategorySelct(item?.name, item?.id);
                              }}
                            >
                              {item?.name}
                            </button>
                            <button
                              className="px-2 cust-btn text-start"
                              style={{ fontSize: "large" }}
                              onClick={() => {
                                onCategorySelct(item?.name, item?.id, "check");
                              }}
                            >
                              {vendorCatogory?.includes(item?.name) ? (
                                <CheckCircleIcon className="primary" />
                              ) : (
                                <RadioButtonUncheckedIcon className="primary" />
                              )}
                            </button>
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  {categoryName?.length == 0 && categoryNameErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Select Vendor Category</p>
                    </div>
                  )}
                </div>
              )}
            </div> */}

            <p className="mt-3 d-flex ac-jc f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Import Admin List
            </p>
            <div className="pointerView w-100 mt-4 ac-jc">
              <div
                onClick={() => fileRef.current.click()}
                className="w-100 d-flex ac-jc"
              >
                {file === "" ? (
                  <img
                    src={uploadIcon}
                    onChange={(e) => setImage(e.target.files[0])}
                    className="mailImg"
                  />
                ) : (
                  <iframe
                    onClick={() => fileRef.current.click()}
                    src={file}
                    className="importExcel"
                  />
                )}
              </div>
              {file === "" && (
                <div
                  onClick={() => {
                    fileRef.current.click();
                  }}
                  className="w-100 d-flex ac-jc"
                >
                  <p className="primary1 w-100 f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 mt-1 d-flex ac-jc">
                    +Import List
                  </p>
                </div>
              )}
              {image?.length == 0 && imageErr && (
                <div className="d-flex ac-jc gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Upload File</p>
                </div>
              )}
              <div className="d-flex ac-jc">
                <button
                  onClick={() => uploadExcel()}
                  disabled={btn}
                  className={`
                  cust-btn addbtn mt-sm-4 ms-1 mt-4 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                    btn && "opacity-50"
                  }`}
                >
                  Submit
                </button>
              </div>
            </div>
            <input
              type="file"
              accept=".xlsx,"
              onChange={(e) => {
                setImage(e.target.files[0]);
              }}
              className="d-none"
              ref={fileRef}
            />
          </div>
        </div>
      )}

      <ProductSearchHeader />
      <div className="d-flex flex-md-row flex-column mt-5 w-100">
        <div className="w-lg-50 w-md-100 w-sm-100 w-xs-100 dailyStatus mt-4 rounded-3">
          <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Vendor Dashboard
          </p>
          <div className="totalSiteDesWid flex-xs-column flex-wrap d-flex mt-4 w-100">
            <div className="dashTotalDesTotalSites">
              <div className="d-flex">
                <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                  Total Sites
                </p>
                <div className="bg-green1 p-1 mt-2 rounded-3 dotColor" />
              </div>
              <p className="ms-lg-2 ms-sm-2 ms-2 ms-xs-2 f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                08
              </p>
            </div>
            <div className="dashTotalDesTotalSites ms-sm-2 ms-md-3 ms-lg-4">
              <div className="d-flex">
                <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                  Total Sites
                </p>
                <div className="bg-red rounded-3 mt-2 p-1 dotColor" />
              </div>
              <p className="ms-lg-2 ms-sm-2 ms-2 ms-xs-2 f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                08
              </p>
            </div>
            <div className="dashTotalDesTotalSites ms-sm-2 ms-md-3 ms-lg-4">
              <div className="d-flex">
                <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                  Total Sites
                </p>
                <div className="bg-yellow rounded-3 mt-2 p-1 dotColor" />
              </div>
              <p className="ms-lg-2 ms-sm-2 ms-2 ms-xs-2 f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                08
              </p>
            </div>
          </div>
        </div>
        <div className="w-lg-50 mt-4 w-md-100 w-sm-100 w-xs-100 dailyStatus ms-lg-4 rounded-3">
          <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Vendor Dashboard
          </p>
          <div className="totalSiteDesWid flex-xs-column flex-wrap d-flex mt-4">
            <div className="dashTotalDesTotalSites1">
              <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15">
                Total Sites
              </p>
              <p className="ms-lg-2 ms-sm-2 ms-2 ms-xs-2 f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                08
              </p>
            </div>
            <div className="dashTotalDesTotalSites1 ms-sm-2 ms-md-3 ms-lg-4">
              <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15">
                Total Sites
              </p>
              <p className="ms-lg-2 ms-sm-2 ms-2 ms-xs-2 f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                08
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-row flex-column ac-jb mt-4">
        <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Vendor Management
        </p>
        <div className="d-flex ac-jc">
          <div className="d-flex w-xs-100 mt-xs-5 position-relative">
            <input
              className="cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
              onChange={(e) => getList(e.target.value, "", tab, 1)}
            />
            <Search className="gray f1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-19 fs-xl-19 fs-xxl-20 mt-1 searchVendor" />
          </div>
          <div
            onClick={() => navigate("/vendor-management")}
            className="d-flex ms-3 pointerView"
          >
            <p className="f3 fs-xs-10 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 primary">
              View Details
            </p>
            <ArrowCircleRightOutlinedIcon className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary ms-1" />
          </div>
        </div>
      </div>
      <div className="w-100 d-flex mt-2 ac-jb flex-wrap">
        <div className="d-flex mt-3">
          <div
            onClick={() => {
              getList("", "", 4, 1);
            }}
            className={`${
              tab == 4
                ? "pointerView border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } `}
          >
            <p
              className={`${
                tab == 4 ? "black" : "gray"
              } pointerView f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              All Vendor
            </p>
          </div>
          <div
            onClick={() => {
              getList("", "", 1, 1);
            }}
            className={`${
              tab == 1
                ? "pointerView border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-4`}
          >
            <p
              className={`${
                tab == 1 ? "black" : "gray"
              } pointerView f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Product Supplier
            </p>
          </div>
          <div
            onClick={() => {
              getList("", "", 2, 1);
            }}
            className={`${
              tab == 2
                ? "pointerView border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-4`}
          >
            <p
              className={`${
                tab == 2 ? "black" : "gray"
              } pointerView f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Decorator
            </p>
          </div>
          <div
            onClick={() => {
              getList("", "", 3, 1);
            }}
            className={`${
              tab == 3
                ? "pointerView border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-4`}
          >
            <p
              className={`${
                tab == 3 ? "black" : "gray"
              } pointerView f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Both
            </p>
          </div>
        </div>
        <div className="w-xs-100 d-flex ac-jc">
          <div
            // role="button"
            onClick={() => getAdminExports()}
            className="pointerView d-flex mt-4 me-3"
          >
            <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Export
            </p>
          </div>
          <div
            onClick={() => setImportList(!importList)}
            className="pointerView d-flex mt-4 me-3"
          >
            <UploadIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Import
            </p>
          </div>
          {/* <div className="pointerView d-flex mt-4 me-3">
            <ListAltRoundedIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Download List
            </p>
          </div> */}
          <button
            onClick={() => navigate("/add-vendor")}
            className="cust-btn addbtn mt-sm-3 ms-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            + Add Vendor
          </button>
        </div>
      </div>
      {tab == 4 && (
        <AllVendor
          getList={getList}
          page={page}
          pageRow={pageRow}
          pagenation={pagenation}
          list={list}
          getStatus={getStatus}
          uploadExcel={uploadExcel}
        />
      )}
      {tab == 1 && (
        <ProductVendor
          getList={getList}
          page={page}
          pageRow={pageRow}
          pagenation={pagenation}
          list={list}
          getStatus={getStatus}
          uploadExcel={uploadExcel}
        />
      )}
      {tab == 2 && (
        <ServiceVendor
          getList={getList}
          page={page}
          pageRow={pageRow}
          pagenation={pagenation}
          list={list}
          getStatus={getStatus}
        />
      )}
      {tab == 3 && (
        <BothVendor
          list={list}
          getList={getList}
          page={page}
          pageRow={pageRow}
          pagenation={pagenation}
          getStatus={getStatus}
        />
      )}
    </div>
  );
};
export default VendorDashboard;
