import { animatedImg, searchIcon, uploadIcon } from "../assets/img";
import { Search } from "@mui/icons-material";
import { selecLibList } from "../redux/api/DummyJson";
import { useLocation, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import "bootstrap/js/src/modal";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import {
  useVendorLibraryEditMutation,
  useVendorLibraryListMutation,
} from "../redux/api/api";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Tab } from "react-bootstrap";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";

const ViewLibraryList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location);
  const [list, setList] = useState([]);
  const [lists, setLists] = useState([]);
  const [types, setTypes] = useState("");
  const [search, setSearch] = useState("");
  const [selectitem, setSelectItem] = useState([]);
  const [image, setImage] = useState("");
  const [libId, setLibId] = useState();
  const dispatch = useDispatch();
  const [imageTitle, setImageTitle] = useState("");
  const [btn, setBtn] = useState(false);

  // const vendors = JSON.parse(localStorage.getItem("user"));
  const fileRef = useRef();

  const [showPopup, setShowPopup] = useState(false);

  // RTK QUERY
  const [vendorLibraryListApi] = useVendorLibraryListMutation();
  const [editLibraryVendor] = useVendorLibraryEditMutation();

  // VENDOR LIBRARY LIST
  const getLibraryList = (id, type) => {
    let formdata = new FormData();
    formdata.append(
      "type",
      type == 0 ? "vendor" : type == 1 ? "product" : type == 2 ? "admin" : " "
    );
    formdata.append("vendor_id", id);
    vendorLibraryListApi(formdata)
      .unwrap()
      .then((res) => {
        setList(res?.libraries);
        setLists(res?.libraries);
        // console.log("ressss", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (location?.state?.vendorID) {
      getLibraryList(location?.state?.vendorID, location?.state?.type);
    }

    setTypes(location?.state?.type);
  }, []);

  const Submit = () => {
    // console.log('datas', );
    let formdata = new FormData();
    if (image?.name) {
      formdata.append("file", image);
    }
    formdata.append("library_id", libId);
    formdata.append("vendor_id", location?.state?.data?.id);
    formdata.append("file_title", imageTitle);
    if (types == 0 || types == 1) {
      formdata.append("is_product_image", types);
      formdata.append("is_admin_image", 2);
    } else {
      formdata.append("is_admin_image", 1);
      formdata.append("is_product_image", 2);
    }

    setBtn(true);
    dispatch(saveLoader(true));
    editLibraryVendor(formdata)
      .unwrap()
      .then((res) => {
        setBtn(false);
        dispatch(saveLoader(false));
        // console.log("reslist", res);
        setShowPopup(false);
        setImage("");
        setImageTitle("");
        setLibId("");
        setTypes(location?.state?.type);
        getLibraryList(location?.state?.data?.id, location?.state?.type);
      })
      .catch((err) => {
        setBtn(false);
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  const toggleShowPopup = (item) => {
    // console.log("toggleShowPopup", item);
    setShowPopup(!showPopup);
    setSelectItem(item);
    setImage(item?.file_url);
    setImageTitle(item?.file_title);
    setLibId(item?.id);
  };
  // GET SEARCH
  const getSearch = (event) => {
    setSearch(event);
    if (event?.length > 0) {
      let filter = lists?.filter((i) =>
        i?.file_title?.toLowerCase().includes(event?.toLowerCase())
      );
      setList(filter);
    } else {
      setList(lists);
    }
  };

  return (
    <>
      {showPopup && (
        <div className="modal-popup w-100 ">
          <div
            className="w-70 ms-xxl-5"
            style={{
              background: "#fff",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: " 7px 7px 15px -1px #d1d6eb",
              WebkitBoxShadow: "7px 7px 15px -1px #d1d6eb",
            }}
          >
            <div className="d-flex ac-jb">
              <p className="w-100 f2 mt-4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 gray">
                Add Image to
                <span className="f2 mt-4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 text-black">
                  {types == 1
                    ? " Product"
                    : types == 0
                    ? " Vendor"
                    : types == 2
                    ? " Admin"
                    : ""}
                </span>
              </p>
              {/* <button
            className={`
cust-btn addbtn mt-sm-4  mt-4 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${btn && "opacity-50"
                }`}
            onClick={toggleShowPopup}
        >
            Close
        </button> */}
              <button
                onClick={() => {
                  toggleShowPopup();
                  setImageTitle("");
                  setImage("");
                }}
                className="cust-btn"
              >
                <HighlightOffIcon />
              </button>
            </div>

            <div>
              {image === "" ? (
                <div className="pointerView w-100 mt-4">
                  <div
                    onClick={() => fileRef.current.click()}
                    className="w-100 w-md-100 d-flex ac-jc"
                  >
                    <img src={uploadIcon} className="uploadImgLib" />
                  </div>
                  <div className="w-100 w-md-100 d-flex ac-jc">
                    <p className="black w-100 f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 mt-1 d-flex ac-jc">
                      Drag and drop to upload or Click
                    </p>
                  </div>
                  <div className="w-100 w-md-100 d-flex ac-jc">
                    <p className="black w-100 f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 mt-1 d-flex ac-jc">
                      here to upload
                    </p>
                  </div>
                </div>
              ) : (
                <div className="w-100 w-md-100  mt-4 d-flex ac-jb">
                  <div
                    //   onClick={() => fileRef.current.click()}
                    className="w-100  w-md-100 d-flex ac-jc"
                  >
                    <div className="w-20">
                      <input
                        type="file"
                        onChange={(e) => setImage(e.target.files[0])}
                      />

                      {image?.type === "application/pdf" ? (
                        <iframe
                          src={URL.createObjectURL(image)}
                          data-bs-toggle="modal"
                          data-bs-target="#imageExample"
                          className="custom-w-h rounded-3 mt-3"
                        />
                      ) : !image?.name && image?.endsWith(".pdf") ? (
                        <iframe
                          src={image}
                          data-bs-toggle="modal"
                          data-bs-target="#imageExample"
                          // className="custom-w-h rounded-3 mt-3"
                          className="rounded-3 mt-3"
                          style={{ height: "300px" }}
                        />
                      ) : (
                        <img
                          src={image?.name ? URL.createObjectURL(image) : image}
                          alt="Image"
                          data-bs-toggle="modal"
                          data-bs-target="#imageExample"
                          className="custom-w-h rounded-3 mt-3"
                        />
                      )}
                      {/* 
                      <img
                        src={image?.name ? URL.createObjectURL(image) : image}
                        className="w-100 mt-2 "
                        style={{ maxHeight: "100px" }}
                      /> */}
                    </div>
                    <p className="f2 w-100 w-md-100 fs-xs-13 ms-2 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 primary1">
                      {image?.name}
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className="w-100 mt-4">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Image Title
              </p>
              <input
                placeholder="Enter Image Title"
                value={imageTitle}
                onChange={(e) => setImageTitle(e.target.value)}
                className="editBtn rounded-3 mt-3 p-2 w-100 w-md-100 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
            </div>
            <div className="w-100 mt-4">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Type
              </p>
              <select
                onChange={(e) => setTypes(e.target.value)}
                value={types}
                className="w-100 editBtnSelect mt-3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3"
              >
                <option value={""} disabled>
                  Select Type
                </option>
                <option value={1}>Product Library</option>
                <option value={0}>Vendor Library</option>
                <option value={2}>Admin Library</option>
              </select>
            </div>
            <div className="w-100 d-flex ac-jc gap-3 w-md-100">
              {types !== "view" && (
                <button
                  className={`cust-btn addbtn mt-sm-4 ms-1 mt-4 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                    btn && "opacity-50"
                  }`}
                  onClick={() => Submit()}
                  disabled={btn}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="dashRightView p-5 home_section trans">
        <ProductSearchHeader />
        <div className="d-flex justify-content-between ac-jb  w-100 mt-5">
          <div className="d-flex align-items-center primary">
            <KeyboardBackspaceIcon
              className="me-2"
              onClick={() =>
                navigate("/product-vendor-details", {
                  state: { tab: 2, data: location?.state?.data },
                })
              }
            />
            <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary ">
              {location?.state?.type == 0
                ? "Vendor"
                : location?.state?.type == 1
                ? "Product"
                : location?.state?.type == 2
                ? "Admin"
                : ""}{" "}
              Library List
            </p>
          </div>
          <div className="d-flex justify-content-end  flex-wrap  ">
            <div className="d-flex justify-content-end">
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont px-4 mt-2">
                Total Count :{lists?.length}
              </p>
            </div>
            <div className="d-flex flex-md-row flex-column gap-2">
              <div className="d-flex border-search">
                <img src={searchIcon} alt="img" className="searchiConImg" />
                <input
                  className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                  placeholder="Search"
                  onChange={(e) => getSearch(e.target.value)}
                  value={search}
                />
              </div>
              <button
                onClick={() =>
                  navigate("/product-vendor-details", {
                    state: { tab: 2, data: location?.state?.data },
                  })
                }
                className="cust-btn addbtn  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
              >
                + Add Library
              </button>
              <button
                className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
                onClick={(e) => {
                  getSearch("");
                  getLibraryList(
                    location?.state?.vendorID,
                    location?.state?.type
                  );
                }}
              >
                <CachedIcon />
              </button>
            </div>
          </div>
        </div>
        <div className="d-flex flex-wrap">
          {list?.map((item) => {
            return (
              <div
                className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
                // onClick={() => navigate('/add-new-product/products/product-detail')}
              >
                <div className="prod-list-box">
                  <div className="cust-btn d-flex ac-jc rounded-3">
                    {item?.file_url?.endsWith(".pdf") ? (
                      <iframe
                        src={item?.file_url}
                        data-bs-toggle="modal"
                        data-bs-target="#imageExample"
                        className="custom-w-h rounded-3"
                      />
                    ) : (
                      <img
                        src={item?.file_url}
                        alt="Image"
                        data-bs-toggle="modal"
                        data-bs-target="#imageExample"
                        className="custom-w-h rounded-3"
                      />
                    )}
                    <div
                      className="modal fade"
                      id="imageExample"
                      tabIndex="-1"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-body">
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                            <img src={item?.file_url} className="w-100" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex ac-jb">
                    <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                      {item?.file_title}
                    </p>
                    <p
                      className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2 primary pointer"
                      onClick={() => toggleShowPopup(item)}
                    >
                      <EditIcon style={{ fontSize: "14px" }} />
                    </p>
                  </div>
                  {/* <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                  {item?.description}
                </p>
                <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
                  {item?.price}
                </p>
                <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
                  {item?.data}
                </p> */}
                </div>
              </div>
            );
          })}
        </div>
        <div className="w-90 ac-jb flex-md-row flex-column d-flex mt-5">
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Back
          </button>
          {location?.state?.type === "edit" && (
            <button className=" cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
              Update Details
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewLibraryList;
