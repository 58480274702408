import React, { useEffect, useState } from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import GroupsIcon from "@mui/icons-material/Groups";
import InventoryIcon from "@mui/icons-material/Inventory";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import SideBarComp from "./SideBarComp";
import SideBarComp2 from "./SideBarComp2";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { animatedImg, logos } from "../../assets/img";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CategoryIcon from "@mui/icons-material/Category";
import { ArrowBackIosNewSharp, Support } from "@mui/icons-material";
import { useLazyLogoutQuery } from "../../redux/api/api";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import ReceiptIcon from "@mui/icons-material/Receipt";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import LogoutPopup from "../Popup/LogoutPopup";

const ProfileNavBar2 = ({ setSaidList }) => {
  const [saideBarShow, setSaidBarShow] = useState(true);
  const [logoutPopupShow, setLogoutPopupShow] = useState(false);
  const [customerDash, setCustomerDash] = useState(false);
  const [enterprises, setEnterprises] = useState(false);
  const [helpDeskDown, setHelpDeskDown] = useState(false);
  const [invoice, setInvoice] = useState(false);
  const [navState, setNavState] = useState(undefined);
  const [activity, setActivity] = useState(false);
  const [notify, setNotify] = useState(false);
  // All Dropdowns
  const [roles, setRoles] = useState(false);
  const [ourPacks, setOurPacks] = useState(false);
  const [enableDown, setEnableDown] = useState(false);
  const [prodManagement, setProdManagement] = useState(false);
  const [projectManagement, setProjectManagement] = useState(false);
  const [customerDown, setCustomerDown] = useState(false);
  const [projectAttributeDown, setProjectAttributeDown] = useState(false);
  const [adminRequest, setAdminRequst] = useState(false);
  const [siteManagement, setSiteManagement] = useState(false);
  const [production, setProduction] = useState(false);
  const [manageOrder, setManageOrder] = useState(false);

  const [support, setSupport] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [btn, setBtn] = useState(false);
  // console.log("Split________", splitLocation);

  // RTK QUERY

  const [logout] = useLazyLogoutQuery();

  const submitLogout = () => {
    setBtn(true);
    logout()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          localStorage.clear();
          setTimeout(() => {
            window.location.reload();
          }, 400);
        }
        setBtn(false);
      })
      .catch((err) => {
        console.log("err");
        setBtn(false);
      });
  };

  useEffect(() => {
    if (splitLocation[1] === "") {
      navigate("/home");
    }
  }, []);

  const toggle_nav = () => {
    setSaidBarShow(!saideBarShow);
  };

  const toggleLogoutPopup = () => {
    setLogoutPopupShow(!logoutPopupShow);
  };

  return (
    <div
      className={`${saideBarShow ? "close_sidebar trans" : "sidebar trans"}`}
    >
      <header>
        <div className="image_text">
          {/* <span className="image">
            <img src={animatedImg} />
          </span> */}
          <div className="header_text">
            <span
              className={`${
                saideBarShow
                  ? "f4 fs-10"
                  : "f4 fs-sm-10 fs-md-25 fs-lg-27 fs-xl-29 fs-xxl-30"
              } name primary trans `}
            >
              {/* Merchhq */}
              <img src={logos} className="logo" alt="" />
            </span>
          </div>
        </div>
        <div
          style={{
            height: !saideBarShow ? "30px" : "20px",
            width: !saideBarShow ? "30px" : "20px",
          }}
          className="toggle cp d-flex ac-jc trans"
          onClick={() => {
            toggle_nav();
            setEnableDown(false);
            setProdManagement(false);
            setProjectManagement(false);
            setCustomerDown(false);
            setSiteManagement(false);
            setProduction(false);
          }}
        >
          <ArrowForwardIosIcon
            style={{
              transform: `rotateY(${!saideBarShow ? "180deg" : "0deg"})`,
              transition: "0.4s all linear",
            }}
            className={`${
              !saideBarShow
                ? " fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20"
                : "fs-10"
            } f5 text-light`}
          />
          {/* {saideBarShow ? (
            <ArrowForwardIosIcon
              className="f5 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19
           fs-xxl-20 text-light"
            />
          ) : (
            <ArrowBackIosNewSharp
              className="f5 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19
           fs-xxl-20 text-light"
            />
          )} */}
        </div>
      </header>
      <div className="menu_bar mt-4 overflow-x-hidden overflow-scroll">
        <div className="menu">
          <ul className="menu_links">
            <li
              className="nav_link trans"
              onClick={() => {
                navigate("/home");
                setNavState("navlink");
                setEnableDown(false);
                setProdManagement(false);
                setProjectManagement(false);
                setCustomerDown(false);
                setSiteManagement(false);
                setProduction(false);
                setSaidBarShow(false);
                setOurPacks(false);
                setRoles(false);
                setAdminRequst(false);
              }}
            >
              <p
                className={` ${
                  navState === "navlink" && splitLocation[1] === "home"
                    ? "primary bg-lt-blue1 f4"
                    : "gray f1"
                } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
              >
                <DashboardIcon className="icon" />
                <span className="text text-nowrap f2 fs-xs-10 trans fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Dashboard
                </span>
              </p>
            </li>
            <li
              className="nav_link trans"
              onClick={() => {
                navigate("/profile");
                setNavState("navlink");
                setEnableDown(false);
                setProdManagement(false);
                setProjectManagement(false);
                setCustomerDown(false);
                setSiteManagement(false);
                setProduction(false);
                setSaidBarShow(false);
                setOurPacks(false);
                setRoles(false);
                setAdminRequst(false);
              }}
            >
              <p
                className={` ${
                  (navState === "navlink" && splitLocation[1] === "profile") ||
                  splitLocation[1] === "add-profile" ||
                  splitLocation[1] === "view-profile" ||
                  splitLocation[1] === "edit-profile"
                    ? "primary bg-lt-blue1 f4"
                    : "gray f1"
                } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
              >
                <PersonIcon className="icon" />
                <span className="text text-nowrap f2 fs-xs-10 trans fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Profile
                </span>
              </p>
            </li>
            <li
              className="nav_link trans"
              onClick={() => {
                setRoles(!roles);
                setNavState("navlink");
                setEnableDown(false);
                setProdManagement(false);
                setProjectManagement(false);
                setCustomerDown(false);
                setSiteManagement(false);
                setProduction(false);
                setSaidBarShow(false);
                setAdminRequst(false);
                setOurPacks(false);
              }}
            >
              <p
                className={` ${
                  roles ||
                  splitLocation[1] === "cateogry-roles" ||
                  splitLocation[1] === "role-profile"
                    ? "primary f4"
                    : ""
                } trans gray f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
              >
                <PermContactCalendarIcon className=" icon" />
                <span className="text text-nowrap f2 fs-xs-10 trans fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Department/ Role
                </span>
              </p>
            </li>
            {roles && (
              <li
                className="nav_link trans"
                onClick={() => {
                  navigate("/cateogry-roles");
                }}
              >
                <p
                  className={` ${
                    (navState === "navlink" &&
                      splitLocation[1] === "cateogry-roles") ||
                    splitLocation[1] === "cateogry-roles-view"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="gray invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Category role
                  </span>
                </p>
              </li>
            )}
            {roles && (
              <li
                className="nav_link trans"
                onClick={() => {
                  navigate("/role-profile");
                }}
              >
                <p
                  className={` ${
                    (navState === "navlink" &&
                      splitLocation[1] === "role-profile") ||
                    splitLocation[1] === "add-role-profile" ||
                    splitLocation[1] === "view-role-profile" ||
                    splitLocation[1] === "edit-role-profile" ||
                    splitLocation[1] == "role-profiles"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="gray invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Roles
                  </span>
                </p>
              </li>
            )}
            <li
              className="nav_link trans"
              onClick={() => {
                // navigate("/preset-packs");
                // setNavState("navlink");
                setEnableDown(false);
                setOurPacks(!ourPacks);
                setProdManagement(false);
                setProjectManagement(false);
                setCustomerDown(false);
                setSiteManagement(false);
                setProduction(false);
                setSaidBarShow(false);
                setRoles(false);
                setAdminRequst(false);
              }}
            >
              <p
                className={` ${
                  ourPacks ||
                  splitLocation[1] === "event-category" ||
                  splitLocation[1] === "our-packs"
                    ? "primary f4"
                    : ""
                } trans gray f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
              >
                <LocalGroceryStoreIcon className=" icon" />
                <span className="text text-nowrap f2 fs-xs-10 trans fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  {" "}
                  Our Packs
                </span>
              </p>
            </li>
            {ourPacks && (
              <li
                className="nav_link trans"
                onClick={() => {
                  navigate("/event-category");
                }}
              >
                <p
                  className={` ${
                    (navState === "navlink" &&
                      splitLocation[1] === "event-category") ||
                    splitLocation[1] === "our-packs"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="gray invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Event Category
                  </span>
                </p>
              </li>
            )}
            {/* {ourPacks && (
              <li
                className="nav_link trans"
                onClick={() => {
                  navigate("/event-categories");
                }}
              >
                <p
                  className={` ${
                    (navState === "navlink" &&
                      splitLocation[1] === "event-categories") ||
                    splitLocation[1] === "our-packs"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="gray invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Event Categories
                  </span>
                </p>
              </li>
            )} */}
            {ourPacks && (
              <li
                className="nav_link trans"
                onClick={() => {
                  navigate("/preset-packs");
                }}
              >
                <p
                  className={` ${
                    (navState === "navlink" &&
                      splitLocation[1] === "preset-packs") ||
                    splitLocation[1] === "preset-packs"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="gray invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Preset Packs
                  </span>
                </p>
              </li>
            )}
            <li
              className="nav_link trans"
              onClick={() => {
                setEnableDown(!enableDown);
                setHelpDeskDown(false);
                // navigate("/vendor-home");
                setProdManagement(false);
                setProjectManagement(false);
                setCustomerDown(false);
                setSiteManagement(false);
                setProduction(false);
                setSaidBarShow(false);
                setOurPacks(false);
                setRoles(false);
                setAdminRequst(false);
              }}
            >
              <p
                className={` ${
                  enableDown ||
                  splitLocation[1] === "vendor-home" ||
                  splitLocation[1] === "add-vendor" ||
                  splitLocation[1] === "vendor-management" ||
                  splitLocation[1] === "vendor-settings" ||
                  splitLocation[1] === "vendor-details"
                    ? "primary f4"
                    : ""
                } trans gray f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
              >
                <SupervisorAccountIcon className=" icon" />
                <span className="text text-nowrap f2 fs-xs-10 trans fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Vendor Management
                </span>
              </p>
            </li>
            {enableDown && (
              <li
                className="nav_link trans"
                onClick={() => {
                  navigate("/vendor-home");
                  setNavState("subdrop1");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "vendor-home" ||
                    splitLocation[1] === "add-vendor"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="gray invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Dashboard
                  </span>
                </p>
              </li>
            )}
            {enableDown && (
              <li
                className="nav_link trans"
                onClick={() => {
                  navigate("/vendor-management");
                  setNavState("subdrop1");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "vendor-management" ||
                    splitLocation[1] === "vendor-details"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="  invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Manage Vendor
                  </span>
                </p>
              </li>
            )}
            {enableDown && (
              <li
                className="nav_link trans"
                onClick={() => {
                  navigate("/vendor-settings");
                  setNavState("subdrop1");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "vendor-settings"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="gray invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Vendor Settings
                  </span>
                </p>
              </li>
            )}
            <li
              className="nav_link trans"
              onClick={() => {
                setProdManagement(!prodManagement);
                setProjectManagement(false);
                setHelpDeskDown(false);
                // navigate("/product-feed");
                setEnableDown(false);
                setCustomerDown(false);
                setSiteManagement(false);
                setProduction(false);
                setSaidBarShow(false);
                setOurPacks(false);
                // setNavState('navlink2')
                setRoles(false);
                setAdminRequst(false);
              }}
            >
              <p
                className={` ${
                  prodManagement ||
                  splitLocation[1] === "product-feed" ||
                  splitLocation[1] === "product-library" ||
                  splitLocation[1] === "pricebook-library" ||
                  splitLocation[1] === "pre-production" ||
                  splitLocation[1] === "category-setting" ||
                  splitLocation[1] === "navigation" ||
                  splitLocation[1] === "decorative-method" ||
                  splitLocation[1] === "hsn-settings" ||
                  splitLocation[1] === "attributes" ||
                  splitLocation[1] === "tags" ||
                  splitLocation[1] === "event-tags" ||
                  splitLocation[1] === "icon-tags"
                    ? "primary f4"
                    : "gray f1"
                } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
              >
                <WarehouseIcon className="icon" />
                <span className="text text-nowrap f2 fs-xs-10 trans fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Product Management
                </span>
              </p>
            </li>
            {prodManagement && (
              <li
                className="nav_link trans"
                onClick={() => {
                  navigate("/product-feed");
                  setNavState("subdrop2");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "product-feed"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="gray invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Feed
                  </span>
                </p>
              </li>
            )}
            {prodManagement && (
              <li
                className="nav_link trans"
                onClick={() => {
                  navigate("/product-library");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "product-library"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="  invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Product Library
                  </span>
                </p>
              </li>
            )}
            {prodManagement && (
              <li
                className="nav_link trans"
                onClick={() => {
                  navigate("/pricebook-library");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "vendor-settings"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="gray invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Pricebook Library
                  </span>
                </p>
              </li>
            )}
            {prodManagement && (
              <li
                className="nav_link trans"
                onClick={() => {
                  navigate("/pre-production");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "pre-production"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Pre Production
                  </span>
                </p>
              </li>
            )}
            {prodManagement && (
              <li
                className="nav_link trans"
                onClick={() => {
                  navigate("/category-setting");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "category-setting"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Category Setting
                  </span>
                </p>
              </li>
            )}
            {prodManagement && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  navigate("/navigation-list");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "navigation-list"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="gray invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Navigation
                  </span>
                </p>
              </li>
            )}
            {prodManagement && (
              <li
                className="nav_link trans"
                onClick={() => {
                  navigate("/decorative-method");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "decorative-method"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="  invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Decoration Method
                  </span>
                </p>
              </li>
            )}
            {prodManagement && (
              <li
                className="nav_link trans"
                onClick={() => {
                  navigate("/hsn-settings");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "hsn-settings"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="gray invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    HSN Settings
                  </span>
                </p>
              </li>
            )}
            {prodManagement && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  navigate("/attributes");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "attributes"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="gray invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Attributes
                  </span>
                </p>
              </li>
            )}
            {prodManagement && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  navigate("/tags");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "tags"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="gray invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Tags
                  </span>
                </p>
              </li>
            )}
            {prodManagement && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  navigate("/event-tags");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "event-tags"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="gray invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Event Tags
                  </span>
                </p>
              </li>
            )}
            {prodManagement && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  navigate("/icon-tags");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "icon-tags"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="gray invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Icon Tags
                  </span>
                </p>
              </li>
            )}
            {prodManagement && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  navigate("/currencies");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "currencies"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="gray invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Currencies
                  </span>
                </p>
              </li>
            )}
            {prodManagement && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  navigate("/colours");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "colours"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="gray invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Colours
                  </span>
                </p>
              </li>
            )}

            <li
              className="nav_link trans"
              onClick={() => {
                setProjectManagement(!projectManagement);
                setHelpDeskDown(false);
                // navigate("/product-feed");
                setEnableDown(false);
                setCustomerDown(false);
                setSiteManagement(false);
                setProduction(false);
                setSaidBarShow(false);
                setOurPacks(false);
                // setNavState('navlink2')
                setRoles(false);
                setAdminRequst(false);
                setProjectAttributeDown(false);
              }}
            >
              <p
                className={` ${
                  projectManagement ||
                  splitLocation[1] === "attributes-project" ||
                  splitLocation[1] === "tags-project" ||
                  splitLocation[1] === "project-partner" ||
                  splitLocation[1] === "project-lead-sources" ||
                  splitLocation[1] === "project-type-of-order" ||
                  splitLocation[1] === "project-location-name" ||
                  splitLocation[1] === "box" ||
                  splitLocation[1] === "charge-shipping"
                    ? "primary f4"
                    : "gray f1"
                } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
              >
                <InventoryIcon className="icon" />
                <span className="text text-nowrap f2 fs-xs-10 trans fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Project Management
                </span>
              </p>
            </li>

            {projectManagement && (
              <>
                <li
                  className="nav_link trans trans"
                  onClick={() => {
                    setProdManagement(false);
                    setProjectAttributeDown(!projectAttributeDown);
                    setEnableDown(false);
                    setHelpDeskDown(false);
                  }}
                >
                  <p
                    className={` ${"gray f1"} trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
                  >
                    <GroupsIcon className="icon invisible" />
                    <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Project Attributes
                      <ArrowDropDownIcon />
                    </span>
                  </p>
                </li>
              </>
            )}

            {projectAttributeDown && (
              <>
                <li
                  className="nav_link trans trans"
                  onClick={() => {
                    setProdManagement(false);
                    setEnableDown(false);
                    setEnterprises(false);
                    setHelpDeskDown(false);
                    setCustomerDash(false);
                    navigate("/overview-attributes-project");
                  }}
                >
                  <p
                    className={` ${
                      splitLocation[1] === "overview-attributes-project"
                        ? "primary bg-lt-blue1 f4"
                        : "gray f1"
                    } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
                  >
                    <GroupsIcon className="icon invisible" />
                    <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Overview Attributes
                    </span>
                  </p>
                </li>
                <li
                  className="nav_link trans trans"
                  onClick={() => {
                    setProdManagement(false);
                    setEnableDown(false);
                    setEnterprises(false);
                    setHelpDeskDown(false);
                    setCustomerDash(false);
                    navigate("/presentation-attributes-project");
                  }}
                >
                  <p
                    className={` ${
                      splitLocation[1] === "presentation-attributes-project"
                        ? "primary bg-lt-blue1 f4"
                        : "gray f1"
                    } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
                  >
                    <GroupsIcon className="icon invisible" />
                    <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Presentation Attributes
                    </span>
                  </p>
                </li>
                <li
                  className="nav_link trans trans"
                  onClick={() => {
                    setProdManagement(false);
                    setEnableDown(false);
                    setEnterprises(false);
                    setHelpDeskDown(false);
                    setCustomerDash(false);
                    navigate("/estimate-attributes-project");
                  }}
                >
                  <p
                    className={` ${
                      splitLocation[1] === "estimate-attributes-project"
                        ? "primary bg-lt-blue1 f4"
                        : "gray f1"
                    } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
                  >
                    <GroupsIcon className="icon invisible" />
                    <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Estimate Attributes
                    </span>
                  </p>
                </li>
                <li
                  className="nav_link trans trans"
                  onClick={() => {
                    setProdManagement(false);
                    setEnableDown(false);
                    setEnterprises(false);
                    setHelpDeskDown(false);
                    setCustomerDash(false);
                    navigate("/sales-order-attributes-project");
                  }}
                >
                  <p
                    className={` ${
                      splitLocation[1] === "sales-order-attributes-project"
                        ? "primary bg-lt-blue1 f4"
                        : "gray f1"
                    } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
                  >
                    <GroupsIcon className="icon invisible" />
                    <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Sales Order Attributes
                    </span>
                  </p>
                </li>
              </>
            )}

            {projectManagement && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProjectAttributeDown(false);
                  navigate("/tags-project");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "tags-project"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="gray invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Project Tags
                  </span>
                </p>
              </li>
            )}
            {projectManagement && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  navigate("/project-partner");
                  setProjectAttributeDown(false);
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "project-partner"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="gray invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Project Partner
                  </span>
                </p>
              </li>
            )}
            {projectManagement && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProjectAttributeDown(false);
                  navigate("/project-lead-sources");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "project-lead-sources"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="gray invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Project Lead Sources
                  </span>
                </p>
              </li>
            )}

            {projectManagement && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProjectAttributeDown(false);
                  navigate("/project-type-of-order");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "project-type-of-order"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="gray invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Project Type of Order
                  </span>
                </p>
              </li>
            )}

            {projectManagement && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProjectAttributeDown(false);
                  navigate("/project-location-name");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "project-location-name"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="gray invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Project Location Name
                  </span>
                </p>
              </li>
            )}

            {projectManagement && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProjectAttributeDown(false);
                  navigate("/box");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "box"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="gray invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Box
                  </span>
                </p>
              </li>
            )}
            {projectManagement && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProjectAttributeDown(false);
                  navigate("/charge-shipping");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "charge-shipping"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
                >
                  <SupervisorAccountIcon className="gray invisible trans f1 fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 icon" />
                  <span className="  text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Shipping Charge
                  </span>
                </p>
              </li>
            )}

            <li
              className="nav_link trans trans"
              onClick={() => {
                setCustomerDash(!customerDash);
                setEnterprises(false);
                setCustomerDown(false);
                setHelpDeskDown(false);
                // navigate("/customer-list");
                setProdManagement(false);
                setEnableDown(false);
                setSiteManagement(false);
                setProduction(false);
                setSaidBarShow(false);
                setOurPacks(false);
                setRoles(false);
                setAdminRequst(false);
              }}
            >
              <p
                className={` ${
                  customerDown ||
                  splitLocation[1] === "customer-dashboard" ||
                  splitLocation[1] === "customer-list" ||
                  splitLocation[1] === "customer-request-list" ||
                  splitLocation[1] === "projects" ||
                  splitLocation[1] === "enterprises-dashboard" ||
                  splitLocation[1] === "enterprises-list" ||
                  splitLocation[1] === "enterprises-request-list" ||
                  splitLocation[1] === "customer-request-list"
                    ? "primary f4"
                    : "gray f1"
                } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
              >
                <GroupsIcon className="icon" />
                <span className="text text-nowrap f2 fs-xs-10 trans fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Sales
                </span>
              </p>
            </li>

            {customerDash && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setCustomerDown(!customerDown);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                }}
              >
                <p
                  className={` ${"gray f1"} trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    E-Commerce
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </li>
            )}

            {customerDown && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setEnterprises(false);
                  setHelpDeskDown(false);
                  navigate("/customer-dashboard");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "customer-dashboard"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    E-Commerce Dashboard
                  </span>
                </p>
              </li>
            )}

            {customerDown && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnterprises(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/customer-list");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "customer-list"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    E-Commerce/ Customer List
                  </span>
                </p>
              </li>
            )}
            {customerDown && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnterprises(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/customer-request-list");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "customer-request-list"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-wrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    {" "}
                    E-Commerce/ Customer Request List
                  </span>
                </p>
              </li>
            )}

            {customerDash && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnterprises(!enterprises);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                }}
              >
                <p
                  className={` ${"gray f1"} trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Enterprises
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </li>
            )}

            {enterprises && (
              <>
                <li
                  className="nav_link trans trans"
                  onClick={() => {
                    setProdManagement(false);
                    setProjectManagement(false);
                    setEnableDown(false);
                    setHelpDeskDown(false);
                    setCustomerDown(false);
                    navigate("/enterprises-dashboard");
                  }}
                >
                  <p
                    className={` ${
                      splitLocation[1] === "enterprises-dashboard"
                        ? "primary bg-lt-blue1 f4"
                        : "gray f1"
                    } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
                  >
                    <GroupsIcon className="icon invisible" />
                    <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Enterprises Dashboard
                    </span>
                  </p>
                </li>
                <li
                  className="nav_link trans trans"
                  onClick={() => {
                    setProdManagement(false);
                    setProjectManagement(false);
                    setEnableDown(false);
                    setHelpDeskDown(false);
                    setCustomerDown(false);
                    navigate("/enterprises-list");
                  }}
                >
                  <p
                    className={` ${
                      splitLocation[1] === "enterprises-list"
                        ? "primary bg-lt-blue1 f4"
                        : "gray f1"
                    } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
                  >
                    <GroupsIcon className="icon invisible" />
                    <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Enterprises List
                    </span>
                  </p>
                </li>
                <li
                  className="nav_link trans trans"
                  onClick={() => {
                    setProdManagement(false);
                    setProjectManagement(false);
                    setEnableDown(false);
                    setHelpDeskDown(false);
                    setCustomerDown(false);
                    navigate("/enterprises-request-list");
                  }}
                >
                  <p
                    className={` ${
                      splitLocation[1] === "enterprises-request-list"
                        ? "primary bg-lt-blue1 f4"
                        : "gray f1"
                    } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
                  >
                    <GroupsIcon className="icon invisible" />
                    <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Enterprises Request List
                    </span>
                  </p>
                </li>
              </>
            )}

            {customerDash && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnterprises(false);
                  setCustomerDown(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/projects");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "projects"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Projects
                  </span>
                </p>
              </li>
            )}
            {customerDash && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnterprises(false);
                  setCustomerDown(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/projects-tasks");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "projects-tasks"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Projects Task
                  </span>
                </p>
              </li>
            )}
            {customerDash && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnterprises(false);
                  setCustomerDown(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/collaborates");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "collaborate"
                      ? "primary bg-lt-blue1 f4"
                      : splitLocation[1] === "view-collaborate"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Collaborate
                  </span>
                </p>
              </li>
            )}
            {customerDash && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnterprises(false);
                  setCustomerDown(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/product-transfer");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "product-transfer"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Product Transfer
                  </span>
                </p>
              </li>
            )}
            <li
              className="nav_link trans trans"
              onClick={() => {
                setProduction(false);
                setProdManagement(false);
                setProjectManagement(false);
                setEnableDown(false);
                setHelpDeskDown(false);
                setCustomerDown(false);
                setSiteManagement(false);
                setManageOrder(!manageOrder);
                // navigate("/ecommerce-list");
                // navigate("/production-dashboard");
                setSaidBarShow(false);
                setOurPacks(false);
                setRoles(false);
                setAdminRequst(false);
              }}
            >
              <p
                className={` ${
                  manageOrder ? "primary f4" : "gray f1"
                } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
              >
                <ManageAccountsIcon className="icon" />
                <span className="text text-nowrap f2 fs-xs-10 trans fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Manage Order
                </span>
              </p>
            </li>
            {manageOrder && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/pending-order");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "pending-order"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Pending Order
                  </span>
                </p>
              </li>
            )}

            <li
              className="nav_link trans trans"
              onClick={() => {
                setSiteManagement(!siteManagement);
                setProdManagement(false);
                setProjectManagement(false);
                setEnableDown(false);
                setHelpDeskDown(false);
                setCustomerDown(false);
                setProduction(false);
                // navigate("/testimonials");
                setSaidBarShow(false);
                setRoles(false);
                setOurPacks(false);
                setAdminRequst(false);

                // navigate("/ecommerce-list");
              }}
            >
              <p
                className={` ${
                  siteManagement ? "primary f4" : "gray f1"
                } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
              >
                <AccountTreeIcon className="icon" />
                <span className="text text-nowrap f2 fs-xs-10 trans fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Site Management
                </span>
              </p>
            </li>
            {siteManagement && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/testimonials");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "testimonials" ||
                    splitLocation[1] === "testimonials-detail" ||
                    splitLocation[1] === "testimonials-edit"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Testimonials
                  </span>
                </p>
              </li>
            )}

            {siteManagement && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/banner-management");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "banner-management" ||
                    splitLocation[1] === "banner-management-edit" ||
                    splitLocation[1] === "banner-management-detail"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Banner Management
                  </span>
                </p>
              </li>
            )}
            {siteManagement && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/blog-management");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "blog-management" ||
                    splitLocation[1] === "blog-management-edit" ||
                    splitLocation[1] === "blog-management-detail"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Blog Management
                  </span>
                </p>
              </li>
            )}
            {siteManagement && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/special-offers-detail");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "special-offers" ||
                    splitLocation[1] === "special-offers-edit" ||
                    splitLocation[1] === "special-offers-detail"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Special Offers
                  </span>
                </p>
              </li>
            )}
            {siteManagement && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/vendor-list");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "vendor-list" ||
                    splitLocation[1] === "vendor-management-edit" ||
                    splitLocation[1] === "vendor-management-detail"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Trusted By
                  </span>
                </p>
              </li>
            )}
            {siteManagement && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/our-deals");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "our-deals" ||
                    splitLocation[1] === "our-deals-view"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Our Deals
                  </span>
                </p>
              </li>
            )}
            {siteManagement && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/join-with-us");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "join-with-us" ||
                    splitLocation[1] === "join-with-us-view"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Join with us
                  </span>
                </p>
              </li>
            )}
            {siteManagement && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/faq-page");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "faq-page" ||
                    splitLocation[1] === "faq-page"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Faq
                  </span>
                </p>
              </li>
            )}

            <li
              className="nav_link trans trans"
              onClick={() => {
                setProduction(!production);
                setProdManagement(false);
                setProjectManagement(false);
                setEnableDown(false);
                setHelpDeskDown(false);
                setCustomerDown(false);
                setSiteManagement(false);
                // navigate("/ecommerce-list");
                // navigate("/production-dashboard");
                setSaidBarShow(false);
                setOurPacks(false);
                setRoles(false);
                setAdminRequst(false);
              }}
            >
              <p
                className={` ${
                  production ? "primary f4" : "gray f1"
                } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
              >
                <CategoryIcon className="icon" />
                <span className="text text-nowrap f2 fs-xs-10 trans fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Production
                </span>
              </p>
            </li>
            {production && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/production-dashboard");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "production-dashboard"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    DashBoard
                  </span>
                </p>
              </li>
            )}
            {production && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/production");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "production"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Production
                  </span>
                </p>
              </li>
            )}
            <li
              className="nav_link trans trans"
              onClick={() => {
                setProduction(false);
                setProdManagement(false);
                setProjectManagement(false);
                setEnableDown(false);
                setHelpDeskDown(false);
                setCustomerDown(false);
                setSiteManagement(false);
                setAdminRequst(!adminRequest);
                // navigate("/ecommerce-list");
                // navigate("/production-dashboard");
                setSaidBarShow(false);
                setOurPacks(false);
                setRoles(false);
                setManageOrder(false);
              }}
            >
              <p
                className={` ${
                  adminRequest ? "primary f4" : "gray f1"
                } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
              >
                <SwitchAccountIcon className="icon" />
                <span className="text text-nowrap f2 fs-xs-10 trans fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Admin request
                </span>
              </p>
            </li>
            {adminRequest && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/po-all-request");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "po-all-request"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    PO overall Request
                  </span>
                </p>
              </li>
            )}
            {adminRequest && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/Overall-estimate-request");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === " Overall-estimate-request"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Overall Estimate Request
                  </span>
                </p>
              </li>
            )}
            {adminRequest && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/overall-markup-request");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "overall-markup-request"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Overall Mockup Artwork
                  </span>
                </p>
              </li>
            )}
            {adminRequest && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/overall-production-markup-request");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "overall-production-markup-request"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Overall Production Request
                  </span>
                </p>
              </li>
            )}
            {adminRequest && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/price-request");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "price-request"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Price Request
                  </span>
                </p>
              </li>
            )}
            <li
              className="nav_link trans"
              onClick={() => {
                navigate("/support");
                setNavState("navlink");
                setEnableDown(false);
                setProdManagement(false);
                setProjectManagement(false);
                setCustomerDown(false);
                setSiteManagement(false);
                setProduction(false);
                setSaidBarShow(false);
              }}
            >
              <p
                className={` ${
                  (navState === "navlink" &&
                    splitLocation[1] === "our-packs") ||
                  splitLocation[1] === "add-role-profile" ||
                  splitLocation[1] === "view-role-profile" ||
                  splitLocation[1] === "edit-role-profile"
                    ? "primary bg-lt-blue1 f4"
                    : "gray f1"
                } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
              >
                <SupportAgentIcon className=" icon" />
                <span className="text text-nowrap f2 fs-xs-10 trans fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  {" "}
                  Support
                </span>
              </p>
            </li>
            <li
              className="nav_link trans trans"
              onClick={() => {
                setProduction(false);
                setProdManagement(false);
                setProjectManagement(false);
                setEnableDown(false);
                setHelpDeskDown(false);
                setCustomerDown(false);
                setSiteManagement(false);
                setManageOrder(false);
                // navigate("/ecommerce-list");
                // navigate("/production-dashboard");
                setSaidBarShow(false);
                setOurPacks(false);
                setRoles(false);
                setAdminRequst(false);
                setInvoice(!invoice);
              }}
            >
              <p
                className={` ${
                  invoice ? "primary f4" : "gray f1"
                } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
              >
                <ReceiptIcon className="icon" />
                <span className="text text-nowrap f2 fs-xs-10 trans fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Template Lists
                </span>
              </p>
            </li>
            {/* {invoice && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/invoice-client");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "invoice-client"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Invoice Client
                  </span>
                </p>
              </li>
            )} */}

            {invoice && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/invoice-client-a");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "invoice-client"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Invoice Client
                  </span>
                </p>
              </li>
            )}
            {invoice && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/packing-slip-a");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "invoice-client"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Packing Slip
                  </span>
                </p>
              </li>
            )}
            {/* {invoice && (
          <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/packing-slip");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "packing-slip"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                  Packing Slip
                  </span>
                </p>
              </li>
        )} */}
            {/* {invoice && (
          <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/shipping-to");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "shipping-to"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                  Shipping To 
                  </span>
                </p>
              </li>
        )} */}
            {invoice && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/shipping-to-a");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "shipping-to-a"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Shipping
                  </span>
                </p>
              </li>
            )}
            {invoice && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/po-product-supplier-a");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "po-product-supplier-a"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Supplier Purchase Order
                  </span>
                </p>
              </li>
            )}
            {/* {invoice && (
          <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/po-product-supplier");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "po-product-supplier"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                  PO Product Supplier
                  </span>
                </p>
              </li>
        )} */}
            {invoice && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/estimation-page-a");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "estimation-page-a"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Estimation
                  </span>
                </p>
              </li>
            )}
            {/* {invoice && (
          <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/estimation-page");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "estimation-page"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                  Estimation Page
                  </span>
                </p>
              </li>
        )} */}
            {/* {invoice && (
          <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/poproduct-supplier-approved-a");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "poproduct-supplier-approved-a"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                  Po Product Supplier Approved A
                  </span>
                </p>
              </li>
        )} */}
            {/* {invoice && (
          <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/poproduct-approved");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "poproduct-approved"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                  Po Product Supplier Approved
                  </span>
                </p>
              </li>
        )} */}
            {/* {invoice && (
          <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/poproduct-request-a");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "poproduct-request-a"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                  Po Product Change Request A
                  </span>
                </p>
              </li>
        )} */}
            {/* {invoice && (
          <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/poproduct-request");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "poproduct-request"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                  Po Product Request
                  </span>
                </p>
              </li>
        )} */}
            {invoice && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/sales-order-a");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "sales-order-a"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Sales Order
                  </span>
                </p>
              </li>
            )}
            {/* {invoice && (
          <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/sales-order");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "sales-order"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Sales Order
                  </span>
                </p>
              </li>
        )} */}
            {/* {invoice && (
          <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/presentation-gallery");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "presentation-gallery"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Presentation Gallery 
                  </span>
                </p>
              </li>
        )} */}
            {invoice && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/presentation-gallery-page-2a");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "presentation-gallery-page-2a"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Presentation Gallery
                  </span>
                </p>
              </li>
            )}
            {/* {invoice && (
            <li
              className="nav_link trans"
              onClick={() => {
                navigate("/presentation-gallery-a1");
                setNavState("navlink");
                setEnableDown(false);
                setProdManagement(false);
                setCustomerDown(false);
                setSiteManagement(false);
                setProduction(false);
                setSaidBarShow(false);
              }}
            >
              <p
                className={` ${
                  (navState === "navlink" &&
                    splitLocation[1] === "our-packs") ||
                  splitLocation[1] === "add-role-profile" ||
                  splitLocation[1] === "view-role-profile" ||
                  splitLocation[1] === "edit-role-profile"
                    ? "primary bg-lt-blue1 f4"
                    : "gray f1"
                } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 `}
              >
                <SupportAgentIcon className=" icon" />
                <span className="text text-nowrap f2 fs-xs-10 trans fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  {" "}
                  Presentation Gallery A1
                </span>
              </p>
            </li>
        )} */}
            {invoice && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/dc-b");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "dc-b"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Delivery Note
                  </span>
                </p>
              </li>
            )}
            {/* {invoice && (
          <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/dc");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "dc"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    DC 
                  </span>
                </p>
              </li>
        )} */}
            {invoice && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/feedback-a");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "feedback-a"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Customers Feedback
                  </span>
                </p>
              </li>
            )}
            {invoice && (
              <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setProjectManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/presentation-list-a");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "presentation-list-a"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Presentation List
                  </span>
                </p>
              </li>
            )}
            {/* {invoice && (
          <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/presentation-gallery-comment-a");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "presentation-gallery-comment-a"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Presentation Gallery Comment A
                  </span>
                </p>
              </li>
        )} */}
            {/* {invoice && (
          <li
                className="nav_link trans trans"
                onClick={() => {
                  setProdManagement(false);
                  setEnableDown(false);
                  setHelpDeskDown(false);
                  navigate("/presentation-gallery-comment");
                }}
              >
                <p
                  className={` ${
                    splitLocation[1] === "presentation-gallery-comment"
                      ? "primary bg-lt-blue1 f4"
                      : "gray f1"
                  } trans  f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13`}
                >
                  <GroupsIcon className="icon invisible" />
                  <span className="text text-nowrap f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    Presentation Gallery Comment 
                  </span>
                </p>
              </li>
        )} */}
            <li
              className="nav_link trans trans"
              onClick={() => {
                navigate("/activity-logs");
                setProduction(false);
                setProdManagement(false);
                setProjectManagement(false);
                setEnableDown(false);
                setHelpDeskDown(false);
                setCustomerDown(false);
                setSiteManagement(false);
                setManageOrder(false);
                // navigate("/ecommerce-list");
                // navigate("/production-dashboard");
                setSaidBarShow(false);
                setOurPacks(false);
                setRoles(false);
                setAdminRequst(false);
                setActivity(!activity);
              }}
            >
              <p
                className={` ${
                  activity ? "primary f4" : "gray f1"
                } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
              >
                <LocalActivityIcon className="icon" />
                <span className="text text-nowrap f2 fs-xs-10 trans fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Activity Logs
                </span>
              </p>
            </li>
            <li
              className="nav_link trans trans"
              onClick={() => {
                navigate("/notification");
                setProduction(false);
                setProdManagement(false);
                setProjectManagement(false);
                setEnableDown(false);
                setHelpDeskDown(false);
                setCustomerDown(false);
                setSiteManagement(false);
                setManageOrder(false);
                // navigate("/ecommerce-list");
                // navigate("/production-dashboard");
                setSaidBarShow(false);
                setOurPacks(false);
                setRoles(false);
                setAdminRequst(false);
                setNotify(!notify);
              }}
            >
              <p
                className={` ${
                  notify ? "primary f4" : "gray f1"
                } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
              >
                <CircleNotificationsIcon className="icon" />
                <span className="text text-nowrap f2 fs-xs-10 trans fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Notifications
                </span>
              </p>
            </li>
            <li
              className="nav_link trans trans mb-5"
              onClick={() => {
                setLogoutPopupShow(true);
                setProduction(false);
                setProdManagement(false);
                setProjectManagement(false);
                setEnableDown(false);
                setHelpDeskDown(false);
                setCustomerDown(false);
                setSiteManagement(false);
                setManageOrder(false);
                // navigate("/ecommerce-list");
                // navigate("/production-dashboard");
                setSaidBarShow(false);
                setOurPacks(false);
                setRoles(false);
                setAdminRequst(false);
                setNotify(!notify);
              }}
            >
              <p
                className={` ${"text-dark f1"} trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20`}
              >
                <LogoutIcon className="icon" />
                <span className="gray text text-nowrap f2 fs-xs-10 trans fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Logout
                </span>
              </p>
            </li>
          </ul>
        </div>
        {/* <div className="bottom_cont" onClick={() => setSaidBarShow(false)}>
          <li className="nav_link trans">
            <p
              className={` ${
                splitLocation[1] === "logout"
                  ? "primary bg-lt-blue1 f4"
                  : "gray f1"
              } trans  fs-xs-18 fs-sm-20 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  `}
            >
              {" "}
              <button
                className="border-0 bg-transparent d-flex"
                onClick={submitLogout}
                disabled={btn ? true : false}
              >
                <LogoutIcon className=" icon" />

                <span className="gray text text-nowrap f2 fs-xs-10 trans fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 mt-1">
                  Logout
                </span>
              </button>
            </p>
          </li>
        </div> */}
      </div>
      {logoutPopupShow && (
        <LogoutPopup
          onCancelClick={toggleLogoutPopup}
          onConfirmClick={submitLogout}
        />
      )}
    </div>
  );
};

export default ProfileNavBar2;
