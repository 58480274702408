// @ts-nocheck
import { Delete } from "@mui/icons-material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { editIcon, toggleOff, toggleOn, viewIcon } from "../../assets/img";
import { customerDecoList } from "../../redux/api/DummyJson";

const VendorManagementViewList = ({ list, change }) => {
  const navigate = useNavigate();

  return (
    <div className="mt-5" style={{ width: "100%", overflow: "auto" }}>
        <table className="table-design-border" style={{ width: "100%" }}>
          <thead>
            <tr className="border-bottom w-100">
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
               S.No
              </p>
            </th>
            <th>
              <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex ac-jc">
                Images                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
              </p>
            </th>
           
            <th>
              <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex ac-jc">
                Priority
              </p>
            </th>
            <th>
              {" "}
              <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex ac-jc">
                Status
              </p>
            </th>
            <th>
              {" "}
              <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex ac-jc">
                Action
              </p>
            </th>
          </tr>
        </thead>
        <tbody>
          {list?.map((item, index) => {
            return (
              <tr className="border-bottom w-100">
                <td>
                  <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                    {index + 1}
                  </p>
                </td>
                <td>
                  <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                  <img src={item?.image_url} style={{width:"80px"}}/>
                  </p>
                </td>

                <td>
                  <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                    {item?.priority} 
                  </p>
                </td>

                <td>
                  <div className=" d-flex ac-jc " onClick={() => change(item?.id)}>
                    {item?.status === 0 ? (
                      <div>
                        <img src={toggleOff} className="toggleOnDes" />
                      </div>
                    ) : (
                      <div>
                        <img src={toggleOn} className="toggleOnDes" />
                      </div>
                    )}
                  </div>
                </td>
                <td>
                  <div className=" d-flex ac-jc primary1 ">
                    <div
                      onClick={() =>
                        navigate("/vendor-management-edit", {
                          state: {type: "view", data: item },
                        })
                      }
                      className="viewBoxDes pointerView p-2 bg-white rounded-2"
                    >
                      <img src={viewIcon} className="viewDes" />
                    </div>
                    <div
                      onClick={() =>
                        navigate("/vendor-management-edit", {
                          state: { type: "edit", data: item },
                        })
                      }
                      className="viewBoxDes pointerView ms-2 p-2 bg-white rounded-2"
                    >
                      <img src={editIcon} className="viewDes" />
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default VendorManagementViewList;
