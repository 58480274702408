import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ProductSearchHeader from "../ProductComp/ProductSearchHeader";
import { product } from "../../assets/img";
import {
  useLazyProductSuggestionViewQuery,
  useProductSuggestionEditMutation,
  useProductSuggestionStatusMutation,
} from "../../redux/api/api";
import RejectPopup from "../Popup/RejectPopup";
import { CloseOutlined } from "@mui/icons-material";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../redux/slice/loaderNoti";
import ErrorIcon from "@mui/icons-material/Error";

const VendorsRequestDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [list, setlist] = useState([]);
  const [status, setStatus] = useState("");
  const [notes, setNotes] = useState("");
  const [show, setShow] = useState(false);
  const [notesErr, setNotesErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const dispatch = useDispatch();
  console.log(location);

  //RTK
  const [ProductEditApi] = useProductSuggestionEditMutation();
  const [ProductViewApi] = useLazyProductSuggestionViewQuery();
  const [productStatusApi] = useProductSuggestionStatusMutation();

  const getView = () => {
    ProductViewApi(location?.state?.data)
      .unwrap()
      .then((res) => {
        // console.log(res)
        const data = res?.decor_price;
        setlist(data);
        setStatus(data?.status);
        //  setPrice(data?.total_amount)
        //  setImages(data?.image_url)
        //  setTitle(data?.name)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getState = (event) => {
    const formData = new FormData();

    formData.append("status", event);

    if (notes) {
      formData.append("notes", notes);
    }
    let count = 1;
    if (event == 4 && notes?.length == 0) {
      setNotesErr(true);
      count = count + 1;
    }
    if (count == 1) {
      dispatch(saveLoader(true));
      setBtn(true);
      productStatusApi({ payload: formData, id: location?.state?.data })
        .unwrap()
        .then((res) => {
          setShow(false);
          dispatch(saveLoader(false));
          setBtn(false);
          navigate("/product-feed", { state: { type: "new_product_suggest" } });
          getView();
          //  setPrice(data?.total_amount)
          //  setImages(data?.image_url)
          //  setTitle(data?.name)
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log(err);
        });
    }
  };

  const onStatusChange = (num) => {
    setStatus(num);
    if (num == 4) {
      setShow(true);
    } else {
      getState(num);
    }
  };

  useEffect(() => {
    if (location?.state?.data) {
      getView();
    }
  }, []);

  const onShowPopupHander = () => {
    setShow(!show);
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      {show && (
        <div className="modal-popup5">
          <div onClick={onShowPopupHander} className="back-close" />
          <div className="center-content5 px-3 mb-4 p-4">
            <div
              className="w-100 d-flex ac-jb cp p-4"
              onClick={onShowPopupHander}
              // style={{ marginTop: "-55px" }}
            >
              <p className="f4 fs-xs-10 fs-sm-14 fs-md-16 fs-lg-17 fs-xl-18 fs-xxl-20 primary">
                Reason For Rejection
              </p>
              <CloseOutlined className="primary f5 fs-2" />
            </div>
            <div className="d-flex ac-jc w-100 mb-4 mt-3">
              <textarea
                placeholder="Text Here..."
                className="editBtn w-95 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 "
                onChange={(e) => setNotes(e.target.value)}
              />
              {notes?.length == 0 && notesErr && (
                <div className="d-flex gap-1 mt-1">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">
                    Enter Reason For Rejection
                  </p>
                </div>
              )}
            </div>
            <div className="d-flex ac-jc">
              <button
                class="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded "
                onClick={() => getState(status)}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
      <ProductSearchHeader />
      <div className="w-100 top_head_cust py-3 d-flex ac-jb px-3">
        <h5 class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
          {list?.vendor?.name}
        </h5>
        <h5 class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 black">
          Status :{" "}
          <span>
            {/* Requested */}
            <select
              name="status"
              className="primary p-1 f2 fs-18"
              style={{ border: "1px solid #07679c", borderRadius: "10px" }}
              onChange={(e) => {
                onStatusChange(e.target.value);
              }}
              value={status}
            >
              {/* <option disabled hidden value="">
                Select
              </option> */}
              <option value={1} disabled hidden>
                Request
              </option>
              <option value={2}>In Process</option>
              <option value={3}>Accept</option>
              <option value={4}>Reject</option>
            </select>
          </span>
        </h5>
      </div>
      <div className="d-flex mt-3 ac-js gap-3">
        <div>
          <p
            className="f4 fs-xs-10 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary"
            role={"button"}
            onClick={() =>
              navigate("/product-feed", {
                state: { type: "new_product_suggest" },
              })
            }
          >
            <KeyboardBackspaceIcon />
          </p>
        </div>
        <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center black  d-flex align-items-center gap-2">
          Vendors Request List
        </p>
      </div>

      <div className="d-flex flex-wrap as-jb flex-m-r my-5">
        <div className="col-lg-7 col-12">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Tags
          </p>
          <input
            disabled
            type="text"
            placeholder="Enter Product Title"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value={list?.name}
          />
        </div>
        <div className="col-lg-7 col-12 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Price
          </p>

          <input
            disabled
            type="text"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            placeholder="Enter product Price here..."
            value={list?.total_amount}
          />
        </div>
        <div className="col-lg-7 col-12 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Product Images
          </p>
          <img
            disabled
            src={list?.image_url ? list?.image_url : product}
            alt="img"
            style={{ width: "150px", height: "150px" }}
          />
        </div>
        <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <button
            className="cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            onClick={() =>
              navigate("/product-feed", {
                state: { type: "new_product_suggest" },
              })
            }
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default VendorsRequestDetails;
