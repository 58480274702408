// import AddContacts from "./AddContacts";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { useState } from "react";
// import PlayArrowIcon from "@mui/icons-material/PlayArrow";

// const ContactDetails = ({
//   setContactPopup,
//   setAddressPopup,
//   setPositionPopup,
// }) => {
//   const [addContact1, setAddContact1] = useState(false);
//   const [addContact2, setAddContact2] = useState(false);
//   const [addContact3, setAddContact3] = useState(false);
//   const [addContact4, setAddContact4] = useState(false);
//   const [addContact5, setAddContact5] = useState(false);
//   const [addContact6, setAddContact6] = useState(false);
//   const [addContact7, setAddContact7] = useState(false);
//   const [addContact8, setAddContact8] = useState(false);
//   const [addContact9, setAddContact9] = useState(false);
//   const [addContact10, setAddContact10] = useState(false);
//   const [addContact11, setAddContact11] = useState(false);

//   return (
//     <div className="w-100 mt-5 ">
//       <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
//         Main Contact Information
//       </p>
//       <div className="w-xs-100 w-sm-100 w-md-100 w-lg-50 d-flex ac-jc mt-3">
//         <p className="primary w-30 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
//           Primary
//         </p>
//         <div className="d-flex w-70">
//           <p className="black f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
//             SakthiVel{" "}
//             <span className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
//               (Executive)
//             </span>
//           </p>
//           <p className="text-danger ms-2 rounded-2 p-1 bg-soft-red f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-12">
//             Not Verified
//           </p>
//         </div>
//       </div>
//       <div className="w-xs-100 w-sm-100 w-md-100 w-lg-50 d-flex ac-jb mt-3">
//         <p className="primary w-30 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
//           Secondary
//         </p>
//         <div className="d-flex w-70">
//           <p className="black f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
//             Charles{" "}
//             <span className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
//               (Sales)
//             </span>
//           </p>
//         </div>
//       </div>
//       <div className="w-xxl-50 w-xl-50 w-lg-50 w-md-70">
//         <div className="w-100 mt-5">
//           <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
//             Departments
//           </p>
//           <div className="w-100 mt-3 ac-jb d-flex">
//             <p className="f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
//               <PlayArrowIcon
//                 onClick={() => setAddContact1(!addContact1)}
//                 className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
//                   addContact1 ? "arrowIcon" : ""
//                 } `}
//               />
//               Management <span className="primary">(1)</span>
//             </p>
//             <div onClick={() => setContactPopup(true)} className="pointerView ">
//               <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
//                 Add Contact
//               </p>
//             </div>
//           </div>
//           {addContact1 && (
//             <AddContacts
//               setContactPopup={setContactPopup}
//               setPositionPopup={setPositionPopup}
//             />
//           )}

//           <div className="w-100 mt-3 ac-jb d-flex">
//             <p className="f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
//               <PlayArrowIcon
//                 onClick={() => setAddContact10(!addContact10)}
//                 className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
//                   addContact1 ? "arrowIcon" : ""
//                 } `}
//               />
//               Marketing <span className="primary">(1)</span>
//             </p>
//             <div onClick={() => setContactPopup(true)} className="pointerView ">
//               <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
//                 Add Contact
//               </p>
//             </div>
//           </div>
//           {addContact10 && <AddContacts setContactPopup={setContactPopup} />}

//           <div className="w-100 mt-3 ac-jb d-flex">
//             <p className="f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
//               <PlayArrowIcon
//                 onClick={() => setAddContact11(!addContact11)}
//                 className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
//                   addContact1 ? "arrowIcon" : ""
//                 } `}
//               />
//               Logistics <span className="primary">(1)</span>
//             </p>
//             <div onClick={() => setContactPopup(true)} className="pointerView ">
//               <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
//                 Add Contact
//               </p>
//             </div>
//           </div>
//           {addContact11 && <AddContacts setContactPopup={setContactPopup} />}

//           <div className="w-100 mt-1 ac-jb d-flex">
//             <p className="f2 mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
//               <PlayArrowIcon
//                 onClick={() => setAddContact2(!addContact2)}
//                 className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
//                   addContact2 ? "arrowIcon" : ""
//                 } `}
//               />
//               Design/Creative <span className="primary">(1)</span>
//             </p>
//             <div onClick={() => setContactPopup(true)} className="pointerView ">
//               <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
//                 Add Contact
//               </p>
//             </div>
//           </div>
//           {addContact2 && (
//             <AddContacts
//               setContactPopup={setContactPopup}
//               setPositionPopup={setPositionPopup}
//             />
//           )}
//           <div className="w-100 mt-1 ac-jb d-flex">
//             <p className="f2 mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
//               <PlayArrowIcon
//                 onClick={() => setAddContact3(!addContact3)}
//                 className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
//                   addContact3 ? "arrowIcon" : ""
//                 } `}
//               />
//               Sales <span className="primary">(1)</span>
//             </p>
//             <div onClick={() => setContactPopup(true)} className="pointerView ">
//               <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
//                 Add Contact
//               </p>
//             </div>
//           </div>
//           {addContact3 && (
//             <AddContacts
//               setContactPopup={setContactPopup}
//               setPositionPopup={setPositionPopup}
//             />
//           )}
//           <div className="w-100 mt-1 ac-jb d-flex">
//             <p className="f2 mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
//               <PlayArrowIcon
//                 onClick={() => setAddContact4(!addContact4)}
//                 className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
//                   addContact4 ? "arrowIcon" : ""
//                 } `}
//               />
//               Production <span className="primary">(1)</span>
//             </p>
//             <div onClick={() => setContactPopup(true)} className="pointerView ">
//               <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
//                 Add Contact
//               </p>
//             </div>
//           </div>
//           {addContact4 && (
//             <AddContacts
//               setContactPopup={setContactPopup}
//               setPositionPopup={setPositionPopup}
//             />
//           )}
//           <div className="w-100 mt-1 ac-jb d-flex">
//             <p className="f2 mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
//               <PlayArrowIcon
//                 onClick={() => setAddContact5(!addContact5)}
//                 className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
//                   addContact5 ? "arrowIcon" : ""
//                 } `}
//               />
//               Customer Service <span className="primary">(1)</span>
//             </p>
//             <div onClick={() => setContactPopup(true)} className="pointerView ">
//               <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
//                 Add Contact
//               </p>
//             </div>
//           </div>
//           {addContact5 && (
//             <AddContacts
//               setContactPopup={setContactPopup}
//               setPositionPopup={setPositionPopup}
//             />
//           )}
//           <div className="w-100 mt-1 ac-jb d-flex">
//             <p className="f2 mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
//               <PlayArrowIcon
//                 onClick={() => setAddContact6(!addContact6)}
//                 className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
//                   addContact6 ? "arrowIcon" : ""
//                 } `}
//               />
//               Accounting <span className="primary">(1)</span>
//             </p>
//             <div onClick={() => setContactPopup(true)} className="pointerView ">
//               <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
//                 Add Contact
//               </p>
//             </div>
//           </div>
//           {addContact6 && (
//             <AddContacts
//               setContactPopup={setContactPopup}
//               setPositionPopup={setPositionPopup}
//             />
//           )}
//           <div className="w-100 mt-1 ac-jb d-flex">
//             <p className="f2 mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
//               <PlayArrowIcon
//                 onClick={() => setAddContact7(!addContact7)}
//                 className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
//                   addContact7 ? "arrowIcon" : ""
//                 } `}
//               />
//               Human Resources <span className="primary">(1)</span>
//             </p>
//             <div onClick={() => setContactPopup(true)} className="pointerView ">
//               <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
//                 Add Contact
//               </p>
//             </div>
//           </div>
//           {addContact7 && (
//             <AddContacts
//               setContactPopup={setContactPopup}
//               setPositionPopup={setPositionPopup}
//             />
//           )}
//           <div className="w-100 mt-1 ac-jb d-flex">
//             <p className="f2 mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
//               <PlayArrowIcon
//                 onClick={() => setAddContact8(!addContact8)}
//                 className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
//                   addContact8 ? "arrowIcon" : ""
//                 } `}
//               />
//               Procurement <span className="primary">(1)</span>
//             </p>
//             <div onClick={() => setContactPopup(true)} className="pointerView ">
//               <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
//                 Add Contact
//               </p>
//             </div>
//           </div>
//           {addContact8 && (
//             <AddContacts
//               setContactPopup={setContactPopup}
//               setPositionPopup={setPositionPopup}
//             />
//           )}
//           <div className="w-100 mt-1 ac-jb d-flex">
//             <p className="f2 mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
//               <PlayArrowIcon
//                 onClick={() => setAddContact9(!addContact9)}
//                 className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
//                   addContact9 ? "arrowIcon" : ""
//                 } `}
//               />
//               Inactive Contacts <span className="primary">(1)</span>
//             </p>
//             <div onClick={() => setContactPopup(true)} className="pointerView ">
//               <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
//                 Add Contact
//               </p>
//             </div>
//           </div>
//           {addContact9 && (
//             <AddContacts
//               setContactPopup={setContactPopup}
//               setPositionPopup={setPositionPopup}
//             />
//           )}
//         </div>

//         <div className="w-100 mt-5">
//           <div className="w-100 ac-jb d-flex">
//             <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
//               Addresses
//             </p>
//             <button
//               onClick={() => setAddressPopup(true)}
//               className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
//             >
//               Add
//             </button>
//           </div>
//           <div className="w-95 mt-4">
//             <div className="w-100 ac-jb d-flex">
//               <p className="black f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mt-1 ">
//                 Address 1{" "}
//                 <span className="primary1 f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mt-1 ">
//                   (Shipping)
//                 </span>
//               </p>
//               <div>
//                 <EditIcon
//                   onClick={() => setAddressPopup(true)}
//                   className="pointerView primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25"
//                 />
//                 <DeleteIcon className="pointerView ms-3 primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25" />
//               </div>
//             </div>
//             <p className="primary1 mt-3 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
//               No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai -
//               600099, Tamil Nadu, India.
//             </p>
//           </div>
//           <div className="w-95 mt-4">
//             <div className="w-100 ac-jb d-flex">
//               <p className="black f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mt-1 ">
//                 Address 2{" "}
//                 <span className="primary1 f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mt-1 ">
//                   (Billing){" "}
//                   <span className="gray f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mt-1 ">
//                     Optional
//                   </span>
//                 </span>
//               </p>
//               <div>
//                 <EditIcon
//                   onClick={() => {
//                     setAddressPopup(true);
//                   }}
//                   className="pointerView primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25"
//                 />
//                 <DeleteIcon className="pointerView ms-3 primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25" />
//               </div>
//             </div>
//             <p className="primary1 mt-3 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
//               No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai -
//               600099, Tamil Nadu, India.
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ContactDetails;

import AddContacts from "./AddContacts";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { toggleOff, toggleOn } from "../../assets/img";

const ContactDetails = ({
  setContactPopup,
  setAddressPopup,
  setPositionPopup,
  vendorDepartmentList,
  getVendorView,
  getPositionData,
  vendorData,
  vendorAddressList,
  addressStatus,
  getVIewAddress,
  change,
}) => {
  const [showVendor, setShowVendor] = useState(null);

  const getVendorShow = (item) => {
    if (showVendor == item) {
      setShowVendor(null);
    } else {
      setShowVendor(item);
    }
  };

  console.log("vendorData", vendorData);

  return (
    <div className="w-100 mt-5 ">
      <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
        Main Contact Information
      </p>
      <div className="w-xs-100 w-sm-100 w-md-100 w-lg-50 d-flex ac-jc mt-3">
        <p className="primary w-30 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
          Primary
        </p>
        <div className="d-flex w-70">
          <p className="black f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
            {vendorData?.primaryContact?.first_name}{" "}
            {vendorData?.primaryContact?.last_name}
            <span className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              {vendorData?.primaryContact?.department?.name &&
                "(" + vendorData?.primaryContact?.department?.name + ")"}
            </span>
          </p>
          <p
            // className={`${
            //   vendorData?.primaryContact?.status == 1
            //     ? "text-success bg-soft-green"
            //     : "text-danger bg-soft-red"
            // } ms-2 rounded-2 p-1  f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-12`}
            className="text-success bg-soft-green ms-2 rounded-2 p-1  f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-12"
          >
            {/* {vendorData?.primaryContact?.status == 1
              ? "Verified"
              : "Not Verified"} */}
            {vendorData?.primaryContact?.first_name && "Verified"}
          </p>
        </div>
      </div>
      <div className="w-xs-100 w-sm-100 w-md-100 w-lg-50 d-flex ac-jb mt-3">
        <p className="primary w-30 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
          Secondary
        </p>
        <div className="d-flex w-70">
          <p className="black f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
            {vendorData?.secondaryContact?.first_name}{" "}
            {vendorData?.secondaryContact?.last_name}
            <span className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              {vendorData?.secondaryContact?.department?.name &&
                "(" + vendorData?.secondaryContact?.department?.name + ")"}
            </span>
          </p>
          <p
            // className={`${
            //   vendorData?.secondaryContact?.status == 1
            //     ? "text-success bg-soft-green"
            //     : "text-danger bg-soft-red"
            // } ms-2 rounded-2 p-1  f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-12`}

            className="text-success bg-soft-green ms-2 rounded-2 p-1  f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-12"
          >
            {/* {vendorData?.secondaryContact?.status == 1
              ? "Verified"
              : "Not Verified"} */}
            {vendorData?.secondaryContact?.first_name && "Verified"}
          </p>
        </div>
      </div>
      <div className="w-xxl-50 w-xl-50 w-lg-50 w-md-70">
        <div className="w-100 mt-5">
          <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Departments
          </p>
          {vendorDepartmentList?.map((item, ind) => {
            return (
              <div>
                <div className="pointerView w-100 mt-3 ac-jb d-flex" key={ind}>
                  <p className="f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
                    <PlayArrowIcon
                      onClick={() => getVendorShow(ind)}
                      className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
                        showVendor == ind ? "arrowIcon" : ""
                      } `}
                    />
                    {item?.name}{" "}
                    <span className="primary">
                      ({item?.vendor_user?.length})
                    </span>
                  </p>
                  <div
                    onClick={() => getVendorView(item, "", ind, "", "add")}
                    className="pointerView "
                  >
                    <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                      Add Contact
                    </p>
                  </div>
                </div>
                {showVendor == ind &&
                  item?.vendor_user?.map((user, inx) => {
                    return (
                      <AddContacts
                        user={user}
                        item={item}
                        ind={ind}
                        inx={inx}
                        getVendorView={getVendorView}
                        setContactPopup={setContactPopup}
                        setPositionPopup={setPositionPopup}
                        getPositionData={getPositionData}
                        change={change}
                      />
                    );
                  })}
              </div>
            );
          })}
        </div>
      </div>

      <div className="w-100 mt-5">
        <div className="w-100 ac-jb d-flex">
          <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Addresses
          </p>
          <button
            onClick={() => setAddressPopup(true)}
            className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Add
          </button>
        </div>
        {vendorAddressList?.map((item, ind) => {
          return (
            <div className="w-95 mt-4" key={ind}>
              <div className="w-100 ac-jb d-flex">
                <p className="black f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mt-1 ">
                  {item?.address}
                  <span className="primary1 f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mt-1 ">
                    {/* (Shipping) */}
                  </span>
                </p>

                <div className=" d-flex ms-5 ac-jc gap-2">
                  <div onClick={() => addressStatus(item?.id)}>
                    {item?.status == 1 ? (
                      <img src={toggleOn} className="toggleOnDes1" />
                    ) : (
                      <img src={toggleOff} className="toggleOnDes1" />
                    )}
                  </div>

                  <EditIcon
                    onClick={() => getVIewAddress(item)}
                    className="pointerView primary ms-1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25"
                  />
                </div>
              </div>
              <p className="primary1 mt-3 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                {item?.name}
              </p>
              <p className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                {item?.landmark}
              </p>
              <p className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                {item?.street}
              </p>
              <p className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                {item?.country}
              </p>
              <p className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                {item?.state}
              </p>
              <p className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                {item?.city}
              </p>
              <p className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                {item?.pincode}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ContactDetails;
