import { Search } from "@mui/icons-material";
import manImage from "../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import ProfileList from "../components/AddRole/ProfileList";
import ProfileRolesList from "../components/AddRole/ProfileRolesList";
import VendorList from "../components/AddRole/VendorList";
import ProductAdminList from "../components/AddRole/ProductAdminList";
import ClientList from "../components/AddRole/ClientList";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import ImageCroper from "../components/Popup/ImageCroper";
import ErrorIcon from "@mui/icons-material/Error";
import { noLogo, uploadIcon } from "../assets/img";
import {
  useDecorativePriceAddMutation,
  useDecorativePriceEditMutation,
  useDecorativeVendorPriceEditMutation,
  useLazyDecorativePriceListQuery,
  useLazyDecorativeViewNewQuery,
  useLazyDecorativeViewQuery,
} from "../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import DeleteIcon from "@mui/icons-material/Delete";

const DecorativeMethodEdit = () => {
  const fileRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.data;
  const types = location?.state?.type;
  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [showPricing, setShowPricing] = useState(false);
  const [visiableErr, setVisiableErr] = useState(false);

  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [nameErr, setNameErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const [btns, setBtns] = useState(false);
  const [list, setList] = useState([]);
  const [listNum, setListNum] = useState([]);
  const [num, setNum] = useState({});
  const [adminPriceErr, setAdminPriceErr] = useState(false);
  const [adminPrice, setAdminPrice] = useState([
    {
      uom: "Stitches",
      uom_count: "",
      color1_rate: "",
      color2_rate: "",
      color3_rate: "",
      id: "",
    },
    {
      uom: "Inches",
      uom_count: "",
      color1_rate: "",
      color2_rate: "",
      color3_rate: "",
      id: "",
    },
  ]);

  // RTK QUERY
  const [decorativePriceList] = useLazyDecorativePriceListQuery();
  const [decorativePriceAdd] = useDecorativePriceAddMutation();
  const [decorativeVendorPriceEdit] = useDecorativeVendorPriceEditMutation();
  const [decorativeViewApi] = useLazyDecorativeViewNewQuery();
  const [decorativePriceEdit] = useDecorativePriceEditMutation();

  const [priorityErr, setPriorityErr] = useState(false);
  const [priorityErrs, setPriorityErrs] = useState(false);

  useEffect(() => {
    if (croperImage) {
      fetch(croperImage)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "File name", { type: "image/png" });
          setImage(file);
        });
    }
  }, [croperImage]);

  useEffect(() => {
    if (location?.state?.data?.id) {
      getViewPriceList(data?.id);
      setName(data?.name);
      setImage(data?.icon_url);
    } else {
      navigate(-1);
    }
  }, []);

  // GET DECORATIVE PRICE LIST
  const getViewPriceList = (id) => {
    decorativeViewApi(id)
      .unwrap()
      .then((responce) => {
        let vendor = [];
        let vendorList = [];

        let count = [];
        for (let i = 1; i < responce?.data?.vendor.length + 1; i++) {
          count.push(i);
        }

        responce?.data?.vendor?.map((item, ind) => {
          vendor.push({
            ...item,
            priority: item?.decor_price?.[0]?.priority,
            count: count,
          });
        });

        vendor?.map((item, ind) => {
          if (item?.decor_price?.length > 0) {
            vendorList.push(item);
          }
        });

        setList(vendorList);
        let temp = [];
        responce?.data?.admin_decor_method_price?.map((item, ind) => {
          temp.push({
            uom: item?.units_of_measurement,
            uom_count: item?.units_of_measurement_count,
            color1_rate: item?.color1_rate,
            color2_rate: item?.color2_rate,
            color3_rate: item?.color3_rate,
            id: item?.id,
          });
        });

        // console.log("count", count);
        setListNum(count);

        if (temp?.length > 0) {
          setAdminPrice(temp);
        } else {
          setAdminPrice([
            {
              uom: "Stitches",
              uom_count: "",
              color1_rate: "",
              color2_rate: "",
              color3_rate: "",
              id: "",
            },
            {
              uom: "Inches",
              uom_count: "",
              color1_rate: "",
              color2_rate: "",
              color3_rate: "",
              id: "",
            },
          ]);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  // VENDOR PRIORITY CHANGER
  const handleChanges = (even, ind, type, id) => {
    let temp = [...list]; //spread full array

    temp[ind] = {
      ...temp[ind],
      priority: even,
    };

    setNum((val) => ({ ...val, [id]: even }));
    setList(temp);
    setVisiableErr(true);
  };

  // VENDOR PRICE CHANGER
  const handleChange = (even, ind, inx, type) => {
    let temp = [...list]; //spread full array
    let decor = [...temp[ind].decor_price]; // spread decor price

    let obj = {
      ...decor[inx],
      [type]: even,
    }; // change the value

    decor[inx] = obj; // assign change value to decor price array

    temp[ind] = {
      ...temp[ind],
      ["decor_price"]: decor,
    }; // assign change array to full array

    setList(temp);
  };

  // ADMIN PRICE CHANGER
  const changeHandler = (even, ind, type) => {
    let temp = [...adminPrice];

    temp[ind] = {
      ...temp[ind],
      [type]: even,
    };
    setAdminPrice(temp);
  };

  // ADMIN HIDE PRICING
  const hidePricing = () => {
    setShowPricing(!showPricing);
  };

  // VENDOR PRICE EDIT
  const submitHandleVendorPrice = (item) => {
    // console.log("item", item);
    let formdata = new FormData();
    formdata.append("vendor_id", item?.id);
    formdata.append("decorative_method_id", data?.id);
    formdata.append("description", item?.description);

    item?.decor_price?.map((price, ind) => {
      formdata.append(`decor_price_details[${ind}][uom]`, price?.uom);
      formdata.append(
        `decor_price_details[${ind}][uom_count]`,
        price?.uom_count
      );
      formdata.append(
        `decor_price_details[${ind}][color1_rate]`,
        price?.color1_rate
      );
      formdata.append(
        `decor_price_details[${ind}][color2_rate]`,
        price?.color2_rate
      );
      formdata.append(
        `decor_price_details[${ind}][color3_rate]`,
        price?.color3_rate
      );
      formdata.append(`decor_price_details[${ind}][decor_price_id]`, price?.id);
    });
    setBtn(true);
    dispatch(saveLoader(true));
    decorativeVendorPriceEdit(formdata)
      .unwrap()
      .then((res) => {
        setBtn(false);
        dispatch(saveLoader(false));
        getViewPriceList(data?.id);
      })
      .catch((err) => {
        setBtn(false);
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  const priorityRef = useRef();

  // ADMIN PRICE
  const submitHandle = () => {
    // console.log("submitHandle");
    let stitchesCheck = false;
    let inchesCheck = false;
    let inches = adminPrice?.[0];
    let stitches = adminPrice?.[1];

    if (
      !inches?.uom_count ||
      !inches?.color1_rate ||
      !inches?.color2_rate ||
      !inches?.color3_rate
    ) {
      inchesCheck = true;
    }

    if (
      !stitches?.uom_count ||
      !stitches?.color1_rate ||
      !stitches?.color2_rate ||
      !stitches?.color3_rate
    ) {
      stitchesCheck = true;
    }

    let priorityCheck = false;

    list?.map((item, ind) => {
      if (!item?.priority) {
        priorityCheck = true;
      }
    });

    // console.log(stitchesCheck, inchesCheck);

    if (name?.length == 0 || priorityCheck || (inchesCheck && stitchesCheck)) {
      setNameErr(true);
      setAdminPriceErr(true);
      priorityCheck == true && priorityRef.current.focus();
      setPriorityErr(true);
      // console.log(1234);
    } else {
      let formdata = new FormData();

      formdata.append("decor_method_id", data?.id);
      formdata.append("name", name);
      if (image?.name) {
        formdata.append("image", image);
      }

      let count;
      let check = 1;

      adminPrice?.map((item, ind) => {
        if (item?.id) {
          count = 1;
          formdata.append(`decor_price_details[${ind}][uom]`, item?.uom);
          formdata.append(
            `decor_price_details[${ind}][uom_count]`,
            item?.uom_count ? item?.uom_count : ""
          );
          formdata.append(
            `decor_price_details[${ind}][color1_rate]`,
            item?.color1_rate ? item?.color1_rate : ""
          );
          formdata.append(
            `decor_price_details[${ind}][color2_rate]`,
            item?.color2_rate ? item?.color2_rate : ""
          );
          formdata.append(
            `decor_price_details[${ind}][color3_rate]`,
            item?.color3_rate ? item?.color3_rate : ""
          );
          formdata.append(
            `decor_price_details[${ind}][decor_method_price_id]`,
            item?.id
          );
        } else {
          formdata.append(`decor_price_details[${ind}][uom]`, item?.uom);
          formdata.append(
            `decor_price_details[${ind}][uom_count]`,
            item?.uom_count ? item?.uom_count : ""
          );
          formdata.append(
            `decor_price_details[${ind}][color1_rate]`,
            item?.color1_rate ? item?.color1_rate : ""
          );
          formdata.append(
            `decor_price_details[${ind}][color2_rate]`,
            item?.color2_rate ? item?.color2_rate : ""
          );
          formdata.append(
            `decor_price_details[${ind}][color3_rate]`,
            item?.color3_rate ? item?.color3_rate : ""
          );
        }
      });

      let priority = [];

      list?.map((item, ind) => {
        if (item?.priority) {
          priority.push(item?.priority);
        }
      });

      if (list?.length == priority?.length) {
        list?.map((item, ind) => {
          formdata.append(`vendor_priorities[${ind}][vendor_id]`, item?.id);
          formdata.append(
            `vendor_priorities[${ind}][priority]`,
            item?.priority
          );
        });
      } else {
        check = check + 1;
      }

      if (check == 1) {
        if (count == 1) {
          setBtns(true);
          dispatch(saveLoader(true));
          decorativePriceEdit(formdata)
            .unwrap()
            .then((res) => {
              setBtns(false);
              dispatch(saveLoader(false));
              navigate("/decorative-method");
            })
            .catch((err) => {
              setBtns(false);
              dispatch(saveLoader(false));
              console.log("err", err);
            });
        } else {
          setBtns(true);
          dispatch(saveLoader(true));
          decorativePriceAdd(formdata)
            .unwrap()
            .then((res) => {
              setBtns(false);
              dispatch(saveLoader(false));
              navigate("/decorative-method");
            })
            .catch((err) => {
              setBtns(false);
              dispatch(saveLoader(false));
              console.log("err", err);
            });
        }
      }
    }
  };

  useEffect(() => {
    if (list?.length > 1 && visiableErr) {
      const lookup = Object.groupBy(list, (e) => e.priority);
      const duplicate = list.filter((e) => lookup[e.priority].length > 1);
      list?.map((item, ind) => {
        if (!item?.priority) {
          setPriorityErr(true);
        } else {
          setPriorityErr(false);
        }
      });
      if (duplicate?.length > 0) {
        setPriorityErrs(true);
      } else {
        setPriorityErrs(false);
      }
    }
  }, [list]);

  useEffect(() => {
    if (showPricing) {
      let stitchesCheck = false;
      let inchesCheck = false;
      let inches = adminPrice?.[0];
      let stitches = adminPrice?.[1];

      if (
        !inches?.uom_count ||
        !inches?.color1_rate ||
        !inches?.color2_rate ||
        !inches?.color3_rate
      ) {
        inchesCheck = true;
      }

      if (
        !stitches?.uom_count ||
        !stitches?.color1_rate ||
        !stitches?.color2_rate ||
        !stitches?.color3_rate
      ) {
        stitchesCheck = true;
      }

      if (stitchesCheck && inchesCheck) {
        setAdminPriceErr(true);
      } else {
        setAdminPriceErr(false);
      }
    }
  }, [adminPrice]);

  return (
    <div className="dashRightView p-5 home_section trans">
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <div>
        <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
          Edit Decoration Method
        </p>

        <div className="d-flex ac-jc add-content">
          <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
            <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Method Name
            </p>
            <div className=" w-100 mt-xs-5 ">
              <div className="w-100 d-flex w-100 mt-xs-5 position-relative">
                <input
                  className="cust-btn searchFill w-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                  placeholder="Decoration Method"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>
              {name?.length == 0 && nameErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Title</p>
                </div>
              )}
            </div>
          </div>
          <div
            className="w-100 d-flex ac-jb mt-4 flex-wrap"
            onClick={() => toggleImagePopup()}
          >
            <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Image (Optional)
            </p>
            <div className="w-100 d-flex w-100 mt-xs-5 position-relative">
              <input
                className="cust-btn searchFill w-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                value={image ? "Image Uploaded Successfuly" : "Upload File"}
              />
            </div>
          </div>
        </div>
        <div className="d-flex ac-jb mt-3 w-80">
          <div>
            <p class="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
              Supplier Priority List
            </p>
            <div style={{ width: "250px" }} className="my-3 f2 fs-14">
              {list?.map((item, ind) => {
                return (
                  <div className="d-flex my-3 ac-jb">
                    <label className="">{item?.name}</label>
                    <select
                      className="cust-btn primary p-1 mx-2"
                      style={{
                        border: "1px solid #07679c",
                        borderRadius: "10px",
                      }}
                      key={ind}
                      onChange={(e) =>
                        handleChanges(e.target.value, ind, "priority", item?.id)
                      }
                      value={item?.priority}
                      ref={priorityRef}
                    >
                      <option value={""}>Select</option>
                      {item?.count?.map((itemNum, inx) => {
                        return (
                          // !Object.values(num)?.includes(item?.priority) && (
                          <option value={itemNum} key={inx}>
                            {itemNum}
                          </option>
                          // )
                        );
                      })}
                    </select>
                  </div>
                );
              })}
            </div>
            {priorityErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select Priorities</p>
              </div>
            )}
            {priorityErrs && !priorityErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">
                  Same Priority Given, Change Priority
                </p>
              </div>
            )}
          </div>
          <div className="">
            {image && (
              <img
                src={
                  image?.name
                    ? URL.createObjectURL(image)
                    : image
                    ? image
                    : noLogo
                }
                style={{ width: "100px" }}
              />
              //   <div className="pointerView w-100 mt-4">
              //     <div
              //       onClick={() => fileRef.current.click()}
              //       className="w-100 w-md-100 d-flex ac-jc"
              //     >
              //       <img src={uploadIcon} className="uploadImgLib" />
              //     </div>
              //     <div className="w-100 w-md-100 d-flex ac-jc">
              //       <p className="black w-100 f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 mt-1 d-flex ac-jc">
              //         Drag and drop to upload or Click
              //       </p>
              //     </div>
              //     <div className="w-100 w-md-100 d-flex ac-jc">
              //       <p className="black w-100 f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 mt-1 d-flex ac-jc">
              //         here to upload
              //       </p>
              //     </div>
              //   </div>
              // )  (
              // <div className="w-100 w-md-100  mt-4 d-flex ac-jb">
              //   <div
              //     onClick={() => fileRef.current.click()}
              //     className="w-100  w-md-100 d-flex ac-jc"
              //   >
              //     <div className="w-20">
              //       {image?.type === "image/png" ||
              //         image?.type === "image/jpeg" ||
              //         image?.type === "image/jpg" ? (
              //         <img
              //           src={window.URL.createObjectURL(image)}
              //           className="uploadImgLib"
              //         />
              //       ) : null}
              //       {image?.type === "application/pdf" ||
              //         image?.type ===
              //         "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
              //         image?.type === "application/doc" ||
              //         image?.type === "application/msword" ? (
              //         <iframe
              //           src={window.URL.createObjectURL(image)}
              //           className="uploadImgLib"
              //         />
              //       ) : null}
              //     </div>
              //     <p className="f2 w-100 w-md-100 fs-xs-13 ms-2 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 primary1">
              //       {image?.name}
              //     </p>
              //   </div>
              //   <div className="w-20 ac-jc d-flex">
              //     <DeleteIcon
              //       onClick={() => setImage("")}
              //       className="pointerView ms-1 primary1 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20"
              //     />
              //   </div>
              // </div>
            )}
          </div>
        </div>

        <div>
          <div className="overflow-scroll mt-4">
            {list?.map((item, ind) => {
              return (
                <div key={ind}>
                  <p
                    role="button"
                    className="mb-4 black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary"
                    style={{
                      textDecoration: "underline",
                      textTransform: "capitalize",
                    }}
                  >
                    {item?.name}
                  </p>
                  {item?.decor_price?.map((price, inx) => {
                    return (
                      <table className="w-100" key={inx}>
                        <tr className="mt-4">
                          <div className="">
                            <td>
                              <div className="d-flex w-100">
                                <div className="mx-2">
                                  <p
                                    className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                                    style={{ width: "100px" }}
                                  >
                                    UOM
                                  </p>
                                </div>
                                <div className="mx-2">
                                  <p
                                    className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                                    style={{ width: "150px" }}
                                  >
                                    Inches
                                  </p>
                                </div>
                                <div className="mx-2">
                                  <p
                                    className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                                    style={{ width: "100px" }}
                                  >
                                    Color 1 Rate
                                  </p>
                                </div>
                                <div className="mx-2">
                                  <p
                                    className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                                    style={{ width: "100px" }}
                                  >
                                    Color 2 Rate
                                  </p>
                                </div>
                                <div className="mx-2">
                                  <p
                                    className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                                    style={{ width: "100px" }}
                                  >
                                    Color 3 Rate
                                  </p>
                                </div>
                              </div>
                            </td>
                          </div>
                        </tr>
                        <tr className="mt-2">
                          <div className="my-3">
                            <td>
                              <div className="d-flex w-100">
                                <div className="mx-2">
                                  <input
                                    disabled
                                    className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    value={price?.uom}
                                    style={{ width: "100px" }}
                                    onChange={(e) =>
                                      handleChange(
                                        e.target.value,
                                        ind,
                                        inx,
                                        "uom"
                                      )
                                    }
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    disabled
                                    className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="number"
                                    value={price?.uom_count}
                                    style={{ width: "150px" }}
                                    onChange={(e) =>
                                      handleChange(
                                        e.target.value,
                                        ind,
                                        inx,
                                        "uom_count"
                                      )
                                    }
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    disabled
                                    className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="number"
                                    value={price?.color1_rate}
                                    style={{ width: "100px" }}
                                    onChange={(e) =>
                                      handleChange(
                                        e.target.value,
                                        ind,
                                        inx,
                                        "color1_rate"
                                      )
                                    }
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="number"
                                    value={price?.color2_rate}
                                    style={{ width: "100px" }}
                                    onChange={(e) =>
                                      handleChange(
                                        e.target.value,
                                        ind,
                                        inx,
                                        "color2_rate"
                                      )
                                    }
                                  />
                                </div>
                                <div className="mx-2">
                                  <input
                                    disabled
                                    className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="number"
                                    value={price?.color3_rate}
                                    style={{ width: "100px" }}
                                    onChange={(e) =>
                                      handleChange(
                                        e.target.value,
                                        ind,
                                        inx,
                                        "color3_rate"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </td>
                          </div>
                        </tr>
                      </table>
                    );
                  })}
                  {/* <div className="d-flex justify-content-end w-65">
                    <button
                      className={`cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded mt-2 ${
                        btn && "opacity-50"
                      }`}
                      onClick={() => submitHandleVendorPrice(item)}
                      disabled={btn}
                    >
                      Edit
                    </button>
                  </div> */}
                </div>
              );
            })}
          </div>
          <button
            class="cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded mt-4"
            onClick={() => hidePricing()}
          >
            {showPricing ? "Hide Pricing" : "View Pricing"}
          </button>
          {showPricing && (
            <div className="overflow-scroll mt-4">
              {adminPrice?.map((item, ind) => {
                return (
                  <table className="w-100" key={ind}>
                    <tr className="mt-4">
                      <div className="">
                        <td>
                          <div className="d-flex w-100">
                            <div className="mx-2">
                              <p
                                className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                                style={{ width: "100px" }}
                              >
                                UOM
                              </p>
                            </div>
                            <div className="mx-2">
                              <p
                                className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                                style={{ width: "150px" }}
                              >
                                Inches
                              </p>
                            </div>
                            <div className="mx-2">
                              <p
                                className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                                style={{ width: "100px" }}
                              >
                                Color 1 Rate
                              </p>
                            </div>
                            <div className="mx-2">
                              <p
                                className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                                style={{ width: "100px" }}
                              >
                                Color 2 Rate
                              </p>
                            </div>
                            <div className="mx-2">
                              <p
                                className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                                style={{ width: "100px" }}
                              >
                                Color 3 Rate
                              </p>
                            </div>
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr className="mt-2">
                      <div className="my-3">
                        <td>
                          <div className="d-flex w-100">
                            <div className="mx-2">
                              <input
                                className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                value={item?.uom}
                                style={{ width: "100px" }}
                                onChange={(e) =>
                                  changeHandler(e.target.value, ind, "uom")
                                }
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                type="number"
                                value={item?.uom_count}
                                style={{ width: "150px" }}
                                onChange={(e) =>
                                  changeHandler(
                                    e.target.value,
                                    ind,
                                    "uom_count"
                                  )
                                }
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                type="number"
                                value={item?.color1_rate}
                                onChange={(e) =>
                                  changeHandler(
                                    e.target.value,
                                    ind,
                                    "color1_rate"
                                  )
                                }
                                style={{ width: "100px" }}
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                type="number"
                                value={item?.color2_rate}
                                onChange={(e) =>
                                  changeHandler(
                                    e.target.value,
                                    ind,
                                    "color2_rate"
                                  )
                                }
                                style={{ width: "100px" }}
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                type="number"
                                value={item?.color3_rate}
                                onChange={(e) =>
                                  changeHandler(
                                    e.target.value,
                                    ind,
                                    "color3_rate"
                                  )
                                }
                                style={{ width: "100px" }}
                              />
                            </div>
                          </div>
                        </td>
                      </div>
                    </tr>
                  </table>
                );
              })}
              {adminPriceErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Fill Price Details</p>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="d-flex mt-3 justify-content-start m-2">
          <button
            className={`mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
              btns ? "opacity-50" : !showPricing ? "opacity-50" : ""
            }`}
            onClick={() => submitHandle()}
            disabled={btn ? true : !showPricing ? true : false}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default DecorativeMethodEdit;
