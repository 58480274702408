import React, { useEffect, useState } from "react";
import { noLogo, searchIcon, toggleOff, toggleOn } from "../../assets/img";
import { useLocation, useNavigate } from "react-router-dom";
import { editIcon, viewIcon } from "../../assets/img";
import GetAppIcon from "@mui/icons-material/GetApp";
import AddEventCategory from "./AddEventCategory";
import EditEventCategory from "./EditEventCategory";
import {
  useAdminExportsMutation,
  useEventCategoryAssignMutation,
  useEventCategoryEditMutation,
  useEventCategoryListMutation,
  useEventCategoryStoreMutation,
  useLazyEventStatusQuery,
} from "../../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";
import ProductSearchHeader from "../ProductComp/ProductSearchHeader";

function EventCategory() {
  const navigate = useNavigate();
  const location = useLocation();
  const [list, setList] = useState([]);
  const [addPopUp, setAddPopUp] = useState(false);
  const [editPopUp, setEditPopUp] = useState(false);
  const [priority, setPriority] = useState("");

  let [dummiData, setDummiData] = useState([]);
  const [btn, setBtn] = useState(false);
  const dispatch = useDispatch();
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [imageErr, setImageErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [eventId, setEventID] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [pageRow, setPageRow] = useState(10);
  const [page, setPage] = useState(1);
  const [pagenation, setPagination] = useState({});
  const [show, setShow] = useState(false);

  //   Event Functions
  const handleClose = () => {
    setAddPopUp(!addPopUp);
    setImage("");
    setName("");
    setEventID("");
    setImageErr(false);
    setNameErr(false);
  };

  const handleOnSubmit = (e) => {
    setAddPopUp(!addPopUp);
    if (e && typeof e === "object") {
      Array.isArray(dummiData) && setDummiData([...dummiData, e]);
    }
  };

  const handleEditClose = () => {
    setEditPopUp(!editPopUp);
  };

  const handleChangePriority = (e) => {
    const { value } = e && e.target;
    if (value?.length) {
      let temp = value?.padStart(1, "0");
      setPriority(temp);
    } else {
      setPriority(value);
    }
  };

  const handleEditOnSubmit = (e) => {
    // console.log(e);
  };

  // const type = location?.state?.type;
  // console.log(location?.state?.type);
  // RTK QUERY
  const [eventCategoryStoreApi] = useEventCategoryStoreMutation();
  const [eventCategoryListApi] = useEventCategoryListMutation();
  const [eventCategoryEditApi] = useEventCategoryEditMutation();
  const [status] = useLazyEventStatusQuery();
  const [assignApi] = useEventCategoryAssignMutation();
  const [exportsApi] = useAdminExportsMutation();

  //save reference for dragItem and dragOverItem
  const dragItem = React.useRef(null);
  const dragOverItem = React.useRef(null);

  //const handle drag sorting
  const handleSort = () => {
    //duplicate items
    let navigateItems = [...list];

    //remove and save the dragged item content
    const draggedItemContent = navigateItems.splice(dragItem.current, 1)[0];

    //switch the position
    navigateItems.splice(dragOverItem.current, 0, draggedItemContent);

    //reset the position ref
    dragItem.current = null;
    dragOverItem.current = null;

    //update the actual array
    setList(navigateItems);
    setShow(true);
  };

  const submitHandle = () => {
    let formdata = new FormData();

    list?.map((item, ind) => {
      formdata.append(`eventCategories[${ind}][eventCategoryId]`, item?.id);
      formdata.append(`eventCategories[${ind}][priority]`, ind + 1);
    });

    setBtn(true);
    dispatch(saveLoader(true));
    assignApi(formdata)
      .unwrap()
      .then((res) => {
        toast.success("Saved Succesfully");
        getList();
        setBtn(false);
        dispatch(saveLoader(false));
        setShow(false);
      })
      .catch((err) => {
        setBtn(false);
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  // EVENT CATEGORY STORE
  const eventCatStore = () => {
    if (image?.length == 0 || name?.length == 0) {
      setImageErr(true);
      setNameErr(true);
    } else {
      let formdata = new FormData();

      if (image?.name) {
        formdata.append("image", image);
      }
      formdata.append("name", name);
      // formdata.append("priority", 1);

      if (eventId) {
        formdata.append("event_category_id", eventId);
        eventCategoryEditApi(formdata)
          .unwrap()
          .then((res) => {
            setBtn(false);
            dispatch(saveLoader(false));
            getList();
            setAddPopUp(false);
            setImage("");
            setName("");
            setBtn("");
            setEventID("");
            // setTypes(location?.state?.type)
            // getLibraryList(location?.state?.data?.id, location?.state?.type);
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });
      } else {
        eventCategoryStoreApi(formdata)
          .unwrap()
          .then((res) => {
            getList();
            setAddPopUp(false);
            setImage("");
            setName("");
            setBtn(false);
            dispatch(saveLoader(false));
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });
      }
    }
  };

  // EVENT CATEGORY STORE
  const getList = (event, row) => {
    let formdata = new FormData();
    // let params = `?page=${page}`;

    setSearchValue(event);

    if (event?.length > 0) {
      formdata.append("search", event);
    }

    if (row) {
      setPageRow(row);
      formdata.append("rows", row);
    }

    eventCategoryListApi(formdata)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          let temp = [...res?.lists];
          temp.sort((a, b) => a.priority - b.priority);
          console.log("temp", temp);
          setList(temp);
          // setPagination(res?.pagination_meta);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  // CHANGE STATUS
  const change = (id) => {
    status(id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          toast.success(res?.data?.message || res?.message);
          getList();
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  useEffect(() => {
    getList();
  }, [page]);

  const toggleShowPopup = (item) => {
    console.log("toggleShowPopup", item);
    setAddPopUp(!addPopUp);
    // setSelectItem(item)
    setImage(item?.image_url);
    setName(item?.name);
    setEventID(item?.id);
  };

  // EXPORT EXCEL
  const getAdminExports = () => {
    let formdata = new FormData();
    formdata.append("type", 12);
    exportsApi(formdata)
      .unwrap()
      .then((res) => {
        if (res?.download_link) {
          const link = document.createElement("a");
          link.href = res?.download_link;
          link.setAttribute("download", "");
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      <ProductSearchHeader />
      <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5">
        Event Category
      </p>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select
            className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary"
            onChange={(e) => getList(searchValue, e.target.value)}
          >
            <option value="10">10</option>
            <option value="10">15</option>
            <option value="10">20</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="d-flex flex-md-row flex-column">
          <div
            className="pointerView d-flex mt-4 me-3"
            role={"button"}
            onClick={getAdminExports}
          >
            <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Export
            </p>
          </div>
          <div className="d-flex mt-sm-3 border-search">
            <img alt="img" src={searchIcon} className="searchiConImg" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
              onChange={(e) => getList(e.target.value, pageRow)}
              value={searchValue}
            />
          </div>
          <button
            onClick={() =>
              setAddPopUp(
                !addPopUp
                // ,{ state: { type: "add" } }
              )
            }
            className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            + Add Category
          </button>
        </div>
      </div>
      {show && (
        <div className="d-flex justify-content-end">
          <button
            className={`cust-btn  bg-greens mt-sm-3 ms-1  text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
              btn && "opacity-50"
            }`}
            onClick={() => submitHandle()}
            disabled={btn ? true : false}
            style={{ padding: "10px" }}
          >
            Save Changes
          </button>
        </div>
      )}
      <div className="mt-5" style={{ width: "100%", overflow: "auto" }}>
        <table className="table-design-new" style={{ width: "100%" }}>
          <thead>
            <tr className="border-bottom w-100">
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  S.No
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Event Image
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Event Name
                </p>
              </th>
              {/* <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Priority
                </p>
              </th> */}
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Status
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Action
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {/* {Array.isArray(dummiData) &&
              dummiData?.map((item, index) => { */}
            {list?.map((item, index) => {
              // let src = item?.image !=='' ? URL.createObjectURL(item?.image) : item?.image;
              return (
                <tr
                  key={index}
                  className="list-item"
                  draggable
                  onDragStart={(e) => (dragItem.current = index)}
                  onDragEnter={(e) => (dragOverItem.current = index)}
                  onDragEnd={handleSort}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <td>
                    <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {index + 1}
                    </p>
                  </td>
                  <td>
                    <div className="table_img_container">
                      <img
                        src={item?.image_url ? item?.image_url : noLogo}
                        alt="img"
                        className="table_img  mt-2 "
                      />
                    </div>
                  </td>
                  <td>
                    <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {item?.name}
                    </p>
                  </td>
                  {/* <td>
                      <div className="d-flex ac-jc">
                        <input
                          className="w-20"
                          value={priority+index || ""}
                          onChange={(e) => handleChangePriority(e)}
                          type="number"
                        />
                      </div>
                    </td> */}
                  <td>
                    <div
                      className="d-flex ac-jc"
                      onClick={() => change(item?.id)}
                    >
                      {item?.status === 0 ? (
                        <div>
                          <img
                            alt="img"
                            src={toggleOff}
                            className="toggleOnDes"
                          />
                        </div>
                      ) : (
                        <div>
                          <img
                            alt="img"
                            src={toggleOn}
                            className="toggleOnDes"
                          />
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex ac-jc primary1">
                      <div
                        onClick={() => toggleShowPopup(item)}
                        // onClick={() => setEditPopUp(!editPopUp)}
                        className="viewBoxDes ms-2 p-2 mb-2 bg-white rounded-2"
                      >
                        <img alt="img" src={editIcon} className="viewDes" />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* <div className="d-flex justify-content-between mt-3">
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Previous
        </button>
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Next
        </button>
      </div> */}
      {addPopUp && (
        <AddEventCategory
          handleClose={handleClose}
          handleOnSubmit={handleOnSubmit}
          image={image}
          name={name}
          imageErr={imageErr}
          nameErr={nameErr}
          setImage={setImage}
          setName={setName}
          eventCatStore={eventCatStore}
          btn={btn}
          list={list}
          change={change}
          // type={type}
        />
      )}
      {editPopUp && (
        <EditEventCategory
          // <AddEventCategory
          handleClose={handleEditClose}
          handleOnSubmit={handleEditOnSubmit}
        />
      )}
    </div>
  );
}

export default EventCategory;
