import React, { useEffect, useState } from "react";
import { emptyImg, product, profilePic, uploadIcon } from "../../../assets/img";
import Carousel from "react-bootstrap/Carousel";
import { productDetailImg } from "../../../redux/api/constants";
import { EditAttributes } from "@mui/icons-material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { owl_List, product_image } from "../../../redux/api/DummyJson";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ImageCroper from "../../Popup/ImageCroper";

const ImageUplodeComp = ({ prods, ind, vendor, vendor2, edit, colorImg }) => {
  const [img, setImg] = useState("");
  useEffect(() => {
    if (prods?.[ind]?.productImage?.length > 0) {
      let finder = prods?.[ind]?.productImage?.find((i) => i?.is_default == 1);
      if (finder?.id) {
        setImg(finder?.image_url);
      } else {
        setImg(
          prods?.[ind]?.productImage?.[prods?.[ind]?.productImage?.length - 1]
            ?.image_url
        );
      }
      // console.log("im");
    } else {
      console.log("image");
      setImg(prods?.[ind]?.defaultImage);
    }
  }, [prods]);

  return (
    <div className="d-flex flex-column gap-3 ac-jc w-100">
      <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-2 text-center mb-3">
        Product Image
      </p>
      <div className="d-flex ac-jc flex-column">
        <div>
          <label className="img_up_cont d-flex ac-jc cp">
            <div className="d-flex ac-jc flex-column">
              <div className="position-relative">
                <img
                  className={
                    img ? "img_up_cont" : "placeholder_icon object-fit-contain"
                  }
                  src={img ? img : uploadIcon}
                />
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default ImageUplodeComp;
