import React, { useEffect, useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import { uploadIcon } from "../assets/img";
import FormComp from "../components/DecorativeComp/FormComp";
import ImageUplodeComp from "../components/DecorativeComp/ImageUplodeComp";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useDecorativeListsMutation,
  useDecorativeMethodeAddedMutation,
  useDecorativeMethodeViewPostMutation,
  useDecorativeVendorPriceEditMutation,
  useLazyDecorativeListQuery,
} from "../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";

const AddDecorativeVendorScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const vendor = location?.state?.name;
  const vendor2 = location?.state?.data;
  const vendors = location?.state?.vendors;
  const id = location?.state?.id;
  const [list, setList] = useState([]);
  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState(false);
  const [decorativeId, setDecorativeId] = useState("");
  const [decorativeMethod, setDecorativeMethod] = useState("");
  const [decorativeMethodErr, setDecorativeMethodErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const [priceErr, setPriceErr] = useState(false);

  const [adminPrice, setAdminPrice] = useState([
    {
      uom: "Stitches",
      uom_count: "",
      color1_rate: "",
      color2_rate: "",
      color3_rate: "",
      id: "",
    },
    {
      uom: "Inches",
      uom_count: "",
      color1_rate: "",
      color2_rate: "",
      color3_rate: "",
      id: "",
    },
  ]);
  // console.log(adminPrice, "adminPrice");

  const [description, setDescription] = useState("");
  //ERR
  const [descriptionErr, setDescriptionErr] = useState(false);
  const [adminPriceErr, setAdminPriceErr] = useState(false);
  const [showPricing, setShowPricing] = useState(false);
  //RTK
  const [decorativeListsApi] = useLazyDecorativeListQuery();
  const [decorativeAddedApi] = useDecorativeMethodeAddedMutation();
  const [decorativeApi] = useDecorativeMethodeViewPostMutation();
  const [decorativeEdit] = useDecorativeVendorPriceEditMutation();

  const getdecorApi = () => {
    let formdata = new FormData();
    formdata.append("vendor_id", location?.state?.data?.id);
    decorativeApi({ payload: formdata, id: id?.id })
      .unwrap()
      .then((res) => {
        console.log(res);
        const data = res?.vendor_decor_price;
        setImage(data?.decor_method_price?.[0]?.image_url);
        setDecorativeMethod(data?.name);
        setDescription(data?.decor_method_price?.[0]?.description);
        setAdminPrice(data?.decor_method_price);
        setDecorativeId(data?.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (location?.state?.type == "edit") {
      getdecorApi();
    }
  }, []);

  const getList = () => {
    decorativeListsApi()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          // console.log(res,"res")
          setList(res?.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // DECORATION SUBMIT
  // console.log(location, "vendors");
  const onSubmit = () => {
    let stitchesCheck = false;
    let inchesCheck = false;
    let inches = adminPrice?.[0];
    let stitches = adminPrice?.[1];

    if (
      !inches?.uom_count ||
      !inches?.color1_rate ||
      !inches?.color2_rate ||
      !inches?.color3_rate
    ) {
      inchesCheck = true;
    }

    if (
      !stitches?.uom_count ||
      !stitches?.color1_rate ||
      !stitches?.color2_rate ||
      !stitches?.color3_rate
    ) {
      stitchesCheck = true;
    }

    if (
      description?.length == "" ||
      decorativeMethod?.length == "" ||
      image?.length == "" ||
      (stitchesCheck && inchesCheck)
    ) {
      setDescriptionErr(true);
      setDecorativeMethodErr(true);
      setImageErr(true);
      setAdminPriceErr(true);
      // setPriceErr(true)
    } else {
      const formData = new FormData();
      if (image?.name) {
        formData.append("image", image);
      }
      formData.append("vendor_id", vendors);
      formData.append("decorative_method_id", decorativeId);
      formData.append("description", description);

      setBtn(true);
      dispatch(saveLoader(true));
      if (location?.state?.type == "edit") {
        adminPrice?.map((item, ind) => {
          if (item?.uom != null) {
            formData.append(`decor_price_details[${ind}][uom]`, item?.uom);
          }

          if (item?.uom_count != null) {
            formData.append(
              `decor_price_details[${ind}][uom_count]`,
              item?.uom_count
            );
          } else {
            formData.append(`decor_price_details[${ind}][uom_count]`, "");
          }
          if (item?.color1_rate != null) {
            formData.append(
              `decor_price_details[${ind}][color1_rate]`,
              item?.color1_rate
            );
          } else {
            formData.append(`decor_price_details[${ind}][color1_rate]`, "");
          }
          if (item?.color2_rate != null) {
            formData.append(
              `decor_price_details[${ind}][color2_rate]`,
              item?.color2_rate
            );
          } else {
            formData.append(`decor_price_details[${ind}][color2_rate]`, "");
          }
          if (item?.color3_rate != null) {
            formData.append(
              `decor_price_details[${ind}][color3_rate]`,
              item?.color3_rate
            );
          } else {
            formData.append(`decor_price_details[${ind}][color3_rate]`, "");
          }
          formData.append(
            `decor_price_details[${ind}][decor_price_id]`,
            item?.id
          );
        });
        // formData.append("decor_price_id", id?.id);
        decorativeEdit(formData)
          .unwrap()
          .then((res) => {
            setBtn(false);
            dispatch(saveLoader(false));
            navigate("/product-vendor-details", {
              state: { tab: 4, data: vendor2, name: "John Rolph" },
            });
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false));
            console.log(err);
          });
      } else {
        adminPrice?.map((item, ind) => {
          if (item?.uom != "null") {
            formData.append(`decor_price_details[${ind}][uom]`, item?.uom);
          }

          if (item?.uom_count != "null") {
            formData.append(
              `decor_price_details[${ind}][uom_count]`,
              item?.uom_count
            );
          }
          if (item?.color1_rate != "null") {
            formData.append(
              `decor_price_details[${ind}][color1_rate]`,
              item?.color1_rate
            );
          }
          if (item?.color2_rate != "null") {
            formData.append(
              `decor_price_details[${ind}][color2_rate]`,
              item?.color2_rate
            );
          }
          if (item?.color3_rate != "null") {
            formData.append(
              `decor_price_details[${ind}][color3_rate]`,
              item?.color3_rate
            );
          }
        });
        decorativeAddedApi(formData)
          .unwrap()
          .then((res) => {
            setBtn(false);
            dispatch(saveLoader(false));
            navigate("/product-vendor-details", {
              state: { tab: 4, data: vendor2, name: "John Rolph" },
            });
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });
      }
    }
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (showPricing) {
      let stitchesCheck = false;
      let inchesCheck = false;
      let inches = adminPrice?.[0];
      let stitches = adminPrice?.[1];

      if (
        !inches?.uom_count ||
        !inches?.color1_rate ||
        !inches?.color2_rate ||
        !inches?.color3_rate
      ) {
        inchesCheck = true;
      }

      if (
        !stitches?.uom_count ||
        !stitches?.color1_rate ||
        !stitches?.color2_rate ||
        !stitches?.color3_rate
      ) {
        stitchesCheck = true;
      }

      if (stitchesCheck && inchesCheck) {
        setAdminPriceErr(true);
      } else {
        setAdminPriceErr(false);
      }
    }
  }, [adminPrice]);

  console.log("adminPrice", adminPrice);

  // console.log(vendor);
  return (
    <div className="dashRightView p-5 home_section trans">
      <ProductSearchHeader />
      <p
        className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 d-flex align-items-center gap-1"
        role={"button"}
        // onClick={() =>  navigate("/product-vendor-details", {
        //   state: { tab: 4, data: vendor2, name: "John Rolph" },
        // });}
        onClick={() =>
          navigate("/product-vendor-details", {
            state: { tab: 4, data: vendor2, name: "John Rolph" },
          })
        }
      >
        <KeyboardBackspaceIcon />{" "}
        {location?.state?.type == "edit"
          ? "Edit New Decoration"
          : "Add New Decoration"}
      </p>
      <div className="d-flex as-jb w-100 mt-5 res-flex gap-5">
        <FormComp
          type={location?.state?.type}
          priceErr={priceErr}
          vendor={vendor}
          list={list}
          setAdminPrice={setAdminPrice}
          adminPrice={adminPrice}
          description={description}
          setDescription={setDescription}
          setDecorativeId={setDecorativeId}
          decorativeId={decorativeId}
          onSubmit={onSubmit}
          descriptionErr={descriptionErr}
          btn={btn}
          setDecorativeMethod={setDecorativeMethod}
          decorativeMethod={decorativeMethod}
          decorativeMethodErr={decorativeMethodErr}
          setPriceErr={setPriceErr}
          adminPriceErr={adminPriceErr}
          setShowPricing={setShowPricing}
        />
        <ImageUplodeComp
          vendor={vendor}
          vendor2={vendor2}
          setImage={setImage}
          image={image}
          imageErr={imageErr}
        />
      </div>
    </div>
  );
};

export default AddDecorativeVendorScreen;
