import React, { useEffect, useRef, useState } from "react";
import {
  product,
  profilePic,
  toggleOff,
  toggleOn,
  uploadIcon,
} from "../../assets/img";
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  CheckCircleOutline,
  CheckCircleOutlineOutlined,
  Circle,
  CircleOutlined,
  DeleteForever,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import ImageCroper from "../Popup/ImageCroper";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useProductsCurrentStatusChangeMutation } from "../../redux/api/api";
import { countriesList } from "../../redux/api/Country";
import ErrorIcon from "@mui/icons-material/Error";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Col } from "react-bootstrap";
import { Select_list } from "../../redux/api/DummyJson";
import { toast } from "react-hot-toast";

const OverviewTab = ({
  addVariants,
  edit,
  vendor,
  selColors,
  setSelColors,
  parentCategoryList,
  getCategoryId,
  subCategoryList,
  setSubCategoryList,
  subCategoryLists,
  setSubCategoryLists,
  setParentCategory,
  decorativeList,
  decorativeID,
  setDecorativeID,
  decorativeName,
  setDecorativeName,
  country,
  setCountry,
  hsnList,
  hsnCode,
  setHsnCode,
  hsnName,
  setHsnName,
  tagsList,
  tags,
  setTags,
  tagsName,
  setTagsName,
  colorsList,
  colorsID,
  setColorsID,
  colorsName,
  setColorsName,
  varientCombination,
  varientShow,
  sizes,
  setSizes,
  materials,
  setMeterials,
  styles,
  setStyles,
  allVarients,
  deleteVarient,
  catogoryName,
  setCatogoryName,
  searchValueSubCategory,
  setSearchSubValueCategory,
  productSuplierCode,
  setProductSuplierCode,
  onSubCategorySelect,
  categoryID,
  setCategoryID,
  name,
  setName,
  mrp,
  setMrp,
  decorationMethod,
  setDecorationMethod,
  productDescription,
  setProductDescription,
  productPackageSize,
  setProductPackageSize,
  productPackageSizeErr,
  setProductPackageSizeErr,
  productDescriptionErr,
  setProductDescriptionErr,
  decorationMethodErr,
  setDecorationMethodErr,
  mrpErr,
  setMrpErr,
  nameErr,
  setNameErr,
  countryErr,
  setCountryErr,
  categoryIDErr,
  setCategoryIDErr,
  setGendor,
  gendor,
  gendorErr,
  secondaryCategoryId,
  setSecondaryCategoryId,
  primaryCategoryId,
  setPrimaryCategoryId,
  submitHandle,
  attributesList,
  productInformation,
  setProductInformation,
  varientsList,
  setVarientList,
  colorsData,
  setColorsData,
  submitVarient,
  primaryCategoryIdErr,
  secondaryCategoryIdErr,
  setTagVal,
  hsnCodeErr,
  tagsIconName,
  setTagsIconName,
  tagErr,
  tagVal,
  tagEventErr,
  tagIconErr,
  setTagEventVal,
  setTagIconVal,
  tagEventVal,
  tagIconVal,
  btn,
  submitBtn,
  catogory,
  setCatogory,
  listAttributes,
  setListAttributes,
  dynamicAttr,
  setDynamicAttr,
  viewData,
  setSmallImage,
  setSmallMockImage,
  smallimage,
  smallMockimage,
  productImg,
  setProductImg,
  productImgErr,
  setProductImgErr,
  morkupImg,
  setMorkupImg,
  morkupImgErr,
  setMorkupImgErr,
  colorsImg,
  setColorsImg,
  colorImgErr,
  setColorImgErr,
  getViewProduct,
  deleteImg,
  otherProInfoErr,
  productInformationErr,
  otherProInfo,
  setOtherProInfo,
  sizesErr,
  materialsErr,
  stylesErr,
  attributesName,
  setAttributesName,
  colorVarientErr,
  setColorVarientErr,
  setColorVarient,
  colorVarient,
  addVarientErr,
  setAddVarientErr,
  varientsCheck,
  setVarientsCheck,
  colorRep,
  submitData,
  tagsEventList,
  tagsIconList,
  tagsEventName,
  setTagsEventName,
  tagsIcon,
  setTagsIcon,
  tagsEvent,
  setTagsEvent,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [productCurrentStatusApi] = useProductsCurrentStatusChangeMutation();

  // Toggle List
  const [catogoryShow, setCatogoryShow] = useState(false);
  const [catogoryShow2, setCatogoryShow2] = useState(null);
  const [dropDown, setDropDown] = useState(null);
  const [subcatogory, setSubcatogory] = useState(false);
  const [Dropdown, setDrop] = useState(0);
  const [showDynamicAttr, setShowDynamicAttr] = useState(false);
  const [showVarient, setShowVarient] = useState(false);
  const [attributesShow, setAttributesShow] = useState(false);
  const [varients, setVarients] = useState(null);

  const [catCheck, setCatCheck] = useState(true);

  // Select Fuctction
  const [check, setCheck] = useState([]);
  const [decorativeLists, setDecorativeLists] = useState([]);
  const [selectALLSub, setSelectALLSub] = useState(true);
  const [selectALLTags, setSelectALLTags] = useState(true);
  const [selectALLTagsEvent, setSelectALLTagsEvent] = useState(true);
  const [selectALLTagsIcon, setSelectALLTagsIcon] = useState(true);
  const [selectALLProInfo, setSelectALLProInfo] = useState(true);
  const [selectALLColors, setSelectALLColors] = useState(true);
  const [searchValueSub, setSearchValueSub] = useState("");
  const [listCountries, setListCountries] = useState([]);
  const [listHSN, setListHSN] = useState([]);
  const [searchValueCountry, setSearchValueCountry] = useState("");
  const [searchValueGendor, setSearchValueGendor] = useState("");
  const [searchHsn, setSearchHsn] = useState("");
  const [listTags, setListTags] = useState([]);
  const [listIconTags, setListIconTags] = useState([]);
  const [listEventTags, setListEventTags] = useState([]);
  const [searchTags, setSearchTags] = useState("");
  const [searchEventTags, setSearchEventTags] = useState("");
  const [searchIconTags, setSearchIconTags] = useState("");
  const [searchProInfo, setSearchProInfo] = useState("");
  const [searchColors, setSearchColors] = useState("");
  const [listColors, setListColors] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [searchValueCategory, setSearchValueCategory] = useState("");
  const [list, setList] = useState({});

  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();
  // const [textShow2, setTextShow2] = useState(false)
  const [croperCol, setCroperCol] = useState(false);
  // const [smallimage, setSmallImage] = useState([]);
  const [colorImg, setColorImg] = useState(null);
  const [bigImage, setBigImage] = useState({
    image: "",
    ind: "",
    is_default: 0,
  });
  const [mockImage, setMockImage] = useState({
    image: "",
    ind: "",
    is_default: 0,
  });
  const [fistShow, setFirstShow] = useState(false);
  const [fistShows, setFirstShows] = useState(false);
  // console.log(vendor);
  const [logo, setLogo] = useState({});
  const [logo1, setLogo1] = useState({});
  const [textShow, setTextShow] = useState(true);
  const [textShows, setTextShows] = useState(true);
  const [indexs, setIndexs] = useState(0);
  // console.log(vendor2);

  const proImgRef = useRef();
  const morkupImgRef = useRef();
  const colorImgRef = useRef();
  const [proIndex, setProIndex] = useState(0);
  const [morkupIndex, setMorkupIndex] = useState(0);
  const [colorIndex, setColorIndex] = useState(0);

  const gendorList = ["NA", "U", "M", "F", "K"];
  const [listGendor, setListGendor] = useState([]);

  const [checkBox, setCheckBox] = useState(false);

  useEffect(() => {
    if (decorativeList?.length > 0) {
      setDecorativeLists(decorativeList);
    }
    if (hsnList?.length > 0) {
      setListHSN(hsnList);
    }
    if (tagsList?.length > 0) {
      setListTags(tagsList);
    }

    if (tagsEventList?.length > 0) {
      setListEventTags(tagsEventList);
    }
    if (tagsIconList?.length > 0) {
      setListIconTags(tagsIconList);
    }

    if (colorsList?.length > 0) {
      setListColors(colorsList);
    }
    if (parentCategoryList?.length > 0) {
      setCategoryList(parentCategoryList);
    }

    if (attributesList?.length > 0) {
      setListAttributes(attributesList);
    }

    setListGendor(gendorList);
    setListCountries(countriesList);
  }, [
    decorativeList,
    hsnList,
    tagsList,
    colorsList,
    parentCategoryList,
    attributesList,
    tagsIconList,
    tagsEventList,
  ]);

  // SEARCH CATEGROY
  const searchCategory = (event) => {
    let temp = [...parentCategoryList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setCategoryList(search);
    } else {
      setCategoryList(parentCategoryList);
    }

    setSearchValueCategory(event);
  };

  // SUB CATEGORY SEARCH
  const searchSubCatFun = (event, ind) => {
    let temp = [...subCategoryLists];
    let tempX = temp[ind];
    let temps = [...subCategoryList];
    let tempXs = temps[ind];
    let search = tempX?.child_categories?.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    // console.log("search", search);

    tempXs = search;

    let obj = {
      ...subCategoryList[ind],
      child_categories: tempXs,
    };

    let arr = [...subCategoryList];
    arr[ind] = obj;

    // console.log("tempXs", obj, arr);

    if (event?.length > 0) {
      setSubCategoryList(arr);
    } else {
      // console.log("jjj");
      setSubCategoryList(subCategoryLists);
    }

    setSearchSubValueCategory((val) => ({ ...val, [ind]: event }));
  };

  // SELECT CATEGORY
  const onCategorySelct = (item) => {
    setCatogoryName({ [item?.id]: "" });
    setCatogoryShow(false);
    setCatogory(item?.name);
    setPrimaryCategoryId(item?.id);
    setSubcatogory(true);
    setProductSuplierCode("");
    getCategoryId(item?.id, "category");
    setCategoryList(parentCategoryList);

    setSearchValueCategory("");
    setDropDown(null);
    // setParentCategory(item?.id);
  };

  // Decoration Method START
  const allSelectSub = () => {
    let decorativeListId = [];
    let decorativeListName = [];
    if (selectALLSub) {
      decorativeList?.map((item, ind) => {
        decorativeListId.push(item?.id);
        decorativeListName.push(item?.name);
        setDecorationMethod(item?.id);
      });

      setDecorativeID(decorativeListId);
      setDecorativeName(decorativeListName);
    } else {
      setDecorativeID(decorativeListId);
      setDecorativeName(decorativeListName);
    }
  };

  const searchSub = (event) => {
    let temp = [...decorativeList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setDecorativeLists(search);
    } else {
      setDecorativeLists(decorativeList);
    }

    setSearchValueSub(event);
  };

  const onSubSelect = (event, id, type) => {
    let temp = [...decorativeName];
    let temps = [...decorativeID];

    if (temp.includes(event)) {
      const listtemp = temp.indexOf(event);
      const list = [...temp];
      list.splice(listtemp, 1);
      setDecorativeName(list);
    } else {
      setDecorativeName([...temp, event]);
    }

    if (temps.includes(id)) {
      const listtemp = temps.indexOf(id);
      const list = [...temps];
      list.splice(listtemp, 1);
      setDecorativeID(list);
    } else {
      setDecorativeID([...temps, id]);
    }

    if (type !== "check") {
      setDropDown(null);
    }

    setSearchValueSub("");
    setDecorativeLists(decorativeList);
    setDecorationMethod(id);
  };
  // Decoration Method END

  // COUNTY START
  const searchCountry = (event) => {
    let temp = [...countriesList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );
    if (event?.length > 0) {
      setListCountries(search);
    } else {
      setListCountries(countriesList);
    }

    setSearchValueCountry(event);
  };

  const getCountry = (event) => {
    setDropDown(null);
    setCountry(event);
    setSearchValueCountry("");
    setListCountries(countriesList);
  };
  // COUNTY END

  // GENDOR SEARCH
  const searchGendor = (event) => {
    let temp = [...gendorList];
    let search = temp.filter((value) =>
      value?.toLowerCase()?.includes(event?.toLowerCase())
    );
    if (event?.length > 0) {
      setListGendor(search);
    } else {
      setListGendor(gendorList);
    }

    setSearchValueGendor(event);
  };

  // GENDOR SELECT
  const onSelectGendor = (item) => {
    setGendor(item);
    setDropDown(null);
    setSearchValueGendor("");
    setListGendor(gendorList);
  };

  // HSN START
  const searchHSNValue = (event) => {
    let temp = [...hsnList];
    let search = temp.filter((value) =>
      value?.code?.toLowerCase()?.includes(event?.toLowerCase())
    );
    if (event?.length > 0) {
      setListHSN(search);
    } else {
      setListHSN(hsnList);
    }

    setSearchHsn(event);
  };

  const getHSN = (event) => {
    setDropDown(null);
    setHsnCode(event?.id);
    setHsnName(event?.code);
    setSearchHsn("");
    setListHSN(hsnList);
  };
  // HSN END

  // TAGS START
  const allSelectTags = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLTags) {
      tagsList?.map((item, ind) => {
        ListId.push(item?.id);
        ListName.push(item?.name);
        setTagVal(item?.id);
      });

      setTags(ListId);
      setTagsName(ListName);
    } else {
      setTags(ListId);
      setTagsName(ListName);
    }

    setSelectALLTags(!selectALLTags);
  };

  const onTagsSelect = (event, id, type) => {
    let temp = [...tags];
    let temps = [...tagsName];

    if (temp.includes(id)) {
      const listtemp = temp.indexOf(id);
      const list = [...temp];
      list.splice(listtemp, 1);
      setTags(list);
    } else {
      setTags([...temp, id]);
    }

    if (temps.includes(event)) {
      const listtemp = temps.indexOf(event);
      const list = [...temps];
      list.splice(listtemp, 1);
      setTagsName(list);
    } else {
      setTagsName([...temps, event]);
    }

    if (type !== "check") {
      setDropDown(null);
    }

    setSearchTags("");
    setListTags(tagsList);
    setTagVal(id);
  };

  const searchTagsFun = (event) => {
    let temp = [...tagsList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setListTags(search);
    } else {
      setListTags(tagsList);
    }

    setSearchTags(event);
  };
  // TAGS END

  // TAGS EVENT START
  const allSelectTagsEvent = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLTagsEvent) {
      tagsEventList?.map((item, ind) => {
        ListId.push(item?.id);
        ListName.push(item?.name);
        setTagIconVal(item?.id);
      });

      setTagsEvent(ListId);
      setTagsEventName(ListName);
    } else {
      setTagsEvent(ListId);
      setTagsEventName(ListName);
    }

    setSelectALLTagsEvent(!selectALLTagsEvent);
  };

  const onTagsSelectEvent = (event, id, type) => {
    let temp = [...tagsEvent];
    let temps = [...tagsEventName];

    if (temp.includes(id)) {
      const listtemp = temp.indexOf(id);
      const list = [...temp];
      list.splice(listtemp, 1);
      setTagsEvent(list);
    } else {
      setTagsEvent([...temp, id]);
    }

    if (temps.includes(event)) {
      const listtemp = temps.indexOf(event);
      const list = [...temps];
      list.splice(listtemp, 1);
      setTagsEventName(list);
    } else {
      setTagsEventName([...temps, event]);
    }

    if (type !== "check") {
      setDropDown(null);
    }

    setSearchEventTags("");
    setListEventTags(tagsEventList);
    console.log("id", id);
    setTagEventVal(id);
  };

  const searchTagsFunEvent = (event) => {
    let temp = [...tagsEvent];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setListEventTags(search);
    } else {
      setListEventTags(tagsList);
    }

    setSearchEventTags(event);
  };
  // TAGS EVENT END

  // TAGS ICON START
  const allSelectTagsIcon = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLTagsIcon) {
      tagsIconList?.map((item, ind) => {
        ListId.push(item?.id);
        ListName.push(item?.name);
        setTagIconVal(item?.id);
      });

      setTagsIcon(ListId);
      setTagsIconName(ListName);
    } else {
      setTagsIcon(ListId);
      setTagsIconName(ListName);
    }

    setSelectALLTagsIcon(!selectALLTagsIcon);
  };

  const onTagsSelectIcon = (event, id, type) => {
    let temp = [...tagsIcon];
    let temps = [...tagsIconName];

    if (temp.includes(id)) {
      const listtemp = temp.indexOf(id);
      const list = [...temp];
      list.splice(listtemp, 1);
      setTagsIcon(list);
    } else {
      setTagsIcon([...temp, id]);
    }

    if (temps.includes(event)) {
      const listtemp = temps.indexOf(event);
      const list = [...temps];
      list.splice(listtemp, 1);
      setTagsIconName(list);
    } else {
      setTagsIconName([...temps, event]);
    }

    if (type !== "check") {
      setDropDown(null);
    }

    setSearchIconTags("");
    setListIconTags(tagsIconList);
    setTagIconVal(id);
  };

  const searchTagsFunIcon = (event) => {
    let temp = [...tagsIcon];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setListIconTags(search);
    } else {
      setListIconTags(tagsList);
    }

    setSearchIconTags(event);
  };
  // TAGS ICON END

  // COLORS START
  const allSelectColors = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLColors) {
      colorsList?.map((item, ind) => {
        ListId.push(item);
        ListName.push(item?.name);
      });

      setColorsID(ListId);
      setColorsName(ListName);
      setColorsData(colorsList);
    } else {
      setColorsID(ListId);
      setColorsName(ListName);
      setColorsData([]);
    }

    setSelectALLColors(!selectALLColors);
  };

  const onColorsSelect = (event, id, item, ind, type) => {
    let temp = [...colorsID];
    let temps = [...colorsName];
    let temx = [...colorsData];

    console.log("item", item);

    if (temp.find((i) => i.color_id == id)) {
      let finder = temp.filter((i) => i.color_id !== id);
      setColorsID(finder);
    } else {
      temp.push(item);
      setColorsID(temp);
    }

    if (temx.find((i) => i.color_id == id)) {
      let finder = temx.filter((i) => i.color_id !== id);
      setColorsData(finder);
    } else {
      temx.push(item);
      setColorsData(temx);
    }

    if (temps.includes(event)) {
      const listtemp = temps.indexOf(event);
      const list = [...temps];
      list.splice(listtemp, 1);
      setColorsName(list);
    } else {
      setColorsName([...temps, event]);
    }

    if (type !== "check") {
      setVarients(null);
    }

    setColorVarient(id);
    setSearchColors("");
    setListColors(colorsList);
    setVarientList([]);
  };

  // console.log("colorsData", colorsData);
  // console.log("colorsList", colorsList);

  const searchColorsFun = (event) => {
    let temp = [...colorsList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setListColors(search);
    } else {
      setListColors(colorsList);
    }

    setSearchColors(event);
  };
  // COLORS END

  // SIZE START
  const handleAdd = () => {
    const abc = [...sizes];
    abc.push({ value: "" });
    setSizes(abc);
    setVarientList([]);
  };
  const handleChange = (event, ind, type) => {
    const inputdata = [...sizes];
    let obj = {
      ...inputdata[ind],
      value: event,
    };
    inputdata[ind] = obj;
    setSizes(inputdata);
    setVarientList([]);
  };
  const handleDelete = (i) => {
    const deletVal = [...sizes];
    deletVal.splice(i, 1);
    setSizes(deletVal);
    setVarientList([]);
  };
  // SIZE END

  // MATERIAL START
  const handleMatAdd = () => {
    const abc = [...materials];
    abc.push({ value: "" });
    setMeterials(abc);
    setVarientList([]);
  };
  const handleMatChange = (event, ind, type) => {
    const inputdata = [...materials];
    let obj = {
      ...inputdata[ind],
      value: event,
    };
    inputdata[ind] = obj;
    setMeterials(inputdata);
    setVarientList([]);
  };
  const handleMatDelete = (i) => {
    const deletVal = [...materials];
    deletVal.splice(i, 1);
    setMeterials(deletVal);
    setVarientList([]);
  };
  // MATERIAL END

  // STYLES START
  const handleStyleAdd = () => {
    const abc = [...styles];
    abc.push({ value: "" });
    setStyles(abc);
    setVarientList([]);
  };
  const handleStyleChange = (event, ind, type) => {
    const inputdata = [...styles];
    let obj = {
      ...inputdata[ind],
      value: event,
    };
    inputdata[ind] = obj;
    setStyles(inputdata);
    setVarientList([]);
  };
  const handleStyleDelete = (i) => {
    const deletVal = [...styles];
    deletVal.splice(i, 1);
    setStyles(deletVal);
    setVarientList([]);
  };
  // STYLES END

  const onDynamicAttributeSelect = (event, id, ind) => {
    let temp = [...productInformation];

    let finder = temp.find((i) => i.id == id);

    if (finder?.id) {
      let inx = temp.findIndex((i) => i.id == id);
      temp.splice(inx, 1);
    } else {
      temp.push({
        id: id,
        title: event,
        value: "",
      });
    }

    setProductInformation(temp);

    if (dynamicAttr.includes(event)) {
      const listtemp = dynamicAttr?.indexOf(event);
      const list = [...dynamicAttr];
      list.splice(listtemp, 1);
      setDynamicAttr(list);
    } else {
      setDynamicAttr([...dynamicAttr, event]);
    }

    setListAttributes(attributesList);
    if (otherProInfo == id) {
      setOtherProInfo("");
    } else {
      setOtherProInfo(id);
    }
    setSearchProInfo("");
  };

  // handle Product Information
  const handleProductInformation = (event, ind) => {
    let temp = [...productInformation];
    temp[ind]["value"] = event;
    setProductInformation(temp);
  };

  const onColorSelect = (e) => {
    if (selColors.includes(e)) {
      const listtemp = selColors.indexOf(e);
      const list = [...selColors];
      list.splice(listtemp, 1);
      setSelColors(list);
    } else {
      setSelColors([...selColors, e]);
    }
  };

  const onClickShowVarient = () => {
    setShowVarient(!showVarient);
  };

  const onAttribute = (e) => {
    setAttributesName(e.target.value);
    if (decorativeName.length >= 2) {
      setAttributesShow(true);
    } else {
      if (decorativeName.length >= 0) setAttributesShow(false);
    }
  };
  const onAttributeClick = (e) => {
    if (attributesName?.includes(e)) {
      const listtemp = attributesName?.indexOf(e);
      const list = [...attributesName];
      list.splice(listtemp, 1);
      setAttributesName(list);

      if (e == "Size") {
        setSizes([{ value: "" }]);
      }

      if (e == "Color") {
        setColorsID([]);
        setColorsName([]);
        setColorsData([]);
        setColorVarient("");
      }

      if (e == "Materials") {
        setMeterials([{ value: "" }]);
      }

      if (e == "Style") {
        setStyles([{ value: "" }]);
      }
    } else {
      setAttributesName([...attributesName, e]);
    }
    setVarientsCheck(e);

    setVarientList([]);
  };

  // TAGS START
  const allSelectProductInfo = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLProInfo) {
      attributesList?.map((item, ind) => {
        ListId.push({ id: item?.id, title: item?.name, value: "" });
        ListName.push(item?.name);
      });

      setProductInformation(ListId);
      setDynamicAttr(ListName);
    } else {
      setProductInformation(ListId);
      setDynamicAttr(ListName);
    }

    setSelectALLProInfo(!selectALLProInfo);
  };

  const searchProductInfoFun = (event) => {
    let temp = [...attributesList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setListAttributes(search);
    } else {
      setListAttributes(attributesList);
    }

    setSearchProInfo(event);
  };
  // TAGS END

  // console.log("catogoryName", catogoryName);

  // console.log("allVarients", allVarients);

  const getView = () => {
    let temp = [];

    // if (viewData?.variant_master?.color?.length > 0) {
    temp.push("Color");
    // }

    if (viewData?.variant_master?.size?.length > 0) {
      temp.push("Size");
    }

    if (viewData?.variant_master?.material?.length > 0) {
      temp.push("Materials");
    }

    if (viewData?.variant_master?.style?.length > 0) {
      temp.push("Style");
    }
    let tempx = [...viewData?.image_urls?.product_images];

    setBigImage({
      image: tempx[tempx.length - 1],
      ind: tempx.length - 1,
      is_default: 0,
    });

    if (tempx?.length > 0) {
      setTextShow(false);
      setFirstShow(true);
    }

    setAttributesName(temp);
    setSubcatogory(true);
  };

  useEffect(() => {
    if (location?.state?.data?.id && viewData?.id) {
      getView();
      setList(viewData);
    }
  }, [viewData]);

  useEffect(() => {
    if (colorsID?.length == 0) {
      setColorVarient("");
    }
  }, [colorsID]);

  // console.log("list", list);

  // CURRENT STATUS CHANGE
  const handleChangeStaus = (status) => {
    let formdata = new FormData();
    formdata.append("productId", list?.id);
    formdata.append("statusType", status);

    productCurrentStatusApi(formdata)
      .unwrap()
      .then((res) => {
        getViewProduct();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const imageSelector = (item, ind, type, is_default) => {
    if (type == "mock") {
      setMockImage({ image: item, ind: ind, is_default: is_default });
    } else if (type == "product") {
      setBigImage({ image: item, ind: ind, is_default: is_default });
    }
  };

  const removeImg = (ind, type, is_default) => {
    // console.log("is_default", is_default);
    if (is_default == 1) {
      toast.error("Selected Image is Set Defaulted");
    } else {
      if (type == "product") {
        let temp = [...smallimage];

        temp.splice(ind, 1);
        setSmallImage(temp);
        if (temp?.length - 1 === 0) {
          setBigImage({
            image: temp[temp.length - 1]?.image,
            ind: temp.length - 1,
            is_default: temp[temp.length - 1]?.is_default,
          });
        } else {
          setBigImage({
            image: temp[ind - 1]?.image,
            ind: ind - 1,
            is_default: temp[ind - 1]?.is_default,
          });
        }
      } else if (type == "mock") {
        let temps = [...smallMockimage];

        temps.splice(ind, 1);
        setSmallMockImage(temps);
        if (temps?.length - 1 === 0) {
          setMockImage({
            image: temps[temps.length - 1]?.image,
            ind: temps.length - 1,
            is_default: temps[temps.length - 1]?.is_default,
          });
        } else {
          setMockImage({
            image: temps[ind - 1]?.image,
            ind: ind - 1,
            is_default: temps[ind - 1]?.is_default,
          });
        }
      }
    }
  };

  // PRODUCT IMAGE
  const handleProductImg = (event, ind) => {
    let temp = [...smallimage];

    if (event?.name) {
      temp[ind] = {
        ...temp[ind],
        image: event,
        is_default: 0,
      };

      setSmallImage(temp);
      setBigImage({
        image: temp[temp.length - 1]?.image,
        ind: temp.length - 1,
        is_default: 0,
      });
      setProductImg(event);
      setFirstShow(true);
      setTextShow(false);
    }
  };

  // MORKUP IMAGE
  const handleMorkupImg = (event, ind) => {
    let temp = [...smallMockimage];

    if (event?.name) {
      temp[ind] = {
        ...temp[ind],
        image: event,
        is_default: 0,
      };

      // console.log("temp", temp);

      setSmallMockImage(temp);
      setMockImage({
        image: temp[temp.length - 1]?.image,
        ind: temp.length - 1,
        is_default: 0,
      });
      setMorkupImg(event);
      setFirstShows(true);
      setTextShows(false);
    }
  };

  // COLOR IMAGE
  const handleColorImg = (event, ind) => {
    let temp = [...colorsData];

    if (event?.name) {
      temp[ind] = {
        ...temp[ind],
        image: event,
      };

      setColorsData(temp);
    }
  };

  const isDeFaultChanger = (item, type) => {
    if (type == "mock") {
      let temp = [...smallMockimage];
      let final = [];

      for (let i = 0; i < temp.length; i++) {
        if (item?.ind == i) {
          let obj = (temp[i] = {
            ...temp[i],
            is_default: 1,
          });
          final.push(obj);
        } else {
          let obj = (temp[i] = {
            ...temp[i],
            is_default: 0,
          });
          final.push(obj);
        }

        setMockImage({ ...mockImage, is_default: 1 });

        setSmallMockImage(final);
      }
    } else {
      let temp = [...smallimage];
      let final = [];

      for (let i = 0; i < temp.length; i++) {
        if (item?.ind == i) {
          let obj = (temp[i] = {
            ...temp[i],
            is_default: 1,
          });
          final.push(obj);
        } else {
          let obj = (temp[i] = {
            ...temp[i],
            is_default: 0,
          });
          final.push(obj);
        }

        setBigImage({ ...bigImage, is_default: 1 });

        setSmallImage(final);
      }
    }

    toast.success("Default Image Set");
  };

  useEffect(() => {
    if (location?.state?.data?.id && viewData?.id) {
      getView();
    }
  }, [viewData]);

  return (
    <>
      <div className="d-flex as-jb w-100 mt-5 res-flex gap-5">
        <div className="w-100">
          <div className="d-flex flex-wrap ac-jb flex-m-r">
            <fieldset disabled={submitBtn == 0 ? false : true}>
              <div className="d-flex flex-wrap ac-jb flex-m-r">
                <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                  <p className="text-dark f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                    Category
                  </p>
                  <div className="position-relative z-3">
                    <input
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      value={catogory}
                      onClick={(e) => {
                        setCatogoryShow(!catogoryShow);
                      }}
                      readOnly
                    />
                    <button
                      className="drop_down cust-btn"
                      onClick={() => {
                        setCatogoryShow(!catogoryShow);
                        setDropDown(null);
                        setAttributesShow(false);
                      }}
                    >
                      <KeyboardArrowDownIcon />
                    </button>
                  </div>
                  {primaryCategoryId?.length == 0 && primaryCategoryIdErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Select Category</p>
                    </div>
                  )}
                  {catogoryShow && (
                    <div
                      className="invisible-cont2 z-0"
                      onClick={() => setCatogoryShow(!catogoryShow)}
                    />
                  )}
                  <div
                    className={`${
                      catogoryShow && "submenu_1"
                    } submenu_cont_1 overflow-scroll z-3`}
                  >
                    <div className="d-flex gap-1">
                      <input
                        className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                        placeholder="Search..."
                        onChange={(e) => searchCategory(e.target.value)}
                        value={searchValueCategory}
                      />
                    </div>
                    {categoryList?.map((item, ind) => {
                      return (
                        <div className="d-flex hover-cust" key={ind}>
                          <button
                            className="px-2 cust-btn text-start py-1 w-100"
                            onClick={() => {
                              onCategorySelct(item);
                              setCatCheck(false);
                              // setParentCategory(item?.id);
                            }}
                          >
                            {item?.name}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {subcatogory && (
                  <>
                    {subCategoryList?.map((item, ind) => {
                      return (
                        <div
                          className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative"
                          key={ind}
                        >
                          <fieldset disabled={catCheck ? true : false}>
                            <p
                              className={`black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1`}
                            >
                              {item?.category?.name}
                            </p>
                            <div className="position-relative">
                              <input
                                placeholder="Required Field"
                                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                                value={catogoryName?.[item?.category?.id]}
                                onClick={() => setCatogoryShow2(ind)}
                                readOnly
                              />
                              <button
                                className="drop_down cust-btn"
                                onClick={() => {
                                  setCatogoryShow2(ind);
                                  setDropDown(null);
                                  setCatogoryShow(false);
                                  setAttributesShow(false);
                                }}
                              >
                                <KeyboardArrowDownIcon />
                              </button>
                            </div>
                            {catogoryShow2 == ind && (
                              <div
                                className="invisible-cont2 z-0"
                                onClick={() => setCatogoryShow2(null)}
                              />
                            )}
                            <div
                              className={`${
                                catogoryShow2 == ind && "submenu_1"
                              } submenu_cont_1 overflow-scroll z-3`}
                            >
                              <div className="d-flex gap-1">
                                <input
                                  className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                                  placeholder="Search..."
                                  onChange={(e) =>
                                    searchSubCatFun(e.target.value, ind)
                                  }
                                  value={searchValueSubCategory[ind]}
                                />
                              </div>
                              {item?.child_categories?.map((subItem, indx) => {
                                return (
                                  <div className="d-flex hover-cust" key={indx}>
                                    <button
                                      className="px-2 cust-btn text-start py-1 w-100"
                                      onClick={() => {
                                        setCatogoryShow2(null);
                                        onSubCategorySelect(subItem, indx, ind);
                                      }}
                                    >
                                      {subItem?.name}
                                    </button>
                                  </div>
                                );
                              })}
                            </div>
                            {ind == 0 &&
                              secondaryCategoryId?.length == 0 &&
                              secondaryCategoryIdErr && (
                                <div className="d-flex gap-1 mt-2">
                                  <ErrorIcon className="svg_log" />
                                  <p className="err-input_log mt-1">
                                    Select Sub Category 1
                                  </p>
                                </div>
                              )}
                            {ind == 1 &&
                              categoryID?.length == 0 &&
                              categoryIDErr && (
                                <div className="d-flex gap-1 mt-2">
                                  <ErrorIcon className="svg_log" />
                                  <p className="err-input_log mt-1">
                                    Select Sub Category 2
                                  </p>
                                </div>
                              )}
                          </fieldset>
                        </div>
                      );
                    })}

                    <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                      <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                        Sub Category Code
                      </p>
                      <div className="position-relative">
                        <input
                          placeholder="Required Field"
                          className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                          value={productSuplierCode}
                          onChange={(e) =>
                            setProductSuplierCode(e.target.value)
                          }
                          type="text"
                          disabled
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                  <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                    Product Name
                  </p>
                  <div className="position-relative">
                    <input
                      placeholder="Required Field"
                      className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                    />
                  </div>
                  {name?.length == 0 && nameErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter Name</p>
                    </div>
                  )}
                </div>
                <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                  <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                    MRP
                  </p>
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    onChange={(e) => setMrp(e.target.value)}
                    value={mrp}
                    type={"number"}
                  />
                  {mrp?.length == 0 && mrpErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter MRP</p>
                    </div>
                  )}
                </div>
                <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                  <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                    Decoration Method
                  </p>
                  <fieldset disabled={edit}>
                    <div className="position-relative">
                      <textarea
                        placeholder="Decorative method"
                        type="text"
                        value={
                          decorativeName?.length > 0
                            ? decorativeName?.toString()
                            : "Select Decorative method"
                        }
                        onClick={() => {
                          setDropDown(0);
                        }}
                        className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      />
                      <button
                        className="drop_down cust-btn"
                        onClick={() => {
                          setDropDown(0);
                        }}
                      >
                        <KeyboardArrowDownIcon />
                      </button>
                      {dropDown == 0 && (
                        <div
                          className="invisible-cont2 z-0"
                          onClick={() => {
                            setDropDown(null);
                          }}
                        />
                      )}
                      <div
                        className={`${
                          dropDown == 0 && "submenu_1 py-2"
                        } submenu_cont_1 overflow-scroll z-3`}
                      >
                        <div className="d-flex gap-1">
                          <input
                            className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                            placeholder="Search..."
                            onChange={(e) => searchSub(e.target.value)}
                            value={searchValueSub}
                          />
                          <button
                            className="border-0 bg-transparent"
                            onClick={() => {
                              setSelectALLSub(!selectALLSub);
                              allSelectSub();
                            }}
                          >
                            All
                          </button>
                        </div>
                        {decorativeLists?.map((item, ind) => {
                          return (
                            <button
                              className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                              key={ind}
                            >
                              <button
                                className="px-2 cust-btn text-start py-1 w-100 "
                                onClick={() => {
                                  onSubSelect(item?.name, item?.id);
                                }}
                              >
                                {item?.name}
                              </button>
                              <button
                                className="px-2 cust-btn text-start"
                                style={{ fontSize: "large" }}
                                onClick={() => {
                                  onSubSelect(item?.name, item?.id, "check");
                                }}
                              >
                                {decorativeName?.includes(item?.name) ? (
                                  <CheckCircleIcon className="primary" />
                                ) : (
                                  <RadioButtonUncheckedIcon className="primary" />
                                )}
                              </button>
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  </fieldset>

                  {decorationMethod?.length == 0 && decorationMethodErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">
                        Select Decoration Method
                      </p>
                    </div>
                  )}
                </div>
                <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                  <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                    Country of Origin
                  </p>
                  <fieldset>
                    <div className="position-relative">
                      <input
                        placeholder="Country"
                        type="text"
                        value={
                          country?.length > 0
                            ? country?.toString()
                            : "Select Country"
                        }
                        onClick={() => {
                          setDropDown(1);
                        }}
                        className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      />
                      <button
                        className="drop_down cust-btn"
                        onClick={() => {
                          setDropDown(1);
                        }}
                      >
                        <KeyboardArrowDownIcon />
                      </button>
                      {dropDown == 1 && (
                        <div
                          className="invisible-cont2 z-0"
                          onClick={() => setDropDown(null)}
                        />
                      )}
                      <div
                        className={`${
                          dropDown == 1 && "submenu_1 py-2"
                        } submenu_cont_1 overflow-scroll z-3`}
                      >
                        <div className="d-flex gap-1">
                          <input
                            className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                            placeholder="Search..."
                            onChange={(e) => searchCountry(e.target.value)}
                            value={searchValueCountry}
                          />
                        </div>
                        {listCountries?.map((item, ind) => {
                          return (
                            <button
                              className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                              onClick={() => {
                                getCountry(item?.name);
                              }}
                              key={ind}
                            >
                              <button className="px-2 cust-btn text-start py-1 w-100 ">
                                {item?.name}
                              </button>
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  </fieldset>
                  {country?.length == 0 && countryErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">
                        Select Registered Address Country
                      </p>
                    </div>
                  )}
                </div>

                <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                  <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                    Gender
                  </p>

                  <fieldset>
                    <div className="position-relative">
                      <input
                        placeholder="Gender"
                        type="text"
                        value={
                          gendor?.length > 0
                            ? gendor?.toString()
                            : "Select Gender"
                        }
                        onClick={() => {
                          setDropDown(11);
                        }}
                        className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      />
                      <button
                        className="drop_down cust-btn"
                        onClick={() => {
                          setDropDown(11);
                        }}
                      >
                        <KeyboardArrowDownIcon />
                      </button>
                      {dropDown == 11 && (
                        <div
                          className="invisible-cont2 z-0"
                          onClick={() => setDropDown(null)}
                        />
                      )}
                      <div
                        className={`${
                          dropDown == 11 && "submenu_1 h-auto py-2"
                        } submenu_cont_1 overflow-scroll z-3`}
                      >
                        <div className="d-flex gap-1">
                          <input
                            className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                            placeholder="Search..."
                            onChange={(e) => searchGendor(e.target.value)}
                            value={searchValueGendor}
                          />
                        </div>
                        {listGendor?.map((item, ind) => {
                          return (
                            <button
                              className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                              onClick={() => {
                                onSelectGendor(item);
                              }}
                              key={ind}
                            >
                              <button className="px-2 cust-btn text-start py-1 w-100 ">
                                {item}
                              </button>
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  </fieldset>
                  {gendor?.length == 0 && gendorErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Select Gender</p>
                    </div>
                  )}
                </div>

                <div className="w-100">
                  <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
                    Product Description
                  </p>
                  <textarea
                    placeholder="Autogenerate"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    onChange={(e) => setProductDescription(e.target.value)}
                    value={productDescription}
                  />
                  {productDescription?.length == 0 && productDescriptionErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">
                        Enter Product Description
                      </p>
                    </div>
                  )}
                </div>

                <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                  <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                    HSN Code
                  </p>
                  <fieldset disabled={false}>
                    <div className="position-relative">
                      <input
                        placeholder="HSN Code"
                        type="text"
                        value={hsnName ? hsnName : "Select HSN Code"}
                        onClick={() => {
                          setDropDown(2);
                        }}
                        className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      />
                      <button
                        className="drop_down cust-btn"
                        onClick={() => {
                          setDropDown(2);
                        }}
                      >
                        <KeyboardArrowDownIcon />
                      </button>
                      {dropDown == 2 && (
                        <div
                          className="invisible-cont2 z-0"
                          onClick={() => setDropDown(null)}
                        />
                      )}
                      <div
                        className={`${
                          dropDown == 2 && "submenu_1  py-2"
                        } submenu_cont_1 overflow-scroll z-3`}
                      >
                        <div className="d-flex gap-1">
                          <input
                            className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                            placeholder="Search..."
                            onChange={(e) => searchHSNValue(e.target.value)}
                            value={searchHsn}
                          />
                        </div>
                        {listHSN?.map((item, ind) => {
                          return (
                            <button
                              className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                              onClick={() => {
                                getHSN(item);
                              }}
                              key={ind}
                            >
                              <button className="px-2 cust-btn text-start py-1 w-100 ">
                                {item?.code}
                              </button>
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  </fieldset>
                  {hsnCode?.length == 0 && hsnCodeErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Select HSN Code</p>
                    </div>
                  )}
                </div>

                <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                  <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                    Tags
                  </p>
                  <fieldset disabled={false}>
                    <div className="position-relative">
                      <input
                        placeholder="Tags"
                        type="text"
                        value={
                          tagsName?.length > 0
                            ? tagsName?.toString()
                            : "Select Tags"
                        }
                        onClick={() => {
                          setDropDown(3);
                        }}
                        className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      />
                      <button
                        className="drop_down cust-btn"
                        onClick={() => {
                          setDropDown(3);
                        }}
                      >
                        <KeyboardArrowDownIcon />
                      </button>
                      {dropDown == 3 && (
                        <div
                          className="invisible-cont2 z-0"
                          onClick={() => setDropDown(null)}
                        />
                      )}
                      <div
                        className={`${
                          dropDown == 3 && "submenu_1  py-2"
                        } submenu_cont_1 overflow-scroll z-3`}
                      >
                        <div className="d-flex gap-1">
                          <input
                            className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                            placeholder="Search..."
                            onChange={(e) => searchTagsFun(e.target.value)}
                            value={searchTags}
                          />
                          <button
                            className="border-0 bg-transparent"
                            onClick={() => {
                              allSelectTags();
                            }}
                          >
                            All
                          </button>
                        </div>
                        {listTags?.map((item, ind) => {
                          return (
                            <button
                              className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                              key={ind}
                            >
                              <button
                                className="px-2 cust-btn text-start py-1 w-100 "
                                onClick={() => {
                                  onTagsSelect(item?.name, item?.id);
                                }}
                              >
                                {item?.name}
                              </button>
                              <button
                                className="px-2 cust-btn text-start"
                                style={{ fontSize: "large" }}
                                onClick={() => {
                                  onTagsSelect(item?.name, item?.id, "check");
                                }}
                              >
                                {tagsName?.includes(item?.name) ? (
                                  <CheckCircleIcon className="primary" />
                                ) : (
                                  <RadioButtonUncheckedIcon className="primary" />
                                )}
                              </button>
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  </fieldset>
                  {tagVal?.length == 0 && tagErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Select Tags</p>
                    </div>
                  )}
                </div>

                <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                  <fieldset disabled={submitData?.id ? true : false}>
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                      Event Tags
                    </p>
                    <div className="position-relative">
                      <input
                        placeholder="Tags"
                        type="text"
                        value={
                          tagsEventName?.length > 0
                            ? tagsEventName?.toString()
                            : "Select Tags"
                        }
                        onClick={() => {
                          setDropDown(13);
                        }}
                        className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      />
                      <button
                        className="drop_down cust-btn"
                        onClick={() => {
                          setDropDown(13);
                        }}
                      >
                        <KeyboardArrowDownIcon />
                      </button>
                      {dropDown == 13 && (
                        <div
                          className="invisible-cont2 z-0"
                          onClick={() => setDropDown(null)}
                        />
                      )}
                      <div
                        className={`${
                          dropDown == 13 && "submenu_1  py-2"
                        } submenu_cont_1 overflow-scroll z-3`}
                      >
                        <div className="d-flex gap-1">
                          <input
                            className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                            placeholder="Search..."
                            onChange={(e) => searchTagsFunEvent(e.target.value)}
                            value={searchEventTags}
                          />
                          <button
                            className="border-0 bg-transparent"
                            onClick={() => {
                              allSelectTagsEvent();
                            }}
                          >
                            All
                          </button>
                        </div>
                        {listEventTags?.map((item, ind) => {
                          return (
                            <button
                              className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                              key={ind}
                            >
                              <button
                                className="px-2 cust-btn text-start py-1 w-100 "
                                onClick={() => {
                                  onTagsSelectEvent(item?.name, item?.id);
                                }}
                              >
                                {item?.name}
                              </button>
                              <button
                                className="px-2 cust-btn text-start"
                                style={{ fontSize: "large" }}
                                onClick={() => {
                                  onTagsSelectEvent(
                                    item?.name,
                                    item?.id,
                                    "check"
                                  );
                                }}
                              >
                                {tagsEventName?.includes(item?.name) ? (
                                  <CheckCircleIcon className="primary" />
                                ) : (
                                  <RadioButtonUncheckedIcon className="primary" />
                                )}
                              </button>
                            </button>
                          );
                        })}
                      </div>
                    </div>
                    {tagEventVal?.length == 0 && tagEventErr && (
                      <div className="d-flex gap-1 mt-2">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">Select Event Tag</p>
                      </div>
                    )}
                  </fieldset>
                </div>

                <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                  <fieldset disabled={submitData?.id ? true : false}>
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                      Icon Tags
                    </p>
                    <div className="position-relative">
                      <input
                        placeholder="Tags"
                        type="text"
                        value={
                          tagsIconName?.length > 0
                            ? tagsIconName?.toString()
                            : "Select Tags"
                        }
                        onClick={() => {
                          setDropDown(31);
                        }}
                        className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      />
                      <button
                        className="drop_down cust-btn"
                        onClick={() => {
                          setDropDown(31);
                        }}
                      >
                        <KeyboardArrowDownIcon />
                      </button>
                      {dropDown == 31 && (
                        <div
                          className="invisible-cont2 z-0"
                          onClick={() => setDropDown(null)}
                        />
                      )}
                      <div
                        className={`${
                          dropDown == 31 && "submenu_1  py-2"
                        } submenu_cont_1 overflow-scroll z-3`}
                      >
                        <div className="d-flex gap-1">
                          <input
                            className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                            placeholder="Search..."
                            onChange={(e) => searchTagsFunIcon(e.target.value)}
                            value={searchIconTags}
                          />
                          <button
                            className="border-0 bg-transparent"
                            onClick={() => {
                              allSelectTagsIcon();
                            }}
                          >
                            All
                          </button>
                        </div>
                        {listIconTags?.map((item, ind) => {
                          return (
                            <button
                              className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                              key={ind}
                            >
                              <button
                                className="px-2 cust-btn text-start py-1 w-100 "
                                onClick={() => {
                                  onTagsSelectIcon(item?.name, item?.id);
                                }}
                              >
                                {item?.name}
                              </button>
                              <button
                                className="px-2 cust-btn text-start"
                                style={{ fontSize: "large" }}
                                onClick={() => {
                                  onTagsSelectIcon(
                                    item?.name,
                                    item?.id,
                                    "check"
                                  );
                                }}
                              >
                                {tagsIconName?.includes(item?.name) ? (
                                  <CheckCircleIcon className="primary" />
                                ) : (
                                  <RadioButtonUncheckedIcon className="primary" />
                                )}
                              </button>
                            </button>
                          );
                        })}
                      </div>
                    </div>
                    {tagIconVal?.length == 0 && tagIconErr && (
                      <div className="d-flex gap-1 mt-2">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">Select Icon Tag</p>
                      </div>
                    )}
                  </fieldset>
                </div>

                <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                  <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                    Product Packaging Size
                  </p>
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    onChange={(e) => setProductPackageSize(e.target.value)}
                    value={productPackageSize}
                    // type={"number"}
                  />
                  {productPackageSize?.length == 0 && productPackageSizeErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">
                        Enter Product Packaging Size
                      </p>
                    </div>
                  )}
                </div>

                <div className="mt-5 w-md-100 w-sm-100 w-xs-100 position-relative">
                  <p className="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
                    Other Product Information
                  </p>
                  <fieldset disabled={false}>
                    <div className="position-relative">
                      <input
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                        value={dynamicAttr}
                        onClick={(e) => setShowDynamicAttr(!showDynamicAttr)}
                        readOnly
                      />
                      <button
                        className="drop_down cust-btn"
                        onClick={() => {
                          setShowDynamicAttr(!showDynamicAttr);
                        }}
                      >
                        <KeyboardArrowDownIcon />
                      </button>
                    </div>
                  </fieldset>
                  {showDynamicAttr && (
                    <div
                      className="invisible-cont2"
                      onClick={() => setShowDynamicAttr(!showDynamicAttr)}
                    />
                  )}
                  <div
                    className={`${
                      showDynamicAttr && "submenu_1 "
                    } submenu_cont_1 overflow-scroll z-3`}
                  >
                    <div className="d-flex gap-1">
                      <input
                        className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                        placeholder="Search..."
                        onChange={(e) => searchProductInfoFun(e.target.value)}
                        value={searchProInfo}
                      />
                      <button
                        className="border-0 bg-transparent"
                        onClick={() => {
                          allSelectProductInfo();
                        }}
                      >
                        All
                      </button>
                    </div>
                    {listAttributes?.map((item, ind) => {
                      return (
                        <div className="d-flex as-jb hover-cust" key={ind}>
                          <button
                            className="px-2 cust-btn text-start py-1 w-100 "
                            onClick={() => {
                              onDynamicAttributeSelect(
                                item?.name,
                                item?.id,
                                ind
                              );
                              setShowDynamicAttr(false);
                            }}
                          >
                            {item?.name}
                          </button>
                          <button
                            className="px-2 cust-btn text-start"
                            onClick={() => {
                              onDynamicAttributeSelect(
                                item?.name,
                                item?.id,
                                ind
                              );
                            }}
                          >
                            {dynamicAttr?.includes(item?.name) ? (
                              <CheckCircleIcon className="primary" />
                            ) : (
                              <RadioButtonUncheckedIcon className="primary" />
                            )}

                            {dynamicAttr?.includes(item?.list)
                              ? item?.check
                              : item.uncheck}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                  {otherProInfo?.length == 0 && otherProInfoErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">
                        Select Other Product Information
                      </p>
                    </div>
                  )}
                </div>

                {productInformation?.map((item, ind) => {
                  return (
                    <div
                      className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100"
                      key={ind}
                    >
                      <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                        {item?.title}
                      </p>
                      <input
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        onChange={(e) =>
                          handleProductInformation(e.target.value, ind)
                        }
                        value={item?.value}
                        disabled={edit}
                      />
                      {item?.value?.length == 0 && productInformationErr && (
                        <div className="d-flex gap-1 mt-2">
                          <ErrorIcon className="svg_log" />
                          <p className="err-input_log mt-1">fill Information</p>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>{" "}
            </fieldset>

            {submitBtn == 0 && (
              <div className="d-flex justify-content-end w-100">
                <button
                  class={`cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                    btn && "opacity-50"
                  }`}
                  onClick={() => submitHandle()}
                  disabled={btn}
                >
                  Store Product
                </button>
              </div>
            )}

            {/* {submitBtn !== 0 && ( */}
            <div className="mt-3 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
                Add Variants
              </p>
              <div className="position-relative  w-sm-100 w-xs-100 x-md-100">
                <input
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  value={attributesName}
                  onClick={() => setAttributesShow(!attributesShow)}
                  readOnly
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setAttributesShow(!attributesShow);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
              </div>
              {attributesShow && (
                <div
                  className="invisible-cont2"
                  onClick={() => setAttributesShow(!attributesShow)}
                />
              )}
              <div
                className={`${
                  attributesShow && "submenu_1 h-auto"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                {Select_list?.map((item, ind) => {
                  return (
                    <div className="d-flex as-jb hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          onAttributeClick(item?.list);
                          setAttributesShow(false);
                        }}
                        key={ind}
                        disabled={item?.list == "Color" ? true : false}
                      >
                        {item?.list}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                        onClick={() => {
                          onAttributeClick(item?.list);
                        }}
                        disabled={item?.list == "Color" ? true : false}
                      >
                        {attributesName?.includes(item?.list)
                          ? item?.check
                          : item.uncheck}
                      </button>
                    </div>
                  );
                })}
              </div>
              {varientsCheck?.length == 0 && addVarientErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Variants</p>
                </div>
              )}
            </div>
            {/* )} */}

            {attributesName?.includes("Color") && (
              <div className=" w-md-100 w-sm-100 w-xs-100 position-relative">
                <p className="black f4 fs-xs-12 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-2 ">
                  Color
                </p>
                <div className="position-relative">
                  <input
                    placeholder="Color"
                    type="text"
                    value={
                      colorsName?.length > 0
                        ? colorsName?.toString()
                        : "Select Color"
                    }
                    onClick={() => {
                      setVarients(0);
                    }}
                    className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                  <button
                    className="drop_down cust-btn"
                    onClick={() => {
                      setVarients(0);
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                  {varients == 0 && (
                    <div
                      className="invisible-cont2 z-0"
                      onClick={() => setVarients(null)}
                    />
                  )}
                  <div
                    className={`${
                      varients == 0 && "submenu_1  py-2"
                    } submenu_cont_1 overflow-scroll z-3`}
                  >
                    <div className="d-flex gap-1">
                      <input
                        className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                        placeholder="Search..."
                        onChange={(e) => searchColorsFun(e.target.value)}
                        value={searchColors}
                      />
                      <button
                        className="border-0 bg-transparent"
                        onClick={() => {
                          allSelectColors();
                        }}
                      >
                        All
                      </button>
                    </div>
                    {listColors?.map((item, ind) => {
                      return (
                        <button
                          className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                          key={ind}
                        >
                          <button
                            className="px-2 cust-btn text-start py-1 w-100 "
                            onClick={() => {
                              onColorsSelect(item?.name, item?.id, item, ind);
                            }}
                          >
                            {item?.name}
                          </button>
                          <button
                            className="px-2 cust-btn text-start"
                            style={{ fontSize: "large" }}
                            onClick={() => {
                              onColorsSelect(
                                item?.name,
                                item?.id,
                                item,
                                ind,
                                "check"
                              );
                            }}
                          >
                            {colorsName?.includes(item?.name) ? (
                              <CheckCircleIcon
                                style={{ color: item?.hex_code }}
                              />
                            ) : (
                              <RadioButtonUncheckedIcon className="primary" />
                            )}
                          </button>
                        </button>
                      );
                    })}
                  </div>
                </div>
                {colorVarient?.length == 0 && colorVarientErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Select Color</p>
                  </div>
                )}
              </div>
            )}

            {attributesName?.includes("Size") && (
              <div className=" w-md-100 w-sm-100 w-xs-100">
                <p className=" black f4 fs-xs-12 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-2 ">
                  Size
                </p>
                <div className="d-flex justify-content-start me-3 ">
                  <div className="editBtn text-center  rounded-3 mt-2 p-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    <button
                      className="border-0 primary f3 "
                      onClick={() => handleAdd()}
                    >
                      +Add New
                    </button>
                  </div>
                </div>
                <div className="d-flex flex-wrap w-100 gap-2">
                  {sizes?.map((data, i) => {
                    return (
                      <div
                        className="d-flex  w-md-100 w-sm-100 w-xs-100"
                        style={{ width: "30% " }}
                      >
                        <div className="position-relative add w-100">
                          <input
                            value={data?.value}
                            onChange={(e) =>
                              handleChange(e.target.value, i, "value")
                            }
                            placeholder="Required Field"
                            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                            disabled={
                              edit ? true : data?.type == "edit" ? true : false
                            }
                          />
                          {data?.value?.length == 0 && sizesErr && (
                            <div className="d-flex gap-1 mt-2">
                              <ErrorIcon className="svg_log" />
                              <p className="err-input_log mt-1">Fill Size</p>
                            </div>
                          )}
                        </div>
                        {sizes?.length > 0 &&
                          !edit &&
                          data?.type !== "edit" && (
                            <button className="mx-2 d-flex ac-jc cp cust-btn">
                              <DeleteForever
                                className="primary"
                                onClick={() => handleDelete(i)}
                              />
                            </button>
                          )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {attributesName?.includes("Materials") && (
              <div className=" w-md-100 w-sm-100 w-xs-100">
                <p className=" black f4 fs-xs-12 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-2 ">
                  Materials
                </p>
                <div className="d-flex justify-content-start me-3 ">
                  <div className="editBtn text-center  rounded-3 mt-2 p-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    <button
                      className="border-0 primary f3 "
                      onClick={() => handleMatAdd()}
                    >
                      +Add New
                    </button>
                  </div>
                </div>
                <div className="d-flex flex-wrap w-100 gap-2">
                  {materials?.map((data, i) => {
                    return (
                      <div
                        className="d-flex  w-md-100 w-sm-100 w-xs-100"
                        style={{ width: "30% " }}
                      >
                        <div className="position-relative add w-100">
                          <input
                            value={data?.value}
                            onChange={(e) =>
                              handleMatChange(e.target.value, i, "value")
                            }
                            placeholder="Required Field"
                            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                            disabled={
                              edit ? true : data?.type == "edit" ? true : false
                            }
                          />
                          {data?.value?.length == 0 && materialsErr && (
                            <div className="d-flex gap-1 mt-2">
                              <ErrorIcon className="svg_log" />
                              <p className="err-input_log mt-1">
                                Fill Materials
                              </p>
                            </div>
                          )}
                        </div>
                        {materials?.length > 0 &&
                          !edit &&
                          data?.type !== "edit" && (
                            <button className="mx-2 d-flex ac-jc cp cust-btn">
                              <DeleteForever
                                className="primary"
                                onClick={() => handleMatDelete(i)}
                              />
                            </button>
                          )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {attributesName?.includes("Style") && (
              <div className=" w-md-100 w-sm-100 w-xs-100">
                <p className=" black f4 fs-xs-12 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-2 ">
                  Style
                </p>
                <div className="d-flex justify-content-start me-3 ">
                  <div className="editBtn text-center  rounded-3 mt-2 p-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    <button
                      className="border-0 primary f3 "
                      onClick={() => handleStyleAdd()}
                    >
                      +Add New
                    </button>
                  </div>
                </div>
                <div className="d-flex flex-wrap w-100 gap-2">
                  {styles?.map((data, i) => {
                    return (
                      <div
                        className="d-flex  w-md-100 w-sm-100 w-xs-100"
                        style={{ width: "30% " }}
                      >
                        <div className="position-relative add w-100">
                          <input
                            value={data?.value}
                            onChange={(e) =>
                              handleStyleChange(e.target.value, i, "value")
                            }
                            placeholder="Required Field"
                            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                            disabled={
                              edit ? true : data?.type == "edit" ? true : false
                            }
                          />
                          {data?.value?.length == 0 && stylesErr && (
                            <div className="d-flex gap-1 mt-2">
                              <ErrorIcon className="svg_log" />
                              <p className="err-input_log mt-1">Fill Style</p>
                            </div>
                          )}
                        </div>
                        {styles?.length > 0 &&
                          !edit &&
                          data?.type !== "edit" && (
                            <button className="mx-2 d-flex ac-jc cp cust-btn">
                              <DeleteForever
                                className="primary"
                                onClick={() => handleStyleDelete(i)}
                              />
                            </button>
                          )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            <div className="w-100">
              <button
                className={`cust-btn addbtn mt-sm-3 align-selfe-center mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                  btn ? "opacity-50" : !submitData?.id ? "opacity-50" : ""
                }`}
                onClick={() => {
                  varientCombination();
                }}
                disabled={btn ? true : !submitData?.id ? true : false}
              >
                Generate SKU
              </button>
            </div>

            {varientShow && (
              <div className="w-100 d-flex flex-wrap mt-4">
                {varientsList?.map((item, ind) => {
                  return (
                    <Col md={12} lg={12} xl={12} xxl={12}>
                      <table className="w-100 mt-2" key={ind}>
                        <tr className="mt-4">
                          <div className="">
                            <td>
                              <div className="d-flex w-100">
                                <div className="mx-2">
                                  <p
                                    className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-start primary f3"
                                    style={{ width: "200px" }}
                                  >
                                    {item?.parent_name}
                                  </p>
                                </div>
                              </div>
                            </td>
                          </div>
                        </tr>
                        {item?.childs?.map((child, inx) => {
                          return (
                            <tr className="mt-2">
                              <div className="my-3">
                                <td>
                                  <div className="d-flex w-100">
                                    <div className="mx-2">
                                      <div
                                        className="num-cls-btn d-flex overflow-scroll p-2"
                                        style={{ width: "auto" }}
                                      >
                                        <p className="text-wrap px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-nowrap">
                                          {child?.sku_name}
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      className="d-flex ac-js"
                                      onClick={() =>
                                        edit
                                          ? console.log("test")
                                          : deleteVarient(
                                              ind,
                                              inx,
                                              child?.status
                                            )
                                      }
                                    >
                                      {child?.status == 0 ? (
                                        <div>
                                          <img
                                            src={toggleOff}
                                            className="toggleOnDes"
                                          />
                                        </div>
                                      ) : (
                                        <div>
                                          <img
                                            src={toggleOn}
                                            className="toggleOnDes"
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </td>
                              </div>
                            </tr>
                          );
                        })}
                      </table>
                    </Col>
                  );
                })}
              </div>
            )}
          </div>
          {submitBtn == 2 && varientsList?.length > 0 && (
            <button
              className={`cust-btn addbtn mt-sm-3 align-selfe-center mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                btn && "opacity-50"
              }`}
              onClick={() => submitVarient()}
              disabled={btn}
            >
              Store SKU
            </button>
          )}
        </div>
        <div className="d-flex gap-3 ac-jc w-100 res-img">
          <div>
            <p class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
              Product Image (185 x 205)
            </p>
            <div>
              <div className="">
                <div
                  className="position-relative"
                  onClick={() => {
                    if (!fistShow) {
                      setProIndex(0);
                      proImgRef?.current?.click();
                    }
                  }}
                >
                  <img
                    className={"img_up_cont"}
                    src={
                      bigImage?.image?.name
                        ? URL.createObjectURL(bigImage?.image)
                        : bigImage?.image
                        ? bigImage?.image
                        : uploadIcon
                    }
                  />
                  {submitBtn == 0 && !textShow && (
                    <div>
                      <ul
                        className={`${"image-hide"}  edit-image image-show d-flex justify-content-between border_img`}
                      >
                        <li
                          className="set-default-image"
                          role={"button"}
                          onClick={() => isDeFaultChanger(bigImage, "product")}
                        >
                          Set&nbsp;Default
                        </li>
                        {smallimage?.length > 1 && (
                          <li
                            className="set-default-image"
                            onClick={() =>
                              removeImg(
                                bigImage?.ind,
                                "product",
                                bigImage?.is_default
                              )
                            }
                            role="button"
                          >
                            Remove
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="d-flex ac-jc gap-3 w-100">
                  {fistShow && (
                    <div
                      class=" mt-3 gap-3 d-flex ac-jc pb-4 ps-5"
                      style={{ width: "300px", overflow: "scroll" }}
                    >
                      {smallimage?.map((item, ind) => {
                        return (
                          <div className="gap-3 d-flex ac-j w-100">
                            <div
                              class="add_img d-flex ac-jc"
                              onClick={() =>
                                imageSelector(
                                  item?.image,
                                  ind,
                                  "product",
                                  item?.is_default
                                )
                              }
                            >
                              <img
                                src={
                                  item?.image?.name
                                    ? URL.createObjectURL(item?.image)
                                    : item?.image
                                }
                                class="cp add_img object-fit-fill"
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {submitBtn == 0 && smallimage?.length > 0 && (
                    <label
                      className={`add_img d-flex ac-jc cp f3 primary fs-3 ${
                        smallimage?.length == 0 && "mt-3"
                      }`}
                      onClick={() => {
                        setProIndex(smallimage?.length);
                        proImgRef?.current?.click();
                      }}
                    >
                      {" "}
                      +
                    </label>
                  )}
                </div>
              </div>
            </div>
            {productImg?.length == 0 && productImgErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Upload Product Image</p>
              </div>
            )}
          </div>

          <div>
            <p class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
              Mockup Image
            </p>

            <div>
              {" "}
              <div className="">
                <div
                  className="position-relative"
                  onClick={() => {
                    if (!fistShows) {
                      setMorkupIndex(0);
                      morkupImgRef?.current?.click();
                    }
                  }}
                >
                  <img
                    className={"img_up_cont"}
                    src={
                      mockImage?.image?.name
                        ? URL.createObjectURL(mockImage?.image)
                        : mockImage?.image
                        ? mockImage?.image
                        : uploadIcon
                    }
                  />
                  {submitBtn == 0 && !textShows && (
                    <div>
                      <ul
                        className={`${"image-hide"}  edit-image image-show d-flex justify-content-between border_img`}
                      >
                        <li
                          className="set-default-image"
                          role={"button"}
                          onClick={() => isDeFaultChanger(mockImage, "mock")}
                        >
                          Set&nbsp;Default
                        </li>
                        {smallMockimage?.length > 1 && (
                          <li
                            className="set-default-image"
                            onClick={() =>
                              removeImg(
                                mockImage?.ind,
                                "mock",
                                mockImage?.is_default
                              )
                            }
                          >
                            Remove
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="d-flex ac-jc gap-3 w-100">
                  {fistShows && (
                    <div
                      class=" mt-3 gap-3 d-flex ac-jc pb-4 ps-5"
                      style={{ width: "300px", overflow: "scroll" }}
                    >
                      {smallMockimage?.map((item, ind) => {
                        return (
                          <div className="gap-3 d-flex ac-j w-100">
                            <div
                              class="add_img d-flex ac-jc"
                              onClick={() =>
                                imageSelector(
                                  item?.image,
                                  ind,
                                  "mock",
                                  item?.is_default
                                )
                              }
                            >
                              {" "}
                              <img
                                src={
                                  item?.image?.name
                                    ? URL.createObjectURL(item?.image)
                                    : item?.image
                                }
                                class="cp add_img object-fit-fill"
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {submitBtn == 0 && smallMockimage?.length > 0 && (
                    <label
                      className={`add_img d-flex ac-jc cp f3 primary fs-3 ${
                        smallMockimage?.length == 0 && "mt-3"
                      }`}
                      onClick={() => {
                        setMorkupIndex(smallMockimage?.length);
                        morkupImgRef?.current?.click();
                      }}
                    >
                      +
                    </label>
                  )}
                </div>
              </div>
            </div>
            {morkupImg?.length == 0 && morkupImgErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Upload Mockup Image</p>
              </div>
            )}
          </div>

          {colorsData?.length > 0 && (
            <div>
              <p class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
                Color Image (312 x 312)
              </p>
              <div
                className="d-flex gap-3 mt-3 ac-jc flex-wrap"
                style={{ width: "350px" }}
                ref={colorRep}
              >
                {colorsData?.map((item, ind) => {
                  return (
                    <div className="d-flex flex-column ac-jc">
                      <div>
                        {item?.image ? (
                          <div className="position-relative for_hover">
                            <img
                              alt="icon"
                              src={
                                item?.image?.name
                                  ? URL.createObjectURL(item?.image)
                                  : item?.image
                              }
                              className="add_img object-fit-fill"
                              onClick={() => {
                                setColorIndex(ind);
                                colorImgRef.current.click();
                              }}
                            />
                          </div>
                        ) : (
                          <label
                            className="add_img d-flex ac-jc cp f3 primary fs-3"
                            onClick={() => {
                              setColorIndex(ind);
                              colorImgRef.current.click();
                            }}
                          >
                            +
                          </label>
                        )}
                        <p className="f3" style={{ color: item?.hex_code }}>
                          {item?.name}
                        </p>
                      </div>
                      {item?.image?.length == 0 && colorImgErr && (
                        <div className="d-flex gap-1 mt-2">
                          <ErrorIcon className="svg_log" />
                          <p className="err-input_log mt-1">
                            Upload Color Image
                          </p>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      <input
        type="file"
        accept=".pdf,.doc,.jpeg,.docx,.png,.jpg"
        onChange={(e) => handleProductImg(e.target.files[0], proIndex)}
        className="d-none"
        ref={proImgRef}
      />

      <input
        type="file"
        accept=".pdf,.doc,.jpeg,.docx,.png,.jpg"
        onChange={(e) => handleMorkupImg(e.target.files[0], morkupIndex)}
        className="d-none"
        ref={morkupImgRef}
      />

      <input
        type="file"
        accept=".pdf,.doc,.jpeg,.docx,.png,.jpg"
        onChange={(e) => handleColorImg(e.target.files[0], colorIndex)}
        className="d-none"
        ref={colorImgRef}
      />
    </>
  );
};

export default OverviewTab;
