import React, { useState } from "react";
import { animatedImg, toggleOff, toggleOn } from "../../assets/img";
import { useLocation, useNavigate } from "react-router-dom";

const DecorativeListComp = ({
  loc,
  place,
  decorative2,
  page,
  setPage,
  pagenation,
  list,
  getDecorStatus,
  name,
  data,
  vendors,
}) => {
  // const [productDetail, setProductDetail] = useState(false)
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <>
      <div className="w-100 d-flex align-items-center mt-5 flex-wrap">
        {list.map((item, ind) => {
          return (
            <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2">
              <div className="prod-list-box">
                <div
                  className="cust-btn d-flex ac-jc rounded-3"
                  onClick={() =>
                    navigate("/decorative-product", {
                      state: {
                        type: "edit",
                        id: item,
                        data: location?.state?.data,
                        name: name,
                        vendors: vendors,
                      },
                    })
                  }
                >
                  <img
                    src={
                      item?.decor_method_price?.[
                        item?.decor_method_price?.length - 1
                      ]?.image_url
                        ? item?.decor_method_price?.[
                            item?.decor_method_price?.length - 1
                          ]?.image_url
                        : animatedImg
                    }
                    alt=""
                    className="custom-w-h rounded-3"
                  />
                </div>
                <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                  {item?.name}
                </p>
                <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary parag mb-2">
                  {
                    item?.decor_method_price?.[
                      item?.decor_method_price?.length - 1
                    ]?.vendor?.legal_name
                  }
                </p>
                <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 mt-2">
                  Status
                  <div
                    onClick={() =>
                      getDecorStatus(
                        item?.id,
                        item?.decor_method_price?.[
                          item?.decor_method_price?.length - 1
                        ]?.vendor?.id
                      )
                    }
                  >
                    {item?.decor_method_price?.[
                      item?.decor_method_price?.length - 1
                    ]?.status == 0 ? (
                      <div>
                        <img src={toggleOff} className="toggleOnDes" />
                      </div>
                    ) : (
                      <div>
                        <img src={toggleOn} className="toggleOnDes" />
                      </div>
                    )}
                  </div>
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="d-flex justify-content-between mt-3">
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            page == 1 && "opacity-50"
          }`}
          onClick={() => setPage(page - 1)}
          disabled={page == 1 ? true : false}
        >
          Previous
        </button>
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            page == pagenation?.last_page && "opacity-50"
          }`}
          onClick={() => setPage(page + 1)}
          disabled={page == pagenation?.last_page ? true : false}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default DecorativeListComp;
