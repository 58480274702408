import { Search } from "@mui/icons-material";
import React from "react";
import manImage from "../../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import { useNavigate } from "react-router-dom";

const ProductSearchHeader = ({ banner }) => {
  const navigate = useNavigate()
  const user = JSON.parse(localStorage.getItem("userDetails"));
  return (
    <div className="d-flex w-100 searchField p-2">
      <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
      <input
        className="cust-btn ms-4 w-100"
        placeholder="Enter Project Name, Client Name, PO# to search"
      />
      <img
        src={user?.image_url ? user?.image_url : manImage}
        className="searchManImg rounded-5"
        role="button"
        onClick={()=>navigate("/profiles", {
          state: { type: "view", data: user },
        })}
      />
    </div>
  );
};

export default ProductSearchHeader;
