import React, { useEffect, useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Filter,
  Filter1Outlined,
  FilterAlt,
  Search,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { animatedImg, product, searchIcon } from "../../assets/img";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ProductMap = ({
  mapProductList,
  setPage,
  page,
  setSearchValue,
  searchValue,
  getProPriceList,
  listCat,
  statusValue,
  catValue,

  fromDateMap,
  setFromDateMap,
  toDateMap,
  setTodateMap,
}) => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [pageRow, setPageRow] = useState(10);
  const [pagenation, setPagination] = useState({});

  useEffect(() => {
    if (mapProductList?.lists?.length > 0) {
      setList(mapProductList?.lists);
      setPagination(mapProductList?.pagination_meta);
    } else {
      setList([]);
    }
  }, [mapProductList]);

  return (
    <>
      <div className="d-flex ac-jb flex-column flex-md-row">
        <div className="d-flex gap-3">
          <select
            className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3"
            onChange={(e) =>
              getProPriceList(searchValue, 12, statusValue, e.target.value)
            }
            value={catValue}
          >
            <option value={""}>Select Categories</option>
            {listCat?.map((item, ind) => {
              return (
                <option key={ind} value={item?.id}>
                  {item?.name}
                </option>
              );
            })}
          </select>
          <select
            className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3"
            onChange={(e) =>
              getProPriceList(searchValue, 12, e.target.value, catValue)
            }
            value={statusValue}
          >
            <option value={""}>Select Status</option>
            <option value={1}>Request</option>
            <option value={2}>Inprocess</option>
            <option value={5}>Rejected</option>
          </select>
        </div>
        <div className="d-flex ac-jb flex-column flex-md-row gap-3 justify-content-md-end">
          <div className="d-flex  border-search">
            <img src={searchIcon} alt="img" className="searchiConImg mt-1" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
              onChange={(e) =>
                getProPriceList(e.target.value, 12, statusValue, catValue)
              }
              value={searchValue}
            />
          </div>
          <div className="d-flex ac-jc gap-3">
            <button
              className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
              onClick={(e) => {
                getProPriceList("", 12, "", "");
                setFromDateMap("");
                setTodateMap("");
              }}
            >
              <CachedIcon />
            </button>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-4 flex-wrap">
        <div className="d-flex ac-jb w-xs-100 mt-xs-5 position-relative">
          <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary me-3">
            From
          </p>
          <input
            type="date"
            id="from"
            name="from"
            className="cust-btn searchFill w-xs-100 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
            onChange={(e) => setFromDateMap(e.target.value)}
            value={fromDateMap}
          />
          <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mx-3">
            To
          </p>
          <input
            type="date"
            id="from"
            name="from"
            className="cust-btn searchFill w-xs-100 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
            onChange={(e) => setTodateMap(e.target.value)}
            value={toDateMap}
          />
        </div>
      </div>
      <div className="w-100 d-flex ac-js mt-4 flex-wrap">
        {list?.map((item, ind) => {
          return (
            <div
              className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
              onClick={() =>
                navigate("/map-request-view", {
                  state: { item: item, map: "map" },
                })
              }
              key={ind}
            >
              <div className="prod-list-box">
                <div className="cust-btn d-flex ac-jc rounded-3">
                  <img
                    src={
                      item?.product?.product_image
                        ? item?.product?.product_image
                        : product
                    }
                    alt=""
                    className="custom-w-h rounded-3"
                  />
                </div>
                <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                  {item?.vendor?.leganEntityName}
                </p>
                <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary parag mb-2">
                  {item?.product?.name}
                </p>
                <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
                  {item?.product?.category_name}
                </p>
                <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 d-flex ac-jb gap-2">
                  {/* SKU0001{"  "} */}
                  Status{"  "}
                  <span
                    className={`${
                      item?.request_status
                        ?.toLowerCase()
                        .startsWith("in progress")
                        ? "text-warning"
                        : item?.request_status
                            ?.toLowerCase()
                            .startsWith("requested")
                        ? "text-primary"
                        : item?.request_status
                            ?.toLowerCase()
                            .startsWith("accep")
                        ? "text-success"
                        : item?.request_status?.toLowerCase().startsWith("reje")
                        ? "text-danger"
                        : ""
                    }  f4 d-flex ac-jb`}
                  >
                    {item?.request_status}
                    {item?.request_status?.toLowerCase().startsWith("reje") && (
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        overlay={(props) => (
                          <Tooltip {...props}>{item?.notes}</Tooltip>
                        )}
                        placement="bottom"
                      >
                        <InfoOutlinedIcon
                          className="fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-1 parag"
                          style={{ color: "#06679c" }}
                        />
                      </OverlayTrigger>
                    )}
                  </span>
                </p>
              </div>
            </div>
          );
        })}
        {/* <div
          className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
          onClick={() => navigate("/map-request-view")}
        >
          <div className="prod-list-box">
            <div className="cust-btn d-flex ac-jc rounded-3">
              <img src={product} alt="" className="custom-w-h rounded-3" />
            </div>
            <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
              Charles
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
              Mobile Phone
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 d-flex ac-jb">
              SKU0001 <span className="text-warning f4">In progress</span>
            </p>
          </div>
        </div>
        <div
          className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
          onClick={() => navigate("/map-request-view")}
        >
          <div className="prod-list-box">
            <div className="cust-btn d-flex ac-jc rounded-3">
              <img src={product} alt="" className="custom-w-h rounded-3" />
            </div>
            <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
              Charles
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
              Mobile Phone
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 d-flex ac-jb">
              SKU0001{" "}
              <span className="text-danger f4 d-flex">
                Rejected
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props}>
                      Product Rejected reason will show here
                    </Tooltip>
                  )}
                  placement="bottom"
                >
                  <InfoOutlinedIcon
                    className="fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-1 parag mt-1"
                    style={{ color: "#06679c" }}
                  />
                </OverlayTrigger>
              </span>
            </p>
          </div>
        </div>
        <div
          className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
          onClick={() => navigate("/map-request-view")}
        >
          <div className="prod-list-box">
            <div className="cust-btn d-flex ac-jc rounded-3">
              <img src={product} alt="" className="custom-w-h rounded-3" />
            </div>
            <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
              Charles
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
              Mobile Phone
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 d-flex ac-jb">
              SKU0001 <span className="primary f4">Requested</span>
            </p>
          </div>
        </div> */}
      </div>
      <div className="d-flex justify-content-between mt-3">
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            page == 1 && "opacity-50"
          }`}
          onClick={() => setPage(page - 1)}
          disabled={page == 1 ? true : false}
        >
          Previous
        </button>
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            page == pagenation?.last_page && "opacity-50"
          }`}
          onClick={() => setPage(page + 1)}
          disabled={page == pagenation?.last_page ? true : false}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default ProductMap;
