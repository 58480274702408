import React, { useEffect, useState } from "react";
import { product } from "../../assets/img";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { sub_menu } from "../../redux/api/DummyJson";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useLocation } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";

const PricingBoxCompNew = ({
  edit,
  setEdit,
  setpriceBook,
  handleEdit,
  setPreviousOrder,
  distributorPriceErr,
  status,
  type,
  setCost,
  cost,
  setNet,
  net,
  setMargin,
  margin,
  setDistributorPrice,
  distributorPrice,
  setPriceCharge,
  priceCharge,
  setMarginBox,
  marginBox,
  setDisabled,
  disabled,
  addpriceCharge,
  removepriceCharge,
  handlepriceCharge,
  handleCost,
  handleMargin,
  handleCloseMargin,
}) => {
  const location = useLocation();
  const data = location?.state?.item;
  const vendors = location?.state?.data;
  const show = location?.state?.status;
  const path = location?.pathname;
  const [box1, setbox1] = useState(25);
  const [box2, setbox2] = useState(50);
  const [box3, setbox3] = useState(100);
  const [box4, setbox4] = useState(150);
  const [box5, setbox5] = useState(250);
  const [box6, setbox6] = useState(500);
  const [box7, setbox7] = useState(1000);

  // console.log(
  //   "data?.vendorRequestDetails?.status",
  //   !data?.vendorRequestDetails?.status,
  //   data?.vendorRequestDetails?.status
  // );

  return (
    <div className="h-auto mt-4">
      <div className="d-flex">
        <p className="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
          Vendor Pricing
        </p>
        {show == "request" && (
          <button
            className="cust-btn addbtn  mx-3  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            onClick={() => {
              setDisabled(!disabled);
              setEdit(!edit);
            }}
          >
            Edit
          </button>
        )}
        {path == "/map-product-detail" &&
        !data?.vendorRequestDetails?.status ? (
          <button
            className="cust-btn addbtn  mx-3  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            onClick={() => {
              handleEdit();
            }}
          >
            {/* Edit */}
            {/* {edit ? "Requested" : "Request"} */}
            Share Price
          </button>
        ) : path == "/map-product-detail" &&
          data?.vendorRequestDetails?.status
            ?.toLowerCase()
            .startsWith("reque") ? (
          <button
            className="cust-btn addbtn  mx-3  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            onClick={() => {
              handleEdit();
            }}
          >
            Share Price
          </button>
        ) : null}
      </div>
      <fieldset
        disabled={edit ? false : true}
        className={!edit && "opacity-75"}
      >
        <div className="">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-3">
            {vendors?.vendor?.name}
          </p>
          <div className="overflow-scroll">
            <table className="">
              <tr className="mt-4">
                <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Qty
                </th>
                <div className="my-3" style={{ display: "inline-block" }}>
                  <td>
                    <div className="d-flex w-100">
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="text"
                          value={box1}
                          onChange={(e) => setbox1(e.target.value)}
                          disabled
                          // disabled={!edit ? true : disabled ? true : false}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="text"
                          value={box2}
                          // disabled={!edit ? true : false}
                          onChange={(e) => setbox2(e.target.value)}
                          disabled
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="text"
                          value={box3}
                          // disabled={!edit ? true : false}
                          onChange={(e) => setbox3(e.target.value)}
                          disabled
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn f2 px-3 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="text"
                          // disabled={!edit ? true : false}
                          value={box4}
                          onChange={(e) => setbox4(e.target.value)}
                          disabled
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn f2 px-3 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="text"
                          value={box5}
                          // disabled={!edit ? true : false}
                          onChange={(e) => setbox5(e.target.value)}
                          disabled
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn f2 px-3 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="text"
                          value={box6}
                          // disabled={!edit ? true : false}
                          onChange={(e) => setbox6(e.target.value)}
                          disabled
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="text"
                          value={box7}
                          // disabled={!edit ? true : false}
                          onChange={(e) => setbox7(e.target.value)}
                          disabled
                        />
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
              <tr>
                <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Cost
                </th>
                <div className="mt-2" style={{ display: "inline-block" }}>
                  <td>
                    <div className="d-flex w-100">
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="number"
                          placeholder="₹00.00"
                          maxlength={3}
                          value={cost?.for_25}
                          onChange={(e) => handleCost(e.target.value, "for_25")}
                          // disabled={!edit ? true : disabled ? true : false}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹00.00"
                          type="number"
                          value={cost?.for_50}
                          onChange={(e) => handleCost(e.target.value, "for_50")}
                          // disabled={!edit ? true : false}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹00.00"
                          type="number"
                          value={cost?.for_100}
                          onChange={(e) =>
                            handleCost(e.target.value, "for_100")
                          }
                          // disabled={!edit ? true : false}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹00.00"
                          type="number"
                          value={cost?.for_150}
                          onChange={(e) =>
                            handleCost(e.target.value, "for_150")
                          }
                          // disabled={!edit ? true : false}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹00.00"
                          type="number"
                          value={cost?.for_250}
                          onChange={(e) =>
                            handleCost(e.target.value, "for_250")
                          }
                          // disabled={!edit ? true : false}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹00.00"
                          type="number"
                          value={cost?.for_500}
                          onChange={(e) =>
                            handleCost(e.target.value, "for_500")
                          }
                          // disabled={!edit ? true : false}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹00.00"
                          type="number"
                          value={cost?.for_1000}
                          onChange={(e) =>
                            handleCost(e.target.value, "for_1000")
                          }
                          // disabled={!edit ? true : false}
                        />
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
              {/* <tr>
              <td className="" colSpan={2}>
                <div
                  className="text-center"
                  style={{ display: "inline-block" }}
                >
                  <button
                    className="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar  text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14  rounded"
                    onClick={() => addpriceCharge()}
                  >
                   + add charges
                  </button>
                </div>
              </td>
            </tr> */}
              <tr>
                <th className="  f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  <button
                    className="cust-btn addbtn mt-sm-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-11 fs-xl-11 fs-xxl-12 px-5 rounded"
                    onClick={() => addpriceCharge()}
                  >
                    + Add Charges
                  </button>
                </th>
              </tr>
              {priceCharge?.map((item, ind) => {
                return (
                  <tr>
                    <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                      {" "}
                      <span>
                        <button
                          className="cust-btn"
                          onClick={() => removepriceCharge(item, ind)}
                        >
                          <HighlightOffIcon
                            style={{ width: "15px" }}
                            className="mb-3"
                          />
                        </button>
                      </span>
                      <input
                        className="mx-1 num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="text"
                        style={{ width: "120px", height: "35px" }}
                        value={item?.name}
                        name="name"
                        onChange={(e) => handlepriceCharge(e, ind, "name")}
                        // disabled={!edit ? true : false}
                      />
                    </th>
                    <div className="mt-2" style={{ display: "inline-block" }}>
                      <td>
                        <div className="d-flex w-100">
                          <div className="mx-2">
                            <input
                              className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              type="number"
                              placeholder="₹00.00"
                              maxlength={3}
                              value={item?.for_25}
                              name="for_25"
                              onChange={(e) =>
                                handlepriceCharge(e, ind, "for_25")
                              }
                              // disabled={!edit ? true : false}
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              placeholder="₹00.00"
                              type="number"
                              value={item?.for_50}
                              name="for_50"
                              onChange={(e) =>
                                handlepriceCharge(e, ind, "for_50")
                              }
                              // disabled={!edit ? true : false}
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              placeholder="₹00.00"
                              type="number"
                              value={item?.for_100}
                              name="for_100"
                              onChange={(e) =>
                                handlepriceCharge(e, ind, "for_100")
                              }
                              // disabled={!edit ? true : false}
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              placeholder="₹00.00"
                              type="number"
                              value={item?.for_150}
                              name="for_150"
                              onChange={(e) =>
                                handlepriceCharge(e, ind, "for_150")
                              }
                              // disabled={!edit ? true : false}
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              placeholder="₹00.00"
                              type="number"
                              value={item?.for_250}
                              name="for_250"
                              onChange={(e) =>
                                handlepriceCharge(e, ind, "for_250")
                              }
                              // disabled={!edit ? true : false}
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              placeholder="₹00.00"
                              type="number"
                              value={item?.for_500}
                              name="for_500"
                              onChange={(e) =>
                                handlepriceCharge(e, ind, "for_500")
                              }
                              // disabled={!edit ? true : false}
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              placeholder="₹00.00"
                              type="number"
                              value={item?.for_1000}
                              name="for_1000"
                              onChange={(e) =>
                                handlepriceCharge(e, ind, "for_1000")
                              }
                              // disabled={!edit ? true : false}
                            />
                          </div>
                        </div>
                      </td>
                    </div>
                  </tr>
                );
              })}
              <tr>
                <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Net
                </th>
                <div className="mt-2" style={{ display: "inline-block" }}>
                  <td>
                    <div className="d-flex w-100">
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="number"
                          placeholder="₹00.00"
                          maxlength={3}
                          disabled
                          value={net?.for_25}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹00.00"
                          type="number"
                          disabled
                          value={net?.for_50}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹00.00"
                          type="number"
                          disabled
                          value={net?.for_100}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹00.00"
                          type="number"
                          disabled
                          value={net?.for_150}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹00.00"
                          type="number"
                          disabled
                          value={net?.for_250}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹00.00"
                          type="number"
                          disabled
                          value={net?.for_500}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹00.00"
                          type="number"
                          disabled
                          value={net?.for_1000}
                        />
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
              <tr>
                <th className="  f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  <button
                    className="cust-btn addbtn mt-sm-3  mt-2 bg-primar text-white f3 fs-11 fs-lg-11 fs-xl-11 fs-xxl-12 px-5 rounded"
                    onClick={() => handleCloseMargin()}
                  >
                    +Add Margin
                  </button>
                </th>
                {!marginBox && (
                  <div className="mt-2" style={{ display: "inline-block" }}>
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <input
                            className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            placeholder="%00.00"
                            maxlength={3}
                            value={margin?.for_25}
                            onChange={(e) =>
                              handleMargin(e.target.value, "for_25")
                            }
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            placeholder="%00.00"
                            type="number"
                            value={margin?.for_50}
                            onChange={(e) =>
                              handleMargin(e.target.value, "for_50")
                            }
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            placeholder="%00.00"
                            type="number"
                            value={margin?.for_100}
                            onChange={(e) =>
                              handleMargin(e.target.value, "for_100")
                            }
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            placeholder="%00.00"
                            type="number"
                            value={margin?.for_150}
                            onChange={(e) =>
                              handleMargin(e.target.value, "for_150")
                            }
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            placeholder="%00.00"
                            type="number"
                            value={margin?.for_250}
                            onChange={(e) =>
                              handleMargin(e.target.value, "for_250")
                            }
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            placeholder="%00.00"
                            type="number"
                            value={margin?.for_500}
                            onChange={(e) =>
                              handleMargin(e.target.value, "for_500")
                            }
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            placeholder="%00.00"
                            type="number"
                            value={margin?.for_1000}
                            onChange={(e) =>
                              handleMargin(e.target.value, "for_1000")
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </div>
                )}
              </tr>

              <tr>
                <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Distributor Price
                </th>
                <div className="mt-2" style={{ display: "inline-block" }}>
                  <td>
                    <div className="d-flex w-100">
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="number"
                          placeholder="₹00.00"
                          maxlength={3}
                          disabled
                          value={distributorPrice?.for_25}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹00.00"
                          type="number"
                          disabled
                          value={distributorPrice?.for_50}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹00.00"
                          type="number"
                          disabled
                          value={distributorPrice?.for_100}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹00.00"
                          type="number"
                          disabled
                          value={distributorPrice?.for_150}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹00.00"
                          type="number"
                          disabled
                          value={distributorPrice?.for_250}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹00.00"
                          type="number"
                          disabled
                          value={distributorPrice?.for_500}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹00.00"
                          type="number"
                          disabled
                          value={distributorPrice?.for_1000}
                        />
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
            </table>
          </div>
        </div>
        {distributorPriceErr && (
          <div className="d-flex gap-1 mt-2">
            <ErrorIcon className="svg_log" />
            <p className="err-input_log mt-1">File Vendor Pricing</p>
          </div>
        )}
      </fieldset>
    </div>
  );
};

export default PricingBoxCompNew;
