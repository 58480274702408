// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useHqListMutation, useLazyHq_viewQuery } from "../../../redux/api/api";
import { noLogo } from "../../../assets/img";
import DOMPurify from "dompurify";

const SpecialOfferViewDetails = () => {
  const location = useLocation();
  const [data, setData] = useState("");
  const [list,setList]=useState([])
  const [action, setAction] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setData(location?.state?.data);
  }, [location]);

  console.log(data);
  //RTK
  const [hqListApi]=useHqListMutation();

  const getHqView=()=>{
    const formData=new URLSearchParams();
    formData.append("type",3);
    hqListApi(formData)
    .unwrap()
    .then((res)=>{
      const temp=[]
      res?.lists.map((item,ind)=>{
        const json = JSON.parse(item?.contents)
        temp.push({
          ...json,
          ...item
        })
      })
      setList(temp)
      console.log(temp,"res")
    })
    .catch((err)=>{
      console.log(err)
    })
  }
useEffect(()=>{
  getHqView()
},[])

  return (
    <div className="dashRightView p-5 home_section trans">
      <div className="d-flex ac-jb">
        <p
          className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-0 mb-3 d-flex align-items-center gap-1"
          role={"button"}
          onClick={() => navigate(-1)}
        >
          <KeyboardBackspaceIcon /> Special Offer Details
        </p>

        <button
          onClick={() => navigate("/special-offers-edit",{state:{data:list}})}
          className="pointerView cust-btn addbtn ms-0 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded mb-3"
        >
          Edit
        </button>
      </div>
      <div className="w-100 searchField mt-0 bg-white ">
        <div className="w-100 d-flex flex-xxl-row flex-xl-row flex-lg-row flex-column">
          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Name:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {list?.[0]?.name?.substring(0,30)}...
              </p>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Special Offer Image:<span style={{color:"#f82a2a"}}>(443px*404px)</span>
              </p>
              <div className="w-70 ms-3">
                <img src={list?.[0]?.image_url?list?.[0]?.image_url:noLogo} className="imgDashboard" />
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Description:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black" dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          // list?.description
                          list?.[0]?.description?.substring(0,50)
                        ),
                      }} >
               {/* {list?.[0]?.description?.substring(0,50)}   */}
              </p>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Button Name:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {list?.[0]?.button_name?.substring(0,20)}
              </p>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Button Link:
              </p>
              <a
                href="#"
                className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-primary"
              >
                {list?.[0]?.link}
              </a>
            </div>
            {/* <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Priority Status:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.orderId}
              </p>
            </div> */}

            {/* <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Status
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.productName}
              </p>
            </div> */}
          </div>
        </div>
      </div>
      <button
        onClick={() => navigate(-1)}
        className="pointerView cust-btn addbtn ms-0 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
      >
        Back
      </button>
    </div>
  );
};

export default SpecialOfferViewDetails;
