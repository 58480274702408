import { Search } from "@mui/icons-material";
import { animatedImg, profilePic, vendorBannerImg } from "../assets/img";
import BusinessIcon from "@mui/icons-material/Business";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ProfileDetails from "../components/VendorDetails/ProfileDetails";
import EditProfileDetails from "../components/VendorDetails/EditProfileDetails";
import HistoryDetails from "../components/VendorDetails/HistoryDetails";
import EditContactPopup from "../components/Popup/EditContactPopup";
import EditAddressPopup from "../components/Popup/EditAddressPopup";
import ContactDetails from "../components/VendorDetails/ContactDetails";
import PositionPopup from "../components/Popup/PositionPopup";
import LibraryDetails from "../components/VendorDetails/LibraryDetails";
import ProductDetails from "../components/VendorDetails/ProductDetails";
import { useSelector } from "react-redux";
import POSandBills from "../components/VendorDetails/POSandBills";
import CollaborateList from "../components/VendorDetails/CollaborateList";
import DecorativeDetails from "../components/VendorDetails/DecorativeDetails";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import ProductVendorPoScreen from "./ProductVendorPoScreen";
import {
  useAddProfileMutation,
  useAddressAddMutation,
  useAddressEditMutation,
  useEditProfileMutation,
  useLazyAddressListQuery,
  useLazyAddressStatusQuery,
  useLazyCategoriesListVendorQuery,
  useLazyCurrencyListsQuery,
  useLazyDecorativeListQuery,
  useLazyProfile_statusQuery,
  useLazyTagsListQuery,
  useLazyVendorViewNewQuery,
  useRoleListsMutation,
  useVendorCreateEditMutation,
  useVendorDepartmentListMutation,
  useVendorPositionChangeMutation,
} from "../redux/api/api";
import { cityList, countriesList, stateList } from "../redux/api/Country";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";

const ProductVendorDetailsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const offeringTypes = location?.state?.data?.offering_type;
  // console.log(location.state.type);
  // console.log("location", location.state);
  const [tab, setTab] = useState(0);
  const [profile, setProfile] = useState(false);
  const [contact, setContact] = useState(false);
  const [library, setLibrary] = useState(false);
  const [products, setProducts] = useState(false);
  const [bills, setBills] = useState(false);
  const [history, setHistory] = useState(false);
  const [collaborate, setCollaborate] = useState(false);
  const [decorative, setDecorative] = useState(false);

  const [editProfile, setEditProfile] = useState(false);
  const [contactPopup, setContactPopup] = useState(false);
  const [addressPopup, setAddressPopup] = useState(false);
  const [positionPopup, setPositionPopup] = useState(false);
  const [positionNumberErr, setPoistionNumberErr] = useState(false);

  // FOR PROFILE START
  //General Details
  // const [categoryList, setCategoryList] = useState([]);
  const [firstname, setfirstname] = useState("");
  const [vendorCat, setVendorCat] = useState("");
  const [vendorCatogory, setVendorCatogory] = useState("");
  // const [currenciesLists, setCurrenciesLists] = useState([]);
  const [mobile, setmobile] = useState("");
  const [name, setname] = useState("");
  const [Tradename, setTradename] = useState("");
  const [vendorOfferType, setVendorOfferType] = useState("");
  const [lineBusiness, setLineBusiness] = useState([]);
  const [list, setList] = useState([]);
  // const[tags,setTags]=useState([])
  const [id, setid] = useState([]);
  // const[tagsList,setTagsList]=useState([]);
  const [paymentTerms, setPaymentTerms] = useState("");
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [address1, setAddress1] = useState("");
  const [GstID, setGstID] = useState("");
  const [currency, setCurrency] = useState("");
  const [currencyName, setCurrencyName] = useState("");
  const [description, setDescription] = useState("");
  const [logo, setLogo] = useState("");
  const [banner, setBanner] = useState("");
  const [btn, setBtn] = useState(false);
  const [update, setUpdate] = useState(false);
  const [vendorUpdateId, setVendorUpdateId] = useState(false);
  const [vendorUpdateIdErr, setVendorUpdateIdErr] = useState(false);

  //error
  const [nameErr, setnameErr] = useState(false);
  const [lineBusinessErr, setLineBusinessErr] = useState(false);
  // const [tagsErr,setTagsErr]=useState(false);
  const [TradenameErr, setTradenameErr] = useState(false);
  const [vendorCatErr, setVendorCatErr] = useState(false);
  const [vendorOfferTypeErr, setVendorOfferTypeErr] = useState(false);
  const [paymentTermsErr, setPaymentTermsErr] = useState(false);
  const [paymentMethodErr, setPaymentMethodErr] = useState(false);
  const [GstIDErr, setGstIDErr] = useState(false);
  const [currencyErr, setCurrencyErr] = useState(false);
  const [descriptionErr, setDescriptionErr] = useState(false);
  const [logoErr, setLogoErr] = useState(false);
  const [bannerErr, setBannerErr] = useState(false);
  const [profileEditBtn, setProfileEditBtn] = useState(false);
  const [positionBtn, setPositionBtn] = useState(false);
  //

  // FOR CONTACT START
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [position, setPosition] = useState("");
  const [role, setRole] = useState("");
  const [number, setNumber] = useState([{ number_type: "work", number: "" }]);
  const [department, setDepartment] = useState("");
  const [twitter, setTwitter] = useState("");
  const [facebook, setFacebook] = useState("");
  const [linkIn, setLinkIn] = useState("");
  const [skype, setSkype] = useState("");
  const [address, setAddress] = useState("");
  const [noMarketing, setNoMarketing] = useState(0);
  const [tags, setTags] = useState("");
  const [tagsProfile, setTagsProfile] = useState("");
  const [primaryNumbers, setPrimaryNumbers] = useState("");
  const [primaryNumbersErr, setPrimaryNumbersErr] = useState(false);

  const [emailView, setEmailView] = useState("");
  const [phoneView, setPhoneView] = useState("");

  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [positionErr, setPositionErr] = useState("");
  const [roleErr, setRoleErr] = useState("");
  const [numberErr, setNumberErr] = useState("");
  const [departmentErr, setDepartmentErr] = useState("");
  const [twitterErr, setTwitterErr] = useState("");
  const [facebookErr, setFacebookErr] = useState("");
  const [linkInErr, setLinkInErr] = useState("");
  const [skypeErr, setSkypeErr] = useState("");
  const [addressErr, setAddressErr] = useState("");
  const [noMarketingErr, setNoMarketingErr] = useState("");
  const [tagsErr, setTagsErr] = useState("");
  const [addressBtn, setAddressBtn] = useState(false);
  const [numbers, setNumbers] = useState("");

  const [vendorDepartmentList, setVendorDepartmentList] = useState([]);
  const [vendorDepartment, setVendorDepartment] = useState({});
  const [departmentList, setDepartmentList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [vendorAddressList, setVendorAddressList] = useState([]);
  const [vendorAddressLists, setVendorAddressLists] = useState([]);
  const [vendorData, setVendorData] = useState({});
  // FOR CONTACT END

  // FOR ADDRESS START
  const [addressName, setAddressName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [landmark, setLandMark] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [addressType, setAddressType] = useState("");

  const [addressNameErr, setAddressNameErr] = useState(false);
  const [companyNameErr, setCompanyNameErr] = useState(false);
  const [landmarkErr, setLandMarkErr] = useState(false);
  const [streetErr, setStreetErr] = useState(false);
  const [cityErr, setCityErr] = useState(false);
  const [countryErr, setCountryErr] = useState(false);
  const [stateErr, setStateErr] = useState(false);
  const [pincodeErr, setPincodeErr] = useState(false);
  const [addressTypeErr, setAddressTypeErr] = useState(false);
  const [btnAdd, setBtnAdd] = useState(false);
  const [currenciesLists, setCurrenciesLists] = useState([]);

  const [country1, setCountry1] = useState("");
  const [state1, setState1] = useState("");
  const [city1, setCity1] = useState("");
  const [pincode1, setPincode1] = useState("");

  const [listStates, setListStates] = useState([]);
  const [listCity, setListCity] = useState([]);
  const [countryId, setCountryId] = useState("");
  const [stateId, setStateId] = useState("");
  const [updateAddress, setUpdateAddress] = useState({});
  // FOR ADDRESS END

  // POSITION CHANGE START
  const [positionNumber, setPoistionNumber] = useState(null);
  const [positionData, setPositionData] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const [vendorCategories, setVendorCategories] = useState([]);
  const [vendorNameErr, setVendorNameErr] = useState(false);
  // POSITION CHANGE END
  const [categoryName, setCategoryName] = useState("");
  const [categoryNameErr, setCategoryNameErr] = useState(false);
  // const [vendorCatogory, setVendorCatogory] = useState("");

  //RTK
  const [categoryListApi] = useLazyCategoriesListVendorQuery();
  const [viewVendorApi] = useLazyVendorViewNewQuery();
  const [vendorEditApi] = useVendorCreateEditMutation();
  const [vendorDepartmentApi] = useVendorDepartmentListMutation();
  const [vendorUserCreateApi] = useAddProfileMutation();
  const [vendorUserUpdateApi] = useEditProfileMutation();
  const [vendorPositionApi] = useVendorPositionChangeMutation();

  const [roleapi] = useRoleListsMutation();
  const [tagsApi] = useLazyTagsListQuery();
  const [statusApi] = useLazyProfile_statusQuery();
  const [currencyListApi] = useLazyCurrencyListsQuery();

  const [addressApi] = useLazyAddressListQuery();
  const [addressAddApi] = useAddressAddMutation();
  const [addressEditApi] = useAddressEditMutation();
  const [addressStatusApi] = useLazyAddressStatusQuery();

  const [decorativeListsApi] = useLazyDecorativeListQuery();

  const [action, setAction] = useState(false);
  // console.log(paymentMethod, "paymentTerms");
  const vendors = location?.state?.data?.id;
  // console.log(location, "vendorsProduct");
  const place = location?.state?.name;
  const type = location?.state?.name;
  const place2 = location?.state?.place;
  // console.log(place);
  // console.log(place);
  // console.log(type);
  const category = location?.state?.category;
  // console.log(category);
  const path = location?.pathname;

  // console.log("location", offeringTypes);
  useEffect(() => {
    if (location?.state?.type === "library") {
      setLibrary(true);
    } else if (location?.state?.type === "collaborate") {
      setCollaborate(true);
    }
    // else if (type === 'decorative') {
    //   setProfile(false);
    //   setContact(false);
    //   setLibrary(false);
    //   setProducts(false);
    //   setBills(false);
    //   setHistory(false);
    //   setEditProfile(false);
    //   setCollaborate(false);
    //   setDecorative(true)
    // }
    // else if (type === 'product') {
    //   setProfile(false);
    //   setContact(false);
    //   setLibrary(false);
    //   setProducts(true);
    //   setBills(false);
    //   setHistory(false);
    //   setEditProfile(false);
    //   setCollaborate(false);
    //   setDecorative(false)
    // }
    else {
      setProfile(true);
    }
    // if (place === 'Jake Adams') {
    //   setProducts(true)
    //   setDecorative(true)
    // }
  }, []);
  // ADDRESS CREATE
  const submitHandleAddress = () => {
    if (
      addressName?.length == 0 ||
      companyName?.length == 0 ||
      landmark?.length == 0 ||
      street?.length == 0 ||
      country?.length == 0 ||
      state?.length == 0 ||
      city?.length == 0 ||
      pincode?.length == 0 ||
      addressType?.length == 0
    ) {
      setAddressNameErr(true);
      setCompanyNameErr(true);
      setLandMarkErr(true);
      setStreetErr(true);
      setCountryErr(true);
      setStateErr(true);
      setCityErr(true);
      setPincodeErr(true);
      setAddressTypeErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("vendor_id", vendors);
      formdata.append("name", companyName);
      formdata.append("address", addressName);
      formdata.append("landmark", landmark);
      formdata.append("state", state);
      formdata.append("city", city);
      formdata.append("country", country);
      formdata.append("type", addressType);
      formdata.append("pincode", pincode);
      formdata.append("street", street);

      setBtnAdd(true);
      dispatch(saveLoader(true));

      if (updateAddress?.id) {
        formdata.append("vendor_address_id", updateAddress?.id);
        // addressEditApi({ payload: formdata, id: updateAddress?.id })
        addressEditApi(formdata)
          .unwrap()
          .then((res) => {
            setUpdateAddress({});
            setBtnAdd(false);
            dispatch(saveLoader(false));
            setCompanyName("");
            setAddressName("");
            setLandMark("");
            setState("");
            setCity("");
            setCountry("");
            setAddressType("");
            setPincode("");
            setStreet("");
            getAddressList(vendors);
            setAddressPopup(false);
            setStateId("");
            setListStates([]);
            setListCity([]);
            setCountryId("");
          })
          .catch((err) => {
            setBtnAdd(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });
      } else {
        addressAddApi(formdata)
          .unwrap()
          .then((res) => {
            setBtnAdd(false);
            setUpdateAddress({});
            dispatch(saveLoader(false));
            setCompanyName("");
            setAddressName("");
            setLandMark("");
            setState("");
            setCity("");
            setCountry("");
            setAddressType("");
            setPincode("");
            setStreet("");
            getAddressList(vendors);
            setAddressPopup(false);
            setStateId("");
            setListStates([]);
            setListCity([]);
            setCountryId("");
          })
          .catch((err) => {
            setBtnAdd(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });
      }
    }
  };

  //Category list

  const getList = () => {
    categoryListApi()
      .unwrap()
      .then((res) => {
        setCategoryList(res?.categories);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getList();
  }, []);

  // VENDOR USER STATUS CHANGE
  const change = (id) => {
    statusApi(id)
      .unwrap()
      .then((res) => {
        getDepartments();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // VENDOR VIEW API
  const getViewProfile = (id) => {
    // vendorViewApi(id)
    viewVendorApi(id)
      .unwrap()
      .then((res) => {
        let data = res?.profile;
        // console.log("data", res?.contact);
        setVendorData(res?.contact);
        setEmailView(data?.vendorUser?.email);
        setPhoneView(data?.vendorUser?.phone_number);
        setname(data?.legal_name);
        setTradename(data?.name);
        var offer =
          data?.offering_type == "1"
            ? "Product"
            : data?.offering_type == "2"
            ? "Services"
            : data?.offering_type == "3"
            ? "Both"
            : null;
        setVendorOfferType(offer);
        setList(data?.decorativeMethods);

        // setLineBusiness(data?.line_of_business);

        if (Array.isArray(data?.line_of_business)) {
          const line = data?.line_of_business?.map((item) => item);
          setLineBusiness(line);
        }
        let payment_methodArr = [];
        if (Array.isArray(data?.payment_method)) {
          data?.payment_method?.map((item) => {
            payment_methodArr.push(item);
          });
          setPaymentMethod(payment_methodArr);
        } else {
          setPaymentMethod(payment_methodArr);
        }
        if (Array.isArray(data?.vendorCategory)) {
          const categories = data.vendorCategory.map((item) => item.name);
          const categoriesId = data.vendorCategory.map((item) => item.id);
          setVendorCatogory(categories);
          setVendorCategories(categoriesId);
        }

        if (Array.isArray(data?.tags)) {
          const tags = data?.tags.map((item) => item?.name);
          const tags_id = data?.tags.map((item) => item?.id);
          setTagsProfile(tags);
          setid(tags_id);
        }
        setBanner(data?.banner_url);
        setAddress1(data?.address1);
        setCountry1(data?.country);
        setState1(data?.state);
        setCity1(data?.city);
        setPincode1(data?.pincode);
        // console.log("data", data?.logo_url);
        setLogo(data?.logo_url);
        setVendorCat(data?.type_of_business);
        setPaymentTerms(data?.payment_terms);
        // setPaymentMethod(data?.payment_method);
        setGstID(data?.gst);
        setCurrencyName(data?.currency?.code);
        setCurrency(data?.currency?.id);
        setDescription(data?.description);

        // setTags();
        // setBannerImage(data?.banner_url);
        // setVendorOfferingType(data?.offering_type);
        // setTypeofBusiness(data?.type_of_business);
        // setCurrency(data?.currency?.id);
        // setPaymentMethod(data?.payment_method);
        // setPaymentTerms(data?.payment_terms);
        // setGSTNumber(data?.gst);
        // setVendorID(data?.id);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // GET VENDOR FROM DEPARTMENT LIST
  const getVendorView = (item, user, ind, inx, type) => {
    // console.log("item", item);
    // console.log("user", user);
    if (type == "add") {
      setVendorDepartment(item);
      setDepartment(item?.id);
    } else {
      setFirstName(user?.first_name);
      setLastName(user?.last_name);
      setEmail(user?.email);
      setPosition(user?.position);
      setRole(user?.role_id);
      setDepartment(user?.vendor_department_id);
      if (user?.vendor_address_id) {
        setAddress(user?.vendor_address_id);
      }
      setNoMarketing(user?.is_marketing);
      setVendorDepartment(user);
      if (user?.primary_mobile) {
        setPrimaryNumbers(user?.primary_mobile);
      }

      setTags(user?.tags?.[0]?.id);
      let additional_info =
        user?.additional_info == ""
          ? user?.additional_info
          : JSON.parse(user?.additional_info);
      let mobile =
        user?.mobile == "-" ? user?.mobile : JSON.parse(user?.mobile);
      if (typeof additional_info == "object") {
        setTwitter(additional_info?.twitter);
        setFacebook(additional_info?.facebook);
        setLinkIn(additional_info?.linkedin);
        setSkype(additional_info?.skype);
      }
      let mobileArr = [];
      if (Array.isArray(mobile)) {
        mobile?.map((item, ind) => {
          mobileArr.push({
            number_type: item?.type,
            number: item?.number,
          });
          setNumbers(item?.number);
        });
      } else {
        mobileArr.push({ number_type: "work", number: "" });
      }

      setNumber(mobileArr);
    }
    setContactPopup(true);
  };

  const showPopupHander = () => {
    setContactPopup(false);
    setAddressPopup(false);
    setPositionPopup(false);

    setTwitter("");
    setFacebook("");
    setAddress("");
    setSkype("");
    setLinkIn("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setRole("");
    setPassword("");
    setPosition("");
    setDepartment("");
    setNoMarketing("");
    setTags("");
    setVendorDepartment({});
    setNumber([{ number_type: "work", number: "" }]);
    setCompanyName("");
    setAddressName("");
    setLandMark("");
    setState("");
    setCity("");
    setCountry("");
    setAddressType("");
    setPincode("");
    setStreet("");
    setUpdateAddress({});
    setStateId("");
    setListStates([]);
    setListCity([]);
    setCountryId("");
    setAddressNameErr(false);
    setCompanyNameErr(false);
    setLandMarkErr(false);
    setStreetErr(false);
    setCityErr(false);
    setCountryErr(false);
    setStateErr(false);
    setPincodeErr(false);
    setAddressTypeErr(false);
    setFirstNameErr(false);
    setLastNameErr(false);
    setEmailErr(false);
    setRoleErr(false);
    setPositionErr(false);
    setAddressErr(false);
    setDepartmentErr(false);
    setPasswordErr(false);
    setNumberErr(false);
    setNumbers("");
    setPrimaryNumbersErr(false);
    setPrimaryNumbers("");
  };

  const getrole = () => {
    const formdata = new URLSearchParams();
    formdata.append("type", "2");
    roleapi(formdata)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setRoleList(res?.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    tagsApi()
      .unwrap()
      .then((res) => {
        setTagsList(res?.normalTag);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    getrole();
  }, []);

  // DEPARTMENT API
  const getDepartments = () => {
    const formData = new FormData();
    formData.append("vendor_id", vendors);
    vendorDepartmentApi(formData)
      .unwrap()
      .then((res) => {
        let vendorDep = [];
        let temp = [];
        let vendorArr = [];
        res?.vender_departments?.map((item, ind) => {
          item?.vendor_user?.map((vendor, inx) => {
            let jsonParserAddional = vendor?.additional_info
              ? vendor?.additional_info
              : JSON.parse(vendor?.additional_info);
            let jsonParserMobile =
              vendor?.mobile == "-"
                ? vendor?.mobile
                : JSON.parse(vendor?.mobile);
            let obj2 = {
              ...vendor,
            };

            if (jsonParserMobile) {
              obj2.mobile_list = jsonParserMobile;
            }

            if (jsonParserAddional) {
              obj2.addional_list = jsonParserAddional;
            }

            vendorArr.push(obj2);
          });

          // console.log("vendorArr", vendorArr);

          vendorDep.push({
            ...item,
            vendor_user: vendorArr?.filter(
              (i) => i?.vendor_department_id == item?.id
            ),
          });

          temp.push({
            id: item?.id,
            name: item?.name,
          });
        });

        // console.log("vendorDep", vendorDep);

        setVendorDepartmentList(vendorDep);

        setDepartmentList(temp);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // // POSITON CHANGE FUNCTION
  // const changePosition = () => {
  //   let formdata = new FormData();
  //   formdata.append("vendor_id", positionData?.vendor_id);
  //   formdata.append("vendor_user_id", positionData?.id);
  //   formdata.append("position", positionNumber);
  //   // formdata.append('type', )
  //   vendorPositionApi(formdata)
  //     .unwrap()
  //     .then((res) => {
  //       getDepartments();
  //       setPositionPopup(false);
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  // };
  // POSITON CHANGE FUNCTION
  const changePosition = () => {
    if (positionNumber?.length == 0) {
      setPoistionNumberErr(true);
    } else {
      setPoistionNumberErr(false);
      let formdata = new FormData();
      formdata.append("vendor_id", vendors);
      formdata.append("vendor_user_id", positionData?.id);
      formdata.append("position", positionNumber);
      // formdata.append('type', )
      dispatch(saveLoader(true));
      setPositionBtn(true);
      vendorPositionApi(formdata)
        .unwrap()
        .then((res) => {
          setPoistionNumber(null);
          setPositionBtn(false);
          dispatch(saveLoader(false));
          getViewProfile(vendors);
          getDepartments();
          setPositionPopup(false);
        })
        .catch((err) => {
          setPositionBtn(false);
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    }
  };

  const submitHandleVendorUser = () => {
    if (
      firstName?.length == 0 ||
      lastName?.length == 0 ||
      email?.length == 0 ||
      role?.length == 0 ||
      numbers?.length == 0 ||
      position?.length == 0 ||
      address?.length == 0 ||
      department?.length == 0 ||
      primaryNumbers?.length == 0 ||
      (!vendorDepartment?.vendor_department_id && password?.length < 8)
    ) {
      setFirstNameErr(true);
      setLastNameErr(true);
      setEmailErr(true);
      setRoleErr(true);
      setPositionErr(true);
      setAddressErr(true);
      setNumberErr(true);
      setDepartmentErr(true);
      setPrimaryNumbersErr(true);

      !vendorDepartment?.vendor_department_id && setPasswordErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("first_name", firstName);
      formdata.append("last_name", lastName);
      formdata.append("email", email);
      formdata.append("role_id", role);
      formdata.append("position", position);
      formdata.append("vendor_address_id", address);
      formdata.append("is_marketing", noMarketing);
      formdata.append("vendor_department_id", department);
      formdata.append("password", password);
      formdata.append("password_confirmation", password);
      formdata.append("vendor_id", vendors);
      formdata.append("primary_mobile", primaryNumbers);

      if (twitter) {
        formdata.append("twitter", twitter);
      }

      if (facebook) {
        formdata.append("facebook", facebook);
      }

      if (linkIn) {
        formdata.append("linkedin", linkIn);
      }

      if (skype) {
        formdata.append("skype", skype);
      }

      // let temp = [];

      // let additional_info = {};
      // additional_info.twitter = twitter;
      // additional_info.facebook = facebook;
      // additional_info.linkedin = linkIn;
      // additional_info.skype = skype;

      // temp.push(additional_info);

      // temp.map((item, ind) => {
      //   if (item?.twitter) {
      //     formdata.append(`additional_info[${ind}][twitter]`, item?.twitter);
      //   }

      //   if (item?.facebook) {
      //     formdata.append(`additional_info[${ind}][facebook]`, item?.facebook);
      //   }

      //   if (item?.linkedin) {
      //     formdata.append(`additional_info[${ind}][linkedin]`, item?.linkedin);
      //   }

      //   if (item?.skype) {
      //     formdata.append(`additional_info[${ind}][skype]`, item?.skype);
      //   }
      // });

      let tagArr = [];
      tagArr.push(tags);

      if (tagArr?.length > 0) {
        tagArr?.map((item, ind) => {
          if (item) {
            formdata.append(`tags[${ind}]`, item);
          }
        });
      }

      number?.map((item, ind) => {
        if (item?.number_type && item?.number) {
          formdata.append(`mobiles[${ind}][number_type]`, item?.number_type);
          formdata.append(`mobiles[${ind}][mobile]`, item?.number);
        }
      });
      dispatch(saveLoader(true));
      setAddressBtn(true);
      if (vendorDepartment?.vendor_department_id) {
        formdata.append("vendor_user_id", vendorDepartment?.id);
        vendorUserUpdateApi(formdata)
          .unwrap()
          .then((res) => {
            setNumber([{ number_type: "work", number: "" }]);
            setTwitter("");
            setFacebook("");
            setAddress("");
            setSkype("");
            setLinkIn("");
            setFirstName("");
            setLastName("");
            setEmail("");
            setRole("");
            setPassword("");
            setPosition("");
            setNoMarketing("");
            setVendorDepartment({});
            setDepartment("");
            setTags("");
            getDepartments();
            showPopupHander();
            dispatch(saveLoader(false));
            setAddressBtn(false);
            setContactPopup(false);
          })
          .catch((err) => {
            console.log("err", err);
            dispatch(saveLoader(false));
            setAddressBtn(false);
          });
      } else {
        vendorUserCreateApi(formdata)
          .unwrap()
          .then((res) => {
            setNumber([{ number_type: "work", number: "" }]);
            setTwitter("");
            setFacebook("");
            setAddress("");
            setSkype("");
            setLinkIn("");
            setFirstName("");
            setLastName("");
            setEmail("");
            setRole("");
            setPassword("");
            setPosition("");
            setDepartment("");
            setNoMarketing("");
            setTags("");
            showPopupHander();
            setVendorDepartment({});
            getDepartments();
            dispatch(saveLoader(false));
            setAddressBtn(false);
            setContactPopup(false);
          })
          .catch((err) => {
            console.log("err", err);
            dispatch(saveLoader(false));
            setAddressBtn(false);
          });
      }
    }
  };

  // VENDOR EDIT API
  const submitHandle = () => {
    if (
      name?.length == 0 ||
      Tradename?.length == 0 ||
      vendorCat?.length == 0 ||
      vendorOfferType?.length == 0 ||
      paymentTerms?.length == 0 ||
      paymentMethod?.length == 0 ||
      GstID?.length == 0 ||
      currencyName?.length == 0 ||
      description?.length == 0 ||
      lineBusiness?.length == 0 ||
      tagsProfile?.length == 0 ||
      (offeringTypes == 1 && vendorCatogory?.length == 0) ||
      (offeringTypes == 3 && vendorCatogory?.length == 0)
    ) {
      // setCategoryNameErr(true);
      offeringTypes == 1 && setnameErr(true);
      offeringTypes == 3 && setnameErr(true);

      setTradenameErr(true);
      setVendorCatErr(true);
      setVendorOfferTypeErr(true);
      setPaymentTermsErr(true);
      setPaymentMethodErr(true);
      setGstIDErr(true);
      setCurrencyErr(true);
      setDescriptionErr(true);
      setLineBusinessErr(true);
      setTagsErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("name", Tradename);
      formdata.append("legal_name", name);
      if (lineBusiness?.length > 0) {
        lineBusiness?.map((item, id) => {
          formdata.append(`line_of_business[${id}]`, item);
        });
      }
      formdata.append("address1", address1);
      formdata.append("country", country1);
      formdata.append("city", city1);
      formdata.append("state", state1);
      formdata.append("pincode", pincode1);
      formdata.append("type_of_business", vendorCat);
      // formdata.append("offering_type", vendorCat);
      formdata.append(
        "offering_type",
        vendorOfferType == "Product"
          ? 1
          : vendorOfferType == "Services"
          ? 2
          : vendorOfferType == "Both"
          ? 3
          : 0
      );
      formdata.append("payment_terms", paymentTerms);
      if (paymentMethod?.length > 0) {
        paymentMethod?.map((item, id) => {
          formdata.append(`payment_method[${id}]`, item);
        });
      }
      formdata.append("gst", GstID);
      formdata.append("currency_id", currency);
      // formdata.append("tags", vendorData?.profile?.tags);
      id.map((item, id) => {
        formdata.append(`tags[${id}]`, item);
      });
      formdata.append("description", description);
      if (vendorCategories?.length > 0) {
        vendorCategories?.map((item, ind) => {
          formdata.append(`category_ids[${ind}]`, item);
        });
      }

      if (logo?.name) {
        formdata.append("logo", logo);
      }

      if (banner?.name) {
        formdata.append("banner", banner);
      }

      formdata.append("vendor_id", vendors);

      // vendorEditApi({ id: vendorID, payload: formdata })
      setBtn(true);
      dispatch(saveLoader(true));
      vendorEditApi(formdata)
        .unwrap()
        .then((res) => {
          setBtn(false);
          dispatch(saveLoader(false));
          getViewProfile(vendors);
          // setUpdate(!update);
          setUpdate(false);
          setProfileEditBtn(false);
        })
        .catch((err) => {
          setBtn(false);
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    }
  };

  // VENDOR ADDRESS LIST
  const getAddressList = (id) => {
    addressApi(id)
      .unwrap()
      .then((res) => {
        let temp = res?.vendor_address?.filter((i) => i?.status == 1);
        setVendorAddressList([...res?.vendor_address]?.reverse());
        setVendorAddressLists([...temp]?.reverse());
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  // ADDRESS STATUS
  const addressStatus = (id) => {
    addressStatusApi(id)
      .unwrap()
      .then((res) => {
        getAddressList(vendors);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (vendors) {
      getViewProfile(vendors);
      getAddressList(vendors);
    }

    currencyListApi()
      .unwrap()
      .then((res) => {
        setCurrenciesLists(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });

    if (location?.state?.tab) {
      setTab(location?.state?.tab);
    }

    getDepartments();
  }, []);

  // GET ADDRESS FOR EDIT
  const getVIewAddress = (item) => {
    setUpdateAddress(item);
    let coutryFinder = countriesList.find((i) => i?.name == item?.country);
    let stateFinder = stateList.find((i) => i?.name == item?.state);
    let stateFinders = stateList.filter(
      (i) => i?.country_id == coutryFinder?.id
    );
    let cityFinder = cityList.filter((i) => i?.state_id == stateFinder?.id);
    setCountry(coutryFinder?.name);
    setCountryId(coutryFinder?.id);
    setListStates(stateFinders);
    setState(stateFinder?.name);
    setStateId(stateFinder?.id);
    setListCity(cityFinder);
    setCity(item?.city);
    setAddressName(item?.address);
    setAddressType(item?.type);
    setLandMark(item?.landmark);
    setStreet(item?.street);
    setCompanyName(item?.name);
    setPincode(item?.pincode);
    setAddressPopup(true);
  };

  // POSITION CHANGE
  const getPositionData = (item) => {
    setPositionData(item);
  };

  //DEcorative List
  const getDecorList = () => {
    decorativeListsApi()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          // console.log(res,"res")
          // setList(res?.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    getDecorList();
  }, []);
  // console.log(change, "changex");

  return (
    <>
      {contactPopup && (
        <EditContactPopup
          vendorDepartment={vendorDepartment}
          tagsList={tagsList}
          roleList={roleList}
          setContactPopup={setContactPopup}
          showPopupHander={showPopupHander}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          setEmail={setEmail}
          email={email}
          setPassword={setPassword}
          password={password}
          setPosition={setPosition}
          position={position}
          setRole={setRole}
          role={role}
          setNumber={setNumber}
          number={number}
          setDepartment={setDepartment}
          department={department}
          setTwitter={setTwitter}
          twitter={twitter}
          setFacebook={setFacebook}
          facebook={facebook}
          setLinkIn={setLinkIn}
          linkIn={linkIn}
          setSkype={setSkype}
          skype={skype}
          setAddress={setAddress}
          address={address}
          setNoMarketing={setNoMarketing}
          noMarketing={noMarketing}
          setTags={setTags}
          tags={tags}
          firstNameErr={firstNameErr}
          lastNameErr={lastNameErr}
          emailErr={emailErr}
          passwordErr={passwordErr}
          positionErr={positionErr}
          roleErr={roleErr}
          numberErr={numberErr}
          departmentErr={departmentErr}
          twitterErr={twitterErr}
          facebookErr={facebookErr}
          linkInErr={linkInErr}
          skypeErr={skypeErr}
          addressErr={addressErr}
          noMarketingErr={noMarketingErr}
          tagsErr={tagsErr}
          addressBtn={addressBtn}
          submitHandleVendorUser={submitHandleVendorUser}
          departmentList={departmentList}
          vendorAddressList={vendorAddressLists}
          numbers={numbers}
          setNumbers={setNumbers}
          primaryNumbers={primaryNumbers}
          setPrimaryNumbers={setPrimaryNumbers}
          primaryNumbersErr={primaryNumbersErr}
        />
      )}
      {addressPopup && (
        <EditAddressPopup
          setAddressPopup={setAddressPopup}
          showPopupHander={showPopupHander}
          addressName={addressName}
          setAddressName={setAddressName}
          companyName={companyName}
          setCompanyName={setCompanyName}
          landmark={landmark}
          setLandMark={setLandMark}
          street={street}
          setStreet={setStreet}
          city={city}
          setCity={setCity}
          country={country}
          setCountry={setCountry}
          state={state}
          setState={setState}
          pincode={pincode}
          setPincode={setPincode}
          addressType={addressType}
          setAddressType={setAddressType}
          addressNameErr={addressNameErr}
          companyNameErr={companyNameErr}
          landmarkErr={landmarkErr}
          streetErr={streetErr}
          cityErr={cityErr}
          countryErr={countryErr}
          stateErr={stateErr}
          pincodeErr={pincodeErr}
          addressTypeErr={addressTypeErr}
          btnAdd={btnAdd}
          submitHandleAddress={submitHandleAddress}
          setStateId={setStateId}
          stateId={stateId}
          listCity={listCity}
          setListCity={setListCity}
          listStates={listStates}
          setListStates={setListStates}
          setCountryId={setCountryId}
          countryId={countryId}
        />
      )}
      {positionPopup && (
        <PositionPopup
          showPopupHander={showPopupHander}
          positionNumber={positionNumber}
          setPoistionNumber={setPoistionNumber}
          positionData={positionData}
          setPositionData={setPositionData}
          changePosition={changePosition}
          positionBtn={positionBtn}
          positionNumberErr={positionNumberErr}
        />
      )}
      <div className="dashRightView p-5 home_section trans">
        <ProductSearchHeader banner={banner} />
        <div
          className="w-100  p-1"
          style={{
            backgroundImage: `url(${
              banner?.name
                ? URL.createObjectURL(banner)
                : banner
                ? banner
                : vendorBannerImg
            })`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "70%",
            height: "285px",
          }}
        >
          <div className="d-flex as-jb flex-md-row flex-column ms-xl-5 ms-lg-5 ms-md-5 vendorDetSpace w-xs-100 w-sm-100 w-md-90 w-lg-90 w-xl-90">
            <div className=" d-lg-flex d-xl-flex d-md-flex as-jc">
              <img
                src={
                  logo?.name
                    ? URL.createObjectURL(logo)
                    : logo
                    ? logo
                    : profilePic
                }
                className="profileImg"
              />
              <div className="ms-xl-5 ms-lg-5 ms-md-5">
                <p className="f4 fs-xs-22 fs-sm-23 fs-md-24 fs-lg-25 fs-xl-26 fs-xxl-27">
                  {name ? name : " "}
                </p>
                <div className="d-flex mt-2">
                  <BusinessIcon className="text-light f4 fs-xs-15 fs-sm-17 fs-md-18 fs-lg-19 fs-xl-20 fs-xxl-21" />
                  <p className="text-light ms-2 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    {emailView ? emailView : " "}
                    {/* {phoneView} */}
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex ac-jc position-relative ">
              {/* <button
                onClick={() => setAction(!action)}
                className="cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
              >
                Actions
              </button> */}
              {/* {action && (
                <div
                  onClick={() =>
                    navigate("/add-vendor", { state: { type: "email" } })
                  }
                  className="actionPopup pointerView px-2 py-2 ac-jc rounded-3"
                >
                  <h6 className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14">
                    Email Accounts
                  </h6>
                  <h6 className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14">
                    Edit Profile
                  </h6>
                </div>
              )}
              {action && (
                <div className="actionPopup pointerView px-2 py-2 ac-jc rounded-3">
                  <button
                    onClick={() =>
                      navigate("/add-vendor", { state: { type: "email" } })
                    }
                    className="cust-btn primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14"
                  >
                    Email Accounts
                  </button>
                  <button
                    onClick={() =>
                      navigate("/add-vendor", { state: { type: "email" } })
                    }
                    className="cust-btn primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14">
                    Edit Profile
                  </button>
                </div>
              )} */}
            </div>
          </div>
        </div>
        <div className="w-100 d-flex mt-4">
          <div className="d-flex flex-wrap mt-3 w-xs-100 ac-jc">
            <div
              onClick={() => {
                setTab(0);
                setProfile(true);
                setContact(false);
                setLibrary(false);
                setProducts(false);
                setBills(false);
                setHistory(false);
                setEditProfile(false);
                setCollaborate(false);
                setDecorative(false);
              }}
              className={`${
                tab == 0 || editProfile
                  ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                  : ""
              } pointerView`}
            >
              <p
                className={`${
                  tab == 0 || editProfile ? "black" : "gray"
                } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
              >
                Profile
              </p>
            </div>
            <div
              onClick={() => {
                setTab(1);
                setProfile(false);
                setContact(true);
                setLibrary(false);
                setProducts(false);
                setBills(false);
                setHistory(false);
                setEditProfile(false);
                setCollaborate(false);
                setDecorative(false);
              }}
              className={`${
                tab == 1
                  ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                  : ""
              } ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-2 pointerView`}
            >
              <p
                className={`${
                  tab == 1 ? "black" : "gray"
                } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
              >
                Contact
              </p>
            </div>
            <div
              onClick={() => {
                setTab(2);
                setProfile(false);
                setContact(false);
                setLibrary(true);
                setProducts(false);
                setBills(false);
                setHistory(false);
                setEditProfile(false);
                setCollaborate(false);
                setDecorative(false);
              }}
              className={`${
                tab == 2
                  ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                  : ""
              }  ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-2 pointerView`}
            >
              <p
                className={`${
                  tab == 2 ? "black" : "gray"
                } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
              >
                Library
              </p>
            </div>
            {+offeringTypes == 1 && (
              <div
                onClick={() => {
                  setTab(3);
                }}
                className={`${
                  tab == 3
                    ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                    : ""
                }  ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-2 pointerView`}
              >
                <p
                  className={`${
                    tab == 3 ? "black" : "gray"
                  } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
                >
                  Products
                </p>
              </div>
            )}
            {+offeringTypes == 2 && (
              <div
                onClick={() => {
                  setTab(4);
                }}
                className={`${
                  tab == 4
                    ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                    : ""
                }  ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-2 pointerView`}
              >
                <p
                  className={`${
                    tab == 4 ? "black" : "gray"
                  } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
                >
                  Decoration
                </p>
              </div>
            )}

            {+offeringTypes == 3 && (
              <>
                <div
                  onClick={() => {
                    setTab(3);
                  }}
                  className={`${
                    tab == 3
                      ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                      : ""
                  }  ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-2 pointerView`}
                >
                  <p
                    className={`${
                      tab == 3 ? "black" : "gray"
                    } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
                  >
                    Products
                  </p>
                </div>
                <div
                  onClick={() => {
                    setTab(4);
                  }}
                  className={`${
                    tab == 4
                      ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                      : ""
                  }  ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-2 pointerView`}
                >
                  <p
                    className={`${
                      tab == 4 ? "black" : "gray"
                    } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
                  >
                    Decoration
                  </p>
                </div>
              </>
            )}

            <div
              onClick={() => {
                setTab(5);
                setProfile(false);
                setContact(false);
                setLibrary(false);
                setProducts(false);
                setBills(true);
                setHistory(false);
                setEditProfile(false);
                setCollaborate(false);
                setDecorative(false);
              }}
              className={`${
                tab == 5
                  ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                  : ""
              }  ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-2 pointerView`}
            >
              <p
                className={`${
                  tab == 5 ? "black" : "gray"
                } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
              >
                PO
              </p>
            </div>
            <div
              onClick={() => {
                setTab(6);
                setProfile(false);
                setContact(false);
                setLibrary(false);
                setProducts(false);
                setBills(false);
                setHistory(true);
                setEditProfile(false);
                setCollaborate(false);
                setDecorative(false);
              }}
              className={`${
                tab == 6
                  ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                  : ""
              }  ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-3 pointerView`}
            >
              <p
                className={`${
                  tab == 6 ? "black" : "gray"
                } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
              >
                Meeting History
              </p>
            </div>
            <div
              onClick={() => {
                setTab(7);
                setProfile(false);
                setContact(false);
                setLibrary(false);
                setProducts(false);
                setBills(false);
                setHistory(false);
                setEditProfile(false);
                setCollaborate(true);
                setDecorative(false);
              }}
              className={`${
                tab == 7
                  ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                  : ""
              }  ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-3 pointerView`}
            >
              <p
                className={`${
                  tab == 7 ? "black" : "gray"
                } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
              >
                Collaborate
              </p>
            </div>
          </div>
        </div>
        {tab == 0 && (
          <ProfileDetails
            decorList={list}
            vendors={vendors}
            vendorUpdateId={vendorUpdateId}
            setVendorUpdateId={setVendorUpdateId}
            vendorUpdateIdErr={vendorUpdateIdErr}
            setUpdate={setUpdate}
            update={update}
            vendorCatogory={vendorCatogory}
            setVendorCatogory={setVendorCatogory}
            categoryName={categoryName}
            setCategoryName={setCategoryName}
            categoryNameErr={categoryNameErr}
            categoryList={categoryList}
            setVendorCat={setVendorCat}
            vendorNameErr={vendorNameErr}
            vendorCategories={vendorCategories}
            setVendorCategories={setVendorCategories}
            vendorCat={vendorCat}
            setEditProfile={setEditProfile}
            setProfile={setProfile}
            setTab={setTab}
            setAddressPopup={setAddressPopup}
            setContactPopup={setContactPopup}
            setHistory={setHistory}
            setPositionPopup={setPositionPopup}
            place={place}
            name={name}
            setname={setname}
            nameErr={nameErr}
            Tradename={Tradename}
            setTradename={setTradename}
            TradenameErr={TradenameErr}
            vendorCatErr={vendorCatErr}
            vendorOfferType={vendorOfferType}
            setVendorOfferType={setVendorOfferType}
            vendorOfferTypeErr={vendorOfferTypeErr}
            paymentTerms={paymentTerms}
            setPaymentTerms={setPaymentTerms}
            paymentTermsErr={paymentTermsErr}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            paymentMethodErr={paymentMethodErr}
            GstID={GstID}
            setGstID={setGstID}
            GstIDErr={GstIDErr}
            country={country}
            setCountry={setCountry}
            state={state}
            setState={setState}
            countryErr={countryErr}
            stateErr={stateErr}
            city={city}
            setCity={setCity}
            cityErr={cityErr}
            pincode={pincode}
            setPincode={setPincode}
            pincodeErr={pincodeErr}
            currency={currency}
            setCurrency={setCurrency}
            setCurrencyName={setCurrencyName}
            currencyName={currencyName}
            currencyErr={currencyErr}
            descriptionErr={descriptionErr}
            description={description}
            setDescription={setDescription}
            logo={logo}
            logoErr={logoErr}
            banner={banner}
            bannerErr={bannerErr}
            setBanner={setBanner}
            setLogo={setLogo}
            lineBusiness={lineBusiness}
            setLineBusiness={setLineBusiness}
            lineBusinessErr={lineBusinessErr}
            tagsProfile={tagsProfile}
            tagsList={tagsList}
            setTagsList={setTagsList}
            setTagsProfile={setTagsProfile}
            tagsErr={tagsErr}
            setTagsErr={setTagsErr}
            id={id}
            setid={setid}
            submitHandle={submitHandle}
            btn={btn}
            vendorDepartmentList={vendorDepartmentList}
            getVendorView={getVendorView}
            vendorAddressList={vendorAddressList}
            getVIewAddress={getVIewAddress}
            addressStatus={addressStatus}
            change={change}
            getPositionData={getPositionData}
            currenciesLists={currenciesLists}
            showPopupHander={showPopupHander}
            profileEditBtn={profileEditBtn}
            setProfileEditBtn={setProfileEditBtn}
            getViewProfile={getViewProfile}
          />
        )}
        {editProfile && (
          <EditProfileDetails
            setContactPopup={setContactPopup}
            setAddressPopup={setAddressPopup}
            setPositionPopup={setPositionPopup}
            setEditProfile={setEditProfile}
            setProfile={setProfile}
            setHistory={setHistory}
            place={place}
          />
        )}
        {tab == 6 && <HistoryDetails />}
        {tab == 1 && (
          <ContactDetails
            setContactPopup={setContactPopup}
            setAddressPopup={setAddressPopup}
            setPositionPopup={setPositionPopup}
            vendorDepartmentList={vendorDepartmentList}
            getVendorView={getVendorView}
            change={change}
            getPositionData={getPositionData}
            vendorData={vendorData}
            vendorAddressList={vendorAddressList}
            addressStatus={addressStatus}
            getVIewAddress={getVIewAddress}
          />
        )}
        {tab == 2 && <LibraryDetails vendorID={vendors} />}
        {tab == 3 && (
          <ProductDetails
            products={products}
            place={place}
            path={path}
            vendors={vendors}
            categoryList={categoryList}
          />
        )}
        {tab == 4 && (
          <DecorativeDetails
            place={place}
            place2={place2}
            decorative2={decorative}
            setDecorative={setDecorative}
            name={name}
          />
        )}
        {/* {bills && <POSandBills />} */}
        {tab == 5 && <ProductVendorPoScreen />}
        {tab == 7 && <CollaborateList />}
        <div className="w-80 mt-1 d-flex flex-md-row flex-column ac-jb">
          <button
            onClick={() => {
              if (place === "John Rolph") {
                navigate("/vendor-home", { state: { type: "John Rolph" } });
              } else if (place === "Charles") {
                navigate("/vendor-home", { state: { type: "Charles" } });
              } else {
                navigate("/vendor-home", { state: { type: "Jake Adams" } });
              }
            }}
            className="cust-btn addbtn mt-3 ms-2 bg-primar text-white f3 fs-10 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Back
          </button>
        </div>
      </div>
    </>
  );
};

export default ProductVendorDetailsPage;
