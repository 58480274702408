// @ts-nocheck
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ImageCroper from "../../../components/Popup/ImageCroper";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ErrorIcon from "@mui/icons-material/Error";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../../redux/slice/loaderNoti";
import { useBannerEditMutation, useBannerStoreMutation, useBannerViewQuery, useLazyBannerViewQuery } from "../../../redux/api/api";
import { noLogo } from "../../../assets/img";
import JoditEditor from 'jodit-react';
import DOMPurify from "dompurify";

const BannerEdit = ({ placeholder }) => {

  const editor = useRef(null);
  const [content, setContent] = useState('');
  const config = useMemo(() =>
  ({
    readonly: false,
    placeholder: placeholder || 'Start typings...'
  }),
    [placeholder]
  );
  console.log(content, "text");

  const location = useLocation();
  const [data, setData] = useState("");
  const [action, setAction] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [croper, setCroper] = useState(false);
  const type = location?.state?.type;
  console.log(location?.state?.type);

  const [image, setImage] = useState('');
  const dispatch = useDispatch();

  const navigate = useNavigate();
  // useEffect(() => {
  //   setData(location?.state?.data);
  // }, [location]);

  // console.log(data);

  // const toggleImagePopup = () => {
  //   setCroper(!croper);
  // };


  //RTK QUERY
  const [addBannerApi] = useBannerStoreMutation();
  const [editBannerApi] = useBannerEditMutation();
  const [viewBannerApi] = useLazyBannerViewQuery();

  const [full_name, setName] = useState("");
  const [full_nameErr, setFull_nameErr] = useState(false);

  const [description, setDescription] = useState("");

  const [desc_Err, setDesc_Err] = useState(false);
  const [client_Err, setClient_Err] = useState(false);

  const [client_img, setClient_Img] = useState("");
  const [priority, setPriority] = useState("");
  const [pririty_Err, setPrio_Err] = useState(false);
  const [btn, setBtn] = useState(false);
  const [button_name, setButtonName] = useState("");
  const [btn_Err, setBtn_Err] = useState("");
  const [button_link, setButtonLink] = useState("");
  const [blink_Err, setBlink_Err] = useState("");
  const [role, setRole] = useState("");

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    setData(location?.state?.data);
  }, [location]);

  const toggleImagePopup = (type, method) => {
    setCroper(!croper);
    if (method == 'open') {
      setImage(type)
    }
  };

  useEffect(() => {

    if (croperImage) {
      fetch(croperImage)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "File name", { type: "image/png" });
          setClient_Img(file);
        });
    }

  }, [croperImage])




  useEffect(() => {
    setData(location?.state?.data);
  }, [location]);

  const getView = () => {
    viewBannerApi(location?.state?.data?.id)
      .unwrap()
      .then((res) => {
        console.log('res', res);
        let data = res?.setting_detail;
        //json decode
        let json = JSON.parse(data?.contents)
        console.log(json);

        setName(json?.name);
        setDescription(json?.description);
        setClient_Img(data?.image_url);
        setPriority(json?.priority);
        setButtonName(json?.button_name);
        setButtonLink(json?.link);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (location?.state?.data?.id) {
      getView();
    }
  }, []);


  const submitHandle = () => {
    if (
      full_name?.length == 0 ||
      description?.length == 0 ||
      client_img?.length == 0 ||
      priority?.length == 0 ||
      button_name?.length == 0 ||
      button_link?.length == 0


      // ||
      // status?.length == 0
    ) {
      setFull_nameErr(true);
      setDesc_Err(true);
      setClient_Err(true);
      setPrio_Err(true);
      setBtn_Err(true);
      setBlink_Err(true);


      // setStatusErr(true);
    } else {
      console.log("done");
      let formdata = new FormData();
      formdata.append("name", full_name);
      formdata.append("description", description);
      formdata.append("button_name", button_name);
      formdata.append("link", button_link);


      formdata.append("priority", priority);
      formdata.append("type", 1);
      formdata.append("status", role);

      if (client_img?.name) {
        formdata.append("image", client_img);
      }



      setBtn(true);
      dispatch(saveLoader(true));
      if (location?.state?.data?.id) {
        formdata.append("setting_id", location?.state?.data?.id);

        editBannerApi(formdata)
          .unwrap()
          .then((res) => {
            setBtn(false);
            dispatch(saveLoader(false));
            navigate("/banner-management");
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });
      } else {
        addBannerApi(formdata)
          .unwrap()
          .then((res) => {
            setBtn(false);
            dispatch(saveLoader(false));
            navigate("/banner-management");
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });
      }
    }
  };


  return (
    <div className="dashRightView p-5 home_section trans">
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <p
        className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-0 mb-3 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon /> Banner
        {location?.state?.type == "view"
          ? " View"
          : location?.state?.type == "edit"
            ? " Edit"
            : " Add"}{" "}
      </p>
      <div className="w-100 searchField mt-0 bg-white ">
        <div className="w-100 d-flex flex-xxl-row flex-xl-row flex-lg-row flex-column">
          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Name:{type == "add" && type !== "view" && <span>*</span>}
              </p>
              <div className="w-50">
                <input
                  className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  type="text"
                  maxLength={50}
                  onChange={(e) => setName(e.target.value)}
                  value={full_name}
                  disabled={type == "view" ? true : false}
                />

                {full_name?.length == 0 && full_nameErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Full Name</p>
                  </div>
                )}
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Banner Image:<span style={{ color: "#f82a2a" }}>(420px*300px)</span>{type == "add" && type !== "view" && <span>*</span>}
              </p>
              {/* <input
                className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                type="file"
              /> */}
              <div className="w-50 " >
                <div className="w-50" >
                  {type !== "view" && <input type="file" className="mb-2" onChange={(e) => setClient_Img(e.target.files[0])} />}
                  <img
                    src={client_img?.name ? URL.createObjectURL(client_img) : client_img ? client_img : noLogo}
                    className="imgDashboard " placeholder="Browse or Drag a file"
                    disabled={type == "view" ? true : false}

                  />
                  {client_img?.length == 0 && client_Err && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Upload Image</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Description:<span style={{ color: "#f82a2a" }}>(150words only)</span>{type == "add" && type !== "view" && <span>*</span>}
              </p>

              <div className="w-50">
                {type != "view" ? (
                  <JoditEditor
                    className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                    ref={editor}
                    value={description}
                    config={config}
                    maxLength={150}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => setDescription(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={newContent => { setDescription(newContent) }}
                  />
                ) : (<p className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  type="text"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      // list?.description
                      description
                    ),
                  }}
                // value={description}
                />)}
                {/* <textarea
                  className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  type="text"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description?.substring(0,150)} maxLength={150}
                disabled={type == "view" ? true : false} */}

                {description?.length == 0 && desc_Err && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Description</p>
                  </div>
                )}
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Button Name:{type == "add" && type !== "view" && <span>*</span>}
              </p>
              <div className="w-50">
                <input
                  className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  type="text"
                  maxLength={50}
                  onChange={(e) => setButtonName(e.target.value)}
                  value={button_name}
                  disabled={type == "view" ? true : false}
                />
                {button_name?.length == 0 && btn_Err && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Button Name</p>
                  </div>
                )}
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Button Link:{type == "add" && type !== "view" && <span>*</span>}
              </p>

              <div className="w-50">
                <input
                  className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  type="text"
                  maxLength={50}
                  onChange={(e) => setButtonLink(e.target.value)}
                  value={button_link}
                  disabled={type == "view" ? true : false}
                />
                {button_link?.length == 0 && blink_Err && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Button link</p>
                  </div>
                )}
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Priority Status:
              </p>

              <div className="w-50">
                <input
                  className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  type="number"
                  onChange={(e) => setPriority(e.target.value)}
                  value={priority}
                  disabled={type == "view" ? true : false}
                />
                {priority?.length == 0 && pririty_Err && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Priority Status</p>
                  </div>
                )}
              </div>
            </div>

            {/* <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Status {type == "add" && type !== "view" && <span>*</span>}
              </p>

              <select
                placeholder=""
                className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                name=""
                id=""
                onChange={(e)=> setRole(e.target.value)}
                value={role}
                disabled={type =="view" ? true : false}
              >
                <option value={1}>Active</option>
                <option value={0}>De Active</option>        
              </select>
      
            </div> */}
          </div>
        </div>
      </div>
      <div className="d-flex ac-jb">
        <button
          onClick={() => navigate(-1)}
          className="pointerView cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
        >
          Back
        </button>
        {type !== "view" && (
          <button
            className={`cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${btn && "opacity-50"
              }`}
            onClick={submitHandle}
            disabled={btn ? true : false}
          >
            submit
          </button>
        )}
      </div>
    </div>
  );
};

export default BannerEdit;
