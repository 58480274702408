import React, { useEffect, useState } from "react";
import { product } from "../../assets/img";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ErrorIcon from "@mui/icons-material/Error";
import {
  useAdminAddProPriceMutation,
  useAssignVendorProPricePriorityMutation,
} from "../../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";
const LibraryVendorPricingComp = ({
  vendorPriceList,
  viewData,
  getVendorPrice,
  vendorPriceLists,
}) => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [lists, setLists] = useState([]);
  const [vendorData, setVendorData] = useState({});
  const [customer, setCustomer] = useState(false);
  const [reseller, setReseller] = useState(false);
  const [checkBox, setCheckBox] = useState(null);
  const [btn, setBtn] = useState(false);
  const [btns, setBtns] = useState(false);

  const [marginShow, SetMarginShow] = useState(false);
  const [marginShow2, SetMarginShow2] = useState(false);
  const [marginBox, setMarginBox] = useState(false);

  const [priorityErr, setPriorityErr] = useState(false);
  const [priorityErrs, setPriorityErrs] = useState(false);

  const [mspPrice, setMspPrice] = useState({
    for_25: "",
    for_50: "",
    for_100: "",
    for_150: "",
    for_250: "",
    for_500: "",
    for_1000: "",
  });
  const [msp, setMsp] = useState("");
  const [scpPrice, setScpPrice] = useState({
    for_25: "",
    for_50: "",
    for_100: "",
    for_150: "",
    for_250: "",
    for_500: "",
    for_1000: "",
  });
  const [scp, setScp] = useState("");
  const [scpErr, setScpErr] = useState(false);
  const [scpErrs, setScpErrs] = useState(false);

  const [cost, setCost] = useState({
    for_25: "",
    for_50: "",
    for_100: "",
    for_150: "",
    for_250: "",
    for_500: "",
    for_1000: "",
  });

  const [net, setNet] = useState({
    for_25: "",
    for_50: "",
    for_100: "",
    for_150: "",
    for_250: "",
    for_500: "",
    for_1000: "",
  });

  const [margin, setMargin] = useState({
    for_25: "",
    for_50: "",
    for_100: "",
    for_150: "",
    for_250: "",
    for_500: "",
    for_1000: "",
  });

  const [distributorPrice, setDistributorPrice] = useState({
    for_25: "",
    for_50: "",
    for_100: "",
    for_150: "",
    for_250: "",
    for_500: "",
    for_1000: "",
  });

  const [priceCharge, setPriceCharge] = useState([]);
  const [count, setCount] = useState(1);

  const [box1, setbox1] = useState(25);
  const [box2, setbox2] = useState(50);
  const [box3, setbox3] = useState(100);
  const [box4, setbox4] = useState(150);
  const [box5, setbox5] = useState(250);
  const [box6, setbox6] = useState(500);
  const [box7, setbox7] = useState(1000);

  // RTK QUERY
  const [adminAddProPriceApi] = useAdminAddProPriceMutation();
  const [assignPriorityApi] = useAssignVendorProPricePriorityMutation();

  // ADD CHANGES
  const addpriceCharge = () => {
    let temp = [...priceCharge];

    temp.push({
      name: "",
      for_25: "",
      for_50: "",
      for_100: "",
      for_150: "",
      for_250: "",
      for_500: "",
      for_1000: "",
    });
    setPriceCharge(temp);
  };

  // REMOVE CHANGES
  const removepriceCharge = (e, ind) => {
    let temp = [...priceCharge];
    temp.splice(ind, 1);
    setPriceCharge(temp);
  };

  // CHARGES VALUE CHANGER
  const handlepriceCharge = (e, index, type) => {
    let temp = [...priceCharge];
    const name = e.target.name;
    const value = e.target.value;

    if (type == "name") {
      let obj = {
        ...temp[index],
        [type]: value,
      };

      temp[index] = obj;
      setPriceCharge(temp);
    } else {
      let obj = {
        ...temp[index],
        [type]: value,
      };

      temp[index] = obj;
      setPriceCharge(temp);
    }
  };

  // COST VALUE CHANGER
  const handleCost = (event, type) => {
    setCost({ ...cost, [type]: event });
  };

  // MARGIN VALUE AND DISTRIBUTOR PRICE CHANGER
  const handleMargin = (event, type) => {
    // let percen = net[type];
    // let cal = (+percen / 100) * +event;
    // let sum_value = +cal + +percen;
    setMargin({ ...margin, [type]: event });
    // setDistributorPrice({ ...net, [type]: sum_value });
  };

  const handleCloseMargin = () => {
    setDistributorPrice(net);
    setMargin({
      for_25: "",
      for_50: "",
      for_100: "",
      for_150: "",
      for_250: "",
      for_500: "",
      for_1000: "",
    });
    setMarginBox(!marginBox);
  };

  const handleMsp = (event, mcpPriceObj) => {
    let obj = {
      for_25: distributorPrice?.for_25,
      for_50: distributorPrice?.for_50,
      for_100: distributorPrice?.for_100,
      for_150: distributorPrice?.for_150,
      for_250: distributorPrice?.for_250,
      for_500: distributorPrice?.for_500,
      for_1000: distributorPrice?.for_1000,
    };

    if (event) {
      if (mcpPriceObj?.for_25) {
        setMspPrice(mcpPriceObj);
      } else {
        let prices = Object.keys(obj);
        for (let i = 0; i < prices?.length; i++) {
          let percen = (+event / 100) * +obj[prices[i]];
          let sum = Number(+percen + +obj[prices[i]]).toFixed();
          setMspPrice((val) => ({ ...val, [prices[i]]: +sum }));
        }
        handleScp("");
      }
    } else {
      setMspPrice({
        for_25: "",
        for_50: "",
        for_100: "",
        for_150: "",
        for_250: "",
        for_500: "",
        for_1000: "",
      });
      handleScp("");
    }

    setMsp(event);
  };

  const handleScp = (event, scpPriceObj) => {
    if (event) {
      if (scpPriceObj?.for_25) {
        setScpPrice(scpPriceObj);
      } else {
        let prices = Object.keys(distributorPrice);
        for (let i = 0; i < prices?.length; i++) {
          let percen = (+event / 100) * +distributorPrice[prices[i]];
          let sum = Number(+percen + +distributorPrice[prices[i]]).toFixed();
          setScpPrice((val) => ({ ...val, [prices[i]]: +sum }));
        }
        // let prices = Object.keys(mspPrice);
        // for (let i = 0; i < prices?.length; i++) {
        //   let percen = (+event / 100) * +mspPrice[prices[i]];
        //   let sum = Number(+percen + +mspPrice[prices[i]]).toFixed();
        //   setScpPrice((val) => ({ ...val, [prices[i]]: +sum }));
        // }
      }

      setScpErrs(true);
    } else {
      setScpPrice({
        for_25: "",
        for_50: "",
        for_100: "",
        for_150: "",
        for_250: "",
        for_500: "",
        for_1000: "",
      });
    }

    setScp(event);
  };

  const handleScpPrice = (event, type) => {
    setScpPrice((val) => ({ ...val, [type]: +event }));
  };

  useEffect(() => {
    if (vendorPriceList?.length > 0) {
      let temp = [];
      let count = [];
      for (let i = 0; i < vendorPriceList?.length; i++) {
        count.push(i + 1);
      }
      vendorPriceList?.map((item, ind) => {
        temp.push({
          ...item,
          count: count,
        });
      });
      setList(temp);
      setLists(temp);
    }
    if (vendorPriceLists?.id) {
      let costPrice = vendorPriceLists?.productBasePrice;
      setCost({
        for_25: costPrice?.for_25,
        for_50: costPrice?.for_50,
        for_100: costPrice?.for_100,
        for_150: costPrice?.for_150,
        for_250: costPrice?.for_250,
        for_500: costPrice?.for_500,
        for_1000: costPrice?.for_1000,
      });

      setMargin({
        for_25: vendorPriceLists?.productMarginPrice?.for_25,
        for_50: vendorPriceLists?.productMarginPrice?.for_50,
        for_100: vendorPriceLists?.productMarginPrice?.for_100,
        for_150: vendorPriceLists?.productMarginPrice?.for_150,
        for_250: vendorPriceLists?.productMarginPrice?.for_250,
        for_500: vendorPriceLists?.productMarginPrice?.for_500,
        for_1000: vendorPriceLists?.productMarginPrice?.for_1000,
      });

      setPriceCharge(vendorPriceLists?.productAdditionalPrice);

      let mcpPriceObj = {
        for_25: vendorPriceLists?.productMspPrice?.for_25,
        for_50: vendorPriceLists?.productMspPrice?.for_50,
        for_100: vendorPriceLists?.productMspPrice?.for_100,
        for_150: vendorPriceLists?.productMspPrice?.for_150,
        for_250: vendorPriceLists?.productMspPrice?.for_250,
        for_500: vendorPriceLists?.productMspPrice?.for_500,
        for_1000: vendorPriceLists?.productMspPrice?.for_1000,
      };

      let scpPriceObj = {
        for_25: vendorPriceLists?.for_25,
        for_50: vendorPriceLists?.for_50,
        for_100: vendorPriceLists?.for_100,
        for_150: vendorPriceLists?.for_150,
        for_250: vendorPriceLists?.for_250,
        for_500: vendorPriceLists?.for_500,
        for_1000: vendorPriceLists?.for_1000,
      };

      setCheckBox(vendorPriceLists?.vendor_product_price_id);
      handleMsp(vendorPriceLists?.msp_percentage, mcpPriceObj);
      handleScp(vendorPriceLists?.scp_percentage, scpPriceObj);
    }
  }, [vendorPriceList, vendorPriceLists]);

  // FOR CALCULATION
  useEffect(() => {
    if (priceCharge?.length > 0) {
      const result = priceCharge?.reduce((accum, current) => {
        Object.entries(current).forEach(([key, value]) => {
          accum[key] = +accum[key] + +value || +value;
        });
        return {
          ...accum,
        };
      }, {});

      delete result?.name;
      const net_value = Object.fromEntries(
        Object.keys(cost).map((k) => [k, +cost[k] + +result[k]])
      );

      setNet(net_value);

      const distributorPrice = Object.fromEntries(
        Object.keys(net_value).map((k) => [
          k,
          Number((+net_value[k] / 100) * +margin[k] + +net_value[k]).toFixed(),
        ])
      );
      setDistributorPrice(distributorPrice);
      if (count == 2) {
        handleMsp("");
        handleScp("");
      }
    } else if (marginBox == false) {
      const distributorPrice = Object.fromEntries(
        Object.keys(cost).map((k) => [
          k,
          Number((+cost[k] / 100) * +margin[k] + +cost[k]).toFixed(),
        ])
      );
      setNet(cost);
      setDistributorPrice(distributorPrice);
      if (count == 2) {
        handleMsp("");
        handleScp("");
      }
    } else {
      setNet(cost);
      setDistributorPrice(cost);
      if (count == 2) {
        handleMsp("");
        handleScp("");
      }
    }
  }, [cost, priceCharge, margin]);

  const getDistributorPrice = (id) => {
    if (id == checkBox) {
      setCheckBox(null);
      setCost({
        for_25: "",
        for_50: "",
        for_100: "",
        for_150: "",
        for_250: "",
        for_500: "",
        for_1000: "",
      });
      setVendorData({});
      SetMarginShow(false);
      setMargin({
        for_25: "",
        for_50: "",
        for_100: "",
        for_150: "",
        for_250: "",
        for_500: "",
        for_1000: "",
      });
      setPriceCharge([]);
    } else {
      setCheckBox(id);
      let finder = vendorPriceList?.find((i) => i?.id == id);
      setCost({
        for_25: Math.round(finder?.for_25),
        for_50: Math.round(finder?.for_50),
        for_100: Math.round(finder?.for_100),
        for_150: Math.round(finder?.for_150),
        for_250: Math.round(finder?.for_250),
        for_500: Math.round(finder?.for_500),
        for_1000: Math.round(finder?.for_1000),
      });
      setVendorData(finder);
      setMargin({
        for_25: "",
        for_50: "",
        for_100: "",
        for_150: "",
        for_250: "",
        for_500: "",
        for_1000: "",
      });

      setPriceCharge([]);
    }
  };

  const submitHandle = () => {
    let formdata = new FormData();
    formdata.append("product_id", viewData?.id);

    // BASIC PRICE
    formdata.append(`basic_prices[for_25]`, cost?.for_25);
    formdata.append(`basic_prices[for_50]`, cost?.for_50);
    formdata.append(`basic_prices[for_100]`, cost?.for_100);
    formdata.append(`basic_prices[for_150]`, cost?.for_150);
    formdata.append(`basic_prices[for_250]`, cost?.for_250);
    formdata.append(`basic_prices[for_500]`, cost?.for_500);
    formdata.append(`basic_prices[for_1000]`, cost?.for_1000);

    // NET PRICE
    formdata.append(`net_prices[for_25]`, net?.for_25);
    formdata.append(`net_prices[for_50]`, net?.for_50);
    formdata.append(`net_prices[for_100]`, net?.for_100);
    formdata.append(`net_prices[for_150]`, net?.for_150);
    formdata.append(`net_prices[for_250]`, net?.for_250);
    formdata.append(`net_prices[for_500]`, net?.for_500);
    formdata.append(`net_prices[for_1000]`, net?.for_1000);

    // MARGIN PRICE
    formdata.append(
      `margin_prices[for_25]`,
      margin?.for_25 ? margin?.for_25 : ""
    );
    formdata.append(
      `margin_prices[for_50]`,
      margin?.for_50 ? margin?.for_50 : ""
    );
    formdata.append(
      `margin_prices[for_100]`,
      margin?.for_100 ? margin?.for_100 : ""
    );
    formdata.append(
      `margin_prices[for_150]`,
      margin?.for_150 ? margin?.for_150 : ""
    );
    formdata.append(
      `margin_prices[for_250]`,
      margin?.for_250 ? margin?.for_250 : ""
    );
    formdata.append(
      `margin_prices[for_500]`,
      margin?.for_500 ? margin?.for_500 : ""
    );
    formdata.append(
      `margin_prices[for_1000]`,
      margin?.for_1000 ? margin?.for_1000 : ""
    );

    // DISTRIBUTOR PRICE
    formdata.append(`distributor_prices[for_25]`, distributorPrice?.for_25);
    formdata.append(`distributor_prices[for_50]`, distributorPrice?.for_50);
    formdata.append(`distributor_prices[for_100]`, distributorPrice?.for_100);
    formdata.append(`distributor_prices[for_150]`, distributorPrice?.for_150);
    formdata.append(`distributor_prices[for_250]`, distributorPrice?.for_250);
    formdata.append(`distributor_prices[for_500]`, distributorPrice?.for_500);
    formdata.append(`distributor_prices[for_1000]`, distributorPrice?.for_1000);

    // MSP PRICE
    formdata.append(`msp_prices[for_25]`, mspPrice?.for_25);
    formdata.append(`msp_prices[for_50]`, mspPrice?.for_50);
    formdata.append(`msp_prices[for_100]`, mspPrice?.for_100);
    formdata.append(`msp_prices[for_150]`, mspPrice?.for_150);
    formdata.append(`msp_prices[for_250]`, mspPrice?.for_250);
    formdata.append(`msp_prices[for_500]`, mspPrice?.for_500);
    formdata.append(`msp_prices[for_1000]`, mspPrice?.for_1000);

    // SCP PRICE
    formdata.append(`product_prices[for_25]`, scpPrice?.for_25);
    formdata.append(`product_prices[for_50]`, scpPrice?.for_50);
    formdata.append(`product_prices[for_100]`, scpPrice?.for_100);
    formdata.append(`product_prices[for_150]`, scpPrice?.for_150);
    formdata.append(`product_prices[for_250]`, scpPrice?.for_250);
    formdata.append(`product_prices[for_500]`, scpPrice?.for_500);
    formdata.append(`product_prices[for_1000]`, scpPrice?.for_1000);

    formdata.append(`vendor_product_price_id`, checkBox);

    // PRICE CHARGE PRICE
    if (priceCharge?.length > 0) {
      priceCharge?.map((item, ind) => {
        formdata.append(
          `additional_prices[${ind}][name]`,
          item?.name ? item?.name : ""
        );
        formdata.append(
          `additional_prices[${ind}][for_25]`,
          item?.for_25 ? item?.for_25 : ""
        );
        formdata.append(
          `additional_prices[${ind}][for_50]`,
          item?.for_50 ? item?.for_50 : ""
        );
        formdata.append(
          `additional_prices[${ind}][for_100]`,
          item?.for_100 ? item?.for_100 : ""
        );
        formdata.append(
          `additional_prices[${ind}][for_150]`,
          item?.for_150 ? item?.for_150 : ""
        );
        formdata.append(
          `additional_prices[${ind}][for_250]`,
          item?.for_250 ? item?.for_250 : ""
        );
        formdata.append(
          `additional_prices[${ind}][for_500]`,
          item?.for_500 ? item?.for_500 : ""
        );
        formdata.append(
          `additional_prices[${ind}][for_1000]`,
          item?.for_1000 ? item?.for_1000 : ""
        );
      });
    }
    if (msp) {
      formdata.append("msp_percentage", msp);
    }

    if (scp) {
      formdata.append("scp_percentage", scp);
    }

    let counter = 1;

    if (scp?.length > 0 && +scp < +msp && !scpErr) {
      counter = counter + 1;
    }

    if (scpErr) {
      counter = counter + 1;
    }

    if (counter == 1) {
      setBtns(true);
      dispatch(saveLoader(true));
      adminAddProPriceApi(formdata)
        .unwrap()
        .then((res) => {
          setCount(1);
          setCheckBox(null);
          setCost({
            for_25: "",
            for_50: "",
            for_100: "",
            for_150: "",
            for_250: "",
            for_500: "",
            for_1000: "",
          });
          setVendorData({});
          SetMarginShow(false);
          setMargin({
            for_25: "",
            for_50: "",
            for_100: "",
            for_150: "",
            for_250: "",
            for_500: "",
            for_1000: "",
          });

          setPriceCharge([]);
          setBtn(false);
          setBtns(false);
          dispatch(saveLoader(false));
          getVendorPrice();
        })
        .catch((err) => {
          setBtns(false);
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    }
  };

  const submitAssignPriority = () => {
    let formdata = new FormData();

    let temp = [];

    let check = 1;

    lists?.map((item, ind) => {
      if (item?.priority) {
        temp.push(item);
      } else {
        check = check + 1;
        setPriorityErr(true);
      }
    });

    temp?.map((item, ind) => {
      formdata.append(`vendor_prices[${ind}][vendor_price_id]`, item?.id);
      formdata.append(`vendor_prices[${ind}][priority]`, item?.priority);
    });

    if (check == 1 && !priorityErrs) {
      setBtn(true);
      dispatch(saveLoader(true));
      assignPriorityApi(formdata)
        .unwrap()
        .then((res) => {
          setBtn(false);
          dispatch(saveLoader(false));
          getVendorPrice();
          toast.success(res?.message);
        })
        .catch((err) => {
          console.log("err", err);
          setBtn(false);
          dispatch(saveLoader(false));
        });
    }
  };

  const handlePriority = (event, ind) => {
    let temp = [...lists];
    temp[ind] = {
      ...temp[ind],
      priority: event,
    };

    setLists(temp);
  };

  useEffect(() => {
    if (lists?.length > 1) {
      const lookup = Object.groupBy(lists, (e) => e.priority);
      const duplicate = lists.filter((e) => lookup[e.priority].length > 1);
      lists?.map((item, ind) => {
        if (!item?.priority) {
          setPriorityErr(true);
        } else {
          setPriorityErr(false);
        }
      });
      if (duplicate?.length > 0) {
        setPriorityErrs(true);
      } else {
        setPriorityErrs(false);
      }
    }
  }, [lists]);

  useEffect(() => {
    if (scpErrs) {
      let count = 1;
      Object.keys(mspPrice).map((key) => {
        if (scpPrice[key] < mspPrice[key]) {
          count = count + 1;
        }
      });

      if (count == 1) {
        setScpErr(false);
      } else {
        setScpErr(true);
      }
    }
  }, [scpPrice, mspPrice]);

  return (
    <div className="h-auto mt-4">
      <p class="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
        Supplier Priority List
      </p>
      <div style={{ width: "250px" }} className="my-3 f2 fs-14 ">
        {lists?.map((item, ind) => {
          return (
            <div
              className="d-flex my-3 align-items-center justify-content-around"
              key={ind}
            >
              <p className="primary1 cp text-decoration-underline">
                {item?.vendor?.code}
              </p>
              <label className="">{item?.vendor?.name}</label>
              <select
                className="cust-btn primary p-1 mx-2"
                style={{ border: "1px solid #07679c", borderRadius: "10px" }}
                value={item?.priority}
                onChange={(e) => handlePriority(e.target.value, ind)}
              >
                <option value={""}>Select</option>
                {item?.count?.map((num, inx) => {
                  return <option value={num}>{num}</option>;
                })}
              </select>
            </div>
          );
        })}
        {priorityErr && (
          <div className="d-flex gap-1 mt-2">
            <ErrorIcon className="svg_log" />
            <p className="err-input_log mt-1">Select Priorities</p>
          </div>
        )}
        {priorityErrs && (
          <div className="d-flex gap-1 mt-2">
            <ErrorIcon className="svg_log" />
            <p className="err-input_log mt-1">
              Same Priority Given, Change Priority
            </p>
          </div>
        )}
      </div>
      <div className="btn-w-cust-cont mt-4 mb-4 w-100">
        {lists?.length > 0 && (
          <button
            class={`btn-w-cust cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
              btn && "opacity-50"
            }`}
            onClick={() => submitAssignPriority()}
            disabled={btn}
          >
            Submit Priority
          </button>
        )}
      </div>

      <p class="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
        Vendor Pricing
      </p>
      {list?.map((item, ind) => {
        return (
          <div key={ind}>
            <div className=" mt-3">
              <div className="d-flex ac-js gap-2">
                <p className="primary1 cp text-decoration-underline">
                  {item?.vendor?.code}
                </p>
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  {item?.vendor?.name}
                  <input
                    type={"checkbox"}
                    className="mx-2"
                    checked={checkBox == item?.id}
                    onClick={() => getDistributorPrice(item?.id)}
                  />
                </p>
              </div>

              <div className="overflow-scroll">
                <table className="w-100">
                  <tr className="mt-4">
                    <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                      Qty
                    </th>
                    <div className="my-3">
                      <td>
                        <div className="d-flex w-100">
                          <div className="mx-2">
                            <input
                              class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              type="button"
                              value="25"
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              type="button"
                              value="50"
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              type="button"
                              value="100"
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              type="button"
                              value="150"
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              type="button"
                              value="250"
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              type="button"
                              value="500"
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              type="button"
                              value="1000"
                            />
                          </div>
                        </div>
                      </td>
                    </div>
                  </tr>
                  <tr>
                    <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                      Distributor Price
                    </th>
                    <div className="mt-2">
                      <td>
                        <div className="d-flex w-100">
                          <div className="mx-2">
                            <input
                              class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              type="number"
                              placeholder="₹25.00"
                              value={item?.for_25}
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              placeholder="₹50.00"
                              type="number"
                              value={item?.for_50}
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              placeholder="₹100.00"
                              type="number"
                              value={item?.for_100}
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              placeholder="₹150.00"
                              type="number"
                              value={item?.for_150}
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              placeholder="₹250.00"
                              type="number"
                              value={item?.for_250}
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              placeholder="₹500.00"
                              type="number"
                              value={item?.for_500}
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              placeholder="₹1000.00"
                              type="number"
                              value={item?.for_1000}
                            />
                          </div>
                        </div>
                      </td>
                    </div>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        );
      })}

      {/* <div className="mt-3">
        <div className="d-flex ac-js gap-2">
          <p className="primary1 cp text-decoration-underline">VMHQ001</p>
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
            Charles Supplier <input type={"checkbox"} className="mx-2" />
          </p>
        </div>

        <div className="overflow-scroll">
          <table className="w-100">
            <tr className="mt-4">
              <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Qty
              </th>
              <div className="my-3">
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="25"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="50"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="100"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="150"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="250"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="500"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="button"
                        value="1000"
                      />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
            <tr>
              <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Distributor Price
              </th>
              <div className="mt-2">
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2">
                      <input
                        class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        type="number"
                        placeholder="₹90.00"
                        maxlength={3}
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="₹85.00"
                        type="number"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="₹85.00"
                        type="number"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="₹75.00"
                        type="number"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="₹75.00"
                        type="number"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="₹75.00"
                        type="number"
                      />
                    </div>
                    <div className="mx-2">
                      <input
                        class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="₹75.00"
                        type="number"
                      />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
          </table>
        </div>
      </div> */}
      {/* <div className="">
        <button
          class={`cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded mt-4 ${
            !checkBox && "opacity-50"
          }`}
          onClick={() => SetMarginShow(!marginShow)}
          disabled={checkBox ? false : true}
        >
          View Margin
        </button>
      </div> */}
      {checkBox && (
        <div>
          <p class="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary mt-4">
            Admin Pricing
          </p>
          <div className="w-100 d-flex flex-wrap res-flex as-jb gap-5 py-4 px-4 bg-lt-blue2 rounded-4 mt-3">
            <div className="w-100">
              <div>
                {/* <div className="w-100 d-flex ac-js">
                  <div className="w-100">
                    <h5 className="primary">Ecommerce:</h5>
                  </div>
                </div> */}
                <div>
                  <fieldset>
                    <div className="">
                      <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-3">
                        {vendorData?.vendor?.name}
                      </p>
                      <div className="overflow-scroll">
                        <table className="">
                          <tr className="mt-4">
                            <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                              Qty
                            </th>
                            <div
                              className="my-3"
                              style={{ display: "inline-block" }}
                            >
                              <td>
                                <div className="d-flex w-100">
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      type="text"
                                      value={box1}
                                      onChange={(e) => setbox1(e.target.value)}
                                      disabled
                                      // disabled={!edit ? true : disabled ? true : false}
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      type="text"
                                      value={box2}
                                      // disabled={!edit ? true : false}
                                      onChange={(e) => setbox2(e.target.value)}
                                      disabled
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      type="text"
                                      value={box3}
                                      // disabled={!edit ? true : false}
                                      onChange={(e) => setbox3(e.target.value)}
                                      disabled
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn f2 px-3 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      type="text"
                                      // disabled={!edit ? true : false}
                                      value={box4}
                                      onChange={(e) => setbox4(e.target.value)}
                                      disabled
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn f2 px-3 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      type="text"
                                      value={box5}
                                      // disabled={!edit ? true : false}
                                      onChange={(e) => setbox5(e.target.value)}
                                      disabled
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn f2 px-3 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      type="text"
                                      value={box6}
                                      // disabled={!edit ? true : false}
                                      onChange={(e) => setbox6(e.target.value)}
                                      disabled
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      type="text"
                                      value={box7}
                                      // disabled={!edit ? true : false}
                                      onChange={(e) => setbox7(e.target.value)}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </td>
                            </div>
                          </tr>
                          <tr>
                            <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                              Distributor Price
                            </th>
                            <div
                              className="mt-2"
                              style={{ display: "inline-block" }}
                            >
                              <td>
                                <div className="d-flex w-100">
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      type="number"
                                      placeholder="₹00.00"
                                      maxlength={3}
                                      value={cost?.for_25}
                                      onChange={(e) =>
                                        handleCost(e.target.value, "for_25")
                                      }
                                      disabled
                                      // disabled={!edit ? true : disabled ? true : false}
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      placeholder="₹00.00"
                                      type="number"
                                      value={cost?.for_50}
                                      onChange={(e) =>
                                        handleCost(e.target.value, "for_50")
                                      }
                                      disabled
                                      // disabled={!edit ? true : false}
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      placeholder="₹00.00"
                                      type="number"
                                      value={cost?.for_100}
                                      onChange={(e) =>
                                        handleCost(e.target.value, "for_100")
                                      }
                                      disabled
                                      // disabled={!edit ? true : false}
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      placeholder="₹00.00"
                                      type="number"
                                      value={cost?.for_150}
                                      onChange={(e) =>
                                        handleCost(e.target.value, "for_150")
                                      }
                                      disabled
                                      // disabled={!edit ? true : false}
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      placeholder="₹00.00"
                                      type="number"
                                      value={cost?.for_250}
                                      onChange={(e) =>
                                        handleCost(e.target.value, "for_250")
                                      }
                                      disabled
                                      // disabled={!edit ? true : false}
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      placeholder="₹00.00"
                                      type="number"
                                      value={cost?.for_500}
                                      onChange={(e) =>
                                        handleCost(e.target.value, "for_500")
                                      }
                                      disabled
                                      // disabled={!edit ? true : false}
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      placeholder="₹00.00"
                                      type="number"
                                      value={cost?.for_1000}
                                      onChange={(e) =>
                                        handleCost(e.target.value, "for_1000")
                                      }
                                      disabled
                                      // disabled={!edit ? true : false}
                                    />
                                  </div>
                                </div>
                              </td>
                            </div>
                          </tr>

                          <tr>
                            <th className="  f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                              <button
                                className="cust-btn addbtn mt-sm-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-11 fs-xl-11 fs-xxl-12 px-5 rounded"
                                onClick={() => {
                                  setCount(2);
                                  addpriceCharge();
                                }}
                              >
                                + add Charges
                              </button>
                            </th>
                          </tr>
                          {priceCharge?.map((item, ind) => {
                            return (
                              <tr>
                                <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                                  {" "}
                                  <span>
                                    <button
                                      className="cust-btn"
                                      onClick={() => {
                                        setCount(2);
                                        removepriceCharge(item, ind);
                                      }}
                                    >
                                      <HighlightOffIcon
                                        style={{ width: "15px" }}
                                        className="mb-3"
                                      />
                                    </button>
                                  </span>
                                  <input
                                    className="mx-1 num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                    type="text"
                                    style={{ width: "120px", height: "35px" }}
                                    value={item?.name}
                                    name="name"
                                    onChange={(e) => {
                                      setCount(2);
                                      handlepriceCharge(e, ind, "name");
                                    }}
                                    // disabled={!edit ? true : false}
                                  />
                                </th>
                                <div
                                  className="mt-2"
                                  style={{ display: "inline-block" }}
                                >
                                  <td>
                                    <div className="d-flex w-100">
                                      <div className="mx-2">
                                        <input
                                          className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                          type="number"
                                          placeholder="₹00.00"
                                          maxlength={3}
                                          value={item?.for_25}
                                          name="for_25"
                                          onChange={(e) => {
                                            setCount(2);
                                            handlepriceCharge(e, ind, "for_25");
                                          }}
                                          // disabled={!edit ? true : false}
                                        />
                                      </div>
                                      <div className="mx-2">
                                        <input
                                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                          placeholder="₹00.00"
                                          type="number"
                                          value={item?.for_50}
                                          name="for_50"
                                          onChange={(e) => {
                                            setCount(2);
                                            handlepriceCharge(e, ind, "for_50");
                                          }}
                                          // disabled={!edit ? true : false}
                                        />
                                      </div>
                                      <div className="mx-2">
                                        <input
                                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                          placeholder="₹00.00"
                                          type="number"
                                          value={item?.for_100}
                                          name="for_100"
                                          onChange={(e) => {
                                            setCount(2);
                                            handlepriceCharge(
                                              e,
                                              ind,
                                              "for_100"
                                            );
                                          }}
                                          // disabled={!edit ? true : false}
                                        />
                                      </div>
                                      <div className="mx-2">
                                        <input
                                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                          placeholder="₹00.00"
                                          type="number"
                                          value={item?.for_150}
                                          name="for_150"
                                          onChange={(e) => {
                                            setCount(2);
                                            handlepriceCharge(
                                              e,
                                              ind,
                                              "for_150"
                                            );
                                          }}
                                          // disabled={!edit ? true : false}
                                        />
                                      </div>
                                      <div className="mx-2">
                                        <input
                                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                          placeholder="₹00.00"
                                          type="number"
                                          value={item?.for_250}
                                          name="for_250"
                                          onChange={(e) => {
                                            setCount(2);
                                            handlepriceCharge(
                                              e,
                                              ind,
                                              "for_250"
                                            );
                                          }}
                                          // disabled={!edit ? true : false}
                                        />
                                      </div>
                                      <div className="mx-2">
                                        <input
                                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                          placeholder="₹00.00"
                                          type="number"
                                          value={item?.for_500}
                                          name="for_500"
                                          onChange={(e) => {
                                            setCount(2);
                                            handlepriceCharge(
                                              e,
                                              ind,
                                              "for_500"
                                            );
                                          }}
                                          // disabled={!edit ? true : false}
                                        />
                                      </div>
                                      <div className="mx-2">
                                        <input
                                          className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                          placeholder="₹00.00"
                                          type="number"
                                          value={item?.for_1000}
                                          name="for_1000"
                                          onChange={(e) => {
                                            setCount(2);
                                            handlepriceCharge(
                                              e,
                                              ind,
                                              "for_1000"
                                            );
                                          }}
                                          // disabled={!edit ? true : false}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                </div>
                              </tr>
                            );
                          })}
                          <tr>
                            <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                              Net
                            </th>
                            <div
                              className="mt-2"
                              style={{ display: "inline-block" }}
                            >
                              <td>
                                <div className="d-flex w-100">
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      type="number"
                                      placeholder="₹00.00"
                                      maxlength={3}
                                      disabled
                                      value={net?.for_25}
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      placeholder="₹00.00"
                                      type="number"
                                      disabled
                                      value={net?.for_50}
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      placeholder="₹00.00"
                                      type="number"
                                      disabled
                                      value={net?.for_100}
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      placeholder="₹00.00"
                                      type="number"
                                      disabled
                                      value={net?.for_150}
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      placeholder="₹00.00"
                                      type="number"
                                      disabled
                                      value={net?.for_250}
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      placeholder="₹00.00"
                                      type="number"
                                      disabled
                                      value={net?.for_500}
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      placeholder="₹00.00"
                                      type="number"
                                      disabled
                                      value={net?.for_1000}
                                    />
                                  </div>
                                </div>
                              </td>
                            </div>
                          </tr>
                          <tr>
                            <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                              <button
                                className="cust-btn addbtn mt-sm-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-11 fs-xl-11 fs-xxl-12 px-5 rounded"
                                onClick={() => {
                                  setCount(2);
                                  handleCloseMargin();
                                }}
                              >
                                + add Margin
                              </button>
                            </th>
                            {!marginBox && (
                              <div
                                className="mt-2"
                                style={{ display: "inline-block" }}
                              >
                                <td>
                                  <div className="d-flex w-100">
                                    <div className="mx-2">
                                      <input
                                        className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                        type="number"
                                        placeholder="%00.00"
                                        maxlength={3}
                                        value={margin?.for_25}
                                        onChange={(e) => {
                                          setCount(2);
                                          handleMargin(
                                            e.target.value,
                                            "for_25"
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="mx-2">
                                      <input
                                        className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                        placeholder="%00.00"
                                        type="number"
                                        value={margin?.for_50}
                                        onChange={(e) => {
                                          setCount(2);
                                          handleMargin(
                                            e.target.value,
                                            "for_50"
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="mx-2">
                                      <input
                                        className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                        placeholder="%00.00"
                                        type="number"
                                        value={margin?.for_100}
                                        onChange={(e) => {
                                          setCount(2);
                                          handleMargin(
                                            e.target.value,
                                            "for_100"
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="mx-2">
                                      <input
                                        className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                        placeholder="%00.00"
                                        type="number"
                                        value={margin?.for_150}
                                        onChange={(e) => {
                                          setCount(2);
                                          handleMargin(
                                            e.target.value,
                                            "for_150"
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="mx-2">
                                      <input
                                        className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                        placeholder="%00.00"
                                        type="number"
                                        value={margin?.for_250}
                                        onChange={(e) => {
                                          setCount(2);
                                          handleMargin(
                                            e.target.value,
                                            "for_250"
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="mx-2">
                                      <input
                                        className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                        placeholder="%00.00"
                                        type="number"
                                        value={margin?.for_500}
                                        onChange={(e) => {
                                          setCount(2);
                                          handleMargin(
                                            e.target.value,
                                            "for_500"
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="mx-2">
                                      <input
                                        className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                        placeholder="%00.00"
                                        type="number"
                                        value={margin?.for_1000}
                                        onChange={(e) => {
                                          setCount(2);
                                          handleMargin(
                                            e.target.value,
                                            "for_1000"
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </td>
                              </div>
                            )}
                          </tr>

                          <tr>
                            <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                              Price
                            </th>
                            <div
                              className="mt-2"
                              style={{ display: "inline-block" }}
                            >
                              <td>
                                <div className="d-flex w-100">
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      type="number"
                                      placeholder="₹00.00"
                                      maxlength={3}
                                      disabled
                                      value={distributorPrice?.for_25}
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      placeholder="₹00.00"
                                      type="number"
                                      disabled
                                      value={distributorPrice?.for_50}
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      placeholder="₹00.00"
                                      type="number"
                                      disabled
                                      value={distributorPrice?.for_100}
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      placeholder="₹00.00"
                                      type="number"
                                      disabled
                                      value={distributorPrice?.for_150}
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      placeholder="₹00.00"
                                      type="number"
                                      disabled
                                      value={distributorPrice?.for_250}
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      placeholder="₹00.00"
                                      type="number"
                                      disabled
                                      value={distributorPrice?.for_500}
                                    />
                                  </div>
                                  <div className="mx-2">
                                    <input
                                      className="num-cls-btn px-3 f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                      placeholder="₹00.00"
                                      type="number"
                                      disabled
                                      value={distributorPrice?.for_1000}
                                    />
                                  </div>
                                </div>
                              </td>
                            </div>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </fieldset>

                  <div className="w-100 d-flex ac-js mt-4">
                    <p className="primary f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                      Minimum Selling Price :
                    </p>
                    <div className="mx-2 place-holde">
                      <input
                        class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="5%"
                        type="number"
                        onChange={(e) => handleMsp(e.target.value)}
                        value={msp}
                      />
                    </div>
                  </div>
                  <div className="overflow-scroll">
                    <table className="w-100 ">
                      <tr className="mt-4">
                        <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                          Net
                        </th>
                        <div className="my-3">
                          <td>
                            <div className="d-flex w-100">
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="₹25.00"
                                  type="number"
                                  value={mspPrice?.for_25}
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="₹50.00"
                                  value={mspPrice?.for_50}
                                  type="number"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="₹100.00"
                                  value={mspPrice?.for_100}
                                  type="number"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="₹150.00"
                                  value={mspPrice?.for_150}
                                  type="number"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="₹250.00"
                                  value={mspPrice?.for_250}
                                  type="number"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="₹500.00"
                                  value={mspPrice?.for_500}
                                  type="number"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="₹1000.00"
                                  value={mspPrice?.for_1000}
                                  type="number"
                                />
                              </div>
                            </div>
                          </td>
                        </div>
                      </tr>
                    </table>
                  </div>
                  <div className="w-100 d-flex ac-js mt-4">
                    <p className="primary f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                      Suggested Selling Price :
                    </p>
                    <div className="mx-2 place-holde d-flex gap-2">
                      <input
                        class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                        placeholder="5%"
                        type="number"
                        onChange={(e) => handleScp(e.target.value)}
                        value={scp}
                      />
                      {/* {scp?.length > 0 && scp < msp && (
                        <div className="d-flex gap-1 mt-2">
                          <ErrorIcon className="svg_log" />
                          <p className="err-input_log mt-1">
                            Enter Higher Value to MSP
                          </p>
                        </div>
                      )} */}

                      {/* {scp?.length > 0 && scp <= msp && (
                        <div className="d-flex gap-1 mt-2">
                          <ErrorIcon className="svg_log" />
                          <p className="err-input_log mt-1">
                            Enter Higher Value to MSP
                          </p>
                        </div>
                      )} */}
                    </div>
                  </div>
                  <div className="overflow-scroll">
                    <table className="w-100 ">
                      <tr className="mt-4">
                        <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                          Net
                        </th>
                        <div className="my-3">
                          <td>
                            <div className="d-flex w-100">
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="₹25.00"
                                  type="number"
                                  value={scpPrice?.for_25}
                                  onChange={(e) =>
                                    handleScpPrice(e.target.value, "for_25")
                                  }
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="₹50.00"
                                  value={scpPrice?.for_50}
                                  type="number"
                                  onChange={(e) =>
                                    handleScpPrice(e.target.value, "for_50")
                                  }
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="₹100.00"
                                  value={scpPrice?.for_100}
                                  type="number"
                                  onChange={(e) =>
                                    handleScpPrice(e.target.value, "for_100")
                                  }
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="₹150.00"
                                  value={scpPrice?.for_150}
                                  type="number"
                                  onChange={(e) =>
                                    handleScpPrice(e.target.value, "for_150")
                                  }
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="₹250.00"
                                  value={scpPrice?.for_250}
                                  type="number"
                                  onChange={(e) =>
                                    handleScpPrice(e.target.value, "for_250")
                                  }
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="₹500.00"
                                  value={scpPrice?.for_500}
                                  type="number"
                                  onChange={(e) =>
                                    handleScpPrice(e.target.value, "for_500")
                                  }
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="₹1000.00"
                                  value={scpPrice?.for_1000}
                                  type="number"
                                  onChange={(e) =>
                                    handleScpPrice(e.target.value, "for_1000")
                                  }
                                />
                              </div>
                            </div>
                          </td>
                        </div>
                      </tr>
                    </table>
                  </div>
                  {scp?.length > 0 && +scp < +msp && !scpErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">
                        Enter Higher Value to MSP
                      </p>
                    </div>
                  )}

                  {scpErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">
                        Enter Higher Value to MSP
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-50">
              <div className="w-100 d-flex ac-jc">
                <div className="w-100">
                  <h5 class="primary">Reseller:</h5>
                </div>
              </div>
              <div>
                <div className="">
                  <div className="overflow-scroll">
                    <table className="w-100">
                      <tr className="mt-4">
                        <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                          Qty
                        </th>
                        <div className="my-3">
                          <td>
                            <div className="d-flex w-100">
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  type="button"
                                  value="250"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  type="button"
                                  value="500"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  type="button"
                                  value="1000"
                                />
                              </div>
                            </div>
                          </td>
                        </div>
                      </tr>
                      <tr>
                        <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                          Net
                        </th>
                        <div className="mt-2">
                          <td>
                            <div className="d-flex w-100">
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="₹85.00"
                                  type="number"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="₹75.00"
                                  type="number"
                                />
                              </div>
                              <div className="mx-2">
                                <input
                                  class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                  placeholder="₹75.00"
                                  type="number"
                                />
                              </div>
                            </div>
                          </td>
                        </div>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className=" d-flex ac-js mt-4">
                  <p className="primary f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                    MSP
                  </p>
                  <div className="mx-2 place-holde">
                    <input
                      class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                      placeholder="5%"
                      type="number"
                    />
                  </div>
                </div>
                <div className="overflow-scroll">
                  <table className="w-100">
                    <tr className="mt-4">
                      <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                        Net
                      </th>
                      <div className="my-3">
                        <td>
                          <div className="d-flex w-100">
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="₹75.00"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="₹75.00"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="₹75.00"
                                type="number"
                              />
                            </div>
                          </div>
                        </td>
                      </div>
                    </tr>
                  </table>
                </div>
                <div className=" d-flex ac-js mt-4">
                  <p className="primary f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                    SCP
                  </p>
                  <div className="mx-2 place-holde">
                    <input
                      class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                      placeholder="5%"
                      type="number"
                    />
                  </div>
                </div>
                <div className="overflow-scroll">
                  <table className="w-100">
                    <tr className="mt-4">
                      <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                        Net
                      </th>
                      <div className="my-3">
                        <td>
                          <div className="d-flex w-100">
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="₹75.00"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="₹75.00"
                                type="number"
                              />
                            </div>
                            <div className="mx-2">
                              <input
                                class="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                placeholder="₹75.00"
                                type="number"
                              />
                            </div>
                          </div>
                        </td>
                      </div>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="w-100 mt-5">
        <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
          Last updated on August 28, 2023 at 03:10 AM
        </p>
      </div>
      {checkBox && (
        <div className="btn-w-cust-cont mt-4 w-100">
          <button
            class={`btn-w-cust cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
              btns && "opacity-50"
            }`}
            onClick={() => submitHandle()}
            disabled={btns ? true : false}
          >
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

export default LibraryVendorPricingComp;
