import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProductListComp from "../ProductComp/ProductListComp";
import MapProductComp from "../ProductComp/MapProductComp";
import UploadIcon from "@mui/icons-material/Upload";
import RequestListComp from "../ProductComp/RequestListComp";
import MyProductListComp from "../ProductComp/MyProductListComp";
import GetAppIcon from "@mui/icons-material/GetApp";
import DownloadIcon from "@mui/icons-material/Download";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import NewProductSuggestion from "./NewProductSuggestion";
import UploadImages from "../Popup/UploadImages";
import {
  useLazyCategoriesQuery,
  usePriceListMutation,
  useProductSuggestionListMutation,
  useProductSuggestionStoreMutation,
  useRequestListMutation,
  useVendorAcceptedProListMutation,
  useVendorMapProListMutation,
} from "../../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../redux/slice/loaderNoti";
import PriceChange from "../ProductFeed/PriceChange";
import { data } from "autoprefixer";

const   ProductDetails = ({ products, place, path, vendors, categoryList }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({});
  const [list, setList] = useState([]);
  const [product, setProduct] = useState(false);
  const [decorative, setDecorative] = useState(false);
  const [priceChange, setPriceChange] = useState(false);
  const [myProducts, setMyProducts] = useState(true);
  const [newProductSuggestion, setNewProductSuggestion] = useState(false);
  const [productList, setParoductList] = useState("products");
  const [uploadPopUp, setUploadPopUp] = useState(false);
  const [btn, setBtn] = useState(false);
  const [listCat, setListCat] = useState([]);
  // console.log(location)
  // const vendorId =vendors

  // Upload images states

  const [fileData, setFileData] = useState([]);
  const [price, setPrice] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImages] = useState("");
  const [search, setSearch] = useState("");
  const [stsSearch, setStsSearch] = useState("");

  //Err
  const [priceErr, setPriceErr] = useState(false);
  const [titleErr, setTitleErr] = useState(false);
  const [imageErr, setImageErr] = useState(false);

  //PRICE CHANGE LIST
  const [priceChangeList, setPriceChangeList] = useState([]);
  const [searchPrice, setSearchPrice] = useState("");
  const [suggestionPage, setsSuggestionPage] = useState(1);
  const [suggestionPagination, setsSuggestionPagination] = useState({});
  const [pageChange, setPageChange] = useState(1);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setTodate] = useState("");
  const [priceRow, setPriceRow] = useState(10);
  // REQUEST
  const [pagenationReq, setPagenationReq] = useState({});
  const [listReq, setListReq] = useState([]);
  const [ReqSearch, setReqSearch] = useState("");
  const [reqStatus, setReqStatus] = useState("");
  const [pageReq, setPageReq] = useState(1);
  const [pageReqRow, setPageReqRow] = useState(10);

  //RTK
  const [PoductStoreApi] = useProductSuggestionStoreMutation();
  const [ProductSuggestionApi] = useProductSuggestionListMutation();
  const [priceListApi] = usePriceListMutation();
  const [productRequestList] = useRequestListMutation();
  const [vendorMapListApi] = useVendorMapProListMutation();
  const [vendorAcceptedListApi] = useVendorAcceptedProListMutation();
  // const [mapProductListApi] = useMapProductsListsMutation();
  // const [acceptedProductsApi] = useMapProductsAcceptedListsMutation();
  const [listCategory] = useLazyCategoriesQuery();
  const [catValueNew, setCatValueNew] = useState("");
  const [priceChangePagination, setPriceChangePagination] = useState({});
  const [priceCat, setPriceCat] = useState("");
  const [proSuggest, setProSuggest] = useState("");
  const tab = location?.state?.tab;

  const getList = () => {
    listCategory()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setListCat(res?.categories);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (tab === "myproduct") {
      setMyProducts(true);
      setDecorative(false);
      setProduct(false);
    } else if (tab === "mapproduct") {
      setMyProducts(false);
      setDecorative(false);
      setProduct(true);
    } else if (tab === "requestproduct") {
      setMyProducts(false);
      setDecorative(true);
      setProduct(false);
    }
  }, []);
  const getRequest = (status, event, category_id) => {
    // console.log(status,"status")
    // console.log(filter,"s")
    let params = `?page=${pageReq}`;
    const formData = new FormData();
    formData.append("vendor_id", vendors);
    // setSearch(event)
    setReqSearch(event);
    setReqStatus(status);
    if (status) {
      formData.append("status", status);
    }
    if (event?.length > 0) {
      formData.append("search", event);
    }

    if (category_id) {
      formData.append("category_id", category_id);
    }

    // formData.append("vendor_id", vendors);

    setCatValueNew(category_id);

    productRequestList({ payload: formData, params: params })
      .unwrap()
      .then((res) => {
        // console.log(res);
        // const temp = [];
        // res?.lists?.map((item, ind) => {
        //   const images = item?.image_urls?.product_images?.find(
        //     (image) => image?.is_default == 1
        //   );

        //   //  console.log(temp)
        // });

        // setListReq(temp);
        setListReq(res?.lists);

        setPagenationReq(res?.pagination_meta);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProduct = (status, event, row, category_id) => {
    // console.log(status,"status")
    // console.log(filter,"s")
    let params = `?page=${suggestionPage}`;
    const formData = new FormData();
    setSearch(event);
    setStsSearch(status);
    setProSuggest(row);
    if (status) {
      formData.append("status", status);
    }
    if (event?.length > 0) {
      formData.append("search", event);
    }

    if (row) {
      formData.append("rows", row);
    }

    formData.append("vendor_id", vendors);
    ProductSuggestionApi({ payload: formData, params: params })
      .unwrap()
      .then((res) => {
        // console.log(res)
        setList(res?.lists);
        setsSuggestionPagination(res?.pagination_meta);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPriceList = (event, type, status, category_id, row) => {
    let formdata = new FormData();
    let params = `?page=${pageChange}`;
    // formdata.append("status", 1);

    if (event?.length > 0) {
      formdata.append("search", event);
    }
    if (fromDate && toDate) {
      formdata.append("from_date", fromDate);
      formdata.append("to_date", toDate);
    }

    if (status) {
      formdata.append("status", status);
    }

    if (row) {
      formdata.append("rows", row);
    }

    if (category_id) {
      formdata.append("category_id", category_id);
    }

    formdata.append("vendor_id", vendors);

    setSearch(event);
    setPriceRow(row);
    setPriceCat(category_id);

    priceListApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        setPriceChangeList(res?.lists);
        setPriceChangePagination(res?.pagination_meta);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (fromDate && toDate) {
      getPriceList();
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    getPriceList();
  }, [pageChange]);

  useEffect(() => {
    getProduct();
  }, [suggestionPage]);

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    getList();
  }, []);

  // MAP PRODUCT LIST
  const [mapCat, setMapCat] = useState("");
  const [mapStatus, setMapStatus] = useState("");
  const [mapPageRow, setMapPageRow] = useState("");
  const [mapSearch, setMapSearch] = useState("");
  const [mapPage, setMapPage] = useState(1);
  const [listMap, setListMap] = useState([]);
  const [mapPagination, setMapPagination] = useState({});

  const getMapList = (event, row, status, category) => {
    let params = `?page=${mapPage}`;
    let formdata = new FormData();

    setMapSearch(event);

    if (event?.length > 0) {
      formdata.append("search", event);
    }

    if (row) {
      formdata.append("rows", row);
    }

    if (status) {
      formdata.append("status", status);
    }

    if (category) {
      formdata.append("category_id", category);
    }

    setMapCat(category);
    setMapStatus(status);
    setMapPageRow(row);

    formdata.append("vendor_id", location?.state?.data?.id);
    vendorMapListApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        setListMap(res?.lists);
        setMapPagination(res?.pagination_meta);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getMapList();
  }, [mapPage]);

  // ACCEPT PRODUCT LIST
  const [acceptedCat, setAcceptedCat] = useState("");
  const [acceptedStatus, setAcceptedStatus] = useState("");
  const [acceptedPageRow, setAcceptedPageRow] = useState("");
  const [acceptedSearch, setAcceptedSearch] = useState("");
  const [acceptedPage, setAcceptedPage] = useState(1);
  const [listaAccepted, setListAccepted] = useState([]);
  const [acceptedPagination, setAcceptedPagination] = useState({});

  const getAcceptedList = (event, row, status, category) => {
    let params = `?page=${acceptedPage}`;
    let formdata = new FormData();

    setAcceptedSearch(event);

    if (event?.length > 0) {
      formdata.append("search", event);
    }

    if (row) {
      formdata.append("rows", row);
    }

    if (status) {
      formdata.append("status", status);
    }

    if (category) {
      formdata.append("category_id", category);
    }

    setAcceptedCat(category);
    setAcceptedStatus(status);
    setAcceptedPageRow(row);

    formdata.append("vendor_id", location?.state?.data?.id);
    vendorAcceptedListApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        setListAccepted(res?.lists);
        setAcceptedPagination(res?.pagination_meta);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getAcceptedList();
  }, [acceptedPage]);

  //   popUp upload function
  const handleUploadHandler = (e) => {
    // console.log(e, "e");
    if (title?.length == "" || image?.length == "" || price?.length == "") {
      setPriceErr(true);
      setTitleErr(true);
      setImageErr(true);
    } else {
      const formData = new FormData();
      formData.append("image", image);
      formData.append("vendor_id", location?.state?.data?.id);
      formData.append("name", title);
      formData.append("total_amount", price);
      setBtn(true);
      dispatch(saveLoader(true));
      PoductStoreApi(formData)
        .unwrap()
        .then((res) => {
          setBtn(false);
          dispatch(saveLoader(false));
          setUploadPopUp(!uploadPopUp);
          getProduct();
        })
        .catch((err) => {
          setBtn(false);
          dispatch(saveLoader(false));
          console.log(err);
        });
    }
  };
  const handleClose = (e) => {
    setUploadPopUp(!uploadPopUp);
  };
  useEffect(() => {
    if (location?.state?.productList) {
      setParoductList(location?.state?.productList);
    }
  }, []);
  // console.log(productList, "tabs");
  return (
    <div className="w-100">
      <div className="d-flex ac-je">
        <div>
          {/* {product && (
            <button
              onClick={() => navigate("/map-product")}
              className="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Map Product
            </button>
          )}
          {myProducts && (
            <button
              onClick={() => navigate("/map-product")}
              className="invisible cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Map Product
            </button>
          )} */}
          {/* <DownloadIcon /> */}

          {productList == "decorative" && (
            <button
              onClick={() =>
                navigate("/create-new-request-new", {
                  state: { data: location?.state?.data },
                })
              }
              className="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Create New Request
            </button>
          )}
          {productList == "new_suggestion" && (
            <button
              class="btn-w-cust cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded w-100"
              onClick={() => {
                setUploadPopUp(!uploadPopUp);
              }}
            >
              Upload Images
            </button>
          )}
        </div>
      </div>
      <div className="d-flex mt-5 ac-jb flex-wrap">
        <div className="d-flex mt-3 rk">
          <button
            onClick={() => {
              setMyProducts(true);
              setDecorative(false);
              setProduct(false);
              setDecorative(false);
              setParoductList("products");
              setNewProductSuggestion(false);
              setPriceChange(false);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              productList == "products"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                productList == "products" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Approved Products
            </p>
          </button>
          <button
            onClick={() => {
              setProduct(true);
              setDecorative(false);
              setParoductList("product");
              setMyProducts(false);
              setNewProductSuggestion(false);
              setPriceChange(false);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              productList == "product"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                productList == "product" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Map Products
            </p>
          </button>
          <button
            onClick={() => {
              setProduct(false);
              setDecorative(true);
              setMyProducts(false);
              setParoductList("decorative");
              setNewProductSuggestion(false);
              setPriceChange(false);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              productList == "decorative"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                productList == "decorative" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Add New Products
            </p>
          </button>
          <button
            onClick={() => {
              setProduct(false);
              setDecorative(false);
              setMyProducts(false);
              setNewProductSuggestion(false);
              setParoductList("price");
              setPriceChange(true);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              productList == "price"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                productList == "price" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Price Change
            </p>
          </button>
          <button
            onClick={() => {
              setProduct(false);
              setDecorative(false);
              setMyProducts(false);
              setNewProductSuggestion(true);
              setParoductList("new_suggestion");
              setPriceChange(false);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              productList == "new_suggestion"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                productList == "new_suggestion" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              New Products Suggestions
            </p>
          </button>
        </div>

        <div>
          {productList != "products" && productList != "new_suggestion" && (
            <button className="cust-btn addbtn2 mt-sm-3 mx-3 mt-2 gray f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
              <UploadIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
              Import
            </button>
          )}
          {productList != "products" && productList != "new_suggestion" && (
            <button className="cust-btn addbtn2 mt-sm-3 mx-3 mt-2 gray f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
              <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
              Export
            </button>
          )}
          {productList != "products" && productList != "new_suggestion" && (
            <button className="cust-btn addbtn2 mt-sm-3 mx-3 mt-2 gray f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
              <ListAltRoundedIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
              Sample Download
            </button>
          )}
        </div>
        {/* <button className="cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            onClick={() => navigate("/products/add-new-product", {
              state: { type: "Charles", place: place },
            })}>
            + Add Products
          </button> */}
      </div>
      {productList == "products" && (
        <MyProductListComp
          products={products}
          place={place}
          listCat={listCat}
          acceptedCat={acceptedCat}
          acceptedStatus={acceptedStatus}
          acceptedPageRow={acceptedPageRow}
          acceptedSearch={acceptedSearch}
          acceptedPagination={acceptedPagination}
          listaAccepted={listaAccepted}
          getAcceptedList={getAcceptedList}
          acceptedPage={acceptedPage}
          setAcceptedPage={setAcceptedPage}
        />
      )}
      {productList == "product" && (
        <MapProductComp
          products={products}
          place={place}
          listCat={listCat}
          getMapList={getMapList}
          setMapPage={setMapPage}
          mapCat={mapCat}
          mapStatus={mapStatus}
          mapPageRow={mapPageRow}
          mapSearch={mapSearch}
          mapPagination={mapPagination}
          listMap={listMap}
          //  list={list}
          //     status={status}
          //     name={name}
          //     searchValue={searchValue}
          //     setSearchValue={setSearchValue}
          //     pageRow={pageRow}
          //     setPageRow={setPageRow}
          //     page={page}
          //     setPage={setPage}
          //     pagenation={pagination}
          //     setPagination={setPagination}
        />
      )}
      {productList == "decorative" && (
        <RequestListComp
          products={products}
          data={location?.state?.data}
          // categoryList={categoryList}
          ReqSearch={ReqSearch}
          pageReqRow={pageReqRow}
          setPageReq={setPageReq}
          getProduct={getRequest}
          catValueNew={catValueNew}
          reqStatus={reqStatus}
          list={listReq}
          pagination={pagenationReq}
          setPageReqRow={setPageReqRow}
          pageReq={pageReq}
          listCat={listCat}
        />
      )}
      {productList == "new_suggestion" && (
        <NewProductSuggestion
          product={product}
          place={place}
          location={location}
          list={list}
          pagination={pagination}
          getProduct={getProduct}
          btn={btn}
          setSearch={setSearch}
          search={search}
          stsSearch={stsSearch}
          suggestionPagination={suggestionPagination}
          suggestionPage={suggestionPage}
          setsSuggestionPage={setsSuggestionPage}
          listCat={listCat}
          proSuggest={proSuggest}
        />
      )}
      {/* {Path?.pathname === "/vendor-details" && place === 'Charles' && <ProductListComp loc={loc} />} */}
      {uploadPopUp && (
        <UploadImages
          onUploadHandler={handleUploadHandler}
          handleClose={handleClose}
          setFileData={setFileData}
          fileData={fileData}
          setPrice={setPrice}
          price={price}
          setTitle={setTitle}
          title={title}
          priceErr={priceErr}
          setPriceErr={setPriceErr}
          titleErr={titleErr}
          setTitleErr={setTitleErr}
          imageErr={imageErr}
          setImageErr={setImageErr}
          setImages={setImages}
          image={image}
          btn={btn}
        />
      )}
      {productList == "price" && (
        <PriceChange
          priceChangeList={priceChangeList}
          setPriceChangeList={setPriceChangeList}
          search={search}
          setSearch={setSearch}
          pageChange={pageChange}
          setPageChange={setPageChange}
          setFromDate={setFromDate}
          setTodate={setTodate}
          toDate={toDate}
          fromDate={fromDate}
          getPriceList={getPriceList}
          priceChangePagination={priceChangePagination}
          priceRow={priceRow}
          catValuePrice={priceCat}
          listCat={listCat}
        />
      )}
    </div>
  );
};

export default ProductDetails;
