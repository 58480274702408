// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useDispatch } from "react-redux";
import { useLazyVendorViewQuery, useVendorEditMutation, useVendorStoreMutation } from "../../../redux/api/api";
import { saveLoader } from "../../../redux/slice/loaderNoti";
import ErrorIcon from "@mui/icons-material/Error";
import ImageCroper from "../../../components/Popup/ImageCroper";
import { noLogo } from "../../../assets/img";

const VendorListEdit = () => {
  const location = useLocation();
  const [data, setData] = useState("");
  const [action, setAction] = useState(false);                                                            
  const [croperImage, setCropImage] = useState();
  const [croper, setCroper] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setData(location?.state?.data);
  }, [location]);

  const type = location?.state?.type;
console.log(location?.state?.type);


  // RTK QUERY
  const [addVendorApi] = useVendorStoreMutation();
  const [editVendorApi] = useVendorEditMutation();
  const [viewVendorApi] = useLazyVendorViewQuery();


  const [full_img, setFullImg] = useState("");
  const [fullImg_Err, setFullImg_Err] = useState(false);

  const [image, setImage] = useState("");
  const [priority, setPriority] = useState("");
  const [pririty_Err, setPrio_Err] = useState(false);




  const [Email, setEmail] = useState("");
  const [Role, setRole] = useState("");
  const [Password, setPassword] = useState("");
  const [CPassword, setCPassword] = useState("");

  // const [image, setImage] = useState('');
  const dispatch = useDispatch();



  const [EmailErr, setEmailErr] = useState(false);
  const [RoleErr, setRoleErr] = useState(false);
  const [PasswordErr, setPasswordErr] = useState(false);
  const [CPasswordErr, setCPasswordErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");


  useEffect(() => {
    setData(location?.state?.data);
  }, [location]);

  const toggleImagePopup = (type, method) => {
    setCroper(!croper);
    if (method == 'open') {
      setImage(type)
    }
  };

  useEffect(() => {
    if(image == 'logo' && croperImage){
      fetch(croperImage)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "File name", { type: "image/png" });
        setFullImg(file);
      });
    }else if(croperImage){
    fetch(croperImage)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "File name", { type: "image/png" });
        setFullImg(file);
      });
    }
  }, [croperImage])


  useEffect(() => {
    setData(location?.state?.data);
  }, [location]);

  const getView = () => {
    viewVendorApi(location?.state?.data?.id)
      .unwrap()
      .then((res) => {
        console.log(res,"view")
        let data = res?.setting_detail;
        setFullImg(data?.image_url);
   
        //json decode
        let json= JSON.parse( data?.contents)
        console.log(json);
        // setClient_Img(data?.image_url);
        setPriority(json?.priority);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (location?.state?.data?.id) {
      getView();
    }
  }, []);

  console.log('full_img', full_img);


  const submitHandle = () => {
    if (
      full_img?.length == 0 ||
      priority?.length == 0
  
    ) {
      setFullImg_Err(true);  
      setPrio_Err(true);

    } else {
      let formdata = new FormData();
      formdata.append("priority", priority);


      formdata.append("type", 2);

      if (full_img?.name) {
        formdata.append("image", full_img);
      }
      

      setBtn(true);
      dispatch(saveLoader(true));
      if (location?.state?.data?.id) {
        formdata.append("setting_id", location?.state?.data?.id);

        editVendorApi(formdata)
          .unwrap()
          .then((res) => {
            setBtn(false);
            dispatch(saveLoader(false));
            navigate("/vendor-list");
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });
      } else {
        addVendorApi(formdata)
          .unwrap()
          .then((res) => {
            setBtn(false);
            dispatch(saveLoader(false));
            navigate("/vendor-list");
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });
      }
    }
  };




  console.log(data);

  return (
    <div className="dashRightView p-5 home_section trans">
            {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <p
        className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-0 mb-3 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon /> Vendor List
        {location?.state?.type == "view"
          ? " View"
          : location?.state?.type == "edit"
            ? " Edit"
            : " Add"}{" "}
      </p>
      <div className="w-100 searchField mt-0 bg-white ">
        <div className="w-100 d-flex flex-xxl-row flex-xl-row flex-lg-row flex-column">
          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Image:<span style={{color:"#f82a2a"}}>(70px * 100px)</span>{type == "add" && type !== "view" && <span>*</span>}
              </p>
              <div className="w-50 " >
                <div className="w-50">
                {type !== "view" && <input type="file" className="mb-2" onChange={(e)=>setFullImg(e.target.files[0])} />}
                  <img
                    src={full_img?.name ? URL.createObjectURL(full_img) : full_img ? full_img : noLogo}
                    className="imgDashboard"
                    disabled={type == "view" ? true : false}
                  />
                  {full_img?.length == 0 && fullImg_Err && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Upload Image</p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Priority Status:{type == "add" && type !== "view" && <span>*</span>}
              </p>

              <div className="w-50">
                <input
                  className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  type="number"
                  onChange={(e) => setPriority(e.target.value)}
                  value={priority}
                  disabled={type == "view" ? true : false}
                />
                {priority?.length == 0 && pririty_Err && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Priority Status</p>
                  </div>
                )}
              </div>
            </div>

            {/* <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Status
              </p>

              <select
                placeholder=""
                className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                name=""
                id=""
              >
                <option>Active</option>
                <option>De Active</option>
              </select>
            </div> */}
          </div>
        </div>
      </div>
      <div className="d-flex ac-jb">
        <button
          onClick={() => navigate(-1)}
          className="pointerView cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
        >
          Back
        </button>
        {type !== "view" && (
          <button
            className={`cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${btn && "opacity-50"
              }`}
            onClick={submitHandle}
            disabled={btn ? true : false}
          >
            submit
          </button>
        )}
      </div>
    </div>

  );
};

export default VendorListEdit;
