import React, { useState } from "react";
import NavbarHead from "../Sub_Layout/NavbarHead";
import EstimateEditComp from "../EstimateComp/EstimateEdit/EstimateEditComp";
import EstimateEditTableComp from "../EstimateComp/EstimateEdit/EstimateEditTableComp";
import { useNavigate } from "react-router-dom";
import CheckingPricePoppup from "../EstimateComp/Poppup/CheckingPricePoppup";
import ImageUpload from "../EstimateComp/Poppup/ImageUpload";
import { Close } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ImageView from "../EstimateComp/Poppup/ImageView";
import ImageCroper from "../Popup/ImageCroper";
import AddFilesPopup from "../Popup/AddFilesOverview";
import AddFilesPopupCopy from "../Popup/AddFiles";

const SalesOrderEditItemScreen = () => {
  const navigate = useNavigate();
  const [checkPricePoppup, setCheckPricePoppup] = useState(false);
  const [imageUpload, setImageUpload] = useState(false);
  const [dropDown, setDropDown] = useState(undefined);
  const [imageViewPoppup, setImageViewPoppup] = useState(undefined);
  const [AddFilesOverview, setAddFilesOverview] = useState(false);

  //   Croper
  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();

  console.log(croperImage, "img");
  const toggleImagePopup = () => {
    setCroper(!croper);
  };
  const [taxAdd, setTaxAdd] = useState([""]);

  const taxAddClick = () => {
    let temp = [...taxAdd];
    temp.push({ warn: "" });
    setTaxAdd(temp);
  };

  const taxRemove = (e, ind) => {
    let temp = [...taxAdd];
    temp.splice(ind, 1);
    setTaxAdd(temp);
  };

  const poppupAddressToggle = () => {
    setCheckPricePoppup(!checkPricePoppup);
  };
  const poppupImageToggle = () => {
    setImageUpload(!imageUpload);
  };
  const poppupImageView = () => {
    setImageViewPoppup(!imageViewPoppup);
  };
  const toggleShowPopups = () => {
    setAddFilesOverview(!AddFilesOverview);
  };
  return (
    <div className="dashRightView overview_pages edit_page_cont overflow-scroll ">
      {/* <NavbarHead /> */}
      {checkPricePoppup && (
        <CheckingPricePoppup poppupAddressToggle={poppupAddressToggle} />
      )}
      {imageUpload && (
        <ImageUpload
          toggleImagePopup={toggleImagePopup}
          poppupImageToggle={poppupImageToggle}
        />
      )}
      {/* {imageViewPoppup && <ImageUpload poppupImageView={poppupImageView} />} */}
      {/* {imageViewPoppup && (
        <AddFilesPopupCopy
          toggleShowPopup={toggleShowPopups}
          popupType={"addClient"}
        />
      )} */}
      {AddFilesOverview && (
        <AddFilesPopupCopy
          toggleShowPopup={toggleShowPopups}
          popupType={"addClient"}
          toggleImagePopup={toggleImagePopup}
        />
      )}
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}

      <div className="w-100 d-flex ac-jb">
        <div>
          <p className="f1 fs-15 fs-xs-16 fs-sm-18 fs-md-19 fs-lg-21 fs-xl-22 fs-xxl-23">
            Edit Item
          </p>
        </div>
        <div className="position-fixed close_position z-3">
          <button
            onClick={() => navigate("/sales-order")}
            className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-5 rounded"
          >
            Close
          </button>
        </div>
      </div>
      <EstimateEditComp
        toggleShowPopups={toggleShowPopups}
        // poppupImageView={poppupImageView}
      />
      <EstimateEditTableComp
        poppupAddressToggle={poppupAddressToggle}
        poppupImageToggle={poppupImageToggle}
      />
      <div className="d-flex as-jb flex-column flex-md-row gap-3 w-100">
        <div className="summary bg-soft-gray1 w-35 md_t_W">
          <div className="d-flex ac-jb py-3 px-1 px-md-3">
            <p className="">Margin</p>
            <p className="">₹0.00</p>
          </div>
          <div className="d-flex ac-jb px-1 px-md-3 py-3">
            <p className="">Margin Amount</p>
            <p className="">₹0.00</p>
          </div>
        </div>
        <div className="summary bg-soft-gray1 w-45 md_t_W ">
          <div className="d-flex ac-jb py-3 px-1 px-md-3">
            <p className="">Subtotal</p>
            <p className="">₹0.00</p>
          </div>
          <div className="d-flex ac-jb px-1 px-md-3 py-3">
            <p className="">Tax</p>
            <button
              className="pointerView cust-btn py-2 tranc cp"
              onClick={() => taxAddClick()}
            >
              <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                + Another
              </p>
            </button>
          </div>

          {taxAdd?.map((item, ind) => {
            return (
              <div className="d-flex ac-jb px-1 px-md-3">
                <div className="mx-2 d-flex ac-jc position-relative">
                  <div
                    className="mx-2 d-flex ac-jc position-relative hide_res2 cp"
                    style={{ width: "20px" }}
                    onClick={() => taxRemove(item, ind)}
                  >
                    <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" />
                  </div>
                  <div className="position-relative">
                    <input
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    />
                    <button
                      className="drop_down cust-btn"
                      onClick={() => setDropDown(8)}
                    >
                      <KeyboardArrowDownIcon className="primary" />
                    </button>
                    {dropDown === 8 && (
                      <div
                        className="invisible-cont2 z-0"
                        onClick={() => setDropDown(undefined)}
                      />
                    )}
                    <div
                      className={`${
                        dropDown === 8 && "submenu_1 h-auto"
                      } submenu_cont_1 overflow-scroll z-3`}
                    >
                      <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                        <button
                          onClick={() => setDropDown(undefined)}
                          className="px-2 cust-btn text-start py-1 w-100 "
                        >
                          GST (6%)(6%)
                        </button>
                      </button>
                      <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                        <button
                          onClick={() => setDropDown(undefined)}
                          className="px-2 cust-btn text-start py-1 w-100 "
                        >
                          (0%)
                        </button>
                      </button>
                      <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                        <button
                          onClick={() => setDropDown(undefined)}
                          className="px-2 cust-btn text-start py-1 w-100 "
                        >
                          CGST (6%)(6%)
                        </button>
                      </button>
                      <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                        <button
                          onClick={() => setDropDown(undefined)}
                          className="px-2 cust-btn text-start py-1 w-100 "
                        >
                          SGST (6%)(6%)
                        </button>
                      </button>
                    </div>
                  </div>
                </div>
                <p className="">₹0.00</p>
              </div>
            );
          })}
          <div className="d-flex ac-jb px-1 px-md-3 py-3">
            <p className="">Discount %</p>
            <p className="">₹10.00</p>
          </div>
          <div className="d-flex ac-jb px-1 px-md-3 py-3">
            <p className="">Total</p>
            <p className="">₹0.00</p>
          </div>
          {/* <div className="d-flex ac-jb px-1 px-md-3 py-3">
                        <div className="d-flex gap-2">
                            <input type="checkbox" />
                            <p className="">Hide Totals</p>
                        </div>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default SalesOrderEditItemScreen;
