import React, { useEffect, useRef, useState } from "react";
import {
  editIcon,
  toggleOff,
  toggleOn,
  viewIcon,
  searchIcon,
  uploadIcon,
} from "../assets/img";
import { decorativeMethodList, hsnListData } from "../redux/api/DummyJson";
import { Search } from "@mui/icons-material";
import CachedIcon from "@mui/icons-material/Cached";
import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";
import AddHsnPopup from "../components/Popup/AddHsnPopup";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
  useAdminExportsMutation,
  useDownloadHsnMutation,
  useDownloadSampleExcelsMutation,
  useHsnCreateMutation,
  useHsnEditMutation,
  useHsnListMutation,
  useLazyHsnStatusQuery,
  useLazyHsnViewQuery,
} from "../redux/api/api";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ErrorIcon from "@mui/icons-material/Error";
import UploadIcon from "@mui/icons-material/Upload";


const HsnListScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [list, setList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageRow, setPageRow] = useState(10);
  const [page, setPage] = useState(1);
  const [pagenation, setPagination] = useState({});

  // RTK QUERY
  const [hsnListApi] = useHsnListMutation();
  const [hsnCreateApi] = useHsnCreateMutation();
  const [hsnEditApi] = useHsnEditMutation();
  const [hsnViewApi] = useLazyHsnViewQuery();
  const [hsnStatusApi] = useLazyHsnStatusQuery();
  const [exportsApi] = useAdminExportsMutation();
  const [importApi] = useDownloadSampleExcelsMutation();
  const [uploadApi] = useDownloadHsnMutation();


  const fileRef = useRef();
  const [file, setFile] = useState("");
  console.log(file?.type);

  const [uploadImg, setUploadImg] = useState("");
  const [importList, setImportList] = useState(false);
  const showPopupHander = () => {
    setImportList(false);
  };
  const [role, setRole] = useState("");
  const [roleErr, setRoleErr] = useState(false);
  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState(false);



  const [hsnCode, setHsnCode] = useState("");
  const [sgst, setSgst] = useState("");
  const [cgst, seCgst] = useState("");
  const [igst, setIgst] = useState("");
  const [description, setDescription] = useState("");

  const [hsnCodeErr, setHsnCodeErr] = useState(false);
  const [sgstErr, setSgstErr] = useState(false);
  const [cgstErr, seCgstErr] = useState(false);
  const [igstErr, setIgstErr] = useState(false);
  const [descriptionErr, setDescriptionErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const [update, setUpdate] = useState({});
  const [errorStatus, setErrorStatus] = useState(false);

  const toggleShowPopup = (type) => {
    setShowPopup(!showPopup);
    if (type) setPopupType(type);
    setUpdate({});
    setDescription("");
    setIgst("");
    seCgst("");
    setSgst("");
    setHsnCode("");
    setHsnCodeErr(false);
    setErrorStatus(false);
  };

  const getList = (event, row) => {
    let formdata = new FormData();
    let params = `?page=${page}`;

    setSearchValue(event);

    if (event?.length > 0) {
      formdata.append("search", event);
    }

    if (row) {
      setPageRow(row);
      formdata.append("rows", row);
    }

    hsnListApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.lists);
          setPagination(res?.pagination_meta);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const change = (id) => {
    hsnStatusApi(id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          toast.success(res?.data?.message || res?.message);
          getList();
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  useEffect(() => {
    getList();
  }, [page]);

  const openHandler = (item, type) => {
    toggleShowPopup(type);
    setUpdate(item);
    setHsnCode(item?.code);
    setDescription(item?.description);
    if (Number.isInteger(item?.igst)) {
      setIgst(item?.igst);
    }

    if (Number.isInteger(item?.cgst) && Number.isInteger(item?.sgst)) {
      seCgst(item?.cgst);
      setSgst(item?.sgst);
    }
  };

  const submitHandle = () => {
    if (
      (sgst?.length == 0 && cgst?.length == 0 && igst?.length == 0) ||
      hsnCode?.length == 0

      // || description?.length == 0
    ) {
      setHsnCodeErr(true);
      setErrorStatus(true);
      // setDescriptionErr(true);
    } else {
      let count = 1;
      if (!sgst && !cgst && igst) {
        count = count + 1;
      }

      if (sgst && cgst && !igst) {
        count = count + 1;
      }

      if (count == 2) {
        let formdata = new FormData();
        formdata.append("code", hsnCode);
        if (sgst && cgst) {
          formdata.append("sgst", sgst);
          formdata.append("cgst", cgst);
        }

        if (igst) {
          formdata.append("igst", igst);
        }

        if (description) {
          formdata.append("description", description);
        }
        setBtn(true);
        dispatch(saveLoader(true));
        if (update?.id) {
          hsnEditApi({ payload: formdata, id: update?.id })
            .unwrap()
            .then((res) => {
              setBtn(false);
              dispatch(saveLoader(false));
              setShowPopup(false);
              setUpdate({});
              setDescription("");
              setIgst("");
              seCgst("");
              setSgst("");
              setHsnCode("");
              setHsnCodeErr(false);
              setErrorStatus(false);
              getList();
            })
            .catch((err) => {
              setBtn(false);
              dispatch(saveLoader(false));
              console.log("err", err);
            });
        } else {
          hsnCreateApi(formdata)
            .unwrap()
            .then((res) => {
              setBtn(false);
              dispatch(saveLoader(false));
              setShowPopup(false);
              setDescription("");
              setIgst("");
              seCgst("");
              setSgst("");
              setHsnCode("");
              setHsnCodeErr(false);
              setErrorStatus(false);
              getList();
            })
            .catch((err) => {
              setBtn(false);
              dispatch(saveLoader(false));
              console.log("err", err);
            });
        }
      }
    }
  };

       // EXPORT EXCEL
const getAdminExports = () => {
  let formdata = new FormData();
  formdata.append("type", 7);
  exportsApi(formdata)
    .unwrap()
    .then((res) => {
      if (res?.download_link) {
        const link = document.createElement("a");
        link.href = res?.download_link;
        link.setAttribute("download", "");
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch((err) => {
      console.log("err", err);
    });
};


  // console.log("errorStatus", errorStatus);


  // IMPORT EXCEL
 const getAdminImports = () => {
  let formdata = new FormData();
  formdata.append("type", 1);
  importApi(formdata)
    .unwrap()
    .then((res) => {
      if (res?.download_link) {
        const link = document.createElement("a");
        link.href = res?.download_link;
        link.setAttribute("download", "");
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
      }
      console.log("res", res)
    })
    .catch((err) => {
      console.log("err", err);
    });
};

// UPLOAD EXCEL

const uploadExcel = () => {
  if (image?.length == 0) {
    setImageErr(true);

  } else {
    let formdata = new FormData();
    if (image?.name) {

      formdata.append("file", image)
    }

    setBtn(true);
    dispatch(saveLoader(true));
    uploadApi(formdata)
      .unwrap()
      .then((res) => {
        setBtn(false);
        dispatch(saveLoader(false));
        console.log('reslist', res);
        setImage("")
        setImportList(false);
        setShowPopup(false);
      })
      .catch((err) => {
        setBtn(false);
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  }
}


  return (
    <>
    {importList && (
        <div className="modal-popup">
          <div onClick={showPopupHander} className="back-close" />
          <div className="center-content p-4">
            <div className=" d-flex justify-content-end">
              <span>
                <button onClick={() => { showPopupHander(); setImage("");setImageErr(""); }} className="cust-btn">
                  <HighlightOffIcon />
                </button>
              </span>
            </div>
            <div>
              <button
                className={`cust-btn addbtn  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded `
                }
                onClick={() => getAdminImports()}
              // disabled={page == pagenation?.last_page ? true : false}
              >
                Sample Download
              </button>
            </div>
            <p className="d-flex ac-jc f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Import Admin List
            </p>
            <div className="pointerView w-100 mt-4 ac-jc">
              <div
                onClick={() => fileRef.current.click()}
                className="w-100 d-flex ac-jc"
              >
                {file === "" ? (
                  <img src={uploadIcon} onChange={(e) => setImage(e.target.files[0])} className="mailImg" />
                ) : (
                  <iframe
                    onClick={() => fileRef.current.click()}
                    src={file}
                    className="importExcel"
                  />
                )}
              </div>
              {file === "" && (
                <div
                  onClick={() => {
                    fileRef.current.click();
                  }}
                  className="w-100 d-flex ac-jc"
                >
                  <p className="primary1 w-100 f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 mt-1 d-flex ac-jc">
                    +Import List
                  </p>
                </div>

              )}
              {image?.length == 0 && imageErr && (
                <div className="d-flex ac-jc gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Upload File</p>
                </div>
              )}
              <div className="d-flex ac-jc">
                <button
                  onClick={() => uploadExcel()}
                  disabled={btn}
                  className={`
                  cust-btn addbtn mt-sm-4 ms-1 mt-4 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${btn && "opacity-50"
                    }`}
                >
                  Submit
                </button>
              </div>
            </div>
            <input
              type="file"
              accept=".xlsx,"
              onChange={(e) => {
                setImage(e.target.files[0])
              }}
              className="d-none"
              ref={fileRef}
            />
          </div>
        </div>
      )}
      {showPopup && (
        <AddHsnPopup
          toggleShowPopup={toggleShowPopup}
          popupType={popupType}
          hsnCode={hsnCode}
          setHsnCode={setHsnCode}
          sgst={sgst}
          setSgst={setSgst}
          seCgst={seCgst}
          cgst={cgst}
          setIgst={setIgst}
          igst={igst}
          description={description}
          setDescription={setDescription}
          hsnCodeErr={hsnCodeErr}
          setHsnCodeErr={setHsnCodeErr}
          sgstErr={sgstErr}
          setSgstErr={setSgstErr}
          cgstErr={cgstErr}
          seCgstErr={seCgstErr}
          igstErr={igstErr}
          setIgstErr={setIgstErr}
          descriptionErr={descriptionErr}
          setDescriptionErr={setDescriptionErr}
          submitHandle={submitHandle}
          btn={btn}
          setUpdate={setUpdate}
          errorStatus={errorStatus}
        />
      )}
      <div className="dashRightView p-5 home_section trans">
        <div className="d-flex ac-jb mt-2 flex-wrap w-100">
          <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
            HSN Settings
          </p>
          {/* <div className="d-flex">
            <label>
              <div className="d-flex ms-3 pointerView ac-jb">
                <p className="f3 primary fs-16">Import</p>
                <ArrowCircleUpOutlinedIcon className="f4 fs-20 primary ms-1" />
                <input type="file" className="d-none" />
              </div>
            </label>
            <div className="d-flex ms-3 pointerView ac-jb">
              <p className="f3 primary fs-16">Sample Download</p>
              <ArrowCircleDownOutlinedIcon className="f4 fs-20 primary ms-1" />
            </div>
          </div> */}
        </div>
        <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
          <div className="d-flex">
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
              Show
            </p>
            <select
              className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary"
              onChange={(e) => getList(searchValue, e.target.value)}
              value={pageRow}
            >
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </select>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
              Entries
            </p>
          </div>
          <div className="d-flex flex-md-row flex-column">
            {/* <div className="d-flex mt-sm-3">
          <img src={searchIcon} className="searchiConImg" alt="icon" />
          <input
            className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
            placeholder="Search"
          />
        </div> */}
            <div className="d-flex ac-jb flex-md-row flex-column">
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont me-3 mt-4">
                Total Count : {pagenation?.total}
              </p>
              <div
            onClick={() => setImportList(!importList)}
            className="pointerView d-flex mt-4 me-3"
          >
            <UploadIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Import
            </p>
          </div>
              <div className="pointerView d-flex mt-4 me-3" role={"button"} onClick={getAdminExports}>
                <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
                <p
                  className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
                >
                  Export
                </p>
              </div>
              <div className="d-flex mt-sm-3 border-search mx-1">
                <img src={searchIcon} alt="search" className="searchiConImg" />
                <input
                  className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                  placeholder="Search"
                  onChange={(e) => getList(e.target.value, pageRow)}
                  value={searchValue}
                />
              </div>
              <button
                className="cust-btn addbtn1 mt-3 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-3 rounded"
                onClick={() => getList("", 10)}
              >
                <CachedIcon />
              </button>
              <button
                onClick={() => toggleShowPopup("add")}
                className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
              >
                + Add HSN Setting
              </button>
            </div>
          </div>
        </div>

        <div className="mt-5" style={{ width: "100%", overflow: "auto" }}>
          <table className="table-design-border " style={{ width: "100%"}}>
            <thead>
              <tr className="border-bottom w-100">
                <th>
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    S.No
                  </p>
                </th>
                <th>
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    HSN Code
                  </p>
                </th>
                <th>
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Description
                  </p>
                </th>
                <th>
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    GST
                  </p>
                </th>
                <th>
                  {" "}
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Status
                  </p>
                </th>
                <th>
                  {" "}
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Action
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item, index) => {
                return (
                  <tr>
                    <td>
                      <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                        {index + 1}
                      </p>
                    </td>
                    <td>
                      <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                        {item?.code}
                      </p>
                    </td>
                    <td>
                      <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                        {item?.description}
                      </p>
                    </td>
                    <td>
                      <div className="d-flex flex-column mt-1">
                        {item?.sgst && item?.cgst ? (
                          <>
                            <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc gap-1 primary text-capitalize">
                              SGST:{" "}
                              <span className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                                {item?.sgst}
                              </span>
                            </p>
                            <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc gap-1 primary text-capitalize">
                              CGST:{" "}
                              <span className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                                {item?.cgst}
                              </span>
                            </p>
                            <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc gap-1 primary text-capitalize">
                              IGST:{" "}
                              <span className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                                {+item?.sgst + +item?.cgst}
                              </span>
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc gap-1 primary text-capitalize">
                              SGST:{" "}
                              <span className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                                {+item?.igst / 2}
                              </span>
                            </p>
                            <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc gap-1 primary text-capitalize">
                              CGST:{" "}
                              <span className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                                {+item?.igst / 2}
                              </span>
                            </p>
                            <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc gap-1 primary text-capitalize">
                              IGST:{" "}
                              <span className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                                {item?.igst}
                              </span>
                            </p>
                          </>
                        )}

                        {/* {item?.sgst && (
                          <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc gap-1 primary text-capitalize">
                            SGST:{" "}
                            <span className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                              {item?.sgst}
                            </span>
                          </p>
                        )}
                        {item?.cgst && (
                          <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc gap-1 primary text-capitalize">
                            CGST:{" "}
                            <span className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                              {item?.cgst}
                            </span>
                          </p>
                        )}
                        {item?.igst && (
                          <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc gap-1 primary text-capitalize">
                            IGST:{" "}
                            <span className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                              {item?.igst}
                            </span>
                          </p>
                        )} */}
                      </div>
                    </td>
                    <td>
                      <div
                        className="d-flex ac-jc"
                        onClick={() => change(item?.id)}
                      >
                        {item?.status === 1 ? (
                          <div>
                            <img src={toggleOn} className="toggleOnDes" />
                          </div>
                        ) : (
                          <div>
                            <img src={toggleOff} className="toggleOnDes" />
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex ac-jc primary1">
                        <div
                          className="viewBoxDes p-2 mb-2 bg-white rounded-2"
                          onClick={() => openHandler(item, "view")}
                        >
                          <img src={viewIcon} className="viewDes" />
                        </div>
                        <div
                          className="viewBoxDes ms-2 p-2 mb-2 bg-white rounded-2"
                          onClick={() => openHandler(item, "edit")}
                        >
                          <img src={editIcon} className="viewDes" />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <button
            className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
              page == 1 && "opacity-50"
            }`}
            onClick={() => setPage(page - 1)}
            disabled={page == 1 ? true : false}
          >
            Previous
          </button>
          <button
            className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
              page == pagenation?.last_page && "opacity-50"
            }`}
            onClick={() => setPage(page + 1)}
            disabled={page == pagenation?.last_page ? true : false}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default HsnListScreen;
