import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { sub_menu, event_category } from "../../../redux/api/DummyJson";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FormComp from "./FormComp";
import ImageUplodeComp from "./ImageUplodeComp";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import {
  useCategoryProductViewNewMutation,
  useFilterBySkuMutation,
  useLazyCategoriesQuery,
  useLazyCategoryActiveViewQuery,
  useLazyCategoryProductViewQuery,
  useLazyNewCategoryViewQuery,
} from "../../../redux/api/api";

const Product1Comp = ({
  skuCodeList,
  showComp,
  setSelprod,
  ind,
  evenCategoryList,
  setEventCategory,
  eventCategory,
  setProducts,
  products,
  onProductSelect,
  setEventCategoryID,
  eventCategoryID,
  setCategoryID,
  categoryID,
  categoryIDErr,
  eventCategoryIDErr,
  productsErr,
  productID,
  setProductID,
  setProds,
  prods,
  eventCategoryName,
  setEventCategoryName,
  productName,
  setProductName,
  setSku,
  sku,
  skuErr,
  setSkuErr,
  setSkuID,
  skuID,
  viewData,
}) => {
  // console.log("ind", ind);
  const navigate = useNavigate();
  const location = useLocation();

  // Toggle List
  const [dropDown, setDropDown] = useState(null);
  const [searchValueSub, setSearchValueSub] = useState("");
  const [searchValuePro, setSearchValuePro] = useState("");
  const [eventCategoryLists, setEventCategoryLists] = useState([]);
  const [selectALLSub, setSelectALLSub] = useState(true);
  const [eventCategoryErr, setEventCategoryErr] = useState(false);
  const [eventCategoryIDs, setEventCategoryIDs] = useState([]);
  const [searchValueCategory, setSearchValueCategory] = useState("");
  const [searchValueSubCategory, setSearchSubValueCategory] = useState({});
  const [parentCategory, setParentCategory] = useState("");
  const [catogoryName, setCatogoryName] = useState({});
  const [catogory, setCatogory] = useState("");
  const [catogoryShow2, setCatogoryShow2] = useState(null);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCategoryLists, setSubCategoryLists] = useState([]);
  const [subcatogory, setSubcatogory] = useState(false);
  const [list, setList] = useState([]);
  const [lists, setLists] = useState([]);
  const [category_id, setCategory_id] = useState("");
  const [productList, setProductList] = useState([]);
  const [productLists, setProductLists] = useState([]);
  const [productIDs, setProductIDs] = useState([]);
  const [skucode, setSkuCode] = useState([]);
  const [skuCodeName, setCodeName] = useState("");
  const [skuSearch, setSearch] = useState("");
  const [skuid, setSkuid] = useState("");
  const [thridCategoryId, setThridCategoryId] = useState("");
  const [secondaryCategoryId, setSecondaryCategoryId] = useState("");

  const [loading, setLoading] = useState(false);

  // RTK QUERY
  // const [viewCategoryApi] = useLazyCategoryProductViewQuery();
  const [viewCategoryApi] = useLazyNewCategoryViewQuery();
  const [viewCategory] = useCategoryProductViewNewMutation();
  const [listCategory] = useLazyCategoriesQuery();
  const [filterBySkuApi] = useFilterBySkuMutation();

  const getList = () => {
    listCategory()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.categories);
          setLists(res?.categories);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (evenCategoryList?.length > 0) {
      setEventCategoryLists(evenCategoryList);
    }
  }, [evenCategoryList]);

  // input box
  // const [sku, setSku] = useState("");
  const [vendorProductCode, setVendorProductCode] = useState("");
  const [count, setCount] = useState(1);

  const onBackPress = () => {
    setSelprod(ind);
  };

  const allSelectSub = () => {
    let categoryListId = [];
    let categoryListName = [];
    if (selectALLSub) {
      evenCategoryList?.map((item, ind) => {
        categoryListId.push(item?.id);
        categoryListName.push(item?.name);
        setEventCategory(item?.id);
      });

      setEventCategoryIDs(categoryListId);
      setEventCategoryName(categoryListName);
    } else {
      setEventCategoryIDs(categoryListId);
      setEventCategoryName(categoryListName);
    }
  };

  const searchSub = (event) => {
    let temp = [...evenCategoryList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setEventCategoryLists(search);
    } else {
      setEventCategoryLists(evenCategoryList);
    }

    setSearchValueSub(event);
  };

  const onSubSelect = (event, id, type) => {
    setEventCategoryName(event);
    setEventCategoryIDs(id);
    setEventCategoryID(id);
    setDropDown(null);
    setSearchValueSub("");
    setEventCategoryLists(evenCategoryList);
    setEventCategory(id);
  };
  const searchSku = (event) => {
    let temp = [...skuCodeList];
    let search = temp?.filter((value) =>
      value?.variant_sku?.toLowerCase()?.includes(event?.toLowerCase())
    );
    if (event?.length > 0) {
      setSkuCode(search);
    } else {
      setSkuCode(skuCodeList);
    }
    setSearch(event);
  };

  const onSelectSku = (event, id, type, item) => {
    setCodeName(event);
    setSku((val) => ({ ...val, [ind]: item }));
    setSearch("");
    setSkuid(id);
    setDropDown(null);
    getSkuProduct(event);
    setSkuID(id);
  };

  const searchPro = (event) => {
    let temp = [...productLists];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setProductList(search);
    } else {
      setProductList(productLists);
    }

    setSearchValuePro(event);
  };

  const onProSelect = (event, id, type, item) => {
    let categorys = [];
    let subCategoryListArr = [];
    let cat = {};

    // console.log("data", data);

    let datas = item?.categories ? item?.categories : item?.category;
    let category_name = item?.categories ? "categories" : "category";

    // CATEGORY
    datas?.parentCategory?.map((item, ind) => {
      if (item?.icon) {
        cat.status = item?.status;
        cat.icon = item?.icon;
        cat.id = item?.id;
        cat.name = item?.name;
        setCatogory(item?.name);
      } else {
        categorys.push(item);
      }
    });

    categorys.push({
      icon: datas?.icon,
      id: datas?.id,
      name: datas?.name,
      status: datas?.status,
    });

    categorys?.map((item, ind) => {
      let obj = { ...item };
      if (ind == 0) {
        obj[category_name] = cat;
      } else {
        obj[category_name] = categorys[ind - 1];
      }
      subCategoryListArr.push(obj);
    });

    setSubCategoryList(subCategoryListArr);
    if (subCategoryListArr?.length > 0) {
      setSubcatogory(true);
    }

    subCategoryListArr?.map((item, ind) => {
      setCatogoryName((val) => ({
        ...val,
        [item[category_name]?.id]: item?.name,
      }));
    });

    onProductSelect(item, ind);
    // setProducts((val) => ({ ...val, [ind]: id }));
    setProductName(event);
    setProductIDs(id);
    setDropDown(null);
    setSearchValuePro("");
    setProductList(productLists);
    setProds((val) => ({ ...val, [ind]: item }));
    setSkuCode(item?.product_parent_skus);
  };

  // SEARCH CATEGROY
  const searchCategory = (event) => {
    let temp = [...lists];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setList(search);
    } else {
      setList(lists);
    }

    setSearchValueCategory(event);
  };

  // SELECT CATEGORY
  const onCategorySelct = (item) => {
    if (ind == 0) {
      setProds({});
      setSku({});
      setSubCategoryList([]);
      setSubCategoryLists([]);
      setProductID("");
      setSearchValueCategory("");
      setProductName([]);
    }
    setCategoryID(item?.id);
    setCatogory(item?.name);
    setSubcatogory(true);
    getCategoryId(item?.id, "category");
    setList(lists);
    setDropDown(null);
    setSkuCode(skuCodeList);
    // setParentCategory(item?.id);
  };

  // GET PARENT ID FOR SUB CATEGORY LIST
  const getCategoryId = (id, type, indx, index, data) => {
    if (type == "category") {
      setCategory_id(id);
      setParentCategory(id);
      getView(id, "", "", type, "");
    } else {
      // console.log("lll");
      if (index == 0) {
        setCategory_id(id);
        // console.log("kkkkk");
      }
      onSubCatSelect(id, indx, index, data);
    }
    setProductName([]);

    setProds((val) => ({ ...val, [ind]: {} }));
    setProductID("");
  };

  // SLICE ARRAY
  const onSubCatSelect = (id, ind, index, data) => {
    if (index < data?.length - 1) {
      let temp = [...data];
      let slicer = temp.slice(0, index + 1);
      setSubCategoryList(slicer);

      let temps = [...subCategoryLists];
      let slicers = temps.slice(0, index + 1);
      setSubCategoryLists(slicers);

      getView(id, ind, index, "sub_category", slicer);
    } else {
      getView(id, ind, index, "", data);
    }
  };

  // CHILD CATEGORY VIEW API AND 2D DIMENTION ARRAY CREATE
  const getView = (id, inx, index, type, data) => {
    setLoading(true);
    viewCategoryApi(id)
      .unwrap()
      .then((res) => {
        let child = [];
        res?.child_categories?.map((item, ind) => {
          child.push({
            ...item,
            category: {
              ...res?.category,
            },
          });
        });

        let responce = {
          category: res?.category,
          child_categories: child,
        };

        let temp = [];
        let temps = [];
        if (data?.length > 0) {
          temp = [...data];
          temps = [...data];
        } else if (type !== "category") {
          temp = [...subCategoryList];
          temps = [...subCategoryLists];
        }

        if (type == "subSumit") {
          let temX = responce?.child_categories;

          let obj = {
            ...temp[index],
            child_categories: temX,
          };

          let final = subCategoryList?.filter((i) => i?.category?.id !== id);
          final.splice(index, 0, obj);

          let finals = subCategoryLists?.filter((i) => i?.category?.id !== id);
          finals.splice(index, 0, obj);

          setSubCategoryList(final);
          setSubCategoryLists(finals);

          let productsArr = [];

          finals?.map((item, ind) => {
            item?.child_categories?.map((chi, indx) => {
              chi?.product?.map((pro, inx) => {
                productsArr.push(pro);
              });
            });
          });
          // console.log("finals", finals);
          setProductLists(productsArr);
          setProductList(productsArr);

          setLoading(false);
        } else {
          let finder = temp.indexOf(id);

          if (temp?.includes(responce)) {
            temp.slice(0, 1);
            temp.push(responce);
          } else {
            temp.push(responce);
          }

          if (temps?.includes(responce)) {
            temps.slice(0, 1);
            temps.push(responce);
          } else {
            temps.push(responce);
          }

          setSubCategoryList(temp);
          setSubCategoryLists(temps);

          let productsArr = [];

          temps?.map((item, ind) => {
            item?.child_categories?.map((chi, indx) => {
              chi?.product?.map((pro, inx) => {
                productsArr.push(pro);
              });
            });
          });
          console.log("temps", temps);
          setProductLists(productsArr);
          setProductList(productsArr);
          setLoading(false);
          getPro(id);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
    setProductName([]);

    setProds((val) => ({ ...val, [ind]: {} }));
    setProductID("");
  };

  const getPro = (id) => {
    // console.log("id", id);
    if (categoryID && secondaryCategoryId) {
      getProducts(categoryID, secondaryCategoryId, id);
    }
  };

  const getProducts = (primary_category_id, secondary_category_id, id) => {
    let formdata = new FormData();
    formdata.append("primary_category_id", primary_category_id);
    formdata.append("secondary_category_id", secondary_category_id);
    viewCategory({ id: id, payload: formdata })
      .unwrap()
      .then((res) => {
        setProductLists(res?.products);
        setProductList(res?.products);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // SUB CATEGORY SEARCH
  const searchSubCatFun = (event, ind) => {
    let temp = [...subCategoryLists];
    let tempX = temp[ind];
    let temps = [...subCategoryList];
    let tempXs = temps[ind];
    let search = tempX?.child_categories?.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    // console.log("search", search);

    tempXs = search;

    let obj = {
      ...subCategoryList[ind],
      child_categories: tempXs,
    };

    let arr = [...subCategoryList];
    arr[ind] = obj;

    // console.log("tempXs", obj, arr);

    if (event?.length > 0) {
      setSubCategoryList(arr);
    } else {
      // console.log("jjj");
      setSubCategoryList(subCategoryLists);
    }

    setSearchSubValueCategory((val) => ({ ...val, [ind]: event }));
  };

  // SELECT CHILD CATEGORY
  const onSubCategorySelect = (subItem, indx, inx) => {
    setSearchSubValueCategory((val) => ({ ...val, [inx]: "" }));
    let temp = [...subCategoryList];
    // console.log("temp[inx]", temp[inx], subCategoryLists[inx]);
    temp[inx] = subCategoryLists[inx];
    // console.log("temp", temp);
    setSubCategoryList(temp);
    setCatogoryShow2(null);
    getCategoryId(subItem?.id, "sub_category", indx, inx, temp);
    setCatogoryName((val) => ({
      ...val,
      [subItem?.category?.id]: subItem?.name,
    }));

    setProductName([]);

    if (inx == 0) {
      setSecondaryCategoryId(subItem?.id);
    }
    setProds((val) => ({ ...val, [ind]: {} }));
    setProductID("");
  };

  // console.log("productList", productList);

  const getSkuProduct = (event) => {
    let formdata = new FormData();
    formdata.append("variant_sku", event);
    if (event?.length > 0) {
      filterBySkuApi(formdata)
        .unwrap()
        .then((res) => {
          let temp = [];
          temp.push(res?.product);
          setProductList(temp);
          setProductLists(temp);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  useEffect(() => {
    setSkuCode(skuCodeList);
  }, [skuCodeList]);

  const getViewBack = (datas) => {
    try {
      let categorys = [];
      let subCategoryListArr = [];
      let cat = {};

      // console.log("datas", datas);
      let data;
      if (location?.state?.type == "edit" && datas?.category?.id) {
        data = "category";
      } else {
        data = "categories";
      }

      // console.log("data", data);

      // CATEGORY
      datas?.[data]?.parentCategory?.map((item, ind) => {
        if (item?.icon) {
          cat.status = item?.status;
          cat.icon = item?.icon;
          cat.id = item?.id;
          cat.name = item?.name;
          setCatogory(item?.name);
        } else {
          categorys.push(item);
        }
      });

      categorys.push({
        icon: datas?.[data]?.icon,
        id: datas?.[data]?.id,
        name: datas?.[data]?.name,
        status: datas?.[data]?.status,
      });

      categorys?.map((item, ind) => {
        let obj = { ...item };
        if (ind == 0) {
          obj[data] = cat;
        } else {
          obj[data] = categorys[ind - 1];
        }
        subCategoryListArr.push(obj);
      });

      setSubCategoryList(subCategoryListArr);
      if (subCategoryListArr?.length > 0) {
        setSubcatogory(true);
      }

      subCategoryListArr?.map((item, ind) => {
        setCatogoryName((val) => ({
          ...val,
          [item?.[data]?.id]: item?.name,
        }));
      });
    } catch {
      console.log("err");
    }
  };

  // console.log("catogoryName", location?.state?.data);

  useEffect(() => {
    if (location?.state?.data?.event_id) {
      getViewBack(location?.state?.data?.prods?.[ind]);
    }
  }, [location]);

  useEffect(() => {
    if (viewData?.id) {
      getViewBack(viewData?.preset_product?.[ind]?.product);
      setSkuCode(viewData?.preset_product?.[ind]?.product?.product_parent_skus);
    }
  }, [viewData]);

  // console.log("viewData", viewData);
  return (
    <div
      className={`${
        showComp ? "d-flex" : "d-none"
      }  as-jb w-100 mt-4 res-flex gap-5 `}
    >
      <div className="w-100">
        <div className="d-flex flex-wrap ac-jb flex-m-r">
          {ind === 0 ? (
            <>
              <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                <p className="text-dark f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Event Category
                </p>
                <div className="position-relative">
                  <input
                    placeholder="Event Category"
                    type="text"
                    value={
                      eventCategoryName
                        ? eventCategoryName
                        : "Select Event Category"
                    }
                    onClick={() => {
                      setDropDown(0);
                    }}
                    className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                  <button
                    className="drop_down cust-btn"
                    onClick={() => {
                      setDropDown(0);
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                  {dropDown == 0 && (
                    <div
                      className="invisible-cont2 z-0"
                      onClick={() => {
                        setDropDown(null);
                      }}
                    />
                  )}
                  <div
                    className={`${
                      dropDown == 0 && "submenu_1 py-2"
                    } submenu_cont_1 overflow-scroll z-3`}
                  >
                    <div className="d-flex gap-1">
                      <input
                        className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                        placeholder="Search..."
                        onChange={(e) => searchSub(e.target.value)}
                        value={searchValueSub}
                      />
                    </div>
                    {eventCategoryLists?.map((item, ind) => {
                      return (
                        <button
                          className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                          key={ind}
                        >
                          <button
                            className="px-2 cust-btn text-start py-1 w-100 "
                            onClick={() => {
                              onSubSelect(item?.name, item?.id);
                            }}
                          >
                            {item?.name}
                          </button>
                        </button>
                      );
                    })}
                  </div>
                </div>

                {eventCategoryID?.length == 0 && eventCategoryIDErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Select Event Category</p>
                  </div>
                )}
              </div>
            </>
          ) : null}

          <>
            <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Category
              </p>
              <div className="position-relative">
                <input
                  placeholder="Catogory"
                  type="text"
                  value={
                    catogory?.length > 0
                      ? catogory?.toString()
                      : "Select Catogory"
                  }
                  onClick={() => {
                    setDropDown(1);
                  }}
                  className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  disabled={location?.state?.type == "view" ? true : false}
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setDropDown(1);
                  }}
                  disabled={location?.state?.type == "view" ? true : false}
                >
                  <KeyboardArrowDownIcon />
                </button>
                {dropDown == 1 && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setDropDown(null)}
                  />
                )}
                <div
                  className={`${
                    dropDown == 1 && "submenu_1 py-2"
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  <div className="d-flex gap-1">
                    <input
                      className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                      placeholder="Search..."
                      onChange={(e) => searchCategory(e.target.value)}
                      value={searchValueCategory}
                    />
                  </div>
                  {list?.map((item, ind) => {
                    return (
                      <button
                        className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                        onClick={() => {
                          onCategorySelct(item);
                        }}
                        key={ind}
                      >
                        <button className="px-2 cust-btn text-start py-1 w-100 ">
                          {item?.name}
                        </button>
                      </button>
                    );
                  })}
                </div>
              </div>
              {categoryID?.length == 0 && categoryIDErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Category</p>
                </div>
              )}
            </div>
          </>
          {subcatogory && (
            <>
              {subCategoryList?.map((item, ind) => {
                return (
                  <div
                    className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative"
                    key={ind}
                  >
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                      {item?.categories
                        ? item?.categories?.name
                        : item?.category?.name}
                    </p>
                    <div className="position-relative">
                      <input
                        placeholder="Required Field"
                        className={`editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust ${
                          ind !== 0 && "opacity-75"
                        }`}
                        value={
                          catogoryName?.[
                            item?.categories
                              ? item?.categories?.id
                              : item?.category?.id
                          ]
                        }
                        // onChange={onCatogory2}
                        onClick={() => setCatogoryShow2(ind)}
                        readOnly
                      />
                      <button
                        className="drop_down cust-btn"
                        onClick={() => {
                          setCatogoryShow2(ind);
                          setDropDown(null);
                        }}
                      >
                        <KeyboardArrowDownIcon />
                      </button>
                    </div>
                    {catogoryShow2 == ind && (
                      <div
                        className="invisible-cont2 z-0"
                        onClick={() => setCatogoryShow2(null)}
                      />
                    )}
                    <div
                      className={`${
                        catogoryShow2 == ind && "submenu_1"
                      } submenu_cont_1 overflow-scroll z-3`}
                    >
                      <div className="d-flex gap-1">
                        <input
                          className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                          placeholder="Search..."
                          onChange={(e) => searchSubCatFun(e.target.value, ind)}
                          value={searchValueSubCategory[ind]}
                        />
                      </div>
                      {item?.child_categories?.map((subItem, indx) => {
                        return (
                          <div className="d-flex hover-cust" key={indx}>
                            <button
                              className={`px-2 cust-btn text-start py-1 w-100 `}
                              onClick={() => {
                                onSubCategorySelect(subItem, indx, ind);
                              }}
                            >
                              {subItem?.name}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </>
          )}

          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="text-dark f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Product Name
            </p>
            <div className="position-relative">
              <input
                placeholder="Product Name"
                type="text"
                value={
                  prods?.[ind]?.name
                    ? prods?.[ind]?.name
                    : "Select Product Name"
                }
                onClick={() => {
                  setDropDown(2);
                }}
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(2);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 2 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => {
                    setDropDown(null);
                    setCatogoryShow2(null);
                  }}
                />
              )}
              <div
                className={`${
                  dropDown == 2 && "submenu_1 py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchPro(e.target.value)}
                    value={searchValuePro}
                  />
                </div>
                {productList?.map((item, inx) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      key={inx}
                    >
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          onProSelect(item?.name, item?.id, "", item);
                        }}
                      >
                        {item?.name}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>

            {productID?.length == 0 && productsErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select Product</p>
              </div>
            )}
          </div>

          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              SKU Code
            </p>
            <div className="position-relative">
              <input
                type="text"
                // placeholder="Required Field"
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={
                  sku?.[ind]?.variant_sku
                    ? sku?.[ind]?.variant_sku
                    : "Select SKU Code"
                }
                // onChange={(e) => {
                //   getSkuProduct(e.target.value);
                //   setSku(e.target.value);
                // }}
                onClick={() => {
                  setDropDown(3);
                }}
                // disabled
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(3);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 3 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => {
                    setDropDown(null);
                    // setCatogoryShow2(null);
                  }}
                />
              )}
              <div
                className={`${
                  dropDown == 3 && "submenu_1 py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchSku(e.target.value)}
                    value={skuSearch}
                  />
                </div>
                {skucode?.map((item, inx) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      key={inx}
                    >
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          onSelectSku(item?.variant_sku, item?.id, "", item);
                        }}
                      >
                        {item?.variant_sku}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
            {skuID?.length == 0 && skuErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select SKU Code</p>
              </div>
            )}
          </div>
        </div>
        {ind === 0 ? null : (
          <div className="mt-5">
            <p
              onClick={onBackPress}
              className="primary f2 pointer fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1"
            >
              <ArrowBackIcon />
              Prev
            </p>
          </div>
        )}
      </div>
      <ImageUplodeComp prods={prods} ind={ind} />
    </div>
  );
};

export default Product1Comp;
