import React, { useEffect, useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import { noLogo, product, searchIcon } from "../../assets/img";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Filter,
  Filter1Outlined,
  FilterAlt,
  Search,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useRequestListMutation } from "../../redux/api/api";

const ProductRequest = ({ listCat }) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pagenation, setPagination] = useState({});
  const [pageRow, setPageRow] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [list, setList] = useState([]);
  const [statusValue, setStatusValue] = useState("");
  const [catValue, setCatValue] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  //RTK
  const [requestListApi] = useRequestListMutation();

  const getRequestList = (event, row, status, category) => {
    let formdata = new FormData();
    let params = `?page=${page}`;

    setSearchValue(event);

    if (event?.length > 0) {
      formdata.append("search", event);
    }

    if (row) {
      setPageRow(row);
      formdata.append("rows", row);
    }

    if (status) {
      formdata.append("status", status);
    }

    setStatusValue(status);

    if (category) {
      formdata.append("category_id", category);
    }

    if (fromDate && toDate) {
      formdata.append("from_date", fromDate);
      formdata.append("to_date", toDate);
    }

    setCatValue(category);

    requestListApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.lists);
          setPagination(res?.pagination_meta);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getRequestList("", 12, "", "");
  }, [page, fromDate, toDate]);

  return (
    <>
      <div className="d-flex ac-jb flex-column flex-md-row">
        <div className="d-flex gap-3">
          <select
            className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3"
            onChange={(e) => getRequestList("", 12, "", e.target.value)}
            value={catValue}
          >
            <option value={""}>Select Categories</option>
            {listCat?.map((item, ind) => {
              return (
                <option key={ind} value={item?.id}>
                  {item?.name}
                </option>
              );
            })}
          </select>
          <select
            className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3"
            onChange={(e) => getRequestList("", 12, e.target.value, "")}
            value={statusValue}
          >
            <option value={""}>Select Status</option>
            <option value={"1"}>Requested </option>
            <option value={"2"}>Inprogress</option>
            <option value={"3"}>Accepted</option>
            <option value={"4"}>Rejected</option>
          </select>
        </div>
        <div className="d-flex ac-jb flex-column flex-md-row gap-3 justify-content-md-end">
          <div className="d-flex border-search">
            <img src={searchIcon} alt="img" className="searchiConImg mt-1" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
              onChange={(e) => getRequestList(e.target.value, 12, "", "")}
              value={searchValue}
            />
          </div>
          <div className="d-flex ac-jc gap-3">
            <button
              className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
              onClick={(e) => {
                getRequestList("", "", "", "");
                setFromDate("");
                setToDate("");
                setPage(1);
              }}
            >
              <CachedIcon />
            </button>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-4 flex-wrap">
        <div className="d-flex ac-jb w-xs-100 mt-xs-5 position-relative">
          <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary me-3">
            From
          </p>
          <input
            type="date"
            id="from"
            name="from"
            className="cust-btn searchFill w-xs-100 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
            onChange={(e) => setFromDate(e.target.value)}
            value={fromDate}
          />
          <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mx-3">
            To
          </p>
          <input
            type="date"
            id="from"
            name="from"
            className="cust-btn searchFill w-xs-100 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
            onChange={(e) => setToDate(e.target.value)}
            value={toDate}
          />
        </div>
      </div>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select
            className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary"
            onChange={(e) => getRequestList("", e.target.value)}
            value={pageRow}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
      </div>
      <div className="w-100 d-flex ac-js mt-4 flex-wrap">
        {Array.isArray(list) &&
          list?.map((item, ind) => {
            return (
              <div
                className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 cp position-relative"
                onClick={() => {
                  // setPopView(!popView)
                  // setProductId(item?.id)
                  // getView(item?.id)
                  navigate("/request-detail-view", {
                    state: { status: "accept", item: item, type: "edit" },
                  });
                }}
                key={ind}
              >
                <div className="prod-list-box">
                  <div className="cust-btn d-flex ac-jc rounded-3">
                    <img
                      src={
                        item?.image_urls?.product_images?.[
                          item?.image_urls?.product_images?.length - 1
                        ]
                          ? item?.image_urls?.product_images?.[
                              item?.image_urls?.product_images?.length - 1
                            ]
                          : noLogo
                      }
                      alt=""
                      className="custom-w-h rounded-3"
                    />
                  </div>
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 mt-3 text-dark text-custom-res">
                    {item?.vendor?.legal_name}
                  </p>
                  <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13  text-primary parag mb-2">
                    {item?.name}
                  </p>
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 primary1 text-custom-res">
                    {item?.category?.parentCategory?.[0]?.name}
                  </p>
                  <p>
                    {item?.status == 1 ? (
                      <p className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 request-text">
                        Request{" "}
                      </p>
                    ) : item?.status == 2 ? (
                      <p className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 Inprocess-text">
                        Inprogress
                      </p>
                    ) : item?.status == 3 ? (
                      <p className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 Accept-text">
                        Accepted
                      </p>
                    ) : item?.status == 4 ? (
                      <p className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 text-danger">
                        Rejected
                        <OverlayTrigger
                          delay={{ hide: 450, show: 300 }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              Product Rejected reason will show here
                            </Tooltip>
                          )}
                          placement="bottom"
                        >
                          <InfoOutlinedIcon
                            className="fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-1 parag mt-1"
                            style={{ color: "#06679c" }}
                          />
                        </OverlayTrigger>
                      </p>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
      <div className="d-flex justify-content-between mt-3">
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            page == 1 && "opacity-50"
          }`}
          onClick={() => setPage(page - 1)}
          disabled={page == 1 ? true : false}
        >
          Previous
        </button>
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            page == pagenation?.last_page && "opacity-50"
          }`}
          onClick={() => setPage(page + 1)}
          disabled={page == pagenation?.last_page ? true : false}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default ProductRequest;
