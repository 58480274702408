import React, { useEffect, useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import Product1Comp from "../components/OurProductComp/Product1/Product1Comp";
import Product2Comp from "../components/OurProductComp/Product2/Product2Comp";
import Product3Comp from "../components/OurProductComp/Product3/Product3Comp";
import Product4Comp from "../components/OurProductComp/Product4/Product4Comp";
import DoneIcon from "@mui/icons-material/Done";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { toggleOff, toggleOn } from "../assets/img";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useEventCategoryListMutation,
  useLazyPresetPackViewQuery,
  useLazySkuCodeQuery,
} from "../redux/api/api";
import ErrorIcon from "@mui/icons-material/Error";
import { toast } from "react-hot-toast";

const AddOurProductScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [totalProd, setTotalProd] = useState([1]);
  const [selProd, setSelprod] = useState(1);
  const [evenCategoryList, setEventCategoryList] = useState([]);
  const [eventCategory, setEventCategory] = useState([]);
  const [eventCategoryName, setEventCategoryName] = useState("");
  const [eventCategoryID, setEventCategoryID] = useState("");
  const [eventCategoryIDErr, setEventCategoryIDErr] = useState(false);
  const [categoryID, setCategoryID] = useState("");
  const [categoryIDErr, setCategoryIDErr] = useState(false);
  const [products, setProducts] = useState([]);
  const [prods, setProds] = useState({});
  const [sku, setSku] = useState({});
  const [productID, setProductID] = useState("");
  const [skuID, setSkuID] = useState("");
  const [productsErr, setProductsErr] = useState(false);
  const [skuErr, setSkuErr] = useState(false);
  const [productName, setProductName] = useState([]);
  const [viewData, setViewData] = useState({});
  const [skuCodeList, setSkuCode] = useState([]);

  // RTK QUERY
  const [eventCategoryListApi] = useEventCategoryListMutation();
  const [eventCategoryViewApi] = useLazyPresetPackViewQuery();
  const [skuCodeApi] = useLazySkuCodeQuery();

  const getView = () => {
    eventCategoryViewApi(location?.state?.data?.id)
      .unwrap()
      .then((res) => {
        let data = res?.preset_pack;
        setViewData(res?.preset_pack);
        setEventCategoryName(res?.preset_pack?.event_category?.name);
        setEventCategoryID(res?.preset_pack?.event_category?.id);
        let tempProduct = [];
        let tempPro = [];
        let tempTotalPro = [];

        data?.preset_product?.map((item, ind) => {
          tempProduct.push({
            id: item?.product?.id,
            name: item?.product?.name,
            stored_id: item?.id,
          });

          tempPro.push({
            ...item?.product,
            stored_id: item?.id,
          });

          tempTotalPro.push(ind);

          setSku((val) => ({ ...val, [ind]: item?.product_sku }));

          setCategoryID(item?.id);
          setProductID(item?.id);
        });
        setProducts(tempProduct);
        setProds(tempPro);
        setTotalProd(tempTotalPro);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const onAddProdClick = () => {
    let tmp = [...totalProd];

    let ids = Object.values(prods).map((item, ind) => item?.id);

    const duplicates = ids.filter((item, index) => ids.indexOf(item) !== index);

    if (duplicates?.length > 0) {
      toast.error("Same Products are Given");
    } else {
      tmp.push(1);
      setTotalProd([...tmp]);
      setSelprod(selProd + 1);
    }
  };

  const onRemoveProdClick = (num) => {
    // ARRAY
    let tmp = [...totalProd];
    tmp.splice(num, 1);
    setTotalProd([...tmp]);

    // OBJECT
    let obj = { ...prods };
    delete obj[num];
    setProds(obj);

    setSelprod(num);

    // ARRAY
    let tmpx = [...products];
    tmpx.splice(num, 1);
    setProducts(tmpx);
  };

  //SKU CODE LIST
  const getSkuCode = () => {
    skuCodeApi()
      .unwrap()
      .then((res) => {
        // console.log(res);
        setSkuCode(res?.lists);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getSkuCode();
  }, []);

  const getEventCategory = () => {
    eventCategoryListApi()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          let temp = res?.lists?.filter((i) => i.status == 1);
          setEventCategoryList(temp);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  useEffect(() => {
    getEventCategory();
    if (location?.state?.data?.id) {
      getView();
    }
  }, []);

  // console.log("location?.state?.data", location?.state?.data);

  useEffect(() => {
    if (location?.state?.data?.event_id) {
      let temp = [];
      let finder = evenCategoryList?.find(
        (i) => i?.id == location?.state?.data?.event_id
      );
      setEventCategoryName(finder?.name);
      setEventCategoryID(location?.state?.data?.event_id);
      setProds(location?.state?.data?.prods);
      setProducts(location?.state?.data?.project_id);
      setSku(location?.state?.data?.sku);
      setSkuID(location?.state?.data?.sku?.[0]?.id);
      for (let i = 0; i < location?.state?.data?.project_id?.length; i++) {
        temp.push(i);
      }
      setTotalProd(temp);
      let proName = [];
      location?.state?.data?.project_id?.map((item, ind) => {
        proName.push(item?.name);
        setCategoryID(item?.id);
        setProductID(item?.id);
      });
      setProductName(proName);
    }
  }, [evenCategoryList, location]);

  const onProductSelect = (item, ind) => {
    let temp = [...products];
    temp[ind] = {
      id: item?.id,
      name: item.name,
    };
    setProducts(temp);
    setProductID(item?.id);
  };

  // console.log("location?.state", location?.state);

  const submitHandler = () => {
    let check = false;

    let ids = Object.values(prods).map((item, ind) => item?.id);

    const duplicates = ids.filter((item, index) => ids.indexOf(item) !== index);

    if (duplicates?.length > 0) {
      check = true;
    }

    if (
      eventCategoryID?.length == 0 ||
      categoryID?.length == 0 ||
      productID?.length == 0 ||
      check == true
      // ||
      // skuID?.length == 0
    ) {
      setCategoryIDErr(true);
      setEventCategoryIDErr(true);
      setProductsErr(true);
      // setSkuErr(true);
      toast.error("Same Products are Given");
    } else {
      let obj = {
        project_id: products,
        event_id: eventCategoryID,
        prods: prods,
        sku: sku,
      };

      if (location?.state?.data?.id) {
        obj.data = viewData;
      }

      if (!location?.state?.data?.id && location?.state?.type == "edit") {
        obj.data = location?.state?.data?.data;
      }

      let final = {
        data: obj,
      };

      if (location?.state?.type == "edit") {
        final.type = location?.state?.type;
      }

      navigate("/add-pack-details", {
        state: final,
      });
    }
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      <ProductSearchHeader />

      <div className="d-flex ac-jb mt-4">
        <div className="d-flex ac-js w-80 overflow-scroll">
          {/* <DoneIcon className="white bg-green1 f2 radiusVendDesIcon fs-xs-28 fs-sm-28 fs-md-28 fs-lg-28 fs-xl-28 fs-xxl-28 mt-1 " /> */}

          {/* <div onClick={() => setSelprod(1)} className="d-flex ac-jc">
            <p
              className={`${
                selProd === 1 ? "bg-primar" : "bg-primary1"
              } white ms-3 f2 radiusVendDes rounded-5 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1`}>
              1
            </p>
            <p
              className={`${
                selProd === 1 ? "black" : "primary1"
              } f2 p-2 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-nowrap`}>
              Product 1
            </p>
          </div> */}

          <div className="d-flex ac-js mt-4">
            <p
              className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary d-flex align-items-center gap-1"
              role={"button"}
              onClick={() => navigate(-1)}
            >
              <KeyboardBackspaceIcon />
            </p>
            {totalProd?.map((it, ind) => {
              return (
                <>
                  {ind === 0 ? null : (
                    <div className="border-bottom w-5 border-primary1" />
                  )}
                  <div
                    role="button"
                    onClick={() => setSelprod(ind + 1)}
                    className="d-flex ac-jc"
                  >
                    <p
                      className={`${
                        selProd === ind + 1 ? "bg-primar" : "bg-primary1"
                      } white ms-3 f2 radiusVendDes rounded-5 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1`}
                    >
                      {ind + 1}
                    </p>
                    <p
                      className={`${
                        selProd === ind + 1 ? "black" : "primary1"
                      } f2 p-2 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-nowrap`}
                    >
                      Product {ind + 1}
                    </p>
                  </div>
                </>
              );
            })}{" "}
          </div>
        </div>

        <div className="d-flex ac-je w-20">
          <button
            onClick={onAddProdClick}
            className="cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            + Add another
          </button>
        </div>
      </div>

      <div className="d-flex ac-jb  mt-5">
        <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary d-flex ac-jc">
          Product {selProd}{" "}
          {selProd != 1 && (
            <button
              className="bg-transparent border-0"
              onClick={() => onRemoveProdClick(selProd - 1)}
            >
              <span className="m-0 p-0 d-flex">
                <img src={toggleOn} className="toggleOnDes mx-3" alt="icon" />
              </span>
            </button>
          )}
        </p>
      </div>

      {totalProd?.map((item, ind) => {
        // console.log(selProd, ind + 1);
        return (
          <Product1Comp
            skuCodeList={skuCodeList}
            ind={ind}
            setSelprod={setSelprod}
            showComp={selProd === ind + 1 ? true : false}
            evenCategoryList={evenCategoryList}
            setEventCategory={setEventCategory}
            eventCategory={eventCategory}
            setEventCategoryID={setEventCategoryID}
            eventCategoryID={eventCategoryID}
            setProducts={setProducts}
            products={products}
            setCategoryID={setCategoryID}
            categoryID={categoryID}
            categoryIDErr={categoryIDErr}
            eventCategoryIDErr={eventCategoryIDErr}
            onProductSelect={onProductSelect}
            productsErr={productsErr}
            productID={productID}
            skuErr={skuErr}
            setSkuErr={setSkuErr}
            setSkuID={setSkuID}
            skuID={skuID}
            setProds={setProds}
            prods={prods}
            setSku={setSku}
            sku={sku}
            setProductID={setProductID}
            eventCategoryName={eventCategoryName}
            setEventCategoryName={setEventCategoryName}
            productName={productName}
            setProductName={setProductName}
            viewData={viewData}
          />
        );
      })}
      <div className="d-flex ac-js mt-3">
        <button
          onClick={() => navigate(-1)}
          className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
        >
          Back
        </button>
        <button
          onClick={() => submitHandler()}
          className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default AddOurProductScreen;
