// @ts-nocheck
import { Delete } from "@mui/icons-material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { editIcon, toggleOff, toggleOn, viewIcon } from "../../assets/img";
import {
  Colours_list,
  Currency_list,
  customerDecoList,
  faq_list,
} from "../../redux/api/DummyJson";

const ColoursList = ({ list, change }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="mt-5" style={{ width: "100%", overflow: "auto" }}>
        <table className="table-design-new" style={{ width: "100%" }}>
          <thead>
            <tr className="border-bottom w-100">
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  S.No
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-js">
                  Colour Name
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-js">
                  Colour Short Name
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-js">
                  Hex Code
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-js">
                  Status
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-js">
                  Options
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.map((item, index) => {
              return (
                <tr>
                  <td>
                    <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {index + 1}
                    </p>
                  </td>
                  <td>
                    <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-js primary1">
                      {item?.name}
                    </p>
                  </td>
                  <td>
                    <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-js primary1">
                      {item?.code}
                    </p>
                  </td>
                  <td>
                    <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-js primary1 text-capitalize">
                      {item?.hex_code?.toUpperCase()}
                    </p>
                  </td>
                  <td>
                    <div
                      className="d-flex ac-js"
                      onClick={() => change(item?.id)}
                    >
                      {item?.status === 1 ? (
                        <div>
                          <img src={toggleOn} className="toggleOnDes" />
                        </div>
                      ) : (
                        <div>
                          <img src={toggleOff} className="toggleOnDes" />
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex ac-js primary1">
                      <div
                        onClick={() =>
                          navigate("/colours-add", {
                            state: { type: "view", data: item },
                          })
                        }
                        className="viewBoxDes p-2 mb-2 bg-white rounded-2"
                      >
                        <img src={viewIcon} className="viewDes" />
                      </div>
                      <div
                        onClick={() =>
                          navigate("/colours-add", {
                            state: { type: "edit", data: item },
                          })
                        }
                        className="viewBoxDes ms-2 p-2 mb-2 bg-white rounded-2"
                      >
                        <img src={editIcon} className="viewDes" />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ColoursList;
