import DeleteIcon from "@mui/icons-material/Delete";
import EventNoteIcon from "@mui/icons-material/EventNote";
import CallIcon from "@mui/icons-material/Call";
import GroupsIcon from "@mui/icons-material/Groups";
import { animatedImg, empty_profile } from "../../assets/img";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import LinkIcon from "@mui/icons-material/Link";
import { IoIosRadioButtonOff } from "react-icons/io";
import { IoIosRadioButtonOn } from "react-icons/io";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Country,
  Vendor_offer,
  replyData,
  Vendor_offer_type,
  currency,
  currency_list,
  line_business,
  payment_Method,
  payment_terms,
  type_business,
  vendor_category,
  sub_menu,
  address_type,
  paymentMethodList,
  lineBusinessList,
  VendoroffertypeList,
  typeBusinessLists,
  termsPayment,
} from "../../redux/api/DummyJson";
import SendIcon from "@mui/icons-material/Send";
import { useEffect, useState } from "react";
import AddContacts from "./AddContacts";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AddNotes from "./AddNotes";
import { useLocation, useNavigate } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ImageCroper from "../Popup/ImageCroper";
import {
  profilePic,
  toggleOff,
  toggleOn,
  vendorBannerImg,
} from "../../assets/img";
import ErrorIcon from "@mui/icons-material/Error";
import EditIcon from "@mui/icons-material/Edit";
import { useVendorLogoDeleteMutation } from "../../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../redux/slice/loaderNoti";

const ProfileDetails = ({
  decorList,
  vendors,
  vendorUpdateId,
  setVendorUpdateId,
  vendorUpdateIdErr,
  setUpdate,
  update,
  submitHandle,
  btn,
  vendorCatogory,
  setVendorCatogory,
  categoryList,
  vendorCatErr,
  vendorCat,
  vendorCategories,
  setVendorCategories,
  setVendorCat,
  categoryName,
  setCategoryName,
  categoryNameErr,
  setEditProfile,
  setTab,
  setProfile,
  setAddressPopup,
  setContactPopup,
  setHistory,
  place,
  setPositionPopup,
  vendorDepartmentList,
  getVendorView,
  vendorAddressList,
  getVIewAddress,
  addressStatus,
  change,
  getPositionData,
  name,
  setname,
  nameErr,
  Tradename,
  setTradename,
  TradenameErr,
  vendorOfferType,
  setVendorOfferType,
  vendorOfferTypeErr,
  paymentTermsErr,
  setPaymentTerms,
  paymentTerms,
  paymentMethod,
  setPaymentMethod,
  paymentMethodErr,
  GstIDErr,
  setGstID,
  GstID,
  currency,
  currencyName,
  setCurrency,
  setCurrencyName,
  currencyErr,
  description,
  setDescription,
  descriptionErr,
  logo,
  logoErr,
  banner,
  bannerErr,
  setLogo,
  setBanner,
  lineBusiness,
  setLineBusiness,
  lineBusinessErr,
  setListStates,
  listStates,
  setListCities,
  listCities,
  tags,
  setTags,
  tagsErr,
  setTagsErr,
  tagsList,
  setTagsList,
  id,
  setid,
  currenciesLists,
  setProfileEditBtn,
  profileEditBtn,
  tagsProfile,
  setTagsProfile,
  getViewProfile,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // RTK QUERY
  const [vendorLogoDeleteApi] = useVendorLogoDeleteMutation();

  const [listCategorys, setListCategorys] = useState([]);

  useEffect(() => {
    if (categoryList?.length > 0) {
      setListCategorys(categoryList);
    }
  }, [categoryList]);

  const [reply, setReply] = useState(false);
  const [note, setNote] = useState(false);
  const [call, setCall] = useState(false);
  const [meeting, setMeeting] = useState(false);
  const [repIndex, setRepIndex] = useState("");
  const [openNotes, setOpenNotes] = useState(false);
  const [noteIndex, setNoteIndex] = useState("");
  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [croperCol, setCroperCol] = useState(false);
  const [btnLogo, setBtnLogo] = useState(false);

  const [addContact1, setAddContact1] = useState(false);
  const [addContact2, setAddContact2] = useState(false);
  const [addContact3, setAddContact3] = useState(false);
  const [addContact4, setAddContact4] = useState(false);
  const [addContact5, setAddContact5] = useState(false);
  const [addContact6, setAddContact6] = useState(false);
  const [addContact7, setAddContact7] = useState(false);
  const [addContact8, setAddContact8] = useState(false);
  const [addContact9, setAddContact9] = useState(false);
  const [addContact10, setAddContact10] = useState(false);
  const [addContact11, setAddContact11] = useState(false);
  // const [profileEditBtn, setProfileEditBtn] = useState(false);
  // const [logo, setLogo] = useState();
  // const [banner, setBanner] = useState();
  // const [vendorCatogory, setVendorCatogory] = useState("");
  const [vendorCatShow, setVendorCatShow] = useState(false);
  const [lineBusinessShow, setLineBusinessShow] = useState(false);
  const [dropDownToggle, setDropDownToggle] = useState(undefined);
  const [paymentMethodShow, setPaymentMethodShow] = useState(false);
  const [decorativeShow, setDecorativeShow] = useState(false);

  const [projectValue, setProjectValue] = useState([]);
  const [showStatAttributes, setShowStatAttributes] = useState(false);
  // const [showSub, setShowSub] = useState(false);
  // const [, setTagsub] = useState(false);
  const [showPayMethod, setShowPayMethod] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchValueSub, setSearchValueSub] = useState("");
  const [searchValueTypeBusiness, setSearchValueTypeBusiness] = useState("");
  const [searchValuePaymethod, setSearchValuePaymethod] = useState("");
  const [searchValueOffering, setSearchValueOffering] = useState("");
  const [searchValueTermsPay, setSearchValueTermsPay] = useState("");
  const [searchValueCountry, setSearchValueCountry] = useState("");
  const [searchValueStates, setSearchValueStates] = useState("");
  const [searchValueCities, setSearchValueCities] = useState("");
  const [searchValueCurrency, setSearchValueCurrency] = useState("");
  const [listProjects, setListProjects] = useState([]);
  const [selectALL, setSelectALL] = useState(true);
  const [selectALLSub, setSelectALLSub] = useState(true);
  const [selectALLTab, setSelectALLTab] = useState(true);
  const [selectALLPay, setSelectALLPay] = useState(true);

  const [subDepartmentList, setSubDepartmentList] = useState([]);
  const [paymentsMethodList, setPaymentsMethodList] = useState([]);
  const [typeBusinessList, setTypeBusinessList] = useState([]);
  const [Vendorofferty, setVendoroffertypes] = useState([]);
  const [paymentTermsLists, setPaymentsTermsLists] = useState([]);
  const [listCountries, setListCountries] = useState([]);
  const [listCityNew, setListCityNew] = useState([]);
  const [listStateNew, setListStateNew] = useState([]);
  const [listCurrency, setListCurrency] = useState([]);

  const [tagShowes, setTagShowes] = useState(false);
  const [searchValueTags, setSearchValueTags] = useState("");
  const [tagList, setTagList] = useState([]);
  const [tagsShow, setTagsShow] = useState(false);

  // const [vendorCatogory, setVendorCatogory] = useState("");
  const [showSub, setShowSub] = useState(false);
  const [tagShow, setTagShow] = useState(false);
  const [showVendor, setShowVendor] = useState(null);
  const getVendorShow = (item) => {
    if (showVendor == item) {
      setShowVendor(null);
    } else {
      setShowVendor(item);
    }
  };
  // Select Function

  // const onClickVendorCat = (e) => {
  //   if (vendorCatogory.includes(e)) {
  //     const listtemp = vendorCatogory.indexOf(e);
  //     const list = [...vendorCatogory];
  //     list.splice(listtemp, 1);
  //     setVendorCatogory(list);
  //   } else {
  //     setVendorCatogory([...vendorCatogory, e]);
  //   }
  // };
  // const onClickLineBusiness = (e) => {
  //   if (lineBusiness.includes(e)) {
  //     const listtemp = lineBusiness.indexOf(e);
  //     const list = [...lineBusiness];
  //     list.splice(listtemp, 1);
  //     setLineBusiness(list);
  //   } else {
  //     setLineBusiness([...lineBusiness, e]);
  //   }
  // };

  // const onClickPaymentMethod = (e) => {
  //   if (paymentMethod.includes(e)) {
  //     const listtemp = paymentMethod.indexOf(e);
  //     const list = [...paymentMethod];
  //     list.splice(listtemp, 1);
  //     setPaymentMethod(list);
  //   } else {
  //     setPaymentMethod([...paymentMethod, e]);
  //   }
  // };

  const onClickVendorCat = (e, id) => {
    let temp = [...vendorCategories];
    let inx = temp.indexOf(id);
    if (vendorCategories?.includes(id)) {
      temp.splice(inx, 1);
    } else {
      temp.push(id);
    }

    setVendorCategories(temp);

    if (vendorCatogory.includes(e)) {
      const listtemp = vendorCatogory.indexOf(e);
      const list = [...vendorCatogory];
      list.splice(listtemp, 1);
      setVendorCatogory(list);
    } else {
      setVendorCatogory([...vendorCatogory, e]);
    }

    setCategoryName(e);
  };

  const handleReply = (ind) => {
    if (repIndex === ind) {
      setReply(!reply);
    }
  };

  const handleNotes = (ind) => {
    if (noteIndex === ind) {
      setOpenNotes(!openNotes);
    }
  };

  // Vendor Offering Type
  const searchVendorOffering = (event) => {
    let temp = [...VendoroffertypeList];
    let search = temp.filter((value) =>
      value?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setVendoroffertypes(search);
    } else {
      setVendoroffertypes(VendoroffertypeList);
    }

    setSearchValueOffering(event);
  };
  const onClickTag = (event, idtags, type) => {
    //  console.log(tags,"tags")
    let temp = [...tagsProfile];

    if (temp.includes(event)) {
      const listtemp = temp.indexOf(event);
      const list = [...temp];
      list.splice(listtemp, 1);
      setTagsProfile(list);
    } else {
      setTagsProfile([...temp, event]);
    }
    let ids = [...id];

    if (ids.includes(idtags)) {
      const listtemp = ids.indexOf(idtags);
      const list = [...ids];
      list.splice(listtemp, 1);
      setid(list);
    } else {
      setid([...ids, idtags]);
    }
    if (type !== "check") {
      setShowSub(false);
    }
  };
  // console.log(tagList,"tagsList")
  // console.log(tagsList,"tagsList")
  // Line of tags
  const searchTagsSub = (event) => {
    let temp = [...tagsList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setTagList(search);
    } else {
      setTagList(tagsProfile);
    }

    setSearchValueTags(event);
  };
  // Line of tags
  const allTagsSelectSub = () => {
    let projectValues = [];
    let id = [];
    if (selectALLTab) {
      tagsList?.map((item, ind) => {
        projectValues.push(item?.name);
        id.push(item?.id);
      });
      setid(id);
      setTagsProfile(projectValues);
    } else {
      setid(id);
      setTagsProfile(projectValues);
    }
  };
  // Line of Business
  const searchSub = (event) => {
    let temp = [...lineBusinessList];
    let search = temp.filter((value) =>
      value?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setSubDepartmentList(search);
    } else {
      setSubDepartmentList(lineBusiness);
    }

    setSearchValueSub(event);
  };
  // Payment Terms
  const searchTermsPayment = (event) => {
    let temp = [...termsPayment];
    let search = temp.filter((value) =>
      value?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setPaymentsTermsLists(search);
    } else {
      setPaymentsTermsLists(termsPayment);
    }

    setSearchValueTermsPay(event);
  };
  // Payment Method ALL SELECT
  const allSelectPay = () => {
    let projectValues = [];
    if (selectALLPay) {
      paymentMethodList?.map((item, ind) => {
        projectValues.push(item);
      });

      setPaymentMethod(projectValues);
    } else {
      setPaymentMethod(projectValues);
    }
  };

  // console.log(paymentMethod,"paymentMethod")

  // Payment Method  SELECT
  const onPaySelect = (event, type) => {
    // console.log(paymentMethod,"paymentMethod")
    let temp = [...paymentMethod];
    // console.log(temp,"temp")

    if (temp.includes(event)) {
      const listtemp = temp.indexOf(event);
      const list = [...temp];
      list.splice(listtemp, 1);
      setPaymentMethod(list);
    } else {
      setPaymentMethod([...temp, event]);
    }
    // if (type !== "checks") {
    //   setShowSub(false);
    // }
  };
  const [gstNoErr, setGstNoErr] = useState(false);
  const handleGST = (event) => {
    setGstID(event);
    let regex = new RegExp(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
    );
    // Return true if the GST_CODE
    // matched the ReGex
    if (regex.test(event) == true) {
      setGstNoErr(false);
      // console.log("true");
      // return 'true';
    } else {
      setGstNoErr(true);
      // console.log("false");
      // return 'false';
    }
  };
  // Payment Method
  const searchPayMethod = (event) => {
    let temp = [...paymentMethodList];
    let search = temp.filter((value) =>
      value?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setPaymentsMethodList(search);
    } else {
      setPaymentsMethodList(paymentMethodList);
    }

    setSearchValuePaymethod(event);
  };
  // Type of Business
  const searchTypeBusiness = (event) => {
    let temp = [...typeBusinessLists];
    let search = temp.filter((value) =>
      value?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setTypeBusinessList(search);
    } else {
      setTypeBusinessList(typeBusinessLists);
    }

    setSearchValueTypeBusiness(event);
  };
  // Currency
  const searchCurrency = (event) => {
    let temp = [...currenciesLists];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );
    if (event?.length > 0) {
      setListCurrency(search);
    } else {
      setListCurrency(currenciesLists);
    }

    setSearchValueCurrency(event);
  };
  // Line of Business
  const allSelectSub = () => {
    let projectValues = [];

    if (selectALLSub) {
      lineBusinessList?.map((item, ind) => {
        projectValues.push(item);
      });

      setLineBusiness(projectValues);
    } else {
      setLineBusiness(projectValues);
    }
  };
  const onSubSelect = (event, type) => {
    let temp = [...lineBusiness];

    if (temp.includes(event)) {
      const listtemp = temp.indexOf(event);
      const list = [...temp];
      list.splice(listtemp, 1);
      setLineBusiness(list);
    } else {
      setLineBusiness([...temp, event]);
    }
    if (type !== "check") {
      setShowSub(false);
    }
  };
  const dualToggele = (type) => {
    if (type == "logo") {
      setCroperCol(false);
      toggleImagePopup();
    } else {
      setCroperCol(true);
      toggleImagePopup();
    }
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  useEffect(() => {
    if (croperCol && croperImage) {
      fetch(croperImage)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "File name", { type: "image/png" });
          setBanner(file);
        });
      setCropImage("");
    } else if (!croperCol && croperImage) {
      // setLogo(croperImage);
      fetch(croperImage)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "File name", { type: "image/png" });
          setLogo(file);
        });
      setCropImage("");
    }
  }, [croperImage]);
  // console.log(currencyName,"subDepartmentList")
  useEffect(() => {
    setTagList(tagsList);
    setSubDepartmentList(lineBusinessList);
    setPaymentsMethodList(paymentMethodList);
    setTypeBusinessList(typeBusinessLists);
    setVendoroffertypes(VendoroffertypeList);
    setPaymentsTermsLists(termsPayment);
    setListCurrency(currenciesLists);
  }, [tagsList, currenciesLists]);

  const deleteLogo = (type) => {
    let formdata = new FormData();
    formdata.append("vendor_id", location?.state?.data?.id);
    formdata.append("type", type);
    setBtnLogo(true);
    dispatch(saveLoader(true));
    vendorLogoDeleteApi(formdata)
      .unwrap()
      .then((res) => {
        setBtnLogo(false);
        dispatch(saveLoader(false));
        getViewProfile(location?.state?.data?.id);
      })
      .catch((err) => {
        setBtnLogo(false);
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  const [searchValueCat, setSearchValueCat] = useState("");
  const [selectALLCat, setSelectALLCat] = useState(false);

  // CATEGORY
  const searchCatSub = (event) => {
    let temp = [...categoryList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setListCategorys(search);
    } else {
      setListCategorys(categoryList);
    }

    setSearchValueCat(event);
  };

  const allCatSelectSub = () => {
    let projectValues = [];
    let id = [];
    if (selectALLCat) {
      categoryList?.map((item, ind) => {
        projectValues.push(item?.name);
        id.push(item?.id);
        setCategoryName(id);
      });
      setVendorCategories(id);
      setVendorCatogory(projectValues);
    } else {
      setVendorCategories(id);
      setVendorCatogory(projectValues);
    }
  };

  return (
    <div className="d-flex flex-md-row flex-column flex-wrap w-100 mt-4">
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <div className="w-xl-50 w-lg-50 w-md-100 w-sm-100 w-xs-100">
        <div className="w-100 mt-1 ac-jb d-flex">
          <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Vendor Details
          </p>

          {/* <button
            onClick={() => {
              setEditProfile(true);
              setProfile(false);
            }}
            className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Edit
          </button> */}
          {/* <button
            onClick={() => {
              setProfileEditBtn(!profileEditBtn);
              // setProfile(false);
            }}
            className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            {profileEditBtn ? "Save" : "Edit"}
          </button> */}
          {profileEditBtn && update ? (
            <button
              onClick={() => {
                // setEditProfile(true);
                // setProfile(false);
                submitHandle();
              }}
              disabled={btn}
              className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${
                btn && "opacity-50"
              }`}
            >
              Submit
            </button>
          ) : (
            <button
              onClick={() => {
                // setEditProfile(true);
                // setProfile(false);
                setUpdate(!update);
                setProfileEditBtn(!profileEditBtn);
              }}
              className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
            >
              Edit
            </button>
          )}
        </div>

        <div className="d-flex flex-wrap as-jb flex-m-r">
          <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Legal Entity Name*
            </p>
            <input
              disabled={profileEditBtn ? false : true}
              placeholder="Enter Legal Name"
              value={name}
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              onChange={(e) => setname(e.target.value)}
            />
            {name?.length == 0 && nameErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Legal Entity Name</p>
              </div>
            )}
          </div>
          <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Trade Name
            </p>
            <input
              disabled={profileEditBtn ? false : true}
              // value={
              //   place === "John Rolph"
              //     ? "John Rolph"
              //     : place === "Jake Adams"
              //       ? "Jake Adams"
              //       : "Charles"
              // }
              value={Tradename}
              placeholder="Enter vendor Name"
              onChange={(e) => setTradename(e.target.value)}
              // value={vendorName}
              // onChange={(e) => setVendorName(e.target.value)}
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
            {Tradename?.length == 0 && TradenameErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Trade Name</p>
              </div>
            )}
          </div>
          <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Vendor ID
            </p>
            <input
              disabled
              placeholder="Required Field"
              value={vendors ? vendors : ""}
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              // onChange={e=>setVendorUpdateId(e.target.value)}
            />
            {vendorUpdateId?.length == 0 && vendorUpdateIdErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Vendor Id</p>
              </div>
            )}
          </div>
          <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Vendor Offering Type*
            </p>
            <div className="position-relative ">
              <input
                disabled={profileEditBtn ? false : true}
                className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                placeholder="Vendor Offering Type"
                type="text"
                value={
                  vendorOfferType?.length > 0
                    ? vendorOfferType?.toString()
                    : "Select Vendor Offering Type"
                }
                onClick={() => {
                  setDropDownToggle(2);
                  setVendorCatShow(false);
                  setShowSub(false);
                  setPaymentMethodShow(false);
                  setLineBusinessShow(false);
                  setTagShow(false);
                }}
              />
              {profileEditBtn && (
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setDropDownToggle(2);
                    setVendorCatShow(false);
                    setShowSub(false);
                    setPaymentMethodShow(false);
                    setLineBusinessShow(false);
                    setTagShow(false);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
              )}
              {dropDownToggle === 2 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDownToggle(undefined)}
                />
              )}
              <div
                className={`${
                  dropDownToggle === 2 && "submenu_1 h-auto py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchVendorOffering(e.target.value)}
                    value={searchValueOffering}
                  />
                </div>
                {Vendorofferty?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      onClick={() => {
                        setVendorOfferType(item);
                        setDropDownToggle(undefined);
                      }}
                    >
                      <button className="px-2 cust-btn text-start py-1 w-100 ">
                        {item}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
            {vendorOfferType?.length == 0 && vendorOfferTypeErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Vendor Offer Type </p>
              </div>
            )}
          </div>
          {(place !== "Charles" || place === "Jake Adams") && (
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
                Decorative Method
              </p>
              <div className="position-relative">
                <input
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  // value={decorativeName}
                  // onChange={onDecorativeClick}
                  // disabled={profileEditBtn ? false : true}
                />
                {/* {profileEditBtn && ( */}
                  <button
                    className="drop_down cust-btn"
                    onClick={() => {
                      setDecorativeShow(!decorativeShow);
                      // setCatogoryShow(false);
                      // setCatogoryShow2(false);
                      // setCatogoryShow3(false);
                      // setProductNameShow(false);
                      // setPackingSizeShow(false);
                      // setColorsShow(false)
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                {/* )}z */}
              </div>
              {decorativeShow && (
                <div
                  className="invisible-cont2"
                  onClick={() => setDecorativeShow(!decorativeShow)}
                />
              )}
              <div
                className={`${
                  decorativeShow && "submenu_1"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                {decorList?.map((item, ind) => {
                  return (
                    <div className="d-flex ac-jb hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          // onDecorativeClick(item?.list);
                          // checkBox(ind);
                        }}
                      >
                        {item?.name}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                        onClick={() => {
                          // onDecorativeClick(item?.list);
                        }}
                      >
                        {/* {decorativeName.includes(item?.list)
                          ? item?.check
                          : item.uncheck} */}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {(place !== "John Rolph" || place === "Jake Adams") && (
            <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Vendor Category*
              </p>
              <div className="position-relative">
                <textarea
                  disabled={profileEditBtn ? false : true}
                  placeholder="Sellect Category"
                  type="text"
                  value={vendorCatogory}
                  onClick={() => setVendorCatShow(!vendorCatShow)}
                  className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                />
                {profileEditBtn && (
                  <button
                    className="drop_down cust-btn w-100 d-flex ac-je me-2"
                    onClick={() => setVendorCatShow(!vendorCatShow)}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                )}
                {vendorCatShow && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setVendorCatShow(false)}
                  />
                )}
                <div
                  className={`${
                    vendorCatShow && "submenu_1 h-auto"
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  <div className="d-flex gap-1">
                    <input
                      className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                      placeholder="Search..."
                      onChange={(e) => searchCatSub(e.target.value)}
                      value={searchValueCat}
                    />
                    <button
                      className="border-0 bg-transparent"
                      onClick={() => {
                        setSelectALLCat(!selectALLCat);
                        allCatSelectSub();
                      }}
                    >
                      All
                    </button>
                  </div>
                  {listCategorys?.map((item, ind) => {
                    return (
                      <button
                        className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                        onClick={() => {
                          onClickVendorCat(item?.name, item?.id);
                        }}
                        key={ind}
                      >
                        <button className="px-2 cust-btn text-start py-1 w-100 ">
                          {item?.name}
                        </button>
                        <button className="px-2 cust-btn text-start">
                          {vendorCatogory.includes(item?.name) ? (
                            <CheckCircleIcon className="primary" />
                          ) : (
                            <RadioButtonUncheckedIcon className="primary" />
                          )}
                        </button>
                      </button>
                    );
                  })}
                </div>
              </div>
              {vendorCatogory?.length == 0 && categoryNameErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Vendor Category</p>
                </div>
              )}
            </div>
          )}

          <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Line of Business*
            </p>
            <div className="position-relative">
              <input
                disabled={profileEditBtn ? false : true}
                placeholder="Sellect Category"
                type="text"
                value={
                  lineBusiness?.length > 0
                    ? lineBusiness?.toString()
                    : "Select Line of Business"
                }
                onClick={() => {
                  setLineBusinessShow(!lineBusinessShow);
                  setVendorCatShow(false);
                  setDropDownToggle(undefined);
                  setPaymentMethodShow(false);
                  setShowSub(true);
                  setTagShow(false);
                }}
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              {profileEditBtn && (
                // <button
                //   className="drop_down cust-btn w-100 d-flex ac-je me-2"
                //   onClick={() => {
                //     setLineBusinessShow(!lineBusinessShow);
                //     setVendorCatShow(false);
                //     setDropDownToggle(undefined);
                //     setPaymentMethodShow(false);
                //   }}
                // >
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setShowSub(true);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
              )}
              {showSub && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setShowSub(false)}
                />
              )}
              <div
                className={`${
                  showSub && "submenu_1 h-auto py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchSub(e.target.value)}
                    value={searchValueSub}
                  />
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => {
                      setSelectALLSub(!selectALLSub);
                      allSelectSub();
                    }}
                  >
                    All
                  </button>
                </div>
                {subDepartmentList?.map((item, ind) => {
                  return (
                    <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          onSubSelect(item);
                        }}
                      >
                        {item}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                        style={{ fontSize: "large" }}
                        onClick={() => {
                          onSubSelect(item, "check");
                        }}
                      >
                        {lineBusiness?.includes(item) ? (
                          <IoIosRadioButtonOn className="primary" />
                        ) : (
                          <IoIosRadioButtonOff className="primary" />
                        )}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
            {lineBusiness?.length == 0 && lineBusinessErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Line of Business </p>
              </div>
            )}
          </div>

          <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Type of Business*
            </p>
            <div className="position-relative ">
              <input
                disabled={profileEditBtn ? false : true}
                placeholder="Required Field"
                className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={
                  vendorCat?.length > 0
                    ? vendorCat?.toString()
                    : "Select Type of Business"
                }
                onClick={() => {
                  setDropDownToggle(1);
                  setVendorCatShow(false);
                  setShowSub(false);
                  setPaymentMethodShow(false);
                  setLineBusinessShow(false);
                  setTagShow(false);
                }}
              />
              {profileEditBtn && (
                <button
                  className="drop_down cust-btn w-100 d-flex ac-je me-2"
                  // onPointerEnter={setVendorCat('others')}
                  onClick={() => {
                    setDropDownToggle(1);
                    setVendorCatShow(false);
                    setShowSub(false);
                    setPaymentMethodShow(false);
                    setLineBusinessShow(false);
                    setTagShow(false);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
              )}
              {dropDownToggle === 1 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDownToggle(undefined)}
                />
              )}
              <div
                className={`${
                  dropDownToggle === 1 && "submenu_1 h-auto"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchTypeBusiness(e.target.value)}
                    value={searchValueTypeBusiness}
                  />
                </div>
                {typeBusinessList?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      onClick={() => {
                        setVendorCat(item);
                        setDropDownToggle(undefined);
                      }}
                    >
                      <button className="px-2 cust-btn text-start py-1 w-100 ">
                        {item}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
            {vendorCat === "others" && (
              <input
                placeholder="Required Field"
                className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => setVendorCat(e.target.value)}
              />
            )}
            {vendorCat?.length == 0 && vendorCatErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Type of Business </p>
              </div>
            )}
          </div>

          <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Tags
            </p>
            <div className="position-relative">
              <textarea
                disabled={profileEditBtn ? false : true}
                placeholder="Required Field"
                type="text"
                value={
                  tagsProfile?.length > 0
                    ? tagsProfile?.toString()
                    : "Select Tags"
                }
                onClick={() => {
                  setTagShowes(!tagShowes);
                  setVendorCatShow(false);
                  setDropDownToggle(undefined);
                  setPaymentMethodShow(false);
                  setLineBusinessShow(false);
                  setShowSub(false);
                  setShowPayMethod(false);
                  setTagsShow(true);
                }}
                className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              {profileEditBtn && (
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setTagsShow(true);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
              )}
              {tagsShow && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setTagsShow(false)}
                />
              )}
              <div
                className={`${
                  tagsShow && "submenu_1 h-auto py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchTagsSub(e.target.value)}
                    value={searchValueTags}
                  />
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => {
                      setSelectALLTab(!selectALLTab);
                      allTagsSelectSub();
                    }}
                  >
                    All
                  </button>
                </div>
                {tagList?.map((tag, index) => (
                  <button
                    key={index}
                    className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                    onClick={() => {
                      onClickTag(tag?.name, tag?.id); // Implement onClickTag function
                    }}
                  >
                    <button className="px-2 cust-btn text-start py-1 w-100">
                      {tag?.name}
                    </button>
                    <button
                      className="px-2 cust-btn text-start"
                      style={{ fontSize: "large" }}
                      onClick={() => {
                        onClickTag(tag?.name, tag?.id, "check");
                      }}
                    >
                      {tagsProfile?.includes(tag?.name) ? (
                        <IoIosRadioButtonOn className="primary" />
                      ) : (
                        <IoIosRadioButtonOff className="primary" />
                      )}
                    </button>
                  </button>
                ))}
              </div>
            </div>
            {/* Tag error */}
            {tagsProfile?.length === 0 && tagsErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select at least one tag</p>
              </div>
            )}
          </div>

          <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Payment Terms
            </p>
            <div className="position-relative ">
              <input
                disabled={profileEditBtn ? false : true}
                placeholder="Required Field"
                className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={
                  paymentTerms?.length > 0
                    ? paymentTerms?.toString()
                    : "Select Payment Terms"
                }
                onClick={() => {
                  setDropDownToggle(4);
                  setVendorCatShow(false);
                  setShowSub(false);
                  setPaymentMethodShow(false);
                  setLineBusinessShow(false);
                  setTagShow(false);
                }}
              />
              {profileEditBtn && (
                <button
                  className="drop_down cust-btn w-100 d-flex ac-je me-2"
                  onClick={() => {
                    setDropDownToggle(4);
                    setVendorCatShow(false);
                    setShowSub(false);
                    setPaymentMethodShow(false);
                    setLineBusinessShow(false);
                    setTagShow(false);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
              )}
              {dropDownToggle === 4 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDownToggle(undefined)}
                />
              )}
              <div
                className={`${
                  dropDownToggle === 4 && "submenu_1 h-auto"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchTermsPayment(e.target.value)}
                    value={searchValueTermsPay}
                  />
                </div>
                {/* {payment_terms?.map((item) => {
                  return (
                    <div className="d-flex hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setDropDownToggle(undefined);
                          // setPaymentTerms(item?.list);
                        }}
                      >
                        {/* {item?.list} */}
                {/* </button> */}
                {/* </div>
                  );
                })} */}
                {paymentTermsLists?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      onClick={() => {
                        setPaymentTerms(item);
                        setDropDownToggle(undefined);
                      }}
                    >
                      <button className="px-2 cust-btn text-start py-1 w-100 ">
                        {item}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
            {paymentTerms?.length == 0 && paymentTermsErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Payment Terms </p>
              </div>
            )}
          </div>
          <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Payment Method
            </p>
            <div className="position-relative">
              <textarea
                disabled={profileEditBtn ? false : true}
                placeholder="Sellect Category"
                type="text"
                value={
                  paymentMethod?.length > 0
                    ? paymentMethod?.toString()
                    : "Select Payment Method"
                }
                onClick={() => {
                  setLineBusinessShow(!lineBusinessShow);
                  setVendorCatShow(false);
                  setDropDownToggle(undefined);
                  setPaymentMethodShow(false);
                  setShowPayMethod(true);
                  setTagShow(false);
                }}
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              {profileEditBtn && (
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setShowPayMethod(true);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
              )}
              {showPayMethod && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setShowPayMethod(false)}
                />
              )}
              <div
                className={`${
                  showPayMethod && "submenu_1 h-auto py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchPayMethod(e.target.value)}
                    value={searchValuePaymethod}
                  />
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => {
                      setSelectALLPay(!selectALLPay);
                      allSelectPay();
                    }}
                  >
                    All
                  </button>
                </div>
                {paymentsMethodList?.map((item, ind) => {
                  return (
                    <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          onPaySelect(item, "ckecks");
                          setShowPayMethod(false);
                        }}
                      >
                        {item}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                        style={{ fontSize: "large" }}
                        onClick={() => {
                          onPaySelect(item, "ckecks");
                        }}
                      >
                        {paymentMethod?.includes(item) ? (
                          <IoIosRadioButtonOn className="primary" />
                        ) : (
                          <IoIosRadioButtonOff className="primary" />
                        )}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
            {paymentMethod?.length == 0 && paymentMethodErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Payment Method </p>
              </div>
            )}
          </div>

          <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              GST Number*
            </p>
            <input
              disabled={profileEditBtn ? false : true}
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              type="text"
              value={GstID}
              onChange={(e) => handleGST(e.target.value)}
              onInput={(e) => {
                if (e.target.value.length > e.target.maxLength)
                  e.target.value = e.target.value.slice(0, e.target.maxLength);
                if (e.target.value.length !== 15) {
                  e.target.setCustomValidity("invalid Number");
                } else if (e.target.value.length == 15) {
                  e.target.setCustomValidity("");

                  handleGST(e.target.value);
                }
              }}
              onFocus={(e) =>
                e.target.addEventListener(
                  "wheel",
                  function (e) {
                    e.preventDefault();
                  },
                  { passive: false }
                )
              }
              maxlength={15}
            />
            {GstID?.length == 0 && GstIDErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter GST Number</p>
              </div>
            )}
            {GstID?.length > 0 && gstNoErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Valid GST Number</p>
              </div>
            )}
          </div>
          <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Currency*
            </p>
            <div className="position-relative ">
              <input
                disabled={profileEditBtn ? false : true}
                placeholder="Enter currency"
                className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={
                  currencyName?.length > 0
                    ? currencyName?.toString()
                    : "Select Currency"
                }
                onClick={() => {
                  setDropDownToggle(10);
                  setVendorCatShow(false);
                  setShowSub(false);
                  setPaymentMethodShow(false);
                  setLineBusinessShow(false);
                }}
              />
              {profileEditBtn && (
                <button
                  className="drop_down cust-btn w-100 d-flex ac-je me-2"
                  // onPointerEnter={setVendorCat('others')}
                  onClick={() => {
                    setDropDownToggle(10);
                    setVendorCatShow(false);
                    setShowSub(false);
                    setPaymentMethodShow(false);
                    setLineBusinessShow(false);
                    setTagShow(false);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
              )}
              {dropDownToggle === 10 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDownToggle(undefined)}
                />
              )}
              <div
                className={`${
                  dropDownToggle === 10 && "submenu_1 h-auto"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchCurrency(e.target.value)}
                    value={searchValueCurrency}
                  />
                </div>
                {listCurrency?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      onClick={() => {
                        setDropDownToggle(undefined);
                        setCurrency(item?.id);
                        setCurrencyName(item?.code);
                        // console.log(item,"item")
                      }}
                    >
                      <button className="px-2 cust-btn text-start py-1 w-100 ">
                        {item?.code}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
            {currencyName?.length == undefined && currencyErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select Currency</p>
              </div>
            )}
          </div>
          <div className="w-xl-100 my-2 w-lg-100 w-md-100 w-sm-100 w-xs-100">
            <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Profile Description
              </p>
              <textarea
                // onChange={(e) => setprofileDescription(e.target.value)}
                // value={profileDescription}
                value={description}
                placeholder="Required Field"
                disabled={profileEditBtn ? false : true}
                onChange={(e) => setDescription(e.target.value)}
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
              {description?.length == 0 && descriptionErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Description</p>
                </div>
              )}
            </div>
          </div>

          {/* <div
            className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100"
            onClick={() => dualToggele("logo")}
          >
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Logo Image
            </p>
            {/* <img
              style={{ cursor: "pointer" }}
              value={logo ? "Logo Uploaded Successfully" : "Choose File"}
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              src={
                logo?.name
                  ? URL.createObjectURL(logo)
                  : logo
                  ? logo
                  : profilePic
              }

            /> */}
          {/* <img
                  className="mt-2"
                  style={{ width: "150px" }}
                  src={
                    logo?.name
                      ? URL.createObjectURL(logo)
                      : logo
                      ? logo
                      : profilePic
                  }
                />
          </div> */}
          {/* <div
            className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100"
            onClick={() => dualToggele("ban")}
          >
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Banner Image
            </p>
            <input
              style={{ cursor: "pointer" }}
              value={banner ? "Banner Uploaded Successfully" : "Choose File"}
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div> */}
          {update ? (
            <>
              {" "}
              <div
                className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100"
                onClick={() => dualToggele("logo")}
              >
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Logo Image(180 * 180 px)
                </p>
                <input
                  style={{ cursor: "pointer" }}
                  value={
                    logo?.name
                      ? "Logo Uploaded Successfully"
                      : "Browse or Drag a file"
                  }
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                />
                {logo?.length == 0 && logoErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Upload Logo Image</p>
                  </div>
                )}
              </div>
              <div
                className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100"
                // onClick={() => dualToggele("ban")}
              >
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Banner Image(8000 * 2550 px)
                </p>
                {/* <input
                  style={{ cursor: "pointer" }}
                  value={
                    bannerImage?.name
                      ? "Banner Uploaded Successfully"
                      : "Browse or Drag a file"
                  }
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                /> */}
                <input
                  style={{ cursor: "pointer" }}
                  type={"file"}
                  // value={
                  //   banner
                  //     ? "Banner Uploaded Successfully"
                  //     : "Browse or Drag a file"
                  // }
                  onChange={(e) => setBanner(e.target.files[0])}
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                />
                {banner?.length == 0 && bannerErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Upload Banner Image</p>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="d-flex gap-5">
              <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                <div className="d-flex justify-content-between">
                  <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                    Logo Image
                  </p>
                  {logo && (
                    <button
                      className="border-0 bg-transparent black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-danger"
                      onClick={() => deleteLogo(1)}
                      disabled={btnLogo}
                    >
                      <DeleteForeverIcon />
                    </button>
                  )}
                </div>
                <img
                  className="mt-2"
                  style={{ width: "150px" }}
                  src={
                    logo?.name
                      ? URL.createObjectURL(logo)
                      : logo
                      ? logo
                      : profilePic
                  }
                />
              </div>
              <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                <div className="d-flex justify-content-between">
                  <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                    Banner Image
                  </p>
                  {banner && (
                    <button
                      className="border-0 bg-transparent black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-danger"
                      onClick={() => deleteLogo(2)}
                      disabled={btnLogo}
                    >
                      <DeleteForeverIcon />
                    </button>
                  )}
                </div>
                <img
                  className="mt-2"
                  style={{ width: "150px" }}
                  src={
                    banner?.name
                      ? URL.createObjectURL(banner)
                      : banner
                      ? banner
                      : vendorBannerImg
                  }
                />
              </div>
            </div>
          )}
          {/* <div className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Profile Description
            </p>
            <textarea
              // onChange={(e) => setprofileDescription(e.target.value)}
              // value={profileDescription}
              value={description}
              placeholder="Required Field"
              disabled={profileEditBtn ? false : true}
              onChange={(e) => setDescription(e.target.value)}
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
           {description?.length == 0 && descriptionErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Description</p>
              </div>
            )}
          </div> */}

          <div className="w-100 mt-5">
            <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Departments
            </p>
            {vendorDepartmentList?.map((item, ind) => {
              return (
                <div>
                  <div
                    className="pointerView w-100 mt-3 ac-jb d-flex"
                    key={ind}
                  >
                    <p className="f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
                      <PlayArrowIcon
                        onClick={() => getVendorShow(ind)}
                        className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
                          showVendor == ind ? "arrowIcon" : ""
                        } `}
                      />
                      {item?.name}{" "}
                      <span className="primary">
                        ({item?.vendor_user?.length})
                      </span>
                    </p>
                    <div
                      onClick={() => getVendorView(item, "", ind, "", "add")}
                      className="pointerView "
                    >
                      <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                        Add Contact
                      </p>
                    </div>
                  </div>
                  {showVendor == ind &&
                    item?.vendor_user?.map((user, inx) => {
                      return (
                        <AddContacts
                          user={user}
                          item={item}
                          ind={ind}
                          inx={inx}
                          getVendorView={getVendorView}
                          setContactPopup={setContactPopup}
                          setPositionPopup={setPositionPopup}
                          change={change}
                          getPositionData={getPositionData}
                        />
                      );
                    })}
                </div>
              );
            })}
          </div>

          <div className="w-100 mt-5">
            <div className="w-100 ac-jb d-flex">
              <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                Addresses
              </p>
              <button
                onClick={() => setAddressPopup(true)}
                className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
              >
                Add
              </button>
            </div>
            {vendorAddressList?.map((item, ind) => {
              return (
                <div className="w-95 mt-4" key={ind}>
                  <div className="w-100 ac-jb d-flex">
                    <p className="black f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mt-1 ">
                      {item?.address}
                      <span className="primary1 f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mt-1 ">
                        {/* (Shipping) */}
                      </span>
                    </p>

                    <div className=" d-flex ms-5 ac-jc gap-2">
                      <div onClick={() => addressStatus(item?.id)}>
                        {item?.status == 1 ? (
                          <img src={toggleOn} className="toggleOnDes1" />
                        ) : (
                          <img src={toggleOff} className="toggleOnDes1" />
                        )}
                      </div>

                      <EditIcon
                        onClick={() => getVIewAddress(item)}
                        className="pointerView primary ms-1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25"
                      />
                    </div>
                  </div>
                  <p className="primary1 mt-3 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                    {item?.name}
                  </p>
                  <p className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                    {item?.landmark}
                  </p>
                  <p className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                    {item?.street}
                  </p>
                  <p className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                    {item?.country}
                  </p>
                  <p className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                    {item?.state}
                  </p>
                  <p className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                    {item?.city}
                  </p>
                  <p className="primary1 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
                    {item?.pincode}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="w-xl-50 w-lg-50 w-md-100 w-sm-100 w-xs-100">
        <div className="w-100 replyPaddings mt-1">
          {!note && !call && !meeting && (
            <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Hey Charles,
            </p>
          )}
          {!note && !call && !meeting && (
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Find below your recent feed:
            </p>
          )}
          {!note && !call && !meeting && (
            <div className="w-100 ac-jc d-flex">
              <div className="d-flex w-lg-90 w-md-60 w-sm-70 flex-column flex-xl-row flex-lg-row flex-md-row flex-sm-row ac-jb">
                <div
                  onClick={() => setNote(true)}
                  className="d-flex pointerView px-4 py-2 rounded-3 bg-soft-primary mt-3"
                >
                  <EventNoteIcon className="primary f2 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
                  <p className="black f2 ms-1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Note
                  </p>
                </div>
                <div
                  onClick={() => setCall(true)}
                  className="d-flex pointerView px-4 py-2 rounded-3 bg-soft-primary mt-3"
                >
                  <CallIcon className="primary f2 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
                  <p className="black f2 ms-1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Call
                  </p>
                </div>
                <div
                  onClick={() => setMeeting(true)}
                  className="d-flex pointerView px-3 py-2 rounded-3 bg-soft-primary mt-3"
                >
                  <GroupsIcon className="primary f2 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
                  <p className="black f2 ms-1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Meeting
                  </p>
                </div>
              </div>
            </div>
          )}
          {note && <AddNotes type="note" setNote={setNote} />}
          {call && <AddNotes type="call" setNote={setCall} />}
          {meeting && <AddNotes type="meet" setNote={setMeeting} />}
          <div className="w-100 d-flex ac-je mt-5">
            <div className="d-flex flex-row">
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
                Show:
              </p>
              <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
                <option value="10">All</option>
              </select>
              <p className="f2 ms-3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                Time Period:
              </p>
              <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
                <option value="10">All</option>
              </select>
            </div>
          </div>
          {replyData?.map((item, index) => {
            return (
              <div className=" w-xl-100 w-lg-100 w-md-90 w-sm-100 w-xs-100 d-grid ac-jc mt-5">
                <div className="w-100 d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row ac-jc gap-3">
                  <div>
                    <img src={empty_profile} className="replyImg rounded-3" />
                  </div>
                  <div>
                    <p className="primary f3 fs-xs-10 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18">
                      {item?.name}{" "}
                      <span className="primary1 f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                        Hourglass Essentials Pvt. Ltd.
                      </span>
                    </p>
                    <p className="primary1 f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                      Roger added the vendor account{" "}
                      <span className="text-black f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                        {item?.referName}
                      </span>
                    </p>
                    <p className="primary1 f3 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Tagged in this post.
                    </p>
                  </div>
                </div>
                <div className="mt-3 w-xl-100 w-lg-100 w-xs-100 w-sm-100 d-flex ac-jb">
                  <div
                    onClick={() => {
                      handleReply(index);
                      setRepIndex(index);
                      setNoteIndex("");
                    }}
                    className="d-flex pointerView"
                  >
                    <ChatBubbleOutlineIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                    <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Reply
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      setNoteIndex(index);
                      handleNotes(index);
                      setRepIndex("");
                    }}
                    className="d-flex pointerView"
                  >
                    <NoteAddOutlinedIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                    <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Add Note
                    </p>
                  </div>
                  <div
                    onClick={() => navigate("/view-thread")}
                    className="d-flex pointerView"
                  >
                    <LinkIcon className="black linkImg f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                    <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Full thread
                    </p>
                  </div>
                  <div className="d-flex pointerView">
                    <DownloadIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                    <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Download
                    </p>
                  </div>
                  {/* <div className="d-flex pointerView">
                    <DeleteIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                    <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Delete
                    </p>
                  </div> */}
                </div>
                <div className="mt-2">
                  <p className="primary f2 ms-1 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    {item?.date}
                  </p>
                </div>
                {repIndex === index && reply && (
                  <div className="w-100 mt-2">
                    <div className="d-flex ac-je">
                      <textarea
                        className="editBtn rounded-3 p-2 w-90"
                        placeholder="Type here..."
                      />
                      <div className="mt-4 ms-1">
                        <SendIcon className="pointerView primary f2 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
                      </div>
                    </div>
                  </div>
                )}
                {noteIndex === index && openNotes && (
                  <AddNotes type="profile" setOpenNotes={setOpenNotes} />
                )}
              </div>
            );
          })}

          <div
            onClick={() => {
              setProfile(false);
              setEditProfile(false);
              setTab(5);
            }}
            className="w-100 ac-jc d-flex mt-4"
          >
            <p className="pointerView f3 mt-3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
              See more
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
