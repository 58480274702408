import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import {
  sub_menu,
  Product_Name,
  Hsn_type,
  Hsn_type_Child2,
  Hsn_type_Child3,
  Hsn_type_Child4,
  catogory_setting,
  counting,
  colors,
  Select_list,
  selecLibList,
  selected_list,
  Select_list2,
} from "../../redux/api/DummyJson";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  CheckCircleOutline,
  CheckCircleOutlineOutlined,
  Circle,
  CircleOutlined,
  DeleteForever,
} from "@mui/icons-material";
import RequestImageUplodeComp from "./RequestImageUplodeComp";
import { countriesList } from "../../redux/api/Country";

const RequestFormComp = ({
  productInformationErr,
  defaultImage,
  setdefaultImage,
  addVarientErr,
  setAddVarientErr,
  addVarients,
  setAddVarients,
  colorVarientErr,
  setColorVarientErr,
  sizeVarientErr,
  setSizeVarientErr,
  styleVarientErr,
  setStyleVarientErr,
  matVarientErr,
  setMatVarientErr,
  colorVarient,
  setColorVarient,
  sizeVarient,
  setSizeVarient,
  styleVarient,
  setStyleVarient,
  matVarient,
  setMatVarient,
  attributesName,
  setAttributesName,

  setOtherProInfo,
  otherProInfoErr,
  otherProInfo,
  setGendor,
  gendor,
  gendorErr,
  categoryList,
  setParentCategoryList,
  onSubmit,
  productNameErr,
  decorativeNameErr,
  parentCategoryList,
  getCategoryId,
  subCategoryList,
  catogoryName,
  categoryID,
  setCountry,
  country,
  subCategoryLists,
  setSubCategoryList,
  searchValueSubCategory,
  productCodeErr,
  setPackingSize,
  setSearchSubValueCategory,
  onSubCategorySelect,
  secondaryCategoryId,
  packingSize,
  hsnErr,
  productSuplierCode,
  setProductSuplierCode,
  setCatogoryName,
  setProductName,
  productImg,
  productName,
  decorList,
  setDecorativeID,
  decorativeID,
  setDecorativeName,
  decorativeName,
  setDecorationMethod,
  hsnList,
  setHsnList,
  setHsnCode,
  hsnCode,
  setHsnName,
  hsnName,
  description,
  setDescription,
  setSmallImage,
  smallimage,
  setProductImg,
  colorsList,
  colorsID,
  secondaryCategoryIdErr,
  categoryIDErr,
  colorsData,
  sizes,
  setSizes,
  materials,
  setMeterials,
  styles,
  setStyles,
  type,
  setColorsID,
  setColorsData,
  colorsImg,
  setColorsImg,
  colorImgErr,
  setColorImgErr,
  dynamicAttr,
  setDynamicAttr,
  attributesList,
  setListAttributes,
  productInformation,
  countryErr,
  colorsName,
  setProductInformation,
  listAttributes,
  selColors,
  primaryCategoryIdErr,
  descriptionErr,
  packingSizeErr,
  catogory,
  setCatogory,
  setSelColors,
  setProductCode,
  productCode,
  primaryCategoryId,
  setPrimaryCategoryId,
  smallimageErr,
  viewData,
  setColorsName,
}) => {
  const navigat = useNavigate();

  // Toggle List
  const [selectALLProInfo, setSelectALLProInfo] = useState(true);
  const [showDynamicAttr, setShowDynamicAttr] = useState(false);
  const [catogoryShow, setCatogoryShow] = useState(false);
  const [catogoryShow2, setCatogoryShow2] = useState(null);
  const [catogoryShow3, setCatogoryShow3] = useState(false);
  const [productNameShow, setProductNameShow] = useState(false);
  const [childMenu, setChildMenu] = useState(false);
  const [PackingSizeShow, setPackingSizeShow] = useState(false);
  const [countryShow, setCountryShow] = useState(false);
  const [subcatogory, setSubcatogory] = useState(false);
  const [subcatogory2, setSubcatogory2] = useState(false);
  const [subcatogory3, setSubcatogory3] = useState(false);
  const [colorsShow, setColorsShow] = useState(false);
  const [decorativeShow, setDecorativeShow] = useState(false);
  const [attributesShow, setAttributesShow] = useState(false);
  const [attributesShow2, setAttributesShow2] = useState(false);
  const [hsnTypeShow, setHsnTypeShow] = useState(false);
  const [childMenu2, setChildMenu2] = useState(false);
  const [Dropdown, setDrop] = useState(0);
  const [details, setDetails] = useState(false);
  const [prodNameicon, setProdNameicon] = useState(false);
  const [distable, setDistable] = useState(false);
  const [check, setCheck] = useState(undefined);
  const [checkBox, setCheckBox] = useState(false);
  const [addIcon, setAddIcon] = useState(undefined);
  const [addIcon1, setAddIcon1] = useState(undefined);
  const [addIcon2, setAddIcon2] = useState(undefined);
  const [varients, setVarients] = useState(null);
  const [dropDown, setDropDown] = useState(null);

  const [searchValueGendor, setSearchValueGendor] = useState("");
  const gendorList = ["NA", "U", "M", "F", "K"];
  const [listGendor, setListGendor] = useState([]);

  // input box
  // const [catogory, setCatogory] = useState("");
  const [catogory2, setCatogory2] = useState("");
  const [catogory3, setCatogory3] = useState("");
  // const [productName, setProductName] = useState("");
  // const [productSuplierCode, setProductSuplierCode] = useState("");
  // const [packingSize, setPackingSize] = useState("");
  // const [country, setCountry] = useState("");
  // const [colorsName, setColorsName] = useState([]);
  const [ind1, setInd] = useState([]);
  // const [decorativeName, setDecorativeName] = useState([]);
  const [Ind2, setInd2] = useState([]);
  // const [attributesName, setAttributesName] = useState([]);
  const [attributesName2, setAttributesName2] = useState([]);
  const [inputSellect, setInputSellect] = useState("");
  const [hsnType, sethsnType] = useState("");
  const [size, setSize] = useState([]);
  const [skuCode, setSkuCode] = useState("");
  const [varient, setVarient] = useState("");
  const [meterial, setMeterial] = useState("");
  const [slash, setslash] = useState("/signin");
  const [textShow2, settextShow2] = useState(false);
  const [searchValueCategory, setSearchValueCategory] = useState("");
  // const [searchValueCategory, setSearchValueCategory] = useState("");
  const [searchValueCountry, setSearchValueCountry] = useState("");
  const [listCountries, setListCountries] = useState([]);
  const [decorativeLists, setDecorativeLists] = useState([]);
  const [selectALLSub, setSelectALLSub] = useState(true);
  const [searchValueSub, setSearchValueSub] = useState("");
  const [listHSN, setListHSN] = useState([]);
  const [listColors, setListColors] = useState([]);
  const [searchHsn, setSearchHsn] = useState("");
  const [searchColors, setSearchColors] = useState("");
  const [selectALLColors, setSelectALLColors] = useState(true);
  const [searchProInfo, setSearchProInfo] = useState("");
  // SEARCH CATEGROY
  const searchCategory = (event) => {
    let temp = [...categoryList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setParentCategoryList(search);
    } else {
      setParentCategoryList(categoryList);
    }

    setSearchValueCategory(event);
  };
  // handle Product Information
  const handleProductInformation = (event, ind) => {
    let temp = [...productInformation];
    temp[ind]["value"] = event;
    setProductInformation(temp);
  };

  const onColorSelect = (e) => {
    if (selColors.includes(e)) {
      const listtemp = selColors.indexOf(e);
      const list = [...selColors];
      list.splice(listtemp, 1);
      setSelColors(list);
    } else {
      setSelColors([...selColors, e]);
    }
  };
  const onDynamicAttributeSelect = (event, id, ind) => {
    let temp = [...productInformation];

    let finder = temp.find((i) => i.id == id);

    if (finder?.id) {
      let inx = temp.findIndex((i) => i.id == id);
      temp.splice(inx, 1);
    } else {
      temp.push({
        id: id,
        title: event,
        value: "",
      });
    }

    setProductInformation(temp);

    if (dynamicAttr.includes(event)) {
      const listtemp = dynamicAttr?.indexOf(event);
      const list = [...dynamicAttr];
      list.splice(listtemp, 1);
      setDynamicAttr(list);
    } else {
      setDynamicAttr([...dynamicAttr, event]);
    }

    setListAttributes(attributesList);
    setSearchProInfo("");
    if (otherProInfo == id) {
      setOtherProInfo("");
    } else {
      setOtherProInfo(id);
    }
  };
  // SELECT CATEGORY
  const onCategorySelct = (item) => {
    setCatogoryName({ [item?.id]: "" });
    setCatogoryShow(false);
    setCatogory(item?.name);
    setPrimaryCategoryId(item?.id);
    setSubcatogory(true);
    setProductSuplierCode("");
    getCategoryId(item?.id, "category");
    setParentCategoryList(categoryList);

    setSearchValueCategory("");
    // setDropDown(null);
    // setParentCategory(item?.id);
  };
  const allSelectProductInfo = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLProInfo) {
      attributesList?.map((item, ind) => {
        ListId.push({ id: item?.id, title: item?.name, value: "" });
        ListName.push(item?.name);
        setOtherProInfo(item?.id);
      });

      setProductInformation(ListId);
      setDynamicAttr(ListName);
    } else {
      setProductInformation(ListId);
      setDynamicAttr(ListName);
      setOtherProInfo("");
    }

    setSelectALLProInfo(!selectALLProInfo);
  };
  // SUB CATEGORY SEARCH
  const searchSubCatFun = (event, ind) => {
    let temp = [...subCategoryLists];
    let tempX = temp[ind];
    let temps = [...subCategoryList];
    let tempXs = temps[ind];
    let search = tempX?.child_categories?.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    // console.log("search", search);

    tempXs = search;

    let obj = {
      ...subCategoryList[ind],
      child_categories: tempXs,
    };

    let arr = [...subCategoryList];
    arr[ind] = obj;

    // console.log("tempXs", obj, arr);

    if (event?.length > 0) {
      setSubCategoryList(arr);
    } else {
      // console.log("jjj");
      setSubCategoryList(subCategoryLists);
    }

    setSearchSubValueCategory((val) => ({ ...val, [ind]: event }));
  };

  // COUNTY START
  const searchCountry = (event) => {
    let temp = [...countriesList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );
    if (event?.length > 0) {
      setListCountries(search);
    } else {
      setListCountries(countriesList);
    }

    setSearchValueCountry(event);
  };

  const getCountry = (event) => {
    // setDropDown(null);
    setCountry(event);
    setSearchValueCountry("");
    setListCountries(countriesList);
  };

  // COUNTY END
  const searchProductInfoFun = (event) => {
    let temp = [...attributesList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setListAttributes(search);
    } else {
      setListAttributes(attributesList);
    }

    setSearchProInfo(event);
  };
  const searchSub = (event) => {
    let temp = [...decorList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setDecorativeLists(search);
    } else {
      setDecorativeLists(decorList);
    }

    setSearchValueSub(event);
  };

  const onSubSelect = (event, id, type) => {
    let temp = [...decorativeName];
    let temps = [...decorativeID];

    if (temp.includes(event)) {
      const listtemp = temp.indexOf(event);
      const list = [...temp];
      list.splice(listtemp, 1);
      setDecorativeName(list);
    } else {
      setDecorativeName([...temp, event]);
    }

    if (temps.includes(id)) {
      const listtemp = temps.indexOf(id);
      const list = [...temps];
      list.splice(listtemp, 1);
      setDecorativeID(list);
    } else {
      setDecorativeID([...temps, id]);
    }

    if (type !== "check") {
      // setDropDown(null);
    }

    setSearchValueSub("");
    setDecorativeLists(decorList);
    setDecorationMethod(id);
  };

  // Decoration Method START
  const allSelectSub = () => {
    let decorativeListId = [];
    let decorativeListName = [];
    if (selectALLSub) {
      decorList?.map((item, ind) => {
        decorativeListId.push(item?.id);
        decorativeListName.push(item?.name);
        setDecorationMethod(item?.id);
      });

      setDecorativeID(decorativeListId);
      setDecorativeName(decorativeListName);
    } else {
      setDecorativeID(decorativeListId);
      setDecorativeName(decorativeListName);
    }
  };

  // HSN START
  const searchHSNValue = (event) => {
    let temp = [...hsnList];
    let search = temp.filter((value) =>
      value?.code?.toLowerCase()?.includes(event?.toLowerCase())
    );
    if (event?.length > 0) {
      setListHSN(search);
    } else {
      setListHSN(hsnList);
    }

    setSearchHsn(event);
  };

  const getHSN = (event) => {
    // setDropDown(null);
    setHsnCode(event?.id);
    setHsnName(event?.code);
    setSearchHsn("");
    setListHSN(hsnList);
  };
  // HSN END

  const searchColorsFun = (event) => {
    let temp = [...colorsList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setListColors(search);
    } else {
      setListColors(colorsList);
    }

    setSearchColors(event);
  };

  const onColorsSelect = (event, id, item, ind, type) => {
    let temp = [...colorsID];
    let temps = [...colorsName];
    let temx = [...colorsData];

    if (temp.find((i) => i.id == id)) {
      let finder = temp.filter((i) => i.id !== id);
      setColorsID(finder);
    } else {
      let obj = {
        id: id,
      };
      temp.push(obj);
      setColorsID(temp);
    }

    if (temps.includes(event)) {
      const listtemp = temps.indexOf(event);
      const list = [...temps];
      list.splice(listtemp, 1);
      setColorsName(list);
    } else {
      setColorsName([...temps, event]);
    }

    // if (temx.includes(item)) {
    //   const listtemp = temx.indexOf(item);
    //   const list = [...temx];
    //   list.splice(listtemp, 1);
    //   setColorsData(list);
    // } else {
    //   setColorsData([...temx, item]);
    // }

    if (temx.find((i) => i.id == id)) {
      let finder = temx.filter((i) => i.id !== id);
      setColorsData(finder);
    } else {
      temx.push(item);
      setColorsData(temx);
    }

    if (type !== "check") {
      // setVarients(null);
    }

    setSearchColors("");
    setListColors(colorsList);
    if (id == colorVarient) {
      setColorVarient("");
    } else {
      setColorVarient(id);
    }
  };

  // COLORS START
  const allSelectColors = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLColors) {
      colorsList?.map((item, ind) => {
        ListId.push({ id: item?.id });
        ListName.push(item?.name);
        setColorVarient(item?.id);
      });

      setColorsID(ListId);
      setColorsName(ListName);
      setColorsData(colorsList);
    } else {
      setColorsID(ListId);
      setColorsName(ListName);
      setColorsData([]);
      setColorVarient("");
    }

    setSelectALLColors(!selectALLColors);
  };
  // SIZE START
  const handleAdd = () => {
    const abc = [...sizes];
    abc.push({ value: "" });
    setSizes(abc);
  };
  const handleChange = (event, ind, type) => {
    const inputdata = [...sizes];
    let obj = {
      ...inputdata[ind],
      value: event,
    };
    inputdata[ind] = obj;
    setSizes(inputdata);
    setSizeVarient(event);
  };
  const handleDelete = (i) => {
    const deletVal = [...sizes];
    deletVal.splice(i, 1);
    setSizes(deletVal);
  };
  // SIZE END

  // MATERIAL START
  const handleMatAdd = () => {
    const abc = [...materials];
    abc.push({ value: "" });
    setMeterials(abc);
  };
  const handleMatChange = (event, ind, type) => {
    const inputdata = [...materials];
    let obj = {
      ...inputdata[ind],
      value: event,
    };
    inputdata[ind] = obj;
    setMeterials(inputdata);
    setMatVarient(event);
  };
  const handleMatDelete = (i) => {
    const deletVal = [...materials];
    deletVal.splice(i, 1);
    setMeterials(deletVal);
  };
  // MATERIAL END

  // STYLES START
  const handleStyleAdd = () => {
    const abc = [...styles];
    abc.push({ value: "" });
    setStyles(abc);
  };
  const handleStyleChange = (event, ind, type) => {
    const inputdata = [...styles];
    let obj = {
      ...inputdata[ind],
      value: event,
    };
    inputdata[ind] = obj;
    setStyles(inputdata);
    setStyleVarient(event);
  };
  const handleStyleDelete = (i) => {
    const deletVal = [...styles];
    deletVal.splice(i, 1);
    setStyles(deletVal);
  };
  // STYLES END

  const onAttributeClick = (e) => {
    if (attributesName.includes(e)) {
      const listtemp = attributesName.indexOf(e);
      const list = [...attributesName];
      list.splice(listtemp, 1);
      setAttributesName(list);
    } else {
      setAttributesName([...attributesName, e]);
    }
    if (e == addVarients) {
      setAddVarients("");
    } else {
      setAddVarients(e);
    }
  };

  const viewDatas = () => {
    const temp = [];
    // if (
    //   viewData?.variant_master?.color?.length > 0 &&
    //   viewData?.variant_master?.color[0] != null
    // ) {
    temp.push("Color");
    // }
    if (
      viewData?.variant_master?.size?.length > 0 &&
      viewData?.variant_master?.size[0] != null
    ) {
      temp.push("Size");
    }
    if (
      viewData?.variant_master?.style?.length > 0 &&
      viewData?.variant_master?.style[0] != null
    ) {
      temp.push("Style");
    }
    if (
      viewData?.variant_master?.material?.length > 0 &&
      viewData?.variant_master?.material[0] != null
    ) {
      temp.push("Materials");
    }

    setAttributesName(temp);
    setSubcatogory(true);
  };
  // GENDOR SEARCH
  const searchGendor = (event) => {
    let temp = [...gendorList];
    let search = temp.filter((value) =>
      value?.toLowerCase()?.includes(event?.toLowerCase())
    );
    if (event?.length > 0) {
      setListGendor(search);
    } else {
      setListGendor(gendorList);
    }

    setSearchValueGendor(event);
  };

  // GENDOR SELECT
  const onSelectGendor = (item) => {
    setGendor(item);
    // setDropDown(null);
    setSearchValueGendor("");
    setListGendor(gendorList);
  };

  // console.log(viewData,"viewData")
  useEffect(() => {
    if (viewData) {
      viewDatas();
    }
    setParentCategoryList(categoryList);
    setListCountries(countriesList);
    setDecorativeLists(decorList);
    setListHSN(hsnList);
    setListColors(colorsList);
    setListAttributes(attributesList);
    setListGendor(gendorList);
  }, [decorList, hsnList, colorsList, attributesList, categoryList]);
  // console?.log(colorsName, "subCategoryList");
  // console?.log(listColors, "subCategoryList");
  return (
    <div className="d-flex as-jb w-100 mt-5 res-flex gap-5">
      <div className="w-100">
        <div className="d-flex flex-wrap ac-jb flex-m-r">
          <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
            <p className="text-dark f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Category
            </p>
            <div className="position-relative z-3">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={catogory}
                // onChange={onCatogory}
                onClick={(e) => {
                  setCatogoryShow(!catogoryShow);
                }}
                readOnly
                disabled={type == "edit" ? true : false}
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setCatogoryShow(!catogoryShow);
                  setChildMenu(false);
                  setCatogoryShow2(null);
                  setCatogoryShow3(false);
                  setProductNameShow(false);
                  setPackingSizeShow(false);
                  setCountryShow(false);
                  setDecorativeShow(false);
                  setAttributesShow(false);
                }}
                disabled={type == "edit" ? true : false}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {primaryCategoryId?.length == 0 && primaryCategoryIdErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select Category</p>
              </div>
            )}
            {catogoryShow && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setCatogoryShow(!catogoryShow)}
              />
            )}
            <div
              className={`${
                catogoryShow && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              <div className="d-flex gap-1">
                <input
                  className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                  placeholder="Search..."
                  onChange={(e) => searchCategory(e.target.value)}
                  value={searchValueCategory}
                />
              </div>
              {parentCategoryList?.map((item, ind) => {
                return (
                  <div className="d-flex hover-cust" key={ind}>
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      onClick={() => {
                        onCategorySelct(item);
                        // setCatogoryShow(false);
                        // setCatogory(item?.name);
                        setChildMenu(false);
                        // setSubcatogory(true);
                      }}
                    >
                      {item?.name}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>

          {subcatogory && (
            <>
              {subCategoryList?.map((item, ind) => {
                return (
                  <div
                    className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative"
                    key={ind}
                  >
                    {/* <fieldset disabled={location?.state?.data?.id ? true : false}> */}
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
                      {item?.category?.name}
                    </p>
                    <div className="position-relative">
                      <input
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                        value={catogoryName?.[item?.category?.id]}
                        onClick={() => setCatogoryShow2(ind)}
                        readOnly
                        disabled={type == "edit" ? true : false}
                      />
                      <button
                        className="drop_down cust-btn"
                        onClick={() => {
                          setCatogoryShow2(ind);

                          setCatogoryShow(false);
                          setCatogoryShow3(false);
                          setProductNameShow(false);
                          setPackingSizeShow(false);
                          setCountryShow(false);
                          setDecorativeShow(false);
                          // setAttributesShow(false);
                        }}
                        disabled={type == "edit" ? true : false}
                      >
                        <KeyboardArrowDownIcon />
                      </button>
                    </div>
                    {catogoryShow2 == ind && (
                      <div
                        className="invisible-cont2 z-0"
                        onClick={() => setCatogoryShow2(null)}
                      />
                    )}
                    <div
                      className={`${
                        catogoryShow2 == ind && "submenu_1"
                      } submenu_cont_1 overflow-scroll z-3`}
                    >
                      <div className="d-flex gap-1">
                        <input
                          className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                          placeholder="Search..."
                          onChange={(e) => searchSubCatFun(e.target.value, ind)}
                          value={searchValueSubCategory[ind]}
                        />
                      </div>
                      {item?.child_categories?.map((subItem, indx) => {
                        return (
                          <div className="d-flex hover-cust" key={indx}>
                            <button
                              className="px-2 cust-btn text-start py-1 w-100"
                              onClick={() => {
                                setCatogoryShow2(null);
                                onSubCategorySelect(subItem, indx, ind);
                              }}
                            >
                              {subItem?.name}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                    {ind == 0 &&
                      secondaryCategoryId?.length == 0 &&
                      secondaryCategoryIdErr && (
                        <div className="d-flex gap-1 mt-2">
                          <ErrorIcon className="svg_log" />
                          <p className="err-input_log mt-1">
                            Select Sub Category 1
                          </p>
                        </div>
                      )}
                    {ind == 1 && categoryID?.length == 0 && categoryIDErr && (
                      <div className="d-flex gap-1 mt-2">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">
                          Select Sub Category 2
                        </p>
                      </div>
                    )}
                  </div>
                );
              })}

              <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Sub Category Code
                </p>
                <div className="position-relative">
                  <input
                    placeholder="Required Field"
                    className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={productSuplierCode}
                    onChange={(e) => setProductSuplierCode(e.target.value)}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </>
          )}
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Product Name
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={productName}
                onChange={(e) => {
                  setProductName(e.target.value);
                }}
                type="text"
                disabled={distable ? true : false}
              />
              {productName?.length == 0 && productNameErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Name</p>
                </div>
              )}
            </div>
          </div>

          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Vendor Product Code
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={productCode}
                onChange={(e) => setProductCode(e.target.value)}
                type="text"
              />
              {productCode?.length == 0 && productCodeErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Product Code</p>
                </div>
              )}
            </div>
          </div>
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Decoration Method
            </p>
            <div className="position-relative">
              <textarea
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={
                  decorativeName?.length > 0
                    ? decorativeName?.toString()
                    : "Select Decorative method"
                }
                onClick={() => setDecorativeShow(!decorativeShow)}
                readOnly
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDecorativeShow(!decorativeShow);
                  setCatogoryShow(false);
                  setCatogoryShow2(null);
                  setCatogoryShow3(false);
                  setProductNameShow(false);
                  setPackingSizeShow(false);
                  setColorsShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {decorativeShow && (
              <div
                className="invisible-cont2"
                onClick={() => setDecorativeShow(!decorativeShow)}
              />
            )}
            <div
              className={`${
                decorativeShow && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              <div className="d-flex gap-1">
                <input
                  className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                  placeholder="Search..."
                  onChange={(e) => searchSub(e.target.value)}
                  value={searchValueSub}
                />
                <button
                  className="border-0 bg-transparent"
                  onClick={() => {
                    setSelectALLSub(!selectALLSub);
                    allSelectSub();
                  }}
                >
                  All
                </button>
              </div>
              {decorativeLists?.map((item, ind) => {
                return (
                  <div className="d-flex ac-jb hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100 "
                      onClick={() => {
                        onSubSelect(item?.name, item?.id);
                      }}
                    >
                      {item?.name}
                    </button>
                    <button
                      className="px-2 cust-btn text-start"
                      onClick={() => {
                        onSubSelect(item?.name, item?.id, "check");
                      }}
                    >
                      {decorativeName?.includes(item?.name) ? (
                        <CheckCircleIcon className="primary" />
                      ) : (
                        <RadioButtonUncheckedIcon className="primary" />
                      )}
                    </button>
                  </div>
                );
              })}
            </div>
            {decorativeName?.length == 0 && decorativeNameErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select Decoration Method</p>
              </div>
            )}
          </div>
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Country of Origin
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={
                  country?.length > 0 ? country?.toString() : "Select Country"
                }
                onClick={() => setCountryShow(!countryShow)}
                readOnly
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setCountryShow(!countryShow);
                  setCatogoryShow(false);
                  setCatogoryShow2(null);
                  setCatogoryShow3(false);
                  setProductNameShow(false);
                  setPackingSizeShow(false);
                  setDecorativeShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {countryShow && (
              <div
                className="invisible-cont2"
                onClick={() => setCountryShow(!countryShow)}
              />
            )}
            <div
              className={`${
                countryShow && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              <div className="d-flex gap-1">
                <input
                  className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                  placeholder="Search..."
                  onChange={(e) => searchCountry(e.target.value)}
                  value={searchValueCountry}
                />
              </div>
              {listCountries?.map((item) => {
                return (
                  <div className="d-flex hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      onClick={() => {
                        setCountryShow(false);
                        // setCountry(item?.list);
                        getCountry(item?.name);
                      }}
                    >
                      {item?.name}
                    </button>
                  </div>
                );
              })}
            </div>
            {country?.length == 0 && countryErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select Country</p>
              </div>
            )}
          </div>
          {/* <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Product Packaging Size
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={packingSize}
                // onChange={onPackingSize}
                onClick={() => setPackingSizeShow(!PackingSizeShow)}
                readOnly
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setPackingSizeShow(!PackingSizeShow);
                  setCatogoryShow(false);
                  setCatogoryShow2(null);
                  setCatogoryShow3(false);
                  setProductNameShow(false);
                  setCountryShow(false);
                  setDecorativeShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {PackingSizeShow && (
              <div
                className="invisible-cont2"
                onClick={() => setPackingSizeShow(!PackingSizeShow)}
              />
            )}
            <div
              className={`${
                PackingSizeShow && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {sub_menu?.map((item) => {
                return (
                  <div className="d-flex hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      onClick={() => {
                        setPackingSizeShow(false);
                        setPackingSize(item?.list);
                      }}
                    >
                      {item?.list}
                    </button>
                  </div>
                );
              })}
            </div>
            {packingSize?.length == 0 && packingSizeErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select PackingSize</p>
              </div>
            )}
          </div> */}
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Product Packaging Size
            </p>
            <div className="position-relative">
              <input
                placeholder="L * W * H"
                className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={packingSize}
                onChange={(e) => setPackingSize(e.target.value)}
                type="text"
              />
              {packingSize?.length == 0 && packingSizeErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select PackingSize</p>
                </div>
              )}
            </div>
          </div>
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              HSN Code
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={hsnName ? hsnName : "Select HSN Code"}
                // onChange={onHsnTyoe}
                onClick={() => setHsnTypeShow(!hsnTypeShow)}
                readOnly
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setHsnTypeShow(!hsnTypeShow);
                  setChildMenu2(false);
                  setCatogoryShow(false);
                  setCatogoryShow2(null);
                  setCatogoryShow3(false);
                  setProductNameShow(false);
                  setPackingSizeShow(false);
                  setCountryShow(false);
                  setDecorativeShow(false);
                  setAttributesShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {hsnTypeShow && (
              <div
                className="invisible-cont2"
                onClick={() => setHsnTypeShow(!hsnTypeShow)}
              />
            )}
            <div
              className={`${
                hsnTypeShow && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              <div className="d-flex gap-1">
                <input
                  className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                  placeholder="Search..."
                  onChange={(e) => searchHSNValue(e.target.value)}
                  value={searchHsn}
                />
              </div>
              {listHSN?.map((item, ind) => {
                return (
                  <div className="d-flex hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      // onClick={() => {
                      //     Dropdown === ind
                      //         ? setChildMenu2(!childMenu2)
                      //         : setDrop(ind);
                      // }}
                      onClick={() => {
                        setHsnTypeShow(false);
                        // sethsnType(item?.list);
                        getHSN(item);
                        setChildMenu2(false);
                      }}
                    >
                      {item?.code}
                    </button>
                  </div>
                );
              })}
            </div>
            {hsnCode?.length == 0 && hsnErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select HSN Code</p>
              </div>
            )}
          </div>
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Gender
            </p>
            <div className="position-relative">
              <input
                placeholder="Gender"
                type="text"
                value={
                  gendor?.length > 0 ? gendor?.toString() : "Select Gender"
                }
                onClick={() => {
                  setDropDown(11);
                }}
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(11);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 11 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDown(null)}
                />
              )}
              <div
                className={`${
                  dropDown == 11 && "submenu_1 py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchGendor(e.target.value)}
                    value={searchValueGendor}
                  />
                </div>
                {listGendor?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      onClick={() => {
                        onSelectGendor(item);
                        setDropDown(null);
                      }}
                      key={ind}
                    >
                      <button className="px-2 cust-btn text-start py-1 w-100 ">
                        {item}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
            {gendor?.length == 0 && gendorErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select Gendor</p>
              </div>
            )}
          </div>
          <div className="w-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Product Description
            </p>
            <textarea
              placeholder="Product Description"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
            {description?.length == 0 && descriptionErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Description</p>
              </div>
            )}
          </div>

          {/* DYNAMIC ATTRIBUTES */}
          <div className="mt-5 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
              Other Product Information
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={dynamicAttr}
                onClick={(e) => setShowDynamicAttr(!showDynamicAttr)}
                readOnly
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setShowDynamicAttr(!showDynamicAttr);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {showDynamicAttr && (
              <div
                className="invisible-cont2"
                onClick={() => setShowDynamicAttr(!showDynamicAttr)}
              />
            )}
            <div
              className={`${
                showDynamicAttr && "submenu_1 "
              } submenu_cont_1 overflow-scroll z-3`}
            >
              <div className="d-flex gap-1">
                <input
                  className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                  placeholder="Search..."
                  onChange={(e) => searchProductInfoFun(e.target.value)}
                  value={searchProInfo}
                />
                <button
                  className="border-0 bg-transparent"
                  onClick={() => {
                    allSelectProductInfo();
                  }}
                >
                  All
                </button>
              </div>
              {listAttributes?.map((item, ind) => {
                return (
                  <div className="d-flex as-jb hover-cust" key={ind}>
                    <button
                      className="px-2 cust-btn text-start py-1 w-100 "
                      onClick={() => {
                        onDynamicAttributeSelect(item?.name, item?.id, ind);
                        setShowDynamicAttr(false);
                      }}
                    >
                      {item?.name}
                    </button>
                    <button
                      className="px-2 cust-btn text-start"
                      onClick={() => {
                        onDynamicAttributeSelect(item?.name, item?.id, ind);
                      }}
                    >
                      {dynamicAttr?.includes(item?.name) ? (
                        <CheckCircleIcon className="primary" />
                      ) : (
                        <RadioButtonUncheckedIcon className="primary" />
                      )}

                      {dynamicAttr?.includes(item?.list)
                        ? item?.check
                        : item.uncheck}
                    </button>
                  </div>
                );
              })}
            </div>
            {otherProInfo?.length == 0 && otherProInfoErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">
                  Select Other Product Information
                </p>
              </div>
            )}
          </div>
          {productInformation?.map((item, ind) => {
            return (
              <div
                className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100"
                key={ind}
              >
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  {item?.title}
                </p>
                <input
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  onChange={(e) =>
                    handleProductInformation(e.target.value, ind)
                  }
                  value={item?.value}
                />
                {item?.value?.length == 0 && productInformationErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">fill Information</p>
                  </div>
                )}
              </div>
            );
          })}
          <div className="mt-3 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
              Add Variants
            </p>
            <div className="position-relative  w-sm-100 w-xs-100 x-md-100">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={attributesName}
                onClick={() => setAttributesShow(!attributesShow)}
                readOnly
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setAttributesShow(!attributesShow);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {attributesShow && (
              <div
                className="invisible-cont2"
                onClick={() => setAttributesShow(!attributesShow)}
              />
            )}
            <div
              className={`${
                attributesShow && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {Select_list?.map((item, ind) => {
                return (
                  <div className="d-flex as-jb hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100 "
                      onClick={() => {
                        onAttributeClick(item?.list);
                        setAttributesShow(false);
                      }}
                      disabled={item?.list == "Color" ? true : false}
                    >
                      {item?.list}
                    </button>
                    <button
                      className="px-2 cust-btn text-start"
                      onClick={() => {
                        onAttributeClick(item?.list);
                      }}
                      disabled={item?.list == "Color" ? true : false}
                    >
                      {attributesName.includes(item?.list)
                        ? item?.check
                        : item.uncheck}
                    </button>
                  </div>
                );
              })}
            </div>
            {attributesName?.length == 1 && addVarientErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select Variants</p>
              </div>
            )}
          </div>
          {attributesName?.includes("Color") && (
            <div className=" w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f4 fs-xs-12 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-2 ">
                Color
              </p>
              <div className="position-relative">
                <input
                  placeholder="Color"
                  type="text"
                  value={
                    colorsName?.length > 0
                      ? colorsName?.toString()
                      : "Select Color"
                  }
                  onClick={() => {
                    setVarients(0);
                  }}
                  className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setVarients(0);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
                {varients == 0 && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setVarients(null)}
                  />
                )}
                <div
                  className={`${
                    varients == 0 && "submenu_1  py-2"
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  <div className="d-flex gap-1">
                    <input
                      className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                      placeholder="Search..."
                      onChange={(e) => searchColorsFun(e.target.value)}
                      value={searchColors}
                    />
                    <button
                      className="border-0 bg-transparent"
                      onClick={() => {
                        allSelectColors();
                      }}
                    >
                      All
                    </button>
                  </div>
                  {listColors?.map((item, ind) => {
                    return (
                      <button
                        className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                        key={ind}
                      >
                        <button
                          className="px-2 cust-btn text-start py-1 w-100 "
                          onClick={() => {
                            onColorsSelect(item?.name, item?.id, item, ind);
                          }}
                        >
                          {item?.name}
                        </button>
                        <button
                          className="px-2 cust-btn text-start"
                          style={{ fontSize: "large" }}
                          onClick={() => {
                            onColorsSelect(
                              item?.name,
                              item?.id,
                              item,
                              ind,
                              "check"
                            );
                          }}
                        >
                          {colorsName?.includes(item?.name) ? (
                            <CheckCircleIcon
                              style={{ color: item?.hex_code }}
                            />
                          ) : (
                            <RadioButtonUncheckedIcon className="primary" />
                          )}
                        </button>
                      </button>
                    );
                  })}
                </div>
              </div>
              {colorVarient?.length == 0 && colorVarientErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Color</p>
                </div>
              )}
            </div>
          )}

          {sizes?.length > 0 && attributesName?.includes("Size") && (
            <div className=" w-md-100 w-sm-100 w-xs-100">
              <p className=" black f4 fs-xs-12 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-2 ">
                Size
              </p>
              <div className="d-flex justify-content-start me-3 ">
                <div className="editBtn text-center  rounded-3 mt-2 p-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  <button
                    className="border-0 primary f3 "
                    onClick={() => handleAdd()}
                  >
                    +Add New
                  </button>
                </div>
              </div>
              <div className="d-flex flex-wrap w-100 gap-2">
                {sizes?.map((data, i) => {
                  return (
                    <div
                      className="d-flex  w-md-100 w-sm-100 w-xs-100"
                      style={{ width: "30% " }}
                    >
                      <div className="position-relative add w-100">
                        <input
                          value={data?.value}
                          onChange={(e) =>
                            handleChange(e.target.value, i, "value")
                          }
                          placeholder="Required Field"
                          className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        />
                      </div>
                      {sizes?.length > 1 && (
                        <button className="mx-2 d-flex ac-jc cp cust-btn">
                          <DeleteForever
                            className="primary"
                            onClick={() => handleDelete(i)}
                          />
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>
              {sizeVarient?.length == 0 && sizeVarientErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Size</p>
                </div>
              )}
            </div>
          )}

          {materials?.length > 0 && attributesName?.includes("Materials") && (
            <div className=" w-md-100 w-sm-100 w-xs-100">
              <p className=" black f4 fs-xs-12 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-2 ">
                Materials
              </p>
              <div className="d-flex justify-content-start me-3 ">
                <div className="editBtn text-center  rounded-3 mt-2 p-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  <button
                    className="border-0 primary f3 "
                    onClick={() => handleMatAdd()}
                  >
                    +Add New
                  </button>
                </div>
              </div>
              <div className="d-flex flex-wrap w-100 gap-2">
                {materials?.map((data, i) => {
                  return (
                    <div
                      className="d-flex  w-md-100 w-sm-100 w-xs-100"
                      style={{ width: "30% " }}
                    >
                      <div className="position-relative add w-100">
                        <input
                          value={data?.value}
                          onChange={(e) =>
                            handleMatChange(e.target.value, i, "value")
                          }
                          placeholder="Required Field"
                          className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        />
                      </div>
                      {materials?.length > 1 && (
                        <button className="mx-2 d-flex ac-jc cp cust-btn">
                          <DeleteForever
                            className="primary"
                            onClick={() => handleMatDelete(i)}
                          />
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>
              {matVarient?.length == 0 && matVarientErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Materials</p>
                </div>
              )}
            </div>
          )}

          {styles?.length > 0 && attributesName?.includes("Style") && (
            <div className=" w-md-100 w-sm-100 w-xs-100">
              <p className=" black f4 fs-xs-12 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-2 ">
                Style
              </p>
              <div className="d-flex justify-content-start me-3 ">
                <div className="editBtn text-center  rounded-3 mt-2 p-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  <button
                    className="border-0 primary f3 "
                    onClick={() => handleStyleAdd()}
                  >
                    +Add New
                  </button>
                </div>
              </div>
              <div className="d-flex flex-wrap w-100 gap-2">
                {styles?.map((data, i) => {
                  return (
                    <div
                      className="d-flex  w-md-100 w-sm-100 w-xs-100"
                      style={{ width: "30% " }}
                    >
                      <div className="position-relative add w-100">
                        <input
                          value={data?.value}
                          onChange={(e) =>
                            handleStyleChange(e.target.value, i, "value")
                          }
                          placeholder="Required Field"
                          className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        />
                      </div>
                      {styles?.length > 1 && (
                        <button className="mx-2 d-flex ac-jc cp cust-btn">
                          <DeleteForever
                            className="primary"
                            onClick={() => handleStyleDelete(i)}
                          />
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>
              {styleVarient?.length == 0 && styleVarientErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Style</p>
                </div>
              )}
            </div>
          )}

          {details && (
            <div className="w-100 overflow-scroll mt-4">
              <table className="w-100">
                <tr className="mt-4">
                  <div className="">
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 cp fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                            style={{ width: "50px" }}
                          >
                            {check === 1 ? (
                              <CheckBoxOutlined
                                onClick={() => setCheck(undefined)}
                              />
                            ) : (
                              <CheckBoxOutlineBlank
                                onClick={() => setCheck(1)}
                              />
                            )}
                          </p>
                        </div>
                        <div className="mx-2">
                          <p
                            className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-start primary f3"
                            style={{ width: "200px" }}
                          >
                            Variant
                          </p>
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
                <tr className="mt-2">
                  <div className="my-3">
                    <td>
                      <div className="d-flex w-100">
                        <div
                          className="d-flex ac-jc mx-2"
                          style={{ width: "50px" }}
                        >
                          <button className="mx-2 d-flex ac-jc cust-btn">
                            {checkBox || check == 1 ? (
                              <CheckBoxOutlined
                                className="primary cp"
                                onClick={() => setCheckBox(false)}
                              />
                            ) : (
                              <CheckBoxOutlineBlank
                                className="primary cp"
                                onClick={() => setCheckBox(true)}
                              />
                            )}
                          </button>
                        </div>
                        <div className="mx-2">
                          <input
                            class="num-cls-btn  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark px-2"
                            style={{ width: "200px" }}
                            value={"XL/Blue/Gold"}
                            disabled
                          />
                        </div>

                        <button className="mx-2 d-flex ac-jc cp cust-btn">
                          <DeleteForever
                            className="primary"
                            onClick={() => setDetails(false)}
                          />
                        </button>
                      </div>
                    </td>
                  </div>
                </tr>
              </table>
            </div>
          )}
        </div>
      </div>
      <RequestImageUplodeComp
        defaultImage={defaultImage}
        setdefaultImage={setdefaultImage}
        type={type}
        viewData={viewData}
        productImg={productImg}
        smallimageErr={smallimageErr}
        textShow2={textShow2}
        colors={colors}
        colorsName={colorsName}
        setSmallImage={setSmallImage}
        smallimage={smallimage}
        setProductImg={setProductImg}
        colorsData={colorsData}
        setColorsData={setColorsData}
        colorImg={colorsImg}
        setColorsImg={setColorsImg}
        colorImgErr={colorImgErr}
        setColorImgErr={setColorImgErr}
      />
    </div>
  );
};

export default RequestFormComp;
