// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ImageCroper from "../components/Popup/ImageCroper";
import {
  useColorCreateMutation,
  useColorEditMutation,
  useLazyColorViewQuery,
} from "../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import ErrorIcon from "@mui/icons-material/Error";

const   ColourAdd = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [data, setData] = useState("");
  const [action, setAction] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [croper, setCroper] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setData(location?.state?.data);
  }, [location]);

  console.log(data);

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  // RTK QUERY
  const [createColorApi] = useColorCreateMutation();
  const [editColorApi] = useColorEditMutation();
  const [viewColorApi] = useLazyColorViewQuery();

  const [colorName, setColorName] = useState("");
  const [colorCode, setColorCode] = useState("");
  const [hexCode, setHexCode] = useState("");
  const [status, setStatus] = useState(1);

  const [colorNameErr, setColorNameErr] = useState(false);
  const [colorCodeErr, setColorCodeErr] = useState(false);
  const [hexCodeErr, setHexCodeErr] = useState(false);
  const [statusErr, setStatusErr] = useState(false);
  const [btn, setBtn] = useState(false);

  const getView = () => {
    viewColorApi(location?.state?.data?.id)
      .unwrap()
      .then((res) => {
        let data = res?.data;
        setColorCode(data?.code);
        setColorName(data?.name);
        setHexCode(data?.hex_code);
        setStatus(data?.status);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (location?.state?.data?.id) {
      getView();
    }
  }, []);

  const submitHandle = () => {
    if (
      colorName?.length == 0 ||
      colorCode?.length == 0
      // ||
      // status?.length == 0
    ) {
      setColorCodeErr(true);
      setColorNameErr(true);
      // setStatusErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("name", colorName);
      formdata.append("code", colorCode);
      formdata.append("hex_code", hexCode);
      formdata.append("status", status);

      setBtn(true);
      dispatch(saveLoader(true));
      if (location?.state?.data?.id) {
        editColorApi({ payload: formdata, id: location?.state?.data?.id })
          .unwrap()
          .then((res) => {
            setBtn(false);
            dispatch(saveLoader(false));
            navigate("/colours");
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });
      } else {
        createColorApi(formdata)
          .unwrap()
          .then((res) => {
            setBtn(false);
            dispatch(saveLoader(false));
            navigate("/colours");
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });
      }
    }
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <p
        className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-0 mb-3 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon />
        {location?.state?.type == "view"
          ? "View"
          : location?.state?.type == "edit"
          ? "Edit"
          : "Add"}{" "}
        Colour
      </p>
      <div className="w-100 searchField mt-0 bg-white ">
        <div className="w-100 d-flex flex-xxl-row flex-xl-row flex-lg-row flex-column">
          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Colour Name:
              </p>
              <input
                className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                type="text"
                onChange={(e) => setColorName(e.target.value)}
                value={colorName}
                disabled={location?.state?.type == "view" ? true : false}
              />
              {colorName?.length == 0 && colorNameErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Colour Name</p>
                </div>
              )}
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Colour Code:
              </p>
              <input
                className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                type="text"
                onChange={(e) => setColorCode(e.target.value)}
                value={colorCode}
                disabled={location?.state?.type == "view" ? true : false}
              />
              {colorCode?.length == 0 && colorCodeErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Colour Code</p>
                </div>
              )}
            </div>

            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Hex Code:
              </p>

              <input
                className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                type="color"
                style={{ height: "2.5rem" }}
                onChange={(e) => setHexCode(e.target.value)}
                value={hexCode}
                disabled={location?.state?.type == "view" ? true : false}
              />
              {hexCode?.length == 0 && hexCodeErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Hex Code</p>
                </div>
              )}
            </div>

            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Status
              </p>

              <select
                placeholder=""
                className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                name=""
                id=""
                onChange={(e) => setStatus(e.target.value)}
                value={status}
                disabled={location?.state?.type == "view" ? true : false}
              >
                <option value={1}>Active</option>
                <option value={0}>De Active</option>
              </select>
              {status?.length == 0 && statusErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Status</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex ac-jb">
        <button
          onClick={() => navigate(-1)}
          className="pointerView cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
        >
          Back
        </button>
        {location?.state?.type !== "view" && (
          <button
            onClick={() => submitHandle()}
            disabled={btn}
            className={`pointerView cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
              btn && "opacity-50"
            }`}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
};

export default ColourAdd;
