// @ts-nocheck
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ImageCroper from "../components/Popup/ImageCroper";
import ErrorIcon from "@mui/icons-material/Error";
import { useDispatch } from "react-redux";
import {
  useFaqEditMutation,
  useFaqStoreMutation,
  useLazyFaqViewQuery,
} from "../redux/api/api";
import { saveLoader } from "../redux/slice/loaderNoti";
import JoditEditor from "jodit-react";
import DOMPurify from "dompurify";

const FaqAdd = ({ placeholder }) => {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: placeholder || "Start typings...",
    }),
    [placeholder]
  );
  // console.log(content, "text");

  const location = useLocation();
  const [data, setData] = useState("");
  const [action, setAction] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [croper, setCroper] = useState(false);
  const navigate = useNavigate();

  // RTK QUERY
  const [addFaqApi] = useFaqStoreMutation();
  const [editFaqApi] = useFaqEditMutation();
  const [viewFaqApi] = useLazyFaqViewQuery();

  const [question, setQuestion] = useState("");
  const [ques_Err, setQues_Err] = useState(false);
  const [answer, setAnswer] = useState("");
  const [ans_Err, setAns_Err] = useState(false);
  const [prio, setPrio] = useState("");
  const [prio_Err, setPrio_Err] = useState(false);

  const [btn, setBtn] = useState(false);
  const dispatch = useDispatch();
  const type = location?.state?.type;
  // console.log(location?.state?.type);

  // useEffect(() => {
  //   setData(location?.state?.data);
  // }, [location]);

  // console.log(data);

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  const getView = () => {
    viewFaqApi(location?.state?.data?.id)
      .unwrap()
      .then((res) => {
        // console.log(res, "faq")
        let data = res?.setting_detail;
        // console.log('res', res);

        //json decode
        let json = JSON.parse(data?.contents);
        // console.log(json);
        setQuestion(json?.name);
        setAnswer(json?.description);
        setPrio(json?.priority);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (location?.state?.data?.id) {
      getView();
    }
  }, []);

  // const toggleImagePopup = (type, method) => {
  //   setCroper(!croper);
  //   if(method == 'open'){
  //     setImage(type)
  //   }
  // };

  const submitHandle = () => {
    if (question?.length == 0 || answer?.length == 0 || prio?.length == 0) {
      setQues_Err(true);
      setAns_Err(true);
      setPrio_Err(true);
    } else {
      let formdata = new FormData();
      formdata.append("name", question);
      formdata.append("description", answer);
      formdata.append("priority", prio);

      formdata.append("type", 4);

      setBtn(true);
      dispatch(saveLoader(true));
      if (location?.state?.data?.id) {
        formdata.append("setting_id", location?.state?.data?.id);

        editFaqApi(formdata)
          .unwrap()
          .then((res) => {
            setBtn(false);
            dispatch(saveLoader(false));
            navigate("/faq-page");
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });
      } else {
        addFaqApi(formdata)
          .unwrap()
          .then((res) => {
            setBtn(false);
            dispatch(saveLoader(false));
            navigate("/faq-page");
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });
      }
    }
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <p
        className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-0 mb-3 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon /> Faq
        {location?.state?.type == "view"
          ? " View"
          : location?.state?.type == "edit"
          ? " Edit"
          : " Add"}{" "}
      </p>
      <div className="w-100 searchField mt-0 bg-white ">
        <div className="w-100 d-flex flex-xxl-row flex-xl-row flex-lg-row flex-column">
          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Question:
                <span style={{ color: "#f82a2a" }}>(150words only)</span>{" "}
                {type == "add" && type !== "view" && <span>*</span>}
              </p>
              <div className="w-50">
                <textarea
                  className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  type="text"
                  style={{ height: "100px" }}
                  onChange={(e) => setQuestion(e.target.value)}
                  value={question}
                  disabled={type == "view" ? true : false}
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    if (e.target.value.length !== 150) {
                      e.target.setCustomValidity("invalid Number");
                    } else if (e.target.value.length == 150) {
                      e.target.setCustomValidity("");

                      setQuestion(e.target.value);
                    }
                  }}
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  maxlength={150}
                />

                {question?.length == 0 && ques_Err && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Question</p>
                  </div>
                )}
              </div>
            </div>

            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Answer: {type == "add" && type !== "view" && <span>*</span>}
              </p>

              <div className="w-100">
                <p>
                  {type != "view" ? (
                    <JoditEditor
                      className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                      ref={editor}
                      value={answer}
                      config={config}
                      tabIndex={1} // tabIndex of textarea
                      onBlur={(newContent) => setAnswer(newContent)} // preferred to use only this option to update the content for performance reasons
                      onChange={(newContent) => {
                        setAnswer(newContent);
                      }}
                    />
                  ) : (
                    <p
                      className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                      type="text"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          // list?.description
                          answer
                        ),
                      }}
                      // value={description}
                    />
                  )}
                </p>
                {/* <input
                  className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  type="text"
                  onChange={(e) => setAnswer(e.target.value)}
                  value={answer}
                  disabled={type == "view" ? true : false}
                /> */}

                {answer?.length == 0 && ans_Err && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Answer</p>
                  </div>
                )}
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Priority Status:
              </p>
              <div className="w-50">
                <input
                  className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  type="number"
                  onChange={(e) => setPrio(e.target.value)}
                  value={prio}
                  disabled={type == "view" ? true : false}
                />
                {prio?.length == 0 && prio_Err && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Priority Status</p>
                  </div>
                )}
              </div>
            </div>

            {/* <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Status
              </p>

              <select
                placeholder=""
                className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                name=""
                id=""
              >
                <option>Active</option>
                <option>De Active</option>
              </select>
            </div> */}
          </div>
        </div>
      </div>
      <div className="d-flex ac-jb">
        <button
          onClick={() => navigate(-1)}
          className="pointerView cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
        >
          Back
        </button>
        {type !== "view" && (
          <button
            className={`cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${
              btn && "opacity-50"
            }`}
            onClick={submitHandle}
            disabled={btn ? true : false}
          >
            submit
          </button>
        )}
      </div>
    </div>
  );
};

export default FaqAdd;
