// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { VendoroffertypeList, productTableData } from "../redux/api/DummyJson";
import { editIcon, noLogo, toggleOff, toggleOn, viewIcon } from "../assets/img";
import ShippingAddressList from "../components/Customers/ShippingAddressList";
import WarHouse from "../components/WarHouse/WarHouse";
import SingleShipping from "../components/WarHouse/SingleShipping";
import AddProjectPopup from "../components/Popup/AddProjectPopup";
import Select from "react-select";
import ImageCroper from "../components/Popup/ImageCroper";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  useAdminListCustomerReqMutation,
  useCustomerRequestListEditNonMutation,
  useLazyCustomerProductStsQuery,
  useLazyCustomerRequestListTagsQuery,
  useLazyCustomerRequestListViewQuery,
} from "../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import ErrorIcon from "@mui/icons-material/Error";
import AddBoxIcon from "@mui/icons-material/AddBox";

const CustomerRequestViewDetails = (listts) => {
  // RTK QUERY
  const [customerViewApi] = useLazyCustomerRequestListViewQuery();
  const [customerEditApi] = useCustomerRequestListEditNonMutation();
  const [customerAdminApi] = useAdminListCustomerReqMutation();
  const [customerTagsApi] = useLazyCustomerRequestListTagsQuery();
  const [productListStsApi] = useLazyCustomerProductStsQuery();

  const navigate = useNavigate();
  const location = useLocation();
  const methods = location?.state?.method;
  const [data, setData] = useState("");
  const [dataErr, setDataErr] = useState(false);
  const [action, setAction] = useState(false);
  const [method, setMethod] = useState("");
  const [shipping, setShipping] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [requestOrderStatus, setRequestOrderStatus] = useState("");
  const [selectBox, setSelectBox] = useState([]);
  const [croperImage, setCropImage] = useState();
  const [croper, setCroper] = useState(false);
  const [orderRep, setOrderRep] = useState([]);
  const [orderReps, setOrderReps] = useState([]);
  const [clientRep, setClientRep] = useState("");
  const [productType, setProductType] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [tags, setTags] = useState([]);
  const [tagReg, setTagReg] = useState("");
  const [storing, setStoring] = useState("");
  const [offerShow, setOfferShow] = useState(false);
  const [clientShow, setClientShow] = useState(false);
  const [searchValueOffering, setSearchValueOffering] = useState("");
  const [searchClientOffering, setSearchClientOffering] = useState("");
  const [clientOffer, setClientOffer] = useState([]);
  const [Vendorofferty, setVendoroffertypes] = useState([]);
  const [vendorOfferType, setVendorOfferType] = useState("");
  const [clientOfferType, setClientOfferType] = useState("");
  const [clientOfferty, setClientOfferTypes] = useState([]);
  const [selectALLSub, setSelectALLSub] = useState(true);
  //tags States
  const [tagList, setTagsList] = useState([]);
  const [searchTagsList, setSearchTagsList] = useState("");
  const [tagsSelectedId, setTagsSelectedId] = useState([]);
  const [tagsName, setTagsName] = useState([]);
  const [colorIndex, setColorIndex] = useState(0);
  const [logoType, setLogoType] = useState("");

  // console.log("data", data);

  // console.log("location", location);
  const [cusName, setCusName] = useState("");
  const [cusLastName, setCusLastName] = useState("");
  const [reqId, setReqId] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [pack, setPack] = useState("");
  const [productCount, setProductCount] = useState("");
  const [colours, setColours] = useState("");
  const [logos, setLogos] = useState("");
  const [seclogo, setSeclogo] = useState("");
  const [refLogos, setRefLogos] = useState("");
  const [selectAll, setSelectAll] = useState();
  const [notes, setNotes] = useState("");
  const [btn, setBtn] = useState(false);
  const dispatch = useDispatch();
  const type = location?.state?.type;

  const [customerType, setCutomerType] = useState("");
  const [date, setDate] = useState("");
  const [tagNormalList, setTagNormalList] = useState([]);
  const [tagIconList, setTagIconList] = useState([]);
  const [tagNormalsList, setTagNormalsList] = useState([]);
  const [tagIconsList, setTagIconsList] = useState([]);
  const [normalTag, setNormalTag] = useState("");
  const [iconsTag, setIconTag] = useState("");
  const [requestList, setRequestList] = useState([]);
  const [Tags, setTagss] = useState("");
  const [selectALLTags, setSelectALLTags] = useState(false);
  const [selectALLIconTags, setSelectALLIconTags] = useState(false);
  const [searchIconTags, setSearchIconTags] = useState("");
  const [dropDown, setDropDown] = useState(null);
  const [searchTags, setSearchTags] = useState("");
  const [IconTags, setIconTags] = useState("");
  const [reqType, setReqType] = useState("");
  const [requestType, setRequestType] = useState("");
  const [viewList, setList] = useState({});
  const [listImg, setListImg] = useState([{ image: "" }]);
  const primref = useRef();
  const secondyRef = useRef();
  // listImg?.length=10

  // VIEW API
  const getView = () => {
    customerViewApi(location?.state?.data?.id)
      .unwrap()
      .then((res) => {
        // console.log(res);
        let data = res?.project_request;
        setList(data);
        if (data?.request_type == 4) {
          setCusName(data?.user?.first_name);
          setCusLastName(data?.user?.last_name);
        } else {
          setCusName(data?.first_name);
          setCusLastName(data?.last_name);
        }

        setReqId(data?.project_request_id);
        setEmail(data?.email);
        // setNumber(data?.user?.mobile);
        setNumber(data?.mobile);
        setPack(data?.no_of_packs);
        setProductCount(data?.priority);
        setColours(data?.project_request_details?.no_of_colors);
        setRefLogos(data?.priority);
        setDate(data?.when_needed);
        setProductCount(data?.project_request_details?.length);
        setNormalTag(data?.order_rep?.first_name);
        setTagss(data?.order_rep?.id);
        setIconTag(data?.client_rep?.first_name);
        setIconTags(data?.client_rep?.id);
        setRequestList(data?.project_request_details);
        // setLogos(data?.primary_logo);
        setSeclogo(data?.secondary_logo);
        setReqType(data?.request_type);
        setRequestType(data?.request_type);
        setRefLogos(data?.image_count);
        setNotes(data?.notes);
        if (data?.status == "Request") {
          setRequestOrderStatus(1);
        } else if (data?.status == "progress") {
          setRequestOrderStatus(2);
        } else if (data?.status == "Collabrate") {
          setRequestOrderStatus(3);
        } else if (data?.status == "Presentation ready") {
          setRequestOrderStatus(4);
        } else if (data?.status == "Presentation accept") {
          setRequestOrderStatus(5);
        } else if (data?.status == "Presentation reject") {
          setRequestOrderStatus(6);
        } else if (data?.status == "Estimation ready") {
          setRequestOrderStatus(7);
        } else if (data?.status == "Estimation Accept") {
          setRequestOrderStatus(8);
        } else if (data?.status == "Estimation reject") {
          setRequestOrderStatus(9);
        } else {
          setRequestOrderStatus(10);
        }
        const id = [];
        const name = [];
        data?.normalTags?.map((item) => {
          id.push(item?.id);

          name?.push(item?.name);
        });

        let imgArr = [];

        data?.images?.map((item, ind) => {
          imgArr.push({
            ...item,
            image: item?.image_url,
          });
        });

        setListImg(imgArr);
        setTagsSelectedId(id);
        setTagsName(name);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (location?.state?.data?.id) {
      getView();
    }
  }, []);

  // CUSTOMER TAGS LIST
  const getTagList = () => {
    customerTagsApi(location?.state?.data?.id)
      .unwrap()
      .then((ress) => {
        if (ress?.status == "success") {
          setTags(ress?.normalTag);
        }
        // console.log(ress);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (location?.state?.data?.id) {
      getTagList();
    }
  }, []);

  // ADMIN LIST CUSTOMER REQ
  const getViewAdmin = () => {
    customerAdminApi(location?.state?.data?.id)
      .unwrap()
      .then((ress) => {
        // console.log(ress);
        let profile = ress?.admins;
        setTagNormalList(profile);
        setTagNormalsList(profile);
        setTagIconList(profile);
        setTagIconsList(profile);
        // setClientRep(profile);
        // setOrderReps(profile?.first_name);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  // procuct Sts
  const getProductSts = (id) => {
    productListStsApi(id)
      .unwrap()
      .then((ress) => {
        // console.log(ress);
        getView();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (location?.state?.data?.id) {
      getViewAdmin();
    }
  }, []);
  // console.log("admin", data);

  // EDIT API
  const submitHandle = () => {
    if (data == "") {
      setDataErr(true);
    } else {
      let formdata = new FormData();
      if (pack) {
        formdata.append("no_of_packs", pack);
      }
      if (Tags) {
        formdata.append("order_rep", Tags);
      }
      if (IconTags) {
        formdata.append("client_rep", IconTags);
      }
      if (requestOrderStatus) {
        formdata.append("status", requestOrderStatus);
      }
      // if (logos?.name) {
      //   formdata.append("primary_logo", logos);
      // }
      // if (seclogo?.name) {
      //   formdata.append("secondary_logo", seclogo);
      // }
      if (date) {
        formdata.append("when_needed", date);
      }
      tagsSelectedId?.map((item, ind) => {
        formdata.append(`tags[${ind}]`, item);
      });
      if (reqType) {
        formdata.append("request_type", reqType);
      }
      if (notes) {
        formdata.append("notes", notes);
      }

      listImg?.map((item, ind) => {
        if (item?.image?.name) {
          formdata.append(`images[${ind}][image]`, item?.image);
          formdata.append(`images[${ind}][type]`, ind + 1);
        }
      });

      setBtn(true);
      dispatch(saveLoader(true));

      customerEditApi({ payload: formdata, params: location?.state?.data?.id })
        .unwrap()
        .then((res) => {
          setBtn(false);
          dispatch(saveLoader(false));
          getView();
          // navigate("/customer-request-list");
        })
        .catch((err) => {
          setBtn(false);
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    }
    // }
  };
  // console.log(tagNormalList,"tagNormalList")

  useEffect(() => {
    setData(location?.state?.data);
    if (methods == "enterprises") {
      setCutomerType("Enterprises");
    } else if (methods !== "enterprises" && customerType == "Enterprises") {
      navigate("/add-enterprises");
    }
  }, [location, customerType]);

  const toggleShowPopup = () => {
    setShowPopup(!showPopup);
  };

  // console.log("datadatadata", date);

  let options = [
    { value: "Bottle", label: "Bottle" },
    { value: "Shirts", label: "Shirts" },
    { value: "T-Shirts", label: "T-Shirts" },
  ];

  const onTagInputChange = (e) => {
    let tmp = {
      value: e,
      label: e,
      type: "input",
    };
    let filtered = options?.filter((item) => item?.type === "input");
    if (filtered?.length === 0) {
      options.push(tmp);
    } else {
      options.pop();
      options.push(tmp);
    }
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  const onTagsSelect = (event, id, type) => {
    setNormalTag(event);
    setTagss(id);

    if (type !== "check") {
      setDropDown(null);
    }
    setSearchTags("");
    setTagNormalList(tagNormalsList);
  };
  const searchList = (event) => {
    let temp = [...tags];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setTagsList(search);
    } else {
      setTagsList(tags);
    }

    setSearchTagsList(event);
  };
  const searchTagsFun = (event) => {
    let temp = [...tagNormalsList];
    let search = temp.filter((value) =>
      value?.first_name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setTagNormalList(search);
    } else {
      setTagNormalList(tagNormalsList);
    }

    setSearchTags(event);
  };
  // TAGS END
  const searchTagsListfun = (event) => {
    let temp = [...tags];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setTagsList(search);
    } else {
      setTagsList(tags);
    }

    setSearchTagsList(event);
  };
  // Decoration Method START
  const allSelectSub = () => {
    let decorativeListId = [];
    let decorativeListName = [];
    if (selectALLSub) {
      tags?.map((item, ind) => {
        decorativeListId.push(item?.id);
        decorativeListName.push(item?.name);
        // setDecorationMethod(item?.id);
      });

      setTagsSelectedId(decorativeListId);
      setTagsName(decorativeListName);
    } else {
      setTagsSelectedId(decorativeListId);
      setTagsName(decorativeListName);
    }
  };
  // TAGS ICON START
  const onSelectTags = (event, id, type) => {
    let temp = [...tagsName];
    let temps = [...tagsSelectedId];

    if (temp.includes(event)) {
      const listtemp = temp.indexOf(event);
      const list = [...temp];
      list.splice(listtemp, 1);
      setTagsName(list);
    } else {
      setTagsName([...temp, event]);
    }

    if (temps.includes(id)) {
      const listtemp = temps.indexOf(id);
      const list = [...temps];
      list.splice(listtemp, 1);
      setTagsSelectedId(list);
    } else {
      setTagsSelectedId([...temps, id]);
    }

    if (type !== "check") {
      setDropDown(null);
    }

    setSearchTagsList("");
    setTagsList(tags);
  };
  // TAGS ICON START
  const allSelectTagsIcon = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLIconTags) {
      tagIconsList?.map((item, ind) => {
        ListId.push(item?.id);
        ListName.push(item?.first_name);
      });

      setIconTag(ListId);
      setIconTags(ListName);
    } else {
      setIconTag(ListId);
      setIconTags(ListName);
    }

    setSelectALLIconTags(!selectALLIconTags);
  };

  const onTagsSelectIcon = (event, id, type) => {
    setIconTag(event);
    setIconTags(id);
    // let temp = [...iconsTag];
    // let temps = [...IconTags];

    // if (temp.includes(id)) {
    //   const listtemp = temp.indexOf(id);
    //   const list = [...temp];
    //   list.splice(listtemp, 1);
    //   setIconTag(list);
    // } else {
    //   setIconTag([...temp, id]);
    // }

    // if (temps.includes(event)) {
    //   const listtemp = temps.indexOf(event);
    //   const list = [...temps];
    //   list.splice(listtemp, 1);
    //   setIconTags(list);
    // } else {
    //   setIconTags([...temps, event]);
    // }

    if (type !== "check") {
      setDropDown(null);
    }

    setSearchIconTags("");
    setTagIconList(tagIconsList);
  };

  const searchTagsFunIcon = (event) => {
    let temp = [...tagIconsList];
    let search = temp.filter((value) =>
      value?.first_name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setTagIconList(search);
    } else {
      setTagIconList(tagIconsList);
    }

    setSearchIconTags(event);
  };
  // TAGS ICON END

  // Vendor Offering Type
  const searchVendorOffering = (event) => {
    // let temp = [...VendoroffertypeList];

    let temp = [...orderRep];
    let search = temp.filter((value) =>
      value?.first_name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setVendoroffertypes(search);
    } else {
      setVendoroffertypes(orderRep);
    }

    setSearchValueOffering(event);
  };
  useEffect(() => {
    setVendoroffertypes(orderRep);
  }, [orderRep]);

  // Vendor Offering Type
  const searchCustomerType = (event) => {
    // let temp = [...VendoroffertypeList];

    let temp = [...clientRep];
    let search = temp.filter((value) =>
      value?.first_name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setClientOfferTypes(search);
    } else {
      setClientOfferTypes(clientRep);
    }

    setSearchClientOffering(event);
  };
  useEffect(() => {
    setClientOfferTypes(clientRep);
    setTagsList(tags);
  }, [clientRep, tags]);

  // console.log("tagNormalList", requestOrderStatus);

  const handelImg = (event, ind, type) => {
    console.log("ind", ind, type);
    let temp = [...listImg];
    if (event) {
      if (temp[0].image == "") {
        temp[0] = {
          image: event,
        };
      } else {
        if (type == "img") {
          temp[ind] = {
            ...temp[ind],
            image: event,
          };
        } else {
          temp.push({ image: event });
        }
      }
      // if (event?.name) {
      //   temp[ind] = {
      //     ...temp[ind],
      //     image: event,
      //   };
      // }

      setListImg(temp);
    }
    setLogoType("");
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <p
        className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon />{" "}
        {location?.state?.listts === "registeredCustomerList"
          ? "Registered Request Details"
          : "Non-Registered Request Details"}
      </p>
      <div className="w-100 searchField mt-5 bg-white ">
        <div className="w-100 d-flex flex-xxl-row flex-xl-row flex-lg-row flex-column">
          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
            {/* <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                {methods == "enterprises" ? "Enterprises" : "Customer"} Name:
                {type == "add" && type !== "view" && <span>*</span>}
              </p>
              <input
                className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-capitalize"
                onChange={(e) => setCusName(e.target.value)}
                value={cusName}
                disabled
              />
            </div> */}
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                First Name:
                {type == "add" && type !== "view" && <span>*</span>}
              </p>
              <input
                className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-capitalize"
                onChange={(e) => setCusName(e.target.value)}
                value={cusName}
                disabled
              />
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Last Name:
                {type == "add" && type !== "view" && <span>*</span>}
              </p>
              <input
                className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-capitalize"
                onChange={(e) => setCusLastName(e.target.value)}
                value={cusLastName}
                disabled
              />
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Request ID:{type == "add" && type !== "view" && <span>*</span>}
              </p>
              <input
                className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => setReqId(e.target.value)}
                value={reqId}
                disabled
              />
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                {methods == "enterprises" ? "Enterprises" : "Customer"} Email:
                {type == "add" && type !== "view" && <span>*</span>}
              </p>

              <input
                className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                disabled
              />
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                {methods == "enterprises" ? "Enterprises" : "Customer"} Phone
                Number:{type == "add" && type !== "view" && <span>*</span>}
              </p>
              <input
                className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => setNumber(e.target.value)}
                value={number}
                disabled
              />
            </div>

            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Products Request Count:
                  {type == "add" && type !== "view" && <span>*</span>}
                </p>
                {/* <div className="w-70 ms-3"> */}
                <input
                  disabled
                  placeholder=""
                  className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  onChange={(e) => setProductCount(e.target.value)}
                  value={productCount}
                />
                {/* </div> */}
              </div>
            )}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Order Rep Name:
                  {type == "add" && type !== "view" && <span>*</span>}
                </p>

                <div className="w-50 position-relative">
                  {/* <div className="w-70 ms-3 position-relative"> */}
                  <input
                    placeholder="Tags"
                    type="text"
                    value={
                      normalTag?.length > 0
                        ? normalTag
                        : "Select Order Rep Name"
                    }
                    disabled={type == "view" ? true : false}
                    onClick={() => {
                      setDropDown(3);
                    }}
                    className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                  <button
                    className="drop_down cust-btn"
                    onClick={() => {
                      setDropDown(3);
                    }}
                    disabled={type == "view" ? true : false}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                  {dropDown == 3 && (
                    <div
                      className="invisible-cont2 z-0"
                      onClick={() => setDropDown(null)}
                    />
                  )}
                  <div
                    className={`${
                      dropDown == 3 && "submenu_1  py-2"
                    } submenu_cont_1 overflow-scroll z-3`}
                  >
                    <div className="d-flex gap-1">
                      <input
                        className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                        placeholder="Search..."
                        onChange={(e) => searchTagsFun(e.target.value)}
                        value={searchTags}
                      />
                    </div>
                    {tagNormalList?.map((item, ind) => {
                      return (
                        <button
                          className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                          key={ind}
                        >
                          <button
                            className="px-2 cust-btn text-start py-1 w-100 "
                            onClick={() => {
                              onTagsSelect(item?.first_name, item?.id);
                            }}
                          >
                            {item?.first_name}
                          </button>
                        </button>
                      );
                    })}
                  </div>
                  {/* </div> */}
                </div>
              </div>
            )}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Client Rep Name:
                  {type == "add" && type !== "view" && <span>*</span>}
                </p>
                <div className="w-50 position-relative">
                  {/* <div className="w-70 ms-3 position-relative"> */}
                  <input
                    placeholder="Tags"
                    type="text"
                    disabled={type == "view" ? true : false}
                    value={
                      iconsTag?.length > 0
                        ? iconsTag?.toString()
                        : "Select Client Rep Name"
                    }
                    onClick={() => {
                      setDropDown(4);
                    }}
                    className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                  <button
                    className="drop_down cust-btn"
                    disabled={type == "view" ? true : false}
                    onClick={() => {
                      setDropDown(4);
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                  {dropDown == 4 && (
                    <div
                      className="invisible-cont2 z-0"
                      onClick={() => setDropDown(null)}
                    />
                  )}
                  <div
                    className={`${
                      dropDown == 4 && "submenu_1  py-2"
                    } submenu_cont_1 overflow-scroll z-3`}
                  >
                    <div className="d-flex gap-1">
                      <input
                        className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                        placeholder="Search..."
                        onChange={(e) => searchTagsFunIcon(e.target.value)}
                        value={searchIconTags}
                      />
                    </div>
                    {tagIconList?.map((item, ind) => {
                      return (
                        <button
                          className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                          key={ind}
                        >
                          <button
                            className="px-2 cust-btn text-start py-1 w-100 "
                            onClick={() => {
                              onTagsSelectIcon(item?.first_name, item?.id);
                            }}
                          >
                            {item?.first_name}
                          </button>
                        </button>
                      );
                    })}
                  </div>
                  {/* </div> */}
                </div>
              </div>
            )}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Production Rep:
                  {type == "add" && type !== "view" && <span>*</span>}
                </p>
                <select
                  disabled={type == "view" ? true : false}
                  placeholder="Production Rep" // onChange={(e) => setPack(e.target.value)}
                  // value={pack}
                  className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 rounded-3 mt-2"
                >
                  <option>Production Rep 1</option>
                  <option>Production Rep 2</option>
                  <option>Production Rep 3</option>
                </select>
              </div>
            )}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Organization Name:
                  {type == "add" && type !== "view" && <span>*</span>}
                </p>
                {/* <div className="w-70 ms-3"> */}
                <input
                  disabled={type == "view" ? true : false}
                  placeholder="Organization  Name"
                  className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  value={viewList?.organisation}
                  // onChange={(e) => setPack(e.target.value)}
                  // value={pack}
                />
                {/* </div> */}
              </div>
            )}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  {methods == "enterprises" ? "Enterprises" : "Customer"} Type:
                </p>
                {/* <div className="w-70 ms-3"> */}
                <select
                  // disabled={type == "view" ? true : false}
                  placeholder=""
                  className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  name=""
                  id=""
                  value={customerType}
                  onChange={(e) => setCutomerType(e.target.value)}
                  // disabled={methods == "enterprises" ? true : false}
                >
                  <option>E-Commerce</option>
                  <option>Enterprises</option>
                </select>
                {/* </div> */}
              </div>
            )}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Request Type:
                  {type == "add" && type !== "view" && <span>*</span>}
                </p>
                {/* <div className="w-70 ms-3"> */}
                <select
                  disabled={type == "view" ? true : false}
                  placeholder=""
                  className="w-50  editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 rounded-3 mt-2"
                  name=""
                  id=""
                  onChange={(e) => setReqType(e.target.value)}
                  value={reqType}
                >
                  <option value={""}> select Request Type </option>
                  <option value={1}>Individual/Design Kit Pack</option>
                  <option value={2}>Preset Pack</option>
                  <option value={3}>Bulk Pack</option>
                  <option value={4}>Ecommerce Pack</option>
                </select>
                {/* </div> */}
              </div>
            )}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Request Order Status:
                  {type == "add" && type !== "view" && <span>*</span>}
                </p>
                {/* <div className="w-70 ms-3"> */}
                <select
                  disabled={type == "view" ? true : false}
                  placeholder=""
                  className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 rounded-3 mt-2"
                  name=""
                  id=""
                  onChange={(e) => setRequestOrderStatus(e.target.value)}
                  value={requestOrderStatus}
                >
                  <option value={1}>Request</option>
                  <option value={2}>Move to project</option>
                  <option value={3}>Rejected</option>
                  {/* <option value={2}>In-process</option>
                  <option value={3}>Collaborate</option>
                  <option value={4}>Presentation Ready</option>
                  <option value={5}>Presentation Accept</option>
                  <option value={6}>Presentation Reject</option>
                  <option value={7}>Estimation Ready</option>
                  <option value={8}>Estimation Accept</option>
                  <option value={9}>Estimation Reject</option>
                  <option value={10}>Customer Paid</option> */}
                </select>
                {/* </div> */}
              </div>
            )}
            {data != undefined && (
              // <div className="w-100 d-flex ac-js mt-3">
              //   <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              //     Tags:{type == "add" && type !== "view" && <span>*</span>}
              //   </p>
              //   <div className="w-70 ms-3">
              //     <select
              //       className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 rounded-3 mt-2"
              //       onChange={(e) => setTagReg(e.target.value)}
              //       disabled={type == "view" ? true : false}
              //       value={tagReg}
              //     >
              //       <option>Tags</option>
              //       {tags?.map((admins, ind) => (
              //         <option key={admins?.id} value={admins?.id}>
              //           {admins?.name}
              //         </option>
              //       ))}
              //     </select>
              //   </div>
              // </div>
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Tags:
                  {type == "add" && type !== "view" && <span>*</span>}
                </p>
                <div className="w-50 position-relative">
                  {/* <div className="w-70 ms-3 position-relative"> */}
                  <input
                    placeholder="Tags"
                    type="text"
                    disabled={type == "view" ? true : false}
                    value={
                      tagsName?.length > 0
                        ? tagsName?.toString()
                        : "Select Client Rep Name"
                    }
                    onClick={() => {
                      setDropDown(5);
                    }}
                    className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                  <button
                    className="drop_down cust-btn"
                    disabled={type == "view" ? true : false}
                    onClick={() => {
                      setDropDown(5);
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                  {dropDown == 5 && (
                    <div
                      className="invisible-cont2 z-0"
                      onClick={() => setDropDown(null)}
                    />
                  )}
                  <div
                    className={`${
                      dropDown == 5 && "submenu_1  py-2"
                    } submenu_cont_1 overflow-scroll z-3`}
                  >
                    <div className="d-flex gap-1">
                      <input
                        className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                        placeholder="Search..."
                        onChange={(e) => searchTagsListfun(e.target.value)}
                        value={searchTagsList}
                      />
                      <button
                        className="border-0 bg-transparent"
                        onClick={() => {
                          setSelectALLSub(!selectALLSub);
                          allSelectSub();
                        }}
                      >
                        All
                      </button>
                    </div>
                    {tagList?.map((item, ind) => {
                      return (
                        <button
                          className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                          key={ind}
                        >
                          <button
                            className="px-2 cust-btn text-start py-1 w-100 "
                            onClick={() => {
                              onSelectTags(item?.name, item?.id);
                            }}
                          >
                            {item?.name}
                          </button>
                          <button
                            className="px-2 cust-btn text-start"
                            onClick={() => {
                              onSelectTags(item?.name, item?.id, "check");
                            }}
                          >
                            {tagsName?.includes(item?.name) ? (
                              <CheckCircleIcon className="primary" />
                            ) : (
                              <RadioButtonUncheckedIcon className="primary" />
                            )}
                          </button>
                        </button>
                      );
                    })}
                  </div>
                  {/* </div> */}
                </div>
              </div>
            )}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  In Hands Date:
                  {type == "add" && type !== "view" && <span>*</span>}
                </p>
                {/* <div className="w-70 ms-3"> */}
                <input
                  disabled={type == "view" ? true : false}
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  type="date"
                  id="from"
                  name="from"
                  className="cust-btn searchFill w-50 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                />
                {/* </div> */}
                {date?.length == 0 && dataErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Select Date</p>
                  </div>
                )}
              </div>
            )}

            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Number of logos:
                  {type == "add" && type !== "view" && <span>*</span>}
                </p>

                {/* <div className="w-70 ms-3"> */}
                <input
                  disabled
                  placeholder=""
                  className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  value={listImg?.length}
                  // onChange={(e) => setRefLogos(e.target.value)}
                  // value={refLogos}
                  disabled={type == "view" ? true : false}
                />
                {/* </div> */}
              </div>
            )}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Reference logo & count:
                  {type == "add" && type !== "view" && <span>*</span>}
                </p>
                <div className="d-flex align-items-center flex-wrap w-70">
                  {/* <div className="d-flex"> */}
                  <div className="d-flex ms-3 mb-2 gap-2 flex-wrap">
                    {listImg?.map((item, ind) => {
                      return (
                        <div className="d-flex flex-column ac-jc" key={ind}>
                          {item?.image ? (
                            <div className="position-relative for_hover">
                              <img
                                alt="icon"
                                src={
                                  item?.image?.name
                                    ? URL.createObjectURL(item?.image)
                                    : item?.image
                                }
                                className="add_img object-fit-fill"
                                onClick={() => {
                                  setLogoType("img");
                                  setColorIndex(ind);
                                  primref.current.click();
                                }}
                              />
                            </div>
                          ) : (
                            <div className="gray ms-2 pointer">
                              <AddBoxIcon
                                onClick={() => {
                                  setColorIndex(ind);
                                  primref.current.click();
                                }}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                    <input
                      disabled={type == "view" ? true : false}
                      type="file"
                      onChange={(e) =>
                        handelImg(e.target.files[0], colorIndex, logoType)
                      }
                      accept=".pdf,.doc,.jpeg,.docx,.png,.jpg"
                      className="d-none"
                      ref={primref}
                      // disabled
                    />

                    {listImg?.[0]?.image && listImg?.length < 10 && (
                      <div className="gray ms-2 pointer my-4">
                        <AddBoxIcon
                          onClick={() => {
                            // setColorIndex(ind);
                            primref.current.click();
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {/* </div> */}
                </div>
              </div>
            )}

            {/* {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Storing Method:
                  {type == "add" && type !== "view" && <span>*</span>}
                </p>
                {/* <div className="w-70 ms-3"> // commend
                <select
                  disabled={type == "view" ? true : false}
                  placeholder=""
                  className="w-50  editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 rounded-3 mt-2"
                  name=""
                  onChange={(e) => setMethod(e.target.value)}
                >
                  <option selected hidden value="">
                    Select Method
                  </option>
                  <option value="WareHouse">WareHous</option>
                  <option value="Shipping">Shipping</option>
                </select>
                {/* </div> // commend
              </div>
            )} */}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Note:
                  {type == "add" && type !== "view" && <span>*</span>}
                </p>

                {/* <div className="w-70 ms-3"> */}
                <input
                  disabled
                  placeholder=""
                  className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  // value={data?.NoOfLog}
                  onChange={(e) => setNotes(e.target.value)}
                  value={notes}
                  disabled={type == "view" ? true : false}
                />
                {/* </div>/ */}
              </div>
            )}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Per Pack Price:
                  {type == "add" && type !== "view" && <span>*</span>}
                </p>

                {/* <div className="w-70 ms-3"> */}
                <input
                  disabled
                  placeholder=""
                  className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  value={viewList?.price_per_pack}
                  disabled
                />
                {/* </div> */}
              </div>
            )}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Total Price:
                  {type == "add" && type !== "view" && <span>*</span>}
                </p>

                {/* <div className="w-70 ms-3"> */}
                <input
                  disabled
                  placeholder=""
                  className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  value={viewList?.total_amount}
                  disabled
                />
                {/* </div> */}
              </div>
            )}
            {data != undefined && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  No of Packs:
                  {type == "add" && type !== "view" && <span>*</span>}
                </p>
                {/* <div className="w-70 ms-3"> */}
                <input
                  disabled={type == "view" ? true : false}
                  placeholder=""
                  className="p-cust editBtn w-50 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  onChange={(e) => setPack(e.target.value)}
                  value={pack}
                />
                {/* </div> */}
              </div>
            )}

            {method === "Shipping" && (
              <div className="w-100 d-flex ac-js mt-3">
                <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                  Shipping Method:
                  {type == "add" && type !== "view" && <span>*</span>}
                </p>
                <div className="w-70 ms-3">
                  <select
                    placeholder=""
                    className="w-50  editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 rounded-3 mt-2"
                    name=""
                    onChange={(e) => setShipping(e.target.value)}
                  >
                    <option selected hidden value="">
                      Select Method
                    </option>
                    <option value="Single">Single Shipping</option>
                    <option value="Multiple">Multiple Shipping</option>
                  </select>
                </div>
              </div>
            )}
          </div>
          <div className="w-xxl-15 w-xl-15 w-lg-15 position-relative">
            {type == "view" ? (
              <button className="cust-btn addbtn mt-4 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
                view
              </button>
            ) : (
              <button
                className="cust-btn addbtn mt-4 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                onClick={() => {
                  submitHandle();
                }}
              >
                Submit
              </button>
            )}

            {action && (
              <>
                {/* <div
                onClick={() => navigate("/order-list")}
                className="w-xxl-100 w-xl-100 w-lg-100 w-20 actionDetailPopup searchField pointerView py-2 ac-jc rounded-3"
              >
                <h6 className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14">
                  Create Order
                </h6>
              </div> */}
                <div className="w-xxl-100 w-xl-100 w-lg-100 w-20 mt-2 actionDetailPopup searchField pointerView py-2 ac-jc rounded-3">
                  {requestOrderStatus == 1 && (
                    <h6
                      className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14"
                      onClick={() => toggleShowPopup()}
                    >
                      Create Project
                    </h6>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        {method === "WareHouse" && <WarHouse setShipping={setShipping} />}
        {shipping === "Single" && <SingleShipping />}
        {shipping === "Multiple" && (
          <div className="w-100 ac-js mt-5">
            <p className=" d-flex ac-jb f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              Shipping Address:
              {type == "add" && type !== "view" && <span>*</span>}
              <button
                onClick={() =>
                  navigate("/view-customer-details", {
                    state: { type: "shipping" },
                  })
                }
                className="cust-btn addbtn mt-sm-3 ms-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
              >
                + Add Shipping Address
              </button>
            </p>
            <ShippingAddressList />
          </div>
        )}
        <div className="w-100">
          <p className="d-flex ac-jb f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5">
            Requested Product List
            <span className="d-flex gap-2">
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont me-3 mt-4">
                Total Count : {requestList?.length}
              </p>
              {data != undefined && (
                <button
                  onClick={() =>
                    navigate("/ordered-view-details", {
                      state: { data: viewList },
                    })
                  }
                  className=" cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                >
                  + Add
                </button>
              )}
            </span>
          </p>
          <div className="w-100 mt-3 overflow-scroll">
            <div className="wholeCustomerDet d-flex mt-4 border-bottom pb-3 ">
              <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black text-center text-nowrap d-flex as-jc">
                Sl.No
              </p>
              <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black text-center text-nowrap d-flex as-jc">
                Category Type
              </p>
              <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black text-center text-nowrap d-flex as-jc">
                Product Name
              </p>
              <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black text-center text-nowrap d-flex as-jc">
                No of Packs
              </p>
              <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black text-center text-nowrap d-flex as-jc">
                Quantity Per Pack
              </p>
              <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black text-center text-nowrap d-flex as-jc">
                Price
              </p>
              <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black text-center text-nowrap d-flex as-jc">
                No of Color
              </p>
              <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black text-center text-nowrap d-flex as-jc">
                Color
              </p>
              {+requestType == 4 ? (
                <>
                  <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black text-center text-nowrap d-flex as-jc">
                    Hex Code
                  </p>
                  <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black text-center text-nowrap d-flex as-jc">
                    Color
                  </p>
                  <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black text-center text-nowrap d-flex as-jc">
                    Size
                  </p>
                  <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black text-center text-nowrap d-flex as-jc">
                    Material
                  </p>
                  <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black text-center text-nowrap d-flex as-jc">
                    Style
                  </p>
                </>
              ) : (
                <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black text-center text-nowrap d-flex as-jc">
                  Hex Code
                </p>
              )}
              <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black text-center text-nowrap d-flex as-jc">
                Status
              </p>
              <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black text-center text-nowrap d-flex as-jc">
                Options
              </p>
            </div>
            {requestList?.map((item, index) => {
              let sku_spliter = item?.product_sku_id?.variant_name?.split("/");
              return (
                <div className="wholeCustomerDet d-flex mt-4 border-bottom pb-3">
                  <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
                    {index + 1}
                  </p>
                  <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
                    {item?.product?.category?.name}
                  </p>
                  <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
                    {item?.product?.name}
                  </p>
                  <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
                    {pack}
                  </p>
                  <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
                    {item?.qty}
                  </p>
                  <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
                    {item?.price}
                  </p>
                  <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
                    {item?.no_of_colors}
                  </p>
                  {/* <div className="d-flex ac-js"> */}
                  <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
                    {item?.product_sku_id?.color?.name}
                  </p>
                  {/* </div> */}
                  {+requestType == 4 ? (
                    <>
                      <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
                        {item?.product_sku_id?.color?.hex_code}
                      </p>
                      <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
                        {sku_spliter?.[1] ? sku_spliter?.[1] : ""}
                      </p>
                      <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
                        {sku_spliter?.[2] ? sku_spliter?.[2] : ""}
                      </p>
                      <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
                        {sku_spliter?.[3] ? sku_spliter?.[3] : ""}
                      </p>
                      <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
                        {sku_spliter?.[4] ? sku_spliter?.[4] : ""}
                      </p>
                    </>
                  ) : (
                    <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc primary1">
                      {item?.product_sku_id?.color?.hex_code}
                    </p>
                  )}
                  <div className="w-20 d-flex justify-content-center">
                    <div onClick={() => getProductSts(item?.id)}>
                      {item?.status == 1 ? (
                        <img src={toggleOn} className="toggleOnDes" />
                      ) : (
                        <img src={toggleOff} className="toggleOnDes" />
                      )}
                    </div>
                  </div>

                  <div className="w-20 d-flex ac-jc">
                    <div
                      onClick={() =>
                        navigate("/ordered-view-details", {
                          state:
                            data != undefined
                              ? {
                                  data: item,
                                  ind: index + 1,
                                }
                              : { undefined },
                        })
                      }
                      className="viewBoxDes pointerView p-2 bg-white rounded-2 mx-2"
                    >
                      <img src={viewIcon} className="viewDes" />
                    </div>
                    {data != undefined && (
                      <div
                        onClick={() =>
                          navigate("/ordered-view-details", {
                            state: {
                              data: item,
                              ind: index + 1,
                            },
                          })
                        }
                        className="viewBoxDes p-2 bg-white rounded-2"
                      >
                        <img src={editIcon} className="viewDes" />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="w-90 ac-jb flex-md-row flex-column d-flex mt-5">
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Back
          </button>
          {/* {location?.state?.type === "edit" && (
            <button className=" cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
              Submit
            </button>
          )} */}
          {location?.state?.type === "edit" && (
            <button
              className={`cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${
                btn && "opacity-50"
              }`}
              onClick={() => {
                submitHandle();
              }}
              disabled={btn ? true : false}
            >
              Submit
            </button>
          )}
        </div>
      </div>

      {showPopup && (
        <AddProjectPopup
          toggleShowPopup={toggleShowPopup}
          popupType={"customerReq"}
        />
      )}
    </div>
  );
};

export default CustomerRequestViewDetails;
