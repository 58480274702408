import React, { useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Filter,
  Filter1Outlined,
  FilterAlt,
  Search,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { animatedImg, product, searchIcon } from "../../assets/img";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const PriceChange = ({
  priceChangeList,
  setPriceChangeList,
  fromDate,
  toDate,
  search,
  setSearch,
  pageChange,
  setPageChange,
  setFromDate,
  setTodate,
  getPriceList,
  listCat,
  catValuePrice,
  statusValuePrice,
  priceChangePagination,
  priceRow,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [pagenation, setPagination] = useState({});

  // console.log("location", location);

  const navigatePath = (item) => {
    let obj = {
      item: item,
      data: location?.state?.data,
    };

    if (location?.pathname == "/product-vendor-details") {
      obj.method = "vendor";
    }

    navigate("/map-request-view", {
      state: obj,
    });
  };

  return (
    <>
      <div className="d-flex ac-jb flex-column flex-md-row">
        <div className="d-flex gap-3">
          <select
            className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3"
            onChange={(e) =>
              getPriceList(
                search,
                "",
                statusValuePrice,
                e.target.value,
                priceRow
              )
            }
            value={catValuePrice}
          >
            <option value={""}>Select Categories</option>
            {listCat?.map((item, ind) => {
              return (
                <option key={ind} value={item?.id}>
                  {item?.name}
                </option>
              );
            })}
          </select>
          <select
            className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3"
            onChange={(e) =>
              getPriceList(search, "", e.target.value, catValuePrice, priceRow)
            }
            value={statusValuePrice}
          >
            <option value={""}>Select Status</option>
            <option value={3}>Price Change Request</option>
            <option value={6}>Price Change Inprogress</option>
            <option value={7}>Price Change Rejected</option>
          </select>
        </div>
        <div className="d-flex ac-jb flex-column flex-md-row gap-3 justify-content-md-end">
          <div className="d-flex  border-search">
            <img src={searchIcon} alt="img" className="searchiConImg mt-1" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
              onChange={(e) =>
                getPriceList(
                  e.target.value,
                  "",
                  statusValuePrice,
                  catValuePrice,
                  priceRow
                )
              }
              value={search}
            />
          </div>
          <div className="d-flex ac-jc gap-3">
            <button
              className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
              onClick={(e) => {
                getPriceList("", "", "", "");
                setFromDate("");
                setTodate("");
              }}
            >
              <CachedIcon />
            </button>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-4 flex-wrap">
        <div className="d-flex ac-jb w-xs-100 mt-xs-5 position-relative">
          <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary me-3">
            From
          </p>
          <input
            type="date"
            id="from"
            name="from"
            value={fromDate}
            onChange={(e) => {
              setFromDate(e.target.value);
            }}
            className="cust-btn searchFill w-xs-100 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
          />
          <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mx-3">
            To
          </p>
          <input
            type="date"
            id="from"
            name="from"
            value={toDate}
            onChange={(e) => {
              setTodate(e.target.value);
            }}
            className="cust-btn searchFill w-xs-100 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
          />
        </div>
      </div>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select
            className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary"
            onChange={(e) =>
              getPriceList(
                search,
                "",
                statusValuePrice,
                catValuePrice,
                e.target.value
              )
            }
            value={priceRow}
          >
            {" "}
            ,<option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
      </div>
      <div className="w-100 d-flex ac-js mt-4 flex-wrap">
        {priceChangeList?.map((item, ind) => {
          return (
            <div
              className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
              onClick={() => navigatePath(item)}
              key={ind}
            >
              <div className="prod-list-box">
                <div className="cust-btn d-flex ac-jc rounded-3">
                  <img
                    src={
                      item?.product?.product_image
                        ? item?.product?.product_image
                        : product
                    }
                    alt=""
                    className="custom-w-h rounded-3"
                  />
                </div>
                <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15  text-dark mt-3 mb-2">
                  {item?.vendor?.leganEntityName}
                </p>
                <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13  text-primary parag mb-2">
                  {item?.product?.name}
                </p>
                <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13  text-primary1 parag mb-2">
                  {item?.product?.category_name}
                </p>
                <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 text-primary1 parag mb-0 d-flex ac-jb gap-2">
                  {/* SKU0001{"  "} */}
                  Status{"  "}
                  <span
                    className={`${
                      item?.request_status
                        ?.toLowerCase()
                        .startsWith("in progress")
                        ? "text-warning"
                        : item?.request_status
                            ?.toLowerCase()
                            .startsWith("requested")
                        ? "text-primary"
                        : item?.request_status
                            ?.toLowerCase()
                            .startsWith("accep")
                        ? "text-success"
                        : item?.request_status?.toLowerCase().startsWith("reje")
                        ? "text-danger"
                        : ""
                    }  f4 d-flex ac-jb text-nowrap`}
                  >
                    {item?.request_status}
                    {item?.request_status?.toLowerCase().startsWith("reje") && (
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        overlay={(props) => (
                          <Tooltip {...props}>{item?.notes}</Tooltip>
                        )}
                        placement="bottom"
                      >
                        <InfoOutlinedIcon
                          className="fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-1 parag"
                          style={{ color: "#06679c" }}
                        />
                      </OverlayTrigger>
                    )}
                  </span>
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="d-flex justify-content-between mt-3">
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            pageChange == 1 && "opacity-50"
          }`}
          onClick={() => setPageChange(pageChange - 1)}
          disabled={pageChange == 1 ? true : false}
        >
          Previous
        </button>
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            pageChange == priceChangePagination?.last_page && "opacity-50"
          }`}
          onClick={() => setPageChange(pageChange + 1)}
          disabled={
            pageChange == priceChangePagination?.last_page ? true : false
          }
        >
          Next
        </button>
      </div>
    </>
  );
};

export default PriceChange;
