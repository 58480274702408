import React, { useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";

function UploadImages({ onUploadHandler, handleClose,
  setFileData,fileData,setPrice,price,title,
  setTitle,priceErr,titleErr,imageErr,image,setImages,btn}) {
//   const [fileData, setFileData] = useState([]);
//   const [price,setPrice]=useState("")
//   const [title,setTitle]=useState("")


// //Err 
// const [priceErr,setPriceErr]=useState(false)
// const [titleErr,setTitleErr]=useState(false)
// const [imageErr,setImageErr]=useState(false)


  // upload image event
  const handleChangeUpload = (e) => {
    const { files } = e && e.target;
    if (files) {
      setFileData((prev) => [...prev, files[0]]);
    }
  };
  //   remove data from array
  const handleRemove = (e) => {
    if (e.name) {
      let filterArr =
        Array.isArray(fileData) &&
        fileData?.filter((data) => data?.name !== e?.name);
      setFileData(filterArr);
    }
  };

  return (
    <div className="popup-wrap">
      <div className="popup-inner_box">
        <div className="position-relative">
          <h5 className="primary">Upload Images</h5>
          <div className="position-absolute popup_close" onClick={handleClose}>
            <button className="btn">&#x2715;</button>
          </div>
          <p style={{ color: "#787B7F", marginTop: "10px", fontSize: "16px" }}>
            Please Upload each side of your products
          </p>
        </div>
        <div className="d-flex w-100 mb-4 mt-3 flex-wrap">
          <div className="col-md-5 col-lg-5 px-1 col-12">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Title
            </p>
            <input
              type="text"
              placeholder="Enter Title"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value={title}
              onChange={(e)=>{setTitle(e.target.value)}}
            />
            {title?.length == 0 && titleErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Type of TiTle</p>
              </div>
            )}
          </div>
          <div className="col-md-5 col-lg-5 px-1 col-12">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Upload Product Images
            </p>
            <input
              style={{ cursor: "pointer" }}
              type="file"
              // multiple={true}
              // onChange={(e) => {
              //   handleChangeUpload(e);
              // }}
              onChange={(e)=>setImages(e.target.files[0])}
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 upload_img"
            />
            {image?.length == 0 && imageErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Upload Image</p>
              </div>
            )}
          </div>
          <div className="col-md-5 col-lg-5 px-1 col-12">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Price
            </p>
            <input
              type="number"
              placeholder="Enter Price here.."
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value={price}
              onChange={(e)=>setPrice(e.target.value)}
            />
            {price?.length == 0 && priceErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Price</p>
              </div>
            )}
          </div>
          <div className="col-md-5 col-lg-5 px-1 col-12">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Upload Images
            </p>
            <div className="d-flex flex-wrap">
            {image?.name&&
            <div className="px-1 position-relative">
             <img
                        src={image?.name?URL.createObjectURL(image):""}
                        style={{ width: "80px", height: "80px" }}
                        alt="img"
                      />
              </div>}

            </div>
            {/* <div className="d-flex flex-wrap">
              {fileData &&
                Array.isArray(fileData) &&
                fileData.map((data, i) => {
                  let src = (data && URL.createObjectURL(data)) || "";
                  return (
                    <div key={i} className="px-1 position-relative">
                      <img
                        src={src}
                        style={{ width: "80px", height: "80px" }}
                        alt="img"
                      />
                      <div
                        className="position-absolute"
                        style={{ top: "-21%", left: "50%" }}
                        onClick={() => {
                          handleRemove(data);
                        }}
                      >
                        <button className="btn" style={{ color: "red" }}>
                          &#x2715;
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div> */}
          </div>
        </div>
        <div className="d-flex ac-jc">
          <button
            class={`cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${btn&&"opacity-50"}`}
            onClick={() => onUploadHandler()}
            disabled={btn?true:false}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default UploadImages;
