import React, { useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { useEventCategoryEditMutation } from "../../redux/api/api";
import { noLogo } from "../../assets/img";

const AddEventCategory = ({
  handleClose,
  handleOnSubmit,
  image,
  name,
  imageErr,
  nameErr,
  setImage,
  setName,
  eventCatStore,
  btn,
  list,
}) => {
  const [addFormData, setAddFormData] = useState({
    image: "",
    category_name: "",
  });

  // const [btn, setBtn] = useState(false);
  // const dispatch = useDispatch();

  // const[eventCategoryEditApi]=useEventCategoryEditMutation();
  // if (location?.state?.data?.id) {
  //   formdata.append("testimonial_id", location?.state?.data?.id);

  //   eventCategoryEditApi(formdata)
  //     .unwrap()
  //     .then((res) => {
  //       setBtn(false);
  //       dispatch(saveLoader(false));
  //       // navigate("/testimonials");
  //     })
  //     .catch((err) => {
  //       setBtn(false);
  //       dispatch(saveLoader(false));
  //       console.log("err", err);
  //     });
  //   }

  //   onchange Event
  const handleOnChange = (e) => {
    const { name, value, files } = e && e.target;
    let temp = {};
    if (name) {
      if (name === "image") {
        temp[name] = files[0];
        setAddFormData({
          ...addFormData,
          ...temp,
        });
      } else {
        temp[name] = value;
        setAddFormData({
          ...addFormData,
          ...temp,
        });
      }
    }
  };
  return (
    <div className="popup-wrap">
      <div className="popup-inner_box position-relative">
        <div className="position-absolute popup_close" onClick={handleClose}>
          <button className="btn">&#x2715;</button>
        </div>
        <h5 className="primary my-2">
          Add Event Category (107 x 124)
          {/* {location?.state?.type == "edit"
          ? " Edit"
            : " Add"}{" "} */}
        </h5>
        <div className="d-flex row my-2">
          <div className="col-6 mb-2">
            <label className="form-label">Category Image</label>
            {/* {type !== "view" && */}
            <input
              type="file"
              name="image"
              className="form-control"
              onChange={(e) => setImage(e.target.files[0])}
            />
            {/* } */}
            {image?.length == 0 && imageErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Upload Image</p>
              </div>
            )}
            <img
              type="file"
              name="image"
              // disabled={type == "view" ? true : false}
              src={
                image?.name
                  ? URL.createObjectURL(image)
                  : image
                  ? image
                  : noLogo
              }
              className="imgDashboard  mt-2 "
              onChange={(e) => setImage(e.target.value)}
            />
          </div>
          <div className="col-6 mb-2">
            <label className="form-label">Category Name</label>
            <input
              type="text"
              className="form-control"
              // name="category_name"
              placeholder="Enter Category Name"
              onChange={(e) => setName(e.target.value)}
              value={name}
              // disabled={type == "view" ? true : false}
              maxLength={50}
            />
            {name?.length == 0 && nameErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Category Name</p>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-end">
          {" "}
          <button
            className="border-0 add_event_cat_btn"
            onClick={() => eventCatStore()}
            disabled={btn}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddEventCategory;
