import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { animatedImg, emptyImg, searchIcon, uploadIcon } from "../assets/img";
import { Search } from "@mui/icons-material";
import ProductVendor from "../components/Vendor/ProductVendor";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import NoData from "../components/Vendor/NoData";
import ServiceVendor from "../components/Vendor/ServiceVendor";
// import UploadIcon from "@mui/icons-material/Upload";
import ImportListPopup from "../components/Popup/ImportListPopup";
import BothVendor from "../components/Vendor/BothVendor";
import { toast } from "react-hot-toast";
import {
  useDownloadSampleExcelsMutation,
  useDownloadVendorCreationMutation,
  useLazyProfile_statusQuery,
  useLazyVendorStatusListQuery,
  useVendorExportMutation,
  useVendorsListMutation,
} from "../redux/api/api";
import UploadIcon from "@mui/icons-material/Upload";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ErrorIcon from "@mui/icons-material/Error";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import GetAppIcon from "@mui/icons-material/GetApp";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import AllVendor from "../components/Vendor/AllVendor";

const VendorManageScreen = () => {
  const [btn, setBtn] = useState(false);
  const [tab, setTab] = useState(4);
  const [prodVendor, setProdVendor] = useState(false);
  const [serVendor, setSerVendor] = useState(false);
  const [serBothVendor, setSerBothVendor] = useState(false);
  const [importList, setImportList] = useState(false);
  const [both, setBoth] = useState(false);
  const [list, setList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageRow, setPageRow] = useState(10);
  const [page, setPage] = useState(1);
  const [pagenation, setPagination] = useState({});
  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState(false);
  const fileRef = useRef();
  const [file, setFile] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  // RTK QUERY
  const [vendorListApi] = useVendorsListMutation();
  const [vendorStatusApi] = useLazyVendorStatusListQuery();
  const [statusApi] = useLazyProfile_statusQuery();
  const [importApi] = useDownloadSampleExcelsMutation();
  const [uploadApi] = useDownloadVendorCreationMutation();
  const [exportApi] = useVendorExportMutation();

  // console.log("LOC____", location?.state?.type);
  useEffect(() => {
    if (location?.state?.type === "service") {
      setSerVendor(true);
    } else {
      setProdVendor(true);
    }
  }, []);

  const getList = (event, row, type, page) => {
    let formdata = new FormData();
    let params = `?page=${page}`;
    setPage(page);

    formdata.append("offering_type", type);
    // formdata.append("search", type);
    setTab(type);
    setSearchValue(event);

    if (event?.length > 0) {
      formdata.append("search", event);
    }

    if (row) {
      setPageRow(row);
      formdata.append("rows", row);
    }

    vendorListApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          let temp = [];
          res?.lists?.map((item, ind) => {
            temp.push({
              ...item,
              offering_type: type,
            });
          });
          setList(res?.lists);
          setPagination(res?.pagination_meta);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const getStatus = (id, type, status, pageNo) => {
    statusApi(id)
      .unwrap()
      .then((res) => {
        if (status == 1) {
          toast.success("The vendor Has Been De Activated");
        } else {
          toast.success("The vendor Has Been Activated");
        }
        console.log(res);
        getList("", "", type, pageNo);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showPopupHander = () => {
    setImportList(false);
  };

  useEffect(() => {
    getList("", "", tab, page);
  }, [page]);

  // IMPORT EXCEL
  const getAdminImports = () => {
    let formdata = new FormData();
    formdata.append("type", 2);
    importApi(formdata)
      .unwrap()
      .then((res) => {
        if (res?.download_link) {
          const link = document.createElement("a");
          link.href = res?.download_link;
          link.setAttribute("download", "");
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
        }
        console.log("res", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  // EXPORT EXCEL
  const getAdminExports = () => {
    let formdata = new FormData();
    formdata.append("offering type", tab);
    exportApi(formdata)
      .unwrap()
      .then((res) => {
        if (res?.download_link) {
          const link = document.createElement("a");
          link.href = res?.download_link;
          link.setAttribute("download", "");
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  // UPLOAD EXCEL
  const uploadExcel = () => {
    if (image?.length == 0) {
      setImageErr(true);
    }
    // else if (vendorOfferType?.length == 0) {
    //   setVendorOfferTypeErr(true);
    //   categoryNameErr(true);
    // }
    else {
      let formdata = new FormData();
      if (image?.name) {
        formdata.append("file", image);
      }

      setBtn(true);
      dispatch(saveLoader(true));
      uploadApi(formdata)
        .unwrap()
        .then((res) => {
          toast.success("Uploaded Successfully");
          setBtn(false);
          dispatch(saveLoader(false));
          console.log("reslist", res);
          setImage("");
          setImportList(false);
          setShowPopup(false);
        })
        .catch((err) => {
          setBtn(false);
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    }
  };
  return (
    <div className="dashRightView p-5 home_section trans">
      {importList && (
        // <ImportListPopup
        //   showPopupHander={showPopupHander}
        //   setImportList={setImportList}
        // />
        <div className="modal-popup">
          <div onClick={showPopupHander} className="back-close" />
          <div className="center-content p-4">
            <div className=" d-flex justify-content-end">
              <span>
                <button
                  onClick={() => {
                    showPopupHander();
                    setImage("");
                    setImageErr("");
                    // setVendorOfferTypeErr("");
                    // setOfferShow("");
                  }}
                  className="cust-btn"
                >
                  <HighlightOffIcon />
                </button>
              </span>
            </div>
            <div>
              <button
                className={`mb-3 cust-btn addbtn  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded `}
                onClick={() => getAdminImports()}
                // disabled={page == pagenation?.last_page ? true : false}
              >
                Sample Download
              </button>
            </div>
            {/* <div className="d-flex ac-jc">
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 me-2">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
                Vendor Offering Type*
              </p>

              <div className="position-relative">
                <input
                  placeholder="Vendor Offering Type"
                  type="text"
                  value={
                    vendorOfferType?.length > 0
                      ? vendorOfferType?.toString()
                      : "Select Vendor Offering Type"
                  }
                  onClick={() => {
                    setOfferShow(!offerShow);
                  }}
                  className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setOfferShow(!offerShow);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
                {offerShow && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setOfferShow(false)}
                  />
                )}
                <div
                  className={`${offerShow && "w-50 submenu_1 h-auto py-2"
                    } submenu_cont_1 overflow-scroll z-3`}
                >
                  <div className="d-flex gap-1">
                    <input
                      className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                      placeholder="Search..."
                      onChange={(e) => searchVendorOffering(e.target.value)}
                      value={searchValueOffering}
                    />
                  </div>
                  {Vendorofferty?.map((item, ind) => {
                    return (
                      <button
                        className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                        onClick={() => {
                          setVendorOfferType(item);
                          setOfferShow(false);
                        }}
                      >
                        <button className="px-2 cust-btn text-start py-1 w-100 ">
                          {item}
                        </button>
                      </button>
                    );
                  })}
                </div>
              </div>
              {vendorOfferType?.length == 0 && vendorOfferTypeErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Vendor Offer Type </p>
                </div>
              )}
            </div>

            {(vendorOfferType == "Product" || vendorOfferType == "Both") && (
              <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Vendor Category*
                </p>
                <div className="position-relative">
                  <input
                    placeholder="Catogory"
                    type="text"
                    value={
                      vendorCatogory?.length > 0
                        ? vendorCatogory?.toString()
                        : "Select Category"
                    }
                    onClick={() => {
                      setDropDown(1);
                    }}
                    className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                  <button
                    className="drop_down cust-btn"
                    onClick={() => {
                      setDropDown(1);
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                  {dropDown == 1 && (
                    <div
                      className="invisible-cont2 z-0"
                      onClick={() => setDropDown(null)}
                    />
                  )}
                  <div
                    className={`${dropDown == 1 && "submenu_1 py-2"
                      } submenu_cont_1 overflow-scroll z-3`}
                  >
                    <div className="d-flex gap-1">
                      <input
                        className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                        placeholder="Search..."
                        onChange={(e) => searchCategory(e.target.value)}
                        value={searchValueCategory}
                      />
                      <button
                        className="border-0 bg-transparent"
                        onClick={() => {
                          setSelectALLSub(!selectALLSub);
                          allSelectSub();
                        }}
                      >
                        All
                      </button>
                    </div>
                    {lists?.map((item, ind) => {
                      return (
                        <button
                          className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                          key={ind}
                        >
                          <button
                            className="px-2 cust-btn text-start py-1 w-100 "
                            onClick={() => {
                              onCategorySelct(item?.name, item?.id);
                            }}
                          >
                            {item?.name}
                          </button>
                          <button
                            className="px-2 cust-btn text-start"
                            style={{ fontSize: "large" }}
                            onClick={() => {
                              onCategorySelct(item?.name, item?.id, "check");
                            }}
                          >
                            {vendorCatogory?.includes(item?.name) ? (
                              <CheckCircleIcon className="primary" />
                            ) : (
                              <RadioButtonUncheckedIcon className="primary" />
                            )}
                          </button>
                        </button>
                      );
                    })}
                  </div>
                </div>
                {categoryName?.length == 0 && categoryNameErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Select Vendor Category</p>
                  </div>
                )}
              </div>
            )}
          </div> */}

            <p className="mt-3 d-flex ac-jc f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Import Admin List
            </p>
            <div className="pointerView w-100 mt-4 ac-jc">
              <div
                onClick={() => fileRef.current.click()}
                className="w-100 d-flex ac-jc"
              >
                {file === "" ? (
                  <img
                    src={uploadIcon}
                    onChange={(e) => setImage(e.target.files[0])}
                    className="mailImg"
                  />
                ) : (
                  <iframe
                    onClick={() => fileRef.current.click()}
                    src={file}
                    className="importExcel"
                  />
                )}
              </div>
              {file === "" && (
                <div
                  onClick={() => {
                    fileRef.current.click();
                  }}
                  className="w-100 d-flex ac-jc"
                >
                  <p className="primary1 w-100 f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 mt-1 d-flex ac-jc">
                    +Import List
                  </p>
                </div>
              )}
              {image?.length == 0 && imageErr && (
                <div className="d-flex ac-jc gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Upload File</p>
                </div>
              )}
              <div className="d-flex ac-jc">
                <button
                  onClick={() => uploadExcel()}
                  disabled={btn}
                  className={`
                cust-btn addbtn mt-sm-4 ms-1 mt-4 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                  btn && "opacity-50"
                }`}
                >
                  Submit
                </button>
              </div>
            </div>
            <input
              type="file"
              accept=".xlsx,"
              onChange={(e) => {
                setImage(e.target.files[0]);
              }}
              className="d-none"
              ref={fileRef}
            />
          </div>
        </div>
      )}

      <ProductSearchHeader />
      <div className="d-flex ac-jb mt-4 flex-wrap">
        <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Vendor Management
        </p>
        <div className="d-flex mt-sm-3 border-search mx-1">
          <img src={searchIcon} alt="search" className="searchiConImg" />
          <input
            className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
            placeholder="Search"
            onChange={(e) => getList(e.target.value, "", tab, 1)}
          />
        </div>
      </div>
      <div className="d-flex mt-4 ac-jb flex-wrap">
        <div className="d-flex mt-3">
          <div
            onClick={() => {
              getList("", "", 4, 1);
              setProdVendor(false);
              setSerVendor(false);
              setBoth(false);
              setSerBothVendor(false);
            }}
            className={`${
              tab == 4
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } `}
          >
            <p
              className={`${
                tab == 4 ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 `}
              style={{ cursor: "pointer" }}
            >
              All Vendor
            </p>
          </div>
          <div
            onClick={() => {
              getList("", "", 1, 1);
              setProdVendor(true);
              setSerVendor(false);
              setBoth(false);
              setSerBothVendor(false);
            }}
            className={`${
              tab == 1
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-4`}
          >
            <p
              className={`${
                tab == 1 ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
              style={{ cursor: "pointer" }}
            >
              Product Supplier
            </p>
          </div>
          <div
            onClick={() => {
              getList("", "", 2, 1);
              setSerVendor(true);
              setProdVendor(false);
              setSerBothVendor(false);
              setBoth(false);
            }}
            className={`${
              tab == 2
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-4`}
          >
            <p
              className={`${
                tab == 2 ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
              style={{ cursor: "pointer" }}
            >
              Decorator
            </p>
          </div>
          <div
            onClick={() => {
              getList("", "", 3, 1);
              setProdVendor(false);
              setSerVendor(false);
              setBoth(true);
              setSerBothVendor(true);
            }}
            className={`${
              tab == 3
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-4`}
          >
            <p
              className={`${
                tab == 3 ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
              style={{ cursor: "pointer" }}
            >
              Both
            </p>
          </div>
        </div>
        <div className="d-flex w-xs-100 ac-jc ms-2 flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-row flex-column">
          <div
            // role="button"
            onClick={() => getAdminExports()}
            className="pointerView d-flex mt-4 me-3"
          >
            <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Export
            </p>
          </div>
          <div
            onClick={() => setImportList(!importList)}
            className="pointerView d-flex mt-4 me-3"
          >
            <UploadIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Import
            </p>
          </div>
          {/* <div className="pointerView d-flex mt-4 me-3">
            <ListAltRoundedIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Download List
            </p>
          </div> */}
          <div>
            <button
              onClick={() => navigate("/add-vendor")}
              className="pointerView cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              + Add Vendor
            </button>
          </div>
        </div>
      </div>
      {tab == 4 && (
        <AllVendor
          getList={getList}
          page={page}
          pageRow={pageRow}
          pagenation={pagenation}
          getStatus={getStatus}
          list={list}
        />
      )}
      {tab == 1 && (
        <ProductVendor
          getList={getList}
          page={page}
          pageRow={pageRow}
          pagenation={pagenation}
          getStatus={getStatus}
          list={list}
        />
      )}
      {tab == 2 && (
        <ServiceVendor
          getList={getList}
          page={page}
          pageRow={pageRow}
          pagenation={pagenation}
          getStatus={getStatus}
          list={list}
        />
      )}
      {tab == 3 && (
        <BothVendor
          getList={getList}
          page={page}
          pageRow={pageRow}
          pagenation={pagenation}
          getStatus={getStatus}
          list={list}
        />
      )}
    </div>
  );
};

export default VendorManageScreen;
