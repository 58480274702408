import { Search } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import manImage from "../../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import DecorativeDropdownTap from "../DecorativeComp/DecorativeDropdownTap";
import DecorativeListComp from "./DecorativeListComp";
import {
  useDecorativeMethodeListMutation,
  useDecorativeMethodeStatusPostMutation,
  useLazyDecorativeMethodeStatusQuery,
} from "../../redux/api/api";

const DecorativeComp = ({ place, decorative2, name, vendors, data }) => {
  const [pagenation, setPagination] = useState({});
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [pageRow, setPageRow] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [priceBook, setpriceBook] = useState(false);
  const [previousOrder, setPreviousOrder] = useState(false);
  const [tagged, setTagged] = useState(false);
  const navigate = useNavigate();
  const Path = useLocation();
  // console.log(Path?.state)
  const location = Path?.state;
  const loc = Path?.state?.type;
  // console.log(place)

  //RTk

  const [decorativeListApi] = useDecorativeMethodeListMutation();
  const [decorativeStatusApi] = useLazyDecorativeMethodeStatusQuery();
  const [decorativeStatusPostApi] = useDecorativeMethodeStatusPostMutation();

  const getdecorate = (event, row) => {
    const formData = new FormData();
    formData.append("vendor_id", location?.data?.id);
    let params = `?page=${page}`;
    if (event?.length > 0) {
      formData.append("search", event);
    }

    if (row) {
      formData.append("rows", row);
    }
    setSearchValue(event);
    setPageRow(row);
    decorativeListApi({ payload: formData, params: params })
      .unwrap()
      .then((res) => {
        // console.log(res)
        setList(res?.lists);
        setPagination(res?.pagination_meta);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDecorStatus = (id, vendor_id) => {
    let formdata = new FormData();
    formdata.append("vendor_id", vendor_id);
    decorativeStatusPostApi({ id: id, payload: formdata })
      .unwrap()
      .then((res) => {
        // console.log(res);
        getdecorate();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (location?.data?.id) {
      getdecorate();
    }
  }, [page]);

  return (
    <div className="">
      <DecorativeDropdownTap
        getdecorate={getdecorate}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        pageRow={pageRow}
      />
      <DecorativeListComp
        decorative2={decorative2}
        place={place}
        name={name}
        page={page}
        setPage={setPage}
        pagenation={pagenation}
        list={list}
        data={data}
        vendors={vendors}
        getDecorStatus={getDecorStatus}
      />
    </div>
  );
};

export default DecorativeComp;
