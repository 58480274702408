import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ImageUplodeComp from "../AddNewProductComps/ImageUplodeComp";
import FormComp from "../AddNewProductComps/FormComp";
import { Col } from "react-bootstrap";
import { currency_list, DefaultTax, sub_menu } from "../../redux/api/DummyJson";
import PorejectPoppup from "../PODetails/PorejectPoppup";
import SaleRejPopup from "../POEstimateRequestComp/SaleRejPopup";
import moment from "moment/moment";

const SalesShipWarehouse = ({
  setAddContComp,
  poppupAddressToggle,
  collaboratClick,
  onCollaborat,
}) => {
  const [dropDown, setDropDown] = useState(undefined);
  const [tab, setTab] = useState(1);
  const [type_of_shipping, setType_of_shipping] = useState(1);

  // input states

  const [how_many_boxesi, setHow_many_boxesi] = useState("");
  const [which_boxi, setWhich_boxi] = useState("FLIP 1");
  const [how_longi, setHow_longi] = useState("");
  const [start_datei, setStart_datei] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const [terms, setTerms] = useState("");
  const [currency, setCurrency] = useState("");
  const [defaultTax, setDefaultTax] = useState("");
  const [statust, setStatust] = useState("");
  const [salestatus, setSaleStatus] = useState("");

  // Shipping and inventory data
  const shippingData = {
    boxes: [
      {
        name: "FLIP 1",
        dimensions: "30 X 13 X 10",
        volumetricWeight: 0.8,
        csRate: 60,
        price: 78,
        shippingType: "Individual Shipping",
        storageType: "Kit Storage",
      },
      {
        name: "FLIP 2",
        dimensions: "29 X 20 X 12",
        volumetricWeight: 1.4,
        csRate: 70,
        price: 91,
        shippingType: "Individual Shipping",
        storageType: "Kit Storage",
      },
      {
        name: "RSA-1.5",
        dimensions: "29 X 25 X 10",
        volumetricWeight: 1.45,
        csRate: 70,
        price: 91,
        shippingType: "Individual Shipping",
        storageType: "Kit Storage",
      },
      {
        name: "FLIP 3",
        dimensions: "31 X 26X10",
        volumetricWeight: 1.6,
        csRate: 85,
        price: 110.5,
        shippingType: "Individual Shipping",
        storageType: "Kit Storage",
      },
      {
        name: "RSA-2",
        dimensions: "30 X 27X 11.5",
        volumetricWeight: 1.86,
        csRate: 85,
        price: 110.5,
        shippingType: "Individual Shipping",
        storageType: "Kit Storage",
      },
      {
        name: "RSA-2.5",
        dimensions: "34 X 30X 12",
        volumetricWeight: 2.44,
        csRate: 100,
        price: 130,
        shippingType: "Individual Shipping",
        storageType: "Kit Storage",
      },
      {
        name: "RSA-3.5",
        dimensions: "47 X33X11",
        volumetricWeight: 3.41,
        csRate: 110,
        price: 143,
        shippingType: "Individual Shipping",
        storageType: "Kit Storage",
      },
      {
        name: "RSA-5",
        dimensions: "47 X 32 X 16",
        volumetricWeight: 4.8,
        csRate: 175,
        price: 227.5,
        shippingType: "Individual Shipping",
        storageType: "Kit Storage",
      },
      {
        name: "Master Box 1",
        dimensions: "55 X 42 X 42",
        volumetricWeight: 19.404,
        csRate: 1350,
        price: 1755,
        shippingType: "Bulk Shipping",
        storageType: "Bulk Storage",
      },
      {
        name: "Master Box 2",
        dimensions: "55 X 42 X 26",
        volumetricWeight: 12.012,
        csRate: 850,
        price: 1105,
        shippingType: "Bulk Shipping",
        storageType: "Bulk Storage",
      },
    ],
    airCargo: [
      { location: "Metro", csRate: 90, price: 117 },
      { location: "National", csRate: 95, price: 123.5 },
      { location: "North East", csRate: 105, price: 136.5 },
    ],
    surfaceCargo: [
      { location: "Local", csRate: 20, price: 26 },
      { location: "Regional", csRate: 30, price: 39 },
      { location: "Zonal", csRate: 35, price: 45.5 },
      { location: "Metro", csRate: 50, price: 65 },
      { location: "National", csRate: 55, price: 71.5 },
      { location: "North East", csRate: 65, price: 84.5 },
    ],
  };

  const calculateInventory = () => {
    try {
      const box = shippingData.boxes.find((b) => b.name === which_boxi);
      const [length, width, height] = box?.dimensions
        ?.split("X")
        .map((dim) => parseInt(dim.trim()));

      const palletSize = {
        length: 40 * 2.54,
        width: 48 * 2.54,
        height: 48 * 2.54,
      }; // Convert inches to cm
      const boxesPerPallet =
        Math.floor(palletSize.length / length) *
        Math.floor(palletSize.width / width) *
        Math.floor(palletSize.height / height);
      const palletsNeeded = Math.ceil(how_many_boxesi / boxesPerPallet);

      const palletArea = (palletSize.length / 100) * (palletSize.width / 100); // Convert cm to m
      const palletAreaSqFt = palletArea * 10.764; // Convert m² to ft²
      const dailyRate = 28 / 30; // ₹28 per month converted to daily rate
      const storageCost =
        palletAreaSqFt * dailyRate * how_longi * palletsNeeded;

      const endDate = new Date(start_datei);
      endDate.setDate(endDate.getDate() + how_longi);

      const explanation = `Inventory Calculation:
      Selected Box (${which_boxi}): ${length}cm x ${width}cm x ${height}cm
      Pallet size: 40" x 48" x 48" (${palletSize.length.toFixed(
        2
      )}cm x ${palletSize.width.toFixed(2)}cm x ${palletSize.height.toFixed(
        2
      )}cm)
      Boxes per Pallet: ${boxesPerPallet}
      Number of Pallets needed: ${palletsNeeded}
      Storage Duration: ${how_longi} days
      Start Date: ${start_datei}
      End Date: ${moment(endDate).format("YYYY-MM-DD")}
      Pallet Area: ${palletArea.toFixed(2)} m² (${palletAreaSqFt.toFixed(
        2
      )} ft²)
      Storage Rate: ₹28 per ft² per month (₹${dailyRate.toFixed(
        2
      )} per ft² per day)
      Total Storage Cost: ₹${storageCost.toFixed(2)}`;

      console.log("explanation", explanation);
    } catch (error) {
      console.log("error", error);
    }
  };

  const [rejectpoppup, setRejectPoppup] = useState(false);
  const [rejectup, setRejectPop] = useState(false);

  const rejPopFun = () => {
    setRejectPoppup(!rejectpoppup);
  };
  const rejectFun = () => {
    setRejectPop(!rejectup);
  };

  console.log("how_many_boxesi", how_many_boxesi, which_boxi);
  return (
    <>
      <div className="w-100 mb-3">
        <div className="mt-3 d-flex ac-jb mt-0 mt-md-3 flex-column flex-md-row">
          <div className="d-flex ac-js">
            <button
              onClick={() => setTab(1)}
              className={`${
                tab == 1 ? "border_click_cust" : "cust-btn "
              } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
            >
              Shipping
            </button>
            <button
              onClick={() => setTab(2)}
              className={`${
                tab == 2 ? "border_click_cust" : "cust-btn "
              } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
            >
              Inventory
            </button>
          </div>
        </div>
        {tab == 2 ? (
          <div className="d-flex ">
            <div className="d-flex flex-column flex-md-row flex-wrap w-50">
              <Col sm={12} md={12} lg={12} className="">
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                  style={{ marginLeft: "0px" }}
                >
                  How many boxes
                </p>

                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  type="number"
                  onChange={(e) => setHow_many_boxesi(e.target.value)}
                  value={how_many_boxesi}
                />
              </Col>
              <Col sm={12} md={12} lg={12} className="">
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                  style={{ marginLeft: "0px" }}
                >
                  Which box
                </p>

                <select
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  onChange={(e) => setWhich_boxi(e.target.value)}
                  value={which_boxi}
                >
                  <option value={"FLIP 1"}>FLIP 1 (30 X 13 X 10)</option>
                  <option value={"FLIP 2"}>FLIP 2 (29 X 20 X 12)</option>
                  <option value={"RSA-1.5"}>RSA-1.5 (29 X 25 X 10)</option>
                  <option value={"FLIP 3"}>FLIP 3 (31 X 26X10)</option>
                  <option value={"RSA-2"}>RSA-2 (30 X 27X 11.5)</option>
                  <option value={"RSA-2.5"}>RSA-2.5 (34 X 30X 12)</option>
                  <option value={"RSA-3.5"}>RSA-3.5 (47 X33X11)</option>
                  <option value={"RSA-5"}>RSA-5 (47 X 32 X 16)</option>
                  <option value={"Master Box 1"}>
                    Master Box 1 (55 X 42 X 42)
                  </option>
                  <option value={"Master Box 2"}>
                    Master Box 2 (55 X 42 X 26)
                  </option>
                </select>
              </Col>
              <Col sm={12} md={12} lg={12} className="">
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                  style={{ marginLeft: "0px" }}
                >
                  How Long (days)
                </p>

                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  type="number"
                  onChange={(e) => setHow_longi(e.target.value)}
                  value={how_longi}
                />
              </Col>
              <Col sm={12} md={12} lg={12} className="">
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                  style={{ marginLeft: "0px" }}
                >
                  Start Date
                </p>

                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  type="date"
                  onChange={(e) => setStart_datei(e.target.value)}
                  value={start_datei}
                />
              </Col>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column flex-md-row flex-wrap w-50 mt-3">
            <Col sm={12} md={12} lg={12} className="me-md-3">
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                style={{ marginLeft: "0px" }}
              >
                Type of shipping
              </p>

              <select
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={type_of_shipping}
                onChange={(e) => setType_of_shipping(e.target.value)}
              >
                <option value={1}>Individual</option>
                <option value={2}>Bulk</option>
              </select>
            </Col>
            {+type_of_shipping == 2 && (
              <Col sm={12} md={12} lg={12} className="">
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                  style={{ marginLeft: "0px" }}
                >
                  Location (Only for Bulk):
                </p>
                <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  <option>Metro</option>
                  <option>National</option>
                  <option>North East</option>
                  <option>Local</option>
                  <option>Regional</option>
                  <option>Zonal</option>
                </select>
              </Col>
            )}
            <Col sm={12} md={12} lg={12} className="">
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                style={{ marginLeft: "0px" }}
              >
                Which box
              </p>

              <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                <option>FLIP 1 (30 X 13 X 10)</option>
                <option>RSA-5 (47 X 32 X 16)</option>
                <option>FLIP 2 (29 X 20 X 12)</option>
                <option>RSA-1.5 (29 X 25 X 10)</option>
                <option>FLIP 3 (31 X 26X10)</option>
                <option>RSA-2 (30 X 27X 11.5)</option>
                <option>RSA-2.5 (34 X 30X 12)</option>
                <option>RSA-3.5 (47 X33X11)</option>
              </select>
            </Col>
            <Col sm={12} md={12} lg={12} className="">
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                style={{ marginLeft: "0px" }}
              >
                How many boxes:
              </p>

              <input
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                type="number"
              />
            </Col>
            <Col sm={12} md={12} lg={12} className="">
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                style={{ marginLeft: "0px" }}
              >
                Shipping mode:
              </p>

              <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                <option>Surface</option>
                <option>Air</option>
                <option>Individual</option>
              </select>
            </Col>
            <Col sm={12} md={12} lg={12} className="">
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                style={{ marginLeft: "0px" }}
              >
                Actual Weight Per Box (kg):
              </p>

              <input
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                type="number"
              />
            </Col>
          </div>
        )}

        <div className="mt-3 mb-3">
          <button
            class="cust-btn addbtn  addbtn_save  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 mx-2 rounded"
            onClick={() => calculateInventory()}
          >
            Calculate
          </button>
        </div>
      </div>
    </>
  );
};

export default SalesShipWarehouse;
