// @ts-nocheck
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ImageCroper from "../../../components/Popup/ImageCroper";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ErrorIcon from "@mui/icons-material/Error";
import JoditEditor from 'jodit-react';
import { useBannerEditMutation, useBlogAddMutation, useBlogEditMutation, useLazyBlog_viewQuery } from "../../../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../../redux/slice/loaderNoti";
import { noLogo } from "../../../assets/img";
import DOMPurify from "dompurify";


const BlogEdit = ({ placeholder }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  //FOR TEXT EDITOR
  const editor = useRef(null);
  const [content, setContent] = useState('');

  const config = useMemo(() =>
  ({
    readonly: false,
    placeholder: placeholder || 'Start typings...'
  }),
    [placeholder]
  );

  console.log(content, "text");
  // const dispatch = useDispatch();
  const [btn, setBtn] = useState(false);

  //RTK
  const [blogAddApi] = useBlogAddMutation();
  const [blogEditApi] = useBlogEditMutation();
  const [blogViewApi] = useLazyBlog_viewQuery();


  const [data, setData] = useState("");
  const [action, setAction] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [croper, setCroper] = useState(false);
  const navigate = useNavigate();
  const type = location?.state?.type;
  console.log(location)

  const [full_name, setName] = useState("");
  const [category, setCategory] = useState("Category1");
  const [logo_img, setLogo_Img] = useState("");
  const [priority, setPriority] = useState("");
  const [shortDes, setShortDes] = useState("");
  const [description, setDescription] = useState("");
  const [desc_Err, setDesc_Err] = useState(false);
  const [categoryErr, setCategoryErr] = useState(false);
  const [client_Err, setClient_Err] = useState(false);
  const [logo_Err, setLogo_Err] = useState(false);
  const [full_nameErr, setFull_nameErr] = useState(false);
  const [pririty_Err, setPrio_Err] = useState(false);
  const [shortDesErr, setShortDesErr] = useState(false);

  //View
  const getView = () => {
    blogViewApi(location?.state?.data?.id)
      .unwrap()
      .then((res) => {
        console.log(res?.blog
          , "blog")
        setName(res?.blog?.title);
        setCategory(res?.blog?.tag);
        setLogo_Img(res?.blog?.image_url);
        setShortDes(res?.blog?.short_description);
        setPriority(res?.blog?.priority);
        setDescription(res?.blog?.description);
      })
      .catch((err) => {
        console.log(err)
      })
  }


  useEffect(() => {
    if (location?.state?.type == "edit" && location?.state?.data?.id) {
      getView()
    }
  }, [])


  useEffect(() => {
    if (croperImage) {
      fetch(croperImage)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "File name", { type: "image/png" });
          setLogo_Img(file);
        });
    }

  }, [croperImage])

  useEffect(() => {
    setData(location?.state?.data);
  }, [location]);



  const toggleImagePopup = () => {
    setCroper(!croper);
  };
  const submitHandle = () => {
    if (
      full_name?.length == 0 ||
      description?.length == 0 ||
      logo_img?.length == 0 ||
      priority?.length == 0 ||
      category?.length == 0 ||
      shortDes?.length == 0
    ) {
      setFull_nameErr(true);
      setCategoryErr(true);
      setDesc_Err(true);
      setClient_Err(true);
      setLogo_Err(true);
      setPrio_Err(true);
      setShortDesErr(true);
    } else {
      let formdata = new FormData();
      if (logo_img?.name) {

        formdata.append("image", logo_img)
      }
      formdata.append("title", full_name)
      formdata.append("tag", category)
      formdata.append("priority", priority)
      formdata.append("description", description)
      formdata.append("short_description", shortDes)

      setBtn(true);
      dispatch(saveLoader(true));
      if (location?.state?.type == "edit") {
        formdata.append("blog_id", location?.state?.data?.id)
        blogEditApi(formdata)
          .unwrap()
          .then((res) => {
            setBtn(false);
            dispatch(saveLoader(false));
            navigate("/blog-management");
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false));  
            console.log("err", err);
          });
      } else {
        blogAddApi(formdata)
          .unwrap()
          .then((res) => {
            setBtn(false);
            dispatch(saveLoader(false));
            navigate("/blog-management");
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });

      }
    }

  }


  return (
    <div className="dashRightView p-5 home_section trans">
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <p
        className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-0 mb-3 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon />{location?.state?.type == "edit" ? "Blogs Edit" : "Blogs Add"}
      </p>
      <div className="w-100 searchField mt-0 bg-white ">
        <div className="w-100 d-flex flex-xxl-row flex-xl-row flex-lg-row flex-column">
          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Tags:
              </p>
              <div className="w-50">

                <select
                  placeholder=""
                  className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  name=""
                  id=""
                  onChange={(e) => setCategory(e.target.value)}
                  value={category}
                >
                  <option value={"Category1"}>Category 1</option>
                  <option value={"Category2"}>Category 2</option>
                  <option value={"Category3"}>Category 3</option>
                </select>
                {category?.length == 0 && categoryErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter category</p>
                  </div>
                )}
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Blog Image:<span style={{ color: "#f82a2a" }}>(1152px*400px)</span>
              </p>
              <div className="w-70" >
                <div className="w-50">
                  {type !== "view" && <input type="file" className="mb-2" onChange={(e) => setLogo_Img(e.target.files[0])} />}
                  <img
                    src={logo_img?.name ? URL.createObjectURL(logo_img) : logo_img ? logo_img : noLogo}
                    className="imgDashboard"
                  />
                  {logo_img?.length == 0 && logo_Err && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Upload Blog Image</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Title:<span style={{ color: "#f82a2a" }}>(100words only)</span>
              </p>
              <div className="w-50">

                <input
                  className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={full_name}
                  maxLength={100}
                />
                {full_name?.length == 0 && full_nameErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Title</p>
                  </div>
                )}
              </div>
            </div>

            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Short Description:
              </p>
              <div className="w-100">

                <textarea
                  className="w-100  editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  type="text"
                  style={{ height: "100px" }}
                  // maxLength={100}
                  onChange={(e) => setShortDes(e.target.value)}
                  value={shortDes}
                />
                {shortDes?.length == 0 && shortDesErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Short Description</p>
                  </div>
                )}
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Description:<span style={{ color: "#f82a2a" }}>(150words only)</span>{type == "add" && type !== "view" && <span>*</span>}
              </p>
              <div className="w-100">
                <JoditEditor
                  className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  ref={editor}
                  value={description}
                  config={config}   
                  // maxLength={150}
                  tabIndex={1}// tabIndex of textarea
                  onBlur={newContent => setDescription(newContent)} // preferred to use only this option to update the content for performance reasons
                  onChange={newContent => { setDescription(newContent) }}

                />


                {/* //   className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                //   type="text"
                //   style={{height:"100px"}}
                //   onChange={(e) => setDescription(e.target.value)}
                //   value={description?.substring(0,150) } maxLength={150}
                //   disabled={type == "view" ? true : false} */}

                {description?.length == 0 && desc_Err && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Description</p>
                  </div>
                )}
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Priority Status:
              </p>
              <div className="w-50">
                <input
                  className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  type="text"
                  onChange={(e) => setPriority(e.target.value)}
                  value={priority}
                />
                {priority?.length == 0 && pririty_Err && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Priority</p>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Status
              </p>
              <div className="w-50">
              <select
                placeholder=""
                className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                name=""
                id=""
                onChange={e=>setAction(e.target.value)}
              >
                <option value={"1"}>Active</option>
                <option value={"0"}>De Active</option>
              </select>
            </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="d-flex ac-jb">
        <button
          onClick={() => navigate(-1)}
          className="pointerView cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
        >
          Back
        </button>
        <button
          onClick={() => submitHandle()}
          className="pointerView cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
        >
          {location?.state?.type == "edit" ? "Edit" : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default BlogEdit;
