import React, { useEffect, useRef, useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { toggleOn } from "../assets/img";
import OverViewComp from "../components/ProductDetailComp/OverViewComp";
import VendorPricingComp from "../components/ProductDetailComp/VendorPricingComp";
import { useLocation, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import PricingBoxComp from "../components/MyProductListComp/PricingBoxComp";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PricingBoxCompNew from "../components/MyProductListComp/PricingBoxCompNew";
import {
  useLazyVendorAcceptedShowQuery,
  useVendorMapPriceAddMutation,
  useVendorMapPriceUpdateMutation,
  useVendorMapViewMutation,
} from "../redux/api/api";
import { saveLoader } from "../redux/slice/loaderNoti";
import { useDispatch } from "react-redux";

const ProductDetailScreen = () => {
  const [priceBook, setpriceBook] = useState(true);
  const [previousOrder, setPreviousOrder] = useState(false);
  const [tagged, setTagged] = useState(false);
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const types = location?.state?.type;
  const dispatch = useDispatch();
  const [priceBtn, setPriceBtn] = useState(false);

  const [parentCategoryList, setParentCategoryList] = useState([]);
  const [selColors, setSelColors] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCategoryLists, setSubCategoryLists] = useState([]);
  const [decorativeList, setDecorativeList] = useState([]);
  const [attributesList, setAttributesList] = useState([]);
  const [parentCategory, setParentCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [decorativeID, setDecorativeID] = useState([]);
  const [decorativeName, setDecorativeName] = useState([]);
  const [hsnList, setHsnList] = useState([]);
  const [country, setCountry] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [hsnCodeErr, setHsnCodeErr] = useState(false);
  const [hsnName, setHsnName] = useState("");
  const [tagsList, setTagsList] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagsName, setTagsName] = useState([]);
  const [colorsList, setColorsList] = useState([]);
  const [colorsID, setColorsID] = useState([]);
  const [colorsName, setColorsName] = useState([]);
  const [colorsData, setColorsData] = useState([]);
  const [varientShow, setVarientShow] = useState(false);
  const [sizes, setSizes] = useState([{ value: "" }]);
  const [materials, setMeterials] = useState([{ value: "" }]);
  const [styles, setStyles] = useState([{ value: "" }]);
  const [allVarients, setAllVarients] = useState([]);
  const [catogoryName, setCatogoryName] = useState({});
  const [primaryCategoryId, setPrimaryCategoryId] = useState("");
  const [secondaryCategoryId, setSecondaryCategoryId] = useState("");
  const [primaryCategoryIdErr, setPrimaryCategoryIdErr] = useState(false);
  const [secondaryCategoryIdErr, setSecondaryCategoryIdErr] = useState(false);
  const [searchValueSubCategory, setSearchSubValueCategory] = useState("");
  const [productSuplierCode, setProductSuplierCode] = useState("");
  const [productInformation, setProductInformation] = useState([]);
  const [smallimage, setSmallImage] = useState([]);
  const [smallimageErr, setSmallImageErr] = useState(false);
  const [smallMockimage, setSmallMockImage] = useState([]);

  const [categoryID, setCategoryID] = useState("");
  const [catogory, setCatogory] = useState("");
  const [name, setName] = useState("");
  const [mrp, setMrp] = useState("");
  const [decorationMethod, setDecorationMethod] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productPackageSize, setProductPackageSize] = useState("");
  const [gendor, setGendor] = useState("");
  const [gendorErr, setGendorErr] = useState("");
  const [productPackageSizeErr, setProductPackageSizeErr] = useState(false);
  const [productDescriptionErr, setProductDescriptionErr] = useState(false);
  const [decorationMethodErr, setDecorationMethodErr] = useState(false);
  const [mrpErr, setMrpErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [countryErr, setCountryErr] = useState(false);
  const [categoryIDErr, setCategoryIDErr] = useState(false);
  const [tagErr, setTagErr] = useState(false);
  const [tagVal, setTagVal] = useState("");
  const [productImg, setProductImg] = useState("");
  const [productImgErr, setProductImgErr] = useState(false);
  const [morkupImg, setMorkupImg] = useState("");
  const [morkupImgErr, setMorkupImgErr] = useState(false);
  const [colorsImg, setColorsImg] = useState("");
  const [colorImgErr, setColorImgErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const [submitBtn, setSubmitBtn] = useState(0);
  const [listAttributes, setListAttributes] = useState([]);
  const [dynamicAttr, setDynamicAttr] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [marginBox, setMarginBox] = useState(false);
  const [submitData, setSubmitData] = useState({});
  const [viewData, setViewData] = useState({});
  const [varientsList, setVarientList] = useState([]);

  const [vendorProCode, setVendorProCode] = useState("");
  const [vendorProCodeErr, setVendorProCodeErr] = useState(false);
  const vendorRef = useRef();

  const [cost, setCost] = useState({
    for_25: "",
    for_50: "",
    for_100: "",
    for_150: "",
    for_250: "",
    for_500: "",
    for_1000: "",
  });

  const [net, setNet] = useState({
    for_25: "",
    for_50: "",
    for_100: "",
    for_150: "",
    for_250: "",
    for_500: "",
    for_1000: "",
  });

  const [margin, setMargin] = useState({
    for_25: "",
    for_50: "",
    for_100: "",
    for_150: "",
    for_250: "",
    for_500: "",
    for_1000: "",
  });

  const [distributorPrice, setDistributorPrice] = useState({
    for_25: 0,
    for_50: 0,
    for_100: 0,
    for_150: 0,
    for_250: 0,
    for_500: 0,
    for_1000: 0,
  });

  const [priceCharge, setPriceCharge] = useState([]);
  const [distributorPriceErr, setDistributorPriceErr] = useState(false);
  const [distributorPriceCheckErr, setDistributorPriceCheckErr] =
    useState(false);

  // RTK QUERY
  const [showMapProductApi] = useVendorMapViewMutation();
  const [approvedEditApi] = useVendorMapPriceUpdateMutation();
  const [productPriceApi] = useVendorMapPriceAddMutation();
  const [proVendorPriceReqShowApi] = useLazyVendorAcceptedShowQuery();

  // console.log("vendorProCode", vendorProCode);

  const submitHandle = () => {
    let formdata = new FormData();
    formdata.append("vendor_id", location?.state?.data?.id);

    let distributorCheck = Object.values(distributorPrice).some((x) => +x == 0);
    setDistributorPriceCheckErr(true);
    if (vendorProCode?.length == 0 || distributorCheck == true) {
      setVendorProCodeErr(true);
      vendorProCode?.length == 0 && vendorRef.current.focus();
      setDistributorPriceErr(true);
    } else {
      // BASIC PRICE
      formdata.append(`basicPrices[for_25]`, cost?.for_25);
      formdata.append(`basicPrices[for_50]`, cost?.for_50);
      formdata.append(`basicPrices[for_100]`, cost?.for_100);
      formdata.append(`basicPrices[for_150]`, cost?.for_150);
      formdata.append(`basicPrices[for_250]`, cost?.for_250);
      formdata.append(`basicPrices[for_500]`, cost?.for_500);
      formdata.append(`basicPrices[for_1000]`, cost?.for_1000);

      // NET PRICE
      formdata.append(`netPrices[for_25]`, net?.for_25);
      formdata.append(`netPrices[for_50]`, net?.for_50);
      formdata.append(`netPrices[for_100]`, net?.for_100);
      formdata.append(`netPrices[for_150]`, net?.for_150);
      formdata.append(`netPrices[for_250]`, net?.for_250);
      formdata.append(`netPrices[for_500]`, net?.for_500);
      formdata.append(`netPrices[for_1000]`, net?.for_1000);

      // MARGIN PRICE
      formdata.append(
        `mspPrices[for_25]`,
        margin?.for_25 ? margin?.for_25 : ""
      );
      formdata.append(
        `mspPrices[for_50]`,
        margin?.for_50 ? margin?.for_50 : ""
      );
      formdata.append(
        `mspPrices[for_100]`,
        margin?.for_100 ? margin?.for_100 : ""
      );
      formdata.append(
        `mspPrices[for_150]`,
        margin?.for_150 ? margin?.for_150 : ""
      );
      formdata.append(
        `mspPrices[for_250]`,
        margin?.for_250 ? margin?.for_250 : ""
      );
      formdata.append(
        `mspPrices[for_500]`,
        margin?.for_500 ? margin?.for_500 : ""
      );
      formdata.append(
        `mspPrices[for_1000]`,
        margin?.for_1000 ? margin?.for_1000 : ""
      );

      // DISTRIBUTOR PRICE
      formdata.append(`distributorPrices[vendor_product_code]`, vendorProCode);
      formdata.append(`distributorPrices[for_25]`, distributorPrice?.for_25);
      formdata.append(`distributorPrices[for_50]`, distributorPrice?.for_50);
      formdata.append(`distributorPrices[for_100]`, distributorPrice?.for_100);
      formdata.append(`distributorPrices[for_150]`, distributorPrice?.for_150);
      formdata.append(`distributorPrices[for_250]`, distributorPrice?.for_250);
      formdata.append(`distributorPrices[for_500]`, distributorPrice?.for_500);
      formdata.append(
        `distributorPrices[for_1000]`,
        distributorPrice?.for_1000
      );

      // PRICE CHARGE PRICE
      if (priceCharge?.length > 0) {
        priceCharge?.map((item, ind) => {
          formdata.append(
            `additionalPrices[${ind}][name]`,
            item?.name ? item?.name : ""
          );
          formdata.append(
            `additionalPrices[${ind}][for_25]`,
            item?.for_25 ? item?.for_25 : ""
          );
          formdata.append(
            `additionalPrices[${ind}][for_50]`,
            item?.for_50 ? item?.for_50 : ""
          );
          formdata.append(
            `additionalPrices[${ind}][for_100]`,
            item?.for_100 ? item?.for_100 : ""
          );
          formdata.append(
            `additionalPrices[${ind}][for_150]`,
            item?.for_150 ? item?.for_150 : ""
          );
          formdata.append(
            `additionalPrices[${ind}][for_250]`,
            item?.for_250 ? item?.for_250 : ""
          );
          formdata.append(
            `additionalPrices[${ind}][for_500]`,
            item?.for_500 ? item?.for_500 : ""
          );
          formdata.append(
            `additionalPrices[${ind}][for_1000]`,
            item?.for_1000 ? item?.for_1000 : ""
          );
        });
      }

      setPriceBtn(true);
      dispatch(saveLoader(true));
      if (types == "map") {
        formdata.append("product_id", location?.state?.item?.id);
        productPriceApi(formdata)
          .unwrap()
          .then((res) => {
            // console.log("res", res);
            // navigate("/product-vendor-details", {
            //   state: { tab: 3, productTab: 1, data: location?.state?.data },
            // });
            navigator();
            setPriceBtn(false);
            dispatch(saveLoader(false));
          })
          .catch((err) => {
            setPriceBtn(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });
      } else {
        formdata.append("product_id", viewData?.product_details?.id);
        approvedEditApi({
          payload: formdata,
          id: viewData?.product_details?.id,
        })
          .unwrap()
          .then((res) => {
            navigate("/product-vendor-details", {
              state: {
                data: location?.state?.data,
                tab: 3,
                productList: "price",
              },
            });
            setPriceBtn(false);
            dispatch(saveLoader(false));
          })
          .catch((err) => {
            setPriceBtn(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });
      }
    }
  };

  // console.log("distributorPrice", distributorPrice);

  // VIEW ACCEPT PRODUCT
  const getAcceptProView = (id) => {
    proVendorPriceReqShowApi(id)
      .unwrap()
      .then((res) => {
        getDetails(res?.product_details, res?.vendor_price_details);
        setViewData(res);
        if (res?.vendor_price_details?.vendor_product_code) {
          setVendorProCode(res?.vendor_price_details?.vendor_product_code);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // VIEW PRODUCT
  const getView = (id) => {
    let formdata = new FormData();
    formdata.append("product_id", id);
    formdata.append("vendor_id", location?.state?.data?.id);
    // viewProductApi(id)
    showMapProductApi(formdata)
      .unwrap()
      .then((res) => {
        // data?.category
        // console.log("res", res);
        getDetails(res?.product_details, res?.vendor_price_details);
        setViewData(res);
        if (res?.vendor_price_details?.vendor_product_code) {
          setVendorProCode(res?.vendor_price_details?.vendor_product_code);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getDetails = (data, prices) => {
    setCountry(data?.country_origin);
    setMrp(data?.mrp);
    setProductDescription(data?.description);
    setProductPackageSize(data?.package_size);
    setName(data?.name);
    setGendor(data?.product_for);

    let tagsName = [];
    let tagsID = [];
    let hsnsName = [];
    let hsnsCode = [];
    let DecorationName = [];
    let DecorationID = [];
    let productAttributesName = [];
    let productAttributesID = [];
    let productImagesArr = [];
    let morkupImagesArr = [];
    let colName = [];
    let colID = [];
    let childs = [];
    let colorsDataArr = [];
    let categorys = [];
    let cat = {};
    let subCategoryListArr = [];

    data?.category?.parentCategory?.map((item, ind) => {
      if (item?.icon) {
        setCatogory(item?.name);
      }
    });

    // CATEGORY
    data?.category?.parentCategory?.map((item, ind) => {
      if (item?.code) {
        setProductSuplierCode(item?.code);
      }

      if (item?.icon) {
        cat.status = item?.status;
        cat.icon = item?.icon;
        cat.id = item?.id;
        cat.name = item?.name;
        setParentCategoryList(item);
      } else {
        categorys.push(item);
      }
    });

    categorys.push({
      icon: data?.category?.icon,
      id: data?.category?.id,
      name: data?.category?.name,
      status: data?.category?.status,
    });

    categorys?.map((item, ind) => {
      let obj = { ...item };
      if (ind == 0) {
        obj.category = cat;
      } else {
        obj.category = categorys[ind - 1];
      }
      subCategoryListArr.push(obj);
    });

    subCategoryListArr?.map((item, ind) => {
      setCatogoryName((val) => ({
        ...val,
        [item?.category?.id]: item?.name,
      }));
    });

    // console.log("subCategoryListArr", subCategoryListArr);

    setSubCategoryList(subCategoryListArr);

    data?.tags?.map((item, ind) => {
      tagsName.push(item?.name);
      tagsID.push(item?.id);
      setTagVal(item?.id);
    });

    data?.productDecorations?.map((item, ind) => {
      DecorationName.push(item?.name);
      DecorationID.push(item?.id);
      setDecorationMethod(item?.id);
    });

    hsnsName.push(data?.hsn?.code);
    hsnsCode.push(data?.hsn?.id);

    data?.productAttributes?.map((item, ind) => {
      productAttributesName.push(item?.attribute_name);
      productAttributesID.push({
        id: item?.attribute_id,
        // attribute_id: item?.attribute_id,
        title: item?.attribute_name,
        value: item?.value,
      });
    });

    data?.productImage?.map((item, ind) => {
      productImagesArr.push(item?.image_url);
      setProductImg(item?.image_url);
    });

    data?.morkupImage?.map((item, ind) => {
      morkupImagesArr.push(item?.image_url);
      setMorkupImg(item?.image_url);
    });

    data?.colors?.map((item, ind) => {
      colName.push(item?.name);
    });

    let childData = [];
    data?.product_parent_skus?.map((item, ind) => {
      item?.product_child_skus?.map((chi, inx) => {
        childData.push({
          color_id: chi?.color_id,
          sku_code: chi?.variant_sku,
          sku_name: chi?.variant_name,
          status: chi?.status,
          id: chi?.id,
          variant_sku: item?.variant_sku,
        });
      });

      childs.push({
        [item?.variant_sku]: item?.variant_sku,
        parent_name: item?.variant_sku,
        childs: childData.filter((val) => val.variant_sku == item?.variant_sku),
      });
    });

    data?.colorImages?.map((item, ind) => {
      let finder = data?.colors?.find((col) => col?.id == item?.color_id);
      colorsDataArr.push({
        color_id: item?.id,
        id: finder?.id,
        code: finder?.code,
        hex_code: finder?.hex_code,
        name: finder?.name,
        image: item?.image_url,
        status: finder?.status,
        description: item?.description,
        image_url: item?.image_url,
      });
    });

    // console.log("childs", childs);

    // data?.category?.parentCategory?.map((item, ind) => {
    //   if (item?.code) {
    //     setProductSuplierCode(item?.code);
    //   }

    //   if (!item?.icon) {
    //     subCategoryListArr.push({
    //       ...item,
    //       category: item,
    //     });
    //   }
    // });

    // subCategoryListArr.push({
    //   icon: data?.category.icon,
    //   id: data?.category.id,
    //   name: data?.category.name,
    //   status: data?.category.status,
    //   category: {
    //     ...data?.category,
    //   },
    // });

    if (data?.sizes?.length > 0) {
      if (data?.sizes?.[0]?.value) {
        setSizes(data?.sizes);
      } else {
        setSizes([]);
      }
    }

    if (data?.materials?.length > 0) {
      if (data?.materials?.[0]?.value) {
        setMeterials(data?.materials);
      } else {
        setMeterials([]);
      }
    }

    if (data?.styles?.length > 0) {
      if (data?.styles?.[0]?.value) {
        setStyles(data?.styles);
      } else {
        setMeterials([]);
      }
    }

    let catArrs = data?.category?.parentCategory;

    setPrimaryCategoryId(catArrs?.[0]?.id);
    setSecondaryCategoryId(catArrs?.[1]?.id);
    setCategoryID(data?.category?.id);
    setColorsID(data?.colors);
    setColorsName(colName);
    setSmallImage(productImagesArr);
    setSmallMockImage(morkupImagesArr);
    setProductInformation(productAttributesID);
    setDynamicAttr(productAttributesName);
    setDecorativeID(DecorationID);
    setDecorativeName(DecorationName);
    setTagsName(tagsName);
    setTags(tagsID);
    setHsnName(hsnsName);
    setHsnCode(hsnsCode);
    setColorsData(colorsDataArr);
    setSubCategoryList(subCategoryListArr);

    if (childs?.length > 0) {
      setVarientShow(true);
      setVarientList(childs);
    }

    // console.log("prices1", prices);

    if (prices) {
      if (JSON.stringify(prices)[0] === "{") {
        // console.log("prices", prices);
        if (
          prices?.cost &&
          prices?.distribute_price &&
          prices?.margin &&
          prices?.net &&
          prices?.add_charge
        ) {
          setCost(prices?.cost);

          let distributeObj = { ...prices?.distribute_price };
          setDistributorPrice({
            for_25: distributeObj?.for_25,
            for_50: distributeObj?.for_50,
            for_100: distributeObj?.for_100,
            for_150: distributeObj?.for_150,
            for_250: distributeObj?.for_250,
            for_500: distributeObj?.for_500,
            for_1000: distributeObj?.for_1000,
          });
          setMargin(prices?.margin);
          setNet(prices?.net);
          setPriceCharge(prices?.add_charge);
        }
      }
    }
  };

  // ADD CHANGES
  const addpriceCharge = () => {
    let temp = [...priceCharge];

    temp.push({
      name: "",
      for_25: "",
      for_50: "",
      for_100: "",
      for_150: "",
      for_250: "",
      for_500: "",
      for_1000: "",
    });
    setPriceCharge(temp);
  };

  // REMOVE CHANGES
  const removepriceCharge = (e, ind) => {
    let temp = [...priceCharge];
    temp.splice(ind, 1);
    setPriceCharge(temp);
  };

  // CHARGES VALUE CHANGER
  const handlepriceCharge = (e, index, type) => {
    let temp = [...priceCharge];
    const name = e.target.name;
    const value = e.target.value;

    if (type == "name") {
      let obj = {
        ...temp[index],
        [type]: value,
      };

      temp[index] = obj;
      setPriceCharge(temp);
    } else {
      let obj = {
        ...temp[index],
        [type]: value,
      };

      temp[index] = obj;
      setPriceCharge(temp);
    }
  };

  // COST VALUE CHANGER
  const handleCost = (event, type) => {
    setCost({ ...cost, [type]: event });
  };

  // MARGIN VALUE AND DISTRIBUTOR PRICE CHANGER
  const handleMargin = (event, type) => {
    // let percen = net[type];
    // let cal = (+percen / 100) * +event;
    // let sum_value = +cal + +percen;
    setMargin({ ...margin, [type]: event });
    // setDistributorPrice({ ...net, [type]: sum_value });
  };

  const handleCloseMargin = () => {
    setDistributorPrice(net);
    setMargin({
      for_25: "",
      for_50: "",
      for_100: "",
      for_150: "",
      for_250: "",
      for_500: "",
      for_1000: "",
    });
    setMarginBox(!marginBox);
  };

  // FOR CALCULATION
  useEffect(() => {
    if (priceCharge?.length > 0) {
      const result = priceCharge?.reduce((accum, current) => {
        Object.entries(current).forEach(([key, value]) => {
          accum[key] = +accum[key] + +value || +value;
        });
        return {
          ...accum,
        };
      }, {});

      delete result?.name;
      const net_value = Object.fromEntries(
        Object.keys(cost).map((k) => [k, +cost[k] + +result[k]])
      );

      setNet(net_value);

      const distributorPrice = Object.fromEntries(
        Object.keys(net_value).map((k) => [
          k,
          Number((+net_value[k] / 100) * +margin[k] + +net_value[k]).toFixed(),
        ])
      );
      setDistributorPrice({
        for_25: distributorPrice?.for_25,
        for_50: distributorPrice?.for_50,
        for_100: distributorPrice?.for_100,
        for_150: distributorPrice?.for_150,
        for_250: distributorPrice?.for_250,
        for_500: distributorPrice?.for_500,
        for_1000: distributorPrice?.for_1000,
      });
    } else if (marginBox == false) {
      const distributorPrice = Object.fromEntries(
        Object.keys(cost).map((k) => [
          k,
          Number((+cost[k] / 100) * +margin[k] + +cost[k]).toFixed(),
        ])
      );
      setNet(cost);
      setDistributorPrice({
        for_25: distributorPrice?.for_25,
        for_50: distributorPrice?.for_50,
        for_100: distributorPrice?.for_100,
        for_150: distributorPrice?.for_150,
        for_250: distributorPrice?.for_250,
        for_500: distributorPrice?.for_500,
        for_1000: distributorPrice?.for_1000,
      });
    } else {
      setNet(cost);
      setDistributorPrice({
        for_25: cost?.for_25,
        for_50: cost?.for_50,
        for_100: cost?.for_100,
        for_150: cost?.for_150,
        for_250: cost?.for_250,
        for_500: cost?.for_500,
        for_1000: cost?.for_1000,
      });
    }
  }, [cost, priceCharge, margin]);

  useEffect(() => {
    if (location?.state?.item?.id) {
      if (location?.state?.type == "approve") {
        getAcceptProView(location?.state?.item?.id);
      } else {
        getView(location?.state?.item?.id);
      }
    }
  }, []);

  const navigator = () => {
    if (location?.state?.type == "approve") {
      navigate("/product-vendor-details", {
        state: { data: location?.state?.data, tab: 3, productList: "products" },
      });
    } else {
      navigate("/product-vendor-details", {
        state: { data: location?.state?.data, tab: 3, productList: "product" },
      });
    }
  };

  // console.log("location?.state", location?.state);

  useEffect(() => {
    let distributorCheck = Object.values(distributorPrice).some((x) => +x == 0);
    // console.log("distributorCheck1", distributorCheck);
    if (distributorCheck == false) {
      setDistributorPriceErr(false);
    } else if (distributorPriceCheckErr) {
      setDistributorPriceErr(true);
    }
  }, [distributorPrice]);

  const handleEdit = () => {
    setDisabled(!disabled);
    setEdit(!edit);
    setVendorProCodeErr(false);
    // setVendorProCode("");
    setDistributorPriceCheckErr(false);
    setDistributorPriceErr(false);
  };

  // console.log("distributorPrice", distributorPrice);

  return (
    <div className="dashRightView  p-5 home_section trans">
      <ProductSearchHeader />
      <div className="w-100 top_head_cust py-3">
        <h5 class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mx-3">
          {location?.state?.data?.legal_name}
        </h5>
      </div>
      <div className="d-flex mt-2 ac-jb flex-column flex-md-row w-100">
        <div className="d-flex mt-3 ac-jc">
          <p
            className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary d-flex align-items-center "
            role={"button"}
            onClick={() => navigator()}
          >
            <KeyboardBackspaceIcon />
          </p>
          <div
            onClick={() => {
              setpriceBook(true);
              setTagged(false);
              setPreviousOrder(false);
              setEdit(false);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              priceBook
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-4 text-center`}
          >
            <p
              className={`${
                priceBook ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center `}
            >
              Overview
            </p>
          </div>
          {/* <div className="ms-5">
            <button
              className="cust_two_S cust-btn f3 primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-18"
              onClick={() => setEdit(!edit)}
            >
              {edit ? "Submit" : "Edit"}
            </button>
          </div> */}
        </div>
        <div className="mt-md-4 mt-3">
          <h5 class="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 w-100 text-dark">
            Currency :
            <span className="primary f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25">
              {" "}
              INR
            </span>
          </h5>
        </div>
      </div>
      {priceBook && (
        <OverViewComp
          types={types}
          colorsData={colorsData}
          setColorsData={setColorsData}
          selColors={selColors}
          setSelColors={setSelColors}
          parentCategoryList={parentCategoryList}
          distributorPriceErr={distributorPriceErr}
          // getCategoryId={getCategoryId}
          subCategoryList={subCategoryList}
          setSubCategoryList={setSubCategoryList}
          subCategoryLists={subCategoryLists}
          setSubCategoryLists={setSubCategoryLists}
          setParentCategory={setParentCategory}
          decorativeList={decorativeList}
          decorativeID={decorativeID}
          setDecorativeID={setDecorativeID}
          decorativeName={decorativeName}
          setDecorativeName={setDecorativeName}
          country={country}
          setCountry={setCountry}
          hsnList={hsnList}
          hsnCode={hsnCode}
          setHsnCode={setHsnCode}
          hsnName={hsnName}
          setHsnName={setHsnName}
          tagsList={tagsList}
          tags={tags}
          setTags={setTags}
          tagsName={tagsName}
          setTagsName={setTagsName}
          colorsList={colorsList}
          colorsID={colorsID}
          setColorsID={setColorsID}
          colorsName={colorsName}
          setColorsName={setColorsName}
          // varientCombination={varientCombination}
          varientShow={varientShow}
          sizes={sizes}
          setSizes={setSizes}
          materials={materials}
          setMeterials={setMeterials}
          styles={styles}
          setStyles={setStyles}
          allVarients={allVarients}
          // deleteVarient={deleteVarient}
          catogoryName={catogoryName}
          setCatogoryName={setCatogoryName}
          searchValueSubCategory={searchValueSubCategory}
          setSearchSubValueCategory={setSearchSubValueCategory}
          productSuplierCode={productSuplierCode}
          setProductSuplierCode={setProductSuplierCode}
          // onSubCategorySelect={onSubCategorySelect}
          categoryID={categoryID}
          setCategoryID={setCategoryID}
          primaryCategoryIdErr={primaryCategoryIdErr}
          secondaryCategoryIdErr={secondaryCategoryIdErr}
          name={name}
          setName={setName}
          mrp={mrp}
          setMrp={setMrp}
          decorationMethod={decorationMethod}
          setDecorationMethod={setDecorationMethod}
          productDescription={productDescription}
          setProductDescription={setProductDescription}
          productPackageSize={productPackageSize}
          setProductPackageSize={setProductPackageSize}
          productPackageSizeErr={productPackageSizeErr}
          setProductPackageSizeErr={setProductPackageSizeErr}
          productDescriptionErr={productDescriptionErr}
          setProductDescriptionErr={setProductDescriptionErr}
          decorationMethodErr={decorationMethodErr}
          setDecorationMethodErr={setDecorationMethodErr}
          mrpErr={mrpErr}
          setMrpErr={setMrpErr}
          nameErr={nameErr}
          setNameErr={setNameErr}
          countryErr={countryErr}
          setCountryErr={setCountryErr}
          categoryIDErr={categoryIDErr}
          setCategoryIDErr={setCategoryIDErr}
          setGendor={setGendor}
          gendor={gendor}
          gendorErr={gendorErr}
          secondaryCategoryId={secondaryCategoryId}
          setSecondaryCategoryId={setSecondaryCategoryId}
          primaryCategoryId={primaryCategoryId}
          setPrimaryCategoryId={setPrimaryCategoryId}
          // submitHandle={submitHandle}
          attributesList={attributesList}
          productInformation={productInformation}
          setProductInformation={setProductInformation}
          varientsList={varientsList}
          // submitVarient={submitVarient}
          setTagVal={setTagVal}
          tagVal={tagVal}
          hsnCodeErr={hsnCodeErr}
          tagErr={tagErr}
          btn={btn}
          submitBtn={submitBtn}
          catogory={catogory}
          setCatogory={setCatogory}
          listAttributes={listAttributes}
          setListAttributes={setListAttributes}
          dynamicAttr={dynamicAttr}
          setDynamicAttr={setDynamicAttr}
          viewData={viewData}
          setSmallImage={setSmallImage}
          setSmallMockImage={setSmallMockImage}
          smallimage={smallimage}
          smallMockimage={smallMockimage}
          productImg={productImg}
          setProductImg={setProductImg}
          productImgErr={productImgErr}
          setProductImgErr={setProductImgErr}
          morkupImg={morkupImg}
          setMorkupImg={setMorkupImg}
          morkupImgErr={morkupImgErr}
          setMorkupImgErr={setMorkupImgErr}
          colorImg={colorsImg}
          setColorsImg={setColorsImg}
          colorImgErr={colorImgErr}
          setColorImgErr={setColorImgErr}
          vendorProCode={vendorProCode}
          setVendorProCode={setVendorProCode}
          vendorProCodeErr={vendorProCodeErr}
          vendorRef={vendorRef}
        />
      )}
      {/* {previousOrder && <VendorPricingComp types={types} edit={edit} />} */}
      <PricingBoxCompNew
        types={types}
        handleEdit={handleEdit}
        edit={edit}
        setEdit={setEdit}
        distributorPriceErr={distributorPriceErr}
        setpriceBook={setpriceBook}
        setPreviousOrder={setPreviousOrder}
        setCost={setCost}
        cost={cost}
        setNet={setNet}
        net={net}
        setMargin={setMargin}
        margin={margin}
        setDistributorPrice={setDistributorPrice}
        distributorPrice={distributorPrice}
        setPriceCharge={setPriceCharge}
        priceCharge={priceCharge}
        setDisabled={setDisabled}
        disabled={disabled}
        setMarginBox={setMarginBox}
        marginBox={marginBox}
        addpriceCharge={addpriceCharge}
        removepriceCharge={removepriceCharge}
        handlepriceCharge={handlepriceCharge}
        handleCost={handleCost}
        handleMargin={handleMargin}
        handleCloseMargin={handleCloseMargin}
      />

      <div className="d-flex ac-jb w-10">
        <button
          className="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          onClick={() => navigate(-1)}
        >
          Back
        </button>

        {!location?.state?.item?.vendorRequestDetails?.status
          ?.toLowerCase()
          .startsWith("reje") && (
          <button
            className={`cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
              priceBtn ? "opacity-50" : !edit ? "opacity-50" : ""
            }`}
            disabled={priceBtn ? true : !edit ? true : false}
            onClick={() => submitHandle()}
          >
            Submit
          </button>
        )}
      </div>
      <div className="w-100 mt-5">
        <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
          Last updated on August 28, 2023 at 03:10 AM{" "}
        </p>
      </div>
    </div>
  );
};

export default ProductDetailScreen;
