import React, { useEffect, useState } from "react";
import { Search } from "@mui/icons-material";
import CachedIcon from "@mui/icons-material/Cached";
import { product, searchIcon } from "../../assets/img";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useProductSuggestionListMutation } from "../../redux/api/api";

const NewProductSuggest = ({ onTabClick, listCat }) => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({});
  const [list, setList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [search, setSearch] = useState("");
  const [stsSearch, setStsSearch] = useState("");
  const [page, setPage] = useState(1);
  const [catValue, setCatValue] = useState("");

  //RTK
  const [ProductSuggestionApi] = useProductSuggestionListMutation();

  const getProduct = (status, event, category_id) => {
    const formData = new FormData();
    setSearch(event);
    setStsSearch(status);
    let params = `?page=${page}`;
    if (status) {
      formData.append("status", status);
    }
    if (event?.length > 0) {
      formData.append("search", event);
    }

    if (category_id) {
      formData.append("category_id", category_id);
    }

    setCatValue(category_id);

    if (startDate && endDate) {
      formData.append("from_date", startDate);
      formData.append("to_date", endDate);
    }

    ProductSuggestionApi({ payload: formData, params: params })
      .unwrap()
      .then((res) => {
        // console.log(res)
        setList(res?.lists);
        setPagination(res?.pagination_meta);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getProduct();
  }, [page]);

  useEffect(() => {
    if (startDate && endDate) {
      getProduct();
    }
  }, [startDate, endDate]);

  return (
    <>
      <div className="d-flex ac-jb flex-column flex-md-row">
        <div className="d-flex gap-3">
          {/* <select
            className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3"
            onChange={(e) => getProduct("", "", e.target.value)}
            value={catValue}
          >
            <option hidden>Select Categories</option>
            {listCat?.map((item, ind) => {
              return (
                <option key={ind} value={item?.id}>
                  {item?.name}
                </option>
              );
            })}
          </select> */}
          <select
            className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3"
            onChange={(e) => getProduct(e.target.value, "")}
            value={stsSearch}
          >
            <option hidden>Select Status</option>
            <option value={""} hidden>
              Select Status
            </option>
            <option value={"1"}>Requested </option>
            <option value={"2"}>Inprogress</option>
            <option value={"3"}>Accepted</option>
            <option value={"4"}>Rejected</option>
          </select>
        </div>
        <div className="d-flex ac-jb flex-column flex-md-row gap-3 justify-content-md-end">
          <div className="d-flex  border-search">
            <img src={searchIcon} alt="img" className="searchiConImg mt-1" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
              // onChange={(e) => getList(e.target.value, "", tab, 1)}
              onChange={(e) => getProduct("", e.target.value)}
              value={search}
            />
          </div>
          <div className="d-flex ac-jc gap-3">
            <button
              className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
              onClick={() => getProduct("", "")}
            >
              <CachedIcon />
            </button>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-4 flex-wrap">
        <div className="d-flex ac-jb w-xs-100 mt-xs-5 position-relative">
          <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary me-3">
            From
          </p>
          <input
            type="date"
            id="from"
            // name="from"
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
            className="cust-btn searchFill w-xs-100 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
          />
          <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mx-3">
            To
          </p>
          <input
            type="date"
            id="from"
            // name="from"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="cust-btn searchFill w-xs-100 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
          />
        </div>
      </div>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
      </div>
      <div className="w-100 d-flex ac-js mt-4 flex-wrap">
        {Array.isArray(list) &&
          list?.map((item, ind) => {
            return (
              <div
                className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 cp position-relative"
                onClick={() =>
                  navigate("/vendors-request-details", {
                    state: { data: item?.id },
                  })
                }
                key={ind}
              >
                <div className="prod-list-box">
                  <div className="cust-btn d-flex ac-jc rounded-3">
                    <img
                      src={item?.image_url ? item?.image_url : product}
                      alt=""
                      className="custom-w-h rounded-3"
                    />
                  </div>
                  <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                    {item?.vendor?.name}
                  </p>
                  <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
                    {item?.name}
                  </p>
                  <p>
                    {item?.status == 1 ? (
                      <p className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 request-text ms-2">
                        Request{" "}
                      </p>
                    ) : item?.status == 2 ? (
                      <p className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 Inprocess-text ms-2">
                        Inprogress
                      </p>
                    ) : item?.status == 3 ? (
                      <p className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 Accept-text ms-2">
                        Accepted
                      </p>
                    ) : item?.status == 4 ? (
                      <p className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 text-danger ms-2">
                        Rejected
                        <OverlayTrigger
                          delay={{ hide: 450, show: 300 }}
                          overlay={(props) => (
                            <Tooltip {...props}>{item?.notes}</Tooltip>
                          )}
                          placement="bottom"
                        >
                          <InfoOutlinedIcon
                            className="fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-1 parag mt-1"
                            style={{ color: "#06679c" }}
                          />
                        </OverlayTrigger>
                      </p>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
      <div className="d-flex justify-content-between mt-3">
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            page == 1 && "opacity-50"
          }`}
          onClick={() => setPage(page - 1)}
          disabled={page == 1 ? true : false}
        >
          Previous
        </button>
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            page == pagination?.last_page && "opacity-50"
          }`}
          onClick={() => setPage(page + 1)}
          disabled={page == pagination?.last_page ? true : false}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default NewProductSuggest;
