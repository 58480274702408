import React, { useRef } from "react";
import ChartBar from "../components/ChartBar/ChartBar";
import LineChart from "../components/ChartBar/LineChart";
import { Search } from "@mui/icons-material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import manImage from "../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { dummyDashboardData } from "../redux/api/DummyJson";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";

const Dashboard = () => {
  const chartRef = useRef();

  return (
    <div className="dashRightView p-5 home_section trans">
      <ProductSearchHeader />
      <div className="d-flex flex-md-row flex-column mt-5 w-100">
        <div className="w-lg-50 w-md-100 w-sm-100 w-xs-100 dailyStatus mt-4 rounded-3">
          <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            My Dashboard
          </p>
          <div className="totalSiteDesWid flex-xs-column flex-wrap d-flex mt-4 w-100">
            <div className="dashTotalDesTotalSites">
              <div className="d-flex">
                <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                  Total Sites
                </p>
                <div className="bg-green1 p-1 mt-2 rounded-3 dotColor" />
              </div>
              <p className="ms-lg-2 ms-sm-2 ms-2 ms-xs-2 f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                08
              </p>
            </div>
            <div className="dashTotalDesTotalSites ms-sm-2 ms-md-3 ms-lg-4">
              <div className="d-flex">
                <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                  Total Sites
                </p>
                <div className="bg-red rounded-3 mt-2 p-1 dotColor" />
              </div>
              <p className="ms-lg-2 ms-sm-2 ms-2 ms-xs-2 f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                08
              </p>
            </div>
            <div className="dashTotalDesTotalSites ms-sm-2 ms-md-3 ms-lg-4">
              <div className="d-flex">
                <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                  Total Sites
                </p>
                <div className="bg-yellow rounded-3 mt-2 p-1 dotColor" />
              </div>
              <p className="ms-lg-2 ms-sm-2 ms-2 ms-xs-2 f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                08
              </p>
            </div>
          </div>
        </div>
        <div className="w-lg-50 mt-4 w-md-100 w-sm-100 w-xs-100 dailyStatus ms-lg-4 rounded-3">
          <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            My Dashboard
          </p>
          <div className="totalSiteDesWid flex-xs-column flex-wrap d-flex mt-4">
            <div className="dashTotalDesTotalSites1">
              <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15">
                Total Sites
              </p>
              <p className="ms-lg-2 ms-sm-2 ms-2 ms-xs-2 f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                08
              </p>
            </div>
            <div className="dashTotalDesTotalSites1 ms-sm-2 ms-md-3 ms-lg-4">
              <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15">
                Total Sites
              </p>
              <p className="ms-lg-2 ms-sm-2 ms-2 ms-xs-2 f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                08
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Schedules
        </p>
        <div className="w-100 d-flex ac-jb flex-md-row flex-column mt-1">
          <div className="dashTotalDesChart w-lg-45 w-md-45 dailyStatus1 rounded-3">
            <p className="f2 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              Daily Status
            </p>
            <p className="f1 w-xs-100 w-sm-100 fs-sm-8 fs-md-9 fs-lg-10 fs-xl-11 fs-xxl-12 gray">
              Task completed daily this month
            </p>
            <div className="mt-5 w-xs-100 w-sm-100 d-flex flex-wrap">
              <div className="chartSpace d-flex">
                <ChartBar height={60} />
              </div>
              <div className="chartSpace d-flex">
                <ChartBar height={70} />
                <ChartBar height={35} />
                <ChartBar height={70} type="static" />
                <ChartBar height={60} />
                <ChartBar height={40} type="static" />
              </div>
              <div className="chartSpace d-flex">
                <ChartBar height={70} type="static" />
                <ChartBar height={35} />
                <ChartBar height={15} />
                <ChartBar height={40} type="static" />
                <ChartBar height={50} />
              </div>
              <div className="chartSpace d-flex">
                <ChartBar height={70} />
                <ChartBar height={45} type="static" />
                <ChartBar height={70} type="static" />
                <ChartBar height={50} />
                <ChartBar height={40} type="static" />
              </div>
              <div className="chartSpace d-flex dataDis">
                <ChartBar height={50} type="static" />
                <ChartBar height={30} />
                <ChartBar height={20} />
                <ChartBar height={35} />
              </div>
            </div>
          </div>
          <div className="dashTotalDesMonths w-50 dailyStatus1 rounded-3 ms-sm-4">
            <p className="f2 w-xs-100 w-sm-100 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              Updates By Months
            </p>
            <LineChart />
          </div>
        </div>
      </div>
      <div className="overflow-scroll">
        <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Mockup List
        </p>
        <div className="wholeDesignDash d-flex mt-4 border-bottom pb-3">
          {/* <div className="w-10">
            <FilterAltIcon className="f1 fs-xs-15 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18" />
          </div> */}
          <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
            Project Status
          </p>
          <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
            S.no
          </p>
          <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
            Site Name
          </p>
          <p className="w-25 ms-4 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
            Decoration Products
          </p>
          <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
            Product Image
          </p>
          <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
            Decoration Assests
          </p>
        </div>
        {dummyDashboardData?.map((item, index) => {
          return (
            <div className="wholeDesignDash mb-3 d-flex mt-4" key={index}>
              <div className="w-15 ps-4">
                {item?.status === "Success" && (
                  <DoneIcon className="bg-green1 white ms-3 rounded-5 f2 p-1 fs-xs-24 fs-sm-24 fs-md-24 fs-lg-24 fs-xl-24 fs-xxl-24" />
                )}
                {item?.status === "Fail" && (
                  <CloseIcon className="bg-red white ms-3 rounded-5 f2 p-1 fs-xs-24 fs-sm-24 fs-md-24 fs-lg-24 fs-xl-24 fs-xxl-24" />
                )}
                {item?.status === "Processing" && (
                  <HourglassBottomIcon className="bg-yellow ms-3 white rounded-5 f2 p-1 fs-xs-24 fs-sm-24 fs-md-24 fs-lg-24 fs-xl-24 fs-xxl-24" />
                )}
              </div>
              <p className="w-7 primary1 ps-1 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                {item?.slNo}
              </p>
              <p className="w-13 f2 primary1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                {item?.siteName}
              </p>
              <p className="w-16 ms-5 f2 primary1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16">
                {item?.productName}
              </p>
              <div className="w-15 dashImgDesig">
                <img src={item?.imgDec} className="imgDashboard" />
              </div>
              <div className="w-10 ms-2">
                <img src={item?.img} className="imgDashboard" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Dashboard;
