// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ImageCroper from "../components/Popup/ImageCroper";
import { useDispatch } from "react-redux";
import {
  useCurrencyCreateMutation,
  useCurrencyEditMutation,
  useCurrencyListMutation,
  useLazyCurrencyViewQuery,
} from "../redux/api/api";
import { saveLoader } from "../redux/slice/loaderNoti";
import ErrorIcon from "@mui/icons-material/Error";
import { listCurrencies } from "../redux/api/DummyJson";

const CurrencyAdd = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [croperImage, setCropImage] = useState();
  const [croper, setCroper] = useState(false);
  const navigate = useNavigate();

  const [countryName, setCountryName] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");
  const [currencyName, setCurrencyName] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [toINR, setToINR] = useState("");
  const [status, setStatus] = useState(1);

  const [countryNameErr, setCountryNameErr] = useState(false);
  const [currencySymbolErr, setCurrencySymbolErr] = useState(false);
  const [currencyNameErr, setCurrencyNameErr] = useState(false);
  const [currencyCodeErr, setCurrencyCodeErr] = useState(false);
  const [toINRErr, setToINRErr] = useState(false);
  const [statusErr, setStatusErr] = useState(false);
  const [btn, setBtn] = useState(false);

  // RTK QUERY
  const [currencyEditApi] = useCurrencyEditMutation();
  const [currencyCreateApi] = useCurrencyCreateMutation();
  const [currencyViewApi] = useLazyCurrencyViewQuery();

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  const getView = () => {
    currencyViewApi(location?.state?.data?.id)
      .unwrap()
      .then((res) => {
        let data = res?.data;
        setCountryName(data?.name);
        setCurrencyCode(data?.code);
        setCurrencyName(data?.currency_name);
        setStatus(data?.status);
        setCurrencySymbol(data?.currency_symbol);
        setToINR(data?.to_inr_conversion);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (location?.state?.data?.id) {
      getView();
    }
  }, []);

  const submitHandle = () => {
    if (
      currencyName?.length == 0 ||
      // currencyCode?.length == 0 ||
      toINR?.length == 0
      // ||
      // status?.length == 0
    ) {
      // setCurrencyCodeErr(true);
      setCountryNameErr(true);
      setToINRErr(true);
      // setStatusErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("name", countryName);
      formdata.append("code", currencyCode);
      formdata.append("currency_name", currencyName);
      formdata.append("currency_symbol", currencySymbol);
      formdata.append("to_inr_conversion", toINR);
      formdata.append("status", status);
      setBtn(true);
      dispatch(saveLoader(true));
      if (location?.state?.data?.id) {
        currencyEditApi({ payload: formdata, id: location?.state?.data?.id })
          .unwrap()
          .then((res) => {
            dispatch(saveLoader(false));
            setBtn(false);
            navigate(-1);
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setBtn(false);
            console.log("err", err);
          });
      } else {
        currencyCreateApi(formdata)
          .unwrap()
          .then((res) => {
            dispatch(saveLoader(false));
            setBtn(false);
            navigate(-1);
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setBtn(false);
            console.log("err", err);
          });
      }
    }
  };

  const getCurrency = (event) => {
    setCountryName(event);
    let finder = listCurrencies?.find((i) => i?.name == event);
    setCurrencyCode(finder?.currency?.code);
    setCurrencyName(finder?.currency?.name);
    setCurrencySymbol(finder?.currency?.symbol);
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <p
        className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-0 mb-3 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon />
        {location?.state?.type == "edit"
          ? "Edit"
          : location?.state?.type == "view"
          ? "View"
          : "Add"}{" "}
        Currency
      </p>
      <div className="w-100 searchField mt-0 bg-white ">
        <div className="w-100 d-flex flex-xxl-row flex-xl-row flex-lg-row flex-column">
          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Country Name:
              </p>
              <select
                placeholder=""
                className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                name=""
                id=""
                onChange={(e) => getCurrency(e.target.value)}
                value={countryName}
                disabled={location?.state?.type == "view" ? true : false}
              >
                <option value={""}>Select Country</option>
                {listCurrencies?.map((item, ind) => {
                  return (
                    <option value={item?.name} key={ind}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
              {countryName?.length == 0 && countryNameErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Country Name</p>
                </div>
              )}
            </div>

            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Currency Name:
              </p>

              <input
                className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                type="text"
                onChange={(e) => setCurrencyName(e.target.value)}
                value={currencyName}
                // disabled={location?.state?.type == "view" ? true : false}
                disabled
              />
              {currencyName?.length == 0 && currencyNameErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Currency Name</p>
                </div>
              )}
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Currency Code:
              </p>

              <input
                className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                type="text"
                onChange={(e) => setCurrencyCode(e.target.value)}
                value={currencyCode}
                // disabled={location?.state?.type == "view" ? true : false}
                disabled
              />
              {currencyCode?.length == 0 && currencyCodeErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Currency Code</p>
                </div>
              )}
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Currency Symbol:
              </p>

              <input
                className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                type="text"
                onChange={(e) => setCurrencySymbol(e.target.value)}
                value={currencySymbol}
                // disabled={location?.state?.type == "view" ? true : false}
                disabled
              />
              {currencySymbol?.length == 0 && currencySymbolErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Currency Symbol</p>
                </div>
              )}
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                To INR:
              </p>

              <input
                className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                type="text"
                onChange={(e) => setToINR(e.target.value)}
                value={toINR}
                disabled={location?.state?.type == "view" ? true : false}
              />
              {toINR?.length == 0 && toINRErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter To INR</p>
                </div>
              )}
            </div>

            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Status
              </p>

              <select
                placeholder=""
                className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                name=""
                onChange={(e) => setStatus(e.target.value)}
                value={status}
                disabled={location?.state?.type == "view" ? true : false}
                id=""
              >
                <option value={1}>Active</option>
                <option value={0}>De Active</option>
              </select>

              {status?.length == 0 && statusErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Status</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex ac-jb">
        <button
          onClick={() => navigate(-1)}
          className="pointerView cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
        >
          Back
        </button>
        <button
          onClick={() => submitHandle()}
          disabled={btn}
          className={`pointerView cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            btn && "opacity-50"
          }`}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default CurrencyAdd;
