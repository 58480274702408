import React from "react";
import FormComp from "../AddNewProductComps/FormComp";
import ImageUplodeComp from "../AddNewProductComps/ImageUplodeComp";
import LeftComp from "./LeftComp";
import RightComp from "./RightComp";
import ImageRightComp from "./ImageRightComp";

const OverViewComp = ({
  edit,
  types,
  decorativeMethod,
  setDecorativeMethod,
  list,
  data,
}) => {
  return (
    <div className="d-flex as-jb w-100 mt-5 res-flex gap-5">
      <LeftComp
        edit={edit}
        types={types}
        setDecorativeMethod={setDecorativeMethod}
        decorativeMethod={decorativeMethod}
        list={list}
        data={data}
      />
      <RightComp types={types} edit={edit} list={list} />
      {/* <ImageRightComp /> */}
    </div>
  );
};

export default OverViewComp;
