import React, { useState } from "react";
import { Col } from "react-bootstrap";

const AddArtWorker = ({
  toggleShowPopup,
  setAddArt,
  toggleShowPopups,
  statusOption,
}) => {
  const [ticketStatus, setTicketStatus] = useState(1);
  const status = [
    {
      list: "Inprogress",
    },
    { list: "Approved" },
    {
      list: "Rejected",
    },
  ];
  const design_status = [
    {
      list: "Inprogress",
    },
    { list: "Completed" },
  ];
  return (
    <div className="add-client-contact-popup ">
      <div className="addArt" style={{ height: "90vh", overflow: "auto" }}>
        <div className="d-flex justify-content-between">
          <div>
            <p className="mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
              {" "}
              Add Artwork
            </p>
          </div>

          {statusOption && (
            <div className="d-flex ac-js">
              <Col sm={6} md={6} lg={6} className="">
                <div className="d-flex flex-column flex-md-row flex-wrap m-2">
                  <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Sales Status
                  </p>
                  <select
                    placeholder="Status"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  >
                    {status?.map((item, ind) => {
                      return (
                        <option key={item?.list} value={item?.list}>
                          {item?.list}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </Col>
              <Col sm={6} md={6} lg={6} className="position-relative rel_sty">
                <div className="d-flex flex-column flex-md-row flex-wrap m-2">
                  <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Design Status
                  </p>
                  <select
                    placeholder="Status"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  >
                    {design_status?.map((item, ind) => {
                      return (
                        <option key={item?.list} value={item?.list}>
                          {item?.list}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </Col>
            </div>
          )}

          <div className="mt-2">
            <button
              className=" cust-btn addbtn ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
              onClick={() => toggleShowPopup()}
            >
              Cancel
            </button>
            <button
              className="cust-btn addbtn ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
              onClick={() => toggleShowPopup()}
            >
              Save
            </button>
          </div>
        </div>

        <div className="popUpForm d-flex flex-wrap">
          <Col sm={12} md={6}>
            <div className="w-100 d-flex ac-js">
              <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                <div className="w-100 d-flex ac-js mt-3">
                  <p className="w-50 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    {/* Imprint Type: */}
                    Decorative Method
                  </p>
                  <div className="w-100 ms-3">
                    <select
                      placeholder=""
                      className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                      name=""
                      id=""
                    >
                      <option hidden>
                        Please Select an Decorative Method...
                      </option>
                      <option>3D</option>
                      <option>Fabric</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex ac-js">
              <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                <div className="w-100 d-flex ac-js mt-3">
                  <p className="w-50 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    Proof Required:
                  </p>
                  <div className="w-100 ms-3">
                    <select
                      placeholder=""
                      className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                      name=""
                      id=""
                    >
                      <option hidden>Please Select a Proof Type...</option>
                      <option>Email Proof</option>
                      <option>Digital Photo</option>
                      <option>Pre Production</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-2">
              <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                <div className="w-100 d-flex ac-js mt-2">
                  <p className="w-50 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    Logo Name:
                  </p>
                  <div className="w-100 ms-3">
                    <textarea
                      placeholder="Required Field"
                      className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-2">
              <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                <div className="w-100 d-flex ac-js mt-2">
                  <p className="w-50 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    UOM:
                  </p>
                  <div className="w-100 ac-js d-flex gap-3 ms-3">
                    <div className="ps-2 bg-transparent ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 ">
                      <button
                        className="d-flex gap-3  ac-js cust-btn"
                        onClick={() => setTicketStatus(1)}
                      >
                        <div className="radio_btn d-flex ac-jc">
                          <div
                            className={`${
                              ticketStatus === 1 ? "sm_radio" : "sm_radio1"
                            }`}
                          />
                        </div>
                        <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          Inches
                        </p>
                      </button>
                    </div>
                    <div className="ps-2 bg-transparent ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 ">
                      <button
                        onClick={() => setTicketStatus(2)}
                        className="d-flex gap-3  ac-js cust-btn"
                      >
                        <div className="radio_btn d-flex ac-jc">
                          <div
                            className={`${
                              ticketStatus === 2 ? "sm_radio" : "sm_radio1"
                            }`}
                          />
                        </div>
                        <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          Stitch
                        </p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-2">
              <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                <div className="w-100 d-flex ac-js mt-2">
                  <p className="w-50 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    Logo Size:
                  </p>
                  <div className="w-100 ms-3 d-flex gap-2">
                    <input
                      placeholder="Length"
                      className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    />
                    <input
                      placeholder="Breadth"
                      className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-2">
              <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                <div className="w-100 d-flex ac-js mt-2">
                  <p className="w-50 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    Logo Color:
                  </p>
                  <div className="w-100 ms-3">
                    <textarea
                      placeholder="Required Field"
                      className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex ac-js">
              <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                <div className="w-100 d-flex ac-js mt-2">
                  <p className="w-50 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    Repeat Logo?:
                  </p>
                  <div className="w-100 ms-3">
                    <select
                      placeholder=""
                      className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                      name=""
                      id=""
                    >
                      <option></option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-100 d-flex ac-js mt-2">
              <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                <div className="w-100 d-flex ac-js mt-2">
                  <p className="w-50 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                    Supplier Notes:
                  </p>
                  <div className="w-100 ms-3">
                    <textarea
                      placeholder="Required Field"
                      className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6}>
            <div className="field_row mt-3 mx-2 ">
              <div
                className="art-placeholder"
                onClick={() => toggleShowPopups()}
              >
                Select Image
              </div>
            </div>
          </Col>
        </div>
      </div>
    </div>
  );
};

export default AddArtWorker;
