import ProfileNavBar from "../components/ProfileNavBar";
import { Search } from "@mui/icons-material";
import manImage from "../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import AddVendorDetails1 from "../components/AddVendor/AddVendorDetails1";
import AddVendorDetails2 from "../components/AddVendor/AddVendorDetails2";
import DoneIcon from "@mui/icons-material/Done";
import AddVendorFinal from "../components/AddVendor/AddVendorFinal";
import AddVendorPopup from "../components/Popup/AddVendorPopup";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import VendorSuccessPopup from "../components/Popup/VendorSuccessPopup";
import {
  useLazyCategoriesListVendorQuery,
  useLazyCurrencyActiveListQuery,
  useLazyCurrencyListRegisterQuery,
  useLazyTagsListQuery,
  useVendorRegisterMutation,
  useVendor_registerMutation,
  useVendor_store_registerMutation,
} from "../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";

const AddVendor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchparams] = useSearchParams();

  const [secondDet, setSecondDet] = useState(false);
  const [finalView, setFinalView] = useState(false);
  const [vendorCat, setVendorCat] = useState("");
  const [popup, setPopup] = useState(false);
  const [successPop, setSuccessPop] = useState(false);
  const location = useLocation();

  //General Details
  const [categoryList, setCategoryList] = useState([]);
  const [firstname, setfirstname] = useState("");
  const [email, setEmail] = useState("");
  const [vendorCatogory, setVendorCatogory] = useState([]);
  const [currenciesLists, setCurrenciesLists] = useState([]);
  const [mobile, setmobile] = useState("");
  const [name, setname] = useState("");
  const [Tradename, setTradename] = useState("");
  const [vendorOfferType, setVendorOfferType] = useState("");
  const [lineBusiness, setLineBusiness] = useState("");
  const [tags, setTags] = useState([]);
  const [id, setid] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [GstID, setGstID] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [currency, setCurrency] = useState("");
  const [currencyName, setCurrencyName] = useState("");
  const [description, setDescription] = useState("");
  const [password, setpassword] = useState("");
  const [confirmPassword, setConfirmPass] = useState("");
  const [logo, setLogo] = useState("");
  const [banner, setBanner] = useState("");
  const [btn, setBtn] = useState(false);

  //error
  const [nameErr, setnameErr] = useState(false);
  const [lineBusinessErr, setLineBusinessErr] = useState(false);
  const [tagsErr, setTagsErr] = useState(false);
  const [TradenameErr, setTradenameErr] = useState(false);
  const [vendorCatErr, setVendorCatErr] = useState(false);
  const [vendorOfferTypeErr, setVendorOfferTypeErr] = useState(false);
  const [paymentTermsErr, setPaymentTermsErr] = useState(false);
  const [paymentMethodErr, setPaymentMethodErr] = useState(false);
  const [GstIDErr, setGstIDErr] = useState(false);
  const [address1Err, setAddress1Err] = useState(false);
  const [address2Err, setAddress2Err] = useState(false);
  const [countryErr, setCountryErr] = useState(false);
  const [stateErr, setStateErr] = useState(false);
  const [cityErr, setCityErr] = useState(false);
  const [pincodeErr, setPincodeErr] = useState(false);
  const [currencyErr, setCurrencyErr] = useState(false);
  const [descriptionErr, setDescriptionErr] = useState(false);
  const [passwordErr, setpasswordErr] = useState(false);
  const [confirmPasswordErr, setConfirmPassErr] = useState(false);
  const [logoErr, setLogoErr] = useState(false);
  const [bannerErr, setBannerErr] = useState(false);

  const [listStates, setListStates] = useState([]);
  const [listCities, setListCities] = useState([]);

  // const [categoryList, setCategoryList] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [categoryNameErr, setCategoryNameErr] = useState(false);

  useEffect(() => {
    if (location?.state?.type === "email") {
      setSecondDet(true);
    }
  }, [location]);

  // const showPopupHander = () => {
  //   setPopup(false);
  //   setSuccessPop(false);
  // };

  // RTK QUERY
  const [tagsListApi] = useLazyTagsListQuery();

  const [categoryListApi] = useLazyCategoriesListVendorQuery();
  const [vendorRegisterApi] = useVendorRegisterMutation();
  const [currencyListApi] = useLazyCurrencyActiveListQuery();
  const [vendorStoreApi] = useVendor_store_registerMutation();

  //TAGS

  const getTagsList = () => {
    tagsListApi()
      .unwrap()
      .then((res) => {
        console.log(res?.normalTag, "res");
        setTagsList(res?.normalTag);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTagsList();
  }, []);

  const getList = () => {
    categoryListApi()
      .unwrap()
      .then((res) => {
        console.log(res, "res");
        setCategoryList(res?.categories);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getList();
  }, []);

  const [vendorName, setVendorName] = useState("");
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [primaryMobile, setPrimaryMobile] = useState("");
  const [vendorNameErr, setVendorNameErr] = useState(false);
  const [primaryEmailErr, setPrimaryEmailErr] = useState(false);
  const [primaryMobileErr, setPrimaryMobileErr] = useState(false);
  // const [btn, setBtn] = useState(false);
  const [vendorCategories, setVendorCategories] = useState([]);

  console.log(vendorCategories, "cate");

  const submitHandler = () => {
    if (
      vendorName?.length == 0 ||
      primaryEmail?.length == 0 ||
      primaryMobile?.length < 10 ||
      ((vendorOfferType == "Product" || vendorOfferType == "Both") &&
        categoryName?.length == 0) ||
      vendorOfferType?.length == 0
    ) {
      setVendorNameErr(true);
      setPrimaryEmailErr(true);
      setPrimaryMobileErr(true);
      if (vendorOfferType == "Product" || vendorOfferType == "Both") {
        setCategoryNameErr(true);
      }
      setVendorOfferTypeErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("name", vendorName);
      formdata.append("email", primaryEmail);
      formdata.append("mobile", primaryMobile);
      formdata.append(
        "offering_type",
        vendorOfferType == "Product"
          ? 1
          : vendorOfferType == "Services"
          ? 2
          : vendorOfferType == "Both"
          ? 3
          : 0
      );
      if (vendorOfferType == "Product" || vendorOfferType == "Both") {
        vendorCategories?.map((item, ind) => {
          formdata.append(`category_ids[${ind}]`, item);
        });
      }
      // categoryList?.map((item, ind) => {
      //   formdata.append(`category_ids[${ind}]`, item?.id);
      // });
      setBtn(true);
      dispatch(saveLoader(true));
      vendorRegisterApi(formdata)
        .unwrap()
        .then((res) => {
          setPopup(true);
          toast.success(res?.data?.message || res?.message);
          dispatch(saveLoader(false));
          setBtn(false);
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  const showPopupHander = () => {
    setPopup(false);
    setSuccessPop(false);
  };
  console.log(currenciesLists, "currenciesLists6");
  useEffect(() => {
    currencyListApi()
      .unwrap()
      .then((res) => {
        setCurrenciesLists(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  const onNest1 = () => {
    if (
      vendorName?.length == 0 ||
      primaryEmail?.length == 0 ||
      primaryMobile?.length < 10 ||
      ((vendorOfferType == "Product" || vendorOfferType == "Both") &&
        categoryName?.length == 0) ||
      vendorOfferType?.length == 0
    ) {
      setVendorNameErr(true);
      setPrimaryEmailErr(true);
      setPrimaryMobileErr(true);
      if (vendorOfferType == "Product" || vendorOfferType == "Both") {
        setCategoryNameErr(true);
      }
      setVendorOfferTypeErr(true);
    } else {
      setSecondDet(true);
    }
  };

  const onNext = () => {
    if (
      name?.length == 0 ||
      Tradename?.length == 0 ||
      vendorCat?.length == 0 ||
      paymentTerms?.length == 0 ||
      paymentMethod?.length == 0 ||
      GstID?.length == 0 ||
      address1?.length == 0 ||
      // address2.length == 0 ||
      country?.length == 0 ||
      state?.length == 0 ||
      city?.length == 0 ||
      pincode?.length == 0 ||
      currency?.length == 0 ||
      description?.length == 0 ||
      lineBusiness?.length == 0 ||
      tags?.length == 0
    ) {
      setnameErr(true);
      setTradenameErr(true);
      setVendorCatErr(true);

      setPaymentTermsErr(true);
      setPaymentMethodErr(true);
      setGstIDErr(true);
      setAddress1Err(true);
      // setAddress2Err(true);
      setCountryErr(true);
      setStateErr(true);
      setCityErr(true);
      setPincodeErr(true);
      setCurrencyErr(true);
      setDescriptionErr(true);
      // setpasswordErr(true);
      // setConfirmPassErr(true);
      // setLogoErr(true);
      // setBannerErr(true);
      setLineBusinessErr(true);
      setTagsErr(true);
    } else {
      setSecondDet(false);
      setFinalView(true);
    }
  };
  console.log(vendorCategories, "paymentTerms");
  const submitdata = () => {
    if (
      name?.length == 0 ||
      Tradename?.length == 0 ||
      vendorCat?.length == 0 ||
      vendorOfferType?.length == 0 ||
      paymentTerms?.length == 0 ||
      paymentMethod?.length == 0 ||
      GstID?.length == 0 ||
      address1?.length == 0 ||
      // address2.length == 0 ||
      country?.length == 0 ||
      state?.length == 0 ||
      city?.length == 0 ||
      pincode?.length == 0 ||
      currency?.length == 0 ||
      description?.length == 0 ||
      // password.length == 0 ||
      // confirmPassword.length == 0 ||
      // logo.length == 0 ||
      // banner.length == 0 ||
      lineBusiness?.length == 0 ||
      tags?.length == 0
    ) {
      setnameErr(true);
      setTradenameErr(true);
      setVendorCatErr(true);
      setVendorOfferTypeErr(true);
      setPaymentTermsErr(true);
      setPaymentMethodErr(true);
      setGstIDErr(true);
      setAddress1Err(true);
      // setAddress2Err(true);
      setCountryErr(true);
      setStateErr(true);
      setCityErr(true);
      setPincodeErr(true);
      setCurrencyErr(true);
      setDescriptionErr(true);
      // setpasswordErr(true);
      // setConfirmPassErr(true);
      // setLogoErr(true);
      // setBannerErr(true);
      setLineBusinessErr(true);
      setTagsErr(true);
    } else {
      const formdata = new FormData();
      formdata.append("logo", logo);
      formdata.append("banner", banner);
      formdata.append("name", Tradename);
      formdata.append("legal_name", name);
      // formdata.append("code","798777")
      lineBusiness.map((item, id) => {
        formdata.append(`line_of_business[${id}]`, item);
      });
      formdata.append("type_of_business", vendorCat);
      formdata.append(
        "offering_type",
        vendorOfferType == "Product"
          ? 1
          : vendorOfferType == "Services"
          ? 2
          : vendorOfferType == "Both"
          ? 3
          : 0
      );
      formdata.append("payment_terms", paymentTerms);
      paymentMethod?.map((item, id) => {
        formdata.append(`payment_method[${id}]`, item);
      });
      // formdata.append("payment_method", paymentMethod);
      formdata.append("gst", GstID);
      formdata.append("address1", address1);
      formdata.append("country", country);
      formdata.append("city", city);
      formdata.append("state", state);
      formdata.append("pincode", pincode);
      formdata.append("currency_id", currency);
      if (vendorOfferType == "Product" || vendorOfferType == "Both") {
        vendorCategories.map((item, ind) => {
          formdata.append(`category_ids[${ind}]`, item);
        });
      }
      // categoryList?.map((item, ind) => {
      //   formdata.append(`category_ids[${ind}]`, item?.id);
      // });
      formdata.append("description", description);
      // formdata.append("department_id",)
      formdata.append("first_name", vendorName);
      formdata.append("primary_mobile", primaryMobile);
      formdata.append("email", primaryEmail);
      formdata.append("type", 3);
      id.map((item, id) => {
        formdata.append(`tags[${id}]`, item);
      });
      // formdata.append("additional_info",)
      // formdata.append("password", password);
      // formdata.append("password_confirmation", confirmPassword);
      // formdata.append("position","ftfttyg")
      // formdata.append("is_marketing",'GYUhh')
      // formdata.append("mobile",'678676689667')
      // formdata.append("landmark",'678676689667')
      // formdata.append("type",'678676689667')
      // formdata.append("address",address1)
      dispatch(saveLoader(true));
      setBtn(true);
      vendorStoreApi(formdata)
        .unwrap()
        .then((res) => {
          console.log(res);
          toast.success(res?.message);
          setBtn(false);
          dispatch(saveLoader(false));
          navigate("/vendor-management");
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  const keydownHandler = (event) => {
    if (event.key == "Enter") {
      submitdata();
    }
  };
  //tags

  return (
    <div className="dashRightView p-5 home_section trans">
      {/* {popup && (
        <AddVendorPopup
          showPopupHander={showPopupHander}
          setPopup={setPopup}
          setSuccessPop={setSuccessPop}
        />
      )} */}
      {popup && <VendorSuccessPopup showPopupHander={showPopupHander} />}

      <ProductSearchHeader />
      <p
        className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon /> Add Vendor
      </p>

      <div className="d-flex ac-js mt-4">
        {!secondDet && !finalView && (
          <p className="white bg-primar f2 radiusVendDes fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            1
          </p>
        )}
        {(finalView || secondDet) && (
          <DoneIcon className="white bg-green1 f2 radiusVendDesIcon fs-xs-28 fs-sm-28 fs-md-28 fs-lg-28 fs-xl-28 fs-xxl-28 mt-1 " />
        )}
        <p className="black f2 p-2 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
          Primary Detail
        </p>
        <div className="border-bottom w-5 border-primary1" />
        {!finalView && (
          <p
            className={`${
              secondDet ? "bg-primar" : "bg-primary1"
            } white ms-3 f2 radiusVendDes rounded-5 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1`}
          >
            2
          </p>
        )}
        {finalView && (
          <DoneIcon className="white bg-green1 f2 ms-3 radiusVendDesIcon fs-xs-28 fs-sm-28 fs-md-28 fs-lg-28 fs-xl-28 fs-xxl-28 mt-1 " />
        )}
        <p
          className={`
        ${
          secondDet || finalView ? "black" : "primary1"
        } f2 p-2 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1`}
        >
          General Detail
        </p>
        <div className="border-bottom w-5 border-primary1" />

        <p
          className={`
        ${
          finalView ? "bg-primar" : "bg-primary1"
        } white ms-3 f2 radiusVendDes rounded-5 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1`}
        >
          3
        </p>
        <p
          className={`${
            finalView ? "black" : "primary1"
          }  f2 p-2 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1`}
        >
          Final Verification
        </p>
      </div>
      {!secondDet && !finalView && (
        <AddVendorDetails2
          setVendorOfferType={setVendorOfferType}
          vendorOfferTypeErr={vendorOfferTypeErr}
          vendorOfferType={vendorOfferType}
          setVendorCat={setVendorCat}
          vendorCat={vendorCat}
          categoryList={categoryList}
          vendorName={vendorName}
          setVendorName={setVendorName}
          primaryEmail={primaryEmail}
          setPrimaryEmail={setPrimaryEmail}
          primaryMobile={primaryMobile}
          setPrimaryMobile={setPrimaryMobile}
          primaryEmailErr={primaryEmailErr}
          primaryMobileErr={primaryMobileErr}
          vendorNameErr={vendorNameErr}
          vendorCategories={vendorCategories}
          setVendorCategories={setVendorCategories}
          categoryName={categoryName}
          setCategoryName={setCategoryName}
          categoryNameErr={categoryNameErr}
          submitHandler={submitHandler}
          btn={btn}
          // setVendorCat={setVendorCat}
          // vendorCat={vendorCat}
          // firstname={firstname}
          // setfirstname={setfirstname}
          // email={email}
          // setEmail={setEmail}
          // mobile={mobile}
          // setmobile={setmobile}
          // categoryList={categoryList}
        />
      )}
      {secondDet && (
        <AddVendorDetails1
          setVendorCatogory={setVendorCatogory}
          vendorCatogory={vendorCatogory}
          currenciesLists={currenciesLists}
          name={name}
          setname={setname}
          nameErr={nameErr}
          Tradename={Tradename}
          setTradename={setTradename}
          TradenameErr={TradenameErr}
          vendorCat={vendorCat}
          setVendorCat={setVendorCat}
          vendorCatErr={vendorCatErr}
          vendorOfferType={vendorOfferType}
          setVendorOfferType={setVendorOfferType}
          vendorOfferTypeErr={vendorOfferTypeErr}
          paymentTerms={paymentTerms}
          setPaymentTerms={setPaymentTerms}
          paymentTermsErr={paymentTermsErr}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          paymentMethodErr={paymentMethodErr}
          GstID={GstID}
          setGstID={setGstID}
          GstIDErr={GstIDErr}
          address1={address1}
          setAddress1={setAddress1}
          address1Err={address1Err}
          address2={address2}
          setAddress2={setAddress2}
          address2Err={address2Err}
          country={country}
          setCountry={setCountry}
          state={state}
          setState={setState}
          countryErr={countryErr}
          stateErr={stateErr}
          city={city}
          setCity={setCity}
          cityErr={cityErr}
          pincode={pincode}
          setPincode={setPincode}
          pincodeErr={pincodeErr}
          currency={currency}
          setCurrency={setCurrency}
          setCurrencyName={setCurrencyName}
          currencyName={currencyName}
          currencyErr={currencyErr}
          descriptionErr={descriptionErr}
          description={description}
          setDescription={setDescription}
          password={password}
          setpassword={setpassword}
          passwordErr={passwordErr}
          confirmPasswordErr={confirmPasswordErr}
          confirmPassword={confirmPassword}
          setConfirmPass={setConfirmPass}
          logo={logo}
          logoErr={logoErr}
          banner={banner}
          bannerErr={bannerErr}
          setBanner={setBanner}
          setLogo={setLogo}
          lineBusiness={lineBusiness}
          setLineBusiness={setLineBusiness}
          lineBusinessErr={lineBusinessErr}
          setListStates={setListStates}
          listStates={listStates}
          listCities={listCities}
          setListCities={setListCities}
          tags={tags}
          tagsList={tagsList}
          setTagsList={setTagsList}
          setTags={setTags}
          tagsErr={tagsErr}
          setTagsErr={setTagsErr}
          id={id}
          setid={setid}
        />
      )}
      {!secondDet && finalView && (
        <AddVendorFinal
          currenciesLists={currenciesLists}
          name={name}
          setname={setname}
          nameErr={nameErr}
          Tradename={Tradename}
          setTradename={setTradename}
          TradenameErr={TradenameErr}
          vendorCat={vendorCat}
          setVendorCat={setVendorCat}
          vendorCatErr={vendorCatErr}
          vendorOfferType={vendorOfferType}
          setVendorOfferType={setVendorOfferType}
          vendorOfferTypeErr={vendorOfferTypeErr}
          paymentTerms={paymentTerms}
          setPaymentTerms={setPaymentTerms}
          paymentTermsErr={paymentTermsErr}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          paymentMethodErr={paymentMethodErr}
          GstID={GstID}
          setGstID={setGstID}
          GstIDErr={GstIDErr}
          address1={address1}
          setAddress1={setAddress1}
          address1Err={address1Err}
          address2={address2}
          setAddress2={setAddress2}
          address2Err={address2Err}
          country={country}
          setCountry={setCountry}
          state={state}
          setState={setState}
          countryErr={countryErr}
          stateErr={stateErr}
          city={city}
          setCity={setCity}
          cityErr={cityErr}
          pincode={pincode}
          setPincode={setPincode}
          pincodeErr={pincodeErr}
          currency={currency}
          setCurrency={setCurrency}
          setCurrencyName={setCurrencyName}
          currencyName={currencyName}
          currencyErr={currencyErr}
          descriptionErr={descriptionErr}
          description={description}
          setDescription={setDescription}
          password={password}
          setpassword={setpassword}
          passwordErr={passwordErr}
          confirmPasswordErr={confirmPasswordErr}
          confirmPassword={confirmPassword}
          setConfirmPass={setConfirmPass}
          logo={logo}
          logoErr={logoErr}
          banner={banner}
          bannerErr={bannerErr}
          setBanner={setBanner}
          setLogo={setLogo}
          firstname={firstname}
          setfirstname={setfirstname}
          email={email}
          setEmail={setEmail}
          mobile={mobile}
          setmobile={setmobile}
          lineBusinessErr={lineBusinessErr}
          lineBusiness={lineBusiness}
          setLineBusiness={setLineBusiness}
          keydownHandler={keydownHandler}
          setListStates={setListStates}
          listStates={listStates}
          listCities={listCities}
          setListCities={setListCities}
          vendorName={vendorName}
          primaryEmail={primaryEmail}
          primaryMobile={primaryMobile}
          tags={tags}
          tagsList={tagsList}
          setTagsList={setTagsList}
          setTags={setTags}
          tagsErr={tagsErr}
          setTagsErr={setTagsErr}
          id={id}
          setid={setid}
        />
      )}
      <div className="w-100 ac-jb flex-md-row flex-column d-flex">
        <button
          onClick={() => {
            if (secondDet === true) {
              setSecondDet(false);
            } else if (finalView === true) {
              setFinalView(false);
              setSecondDet(true);
            } else {
              navigate("/vendor-home");
            }
          }}
          className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
        >
          Back
        </button>
        {!finalView && (
          <button
            onClick={() => {
              if (secondDet === false) {
                // setSecondDet(true);
                onNest1();
              } else if (secondDet === true) {
                // setSecondDet(false);
                // setFinalView(true);
                onNext();
              }
            }}
            className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Next
          </button>
        )}
        {finalView && (
          <button
            // onClick={() => setPopup(true)}
            onClick={() => submitdata()}
            className={`cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${
              btn && "opacity-50"
            }`}
            disabled={btn ? true : false}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
};

export default AddVendor;
