import React, { useEffect, useState } from "react";
import {
  product,
  profilePic,
  toggleOff,
  toggleOn,
  uploadIcon,
} from "../../assets/img";
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  CheckCircleOutline,
  CheckCircleOutlineOutlined,
  Circle,
  CircleOutlined,
  DeleteForever,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Col } from "react-bootstrap";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

const OverviewTab = ({ viewData, vendorProductCode }) => {
  const navigate = useNavigate();
  const [smallimage, setSmallImage] = useState([]);
  const [smallMockimage, setSmallMockImage] = useState([]);
  const [varientsList, setVarientList] = useState([]);
  const [parentCategoryList, setParentCategoryList] = useState({});
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [productSuplierCode, setProductSuplierCode] = useState("");
  const [list, setList] = useState({});
  const [decorativeName, setDecorativeName] = useState([]);
  const [tagsName, setTagsName] = useState([]);
  const [hsnName, setHsnName] = useState("");
  const [dynamicAttr, setDynamicAttr] = useState([]);
  const [productInformation, setProductInformation] = useState([]);
  const [attributesName, setAttributesName] = useState([]);
  const [colorsName, setColorsName] = useState([]);
  const [varients, setVarients] = useState(null);
  const [sizes, setSizes] = useState([{ value: "" }]);
  const [materials, setMeterials] = useState([{ value: "" }]);
  const [styles, setStyles] = useState([{ value: "" }]);
  const [bigImage, setBigImage] = useState({ image: "", ind: "" });
  const [mockImage, setMockImage] = useState({ image: "", ind: "" });
  const [productImg, setProductImg] = useState("");
  const [morkupImg, setMorkupImg] = useState("");
  const [colorsData, setColorsData] = useState([]);

  const getData = (data) => {
    let childs = [];
    let childData = [];
    let subCategoryListArr = [];
    let categorys = [];
    let tagsArr = [];
    let decorationArr = [];
    let productAttributesName = [];
    let productAttributesID = [];
    let cat = {};
    let colName = [];
    let productImagesArr = [];
    let morkupImagesArr = [];
    let colorsDataArr = [];

    // CATEGORY
    data?.category?.parentCategory?.map((item, ind) => {
      if (item?.code) {
        setProductSuplierCode(item?.code);
      }

      if (item?.icon) {
        cat.status = item?.status;
        cat.icon = item?.icon;
        cat.id = item?.id;
        cat.name = item?.name;
        setParentCategoryList(item);
      } else {
        categorys.push(item);
      }
    });

    categorys.push({
      icon: data?.category?.icon,
      id: data?.category?.id,
      name: data?.category?.name,
      status: data?.category?.status,
    });

    categorys?.map((item, ind) => {
      let obj = { ...item };
      if (ind == 0) {
        obj.category = cat;
      } else {
        obj.category = categorys[ind - 1];
      }
      subCategoryListArr.push(obj);
    });

    setSubCategoryList(subCategoryListArr);

    // TAGS
    data?.tags?.map((item, ind) => {
      tagsArr.push(item?.name);
    });

    setTagsName(tagsArr);

    // DECORATOR
    data?.productDecorations?.map((item, ind) => {
      decorationArr.push(item?.name);
    });
    setDecorativeName(decorationArr);

    // PRODUCT INFORMATION
    data?.productAttributes?.map((item, ind) => {
      productAttributesName.push(item?.attribute_name);
      productAttributesID.push({
        id: item?.attribute_id,
        title: item?.attribute_name,
        value: item?.value,
      });
    });

    setDynamicAttr(productAttributesName);
    setProductInformation(productAttributesID);

    // VARIENTS
    data?.product_parent_skus?.map((item, ind) => {
      item?.product_child_skus?.map((chi, inx) => {
        childData.push({
          color_id: chi?.color_id,
          sku_code: chi?.variant_sku,
          sku_name: chi?.variant_name,
          status: chi?.status,
          id: chi?.id,
          variant_sku: item?.variant_sku,
        });
      });

      childs.push({
        [item?.variant_sku]: item?.variant_sku,
        parent_name: item?.variant_sku,
        childs: childData.filter((val) => val.variant_sku == item?.variant_sku),
      });
    });
    setVarientList(childs);

    // COLOR IMAGES
    data?.colorImages?.map((item, ind) => {
      let finder = data?.colors?.find((col) => col?.id == item?.color_id);
      colorsDataArr.push({
        color_id: finder?.id,
        id: item?.id,
        code: finder?.code,
        hex_code: finder?.hex_code,
        name: finder?.name,
        image: item?.image_url,
        status: finder?.status,
        description: item?.description,
        image_url: item?.image_url,
      });
    });

    // console.log("colorsDataArr", colorsDataArr);

    setColorsData(colorsDataArr);

    // FOR COLOR
    data?.colors?.map((item, ind) => {
      colName.push(item?.name);
    });

    setColorsName(colName);

    // FOR SIZE
    if (data?.sizes?.length > 0) {
      if (data?.sizes?.[0]?.value) {
        setSizes(data?.sizes);
      } else {
        setSizes([]);
      }
    }

    // FOR MATERIALS
    if (data?.materials?.length > 0) {
      if (data?.materials?.[0]?.value) {
        setMeterials(data?.materials);
      } else {
        setMeterials([]);
      }
    }

    // FOR STYLES
    if (data?.styles?.length > 0) {
      if (data?.styles?.[0]?.value) {
        setStyles(data?.styles);
      } else {
        setStyles([]);
      }
    }

    // FOR ATTRIBUTE
    let temp = [];
    if (data?.colors?.length > 0) {
      temp.push("Color");
    }

    if (data?.sizes?.length > 0) {
      temp.push("Size");
    }

    if (data?.materials?.length > 0) {
      temp.push("Materials");
    }

    if (data?.styles?.length > 0) {
      temp.push("Style");
    }
    setAttributesName(temp);

    data?.productImage?.map((item, ind) => {
      productImagesArr.push({
        ...item,
        image: item?.image_url,
      });
    });

    data?.morkupImage?.map((item, ind) => {
      morkupImagesArr.push({
        ...item,
        image: item?.image_url,
      });
    });

    setSmallImage(productImagesArr);
    setSmallMockImage(morkupImagesArr);

    // FOR IMAGE SHOW BIG
    if (Array.isArray(data?.productImage)) {
      let finder = data?.productImage.find((i) => i?.is_default == 1);
      let findInx = data?.productImage.findIndex((i) => i?.id == finder?.id);

      if (finder?.id) {
        setBigImage({
          image: finder?.image_url,
          ind: findInx,
        });
      } else {
        setBigImage({
          image: data?.productImage[data?.productImage?.length - 1]?.image_url,
          ind: data?.productImage?.length - 1,
        });
      }
    }

    // setMockImage({
    //   image: data?.morkupImage[data?.morkupImage?.length - 1]?.image_url,
    //   ind: data?.morkupImage.length - 1,
    // });

    setHsnName(data?.hsn?.code);
    setList(viewData);
  };

  const imageSelector = (item, ind, type) => {
    if (type == "mock") {
      setMockImage({ image: item, ind: ind });
    } else if (type == "product") {
      setBigImage({ image: item, ind: ind });
    }
  };

  useEffect(() => {
    if (viewData) {
      getData(viewData);
    }
  }, [viewData]);

  // console.log("colorsData", colorsData);

  return (
    <div className="d-flex as-jb w-100 mt-5 res-flex gap-5">
      <div className="d-flex flex-wrap as-jb flex-m-r w-100">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Category
          </p>
          <input
            disabled={true}
            value={parentCategoryList?.name}
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        {subCategoryList?.map((item, ind) => {
          return (
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                {item?.category?.name}
              </p>
              <input
                value={item?.name}
                disabled={true}
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
            </div>
          );
        })}
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Sub Category Code
          </p>
          <div className="position-relative">
            <input
              placeholder="Required Field"
              className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value={productSuplierCode}
              onChange={(e) => setProductSuplierCode(e.target.value)}
              type="text"
              disabled
            />
          </div>
        </div>

        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Product Name
          </p>
          <div className="position-relative">
            <input
              placeholder="Required Field"
              className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value={list?.name}
              type="text"
              disabled
            />
          </div>
        </div>

        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Vendor Product Code
          </p>
          <input
            disabled
            value={vendorProductCode}
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Decoration Method
          </p>
          <textarea
            value={decorativeName}
            disabled={true}
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Country of Origin
          </p>
          <input
            value={list?.country_origin}
            disabled={true}
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Product Packaging Size
          </p>
          <input
            value={list?.package_size}
            disabled={true}
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            HSN Code
          </p>
          <input
            value={hsnName}
            disabled={true}
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
            Product Description
          </p>
          <textarea
            value={list?.description}
            disabled
            placeholder="Autogenerate"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>

        <div className="mt-5 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
            Other Product Information
          </p>
          <fieldset disabled>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={dynamicAttr}
                readOnly
              />
              <button className="drop_down cust-btn">
                <KeyboardArrowDownIcon />
              </button>
            </div>
          </fieldset>
        </div>
        {productInformation?.map((item, ind) => {
          return (
            <div
              className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100"
              key={ind}
            >
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                {item?.title}
              </p>
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={item?.value}
                disabled
              />
            </div>
          );
        })}

        {/* <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Dimension
          </p>
          <input
            // value={"Dummy Cont"}
            disabled={true}
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div> */}

        {attributesName?.includes("Color") && (
          <div className=" w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f4 fs-xs-12 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-2 ">
              Color
            </p>
            <fieldset disabled>
              <div className="position-relative">
                <input
                  placeholder="Color"
                  type="text"
                  value={
                    colorsName?.length > 0
                      ? colorsName?.toString()
                      : "Select Color"
                  }
                  onClick={() => {
                    setVarients(0);
                  }}
                  className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setVarients(0);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
                {varients == 0 && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setVarients(null)}
                  />
                )}
              </div>
            </fieldset>
          </div>
        )}

        {attributesName?.includes("Size") && (
          <div className=" w-md-100 w-sm-100 w-xs-100">
            <p className=" black f4 fs-xs-12 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-2 ">
              Size
            </p>
            <div className="d-flex flex-wrap w-100 gap-2">
              {sizes?.map((data, i) => {
                return (
                  <div
                    className="d-flex  w-md-100 w-sm-100 w-xs-100"
                    style={{ width: "30% " }}
                  >
                    <div className="position-relative add w-100">
                      <input
                        value={data?.value}
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        disabled
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {attributesName?.includes("Materials") && (
          <div className=" w-md-100 w-sm-100 w-xs-100">
            <p className=" black f4 fs-xs-12 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-2 ">
              Materials
            </p>

            <div className="d-flex flex-wrap w-100 gap-2">
              {materials?.map((data, i) => {
                return (
                  <div
                    className="d-flex  w-md-100 w-sm-100 w-xs-100"
                    style={{ width: "30% " }}
                  >
                    <div className="position-relative add w-100">
                      <input
                        value={data?.value}
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        disabled
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {attributesName?.includes("Style") && (
          <div className=" w-md-100 w-sm-100 w-xs-100">
            <p className=" black f4 fs-xs-12 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-2 ">
              Style
            </p>
            <div className="d-flex flex-wrap w-100 gap-2">
              {styles?.map((data, i) => {
                return (
                  <div
                    className="d-flex  w-md-100 w-sm-100 w-xs-100"
                    style={{ width: "30% " }}
                  >
                    <div className="position-relative add w-100">
                      <input
                        value={data?.value}
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        disabled
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <div className="w-100 d-flex flex-wrap mt-4">
          {varientsList?.map((item, ind) => {
            return (
              <Col md={12} lg={12} xl={12} xxl={12}>
                <table className="w-100 mt-2" key={ind}>
                  <tr className="mt-4">
                    <div className="">
                      <td>
                        <div className="d-flex w-100">
                          <div className="mx-2">
                            <p
                              className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-start primary f3"
                              style={{ width: "200px" }}
                            >
                              {item?.parent_name}
                            </p>
                          </div>
                        </div>
                      </td>
                    </div>
                  </tr>
                  {item?.childs?.map((child, inx) => {
                    return (
                      <tr className="mt-2" key={inx}>
                        <div className="my-3">
                          <td>
                            <div className="d-flex gap-3 w-100">
                              <div className="mx-2">
                                <div
                                  className="num-cls-btn d-flex overflow-scroll p-2"
                                  style={{ width: "auto" }}
                                >
                                  <p className="text-wrap px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-nowrap">
                                    {child?.sku_name}
                                  </p>
                                </div>
                              </div>
                              <div className="mx-2">
                                <div
                                  className="num-cls-btn d-flex overflow-scroll p-2"
                                  style={{ width: "auto" }}
                                >
                                  <p className="text-wrap px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-nowrap">
                                    {child?.sku_name}
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex ac-js">
                                {child?.status == 0 ? (
                                  <div>
                                    <img
                                      src={toggleOff}
                                      className="toggleOnDes"
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <img
                                      src={toggleOn}
                                      className="toggleOnDes"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                        </div>
                      </tr>
                    );
                  })}
                </table>
              </Col>
            );
          })}
        </div>
        <div className="btn-w-cust-cont mt-4">
          <button
            class="btn-w-cust cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded w-100"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </div>
      </div>
      <div className="d-flex gap-2 flex-column ac-jc w-100 mb-5">
        <div className="w-100 d-flex flex-column ac-jc pb-4">
          <div className="d-flex ac-js mb-3 gap-3">
            <h4 className="primary">Product Image</h4>
          </div>
          <div>
            <div className="">
              <div className="position-relative">
                <img
                  className={"img_up_cont"}
                  src={
                    bigImage?.image?.name
                      ? URL.createObjectURL(bigImage?.image)
                      : bigImage?.image
                      ? bigImage?.image
                      : uploadIcon
                  }
                />
              </div>
              <div className="d-flex ac-jc gap-3 w-100">
                <div
                  class=" mt-3 gap-3 d-flex ac-jc pb-4 ps-5"
                  style={{ width: "300px", overflow: "scroll" }}
                >
                  {smallimage?.map((item, ind) => {
                    return (
                      <div className="gap-3 d-flex ac-j w-100">
                        <div
                          class="add_img d-flex ac-jc"
                          onClick={() =>
                            imageSelector(item?.image, ind, "product")
                          }
                        >
                          <img
                            src={
                              item?.image?.name
                                ? URL.createObjectURL(item?.image)
                                : item?.image
                            }
                            class="cp add_img object-fit-fill"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          {colorsData?.length > 0 && (
            <div>
              <p class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
                Color Image
              </p>
              <div
                className="d-flex gap-3 mt-3 ac-jc flex-wrap"
                style={{ width: "350px" }}
              >
                {colorsData?.map((item, ind) => {
                  return (
                    <div className="d-flex flex-column ac-jc">
                      <div className="position-relative for_hover">
                        <img
                          alt="icon"
                          src={
                            item?.image?.name
                              ? URL.createObjectURL(item?.image)
                              : item?.image
                          }
                          className="add_img object-fit-fill"
                        />
                        {/* <div className="d-flex w-100 justify-content-between position-absolute in_hover text-white">
                          <FileDownloadOutlinedIcon />
                          <ClearOutlinedIcon />
                        </div> */}
                      </div>

                      <p className="f3" style={{ color: item?.hex_code }}>
                        {item?.name}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OverviewTab;
