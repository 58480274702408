import ProfileNavBar from "../components/ProfileNavBar";
import { Search } from "@mui/icons-material";
import manImage from "../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import ImageCroper from "../components/Popup/ImageCroper";
import { useEffect, useState } from "react";
import intelImg from "../assets/img/download.jpeg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  useActiveRolesQuery,
  useAdminAddMutation,
  useAdminEditMutation,
  useLazyAdminViewQuery,
  useLazyDepartmentActiveRoleListQuery,
  useMasterActiveListMutation,
} from "../redux/api/api";
import ErrorIcon from "@mui/icons-material/Error";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";

const AddProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;
  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [image, setImage] = useState();
  const [roles, setRoles] = useState([]);
  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  // RTK QUERY
  const { isSuccess: isRoleSuccess, data: roleData } = useActiveRolesQuery();
  const [createAdmin] = useAdminAddMutation();
  const [updateAdmin] = useAdminEditMutation();
  const [viewAdmin] = useLazyAdminViewQuery();
  const [activeMasterListApi] = useMasterActiveListMutation();
  const [activeDepartmentList] = useLazyDepartmentActiveRoleListQuery();

  const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);

  // useEffect(() => {
  //   let temp = roleData?.roles?.filter((i) => i?.type == 1);
  //   setRoles(temp);
  // }, [roleData]);

  useEffect(() => {
    let formdata = new FormData();
    formdata.append("type", 1);
    activeDepartmentList(formdata)
      .unwrap()
      .then((res) => {
        setRoles(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  useEffect(() => {
    if (croperImage) {
      fetch(croperImage)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "File name", { type: "image/png" });
          setImage(file);
        });
    }
  }, [croperImage]);

  // console.log("imageimage", image);

  const getView = () => {
    viewAdmin(location?.state?.data?.id).unwrap()
      .then((res) => {
        if (res?.status == "success" || res?.data?.status == "success") {
          let data = res?.showData || res?.data?.showData;
          setFirst_name(data?.first_name);
          setLast_name(data?.last_name);
          setEmail(data?.email);
          setRole(data?.role?.id);
          setImage(data?.image_url ? data?.image_url : intelImg);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  useEffect(() => {
    if (location?.state?.data?.id) {
      getView();
    }
  }, []);

  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [Email, setEmail] = useState("");
  const [Role, setRole] = useState("");
  const [Password, setPassword] = useState("");
  const [CPassword, setCPassword] = useState("");

  const [first_nameErr, setFirst_nameErr] = useState(false);
  const [last_nameErr, setLast_nameErr] = useState(false);
  const [EmailErr, setEmailErr] = useState(false);
  const [RoleErr, setRoleErr] = useState(false);
  const [PasswordErr, setPasswordErr] = useState(false);
  const [CPasswordErr, setCPasswordErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const submitHandler = () => {
    if (
      first_name?.length == 0 ||
      last_name?.length == 0 ||
      Email?.length == 0 ||
      Role?.length == 0 ||
      (type == "add" && Password?.length == 0) ||
      (type == "add" && Password !== CPassword)
    ) {
      setEmailErr(true);
      setFirst_nameErr(true);
      setLast_nameErr(true);
      setRoleErr(true);
      type == "add" && setPasswordErr(true);
      type == "add" && setCPasswordErr(true);
    } else if (errorMessage?.length == 0) {
      let formdata = new FormData();

      formdata.append("first_name", first_name);
      formdata.append("last_name", last_name);
      formdata.append("email", Email);
      formdata.append("role_id", Role);
      if (image?.name) {
        formdata.append("image", image);
      }
      if (Password && CPassword) {
        formdata.append("password", Password);
        formdata.append("password_confirmation", CPassword);
      }
      setBtn(true);
      dispatch(saveLoader(true));
      if (type == "edit") {
        // formdata.append("_method", "PUT");
        updateAdmin({ id: location?.state?.data?.id, payload: formdata })
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.data?.message || res?.message);
              navigate("/profile");
            }
            setBtn(false);
            dispatch(saveLoader(false));
          })
          .catch((err) => {
            console.log("err");
            dispatch(saveLoader(false));
            setBtn(false);
          });
      } else {
        createAdmin(formdata)
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.data?.message || res?.message);
              navigate("/profile");
            }
            setBtn(false);
            dispatch(saveLoader(false));
          })
          .catch((err) => {
            console.log("err");
            dispatch(saveLoader(false));
            setBtn(false);
          });
      }
    }
  };

  const handlePassword = (event) => {
    let new_pass = event.target.value;
    if (new_pass?.length !== 0) {
      setPassword(new_pass);

      // regular expressions to validate password
      var lowerCase = /[a-z]/g;
      var upperCase = /[A-Z]/g;
      var numbers = /[0-9]/g;
      var special = /[*@!#%&()^~{}]+/g;
      if (!new_pass.match(lowerCase)) {
        setErrorMessage("Password should contains lowercase letters!");
      } else if (!new_pass.match(upperCase)) {
        setErrorMessage("Password should contain uppercase letters!");
      } else if (!new_pass.match(numbers)) {
        setErrorMessage("Password should contains numbers also!");
      } else if (!new_pass.match(special)) {
        setErrorMessage("Password should contains special character also!");
      } else if (new_pass.length < 8) {
        setErrorMessage("Password length should be more than 8.");
      } else {
        // setErrorMessage("Password is strong!");
        setErrorMessage("");
      }
    } else {
      setErrorMessage("");
      setPassword(new_pass);
    }
  };

  const getUrlCheck = () => {
    const url = croperImage;
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "File name", { type: "image/png" });
        console.log("file", file);
      });
  };

  const keydownHandler = (event) => {
    if (event.key == "Enter") {
      submitHandler();
    }
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      <ProductSearchHeader />
      <p
        className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon />{" "}
        {type == "add" ? "Add" : type == "edit" ? "Edit" : ""} Profile{" "}
        {type == "view" ? "Details" : ""}
      </p>
      <div className="w-90 mt-lg-5 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            First Name{type == "add" && type !== "view" && <span>*</span>}
          </p>
          <input
            placeholder="First Name"
            className="editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            onChange={(e) => setFirst_name(e.target.value)}
            value={first_name}
            disabled={type == "view" ? true : false}
          />
          {first_name?.length == 0 && first_nameErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter First Name</p>
            </div>
          )}
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Last Name{type == "add" && type !== "view" && <span>*</span>}
          </p>
          <input
            placeholder="Last Name"
            className=" editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            onChange={(e) => setLast_name(e.target.value)}
            value={last_name}
            disabled={type == "view" ? true : false}
          />
          {first_name?.length == 0 && last_nameErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Last Name``</p>
            </div>
          )}
        </div>
      </div>
      <div className="w-90 mt-lg-5 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Email{type == "add" && type !== "view" && <span>*</span>}
          </p>
          <input
            placeholder="Email"
            className=" editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            onChange={(e) => setEmail(e.target.value)}
            value={Email}
            disabled={type == "add" ? false : true}
          />
          {validEmail.test(Email) == false && EmailErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Email</p>
            </div>
          )}
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Department/ Roles
            {type == "add" && type !== "view" && <span>*</span>}
          </p>
          <select
            placeholder=""
            className=" editBtn mt-2 rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            name=""
            id=""
            onChange={(e) => setRole(e.target.value)}
            value={Role}
            disabled={type == "view" ? true : false}
          >
            <option hidden>Select Department/Roles</option>
            {roles?.map((item, ind) => {
              return (
                <option value={item?.id} key={ind}>
                  {item?.role}
                </option>
              );
            })}
          </select>
          {Role?.length == 0 && RoleErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Select Department/ Role</p>
            </div>
          )}
        </div>
      </div>
      {type !== "view" && (
        <div className="w-90 mt-lg-5 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Password{type == "add" && type !== "view" && <span>*</span>}
            </p>
            <input
              placeholder="Password"
              className=" editBtn mt-2 rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              onChange={(e) => handlePassword(e)}
              value={Password}
              onKeyDown={keydownHandler}
            />
            {Password?.length == 0 && PasswordErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Password</p>
              </div>
            )}
            {errorMessage && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">{errorMessage}</p>
              </div>
            )}
          </div>
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Confirm Password
              {type == "add" && type !== "view" && <span>*</span>}
            </p>
            <input
              placeholder="Confirm Password"
              className=" editBtn mt-2 rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              onChange={(e) => setCPassword(e.target.value)}
              value={CPassword}
              onKeyDown={keydownHandler}
            />
            {CPassword?.length == 0 && CPasswordErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Confirm Password</p>
              </div>
            )}
            {CPassword?.length > 0 && Password !== CPassword && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">
                  Password And Confirm Password are Not Same
                </p>
              </div>
            )}
          </div>
        </div>
      )}
      {type == "view" ? (
        <div className="w-90 mt-5 d-flex flex-md-row flex-column">
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Profile Image
            </p>
            <img src={image} className="proImg mt-lg-2" />
          </div>
        </div>
      ) : (
        <div className="w-90 mt-lg-5 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
          <div
            className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100"
            onClick={() => toggleImagePopup("ban")}
          >
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Profile Image
            </p>

            <input
              style={{ cursor: "pointer" }}
              value={
                image
                  ? "Profile Image Uploaded Successfully"
                  : "Browse or Drag a file"
              }
              className="editBtn mt-2 rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>

          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            {" "}
            {image && (
              <div
                style={{
                  width: "150px",
                  border: "1px solid transparent",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
                className={"mt-2"}
              >
                <img
                  src={image?.name ? URL.createObjectURL(image) : image}
                  className="w-100"
                />
              </div>
            )}
          </div>
        </div>
      )}

      <div className="w-90 ac-jb flex-md-row flex-column d-flex">
        <button
          onClick={() => navigate("/profile")}
          className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
        >
          Back
        </button>
        {type !== "view" && (
          <button
            className={`cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${
              btn && "opacity-50"
            }`}
            onClick={submitHandler}
            disabled={btn ? true : false}
          >
            {type == "edit" ? "Update" : "Create"} Admin
          </button>
        )}
      </div>
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
    </div>
  );
};

export default AddProfile;
