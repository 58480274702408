import React, { useEffect, useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import { Search } from "@mui/icons-material";
import ProjectDetailPopup from "../Popup/ProjectDetailPopup";
import AddTaskPopup from "../Popup/AddTaskPopup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { salesForce } from "../../redux/api/DummyJson";
import { Link, useNavigate } from "react-router-dom";
import ProjectDetailPopup2 from "../Popup/ProjectDetailPopup2";
import ProductSearchHeader from "../ProductComp/ProductSearchHeader";
import { searchIcon, viewIcon } from "../../assets/img";
import moment from "moment/moment";

const TaskAssignby = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("sales");
  const [showDetailPopup, setShowDetailPopup] = useState(null);
  const [showAddTask, setShowAddTask] = useState(false);
  const [list, setList] = useState([]);

  const onTabClick = (tab) => {
    setActiveTab(tab);
  };

  const closeDetailPopup = () => {
    setShowDetailPopup(null);
  };

  const onClickAddTask = () => {
    setShowAddTask(!showAddTask);
  };

  useEffect(() => {
    setList([
      {
        id: 1,
        start_date: moment(new Date()).format("YYYY-MM-DD"),
        Task_Deadline: moment(new Date()).format("YYYY-MM-DD"),
        Project_Associated: "PRJ001",
        Assigned_By: "Roger",
        User: "Rahul",
        Status: "Yet to start",
      },
      {
        id: 1,
        start_date: moment(new Date()).format("YYYY-MM-DD"),
        Task_Deadline: moment(new Date()).format("YYYY-MM-DD"),
        Project_Associated: "PRJ001",
        Assigned_By: "Roger",
        User: "Rahul",
        Status: "Completed",
      },
      {
        id: 1,
        start_date: moment(new Date()).format("YYYY-MM-DD"),
        Task_Deadline: moment(new Date()).format("YYYY-MM-DD"),
        Project_Associated: "PRJ001",
        Assigned_By: "Roger",
        User: "Rahul",
        Status: "In Process",
      },
      {
        id: 1,
        start_date: moment(new Date()).format("YYYY-MM-DD"),
        Task_Deadline: moment(new Date()).format("YYYY-MM-DD"),
        Project_Associated: "PRJ001",
        Assigned_By: "Roger",
        User: "Rahul",
        Status: "Completed",
      },
    ]);
  }, []);

  return (
    <div className="mt-4 ">
      <div className="d-flex ac-jb flex-column flex-md-row">
        <div className=" d-flex ac-jc place-holde justify-content-md-start gap-3 flex-wrap w-100 w-md-90 mb-md-0 mb-4">
          <button className="w-15 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
            <select className="bg-transparent border-0 py-3 primary1">
              <option disabled selected value="">
                Client Rep
              </option>
              <option value="All">All</option>
              <option value="In Active Reps">In Active Reps</option>
            </select>
          </button>
          <button className="w-15 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
            <select className="bg-transparent border-0 py-3 primary1">
              <option disabled selected value="">
                Order Rep
              </option>
              <option value="All">All</option>
              <option value="In Active Reps">In Active Reps</option>
            </select>
          </button>
          <button className="w-15 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
            <select className="bg-transparent border-0 py-3 primary1">
              <option disabled selected value="">
                Tasks by Rep
              </option>
              <option value="All">All</option>
              <option value="In Active Reps">In Active Reps</option>
            </select>
          </button>
          <button className="w-15 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
            <select className="bg-transparent border-0 py-3 primary1">
              <option disabled selected value="">
                Stage
              </option>
              <option value="All">All</option>
              <option value="Open Projects">Open Projects</option>
              <option value="Opportunity">Opportunity</option>
              <option value="Presentation">Presentation</option>
              <option value="Estimate">Estimate</option>
              <option value="Sales Order">Sales Order</option>
              <option value="Invoice">Invoice</option>
            </select>
          </button>
        </div>
        <div className="d-flex ac-jc gap-3">
          <div className="d-flex mt-sm-3 border-search">
            <img src={searchIcon} alt="img" className="searchiConImg mt-1" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
              // onChange={(e) => getList(e.target.value, pageRow)}
              // value={searchValue}
            />
          </div>
          <button className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded">
            <CachedIcon />
          </button>
        </div>
      </div>
      <div className="w-95 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont me-3">
          Total Count : {"02"}
        </p>
      </div>

      <div className="mt-5" style={{ width: "100%", overflow: "auto" }}>
        <table className="table-design-new" style={{ width: "100%" }}>
          <thead>
            <tr className="border-bottom w-100 text-nowrap text-center">
              <th>
                <p className="f2 fs-14 text-center">S.No</p>
              </th>
              <th>
                <p className="f2 fs-14 text-center">
                  Task Date
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                <p className="f2 fs-14 text-center">
                  Task Deadline
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>

              <th>
                <p className="f2 fs-14 text-center">
                  Project Associated
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                {" "}
                <p className="f2 fs-14 text-center">
                  Assigned By
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                {" "}
                <p className="f2 fs-14 text-center">
                  User
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                {" "}
                <p className="f2 fs-14 text-center">
                  Status
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                </p>
              </th>
              <th>
                {" "}
                <p className="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-js">
                  Options
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.map((item, index) => {
              return (
                <tr className="text-center" key={index}>
                  <td>
                    <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {index + 1}
                    </p>
                  </td>
                  <td>
                    <p
                      className="f2 fs-14 text-center primary1"
                      // onClick={() => setShowDetailPopup(item)}
                    >
                      {item?.start_date}
                    </p>
                  </td>
                  <td>
                    <p
                      className="f2 fs-14 text-center primary1"
                      // onClick={() => setShowDetailPopup(item)}
                    >
                      {item?.Task_Deadline}
                    </p>
                  </td>
                  <td>
                    <div className="d-felx fs-14">
                      <Link
                        className="f3 primary1"
                        to={"/overview"}
                        target="_blank"
                      >
                        {item?.Project_Associated}
                      </Link>
                    </div>
                  </td>
                  <td>
                    <p className="f2">{item?.Assigned_By}</p>
                  </td>

                  <td>
                    <p className=" f2 ">{item?.User}</p>
                  </td>
                  <td>
                    <p
                      className={`${
                        item?.Status == "Yet to start"
                          ? "text-primary"
                          : item?.Status == "In Process"
                          ? "text-warning"
                          : item?.Status == "Completed"
                          ? "text-success"
                          : ""
                      } f3 text-center `}
                      // onClick={() => setShowDetailPopup(item)}
                    >
                      {item?.Status}
                    </p>
                  </td>
                  <td>
                    {" "}
                    <div
                      onClick={() =>
                        navigate("/projects-task-view", {
                          state: { type: "view", data: item },
                        })
                      }
                      className="viewBoxDes p-2 mb-2 bg-white rounded-2"
                    >
                      <img src={viewIcon} className="viewDes" />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {showDetailPopup && (
        <ProjectDetailPopup2
          item={showDetailPopup}
          toggleShowPopup={closeDetailPopup}
          onClickAddTask={onClickAddTask}
        />
      )}
      {showAddTask && <AddTaskPopup toggleShowPopup={onClickAddTask} />}
      <div className="d-flex justify-content-between mt-3">
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Previous
        </button>
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Next
        </button>
      </div>
    </div>
  );
};

export default TaskAssignby;
