import React, { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";

const ProjectDetailPopup2 = ({ item, toggleShowPopup, onClickAddTask }) => {
  const [completeTask, setCompleteTask] = useState(false);

  const toggleComplete = () => {
    setCompleteTask(!completeTask);
  };

  return (
    <div className="add-category-popup2">
      <div className="project-detail-popup position-relative">
        <button onClick={toggleShowPopup} className="cust-btn posi_btn m-4">
          <HighlightOffIcon className="w-100 d-flex ac-jb f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-30 fs-xxl-30 primary" />
        </button>
        <div className="d-flex ac-jb flex-column">
          <p className="w-100 d-flex ac-jb f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary m-4">
            {item?.project?.id}
            <span>
              <Link className="" to={"/overview"} target="_blank">
                <button className="mx-3 cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
                  View Project{" "}
                </button>
              </Link>
            </span>
          </p>
          <div className="d-flex w-100 as-jb flex-column flex-md-row">
            <div
              style={{ height: "350px" }}
              className="w-100 overflow-scroll px-2">
              <div className="d-flex ac-jc my-2">
                <p className="w-40 f3">Stage</p>
                <input
                  className="primary editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  placeholder="stage "
                />
              </div>
              <div className="d-flex ac-jc my-2">
                <p className="w-40 f3">Status</p>
                <select
                  className="primary editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  placeholder="stage ">
                  <option> Open </option>
                  <option> Closed </option>
                  <option> Converted </option>
                </select>
              </div>
              <div className="d-flex ac-jc my-2">
                <p className="w-40 f3">In-Hands Date</p>
                <input
                  type="date"
                  className="primary editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                />
              </div>
              <div className="d-flex ac-jc my-2">
                <p className="w-40 f3">Order Rep</p>
                <select
                  className="primary editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  placeholder="stage ">
                  <option> Roger Daniel </option>
                </select>
              </div>
              <div className="d-flex ac-jc my-2">
                <p className="w-40 f3">Client Rep</p>
                <select
                  disabled
                  className="primary editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  placeholder="stage ">
                  <option> Roger Daniel </option>
                </select>
              </div>
              <div className="d-flex ac-jc my-2">
                <p className="w-40 f3">Budget</p>
                <input
                  className="primary editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  placeholder="250000 "
                />
              </div>
              <div className="d-flex ac-jc my-2">
                <p className="w-40 f3"> Sales Value</p>
                <input
                  disabled
                  className="primary editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  placeholder="0 "
                />
              </div>
            </div>
            <div className="w-100 mt-3">
              {/* <div
                onClick={onClickAddTask}
                className="bg-lt-blue f3 w-100 py-1 px-3 fs-18 text-end"> */}
                {/* <button className="border-0 bg-transparent fs-xs-8 fs-sm-8 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                  + Add Task
                </button> */}
              {/* </div> */}
              {/* <p className="mt-3 f3 fs-18">
                {completeTask ? "Completed" : "Tasks"}
              </p>
              <div className="my-3 py-3 bg-lt-blue f3 w-100 py-1 px-3 fs-18 d-flex ac-jb">
                <span className="d-flex">
                  <input type="checkbox" onClick={toggleComplete} />
                  <span>
                    <p
                      className={`mx-2 f1 gray ${
                        completeTask ? "text-decoration-line-through" : ""
                      } `}>
                      Sample Task
                    </p>
                    <p className="mx-2 ">For Roger Daniel on 2023-10-18</p>
                  </span>
                </span>
                <EditIcon className="fs-18 gray" onClick={onClickAddTask} />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailPopup2;
