import React, { useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import { Search } from "@mui/icons-material";
import ProjectDetailPopup from "../Popup/ProjectDetailPopup";
import AddTaskPopup from "../Popup/AddTaskPopup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { salesForce } from "../../redux/api/DummyJson";
import { Link, useNavigate } from "react-router-dom";
import ProjectDetailPopup2 from "../Popup/ProjectDetailPopup2";
import ProductSearchHeader from "../ProductComp/ProductSearchHeader";
import { searchIcon } from "../../assets/img";
import TaskAssignby from "./TaskAssignby";
import TaskAssignto from "./TaskAssignto";

const SalesTasks = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [showDetailPopup, setShowDetailPopup] = useState(null);
  const [showAddTask, setShowAddTask] = useState(false);

  const closeDetailPopup = () => {
    setShowDetailPopup(null);
  };

  const onClickAddTask = () => {
    setShowAddTask(!showAddTask);
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      <ProductSearchHeader />
      <div className="d-flex ac-jb flex-wrap rk2">
        <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary ">
          Projects Tasks
        </p>
        <button
          onClick={() => navigate("/projects-task", { state: { type: "add" } })}
          className="cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
        >
          + Add Task
        </button>
      </div>
      <div className="d-flex mt-2 ac-jb flex-wrap me-md-3 rk2">
        <div className="d-flex mt-4 rk">
          <button
            onClick={() => setActiveTab(0)}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab == 0
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab == 0 ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Assigned by Me
            </p>
          </button>
          <button
            onClick={() => setActiveTab(1)}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab == 1
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab == 1 ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Assigned to Me
            </p>
          </button>
        </div>
      </div>
      {activeTab == 0 && <TaskAssignby />}
      {activeTab == 1 && <TaskAssignto />}
    </div>
  );
};

export default SalesTasks;
