import { useEffect, useRef, useState } from "react";
import FolderIcon from "@mui/icons-material/Folder";
import UploadLibraryImage from "./UploadLibraryImage";
import DeleteIcon from "@mui/icons-material/Delete";
import { dummyImages } from "../../redux/api/DummyJson";
import EditIcon from "@mui/icons-material/Edit";
import { useLocation, useNavigate } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import UploadIcon from "@mui/icons-material/Upload";
import GetAppIcon from "@mui/icons-material/GetApp";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import { useVendorLibraryStoreMutation } from "../../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../redux/slice/loaderNoti";

const LibraryDetails = ({ vendorID }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileRef = useRef();

  const [productImg, setProductImg] = useState(false);
  const [vendorImg, setVendorImg] = useState(false);
  const [merchImg, setMerchImg] = useState(false);
  const [folderList, setFolderList] = useState([]);
  const [folderName, setFolderName] = useState("");
  const [editFoler, setEditFolder] = useState(false);

  const [folderIndex, setFolderIndex] = useState("");
  const [selectIndex, setSelectIndex] = useState("");
  const [selectedFolder, setSelectedFolder] = useState("");

  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState(false);
  const [types, setTypes] = useState("");
  const [typesErr, setTypesErr] = useState(false);
  const [imageTitle, setImageTitle] = useState("");
  const [imageTitleErr, setImageTitleErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const location = useLocation();
  // console.log(location, "location");
  const vendors = location?.state?.data?.id;

  const handleFolders = () => {
    setFolderList([...folderList, folderName]);
    setFolderName("");
    // setEditFolder(false);
    setMerchImg(false);
  };

  useEffect(() => {
    setProductImg(true);
  }, []);

  const handleUpdate = () => {
    const array = [...folderList];
    const ind = array.indexOf(folderIndex);
    if (ind !== -1) {
      array[ind] = folderName;
    }
    // array.splice(folderIndex, 1, folderName);
    setFolderList([...array]);
    // setFolderList([...folderList, folderName]);
    setEditFolder(!editFoler);
    setMerchImg(false);
    setFolderName("");
  };

  // RTK QUERY
  const [vendorLibraryStoreApi] = useVendorLibraryStoreMutation();

  //VENDOR LIBRARY STORE
  const createLibrary = () => {
    if (image?.length == 0 || imageTitle?.length == 0 || types?.length == 0) {
      setImageErr(true);
      setImageTitleErr(true);
      setTypesErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("vendor_id", vendors);
      formdata.append("file", image);
      formdata.append("file_title", imageTitle);
      // formdata.append("vendor_id", id);
      if (types == 0 || types == 1) {
        formdata.append("is_product_image", types);
        formdata.append("is_admin_image", 2);
      } else {
        formdata.append("is_admin_image", 1);
        formdata.append("is_product_image", 2);
      }

      setBtn(true);
      dispatch(saveLoader(true));
      vendorLibraryStoreApi(formdata)
        .unwrap()
        .then((res) => {
          setBtn(false);
          dispatch(saveLoader(false));
          if (types == 0) {
            navigate("/view-library-list", {
              state: {
                vendorID: vendors,
                type: types,
                data: location?.state?.data,
              },
            });
          } else if (types == 1) {
            navigate("/view-library-list", {
              state: {
                vendorID: vendors,
                type: types,
                data: location?.state?.data,
              },
            });
          } else if (types == 2) {
            navigate("/view-library-list", {
              state: {
                vendorID: vendors,
                type: types,
                data: location?.state?.data,
              },
            });
          }
        })
        .catch((err) => {
          setBtn(false);
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    }
  };

  // console.log(image);
  return (
    <div className="w-100 mt-5">
      <div className="w-100 d-flex ac-jb">
        <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Library
        </p>
        <div>
          <button className="cust-btn addbtn2 mt-sm-3 mx-3 mt-2 gray f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
            <UploadIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            Import
          </button>
          <button className="cust-btn addbtn2 mt-sm-3 mx-3 mt-2 gray f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
            <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            Export
          </button>
          <button className="cust-btn addbtn2 mt-sm-3 mx-3 mt-2 gray f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
            <ListAltRoundedIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            Sample Download
          </button>
        </div>
      </div>
      <p className="w-100 f2 mt-4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 primary1">
        Click on an image to download it, and on a file name to edit it. You can
        also create folders, and drag artwork into them.
      </p>
      <div className="w-100 d-xxl-flex d-xl-flex d-lg-flex d-column">
        <div className="mt-5 w-100">
          <div className="w-100  d-flex">
            <div className="w-60 d-flex ac-jb">
              <div
                onClick={() => {
                  navigate("/view-library-list", {
                    state: {
                      vendorID: vendors,
                      type: 1,
                      data: location?.state?.data,
                    },
                  });
                }}
                className="pointerView d-flex"
              >
                <FolderIcon className="ms-1 primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25" />
                <p className="w-80 ms-3 f2 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 black">
                  Product Library
                </p>
              </div>
              <FileUploadIcon
                onClick={() => {
                  setProductImg(true);
                  setVendorImg(false);
                  setMerchImg(false);
                  setSelectedFolder("");
                }}
                className="pointerView ms-1 primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25"
              />
            </div>
          </div>
          <div className="w-100 d-flex mt-3">
            <div className="w-60 d-flex pointerView ac-jb ">
              <div
                onClick={() => {
                  navigate("/view-library-list", {
                    state: {
                      vendorID: vendors,
                      type: 0,
                      data: location?.state?.data,
                    },
                  });
                }}
                className="d-flex "
              >
                <FolderIcon className="ms-1 primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25" />

                <p className="w-80 ms-3 f2 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 black">
                  Vendor Library
                </p>
              </div>

              {/* <FileUploadIcon
                onClick={() => {
                  setProductImg(false);
                  setVendorImg(true);
                  setMerchImg(false);
                  setSelectedFolder("");
                }}
                className="pointerView ms-1 primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25"
              /> */}
            </div>
          </div>
          {folderList?.map((item, index) => {
            return (
              <div className="d-flex mt-3 ac-jb w-100">
                <div className="d-flex pointerView ac-js w-50">
                  <div
                    onClick={() => {
                      navigate("/view-library-list", {
                        state: { name: item },
                      });
                    }}
                    className=" d-flex"
                  >
                    <FolderIcon className="ms-1 primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25" />
                    {!editFoler && (
                      <p className="w-60 ms-3 f2 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 black">
                        {item}
                      </p>
                    )}
                    {selectIndex !== index && editFoler && (
                      <p className="w-60 ms-3 f2 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 black">
                        {item}
                      </p>
                    )}
                  </div>

                  {selectIndex === index && editFoler && (
                    <input
                      onChange={(e) => {
                        setFolderIndex(item);
                        setFolderName(e.target.value);
                      }}
                      placeholder={item}
                      className="editBtn rounded-3 ms-3 p-1"
                    />
                  )}
                </div>
                <div className="d-flex w-50">
                  <FileUploadIcon
                    onClick={() => {
                      setProductImg(false);
                      setVendorImg(false);
                      setSelectedFolder(item);
                    }}
                    className="pointerView ms-1 primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25"
                  />
                  <EditIcon
                    onClick={() => {
                      setProductImg(false);
                      setVendorImg(false);
                      setEditFolder(true);
                      setSelectIndex(index);
                    }}
                    className="pointerView primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25"
                  />
                  <DeleteIcon className="pointerView ms-1 primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25" />
                </div>
              </div>
            );
          })}
          <div className="w-100 d-flex mt-3">
            <div className="w-60 d-flex pointerView ac-jb ">
              <div
                onClick={() => {
                  navigate("/view-library-list", {
                    state: {
                      vendorID: vendors,
                      type: 2,
                      data: location?.state?.data,
                    },
                  });
                }}
                className="d-flex "
              >
                <FolderIcon className="ms-1 primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25" />

                <p className="w-80 ms-3 f2 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 black">
                  Admin Library
                </p>
              </div>

              {/* <FileUploadIcon
                onClick={() => {
                  setProductImg(false);
                  setVendorImg(true);
                  setMerchImg(false);
                  setSelectedFolder("");
                }}
                className="pointerView ms-1 primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25"
              /> */}
            </div>
          </div>
          {/* <div
            onClick={() => {
              setProductImg(false);
              setVendorImg(false);
              setMerchImg(true);
            }}
            className="pointerView d-flex mt-3 ac-js"
          >
            {!merchImg && (
              <FolderIcon className=" ms-1 primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25" />
            )}
            {!merchImg && (
              <p className="w-80 ms-3 f2 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-16 black">
                +Add Library
              </p>
            )}
            {merchImg && (
              <div className="w-100">
                <FolderIcon className=" ms-1 primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25" />

                <input
                  onChange={(e) => {
                    setFolderName(e.target.value);
                  }}
                  value={folderName}
                  className="editBtn rounded-3 ms-3 p-1"
                />
                <div className="w-60 mt-4 d-flex ac-jc">
                  {/* <button
                    onClick={() => setMerchImg(false)}
                    className="cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
                  >
                    Cancel
                  </button> 
                  {!editFoler && (
                    <button
                      onClick={() => {
                        handleFolders();
                      }}
                      className="cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
                    >
                      Add
                    </button>
                  )}
                  {editFoler && (
                    <button
                      onClick={() => {
                        handleUpdate();
                      }}
                      className="cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
                    >
                      Update
                    </button>
                  )}
                </div>
              </div>
            )}
          </div> */}
          <p className="w-90 mt-3 f2 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-13 primary1">
            To create a new folder, simply click the "Add Folder" option.
          </p>
        </div>
        {productImg && (
          <UploadLibraryImage
            type="product"
            setImage={setImage}
            image={image}
            setTypes={setTypes}
            types={types}
            typesErr={typesErr}
            imageTitle={imageTitle}
            setImageTitle={setImageTitle}
            imageTitleErr={imageTitleErr}
            imageErr={imageErr}
            btn={btn}
            setBtn={setBtn}
            createLibrary={createLibrary}
          />
        )}
        {vendorImg && (
          <UploadLibraryImage type="vendor" setImage={setImage} image={image} />
        )}
        {merchImg && selectedFolder === "" && (
          <UploadLibraryImage
            type=""
            folderName={selectedFolder}
            setImage={setImage}
            image={image}
          />
        )}
        {selectedFolder !== "" && (
          <UploadLibraryImage
            type=""
            folderName={selectedFolder}
            setImage={setImage}
            image={image}
          />
        )}
      </div>
      {/* <div className="mt-2 w-100">
        <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Selected Library List
        </p>
        <div className="mt-3 d-flex wholeDesignDash overflow-scroll">
          <div className="w-25">
            <p className="f2 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 black d-flex as-jc">
              Sl.No
            </p>
          </div>
          <div className="w-25">
            <p className="f2 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 black d-flex as-jc">
              Name
            </p>
          </div>
          <div className="w-25">
            <p className="f2 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 black d-flex as-jc">
              Image
            </p>
          </div>
          <div className="w-25">
            <p className="f2 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 black d-flex as-jc">
              Description
            </p>
          </div>
        </div>
        {dummyImages?.map((item, index) => {
          return (
            <div className="mt-3 d-flex wholeDesignDash">
              <div className="w-25">
                <p className=" f2 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary1 d-flex ac-jc">
                  {index + 1}
                </p>
              </div>
              <div className="w-25">
                <p className=" f2 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary1 d-flex ac-jc">
                  {item?.name}
                </p>
              </div>
              <div className="w-25 d-flex ac-jc">
                <img src={item?.imgUrl} className="noDataImg" />
              </div>
              <div className="w-25">
                <p className=" f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1 d-flex ac-jc">
                  {item?.description}
                </p>
              </div>
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

export default LibraryDetails;
