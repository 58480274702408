import React, { useState, useRef, useEffect } from "react";
import { Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { product } from "../assets/img";
import ImageCroper from "../components/Popup/ImageCroper";
import { useCustomerFileEditMutation } from "../redux/api/api";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const CustomerFiles = ({ list }) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [croperImage, setCropImage] = useState();
  const [croper, setCroper] = useState(false);
  const [secondary, setSecondary] = useState("");
  const [primary, setPrimary] = useState("");
  const [imageClick, setImageClick] = useState(false);
  const [itemId, setItemId] = useState("");
  const [data, setData] = useState([]);
  const proImgRef = useRef();
  const secImagRef = useRef();
  const [close, setClose] = useState(false);

  //RTK
  const [uploadFileApi] = useCustomerFileEditMutation();

  useEffect(() => {
    if (list?.project_request?.length > 0) {
      setData(list?.project_request);
    }
  }, [list]);

  const getFileApi = (id) => {
    const formData = new FormData();
    formData.append("project_requests[0][id]", id);
    if (primary?.name) {
      formData.append("project_requests[0][primary_logo]", primary);
    }
    if (secondary?.name) {
      formData.append("project_requests[0][secondary_logo]", secondary);
    }
    uploadFileApi(formData)
      .unwrap()
      .then()
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangePrimary = (e, ind, type) => {
    proImgRef?.current?.click();
    console.log(ind, "e:", ind, "tempprim");
    const temp = [...data];
    temp[ind] = {
      ...temp[ind],
      [type]: e,
    };
    console.log(temp, "temp");
    setData(temp);
  };
  //  const onClickHandeler = (ind)=>{
  //   proImgRef?.current?.click();
  //  }

  const toggleImagePopup = () => {
    setCroper(!croper);
  };
  const secondaryClick = () => {
    setImageClick(!imageClick);
  };
  // console.log(secondary, proImgRef, "proImgRef");
  // console.log("list?.project_request", list?.project_request);
  const handleClose = () => {
    setClose(!close);
  };

  return (
    <>
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      {/* <div className="text-center files_tab mt-3 f2 ">
        <label className="mt-2 f2" onClick={() => toggleImagePopup()}>
          Upload Files
        </label>
      </div> */}
      {/* popup design */}

      {/* <div className="popImg" >
        <div className="popImg_1" onClick={handleClose}>
          <button className="btn_cncl" style={{ background: "none", border: "none" }} >
            <HighlightOffIcon />
          </button>

          <img src={product} />
        </div>
      </div> */}

      <div className=" mt-4 mx-3 details_tab">
        {data?.map((item, ind) => {
          return (
            <>
              <div className="d-flex jb-ac mt-1">
                <p className="pe-3 ">{item?.project_request_id}</p>
                <p>{item?.when_needed}</p>
              </div>
              <div className="">
                <div className={"ms-3 w-80 m-auto"} >
                  <div className=" flex-wrap d-flex gap-3">
                    {/* <img
                      src={product}
                      className="file_imgs pointer"
                      onClick={handleClose}
                    /> */}
                    {item?.images?.length > 0 &&
                      item?.images?.map((img_url, img_ind) => {
                        return (
                          <div className="files_img">
                            <img
                              src={img_url?.image_url}
                              className="file_imgs pointer"
                              onClick={handleClose}
                              key={img_ind}
                            />
                            <div className="files_btn">
                              <button className="">
                                <FileDownloadIcon />
                              </button>
                            </div>
                            <p className="text-center mt-3">T-Shirt</p>
                          </div>
                        );
                      })}
                    {close && (
                      <div className="popImg">
                        <div className="popImg_1">
                          <button
                            className="btn_cncl"
                            style={{ background: "none", border: "none" }}
                            onClick={handleClose}
                          >
                            <HighlightOffIcon />
                          </button>

                          <img src={product} className="file_imgs_pop pointer" />
                        </div>
                      </div>
                    )}

                    {/* <img
                      src={
                        item?.primary_logo?.name
                          ? URL.createObjectURL(item?.primary_logo)
                          : item?.primary_logo
                            ? item?.primary_logo
                            : product
                      }
                      className="file_imgs pointer"
                      onClick={() => {
                        handleChangePrimary("", ind, "")
                      }}
                    /> */}

                  </div>
                  
                </div>
              </div>
            </>
          );
        })}
        {/* <input
                  type="file"
                  accept=".pdf,.doc,.jpeg,.docx,.png,.jpg"
                  ref={secImagRef}
                  className="d-none"
                  onChange={(e) => setSecondary(e.target.files[0])}
                /> */}
        {croperImage && (
          <div className={"ms-3"}>
            <div className="files_img d-flex ac-jc">
              <img src={croperImage} className="w-100" />
              {/* <div className="files_btn">
                <button className="mx-2">Edit</button>
              </div> */}
            </div>
            <p className="text-center mt-3">T-Shirt</p>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomerFiles;
