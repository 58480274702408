import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ImageUplodeComp from "../AddNewProductComps/ImageUplodeComp";
import FormComp from "../AddNewProductComps/FormComp";
import { Col } from "react-bootstrap";
import { currency_list, DefaultTax, sub_menu } from "../../redux/api/DummyJson";
import PorejectPoppup from "../PODetails/PorejectPoppup";
import SaleRejPopup from "../POEstimateRequestComp/SaleRejPopup";
import moment from "moment/moment";
import { editIcon } from "../../assets/img";
import Modal from "react-bootstrap/Modal";

const SalesAddress = ({
  setAddContComp,
  poppupAddressToggle,
  collaboratClick,
  onCollaborat,
}) => {
  const [dropDown, setDropDown] = useState(undefined);
  const [tab, setTab] = useState(1);
  const [type_of_shipping, setType_of_shipping] = useState(1);
  const [show, setShow] = useState(false);

  // input states

  const [how_many_boxesi, setHow_many_boxesi] = useState("");
  const [which_boxi, setWhich_boxi] = useState("FLIP 1");
  const [how_longi, setHow_longi] = useState("");
  const [start_datei, setStart_datei] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const [terms, setTerms] = useState("");
  const [currency, setCurrency] = useState("");
  const [defaultTax, setDefaultTax] = useState("");
  const [statust, setStatust] = useState("");
  const [salestatus, setSaleStatus] = useState("");

  const [rejectpoppup, setRejectPoppup] = useState(false);
  const [rejectup, setRejectPop] = useState(false);

  const rejPopFun = () => {
    setRejectPoppup(!rejectpoppup);
  };
  const rejectFun = () => {
    setRejectPop(!rejectup);
  };

  const closePopup = () => {
    setShow(false);
  };

  console.log("how_many_boxesi", how_many_boxesi, which_boxi);
  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="p-3 d-flex justify-content-between">
          <p className="f3 fs-sm-19 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 primary">
            {tab == 2 ? "Billing" : "Shipping"} Address
          </p>
          <div className="mt-3 mb-3 d-flex justify-content-end mx-3">
            <button
              class="cust-btn addbtn  addbtn_save  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 mx-2 rounded"
              onClick={() => setShow(false)}
            >
              Cancel
            </button>
            <button
              class="cust-btn addbtn  addbtn_save  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 mx-2 rounded"
              onClick={() => setShow(false)}
            >
              Submit
            </button>
          </div>
        </div>
        <div className="mt-3 overflow-scroll h_cust_res">
          <div className="w-100 px-4 px-md-5 ">
            <div className="w-100">
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2">
                  First Name
                </p>

                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  // onChange={(e) => setHow_many_boxesi(e.target.value)}
                  // value={how_many_boxesi}
                  placeholder="Required Field"
                />
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2">
                  Last Name
                </p>

                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  // onChange={(e) => setHow_many_boxesi(e.target.value)}
                  // value={how_many_boxesi}
                  placeholder="Required Field"
                />
              </div>

              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2">
                  Email ID
                </p>

                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  // onChange={(e) => setHow_many_boxesi(e.target.value)}
                  // value={how_many_boxesi}
                  placeholder="Required Field"
                />
              </div>

              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2">
                  Address Line 1
                </p>

                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  // onChange={(e) => setHow_longi(e.target.value)}
                  // value={how_longi}
                  placeholder="Required Field"
                />
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                  style={{ marginLeft: "0px" }}
                >
                  Address Line 2
                </p>

                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  // onChange={(e) => setHow_longi(e.target.value)}
                  // value={how_longi}
                  placeholder="Required Field"
                />
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                  style={{ marginLeft: "0px" }}
                >
                  Land Mark
                </p>

                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  // onChange={(e) => setStart_datei(e.target.value)}
                  // value={start_datei}
                  placeholder="Required Field"
                />
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                  style={{ marginLeft: "0px" }}
                >
                  State
                </p>

                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  // onChange={(e) => setStart_datei(e.target.value)}
                  // value={start_datei}
                  placeholder="Required Field"
                />
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                  style={{ marginLeft: "0px" }}
                >
                  City
                </p>

                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  // onChange={(e) => setStart_datei(e.target.value)}
                  // value={start_datei}
                  placeholder="Required Field"
                />
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                  style={{ marginLeft: "0px" }}
                >
                  Pincode
                </p>

                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  // onChange={(e) => setStart_datei(e.target.value)}
                  // value={start_datei}
                  placeholder="Required Field"
                />
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                  style={{ marginLeft: "0px" }}
                >
                  Phone Number
                </p>

                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  // onChange={(e) => setStart_datei(e.target.value)}
                  // value={start_datei}
                  placeholder="Required Field"
                />
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                  style={{ marginLeft: "0px" }}
                >
                  Alternate Phone Number
                </p>

                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  // onChange={(e) => setStart_datei(e.target.value)}
                  // value={start_datei}
                  placeholder="Required Field"
                />
              </div>
              <div className="d-flex as-jb my-2 flex-column flex-md-row">
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                  style={{ marginLeft: "0px" }}
                >
                  GST
                </p>

                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  // onChange={(e) => setStart_datei(e.target.value)}
                  // value={start_datei}
                  placeholder="Optional Field"
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="w-100 mb-3">
        <div className="mt-3 d-flex ac-jb mt-0 mt-md-3 flex-column flex-md-row">
          <div className="d-flex ac-js">
            <button
              onClick={() => setTab(1)}
              className={`${
                tab == 1 ? "border_click_cust" : "cust-btn "
              } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
            >
              Shipping
            </button>
            <button
              onClick={() => setTab(2)}
              className={`${
                tab == 2 ? "border_click_cust" : "cust-btn "
              } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
            >
              Billing
            </button>
          </div>
        </div>
        {tab == 2 ? (
          // <div className="d-flex ">
          //   <div className="d-flex flex-column flex-md-row flex-wrap w-100 gap-2">
          //     <Col sm={12} md={12} lg={5} className="">
          //       <p className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2">
          //         First Name
          //       </p>

          //       <input
          //         className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          //         readOnly
          //         onChange={(e) => setHow_many_boxesi(e.target.value)}
          //         // value={how_many_boxesi}
          //         value={"Roger"}
          //       />
          //     </Col>
          //     <Col sm={12} md={12} lg={5} className="">
          //       <p className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2">
          //         Last Name
          //       </p>

          //       <input
          //         className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          //         readOnly
          //         onChange={(e) => setHow_many_boxesi(e.target.value)}
          //         // value={how_many_boxesi}
          //         value={"Daniel"}
          //       />
          //     </Col>
          //     <Col sm={12} md={12} lg={5} className="">
          //       <p className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2">
          //         Email ID
          //       </p>

          //       <input
          //         className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          //         readOnly
          //         onChange={(e) => setHow_many_boxesi(e.target.value)}
          //         // value={how_many_boxesi}
          //         value={"roger@companystore.io"}
          //       />
          //     </Col>
          //     <Col sm={12} md={12} lg={5} className="">
          //       <p className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2">
          //         Address Line 1
          //       </p>

          //       <input
          //         className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          //         readOnly
          //         onChange={(e) => setHow_longi(e.target.value)}
          //         // value={how_longi}
          //         value={"#39 Krishna Reddy Colony"}
          //       />
          //     </Col>
          //     <Col sm={12} md={12} lg={5} className="">
          //       <p
          //         className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
          //         style={{ marginLeft: "0px" }}
          //       >
          //         Address Line 2
          //       </p>

          //       <input
          //         className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          //         readOnly
          //         onChange={(e) => setHow_longi(e.target.value)}
          //         // value={how_longi}
          //         value={"Domlur Layout"}
          //       />
          //     </Col>
          //     <Col sm={12} md={12} lg={5} className="">
          //       <p
          //         className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
          //         style={{ marginLeft: "0px" }}
          //       >
          //         Land Mark
          //       </p>

          //       <input
          //         className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          //         readOnly
          //         onChange={(e) => setStart_datei(e.target.value)}
          //         // value={start_datei}
          //         value={"Accord Road	"}
          //       />
          //     </Col>
          //     <Col sm={12} md={12} lg={5} className="">
          //       <p
          //         className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
          //         style={{ marginLeft: "0px" }}
          //       >
          //         State
          //       </p>

          //       <input
          //         className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          //         readOnly
          //         onChange={(e) => setStart_datei(e.target.value)}
          //         // value={start_datei}
          //         value={"Karnataka"}
          //       />
          //     </Col>
          //     <Col sm={12} md={12} lg={5} className="">
          //       <p
          //         className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
          //         style={{ marginLeft: "0px" }}
          //       >
          //         City
          //       </p>

          //       <input
          //         className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          //         readOnly
          //         onChange={(e) => setStart_datei(e.target.value)}
          //         // value={start_datei}
          //         value={"Bangalore"}
          //       />
          //     </Col>
          //     <Col sm={12} md={12} lg={5} className="">
          //       <p
          //         className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
          //         style={{ marginLeft: "0px" }}
          //       >
          //         Pincode
          //       </p>

          //       <input
          //         className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          //         readOnly
          //         onChange={(e) => setStart_datei(e.target.value)}
          //         // value={start_datei}
          //         value={"560071"}
          //       />
          //     </Col>
          //     <Col sm={12} md={12} lg={5} className="">
          //       <p
          //         className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
          //         style={{ marginLeft: "0px" }}
          //       >
          //         Phone Number
          //       </p>

          //       <input
          //         className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          //         readOnly
          //         onChange={(e) => setStart_datei(e.target.value)}
          //         // value={start_datei}
          //         value={"9632950798"}
          //       />
          //     </Col>
          //     <Col sm={12} md={12} lg={5} className="">
          //       <p
          //         className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
          //         style={{ marginLeft: "0px" }}
          //       >
          //         Alternate Phone Number
          //       </p>

          //       <input
          //         className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          //         readOnly
          //         onChange={(e) => setStart_datei(e.target.value)}
          //         // value={start_datei}
          //         value={"9632208028"}
          //       />
          //     </Col>
          //     <Col sm={12} md={12} lg={5} className="">
          //       <p
          //         className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
          //         style={{ marginLeft: "0px" }}
          //       >
          //         GST
          //       </p>

          //       <input
          //         className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          //         readOnly
          //         onChange={(e) => setStart_datei(e.target.value)}
          //         // value={start_datei}
          //         value={"29AABCH6959B1ZG"}
          //       />
          //     </Col>
          //   </div>
          // </div>
          <div>
            <div className="d-flex justify-content-end">
              <button
                class="cust-btn addbtn  addbtn_save  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 mx-2 rounded"
                onClick={() => setShow(true)}
              >
                + Add Address
              </button>
            </div>
            <div
              style={{ width: "100%", overflow: "auto" }}
              className="w-100 pt-3 w-100 mt-5"
            >
              <table className="table-design-new">
                <thead>
                  <tr className="text-nowrap text-center">
                    <th>S.No</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email ID</th>
                    <th>Address Line 1</th>
                    <th>Address Line 2</th>
                    <th>Land Mark</th>
                    <th>State</th>
                    <th>City</th>
                    <th>Pincode</th>
                    <th>Phone Number</th>
                    <th>Alternate Phone Number</th>
                    <th>GST</th>
                    <th>Options</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-center">
                    <td>1</td>
                    <td>Roger</td>
                    <td>Daniel</td>
                    <td>roger@companystore.io</td>
                    <td>#39 Krishna Reddy Colony</td>
                    <td>Domlur Layout</td>
                    <td>Accord Road</td>
                    <td>Karnataka</td>
                    <td>Bangalore</td>
                    <td>560071</td>
                    <td>9632950798</td>
                    <td>9632208028</td>
                    <td>29AABCH6959B1ZG</td>
                    <td>
                      {" "}
                      <div
                        className="viewBoxDes ms-2 p-2 mb-2 bg-white rounded-2"
                        role={"button"}
                        onClick={() => setShow(true)}
                      >
                        <img src={editIcon} className="viewDes" />
                      </div>
                    </td>
                  </tr>
                  <tr className="text-center">
                    <td>2</td>
                    <td>Sakthi</td>
                    <td>Vel</td>
                    <td>vel@companystore.io</td>
                    <td>#49 Krishna Reddy Colony</td>
                    <td>Domlur Layout</td>
                    <td>Accord Road</td>
                    <td>Chennai</td>
                    <td>Tamil Nadu</td>
                    <td>600071</td>
                    <td>9632950799</td>
                    <td>9632208029</td>
                    <td>29AABCH6959B1ZO</td>
                    <td>
                      {" "}
                      <div
                        className="viewBoxDes ms-2 p-2 mb-2 bg-white rounded-2"
                        role={"button"}
                        onClick={() => setShow(true)}
                      >
                        <img src={editIcon} className="viewDes" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div>
            <div className="d-flex justify-content-end">
              <button
                class="cust-btn addbtn  addbtn_save  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 mx-2 rounded"
                onClick={() => setShow(true)}
              >
                + Add Address
              </button>
            </div>
            <div
              style={{ width: "100%", overflow: "auto" }}
              className="w-100 pt-3 w-100 mt-5"
            >
              <table className="table-design-new">
                <thead>
                  <tr className="text-nowrap text-center">
                    <th>S.No</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email ID</th>
                    <th>Address Line 1</th>
                    <th>Address Line 2</th>
                    <th>Land Mark</th>
                    <th>State</th>
                    <th>City</th>
                    <th>Pincode</th>
                    <th>Phone Number</th>
                    <th>Alternate Phone Number</th>
                    <th>GST</th>
                    <th>Options</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-center">
                    <td>1</td>
                    <td>Roger</td>
                    <td>Daniel</td>
                    <td>roger@companystore.io</td>
                    <td>#39 Krishna Reddy Colony</td>
                    <td>Domlur Layout</td>
                    <td>Accord Road</td>
                    <td>Karnataka</td>
                    <td>Bangalore</td>
                    <td>560071</td>
                    <td>9632950798</td>
                    <td>9632208028</td>
                    <td>29AABCH6959B1ZG</td>
                    <td>
                      {" "}
                      <div
                        className="viewBoxDes ms-2 p-2 mb-2 bg-white rounded-2"
                        role={"button"}
                        onClick={() => setShow(true)}
                      >
                        <img src={editIcon} className="viewDes" />
                      </div>
                    </td>
                  </tr>
                  <tr className="text-center">
                    <td>2</td>
                    <td>Sakthi</td>
                    <td>Vel</td>
                    <td>vel@companystore.io</td>
                    <td>#49 Krishna Reddy Colony</td>
                    <td>Domlur Layout</td>
                    <td>Accord Road</td>
                    <td>Chennai</td>
                    <td>Tamil Nadu</td>
                    <td>600071</td>
                    <td>9632950799</td>
                    <td>9632208029</td>
                    <td>29AABCH6959B1ZO</td>
                    <td>
                      {" "}
                      <div
                        className="viewBoxDes ms-2 p-2 mb-2 bg-white rounded-2"
                        role={"button"}
                        onClick={() => setShow(true)}
                      >
                        <img src={editIcon} className="viewDes" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SalesAddress;
