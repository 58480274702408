import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  editIcon,
  profilePic,
  toggleOff,
  toggleOn,
  uploadIcon,
  viewIcon,
} from "../assets/img";
import { useLocation } from "react-router-dom";

const AddCategorySub = ({
  selectedCat,
  subList,
  onSubCatSelect,
  selectedSubCat,
  toggleSubCategShow,
  index,
  statusChanger,
  editSubHandler,
  codeShow,
}) => {
  const location = useLocation();
  const pageType = location.state?.type;
  return (
    <div>
      {" "}
      <p className="f2 fs-16 primary">{subList?.category?.name}</p>
      {pageType !== "view" && (
        <button
          onClick={() =>
            toggleSubCategShow(
              "add",
              subList?.category,
              index,
              subList?.category?.id == codeShow ? "show" : "hide"
            )
          }
          className="mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
        >
          + Add{" "}
          {subList?.category?.name?.length > 20
            ? subList?.category?.name?.slice(0, 20) + "..."
            : subList?.category?.name}
        </button>
      )}
      <div
        className="list-wrapper"
        // style={{
        //   width: "350px",
        //   height: "60vh",
        //   overflow: "scroll",
        //   scrollbarWidth: "thin",
        // }}
      >
        {subList?.child_categories?.length > 0 ? (
          subList?.child_categories?.map((item, ind) => {
            return (
              <div
                className={`list ${
                  item.name === selectedSubCat && "bg-soft-gray1"
                } `}
              >
                <div className="d-flex">
                  <p>{item?.name}</p>
                  <p>{item?.code}</p>
                </div>
                <div>
                  <button
                    className="border-0 bg-transparent"
                    onClick={() =>
                      statusChanger(
                        item?.id,
                        "sub_cat",
                        subList?.category,
                        index
                      )
                    }
                    disabled={pageType == "view" ? true : false}
                  >
                    <img
                      src={item?.status == 1 ? toggleOn : toggleOff}
                      className="toggleOnDes"
                      alt="icon"
                    />
                  </button>
                  {pageType !== "view" && (
                    <img
                      onClick={() =>
                        editSubHandler(
                          subList?.category,
                          item,
                          index,
                          subList?.category?.id == codeShow ? "show" : "hide"
                        )
                      }
                      src={editIcon}
                      className="viewDes"
                      alt="icon"
                    />
                  )}
                  <div onClick={() => onSubCatSelect(item, ind, index)}>
                    <ArrowForwardIosIcon className="arrow primary1" />
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="d-flex justify-content-start mt-2 mx-2">
            <p>No Data Found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddCategorySub;
