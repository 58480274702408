import React from "react";
const Detailing = () => {
  return (
    <div className="detailing">
      <div className="w-100 d-flex flex-wrap">
        {" "}
        <div className="w-50">
          <div className="px-1">
            <p
              className="f2 cust-btn border-1 fs-14 fs-md-15 
            fs-lg-16 mt-3"
            >
              Client
            </p>
            <p
              className="f2 cust-btn fs-14 fs-md-15 
            fs-lg-16 w-100 pt-0 mt-0 rounded primary1"
            >
              Google Inda
            </p>
          </div>
        </div>
        <div className="w-50">
          <div className="px-1">
            <p
              className="f2 cust-btn border-1 fs-14 fs-md-15 
            fs-lg-16 mt-3"
            >
              Project
            </p>
            <p
              className="f2 cust-btn fs-14 fs-md-15 
            fs-lg-16 w-100 pt-0 mt-0 rounded primary1"
            >
              Google Inda
            </p>
          </div>
        </div>
        <div className="w-50">
          <div className="px-1">
            <p
              className="f2 cust-btn border-1 fs-14 fs-md-15 
            fs-lg-16 mt-3"
            >
              Prod.Rep
            </p>
            <p
              className="f2 cust-btn fs-14 fs-md-15 
            fs-lg-16 w-100 pt-0 mt-0 rounded primary1"
            >
              Google Map
            </p>
          </div>
        </div>
        <div className="w-50">
          <div className="px-1">
            <p
              className="f2 cust-btn border-1 fs-14 fs-md-15 
            fs-lg-16 mt-3"
            >
              Total
            </p>
            <p
              className="f2 cust-btn fs-14 fs-md-15 
            fs-lg-16 w-100 pt-0 mt-0 rounded primary1"
            >
              ₹980
            </p>
          </div>
        </div>
        <div className="w-50">
          <div className="px-1">
            <p
              className="f2 cust-btn border-1 fs-14 fs-md-15 
            fs-lg-16 mt-3"
            >
              Client Rep
            </p>
            <p
              className="f2 cust-btn fs-14 fs-md-15 
            fs-lg-16 w-100 pt-0 mt-0 rounded primary1"
            >
              Order Rep
            </p>
          </div>
        </div>
        <div className="w-50">
          <div className="px-1">
            <p
              className="f2 cust-btn border-1 fs-14 fs-md-15 
            fs-lg-16 mt-3"
            >
              Order Rep
            </p>
            <p
              className="f2 cust-btn fs-14 fs-md-15 
            fs-lg-16 w-100 pt-0 mt-0 rounded primary1"
            >
              ₹980
            </p>
          </div>
        </div>
      </div>
      <div className="w-100 d-flex flex-wrap">
        <div className="w-50 ">
          <div className="px-1">
            <label
              className="f2 cust-btn border-1 fs-14 fs-md-15 
            fs-lg-16 mt-3"
            >
              stage
            </label>
            <select
              name=""
              className="f2 cust-btn fs-14 fs-md-15 
            fs-lg-16 w-100 py-2 mt-0 px-2 rounded"
              id=""
            >
              <option value="Problem">Problem</option>
              <option value="Follow Up">Follow Up</option>
              <option value="Created">Created</option>
              <option value="Submitted">Submitted</option>
              <option value="Confirmed">Confirmed</option>
              <option value="Shipped">Shipped</option>
            </select>
          </div>
        </div>
        <div className="w-50">
          <div className="px-1">
            <label
              className="f2 cust-btn border-1 fs-14 fs-md-15 
            fs-lg-16 mt-3"
            >
              Status
            </label>

            <select
              name=""
              className="f2 cust-btn fs-14 fs-md-15 
            fs-lg-16 w-100 py-2 mt-0 px-2 rounded"
              id=""
            >
              <option value="Confirmed">Confirmed</option>
              <option value="Problem">Problem</option>
              <option value="Follow Up">Follow Up</option>
            </select>
          </div>
        </div>
        <div className="w-50">
          <div className="px-1">
            <label
              className="f2 cust-btn border-1 fs-14 fs-md-15 
            fs-lg-16 mt-3"
            >
              Next Action
            </label>

            <select
              name=""
              className="f2 cust-btn fs-14 fs-md-15 
            fs-lg-16 w-100 py-2 mt-0 px-2 rounded"
              id=""
            >
              <option value="Confirmed">None Set</option>
              <option value="Problem">Check Stock</option>
              <option value="Follow Up">Call Supplier</option>
              <option value="Follow Up">Call Client</option>
            </select>
          </div>
        </div>
        <div className="w-50">
          <div className="px-1">
            <label
              className="f2 cust-btn border-1 fs-14 fs-md-15 
            fs-lg-16 mt-3"
            >
              Next Action Date
            </label>
            <input
              type="date"
              className="f2 cust-btn fs-14 fs-md-15 
            fs-lg-16 w-100 py-2 mt-0 px-2 rounded"
            />
          </div>
        </div>
        <div className="w-50">
          <div className="px-1">
            <label
              className="f2 cust-btn border-1 fs-14 fs-md-15 
            fs-lg-16 mt-3"
            >
              In Hands Date
            </label>
            <p
              className="f2 cust-btn fs-14 fs-md-15 
            fs-lg-16 w-100 py-2 mt-0 rounded"
            >
              29-09-2023
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Detailing;
