// @ts-nocheck
import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./routes/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-datepicker/dist/react-datepicker.css";
import "../src/assets/sass/buttons.scss";
import "../src/assets/sass/default/style.scss";
import "../src/assets/sass/default/responsive.scss";
import "../src/assets/sass/constatnt/custome.scss";
import "../src/assets/sass/constatnt/width.scss";
import AdminPanel from "./routes/AdminPanel";
import AddProfile from "./routes/AddProfile";
import ViewProfile from "./routes/ViewProfile";
import EditProfile from "./routes/EditProfile";
import Layout from "./components/Layout/Layout";
import RoleProfileList from "./routes/RoleProfileList";
import RoleAddProfile from "./routes/RoleAddProfile";
import RoleViewProfile from "./routes/RoleViewProfile";
import RoleEditProfile from "./routes/RoleEditProfile";
import SignInScreen from "./routes/SignInScreen";
import ForgotPassword from "./routes/ForgotPassword";
import ResetPasswordScreen from "./routes/ResetPasswordScreen";
import VendorDashboard from "./routes/VendorDashboard";
import AddVendor from "./routes/AddVendor";
import ProductFeed from "./routes/ProductFeed";
import ProductLibrary from "./routes/ProductLibrary";
import VendorManageScreen from "./routes/VendorManageScreen";
import ProductScreen from "./routes/ProductScreen";
import AddNewProductScreen from "./routes/AddNewProductScreen";
// import VendorDetailsPage from "./routes/VendorDetailsPage";
import ProductDetailScreen from "./routes/ProductDetailScreen";
import ViewThread from "./components/VendorDetails/ViewThread";
import ViewCustomer from "./routes/ViewCustomer";
import CustomerList from "./routes/CustomerList";
import CatogorySettingScreen from "./routes/CatogorySettingScreen";
import ProductLibraryDetailsScreen from "./routes/ProductLibraryDetailsScreen";
import ViewLibraryList from "./routes/ViewLibraryList";
import OrderList from "./routes/OrderList";
import CustomerRequestViewDetails from "./routes/CustomerRequestViewDetails";
import CustomerViewDetailsScreen from "./routes/CustomerViewDetailsScreen";
import OrderViewDetails from "./routes/OrderViewDetails";
import OrderedViewDetails from "./routes/OrderedViewDetails";
import ECommerceList from "./routes/ECommerceList";
import EcommerceViewDetails from "./components/Ecommerce/EcommerceViewDetails";
import NoncustomerRequestViewDetails from "./routes/NoncustomerRequestViewDetails";
import ViewCollaborateDetails from "./routes/ViewCollaborateDetails";
import VendorSettings from "./routes/VendorSettings";
import RequestDetailView from "./routes/RequestDetailView";
import AddCategoryScreen from "./routes/AddCategoryScreen";
import DecorativeDetailScreen from "./routes/DecorativeDetailScreen";
import ViewCategoryScreen from "./routes/ViewCategoryScreen";
import DecorativeMethod from "./routes/DecorativeMethod";
import TestimonialScreen from "./routes/sitemanagement/TestimonialScreen";
import TestimonialViewDetails from "./routes/sitemanagement/testimonial/TestimonialViewDetails";
import TestimonialEdit from "./routes/sitemanagement/testimonial/TestimonialEdit";
import BannerManagementScreen from "./routes/sitemanagement/BannerManagementScreen";
import BannerViewDetails from "./routes/sitemanagement/BannerManagement/BannerViewDetails";
import BannerEdit from "./routes/sitemanagement/BannerManagement/BannerEdit";
import BlogManagementScreen from "./routes/sitemanagement/BlogManagementScreen";
import BlogEdit from "./routes/sitemanagement/BlogManagement/BlogEdit";
import BlogViewDetails from "./routes/sitemanagement/BlogManagement/BlogViewDetails";
import SpecialOffersScreen from "./routes/sitemanagement/SpecialOffersScreen";
import SpecialOfferViewDetails from "./routes/sitemanagement/SpecialOffers/SpecialOfferViewDetails";
import SpecialOfferEdit from "./routes/sitemanagement/SpecialOffers/SpecialOfferEdit";
import VendorListScreen from "./routes/sitemanagement/VendorListScreen";
import VendorListViewDetails from "./routes/sitemanagement/VendorList/VendorListViewDetails";
import VendorListEdit from "./routes/sitemanagement/VendorList/VendorListEdit";
import HsnListScreen from "./routes/HsnListScreen";
import AttributesListScreen from "./routes/AttributesListScreen";
import MapProduct from "./routes/MapProduct";
import MyProductDetaileScreen from "./routes/MyProductDetaileScreen";
import RequestListDetaileScreen from "./routes/RequestListDetaileScreen";
import PricingPage from "./routes/PricingPage";
import ProjectsScreen from "./routes/ProjectsScreen";
import OverView from "./components/Projects/Overview";
import Collaborate from "./components/Projects/Collaborate";
import OurDeals from "./routes/sitemanagement/OurDeals/OurDeals";
import OurDealsView from "./routes/sitemanagement/OurDeals/OurDealsView";
import JoinWithUs from "./routes/sitemanagement/JoinWithUs/JoinWithUs";
import JoinWithUsView from "./routes/sitemanagement/JoinWithUs/JoinWithUsView";
import MapRequestView from "./routes/MapRequestView";
import RequestAcceptScreen from "./routes/RequestAcceptScreen";
import ProductVendorDetailsPage from "./routes/ProductVendorDetailsPage";
import DecorativeVendorDetailsPage from "./routes/DecorativeVendorDetailsPage";
import BothVendorDetailsPage from "./routes/BothVendorDetailsPage";
import CreateNewRequestScreen from "./routes/CreateNewRequestScreen";
import Presentation from "./components/Projects/Presentation";
import OurPackScreen from "./routes/OurPackScreen";
import AddOurProductScreen from "./routes/AddOurProductScreen";
import AddPackDetails from "./routes/AddPackDetails";
import PresentationEdit from "./components/Projects/PresentationEdit";
import SubLayout from "./components/Sub_Layout/SubLayout";
import EstimateScreen from "./components/Projects/EstimateScreen";
import ShippingScreen from "./routes/ShippingScreen";
import SalesOrderScreen from "./components/Projects/SalesOrderScreen";
import AddDecorativeVendorScreen from "./routes/AddDecorativeVendorScreen";
import EstimateEditItemScreen from "./components/Projects/EstimateEditItemScreen";
import ProductionScreen from "./routes/Production/ProductionScreen";
import ProductionDashBoard from "./routes/Production/ProductionDashBoard";
import SalesScreen from "./components/Projects/SalesScreen";
import DecorativeMethodEdit from "./routes/DecorativeMethodEdit";
import SampleProfileNavBar from "./components/Sub_Layout/ProfileNavBar2";
import SampleEmtyScreen from "./components/Projects/SampleEmtyScreen";
import Layout2 from "./components/Sub_Layout/Layout2";
import PresentationEditScreen from "./components/Projects/PresentationEditScreen";
import CollaborateScreen from "./routes/CollaborateScreen";
import AddCollaborate from "./components/Projects/AddCollaborate";
import SalesOrderEditItemScreen from "./components/Projects/SalesOrderEditItemScreen";
import CollaborateLists from "./components/Projects/CollaborateList";
import ProductTransfer from "./components/Sales/collaborate/product/ProductTransfer";
import ProductTransferView from "./components/Sales/collaborate/product/ProductTransferView";
import PriceBookLibraryScreen from "./routes/PriceBookLibraryScreen";
import AddPriceBookLibraryScreen from "./routes/AddPriceBookLibraryScreen";
import CustomerDashboard from "./routes/CustomerDashboard";
import EnterprisesDashboard from "./routes/EnterprisesDashboard";
import EnterprisesList from "./routes/EnterprisesList";
import EnterprisesView from "./routes/EnterprisesView";
import AddEnterprises from "./routes/AddEnterprises";
import CollabrateAcceptEditScreen from "./routes/CollabrateAcceptEditScreen";
import EnterprisesRequestList from "./routes/EnterprisesRequestList";
import CollaborateNew from "./components/Projects/CollaborateNew";
import PriceBookList from "./components/PriceBookLibraryComp/PriceBookList";
import PriceBookView from "./components/PriceBookLibraryComp/PriceBookView";
import PriceBookListProduct from "./components/PriceBookLibraryComp/PriceBookListProduct";
import PrivateRoutes from "./redux/store/privateRoutes";
import { TOKEN } from "./redux/api/constants";
import AddCategoryScreenTest from "./routes/AddCategoryScreenTest";
import CategoryRole from "./routes/CategoryRole";
import CategoryRoleView from "./routes/CategoryRoleView";
import EventCategory from "./components/Packs/EventCategory";
import Tags from "./components/Tags/Tags";
import EventTags from "./components/EventTags/EventTags";
import IconTags from "./components/IconTags/IconTags";
import Navigation from "./components/Navigation/Navigation";
import InvoicePage from "./components/Projects/InvoicePage";
import VendorsRequestDetails from "./components/ProductFeed/VendorsRequestDetails";
import POoverAllRequestScreen from "./routes/AdminRequest/POoverAllRequestScreen";
import PODetailViewScreen from "./routes/AdminRequest/PODetailViewScreen";
import PendingOrderScreen from "./routes/PendingOrderScreen";
import PendingDetailView from "./routes/PendingDetailView";
import SupportScreen from "./components/SupportComp/SupportScreen";
import Chat from "./components/ChatScreen/Chat";
import PoAcceptViewScreen from "./routes/AdminRequest/PoAcceptViewScreen";
import PoRejectViewScreen from "./routes/AdminRequest/PoRejectViewScreen";
import PODetailViewRequestScreen from "./routes/PODetailViewRequestScreen";
import ProductVendorPoAcceptViewScreen from "./routes/ProductVendorPoAcceptViewScreen";
import ProductVendPoRejectViewScreen from "./routes/ProductVendPoRejectViewScreen";
import Support from "./components/SupportComp/SupportScreen";
import OverallArtworkRequestScreen from "./routes/AdminRequest/OverallArtworkRequestScreen";
import OverallProdectionArtworkRequestScreen from "./routes/AdminRequest/OverallProdectionArtworkRequestScreen";
import ProductionArtworkScreen from "./components/Projects/ProductionArtworkScreen";
import UploadArtworkUploadScreen from "./components/Projects/UploadArtworkUploadScreen";

import SalesOrderPoUploadPopup from "./components/sales_order/Poppup/SalesOrderPoUploadPopup";
import PreProduction from "./routes/PreProduction";
import PriceRequest from "./routes/AdminRequest/PriceRequest";
import OverallEstimateRequest from "./routes/AdminRequest/OverallEstimateRequest";
import PoScreen from "./routes/PoScreen";
import ShippingDetails from "./routes/ShippingDetails";
import BillsPage from "./routes/BillsPage";
import CreateNewBill from "./routes/CreateNewBill";
import BillTotalPage from "./routes/BillTotalPage";
import POAllScreen from "./routes/AdminRequest/POAllScreen";
import POAllVendorScreen from "./routes/POAllVendorScreen";
import InvoiceClient from "./routes/InvoiceClient";
import InvoiceClientA from "./routes/InvoiceClientA";
import PackingSlipA from "./routes/PackingSlipA";
import PackingSlip from "./routes/PackingSlip";
import ShippingTo from "./routes/ShippingTo";
import ShippingToA from "./routes/ShippingToA";
import POProductSupplierA from "./routes/POProductSupplierA";
import POProductSupplier from "./routes/POProductSupplier";
import EstimationPageA from "./routes/EstimationPageA";
import EstimationPage from "./routes/EstimationPage";
import PoProductSupplierApproved from "./routes/PoProductSupplierApproved";
import PoProductSupplierApprovedA from "./routes/PoProductSupplierApprovedA";
import PoProductRequestA from "./routes/PoProductRequestA";
import PoProductRequest from "./routes/PoProductRequest";
import SalesOrderA from "./routes/SalesOrderA";
import SalesOrder from "./routes/SalesOrder";
import PresentationGallery from "./routes/PresentationGallery";
import PresentationGalleryA1 from "./routes/PresentationGalleryA1";
import DCb from "./routes/DCb";
import DC from "./routes/DC";
import PresentationGalleryCommentA from "./routes/PresentationGalleryCommentA";
import PresentationGalleryComment from "./routes/PresentationGalleryComment";
import PresentationGalleryPage2A from "./routes/PresentationGalleryPage2A";
import PresentationGalleryPage3A from "./routes/PresentationGalleryPage3A";
import FeedbackA from "./routes/FeedBackA";
import PresentationListA from "./routes/PresentationListA";
import SalesOrderEnlargeA from "./routes/SalesOrderEnlargeA";
import SalesOrderEnlargeB from "./routes/SalesOrderEnlargeB";
import EstimateEnlargeA from "./routes/EstimateEnlargeA";
import EstimateEnlargeB from "./routes/EstimateEnlargeB";
import SalesArtEnlarge2 from "./routes/SalesArtEnlarge2";
import SalesArtEnlarge1 from "./routes/SalesArtEnlarge1";
import SupplierPORequestA from "./routes/SupplierPORequestA";
import Faq from "./routes/sitemanagement/Faq";
import FaqEdit from "./routes/FaqEdit";
import FaqAdd from "./routes/FaqAdd";
import FaqView from "./routes/FaqView";
import RoleVendorList from "./routes/RoleVendorList";
import NavigationList from "./components/Navigation/NavigationList";
import ActivityLogs from "./routes/ActivityLogs";
import Notification from "./routes/Notification";
import Currencies from "./routes/Currencies";
import Colours from "./routes/Colours";
import ColourAdd from "./routes/ColourAdd";
import CurrencyAdd from "./routes/CurrencyAdd";
import EventCategories from "./components/Packs/EventCategories";
import NewProductView from "./routes/sitemanagement/VendorList/NewProductView";
import SalesTasks from "./components/Sales/SalesTasks";
import AddTask from "./components/Sales/AddTask";
import ViewTask from "./components/Sales/ViewTask";
import PartnerListPage from "./routes/PartnerListPage";
import LeadSourcePage from "./routes/LeadSourcePage";
import TypeofOrderPage from "./routes/TypeofOrderPage";
import LocationNamePage from "./routes/LocationNamePage";
import AttributesOverViewListScreen from "./routes/AttributesOverViewListScreen";
import AttributesPresentationListScreen from "./routes/AttributesPresentationListScreen";
import AttributesEstimateListScreen from "./routes/AttributesEstimateListScreen";
import AttributesSalesOrderListScreen from "./routes/AttributesSalesOrderListScreen";
import InventoryBoxPage from "./routes/InventoryBoxPage";
import ChargeShippingPage from "./routes/ChargeShippingPage";

const ReactRoutes = () => {
  const token = localStorage.getItem(TOKEN);
  const [imageShow, setImageShow] = useState(false);
  const imageShoweClick = () => {
    setImageShow(!imageShow);
  };
  return (
    <Routes>
      {/* invoice starts */}
      <Route path="/invoice-client" element={<InvoiceClient />} />
      <Route path="/invoice-client-a" element={<InvoiceClientA />} />
      <Route path="/packing-slip-a" element={<PackingSlipA />} />
      <Route path="/packing-slip" element={<PackingSlip />} />
      <Route path="/shipping-to" element={<ShippingTo />} />
      <Route path="/shipping-to-a" element={<ShippingToA />} />
      <Route path="/po-product-supplier-a" element={<POProductSupplierA />} />
      <Route path="/po-product-supplier" element={<POProductSupplier />} />
      <Route path="/estimation-page-a" element={<EstimationPageA />} />
      <Route path="/estimation-page" element={<EstimationPage />} />
      <Route
        path="/poproduct-supplier-approved-a"
        element={<PoProductSupplierApprovedA />}
      />
      <Route
        path="/poproduct-approved"
        element={<PoProductSupplierApproved />}
      />
      <Route path="/poproduct-request-a" element={<PoProductRequestA />} />
      <Route path="/poproduct-request" element={<PoProductRequest />} />
      <Route path="/supplier-po-request-a" element={<SupplierPORequestA />} />
      <Route path="/sales-order-a" element={<SalesOrderA />} />
      <Route path="/sales-orderss" element={<SalesOrder />} />
      <Route path="/presentation-gallery" element={<PresentationGallery />} />
      <Route
        path="/presentation-gallery-a1"
        element={<PresentationGalleryA1 />}
      />
      <Route path="/dc-b" element={<DCb />} />
      <Route path="/dc" element={<DC />} />
      <Route
        path="/presentation-gallery-comment-a"
        element={<PresentationGalleryCommentA />}
      />
      <Route
        path="/presentation-gallery-comment"
        element={<PresentationGalleryComment />}
      />
      <Route
        path="/presentation-gallery-page-2a"
        element={<PresentationGalleryPage2A />}
      />
      <Route
        path="/presentation-gallery-page-3a"
        element={<PresentationGalleryPage3A />}
      />
      <Route path="/feedback-a" element={<FeedbackA />} />
      <Route path="/presentation-list-a" element={<PresentationListA />} />
      <Route path="/sales-order-enlarge-a" element={<SalesOrderEnlargeA />} />
      <Route path="/sales-order-enlarge-b" element={<SalesOrderEnlargeB />} />
      <Route path="/estimate-a" element={<EstimateEnlargeA />} />
      <Route path="/estimate-b" element={<EstimateEnlargeB />} />
      <Route path="/sales-art-enlarge-2" element={<SalesArtEnlarge2 />} />
      <Route path="/sales-art-enlarge-1" element={<SalesArtEnlarge1 />} />
      {/* invoice ends */}
      <Route path="/" element={<SignInScreen />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPasswordScreen />} />
      <Route element={<PrivateRoutes token={token}></PrivateRoutes>}>
        <Route element={<Layout2 />}>
          {/* <Route element={<Layout />}> */}
          <Route path="/home" index element={<Dashboard />} />
          <Route path="/profile" element={<AdminPanel />} />
          <Route path="/profiles" element={<AddProfile />} />
          <Route path="/view-profile" element={<ViewProfile />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/role-profile" element={<RoleProfileList />} />
          <Route path="/cateogry-roles" element={<CategoryRole />} />
          <Route path="/cateogry-roles-view" element={<CategoryRoleView />} />
          <Route path="/role-profiles" element={<RoleAddProfile />} />
          {/* <Route path="/view-role-profile" element={<RoleViewProfile />} /> */}
          <Route path="/view-role-profile" element={<RoleVendorList />} />
          <Route path="/edit-role-profile" element={<RoleEditProfile />} />
          <Route path="/vendor-home" element={<VendorDashboard />} />
          <Route path="/add-vendor" element={<AddVendor />} />
          <Route path="/product-feed" element={<ProductFeed />} />
          <Route
            path="/vendors-request-details"
            element={<VendorsRequestDetails />}
          ></Route>
          <Route path="/product-library" element={<ProductLibrary />} />
          <Route path="/vendor-management" element={<VendorManageScreen />} />
          <Route path="/products" element={<ProductScreen />} />
          <Route path="/add-collaborate" element={<AddCollaborate />} />
          <Route path="/collaborate-list" element={<CollaborateLists />} />
          <Route path="/product-transfer" element={<ProductTransfer />} />
          <Route
            path="/product-transfer-view"
            element={<ProductTransferView />}
          />
          <Route
            path="/products/add-new-product"
            element={<AddNewProductScreen />}
          />
          <Route
            path="/product-vendor-details"
            element={<ProductVendorDetailsPage />}
          />
          <Route
            path="/decorative-vendor-details"
            element={<DecorativeVendorDetailsPage />}
          />
          <Route
            path="/both-vendor-details"
            element={<BothVendorDetailsPage />}
          />
          <Route
            path="/price-book-library"
            element={<PriceBookLibraryScreen />}
          />
          <Route
            path="/add-price-book-library"
            element={<AddPriceBookLibraryScreen />}
          />
          <Route
            path="/collabrate-accept-edit"
            element={<CollabrateAcceptEditScreen />}
          />

          <Route path="/add-new-product/products" element={<ProductScreen />} />
          <Route path="/map-product-detail" element={<ProductDetailScreen />} />
          <Route
            path="/my-product-detail"
            element={<MyProductDetaileScreen />}
          />
          <Route path="/preset-packs" element={<OurPackScreen />} />
          <Route path="/event-category" element={<EventCategory />}></Route>
          {/* <Route path="/event-categories" element={<EventCategories />}></Route> */}
          <Route path="/add-our-packs" element={<AddOurProductScreen />} />
          <Route
            path="/request-list-detail"
            element={<RequestListDetaileScreen />}
          />
          <Route
            path="/decorative-detail"
            element={<DecorativeDetailScreen />}
          />
          <Route
            path="/product-library-details"
            element={<ProductLibraryDetailsScreen />}
          />
          <Route
            path="/decorative-product"
            element={<AddDecorativeVendorScreen />}
          />
          <Route path="/view-thread" element={<ViewThread />} />
          <Route path="/category-setting" element={<CatogorySettingScreen />} />
          <Route path="/pre-production" element={<PreProduction />} />
          <Route path="/customer-list" element={<CustomerList />} />
          <Route path="/customer-dashboard" element={<CustomerDashboard />} />
          <Route
            path="/enterprises-dashboard"
            element={<EnterprisesDashboard />}
          />
          <Route path="/navigation" element={<Navigation />}></Route>
          <Route path="/navigation-list" element={<NavigationList />} />
          <Route path="/view-customer" element={<ViewCustomer />} />
          <Route path="/customer-list" element={<CustomerList />} />
          <Route path="/enterprises-list" element={<EnterprisesList />} />
          <Route path="/enterprises-view" element={<EnterprisesView />} />
          <Route path="/add-enterprises" element={<AddEnterprises />} />
          <Route path="/view-customer" element={<ViewCustomer />} />
          <Route
            path="/view-request-details-customer"
            element={<CustomerRequestViewDetails />}
          />

          <Route path="/projects-tasks" element={<SalesTasks />} />
          <Route path="/projects-task" element={<AddTask />} />
          <Route path="/projects-task-view" element={<ViewTask />} />
          <Route
            path="/view-nonrequest-details"
            element={<NoncustomerRequestViewDetails />}
          />
          <Route path="/category-setting" element={<CatogorySettingScreen />} />
          <Route path="/customer-request-list" element={<ViewCustomer />} />
          <Route
            path="/enterprises-request-list"
            element={<EnterprisesRequestList />}
          />
          <Route path="/view-library-list" element={<ViewLibraryList />} />
          <Route path="/order-list" element={<OrderList />} />
          <Route
            path="/view-customer-details"
            element={<CustomerViewDetailsScreen />}
          />
          <Route path="/view-order-details" element={<OrderViewDetails />} />
          <Route
            path="/ordered-view-details"
            element={<OrderedViewDetails />}
          />
          <Route path="/ecommerce-list" element={<ECommerceList />} />
          <Route
            path="/ecommerce-view-details"
            element={<EcommerceViewDetails />}
          />
          <Route
            path="/view-collaborate-details"
            element={<ViewCollaborateDetails />}
          />
          <Route path="/vendor-settings" element={<VendorSettings />} />
          <Route path="/request-detail-view" element={<RequestDetailView />} />
          {/* <Route path="/add-category" element={<AddCategoryScreen />} /> */}
          <Route path="/add-category" element={<AddCategoryScreenTest />} />
          <Route path="/view-category" element={<ViewCategoryScreen />} />
          <Route path="/decorative-method" element={<DecorativeMethod />} />
          <Route
            path="/decorative-method-edit"
            element={<DecorativeMethodEdit />}
          />
          <Route path="/hsn-settings" element={<HsnListScreen />} />
          <Route path="/pricebook-library" element={<PriceBookList />} />
          <Route
            path="/view-pricebook-library"
            element={<PriceBookListProduct />}
          />
          <Route path="/pricebook-library-view" element={<PriceBookView />} />
          <Route path="/attributes" element={<AttributesListScreen />} />
          <Route
            path="/attributes-project"
            element={<AttributesListScreen />}
          />
          <Route
            path="/overview-attributes-project"
            element={<AttributesOverViewListScreen />}
          />
          <Route
            path="/presentation-attributes-project"
            element={<AttributesPresentationListScreen />}
          />
          <Route
            path="/estimate-attributes-project"
            element={<AttributesEstimateListScreen />}
          />

          <Route
            path="/sales-order-attributes-project"
            element={<AttributesSalesOrderListScreen />}
          />

          <Route path="/project-partner" element={<PartnerListPage />} />
          <Route path="/project-lead-sources" element={<LeadSourcePage />} />
          <Route path="/project-type-of-order" element={<TypeofOrderPage />} />
          <Route path="/project-location-name" element={<LocationNamePage />} />
          <Route path="/box" element={<InventoryBoxPage />} />
          <Route path="/charge-shipping" element={<ChargeShippingPage />} />
          <Route path="/tags" element={<Tags />}></Route>
          <Route path="/tags-project" element={<Tags />}></Route>
          <Route path="/event-tags" element={<EventTags />}></Route>
          <Route path="/icon-tags" element={<IconTags />}></Route>
          <Route path="/currencies" element={<Currencies />}></Route>
          <Route path="/currencies-add" element={<CurrencyAdd />}></Route>
          <Route path="/colours" element={<Colours />}></Route>
          <Route path="/colours-add" element={<ColourAdd />}></Route>
          <Route path="/pricing-page" element={<PricingPage />} />
          <Route path="/projects" element={<ProjectsScreen />} />
          <Route path="/collaborates" element={<CollaborateScreen />} />
          <Route path="/collaborate-view" element={<Collaborate />} />
          <Route path="/map-request-view" element={<MapRequestView />} />
          <Route path="/request-accept" element={<RequestAcceptScreen />} />
          <Route path="/add-pack-details" element={<AddPackDetails />} />
          {/* rubesh-start */}
          <Route path="/testimonials" element={<TestimonialScreen />} />
          <Route
            path="/testimonials-detail"
            element={<TestimonialViewDetails />}
          />
          <Route path="/testimonials-edit" element={<TestimonialEdit />} />
          <Route
            path="/banner-management"
            element={<BannerManagementScreen />}
          />
          <Route
            path="/banner-management-detail"
            element={<BannerViewDetails />}
          />
          <Route path="/banner-management-edit" element={<BannerEdit />} />
          <Route path="/our-deals" element={<OurDeals />} />
          <Route path="/our-deals-view" element={<OurDealsView />} />
          <Route path="/join-with-us" element={<JoinWithUs />} />
          <Route path="/faq-page" element={<Faq />} />
          <Route path="/faq-edit" element={<FaqEdit />} />
          <Route path="/faq-add" element={<FaqAdd />} />
          <Route path="/faq-view" element={<FaqView />} />
          <Route path="/join-with-us-view" element={<JoinWithUsView />} />
          <Route path="/blog-management" element={<BlogManagementScreen />} />

          <Route path="/blog-management-detail" element={<BlogViewDetails />} />
          <Route path="/blog-management-edit" element={<BlogEdit />} />
          <Route path="/special-offers" element={<SpecialOffersScreen />} />
          {/* production-start */}
          <Route
            path="/production-dashboard"
            element={<ProductionDashBoard />}
          />
          <Route path="/production" element={<ProductionScreen />} />
          {/* production-end */}
          <Route path="/support" element={<SupportScreen />} />
          <Route path="/chat" element={<Chat />} />
          <Route
            path="/special-offers-detail"
            element={<SpecialOfferViewDetails />}
          />
          <Route path="/special-offers-edit" element={<SpecialOfferEdit />} />
          <Route path="/vendor-list" element={<VendorListScreen />} />
          <Route
            path="/vendor-management-detail"
            element={<VendorListViewDetails />}
          />
          <Route path="/map-product" element={<MapProduct />} />
          <Route
            path="/create-new-request"
            element={<CreateNewRequestScreen />}
          />
          <Route path="/create-new-request-new" element={<NewProductView />} />
          <Route path="/vendor-management-edit" element={<VendorListEdit />} />
          {/* rubesh-end*/}
          {/* Sankari start */}
          <Route
            path="/po-overall-request"
            element={<POoverAllRequestScreen />}
          />
          <Route path="/po-all-request" element={<POAllScreen />} />
          <Route path="/po-all-vendor" element={<POAllVendorScreen />} />
          <Route path="/po-detail-view" element={<PODetailViewScreen />} />
          <Route
            path="/po-detail-accept-view"
            element={<PoAcceptViewScreen />}
          />
          <Route
            path="/po-detail-reject-view"
            element={<PoRejectViewScreen />}
          />
          <Route
            path="/product-vendor-porequest-view"
            element={<PODetailViewRequestScreen />}
          />
          <Route
            path="/vendor-panel-poaccept-view"
            element={<ProductVendorPoAcceptViewScreen />}
          />
          <Route
            path="/product-vendor-poreject-view"
            element={<ProductVendPoRejectViewScreen />}
          />
          <Route
            path="/overall-markup-request"
            element={<OverallArtworkRequestScreen />}
          />
          <Route
            path="/overall-production-markup-request"
            element={<OverallProdectionArtworkRequestScreen />}
          />
          {/* Sankari end*/}
          {/* hari start */}
          <Route path="/pending-order" element={<PendingOrderScreen />} />
          <Route path="/pending-detail-view" element={<PendingDetailView />} />
          <Route path="/price-request" element={<PriceRequest />} />
          <Route path="/notification" element={<Notification />} />
          <Route
            path="/Overall-estimate-request"
            element={<OverallEstimateRequest />}
          />
          <Route path="/activity-logs" element={<ActivityLogs />} />

          {/* hari end */}
        </Route>

        <Route path="/presentation-edit" element={<PresentationEditScreen />} />
        {/* <Route path="/presentation-edit-dummy" element={<PresentationEditScreen />} /> */}
        <Route element={<SubLayout />}>
          <Route path="/overview" element={<OverView />} />
          <Route path="/presentation" element={<Presentation />} />
          <Route path="/estimate" element={<EstimateScreen />} />
          <Route path="/shipping" element={<ShippingScreen />} />
          <Route path="/shipping-details" element={<ShippingDetails />} />
          <Route path="/Po" element={<PoScreen />} />
          <Route path="/sales-order" element={<SalesScreen />} />
          <Route path="/view-collaborate" element={<CollaborateNew />} />
          <Route path="/collaborate" element={<Collaborate />} />
          <Route path="/invoice-page" element={<InvoicePage />} />
          <Route path="/bills-page" element={<BillsPage />} />
          <Route path="/create-new-bill" element={<CreateNewBill />} />
          <Route path="/bill-total-page" element={<BillTotalPage />} />
          {/* Sankari start */}
          <Route
            path="/prodection-artwork"
            element={<ProductionArtworkScreen />}
          />
          <Route
            path="/upload-artwork-inprocess"
            element={<UploadArtworkUploadScreen />}
          />
          {/* Sankari end */}
        </Route>
        <Route element={<Layout />}>
          <Route path="/empty" element={<SampleEmtyScreen />} />
        </Route>
        <Route
          path="/estimate-edit-item"
          element={<EstimateEditItemScreen />}
        />
        <Route
          path="/sales-order-edit-item"
          element={<SalesOrderEditItemScreen />}
        />
      </Route>
    </Routes>
  );
};

export default ReactRoutes;
