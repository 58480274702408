import React, { useEffect, useRef, useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { toggleOn } from "../assets/img";
import OverViewComp from "../components/ProductDetailComp/OverViewComp";
import VendorPricingComp from "../components/ProductDetailComp/VendorPricingComp";
import LibraryOverViewComp from "../components/ProductLibraryDetails/LibraryOverViewComp";
import LibraryVendorPricingComp from "../components/ProductLibraryDetails/LibraryVendorPricingComp";
import { useLocation, useNavigate } from "react-router-dom";
import DecorativePricing from "../components/ProductLibraryDetails/DecorativePricing";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useDispatch } from "react-redux";
import {
  useLazyAttributeListQuery,
  useLazyCategoriesQuery,
  useLazyCategoryViewQuery,
  useLazyColorsListAllQuery,
  useLazyDecorativeListQuery,
  useLazyGetProductDecorPriceListQuery,
  useLazyGetProductVendorPriceListQuery,
  useLazyHsnAllListQuery,
  useLazyProductImgDeleteQuery,
  useLazyProductViewQuery,
  useLazyTagsListQuery,
  useProductCreateMutation,
  useProductGenerateVarientMutation,
  useProductStoreVarientMutation,
  useProductUpdateMutation,
  useProductUpdateVarientMutation,
} from "../redux/api/api";
import { saveLoader } from "../redux/slice/loaderNoti";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { toast } from "react-hot-toast";

const ProductLibraryDetailsScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const [edit, setEdit] = useState(true);

  const dispatch = useDispatch();
  const [selColors, setSelColors] = useState([]);
  const vendor = location?.state?.type;
  const vendor2 = location?.state?.path;
  const vendor3 = location?.state?.place;

  const [parentCategoryList, setParentCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCategoryLists, setSubCategoryLists] = useState([]);
  const [decorativeList, setDecorativeList] = useState([]);
  const [attributesList, setAttributesList] = useState([]);
  const [parentCategory, setParentCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [decorativeID, setDecorativeID] = useState([]);
  const [decorativeName, setDecorativeName] = useState([]);
  const [hsnList, setHsnList] = useState([]);
  const [country, setCountry] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [otherProInfo, setOtherProInfo] = useState("");
  const [hsnCodeErr, setHsnCodeErr] = useState(false);
  const [hsnName, setHsnName] = useState("");
  const [tagsList, setTagsList] = useState([]);
  const [tagsEventList, setEventTagsList] = useState([]);
  const [tagsIconList, setIconTagsList] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagsName, setTagsName] = useState([]);
  const [tagsEvent, setTagsEvent] = useState([]);
  const [tagsEventName, setTagsEventName] = useState([]);
  const [tagsIcon, setTagsIcon] = useState([]);
  const [tagsIconName, setTagsIconName] = useState([]);
  const [colorsList, setColorsList] = useState([]);
  const [colorsID, setColorsID] = useState([]);
  const [colorsName, setColorsName] = useState([]);
  const [colorsData, setColorsData] = useState([]);
  const [varientShow, setVarientShow] = useState(false);
  const [sizes, setSizes] = useState([{ value: "" }]);
  const [materials, setMeterials] = useState([{ value: "" }]);
  const [styles, setStyles] = useState([{ value: "" }]);
  const [allVarients, setAllVarients] = useState([]);
  const [catogoryName, setCatogoryName] = useState({});
  const [primaryCategoryId, setPrimaryCategoryId] = useState("");
  const [secondaryCategoryId, setSecondaryCategoryId] = useState("");
  const [primaryCategoryIdErr, setPrimaryCategoryIdErr] = useState(false);
  const [secondaryCategoryIdErr, setSecondaryCategoryIdErr] = useState(false);
  const [searchValueSubCategory, setSearchSubValueCategory] = useState("");
  const [productSuplierCode, setProductSuplierCode] = useState("");
  const [productInformation, setProductInformation] = useState([]);
  const [smallimage, setSmallImage] = useState([]);
  const [smallMockimage, setSmallMockImage] = useState([]);
  const [attributesName, setAttributesName] = useState(["Color"]);

  const [categoryID, setCategoryID] = useState("");
  const [catogory, setCatogory] = useState("");
  const [name, setName] = useState("");
  const [mrp, setMrp] = useState("");
  const [decorationMethod, setDecorationMethod] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productPackageSize, setProductPackageSize] = useState("");
  const [gendor, setGendor] = useState("");
  const [gendorErr, setGendorErr] = useState("");
  const [colorVarient, setColorVarient] = useState("");
  const [productPackageSizeErr, setProductPackageSizeErr] = useState(false);
  const [productDescriptionErr, setProductDescriptionErr] = useState(false);
  const [decorationMethodErr, setDecorationMethodErr] = useState(false);
  const [colorVarientErr, setColorVarientErr] = useState(false);
  const [mrpErr, setMrpErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [countryErr, setCountryErr] = useState(false);
  const [categoryIDErr, setCategoryIDErr] = useState(false);
  const [tagErr, setTagErr] = useState(false);
  const [tagVal, setTagVal] = useState("");
  const [tagEventErr, setTagEventErr] = useState(false);
  const [tagEventVal, setTagEventVal] = useState("");
  const [tagIconErr, setTagIconErr] = useState(false);
  const [tagIconVal, setTagIconVal] = useState("");
  const [productImg, setProductImg] = useState("");
  const [productImgErr, setProductImgErr] = useState(false);
  const [morkupImg, setMorkupImg] = useState("");
  const [morkupImgErr, setMorkupImgErr] = useState(false);
  const [colorsImg, setColorsImg] = useState("");
  const [colorImgErr, setColorImgErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const [submitBtn, setSubmitBtn] = useState(0);
  const [listAttributes, setListAttributes] = useState([]);
  const [dynamicAttr, setDynamicAttr] = useState([]);
  const [addVariants, setAddVariants] = useState(false);
  const [otherProInfoErr, setOtherProInfoErr] = useState(false);
  const [productInformationErr, setProductInformationErr] = useState(false);
  const [stylesErr, setStylesErr] = useState(false);
  const [materialsErr, setMaterialsErr] = useState(false);
  const [sizesErr, setSizesErr] = useState(false);
  const [colosErr, setColosErr] = useState(false);

  const [submitData, setSubmitData] = useState({});
  const [viewData, setViewData] = useState({});
  const [varientsList, setVarientList] = useState([]);
  const [vendorPriceList, setVendorPriceList] = useState([]);
  const [vendorPriceLists, setVendorPriceLists] = useState({});
  const [decorPriceList, setDecorPriceList] = useState([]);
  const [varientsCheck, setVarientsCheck] = useState("");
  const [addVarientErr, setAddVarientErr] = useState(false);

  // RTK QUERY
  const [listCategory] = useLazyCategoriesQuery();
  const [viewCategory] = useLazyCategoryViewQuery();
  const [decorativeListApi] = useLazyDecorativeListQuery();
  const [hsnListApi] = useLazyHsnAllListQuery();
  const [tagsListApi] = useLazyTagsListQuery();
  const [colorsListApi] = useLazyColorsListAllQuery();
  const [attributesApi] = useLazyAttributeListQuery();

  const [productCreateApi] = useProductCreateMutation();
  const [productUpdateApi] = useProductUpdateMutation();
  const [productViewApi] = useLazyProductViewQuery();
  const [productGenerateVarientApi] = useProductGenerateVarientMutation();
  const [productStoreVarientApi] = useProductStoreVarientMutation();
  const [productUpdateVarientApi] = useProductUpdateVarientMutation();
  const [getProductVendorPriceApi] = useLazyGetProductVendorPriceListQuery();
  const [getProductDecorPriceApi] = useLazyGetProductDecorPriceListQuery();

  // GET PARENT CATEGORY LIST
  const getParentCategoryList = () => {
    listCategory()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setParentCategoryList(res?.categories);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  // GET DECORATIVE METHOD
  const getDecorativeList = () => {
    decorativeListApi()
      .unwrap()
      .then((res) => {
        setDecorativeList(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // GET HSN LIST
  const getHsnList = () => {
    hsnListApi()
      .unwrap()
      .then((res) => {
        setHsnList(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // GET HSN LIST
  const getTagsList = () => {
    tagsListApi()
      .unwrap()
      .then((res) => {
        setTagsList(res?.normalTag);
        setEventTagsList(res?.eventTag);
        setIconTagsList(res?.iconTag);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // GET COLORS
  const getColors = (colorTemp) => {
    colorsListApi()
      .unwrap()
      .then((res) => {
        // console.log("colorTemp", colorTemp);
        let temp = [];

        if (colorTemp?.length > 0) {
          const map = new Map(colorTemp?.map(({ id, ...rest }) => [id, rest]));
          const result = res?.data?.map((o) => ({ ...o, ...map.get(o.id) }));

          // console.log("result", result);

          result?.map((item, ind) => {
            let obj = {
              ...item,
              image: "",
              color_id: item?.id,
            };
            temp.push(obj);
          });
          setColorsList(temp);
        } else {
          res?.data?.map((item, ind) => {
            let obj = {
              ...item,
              image: "",
              color_id: item?.id,
            };
            temp.push(obj);
          });
          setColorsList(temp);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getAttributes = () => {
    attributesApi()
      .unwrap()
      .then((res) => {
        setAttributesList(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getDecorPriceList = () => {
    getProductDecorPriceApi(location?.state?.data?.id)
      .unwrap()
      .then((res) => {
        setDecorPriceList(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getParentCategoryList();
    getDecorativeList();
    getHsnList();
    getTagsList();
    // getColors();
    getAttributes();
  }, []);

  // GET PARENT ID FOR SUB CATEGORY LIST
  const getCategoryId = (id, type, ind, index, data) => {
    if (type == "category") {
      setParentCategory(id);
      getView(id, "", "", type, "");
    } else {
      setSubCategory(id);
      onSubCatSelect(id, ind, index, data);
    }
  };

  // SLICE ARRAY
  const onSubCatSelect = (id, ind, index, data) => {
    if (index < data?.length - 1) {
      let temp = [...data];
      let slicer = temp.slice(0, index + 1);
      setSubCategoryList(slicer);

      let temps = [...subCategoryLists];
      let slicers = temps.slice(0, index + 1);
      setSubCategoryLists(slicers);

      getView(id, ind, index, "sub_category", slicer);
    } else {
      getView(id, ind, index, "", data);
    }
  };

  // VIEW CHID CATEGORY API
  const getView = (id, ind, index, type, data) => {
    viewCategory(id)
      .unwrap()
      .then((res) => {
        let child = [];
        res?.child_categories?.map((item, ind) => {
          child.push({
            ...item,
            category: {
              ...res?.category,
            },
          });
        });

        let responce = {
          category: res?.category,
          child_categories: child,
        };

        let temp = [];
        let temps = [];
        if (data?.length > 0) {
          temp = [...data];
          temps = [...data];
        } else if (type !== "category") {
          temp = [...subCategoryList];
          temps = [...subCategoryLists];
        }

        if (type == "subSumit") {
          let temX = responce?.child_categories;

          let obj = {
            ...temp[index],
            child_categories: temX,
          };

          let final = subCategoryList?.filter((i) => i?.category?.id !== id);
          final.splice(index, 0, obj);

          let finals = subCategoryLists?.filter((i) => i?.category?.id !== id);
          finals.splice(index, 0, obj);

          setSubCategoryList(final);
          setSubCategoryLists(finals);
        } else {
          let finder = temp.indexOf(id);

          if (temp?.includes(responce)) {
            temp.slice(0, 1);
            temp.push(responce);
          } else {
            temp.push(responce);
          }

          if (temps?.includes(responce)) {
            temps.slice(0, 1);
            temps.push(responce);
          } else {
            temps.push(responce);
          }

          setSubCategoryList(temp);
          setSubCategoryLists(temps);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // SELECT CHILD CATEGORY
  const onSubCategorySelect = (subItem, indx, ind) => {
    setSearchSubValueCategory((val) => ({ ...val, [ind]: "" }));
    let temp = [...subCategoryList];
    temp[ind] = subCategoryLists[ind];
    setSubCategoryList(temp);
    getCategoryId(subItem?.id, "sub_category", indx, ind, temp);
    setCatogoryName((val) => ({
      ...val,
      [subItem?.category?.id]: subItem?.name,
    }));
    if (ind == 0) {
      setSecondaryCategoryId(subItem?.id);
    } else {
      setCategoryID(subItem?.id);
    }

    setProductSuplierCode(subItem?.code);
  };

  // VARIENT COMBINATION STATUC CHANGE
  const deleteVarient = (ind, index, status, type) => {
    let temp = [...varientsList];
    if (type == "parent") {
      // FOR PARENT
      let change_status;
      if (status == 1) {
        change_status = 0;
      } else {
        change_status = 1;
      }

      let tempx = temp[ind].childs;

      for (let i = 0; i < tempx?.length; i++) {
        tempx[i] = {
          ...tempx[i],
          status: change_status,
        };
      }

      temp[ind] = {
        ...temp[ind],
        status: change_status,
        childs: tempx,
      };
      setVarientList(temp);
    } else {
      // FOR CHILD
      let tempx = temp[ind].childs;
      let tem = [...tempx];

      let change_status;
      if (status == 1) {
        change_status = 0;
      } else {
        change_status = 1;
      }

      let obj = {
        ...tem[index],
        status: change_status,
      };
      tem[index] = obj;
      tempx = tem;
      temp[ind]["childs"] = tempx;
      setVarientList(temp);
    }
  };

  // console.log("subCategoryList", subCategoryList);

  // PRODUCT CREATE
  const submitHandle = () => {
    let check;

    if (productInformation?.length > 0) {
      productInformation?.map((item, ind) => {
        check = Object.values(item).some((x) => x === " " || x === "");
      });
    }

    if (
      primaryCategoryId?.length == 0 ||
      secondaryCategoryId?.length == 0 ||
      categoryID?.length == 0 ||
      name?.length == 0 ||
      gendor?.length == 0 ||
      country?.length == 0 ||
      productPackageSize?.length == 0 ||
      mrp?.length == 0 ||
      hsnCode?.length == 0 ||
      productDescription?.length == 0 ||
      tagVal?.length == 0 ||
      tagEventVal?.length == 0 ||
      tagIconVal?.length == 0 ||
      decorationMethod?.length == 0 ||
      productImg?.length == 0 ||
      // morkupImg?.length == 0 ||
      // otherProInfo?.length == 0 ||
      check == true
    ) {
      setPrimaryCategoryIdErr(true);
      setSecondaryCategoryIdErr(true);
      setGendorErr(true);
      setCategoryIDErr(true);
      setNameErr(true);
      setCountryErr(true);
      setProductDescriptionErr(true);
      setProductPackageSizeErr(true);
      setMrpErr(true);
      setHsnCodeErr(true);
      setTagErr(true);
      setTagEventErr(true);
      setTagIconErr(true);
      setDecorationMethodErr(true);
      setProductImgErr(true);
      // setMorkupImgErr(true);
      // setOtherProInfoErr(true);
      setProductInformationErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("primary_category_id", primaryCategoryId);
      formdata.append("secondary_category_id", secondaryCategoryId);
      formdata.append("category_id", categoryID);
      formdata.append("name", name);
      formdata.append("product_for", gendor);
      formdata.append("country_origin", country);
      formdata.append("package_size", productPackageSize);
      formdata.append("mrp", mrp);
      formdata.append("hsn_id", hsnCode);
      formdata.append("description", productDescription);

      let tag_temp = [...tags, ...tagsEvent, ...tagsIcon];

      tag_temp?.map((item, ind) => {
        formdata.append(`tag_ids[${ind}]`, item);
      });

      decorativeID?.map((item, ind) => {
        formdata.append(`decoration_ids[${ind}]`, item);
      });

      if (productInformation?.length > 0) {
        productInformation?.map((item, ind) => {
          formdata.append(`attribute_varients[${ind}][attribute_id]`, item?.id);
          formdata.append(`attribute_varients[${ind}][value]`, item?.value);
          // formdata.append(`attribute_id[${ind}]`, item?.id);
          // formdata.append(`value[${ind}]`, item?.value);
        });
      } else {
        formdata.append(`attribute_varients[0]`, "");
      }

      let smallimageArr = [];

      smallimage?.map((item, ind) => {
        if (item?.image?.name) {
          smallimageArr.push(item);
        }
      });

      if (smallimage?.length > 0) {
        smallimage?.map((item, ind) => {
          formdata.append(
            `product_images[${ind}][id]`,
            item?.id ? item?.id : ""
          );
          formdata.append(
            `product_images[${ind}][image]`,
            item?.image?.name ? item?.image : ""
          );
          formdata.append(`product_images[${ind}][description]`, "");
          formdata.append(
            `product_images[${ind}][is_default]`,
            item?.is_default
          );
        });
      }

      let smallMockimageArr = [];

      smallMockimage?.map((item, ind) => {
        if (item?.image?.name) {
          smallMockimageArr.push(item);
        }
      });

      if (smallMockimage?.length > 0) {
        smallMockimage?.map((item, ind) => {
          formdata.append(
            `morkup_images[${ind}][id]`,
            item?.id ? item?.id : ""
          );
          formdata.append(
            `morkup_images[${ind}][image]`,
            item?.image?.name ? item?.image : ""
          );
          formdata.append(`morkup_images[${ind}][description]`, "");
          formdata.append(
            `morkup_images[${ind}][is_default]`,
            item?.is_default
          );
        });
      }

      // 14-09-2024

      // formdata.append("product_id", viewData?.id);

      // if (colorsID?.length > 0) {
      //   colorsID?.map((item, ind) => {
      //     formdata.append(`master_varients[colors][${ind}]`, item?.id);
      //   });
      // }

      // if (sizes?.length > 0) {
      //   sizes?.map((item, ind) => {
      //     formdata.append(`master_varients[sizes][${ind}]`, item?.value);
      //   });
      // }

      // if (materials?.length > 0) {
      //   materials?.map((item, ind) => {
      //     formdata.append(`master_varients[materials][${ind}]`, item?.value);
      //   });
      // }

      // if (styles?.length > 0) {
      //   styles?.map((item, ind) => {
      //     formdata.append(`master_varients[styles][${ind}]`, item?.value);
      //   });
      // }

      // let colorsDataArr = [];

      // colorsData?.map((item, ind) => {
      //   if (item?.image?.name) {
      //     colorsDataArr.push(item);
      //   }

      //   formdata.append(`color_images[${ind}][color_id]`, item?.color_id);
      //   formdata.append(
      //     `color_images[${ind}][image]`,
      //     item?.image?.name ? item?.image : ""
      //   );
      //   formdata.append(`color_images[${ind}][description]`, "");
      //   formdata.append(
      //     `color_images[${ind}][id]`,
      //     item?.image_url ? item?.id : ""
      //   );
      // });

      // if (varientsList?.length > 0) {
      //   varientsList?.map((item, ind) => {
      //     formdata.append(
      //       `product_skus[${ind}][parent_sku]`,
      //       item?.parent_name
      //     );
      //     formdata.append(`product_skus[${ind}][status]`, item?.status);
      //     item?.childs?.map((child, inx) => {
      //       formdata.append(
      //         `product_skus[${ind}][child_sku][${inx}][color_id]`,
      //         child?.color_id
      //       );
      //       formdata.append(
      //         `product_skus[${ind}][child_sku][${inx}][sku_name]`,
      //         child?.sku_name
      //       );
      //       formdata.append(
      //         `product_skus[${ind}][child_sku][${inx}][sku_code]`,
      //         child?.sku_code
      //       );
      //       formdata.append(
      //         `product_skus[${ind}][child_sku][${inx}][status]`,
      //         child?.status
      //       );
      //     });
      //   });
      // }

      dispatch(saveLoader(true));
      setBtn(true);
      productUpdateApi({ payload: formdata, id: viewData?.id })
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          setBtn(false);
          setSubmitBtn(1);
          setSubmitData(res?.data);
          toast.success(res?.message);
          // console.log("res", res);
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  // COMBINATE GENERATE
  const varientCombination = () => {
    let sizeCheck;
    let colorCheck;
    let styleCheck;
    let materialCheck;
    let check;

    sizes?.map((item, ind) => {
      sizeCheck = Object.values(item).some((x) => x === " " || x === "");
    });

    colorsID?.map((item, ind) => {
      colorCheck = Object.values(item).some((x) => x === " " || x === "");
    });

    materials?.map((item, ind) => {
      materialCheck = Object.values(item).some((x) => x === " " || x === "");
    });

    styles?.map((item, ind) => {
      styleCheck = Object.values(item).some((x) => x === " " || x === "");
    });

    colorsData?.map((item, ind) => {
      check = Object.values(item).some((x) => x === " " || x === "");
    });

    // console.log("sizeCheck", sizeCheck, styleCheck, materialCheck);

    if (
      // (!attributesName?.includes("Varient") && varientsCheck?.length == 0) ||
      (attributesName?.includes("Size") && sizeCheck == true) ||
      (attributesName?.includes("Materials") && materialCheck == true) ||
      (attributesName?.includes("Color") && colorVarient?.length == 0) ||
      (attributesName?.includes("Style") && styleCheck == true) ||
      check == true
    ) {
      // !attributesName?.includes("Varient") && setAddVarientErr(true);
      attributesName?.includes("Size") && setSizesErr(true);
      attributesName?.includes("Color") && setColorVarientErr(true);
      attributesName?.includes("Materials") && setMaterialsErr(true);
      attributesName?.includes("Style") && setStylesErr(true);
      setColorImgErr(true);
    } else {
      let formdata = new FormData();
      let emptyArr = [];
      if (colorsID?.length > 0) {
        colorsID?.map((item, ind) => {
          formdata.append(`master_varients[colors][${ind}]`, item?.id);
        });
      } else {
        formdata.append(`master_varients[colors][${0}]`, emptyArr);
      }

      if (sizes?.length > 0) {
        sizes?.map((item, ind) => {
          formdata.append(`master_varients[sizes][${ind}]`, item?.value);
        });
      } else {
        formdata.append(`master_varients[sizes][${0}]`, emptyArr);
      }

      if (materials?.length > 0) {
        materials?.map((item, ind) => {
          formdata.append(`master_varients[materials][${ind}]`, item?.value);
        });
      } else {
        formdata.append(`master_varients[materials][${0}]`, emptyArr);
      }

      if (styles?.length > 0) {
        styles?.map((item, ind) => {
          formdata.append(`master_varients[styles][${ind}]`, item?.value);
        });
      } else {
        formdata.append(`master_varients[styles][${0}]`, emptyArr);
      }

      formdata.append("product_id", viewData?.id);

      dispatch(saveLoader(true));
      setBtn(true);
      productGenerateVarientApi(formdata)
        .unwrap()
        .then((res) => {
          let attribute_variations = res?.attribute_variations;
          let temp = [];
          let arr = [];

          for (let n in attribute_variations) {
            temp.push(n);
          }
          temp.map((item, ind) => {
            let obj = {
              [item]: item,
              parent_name: item,
              childs: attribute_variations[item],
            };

            arr.push(obj);

            // console.log("obj", obj);
          });

          // console.log("arr", arr);
          let childs = [];
          arr.map((item, ind) => {
            childs.push({
              ...item,
              status: 1,
              childs: item?.childs?.map((el) => {
                var o = Object.assign({}, el);
                o.status = 1;
                return o;
              }),
            });
          });

          setVarientList(childs);
          setVarientShow(true);
          setSubmitBtn(2);
          dispatch(saveLoader(false));
          setBtn(false);
          // console.log("res", res);
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  // console.log("colorsData", colorsData);

  const colorRef = useRef();

  // COMBINATION STORE
  const submitVarient = () => {
    let check;
    colorsData?.map((item, ind) => {
      check = Object.values(item).some((x) => x === " " || x === "");
    });

    if (check) {
      setColorImgErr(true);
      colorRef.current.focus();
    } else {
      setColorImgErr(false);

      let formdata = new FormData();

      let emptyArr = [];
      if (colorsID?.length > 0) {
        colorsID?.map((item, ind) => {
          formdata.append(`master_varients[colors][${ind}]`, item?.id);
        });
      } else {
        // formdata.append(`master_varients[colors][${0}]`, emptyArr);
      }

      if (sizes?.length > 0) {
        sizes?.map((item, ind) => {
          formdata.append(`master_varients[sizes][${ind}]`, item?.value);
        });
      } else {
        // formdata.append(`master_varients[sizes][${0}]`, emptyArr);
      }

      if (materials?.length > 0) {
        materials?.map((item, ind) => {
          formdata.append(`master_varients[materials][${ind}]`, item?.value);
        });
      } else {
        // formdata.append(`master_varients[materials][${0}]`, emptyArr);
      }

      if (styles?.length > 0) {
        styles?.map((item, ind) => {
          formdata.append(`master_varients[styles][${ind}]`, item?.value);
        });
      } else {
        // formdata.append(`master_varients[styles][${0}]`, emptyArr);
      }

      let colorsDataArr = [];

      colorsData?.map((item, ind) => {
        if (item?.image?.name) {
          colorsDataArr.push(item);
        }

        formdata.append(`color_images[${ind}][color_id]`, item?.color_id);
        formdata.append(
          `color_images[${ind}][image]`,
          item?.image?.name ? item?.image : ""
        );
        formdata.append(`color_images[${ind}][description]`, "");
        formdata.append(
          `color_images[${ind}][id]`,
          item?.image_url ? item?.id : ""
        );
      });

      // if (colorsDataArr?.length > 0) {
      //   colorsDataArr?.map((item, ind) => {
      //     formdata.append(`color_images[${ind}][color_id]`, item?.color_id);
      //     formdata.append(`color_images[${ind}][image]`, item?.image);
      //     formdata.append(`color_images[${ind}][description]`, "");
      //     formdata.append(`color_images[${ind}][id]`, item?.id ? item?.id : "");
      //   });
      // }

      varientsList?.map((item, ind) => {
        formdata.append(`product_skus[${ind}][parent_sku]`, item?.parent_name);
        formdata.append(`product_skus[${ind}][status]`, item?.status);
        item?.childs?.map((child, inx) => {
          formdata.append(
            `product_skus[${ind}][child_sku][${inx}][color_id]`,
            child?.color_id
          );
          formdata.append(
            `product_skus[${ind}][child_sku][${inx}][sku_name]`,
            child?.sku_name
          );
          formdata.append(
            `product_skus[${ind}][child_sku][${inx}][sku_code]`,
            child?.sku_code
          );
          formdata.append(
            `product_skus[${ind}][child_sku][${inx}][status]`,
            child?.status
          );
        });
      });

      dispatch(saveLoader(true));
      setBtn(true);
      formdata.append("product_id", viewData?.id);
      productUpdateVarientApi(formdata)
        // productStoreVarientApi(formdata)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          setBtn(false);
          setEdit(true);
          getViewProduct();
          setEdit(!edit);
          // console.log("res", res);
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  // console.log("varientsList", varientsList);
  // console.log("colorsData", parentCategoryList);

  const getViewProduct = () => {
    productViewApi(location?.state?.data?.id)
      .unwrap()
      .then((res) => {
        let data = res?.data;
        setViewData(res?.data);
        setCountry(data?.country_origin);
        setMrp(data?.mrp);
        setProductDescription(data?.description);
        setProductPackageSize(data?.package_size);
        setName(data?.name);
        setGendor(data?.product_for);

        let tagsName = [];
        let tagsID = [];
        let tagsEventName = [];
        let tagsEventID = [];
        let tagsIconName = [];
        let tagsIconID = [];
        let hsnsName = [];
        let hsnsCode = [];
        let DecorationName = [];
        let DecorationID = [];
        let productAttributesName = [];
        let productAttributesID = [];
        let productImagesArr = [];
        let morkupImagesArr = [];
        let colName = [];
        let colID = [];
        let childs = [];
        let colorsDataArr = [];

        if (
          data?.colors?.length == 0 &&
          data?.materials?.length == 0 &&
          data?.styles?.length == 0 &&
          data?.sizes?.length == 0
        ) {
          setAddVariants(true);
        } else {
          setVarientsCheck(23);
        }

        if (data?.colors?.length > 0) {
          setColorVarient(23);
        }

        data?.category?.parentCategory?.map((item, ind) => {
          if (item?.icon) {
            setCatogory(item?.name);
          }
        });

        data?.normalTags?.map((item, ind) => {
          tagsName.push(item?.name);
          tagsID.push(item?.id);
          setTagVal(item?.id);
        });

        data?.eventTags?.map((item, ind) => {
          tagsEventName.push(item?.name);
          tagsEventID.push(item?.id);
          setTagEventVal(item?.id);
        });

        data?.iconTags?.map((item, ind) => {
          tagsIconName.push(item?.name);
          tagsIconID.push(item?.id);
          setTagIconVal(item?.id);
        });

        data?.productDecorations?.map((item, ind) => {
          DecorationName.push(item?.name);
          DecorationID.push(item?.id);
          setDecorationMethod(item?.id);
        });

        hsnsName.push(data?.hsn?.code);
        hsnsCode.push(data?.hsn?.id);

        data?.productAttributes?.map((item, ind) => {
          productAttributesName.push(item?.attribute_name);
          setOtherProInfo(item?.id);
          productAttributesID.push({
            id: item?.attribute_id,
            // attribute_id: item?.attribute_id,
            title: item?.attribute_name,
            value: item?.value,
          });
        });

        data?.productImage?.map((item, ind) => {
          productImagesArr.push({
            ...item,
            image: item?.image_url,
          });
          setProductImg(item?.image_url);
        });

        data?.morkupImage?.map((item, ind) => {
          morkupImagesArr.push({
            ...item,
            image: item?.image_url,
          });
          setMorkupImg(item?.image_url);
        });

        data?.colors?.map((item, ind) => {
          colName.push(item?.name);
        });

        let childData = [];
        data?.product_parent_skus?.map((item, ind) => {
          item?.product_child_skus?.map((chi, inx) => {
            childData.push({
              color_id: chi?.color_id,
              sku_code: chi?.variant_sku,
              sku_name: chi?.variant_name,
              status: chi?.status,
              id: chi?.id,
              variant_sku: item?.variant_sku,
            });
          });

          childs.push({
            [item?.variant_sku]: item?.variant_sku,
            parent_name: item?.variant_sku,
            status: item?.status,
            childs: childData.filter(
              (val) => val.variant_sku == item?.variant_sku
            ),
          });
        });

        data?.colorImages?.map((item, ind) => {
          let finder = data?.colors?.find((col) => col?.id == item?.color_id);
          colorsDataArr.push({
            color_id: finder?.id,
            id: item?.id,
            code: finder?.code,
            hex_code: finder?.hex_code,
            name: finder?.name,
            image: item?.image_url,
            status: finder?.status,
            description: item?.description,
            image_url: item?.image_url,
          });
        });

        // console.log("childs", childs);

        let subCategoryListArr = [];
        let categorys = [];
        let cat = {};

        data?.category?.parentCategory?.map((item, ind) => {
          if (item?.code) {
            setProductSuplierCode(item?.code);
          }

          if (item?.icon) {
            cat.status = item?.status;
            cat.icon = item?.icon;
            cat.id = item?.id;
            cat.name = item?.name;
            setParentCategoryList(item);
          } else {
            categorys.push(item);
          }
        });

        categorys.push({
          icon: data?.category?.icon,
          id: data?.category?.id,
          name: data?.category?.name,
          status: data?.category?.status,
        });

        categorys?.map((item, ind) => {
          let obj = { ...item };
          if (ind == 0) {
            obj.category = cat;
          } else {
            obj.category = categorys[ind - 1];
          }
          subCategoryListArr.push(obj);
        });

        if (data?.sizes?.length > 0) {
          if (data?.sizes?.[0]?.value) {
            let Stemp = [];
            data?.sizes?.map((item, ind) => {
              Stemp.push({
                ...item,
                type: "edit",
              });
            });
            setSizes(Stemp);
          } else {
            setSizes([]);
          }
        }

        if (data?.materials?.length > 0) {
          if (data?.materials?.[0]?.value) {
            let Mtemp = [];
            data?.materials?.map((item, ind) => {
              Mtemp.push({
                ...item,
                type: "edit",
              });
            });
            setMeterials(Mtemp);
          } else {
            setMeterials([]);
          }
        }

        if (data?.styles?.length > 0) {
          if (data?.styles?.[0]?.value) {
            let STtemp = [];
            data?.styles?.map((item, ind) => {
              STtemp.push({
                ...item,
                type: "edit",
              });
            });
            setStyles(STtemp);
          } else {
            setStyles([]);
          }
        }

        if (data?.colors?.length > 0) {
          let Ctemp = [];
          data?.colors?.map((item, ind) => {
            Ctemp.push({
              ...item,
              type: "edit",
            });
          });
          getColors(Ctemp);
          setColorsID(Ctemp);
        } else {
          getColors();
          setColorsID([]);
        }

        let catArrs = data?.category?.parentCategory;

        setPrimaryCategoryId(catArrs?.[0]?.id);
        setSecondaryCategoryId(catArrs?.[1]?.id);
        setCategoryID(data?.category?.id);
        setColorsName(colName);
        setSmallImage(productImagesArr);
        setSmallMockImage(morkupImagesArr);
        setProductInformation(productAttributesID);
        setDynamicAttr(productAttributesName);
        setDecorativeID(DecorationID);
        setDecorativeName(DecorationName);
        setTagsName(tagsName);
        setTags(tagsID);
        setTagsEventName(tagsEventName);
        setTagsEvent(tagsEventID);
        setTagsIconName(tagsIconName);
        setTagsIcon(tagsIconID);
        setHsnName(hsnsName);
        setHsnCode(hsnsCode);
        setColorsData(colorsDataArr);
        setSubCategoryList(subCategoryListArr);

        subCategoryListArr?.map((item, ind) => {
          setCatogoryName((val) => ({
            ...val,
            [item?.category?.id]: item?.name,
          }));
        });

        if (childs?.length > 0) {
          setVarientShow(true);
          setVarientList(childs);
        }

        // data?.category
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getVendorPrice = () => {
    getProductVendorPriceApi(location?.state?.data?.id)
      .unwrap()
      .then((res) => {
        setVendorPriceList(res?.data);
        setVendorPriceLists(res?.productPrice);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (location?.state?.type == "edit") {
      getViewProduct();
      getVendorPrice();
      getDecorPriceList();
    } else {
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    if (productInformation?.length == 0) {
      setOtherProInfo("");
    }
  }, [productInformation]);

  return (
    <div className="dashRightView  p-5 home_section trans">
      <ProductSearchHeader />
      <div className="w-100 top_head_cust py-3">
        <h5 class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary text-capitalize mx-3">
          {viewData?.name}
        </h5>
      </div>
      <div className="d-flex mt-2 ac-jb flex-column flex-md-row w-100">
        <div className="d-flex mt-3 ac-jc">
          <div>
            <p
              className="f4 fs-xs-10 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary"
              role={"button"}
              onClick={() => navigate(-1)}
            >
              <KeyboardBackspaceIcon />
            </p>
          </div>
          <div
            onClick={() => {
              setTab(0);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              tab == 0
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-4`}
          >
            <p
              className={`${
                tab == 0 ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Overview
            </p>
          </div>
          <div
            onClick={() => {
              setTab(1);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              tab == 1
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-4`}
          >
            <p
              className={`${
                tab == 1 ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Product Pricing
            </p>
          </div>
          <div
            onClick={() => {
              setTab(2);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              tab == 2
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-4`}
          >
            <p
              className={`${
                tab == 2 ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Decoration Pricing
            </p>
          </div>
          {tab == 0 && (
            <div className="ms-5">
              {edit && (
                <button
                  className={
                    edit
                      ? "cust_two_E cust-btn f3 primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-18"
                      : "cust_two_S cust-btn f3 primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-18"
                  }
                  onClick={() => setEdit(!edit)}
                >
                  {edit ? "Edit" : "Submit"}
                </button>
              )}
            </div>
          )}
        </div>
        <div className="mt-md-4 mt-3">
          <h5 class="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 w-100 text-dark">
            Currency :
            <span className="primary f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25">
              {" "}
              INR
            </span>
          </h5>
        </div>
      </div>
      {tab == 0 && (
        <LibraryOverViewComp
          addVariants={addVariants}
          edit={edit}
          colorsData={colorsData}
          setColorsData={setColorsData}
          selColors={selColors}
          setSelColors={setSelColors}
          vendor={vendor}
          parentCategoryList={parentCategoryList}
          getCategoryId={getCategoryId}
          subCategoryList={subCategoryList}
          setSubCategoryList={setSubCategoryList}
          subCategoryLists={subCategoryLists}
          setSubCategoryLists={setSubCategoryLists}
          setParentCategory={setParentCategory}
          decorativeList={decorativeList}
          decorativeID={decorativeID}
          setDecorativeID={setDecorativeID}
          decorativeName={decorativeName}
          setDecorativeName={setDecorativeName}
          country={country}
          setCountry={setCountry}
          hsnList={hsnList}
          hsnCode={hsnCode}
          setHsnCode={setHsnCode}
          hsnName={hsnName}
          setHsnName={setHsnName}
          tagsList={tagsList}
          tags={tags}
          setTags={setTags}
          tagsName={tagsName}
          setTagsName={setTagsName}
          tagsEventList={tagsEventList}
          tagsEvent={tagsEvent}
          setTagsEvent={setTagsEvent}
          tagsEventName={tagsEventName}
          setTagsEventName={setTagsEventName}
          tagsIconList={tagsIconList}
          tagsIcon={tagsIcon}
          setTagsIcon={setTagsIcon}
          tagsIconName={tagsIconName}
          setTagsIconName={setTagsIconName}
          colorsList={colorsList}
          colorsID={colorsID}
          setColorsID={setColorsID}
          colorsName={colorsName}
          setColorsName={setColorsName}
          varientCombination={varientCombination}
          varientShow={varientShow}
          sizes={sizes}
          setSizes={setSizes}
          materials={materials}
          setMeterials={setMeterials}
          styles={styles}
          setStyles={setStyles}
          allVarients={allVarients}
          deleteVarient={deleteVarient}
          catogoryName={catogoryName}
          setCatogoryName={setCatogoryName}
          searchValueSubCategory={searchValueSubCategory}
          setSearchSubValueCategory={setSearchSubValueCategory}
          productSuplierCode={productSuplierCode}
          setProductSuplierCode={setProductSuplierCode}
          onSubCategorySelect={onSubCategorySelect}
          categoryID={categoryID}
          setCategoryID={setCategoryID}
          primaryCategoryIdErr={primaryCategoryIdErr}
          secondaryCategoryIdErr={secondaryCategoryIdErr}
          name={name}
          setName={setName}
          mrp={mrp}
          setMrp={setMrp}
          decorationMethod={decorationMethod}
          setDecorationMethod={setDecorationMethod}
          productDescription={productDescription}
          setProductDescription={setProductDescription}
          productPackageSize={productPackageSize}
          setProductPackageSize={setProductPackageSize}
          productPackageSizeErr={productPackageSizeErr}
          setProductPackageSizeErr={setProductPackageSizeErr}
          productDescriptionErr={productDescriptionErr}
          setProductDescriptionErr={setProductDescriptionErr}
          decorationMethodErr={decorationMethodErr}
          setDecorationMethodErr={setDecorationMethodErr}
          mrpErr={mrpErr}
          setMrpErr={setMrpErr}
          nameErr={nameErr}
          setNameErr={setNameErr}
          countryErr={countryErr}
          setCountryErr={setCountryErr}
          categoryIDErr={categoryIDErr}
          setCategoryIDErr={setCategoryIDErr}
          setGendor={setGendor}
          gendor={gendor}
          gendorErr={gendorErr}
          secondaryCategoryId={secondaryCategoryId}
          setSecondaryCategoryId={setSecondaryCategoryId}
          primaryCategoryId={primaryCategoryId}
          setPrimaryCategoryId={setPrimaryCategoryId}
          submitHandle={submitHandle}
          attributesList={attributesList}
          productInformation={productInformation}
          setProductInformation={setProductInformation}
          varientsList={varientsList}
          setVarientList={setVarientList}
          submitVarient={submitVarient}
          setTagVal={setTagVal}
          tagVal={tagVal}
          tagErr={tagErr}
          setTagEventVal={setTagEventVal}
          tagEventVal={tagEventVal}
          tagEventErr={tagEventErr}
          setTagIconVal={setTagIconVal}
          tagIconVal={tagIconVal}
          tagIconErr={tagIconErr}
          hsnCodeErr={hsnCodeErr}
          btn={btn}
          submitBtn={submitBtn}
          catogory={catogory}
          setCatogory={setCatogory}
          listAttributes={listAttributes}
          setListAttributes={setListAttributes}
          dynamicAttr={dynamicAttr}
          setDynamicAttr={setDynamicAttr}
          viewData={viewData}
          setSmallImage={setSmallImage}
          setSmallMockImage={setSmallMockImage}
          smallimage={smallimage}
          smallMockimage={smallMockimage}
          productImg={productImg}
          setProductImg={setProductImg}
          productImgErr={productImgErr}
          setProductImgErr={setProductImgErr}
          morkupImg={morkupImg}
          setMorkupImg={setMorkupImg}
          morkupImgErr={morkupImgErr}
          setMorkupImgErr={setMorkupImgErr}
          colorsImg={colorsImg}
          setColorsImg={setColorsImg}
          colorImgErr={colorImgErr}
          setColorImgErr={setColorImgErr}
          getViewProduct={getViewProduct}
          deleteImg={""}
          otherProInfoErr={otherProInfoErr}
          productInformationErr={productInformationErr}
          setOtherProInfo={setOtherProInfo}
          otherProInfo={otherProInfo}
          stylesErr={stylesErr}
          setStylesErr={setStylesErr}
          materialsErr={materialsErr}
          setMaterialsErr={setMaterialsErr}
          sizesErr={sizesErr}
          setSizesErr={setSizesErr}
          colosErr={colosErr}
          setColosErr={setColosErr}
          setAttributesName={setAttributesName}
          attributesName={attributesName}
          colorVarientErr={colorVarientErr}
          setColorVarientErr={setColorVarientErr}
          setColorVarient={setColorVarient}
          colorVarient={colorVarient}
          addVarientErr={addVarientErr}
          setAddVarientErr={setAddVarientErr}
          varientsCheck={varientsCheck}
          setVarientsCheck={setVarientsCheck}
          colorRef={colorRef}
        />
      )}
      {tab == 1 && (
        <LibraryVendorPricingComp
          vendorPriceList={vendorPriceList}
          viewData={viewData}
          getVendorPrice={getVendorPrice}
          vendorPriceLists={vendorPriceLists}
        />
      )}
      {tab == 2 && (
        <DecorativePricing
          decorPriceList={decorPriceList}
          viewData={viewData}
          getDecorPriceList={getDecorPriceList}
        />
      )}
    </div>
  );
};

export default ProductLibraryDetailsScreen;
