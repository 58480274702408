// @ts-nocheck
import { Delete } from "@mui/icons-material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { editIcon, toggleOff, toggleOn, viewIcon } from "../../assets/img";
import { customerDecoList } from "../../redux/api/DummyJson";
import DOMPurify from "dompurify";

const  BannerManagementList = ({ list, change }) => {
  const navigate = useNavigate();
 console.log(list,"list")
  return (
    <>
      <div className="mt-5" style={{ width: "100%", overflow: "auto" }}>
        <table className="table-design-border " style={{ width: "100%" }}>
          <thead>
            <tr className="border-bottom w-100">
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  S.No
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Name
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Description
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Images
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Priority
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Status
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Action
                </p>
              </th>
            </tr>
          </thead>
          <tbody>

            {list?.map((item, index) => {
              return (
                <tr  className="border-bottom w-100">
                  <td>
                    {/* <div className="wholeDesignCustomerDeco1 d-flex mt-5 pb-2"> */}
                    <p className=" f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc mw-100px w-200px">
                      {index + 1}
                    </p>
                  </td>
                  <td>
                    <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc mw-100px w-200px">
                      {item?.name.substring(0,50)}..
                    </p>
                  </td>
                  <td>

                    <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc mw-100px w-200px"
                    
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          item?.description.substring(0,50)
                        ),
                      }}
                      > 
                    </p>
                  </td>
                  <td>

                    <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc mw-100px w-200px">
                    <img src={item?.image_url} style={{width:"80px"}}/>
                    </p>
                  </td>
                  <td>

                    <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc mw-100px w-200px" >
                      {item?.priority} 
                    </p>
                  </td>
                  <td>
                  <div className=" d-flex ac-jc " onClick={() => change(item?.id)}>
                    {item?.status === 0 ? (
                      <div>
                        <img src={toggleOff} className="toggleOnDes" />
                      </div>
                    ) : (
                      <div>
                        <img src={toggleOn} className="toggleOnDes" />
                      </div>
                    )}
                  </div> 
                  </td>
                  <td>

                    <div className=" d-flex ac-jc primary1 mw-100px w-200px">
                      <div
                        onClick={() =>
                          navigate("/banner-management-edit", {
                            state: { type: "view", data: item  },
                          })
                        }
                        className="viewBoxDes pointerView p-2 bg-white rounded-2"
                      >
                        <img src={viewIcon} className="viewDes" />
                      </div>
                      <div 
                        onClick={() =>
                          navigate("/banner-management-edit", {
                            state: { type: "edit", data: item },
                          })
                        }
                        className="viewBoxDes pointerView ms-2 p-2 bg-white rounded-2"
                      >
                        <img src={editIcon} className="viewDes" />
                      </div>
                      {/* <div className="viewBoxDes pointerView ms-2 p-2 bg-white rounded-2">
                <Delete />
              </div> */}
                    </div>
                  </td>

                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BannerManagementList;
