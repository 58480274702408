import { CheckBox } from "@mui/icons-material";
import React, { useState } from "react";
import { Col } from "react-bootstrap";

import CancelIcon from '@mui/icons-material/Cancel';

import NavbarHead from "../components/Sub_Layout/NavbarHead";
import { Sales_order, Shipping_bills, bill_total } from "../redux/api/DummyJson";
import { useNavigate } from "react-router-dom";

const BillTotalPage = () => {
     
  const [actionBtn, setActionBtn] = useState(false);
  const onLinkShow = () => {
    setActionBtn(!actionBtn);
  };
  return (
    <div className="dashRightView2 overview_pages overflow-scroll ">
      <NavbarHead />
     
      <div className=" py-5">
        <div className="d-flex as-js gap-2">
          <button className=" bg-lt-blue primary1 border-0 rounded-1 px-3">
            <select className="bg-transparent border-0 py-2 primary1">
              <option value="0">Search for project </option>
              <option value="1">PO</option>
              <option value="2">Merchhq</option>
              <option value="3">Over</option>
              <option value="4">Sales</option>
              {/* <option value="5">Honda</option>
              <option value="6">Jaguar</option>
              <option value="7">Land Rover</option>
              <option value="8">Mercedes</option>
              <option value="9">Mini</option>
              <option value="10">Nissan</option>
              <option value="11">Toyota</option>
              <option value="12">Volvo</option> */}
            </select>
          </button>
          <button className=" bg-lt-blue primary1 border-0 rounded-1 px-2">
            <select className="bg-transparent border-0 py-2 primary1">
              <option value="0">Client Rep</option>
              <option value="10">Shakthi</option>
              <option value="11">Ninos</option>
              <option value="12">Krishna</option>
            </select>
          </button>
          <button className=" bg-lt-blue primary1 border-0 rounded-1 px-2">
            <select className="bg-transparent border-0 py-2 primary1">
              <option value="0">Order Rep</option>
              <option value="11">Delivered</option>
              <option value="12">In Process</option>
            </select>
          </button>
          <button className=" bg-lt-blue primary1 border-0 rounded-1 px-2">
            <select className="bg-transparent border-0 py-2 primary1">
              <option value="0">Stage</option>
              <option value="11">Completed</option>
              <option value="12">Ongoing</option>
            </select>
          </button>
        </div>
      </div>
      
      <div className="">
        <table className="w-100 px-2 pt-4">
          <tr className=" bg-lt-blue rounded px-2">
            <th className="">
              <p className=" f3 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center gray f3">
                S.NO
              </p>
            </th>
            <th className="">
              <p className="f3 d-flex align-items-start fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 text-nowrap text-center gray">
                PO
              </p>
            </th>{" "}
            <th className="">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 text-nowrap text-center gray">
                PO Status
              </p>
            </th>{" "}
            <th className="">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 text-nowrap text-center gray">
                {" "}
                PO Supplier
              </p>
            </th>
            <th className="">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 text-nowrap text-center gray">
                PO Amount
              </p>
            </th>
            <th className="">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 text-nowrap text-center gray">
                Bill
              </p>
            </th>
            <th className="">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 tect-nowrap text-center gray">
                Bill Supplier
              </p>
            </th>
            <th className="">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 text-nowrap text-center gray">
                All Bill Received
              </p>
            </th>
            <th className="">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 text-nowrap text-center gray">
                Amount
              </p>
            </th>
          </tr>
          {bill_total ?.map((item, ind) => {
            return (
              <tr className="mt-4 px-2 vert-top">
                <td className="my-2">
                  <p className=" fs-xs-11 fs-sm-12 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center mt-3  f3">
                    {item?.id}
                  </p>
                </td>
                <td className="my-2">
                  <p className=" fs-xs-11 fs-sm-12 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center mt-3  f3">
                    {item?.po}
                  </p>
                </td>
                <td className="my-2">
                  <p className="fs-xs-11 fs-sm-12 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center mt-3  f3 bg-lt-blue rounded">
                         {item?.po_status}
                  </p>
                </td>{" "}
                <td className="my-2"> 
                    <p className="f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 text-nowrap  text-center">
                      {item?.po_supplier}
                    </p>                
                </td>{" "}
                <td className="my-2">
                <p className="f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 text-nowrap  text-center">
                      {item?.po_amount}
                    </p>
                </td>
                <td className="my-2">
                <p className="f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 text-nowrap  text-center">
                      {item?.bill}
                    </p>                
                    </td>
                <td className="my-2">
                  <p className="f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 text-nowrap text-center  w-90 ">
                    {item?.bill_supplier}
                  </p>
                </td>
                <td className="my-2">
                  <p className="f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 tect-nowrap text-center primary w-90 ">
                  <input className="mx-2" type="checkbox" />
                  </p>
                </td>
                <td className="my-2">
                  <p className="f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-2 text-nowrap text-center w-90 ">
                    {item?.amount}
                  </p>
                </td>
              </tr>
            );
          })}
        </table>
      </div>
      <div className="d-flex ac-jb">
      <div className="bg-lt-blue mt-4 rounded d-inline-block p-3">
        <div className="d-flex gap-5 justify-content-between">
          <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  primary1 f3 pb-2">
            Subtotal(all invoices)
          </p>
          <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  f3 pb-2 align-items-end">
            ₹2970.00
          </p>
        </div>
        <div className="d-flex gap-5 justify-content-between">
          <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  primary1 f3 pb-2">
            Bill Margin Amount
          </p>
          <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  f3 pb-2">
            ₹2970.00
          </p>
        </div>
        <div className="d-flex gap-5 justify-content-between">
          <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 f3 pb-2">
            Billed Margin
          </p>
          <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  f3 pb-2">
            100.00%
          </p>
        </div>
        <div className="d-flex gap-5 justify-content-between">
          <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 f3 pb-2">
            Booked Margin
          </p>
          <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  f3 pb-2 ">
            5.00%
          </p>
        </div>
      </div>
      <div className="me-5">
          <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  f3 pb-2 ">
               Bill Subtotal: ₹5100.00
          </p>
      </div>
      </div>
    </div>
  );
};

export default BillTotalPage;
