import React, { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ErrorIcon from "@mui/icons-material/Error";

const AddHsnPopup = ({
  toggleShowPopup,
  popupType,
  hsnCode,
  setHsnCode,
  sgst,
  setSgst,
  seCgst,
  cgst,
  setIgst,
  igst,
  description,
  setDescription,
  hsnCodeErr,
  setHsnCodeErr,
  sgstErr,
  setSgstErr,
  cgstErr,
  seCgstErr,
  igstErr,
  setIgstErr,
  descriptionErr,
  setDescriptionErr,
  submitHandle,
  btn,
  setUpdate,
  errorStatus,
}) => {
  return (
    <div className="add-category-popup">
      <div>
        <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
          {popupType === "view"
            ? "HSN Setting"
            : popupType === "edit"
            ? "Edit HSN Setting"
            : "Add HSN Setting"}
          <span>
            <button onClick={toggleShowPopup} className="cust-btn">
              <HighlightOffIcon />
            </button>
          </span>
        </p>

        <div className="d-flex ac-jb add-content w-100">
          <div className="w-100 d-flex ac-jb mt-4 flex-wrap">
            <p className="f3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              HSN Code*
            </p>
            <div className="w-100 d-flex w-xs-100 mt-xs-5 position-relative">
              <input
                className="w-100 cust-btn searchFill w-xs-100 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                placeholder="HSN Code"
                value={hsnCode}
                type={"number"}
                onChange={(e) => setHsnCode(e.target.value)}
                disabled={popupType === "view" ? true : false}
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                  if (e.target.value.length !== 10) {
                    e.target.setCustomValidity("invalid Number");
                  } else if (e.target.value.length == 10) {
                    e.target.setCustomValidity("");

                    setHsnCode(e.target.value);
                  }
                }}
                onFocus={(e) =>
                  e.target.addEventListener(
                    "wheel",
                    function (e) {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
                maxlength={8}
              />
            </div>
            {hsnCode?.length == 0 && hsnCodeErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter HSN CODE</p>
              </div>
            )}
          </div>
        </div>
        <div>
          {popupType == "view" ? (
            <div className="d-flex ac-jb add-content w-100 position-relative pb-2">
              <div className="w-30 d-flex ac-jb mt-4 flex-wrap">
                <p className="f3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                  SGST
                </p>
                <div className="w-100 d-flex w-xs-100 mt-xs-5 position-relative">
                  <input
                    className={`w-100 cust-btn searchFill w-xs-100 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 `}
                    placeholder="SGST %"
                    value={sgst && cgst ? sgst : +igst / 2}
                    type={"number"}
                    onChange={(e) => setSgst(e.target.value)}
                    disabled
                  />
                </div>
                {sgst?.length == 0 && sgstErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter SGST</p>
                  </div>
                )}
              </div>
              <div className="w-30 d-flex ac-jb mt-4 flex-wrap">
                <p className="f3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                  CGST
                </p>
                <div className="w-100 d-flex w-xs-100 mt-xs-5 position-relative">
                  <input
                    className={`w-100 cust-btn searchFill w-xs-100 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 `}
                    placeholder="CGST %"
                    value={sgst && cgst ? cgst : +igst / 2}
                    type={"number"}
                    onChange={(e) => seCgst(e.target.value)}
                    disabled
                  />
                </div>
                {cgst?.length == 0 && cgstErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter CGST</p>
                  </div>
                )}
              </div>
              <div className="w-30 d-flex ac-jb mt-4 flex-wrap">
                <p className="f3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                  IGST
                </p>
                <div className="w-100 d-flex w-xs-100 mt-xs-5 position-relative">
                  <input
                    className={`w-100 cust-btn searchFill w-xs-100 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 `}
                    placeholder="IGST %"
                    value={igst ? igst : +sgst + +cgst}
                    type={"number"}
                    onChange={(e) => setIgst(e.target.value)}
                    disabled
                  />
                </div>
                {igst?.length == 0 && igstErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter IGST</p>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="d-flex ac-jb add-content w-100 position-relative pb-2">
              <div className="w-30 d-flex ac-jb mt-4 flex-wrap">
                <p className="f3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                  SGST
                </p>
                <div className="w-100 d-flex w-xs-100 mt-xs-5 position-relative">
                  <input
                    className={`w-100 cust-btn searchFill w-xs-100 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ${
                      igst && "opacity-50"
                    }`}
                    placeholder="SGST %"
                    value={sgst}
                    type={"number"}
                    onChange={(e) => setSgst(e.target.value)}
                    disabled={popupType === "view" ? true : igst ? true : false}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                      if (e.target.value.length !== 10) {
                        e.target.setCustomValidity("invalid Number");
                      } else if (e.target.value.length == 10) {
                        e.target.setCustomValidity("");

                        setSgst(e.target.value);
                      }
                    }}
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    maxlength={2}
                  />
                </div>
                {sgst?.length == 0 && sgstErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter SGST</p>
                  </div>
                )}
              </div>
              <div className="w-30 d-flex ac-jb mt-4 flex-wrap">
                <p className="f3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                  CGST
                </p>
                <div className="w-100 d-flex w-xs-100 mt-xs-5 position-relative">
                  <input
                    className={`w-100 cust-btn searchFill w-xs-100 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ${
                      igst && "opacity-50"
                    }`}
                    placeholder="CGST %"
                    value={cgst}
                    type={"number"}
                    onChange={(e) => seCgst(e.target.value)}
                    disabled={popupType === "view" ? true : igst ? true : false}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                      if (e.target.value.length !== 10) {
                        e.target.setCustomValidity("invalid Number");
                      } else if (e.target.value.length == 10) {
                        e.target.setCustomValidity("");

                        seCgst(e.target.value);
                      }
                    }}
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    maxlength={2}
                  />
                </div>
                {cgst?.length == 0 && cgstErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter CGST</p>
                  </div>
                )}
              </div>
              <div className="w-30 d-flex ac-jb mt-4 flex-wrap">
                <p className="f3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                  IGST
                </p>
                <div className="w-100 d-flex w-xs-100 mt-xs-5 position-relative">
                  <input
                    className={`w-100 cust-btn searchFill w-xs-100 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ${
                      (sgst && "opacity-50") || (cgst && "opacity-50")
                    }`}
                    placeholder="IGST %"
                    value={igst}
                    type={"number"}
                    onChange={(e) => setIgst(e.target.value)}
                    disabled={
                      popupType === "view"
                        ? true
                        : sgst
                        ? true
                        : cgst
                        ? true
                        : false
                    }
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                      if (e.target.value.length !== 10) {
                        e.target.setCustomValidity("invalid Number");
                      } else if (e.target.value.length == 10) {
                        e.target.setCustomValidity("");

                        setIgst(e.target.value);
                      }
                    }}
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    maxlength={2}
                  />
                </div>
                {igst?.length == 0 && igstErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter IGST</p>
                  </div>
                )}
              </div>
            </div>
          )}
          {sgst?.length == 0 &&
            cgst?.length == 0 &&
            igst?.length == 0 &&
            errorStatus && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter SGST & CGST or IGST</p>
              </div>
            )}

          {sgst && !cgst && errorStatus && popupType !== "edit" && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter SGST & CGST or IGST</p>
            </div>
          )}
          {!sgst && cgst && errorStatus && popupType !== "edit" && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter SGST & CGST or IGST</p>
            </div>
          )}

          {sgst && !cgst && popupType === "edit" && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter SGST & CGST or IGST</p>
            </div>
          )}
          {!sgst && cgst && popupType === "edit" && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter SGST & CGST or IGST</p>
            </div>
          )}
        </div>
        <div className="mt-3 w-100">
          <p className="f3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Description
          </p>
          <textarea
            disabled={popupType === "view" ? true : false}
            placeholder="Description"
            className=" editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />
          {description?.length == 0 && descriptionErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Description</p>
            </div>
          )}
        </div>

        <div className="d-flex mt-3 justify-content-end m-2">
          {popupType === "view" ? null : (
            <button
              onClick={submitHandle}
              disabled={btn}
              className={`mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                btn && "opacity-50"
              }`}
            >
              Submit
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddHsnPopup;
