import React, { useState } from "react";
import { product } from "../../assets/img";
import ErrorIcon from "@mui/icons-material/Error";

const VendorProductView = ({ handleClose,setPrice,price,title,setTitle,priceErr,titleErr,imageErr,image,setImages,EditHandelor,status}) => {
  const [fileData, setFileData] = useState([]);

  // upload image event
  const handleChangeUpload = (e) => {
    const { files } = e && e.target;
    if (files) {
      setFileData((prev) => [...prev, files[0]]);
    }
  };
  return (
    <div className="popup-wrap">
      <div className="popup-inner_box">
        <div className="position-relative">
          <h5 className="primary">{status==1?"Edit Proof":"View Proof"}</h5>
          <div className="position-absolute popup_close" onClick={handleClose}>
            <button className="btn">&#x2715;</button>
          </div>
        </div>
        <div className="d-flex w-100 mb-4 mt-3 flex-wrap">
          <div className="col-md-5 col-lg-5 px-1 col-12">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Title
            </p>
            <input
              type="text"
              placeholder="Enter Title"
              // value={"Charles"}
              disabled={status!=1?true:false}
              value={title}
              onChange={(e)=>setTitle(e.target.value)}
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
            {title?.length == 0 && titleErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Type of TiTle</p>
              </div>
            )}
          </div>
          <div className="col-md-5 col-lg-5 px-1 col-12">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Upload Product Images
            </p>
            <input
              style={{ cursor: "pointer" }}
              disabled={status!=1?true:false}
              type="file"
              // multiple={true}
              // onChange={(e) => {
              //   handleChangeUpload(e);
              // }}
              onChange={(e)=>setImages(e.target.files[0])}
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 upload_img"
            />
            {image?.length == 0 && imageErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Upload Image</p>
              </div>
            )}
          </div>
          <div className="col-md-5 col-lg-5 px-1 col-12">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Product Images
            </p>
            <img
              // src={product}
              src={image?.name?URL.createObjectURL(image):image?image:""}
              style={{ width: "100px", height: "100px" }}
              alt="img"
            />
          </div>
          <div className="col-md-5 col-lg-5 px-1 col-12">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Price
            </p>
            <input
              type="number"
              placeholder="Enter Price here.."
              // value={1000}
              disabled={status!=1?true:false}
              value={price}
              onChange={(e)=>setPrice(e.target.value)}
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
            {price?.length == 0 && priceErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Price</p>
              </div>
            )}
          </div>
        </div>
        {status==1&&<div className="d-flex ac-jc">
          <button
            class="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded "
            onClick={() => EditHandelor()}
          >
            Submit
          </button>
        </div>}
      </div>
    </div>
  );
};

export default VendorProductView;
