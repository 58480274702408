import { Search } from "@mui/icons-material";
import React from "react";
import CachedIcon from "@mui/icons-material/Cached";
import { searchIcon } from "../../assets/img";

const DecorativeDropdownTap = ({
  location,
  decorative2,
  getdecorate,
  searchValue,
  setSearchValue,
  pageRow,
}) => {
  // console.log(location)
  return (
    <div className="mt-5 banner-left">
      <div className="d-flex ac-jb flex-column flex-md-row">
        <div className="d-flex search_new_design mx-2">
          <img src={searchIcon} className="searchiConImg" />
          <input
            className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 w-100 p-1"
            placeholder="Search"
            onChange={(e) => getdecorate(e.target.value, pageRow)}
            value={searchValue}
          />
        </div>
        <div className="d-flex ac-jc gap-3">
          <button
            onClick={() => getdecorate("", "")}
            className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
          >
            <CachedIcon />
          </button>
        </div>
      </div>
      <div className="mt-md-3  mt-4">
        <p class=" f2 primary1 mb-0">
          {/* Your Hourglass Essentials Pvt. Ltd. database contains products that
          you or your co-workers have added directly into commonsku. These
          products will often be Hourglass Essentials Pvt. Ltd.'s go-to
          products, preferred supplier products, or local decorators that may
          not be on commonsku otherwise. */}
        </p>
        <p class=" f2 primary1 mb-0">
          {/* Enter some criteria to search for products. */}
        </p>
      </div>
    </div>
  );
};

export default DecorativeDropdownTap;
