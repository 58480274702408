import React, { useEffect, useRef, useState } from "react";
import { product, uploadIcon } from "../../assets/img";
import { useLocation, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { productDetailImg } from "../../redux/api/constants";
import ImageCroper from "../Popup/ImageCroper";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import { toast } from "react-hot-toast";

const ImageUplodeComp = ({
  vendor,
  vendor2,
  selColors,
  setSmallImage,
  setSmallMockImage,
  smallimage,
  smallMockimage,
  colorsData,
  setColorsData,

  productImg,
  setProductImg,
  productImgErr,
  setProductImgErr,
  morkupImg,
  setMorkupImg,
  morkupImgErr,
  setMorkupImgErr,

  colorsImg,
  setColorsImg,
  colorImgErr,
  setColorImgErr,
  viewData,
  colorRep,
  submitData,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [colorImg, setColorImg] = useState(null);
  const [bigImage, setBigImage] = useState({
    image: "",
    ind: "",
    is_default: 0,
  });
  const [mockImage, setMockImage] = useState({
    image: "",
    ind: "",
    is_default: 0,
  });
  const [fistShow, setFirstShow] = useState(false);
  const [fistShows, setFirstShows] = useState(false);
  // console.log(vendor);
  const [logo, setLogo] = useState({});
  const [logo1, setLogo1] = useState({});
  const [textShow, setTextShow] = useState(true);
  const [textShows, setTextShows] = useState(true);
  const [indexs, setIndexs] = useState(0);
  // console.log(vendor2);

  const proImgRef = useRef();
  const morkupImgRef = useRef();
  const colorImgRef = useRef();
  const [proIndex, setProIndex] = useState(0);
  const [morkupIndex, setMorkupIndex] = useState(0);
  const [colorIndex, setColorIndex] = useState(0);

  const onColorImgUpload = (img) => {
    // console.log("img", img);
    let temp = [...colorsData];
    temp[indexs] = {
      ...temp[indexs],
      image: img,
    };
    setColorsData(temp);
    setColorImg(img);
    setColorsImg(img);
  };

  const imageSelector = (item, ind, type, is_default) => {
    if (type == "mock") {
      setMockImage({ image: item, ind: ind, is_default: is_default });
    } else if (type == "product") {
      setBigImage({ image: item, ind: ind, is_default: is_default });
    }
  };

  const removeImg = (ind, type, is_default) => {
    // console.log("is_default", is_default);
    if (!submitData?.id) {
      if (is_default == 1) {
        toast.error("Selected Image is Set Defaulted");
      } else {
        if (type == "product") {
          let temp = [...smallimage];

          temp.splice(ind, 1);
          setSmallImage(temp);
          if (temp?.length - 1 === 0) {
            setBigImage({
              image: temp[temp.length - 1]?.image,
              ind: temp.length - 1,
              is_default: temp[temp.length - 1]?.is_default,
            });
          } else {
            setBigImage({
              image: temp[ind - 1]?.image,
              ind: ind - 1,
              is_default: temp[ind - 1]?.is_default,
            });
          }
        } else if (type == "mock") {
          let temps = [...smallMockimage];

          temps.splice(ind, 1);
          setSmallMockImage(temps);
          if (temps?.length - 1 === 0) {
            setMockImage({
              image: temps[temps.length - 1]?.image,
              ind: temps.length - 1,
              is_default: temps[temps.length - 1]?.is_default,
            });
          } else {
            setMockImage({
              image: temps[ind - 1]?.image,
              ind: ind - 1,
              is_default: temps[ind - 1]?.is_default,
            });
          }
        }
      }
    }
  };

  const getView = () => {
    let temp = [...viewData?.productImage];
    let temps = [...viewData?.morkupImage];

    setBigImage({
      image: temp[temp.length - 1]?.image_url,
      ind: temp.length - 1,
    });

    setMockImage({
      image: temps[temps.length - 1]?.image_url,
      ind: temps.length - 1,
    });
    setTextShow(false);
    setFirstShow(true);
    setTextShows(false);
    setFirstShows(true);
  };

  // PRODUCT IMAGE
  const handleProductImg = (event, ind) => {
    let temp = [...smallimage];

    if (event?.name) {
      temp[ind] = {
        ...temp[ind],
        image: event,
        is_default: 0,
      };

      setSmallImage(temp);
      setBigImage({
        image: temp[temp.length - 1]?.image,
        ind: temp.length - 1,
        is_default: 0,
      });
      setProductImg(event);
      setFirstShow(true);
      setTextShow(false);
    }
  };

  // MORKUP IMAGE
  const handleMorkupImg = (event, ind) => {
    let temp = [...smallMockimage];

    if (event?.name) {
      temp[ind] = {
        ...temp[ind],
        image: event,
        is_default: 0,
      };

      setSmallMockImage(temp);
      setMockImage({
        image: temp[temp.length - 1]?.image,
        ind: temp.length - 1,
        is_default: 0,
      });
      setMorkupImg(event);
      setFirstShows(true);
      setTextShows(false);
    }
  };

  // COLOR IMAGE
  const handleColorImg = (event, ind) => {
    let temp = [...colorsData];

    if (event?.name) {
      temp[ind] = {
        ...temp[ind],
        image: event,
      };

      setColorsData(temp);
    }
  };

  const isDeFaultChanger = (item, type) => {
    if (!submitData?.id) {
      if (type == "mock") {
        let temp = [...smallMockimage];
        let final = [];

        for (let i = 0; i < temp.length; i++) {
          if (item?.ind == i) {
            let obj = (temp[i] = {
              ...temp[i],
              is_default: 1,
            });
            final.push(obj);
          } else {
            let obj = (temp[i] = {
              ...temp[i],
              is_default: 0,
            });
            final.push(obj);
          }

          setMockImage({ ...mockImage, is_default: 1 });

          setSmallMockImage(final);
        }
      } else {
        let temp = [...smallimage];
        let final = [];

        for (let i = 0; i < temp.length; i++) {
          if (item?.ind == i) {
            let obj = (temp[i] = {
              ...temp[i],
              is_default: 1,
            });
            final.push(obj);
          } else {
            let obj = (temp[i] = {
              ...temp[i],
              is_default: 0,
            });
            final.push(obj);
          }

          setBigImage({ ...bigImage, is_default: 1 });

          setSmallImage(final);
        }
      }

      toast.success("Default Image Set");
    }
  };

  useEffect(() => {
    if (location?.state?.data?.id && viewData?.id) {
      getView();
    }
  }, [viewData]);

  // console.log("smallimage", smallimage);
  // console.log("smallMockimage", smallMockimage);
  // console.log("mockImage", mockImage);

  return (
    <>
      <div className="d-flex gap-3 ac-jc w-100 res-img">
        <div>
          <p class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
            Product Image (185 x 205)
          </p>
          <div>
            <div className="">
              <div
                className="position-relative"
                onClick={() => {
                  if (!fistShow) {
                    setProIndex(0);
                    proImgRef?.current?.click();
                  }
                }}
              >
                <img
                  className={"img_up_cont"}
                  src={
                    bigImage?.image?.name
                      ? URL.createObjectURL(bigImage?.image)
                      : bigImage?.image
                      ? bigImage?.image
                      : uploadIcon
                  }
                />
                {!textShow && (
                  <div>
                    <ul
                      className={`${"image-hide"}  edit-image image-show d-flex justify-content-between border_img`}
                    >
                      <li
                        className="set-default-image"
                        role={"button"}
                        onClick={() => isDeFaultChanger(bigImage, "product")}
                      >
                        Set&nbsp;Default
                      </li>
                      {smallimage?.length > 1 && (
                        <li
                          className="set-default-image"
                          onClick={() =>
                            removeImg(
                              bigImage?.ind,
                              "product",
                              bigImage?.is_default
                            )
                          }
                          role="button"
                        >
                          Remove
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
              <div className="d-flex ac-jc gap-3 w-100">
                {fistShow && (
                  <div
                    class=" mt-3 gap-3 d-flex ac-jc pb-4 ps-5"
                    style={{ width: "300px", overflow: "scroll" }}
                  >
                    {smallimage?.map((item, ind) => {
                      return (
                        <div className="gap-3 d-flex ac-j w-100">
                          <div
                            class="add_img d-flex ac-jc"
                            onClick={() =>
                              imageSelector(
                                item?.image,
                                ind,
                                "product",
                                item?.is_default
                              )
                            }
                          >
                            <img
                              src={
                                item?.image?.name
                                  ? URL.createObjectURL(item?.image)
                                  : item?.image
                              }
                              class="cp add_img object-fit-fill"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {smallimage?.length > 0 && (
                  <label
                    className={`add_img d-flex ac-jc cp f3 primary fs-3 ${
                      smallimage?.length == 0 && "mt-3"
                    }`}
                    onClick={() => {
                      setProIndex(smallimage?.length);
                      proImgRef?.current?.click();
                      //  dualToggele("product")
                    }}
                  >
                    {" "}
                    +
                  </label>
                )}
              </div>
            </div>
          </div>
          {productImg?.length == 0 && productImgErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Upload Product Image</p>
            </div>
          )}
        </div>

        <div>
          <p class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
            Mockup Image
          </p>

          <div>
            {" "}
            <div className="">
              <div
                className="position-relative"
                onClick={() => {
                  if (!fistShows) {
                    setMorkupIndex(0);
                    morkupImgRef?.current?.click();
                  }
                }}
              >
                <img
                  className={"img_up_cont"}
                  src={
                    mockImage?.image?.name
                      ? URL.createObjectURL(mockImage?.image)
                      : mockImage?.image
                      ? mockImage?.image
                      : uploadIcon
                  }
                />
                {!textShows && (
                  <div>
                    <ul
                      className={`${"image-hide"}  edit-image image-show d-flex justify-content-between border_img`}
                    >
                      <li
                        className="set-default-image"
                        role={"button"}
                        onClick={() => isDeFaultChanger(mockImage, "mock")}
                      >
                        Set&nbsp;Default
                      </li>
                      {smallMockimage?.length > 1 && (
                        <li
                          className="set-default-image"
                          onClick={() =>
                            removeImg(
                              mockImage?.ind,
                              "mock",
                              mockImage?.is_default
                            )
                          }
                        >
                          Remove
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
              <div className="d-flex ac-jc gap-3 w-100">
                {fistShows && (
                  <div
                    class=" mt-3 gap-3 d-flex ac-jc pb-4 ps-5"
                    style={{ width: "300px", overflow: "scroll" }}
                  >
                    {smallMockimage?.map((item, ind) => {
                      return (
                        <div className="gap-3 d-flex ac-j w-100">
                          <div
                            class="add_img d-flex ac-jc"
                            onClick={() =>
                              imageSelector(
                                item?.image,
                                ind,
                                "mock",
                                item?.is_default
                              )
                            }
                          >
                            {" "}
                            <img
                              src={
                                item?.image?.name
                                  ? URL.createObjectURL(item?.image)
                                  : item?.image
                              }
                              class="cp add_img object-fit-fill"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {smallMockimage?.length > 0 && (
                  <label
                    className={`add_img d-flex ac-jc cp f3 primary fs-3 ${
                      smallMockimage?.length == 0 && "mt-3"
                    }`}
                    onClick={() => {
                      setMorkupIndex(smallMockimage?.length);
                      morkupImgRef?.current?.click();
                    }}
                  >
                    +
                  </label>
                )}
              </div>
            </div>
          </div>
          {morkupImg?.length == 0 && morkupImgErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Upload Mockup Image</p>
            </div>
          )}
        </div>

        {(vendor === "John Rolph" || vendor === "Jake Adams") && (
          <div>
            <p class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
              Decoration Image
            </p>
            <div className="file-upload" style={{ cursor: "pointer" }}>
              <div className="file-icon d-flex ac-jc">
                <div className="d-flex flex-column ac-jc">
                  <label className="upload-btn">
                    <img
                      className={
                        logo?.name
                          ? "placeholder_icon1 object-fit-contain"
                          : "placeholder_icon object-fit-contain"
                      }
                      src={
                        logo?.name
                          ? window.URL.createObjectURL(logo)
                          : uploadIcon
                      }
                      alt="logo"
                    />
                    {textShow ? (
                      <p className="fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 px-5 text-center">
                        Drag and drop to upload or Click here to upload
                      </p>
                    ) : null}
                    <input
                      onChange={(e) => {
                        setLogo(e.target.files[0]);
                        setTextShow(false);
                      }}
                      className="d-none"
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                    />
                  </label>
                  {/* <p class="f2 text-dark mb-2 mb-lg-3 text-center fs-13 fs-md-14 fs-lg-15 fs-xl-16 w-70">Drag and drop to upload or Click here to upload</p> */}
                </div>
              </div>
            </div>
            <div className="btn-w-cust-cont mt-4">
              <button
                class="btn-w-cust cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded w-100"
                onClick={() => navigate(-1)}
              >
                Save
              </button>
            </div>
          </div>
        )}
        {colorsData?.length > 0 && (
          <div>
            <p class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
              Color Image (312 x 312)
            </p>
            <div
              className="d-flex gap-3 mt-3 ac-jc flex-wrap"
              style={{ width: "350px" }}
              ref={colorRep}
            >
              {colorsData?.map((item, ind) => {
                return (
                  <div className="d-flex flex-column ac-jc">
                    <div>
                      {item?.image ? (
                        <div className="position-relative for_hover">
                          <img
                            alt="icon"
                            src={
                              item?.image?.name
                                ? URL.createObjectURL(item?.image)
                                : item?.image
                            }
                            className="add_img object-fit-fill"
                            onClick={() => {
                              setColorIndex(ind);
                              colorImgRef.current.click();
                            }}
                          />
                          {/* <div className="d-flex w-100 justify-content-between position-absolute in_hover text-white">
                            <FileDownloadOutlinedIcon />
                            <ClearOutlinedIcon />
                          </div> */}
                        </div>
                      ) : (
                        <label
                          className="add_img d-flex ac-jc cp f3 primary fs-3"
                          onClick={() => {
                            setColorIndex(ind);
                            colorImgRef.current.click();
                          }}
                        >
                          +
                        </label>
                      )}
                      <p className="f3" style={{ color: item?.hex_code }}>
                        {item?.name}
                      </p>
                    </div>
                    {item?.image?.length == 0 && colorImgErr && (
                      <div className="d-flex gap-1 mt-2">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">Upload Color Image</p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>

      <input
        type="file"
        accept=".pdf,.doc,.jpeg,.docx,.png,.jpg"
        onChange={(e) => handleProductImg(e.target.files[0], proIndex)}
        className="d-none"
        ref={proImgRef}
        disabled={submitData?.id ? true : false}
      />

      <input
        type="file"
        accept=".pdf,.doc,.jpeg,.docx,.png,.jpg"
        onChange={(e) => handleMorkupImg(e.target.files[0], morkupIndex)}
        className="d-none"
        ref={morkupImgRef}
        disabled={submitData?.id ? true : false}
      />

      <input
        type="file"
        accept=".pdf,.doc,.jpeg,.docx,.png,.jpg"
        onChange={(e) => handleColorImg(e.target.files[0], colorIndex)}
        className="d-none"
        ref={colorImgRef}
      />
    </>
  );
};

export default ImageUplodeComp;
