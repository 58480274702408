import { Search } from "@mui/icons-material";
import NavBar from "../components/NavBar";
import manImage from "../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import {
  noLogo,
  searchIcon,
  toggleOff,
  toggleOn,
  uploadIcon,
} from "../assets/img";
import { adminData } from "../redux/api/DummyJson";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useEffect, useRef, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ProfileNavBar from "../components/ProfileNavBar";
import { useNavigate } from "react-router-dom";
import { editIcon, viewIcon } from "../assets/img";
import UploadIcon from "@mui/icons-material/Upload";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import ErrorIcon from "@mui/icons-material/Error";

import {
  useAdminsListMutation,
  useAdminExportsMutation,
  useLazyAdminsQuery,
  useLazyAdminStatusQuery,
  useDownloadSampleExcelsMutation,
  useDownloadAdminProfileMutation,
} from "../redux/api/api";
import GetAppIcon from "@mui/icons-material/GetApp";
import { toast } from "react-hot-toast";
import { BASE_URL, URL } from "../redux/api/constants";
import axios from "axios";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";

const AdminPanel = () => {
  const [enable, setEnable] = useState(false);
  const [importList, setImportList] = useState(false);
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageRow, setPageRow] = useState(10);
  const [page, setPage] = useState(1);
  const [pagenation, setPagination] = useState({});
  const fileRef = useRef();
  const [file, setFile] = useState("");
  // console.log(file?.type);
  const [btn, setBtn] = useState(false);
  const [uploadImg, setUploadImg] = useState("");

  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState(false);

  const showPopupHander = () => {
    setImportList(false);
  };

  // RTK QUERY
  const [listAdmin] = useLazyAdminsQuery();
  const [listAdminApi] = useAdminsListMutation();
  const [status] = useLazyAdminStatusQuery();
  const [exportsApi] = useAdminExportsMutation();
  const [importApi] = useDownloadSampleExcelsMutation();
  const [uploadApi] = useDownloadAdminProfileMutation();

  // GET LIST
  const getList = (event, row) => {
    let formdata = new FormData();
    let params = `?page=${page}`;

    setSearchValue(event);

    if (event?.length > 0) {
      formdata.append("search", event);
    }

    if (row) {
      setPageRow(row);
      formdata.append("rows", row);
    }

    listAdminApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.lists);
          setPagination(res?.pagination_meta);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // CHANGE STATUS
  const change = (id) => {
    status(id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          toast.success(res?.data?.message || res?.message);
          getList();
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  useEffect(() => {
    getList();
  }, [page]);

  // EXPORT EXCEL
  const getAdminExports = () => {
    let formdata = new FormData();
    formdata.append("type", 1);
    exportsApi(formdata)
      .unwrap()
      .then((res) => {
        if (res?.download_link) {
          const link = document.createElement("a");
          link.href = res?.download_link;
          link.setAttribute("download", "");
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    // const headers = {
    //   "Content-Type": "blob",
    //   "Access-Control-Allow-Origin": "*",
    //   Authorization: `Bearer ${localStorage.getItem("token")}`,
    // };

    // const config = {
    //   method: "get",
    //   url: BASE_URL + URL.ADMIN_EXPORTS,
    //   responseType: "arraybuffer",
    //   headers,
    // };

    // try {
    //   const response = await axios(config);
    //   const url = URL.createObjectURL(new Blob([response.data?.download_link]));
    //   const link = document.createElement("a");
    //   link.href = url;
    //   link.setAttribute("download", "Admins.csv");
    //   document.body.appendChild(link);
    //   link.click();
    // } catch (error) {
    //   console.log("error", error);
    // }
  };

  // IMPORT EXCEL
  const getAdminImports = () => {
    let formdata = new FormData();
    formdata.append("type", 4);
    importApi(formdata)
      .unwrap()
      .then((res) => {
        if (res?.download_link) {
          const link = document.createElement("a");
          link.href = res?.download_link;
          link.setAttribute("download", "");
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
        }
        console.log("res", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // UPLOAD EXCEL

  const uploadExcel = () => {
    if (image?.length == 0) {
      setImageErr(true);
    } else {
      let formdata = new FormData();
      if (image?.name) {
        formdata.append("file", image);
      }

      setBtn(true);
      dispatch(saveLoader(true));
      uploadApi(formdata)
        .unwrap()
        .then((res) => {
          toast.success("Uploaded Successfully");
          setBtn(false);
          dispatch(saveLoader(false));
          console.log("reslist", res);
          setImage("");
          setImportList(false);
          setShowPopup(false);
        })
        .catch((err) => {
          setBtn(false);
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    }
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      {importList && (
        <div className="modal-popup">
          <div onClick={showPopupHander} className="back-close" />
          <div className="center-content p-4">
            <div className=" d-flex justify-content-end">
              <span>
                <button
                  onClick={() => {
                    showPopupHander();
                    setImage("");
                    setImageErr(false);
                  }}
                  className="cust-btn"
                >
                  <HighlightOffIcon />
                </button>
              </span>
            </div>
            <div>
              <button
                className={`cust-btn addbtn  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded `}
                onClick={() => getAdminImports()}
                // disabled={page == pagenation?.last_page ? true : false}
              >
                Sample Download
              </button>
            </div>
            <p className="d-flex ac-jc f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Import Admin List
            </p>
            <div className="pointerView w-100 mt-4 ac-jc">
              <div
                onClick={() => fileRef.current.click()}
                className="w-100 d-flex ac-jc"
              >
                {file === "" ? (
                  <img
                    src={uploadIcon}
                    onChange={(e) => setImage(e.target.files[0])}
                    className="mailImg"
                  />
                ) : (
                  <iframe
                    onClick={() => fileRef.current.click()}
                    src={file}
                    className="importExcel"
                  />
                )}
              </div>
              {file === "" && (
                <div
                  onClick={() => {
                    fileRef.current.click();
                  }}
                  className="w-100 d-flex ac-jc"
                >
                  <p className="primary1 w-100 f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 mt-1 d-flex ac-jc">
                    +Import List
                  </p>
                </div>
              )}
              {image?.length == 0 && imageErr && (
                <div className="d-flex ac-jc gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Upload File</p>
                </div>
              )}
              <div className="d-flex ac-jc">
                <button
                  onClick={() => uploadExcel()}
                  disabled={btn}
                  className={`
                  cust-btn addbtn mt-sm-4 ms-1 mt-4 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                    btn && "opacity-50"
                  }`}
                >
                  Submit
                </button>
              </div>
            </div>
            <input
              type="file"
              accept=".xlsx,"
              onChange={(e) => {
                setImage(e.target.files[0]);
              }}
              className="d-none"
              ref={fileRef}
            />
          </div>
        </div>
      )}
      <ProductSearchHeader />
      <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5">
        Profile List
      </p>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4 align-items-center">
        <div className="d-flex ">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select
            className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary"
            onChange={(e) => getList(searchValue, e.target.value)}
            value={pageRow}
          >
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="d-flex flex-md-row flex-column">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont mt-4 me-3">
            Total Count : {pagenation?.total}
          </p>
          <div
            onClick={() => setImportList(!importList)}
            className="pointerView d-flex mt-4 me-3"
          >
            <UploadIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Import
            </p>
          </div>
          <div
            className="pointerView d-flex mt-4 me-3"
            role={"button"}
            onClick={getAdminExports}
          >
            <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Export
            </p>
          </div>
          <div className="d-flex mt-sm-3 border-search">
            <img src={searchIcon} alt="img" className="searchiConImg mt-1" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
              onChange={(e) => getList(e.target.value, pageRow)}
              value={searchValue}
            />
          </div>
          <button
            onClick={() => navigate("/profiles", { state: { type: "add" } })}
            className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            + Add Profile
          </button>
        </div>
      </div>
      <div className="mt-5" style={{ width: "100%", overflow: "auto" }}>
        <table className="table-design-new" style={{ width: "100%" }}>
          <thead>
            <tr className="border-bottom w-100">
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  S.No
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-js">
                  Image
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-js">
                  Name
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-js">
                  Email
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-js">
                  Department
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-js">
                  Status
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-js">
                  Options
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.map((item, index) => {
              return (
                <tr>
                  <td>
                    <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {index + 1}
                    </p>
                  </td>
                  <td>
                    <img
                      src={item?.image_url ? item?.image_url : noLogo}
                      style={{ width: "100px", borderRadius: "5px" }}
                    />
                  </td>
                  <td>
                    <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-js primary1 text-capitalize">
                      {item?.first_name}
                    </p>
                  </td>
                  <td>
                    <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-js primary1">
                      {item?.email}
                    </p>
                  </td>
                  <td>
                    <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-js primary1">
                      {item?.role?.role}
                    </p>
                  </td>

                  <td>
                    <div
                      className="d-flex ac-js"
                      onClick={() => change(item?.id)}
                    >
                      {item?.status === 3 ? (
                        <div>
                          <img src={toggleOff} className="toggleOnDes" />
                        </div>
                      ) : (
                        <div>
                          <img src={toggleOn} className="toggleOnDes" />
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex ac-jc primary1">
                      <div
                        onClick={() =>
                          navigate("/profiles", {
                            state: { type: "view", data: item },
                          })
                        }
                        className="viewBoxDes p-2 mb-2 bg-white rounded-2"
                      >
                        <img src={viewIcon} className="viewDes" />
                      </div>
                      <div
                        onClick={() =>
                          navigate("/profiles", {
                            state: { type: "edit", data: item },
                          })
                        }
                        className="viewBoxDes ms-2 p-2 mb-2 bg-white rounded-2"
                      >
                        <img src={editIcon} className="viewDes" />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            page == 1 && "opacity-50"
          }`}
          onClick={() => setPage(page - 1)}
          disabled={page == 1 ? true : false}
        >
          Previous
        </button>
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            page == pagenation?.last_page && "opacity-50"
          }`}
          onClick={() => setPage(page + 1)}
          disabled={page == pagenation?.last_page ? true : false}
        >
          Next
        </button>
      </div>
    </div>
  );
};
export default AdminPanel;
