import React, { useEffect, useState } from "react";
import { product } from "../../assets/img";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const OverviewFiles = ({ list }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    let temp = [];
    for (let i = 0; i < list; i++) {
      temp.push(i);
    }

    setData(temp);
  }, []);

  return (
    <div className="over_imgs mt-5">
      <div className="d-flex flex-wrap gap-4">
        {data?.map((item, ind) => {
          return (
            <div>
              <div
                className="over_img d-flex ac-jc rounded-2 mt-3"
                style={{ width: "200px" }}
                key={ind}
              >
                <img src={product} className="w-100 object-fit-contain" />
                <div className="files_btn gap-1 d-flex mt-2 px-2">
                  <button className="">Edit</button>
                  <button className="">Delete</button>
                  <button className="">
                    <FileDownloadIcon />
                  </button>
                </div>
              </div>{" "}
              <div className="text-center mt-4">
                <p className="im1">Jobs1.jpeg</p>
                <p className="im2">Updated 14 October 2023</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OverviewFiles;
