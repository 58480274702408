import React, { useEffect, useRef, useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import OurProductListComp from "../components/OurProductComp/OurProductListComp";
import { useNavigate } from "react-router-dom";
import { Search } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  useAdminExportsMutation,
  useDownloadIconTagsMutation,
  useDownloadPacksMutation,
  useDownloadSampleExcelsMutation,
  useEventCategoryListMutation,
  useLazyPresetPackStausQuery,
  useLazyTagsListQuery,
  usePresetPackListMutation,
  usePresetPackPriorityMutation,
  usePresetPackStoreMutation,
} from "../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";
import { searchIcon, uploadIcon } from "../assets/img";
import ErrorIcon from "@mui/icons-material/Error";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import UploadIcon from "@mui/icons-material/Upload";
import GetAppIcon from "@mui/icons-material/GetApp";

const OurPackScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageRow, setPageRow] = useState(10);
  const [page, setPage] = useState(1);
  const [pagenation, setPagination] = useState({});
  const [sortedList, setSortedList] = useState([]);

  const [tagNormalList, setTagNormalList] = useState([]);
  const [tagIconList, setTagIconList] = useState([]);
  const [tagEventList, setTagEventList] = useState([]);
  const [evenCategoryList, setEventCategoryList] = useState([]);
  const [tagNormal, setTagNormal] = useState("");
  const [tagIcon, setTagIcon] = useState("");
  const [tagEvent, setTagEvent] = useState("");
  const [evenCategory, setEventCategory] = useState("");

  const fileRef = useRef();
  const [file, setFile] = useState("");
  // console.log(file?.type);
  const [btn, setBtn] = useState(false);
  const [uploadImg, setUploadImg] = useState("");
  const [importList, setImportList] = useState(false);
  const showPopupHander = () => {
    setImportList(false);
  };
  const [role, setRole] = useState("");
  const [roleErr, setRoleErr] = useState(false);
  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  // RTK QUERY
  const [presetPackListApi] = usePresetPackListMutation();
  const [presetPackPriorityApi] = usePresetPackPriorityMutation();
  const [presetPackStatusApi] = useLazyPresetPackStausQuery();
  const [tagsListApi] = useLazyTagsListQuery();
  const [eventCategoryListApi] = useEventCategoryListMutation();
  const [importApi] = useDownloadSampleExcelsMutation();
  const [uploadApi] = useDownloadPacksMutation();
  const [exportsApi] = useAdminExportsMutation();

  useEffect(() => {
    tagsListApi()
      .unwrap()
      .then((res) => {
        setTagNormalList(res?.normalTag);
        setTagIconList(res?.iconTag);
        setTagEventList(res?.eventTag);
      })
      .catch((err) => {
        console.log("err", err);
      });

    eventCategoryListApi()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          let temp = [...res?.lists];
          temp.filter((i) => i.status == 1);
          setEventCategoryList(temp);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  }, []);

  const getList = (event, tag, event_id) => {
    let params = `?page=${page}`;
    let formdata = new FormData();

    if (event?.length > 0) {
      formdata.append("search", event);
    }
    if (tag) {
      formdata.append("tag_id", tag);
    }

    setTagIcon(tag);
    setTagNormal(tag);
    setTagEvent(tag);

    if (event_id) {
      formdata.append("event_category_id", event_id);
    }
    setSearchValue(event);
    setEventCategory(event_id);
    dispatch(saveLoader(true));
    presetPackListApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        setList(res?.lists);
        let temp = [...res?.lists];
        temp.sort((a, b) => a?.priority - b?.priority);
        setSortedList(temp);
        setPagination(res?.pagination_meta);
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  const statusChange = (id) => {
    presetPackStatusApi(id)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        getList();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const submitPriorityHandle = () => {
    let formdata = new FormData();

    sortedList?.map((item, ind) => {
      formdata.append(`priorities[${ind}][id]`, item?.id);
      formdata.append(`priorities[${ind}][priority]`, ind + 1);
    });

    presetPackPriorityApi(formdata)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        getList();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getList();
  }, [page]);

  // IMPORT EXCEL
  const getAdminImports = () => {
    let formdata = new FormData();
    formdata.append("type", 8);
    importApi(formdata)
      .unwrap()
      .then((res) => {
        if (res?.download_link) {
          const link = document.createElement("a");
          link.href = res?.download_link;
          link.setAttribute("download", "");
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
        }
        console.log("res", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // UPLOAD EXCEL
  const uploadExcel = () => {
    if (image?.length == 0) {
      setImageErr(true);
    } else {
      let formdata = new FormData();
      if (image?.name) {
        formdata.append("file", image);
      }

      setBtn(true);
      dispatch(saveLoader(true));
      uploadApi(formdata)
        .unwrap()
        .then((res) => {
          toast.success("Uploaded Successfully");
          setBtn(false);
          dispatch(saveLoader(false));
          console.log("reslist", res);
          setImage("");
          setImportList(false);
          setShowPopup(false);
        })
        .catch((err) => {
          setBtn(false);
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    }
  };
  // EXPORT EXCEL
  const getAdminExports = () => {
    let formdata = new FormData();
    formdata.append("type", 17);
    exportsApi(formdata)
      .unwrap()
      .then((res) => {
        if (res?.download_link) {
          const link = document.createElement("a");
          link.href = res?.download_link;
          link.setAttribute("download", "");
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <>
      {importList && (
        <div className="modal-popup">
          <div onClick={showPopupHander} className="back-close" />
          <div className="center-content p-4">
            <div className=" d-flex justify-content-end">
              <span>
                <button
                  onClick={() => {
                    showPopupHander();
                    setImage("");
                    setImageErr("");
                  }}
                  className="cust-btn"
                >
                  <HighlightOffIcon />
                </button>
              </span>
            </div>
            <div>
              <button
                className={`cust-btn addbtn  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded `}
                onClick={() => getAdminImports()}
                // disabled={page == pagenation?.last_page ? true : false}
              >
                Sample Download
              </button>
            </div>
            <p className="d-flex ac-jc f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Import Admin List
            </p>
            <div className="pointerView w-100 mt-4 ac-jc">
              <div
                onClick={() => fileRef.current.click()}
                className="w-100 d-flex ac-jc"
              >
                {file === "" ? (
                  <img
                    src={uploadIcon}
                    onChange={(e) => setImage(e.target.files[0])}
                    className="mailImg"
                  />
                ) : (
                  <iframe
                    onClick={() => fileRef.current.click()}
                    src={file}
                    className="importExcel"
                  />
                )}
              </div>
              {file === "" && (
                <div
                  onClick={() => {
                    fileRef.current.click();
                  }}
                  className="w-100 d-flex ac-jc"
                >
                  <p className="primary1 w-100 f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 mt-1 d-flex ac-jc">
                    +Import List
                  </p>
                </div>
              )}
              {image?.length == 0 && imageErr && (
                <div className="d-flex ac-jc gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Upload File</p>
                </div>
              )}
              <div className="d-flex ac-jc">
                <button
                  onClick={() => uploadExcel()}
                  disabled={btn}
                  className={`
              cust-btn addbtn mt-sm-4 ms-1 mt-4 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                btn && "opacity-50"
              }`}
                >
                  Submit
                </button>
              </div>
            </div>
            <input
              type="file"
              accept=".xlsx,"
              onChange={(e) => {
                setImage(e.target.files[0]);
              }}
              className="d-none"
              ref={fileRef}
            />
          </div>
        </div>
      )}
      <div className="dashRightView p-5 home_section trans">
        <ProductSearchHeader />

        <div className="d-flex mt-2 ac-jb flex-wrap me-md-3 rk2">
          <div className="d-flex mt-3 w-100 ac-jb pt-4">
            <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
              Our Packs
            </p>
            <div className="d-flex ac-jb">
              <div
                onClick={getAdminExports}
                className="pointerView d-flex  me-3"
              >
                <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
                <p
                  className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
                >
                  Export
                </p>
              </div>
              <div
                onClick={() => setImportList(!importList)}
                className="pointerView d-flex  me-3"
              >
                <UploadIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
                <p
                  className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
                >
                  Import
                </p>
              </div>
              <h5 className="preset_count bold pe-3">
                Total Count: {list?.length}
              </h5>
              <button
                onClick={() => navigate("/add-our-packs")}
                className="cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
              >
                + Add Product
              </button>
            </div>
          </div>
          <div className="d-flex mt-3 w-100 ac-jb pt-4">
            <div className="d-flex ac-jb">
              <p className="tag_filter d-flex primary ">
                Tags{" "}
                <select
                  style={{ border: "0", width: "40px" }}
                  value={tagNormal}
                  onChange={(e) => getList("", e.target.value)}
                >
                  {/* <option>All</option> */}
                  <option value={""}>Select Tags</option>
                  {tagNormalList?.map((item, ind) => {
                    return (
                      <option key={ind} value={item?.id}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
              </p>

              <p className="tag_filter d-flex primary ms-2">
                Event Tags{" "}
                {/* <button>
                All <KeyboardArrowDownIcon />
              </button> */}
                <select
                  style={{ border: "0", width: "40px" }}
                  value={tagEvent}
                  onChange={(e) => getList("", e.target.value)}
                >
                  <option value={""}>Select Event Tags</option>
                  {tagEventList?.map((item, ind) => {
                    return (
                      <option key={ind} value={item?.id}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
              </p>
              <p className="tag_filter d-flex primary ms-2">
                Icon Tags{" "}
                {/* <button>
                All <KeyboardArrowDownIcon />
              </button> */}
                <select
                  style={{ border: "0", width: "40px" }}
                  value={tagIcon}
                  onChange={(e) => getList("", e.target.value)}
                >
                  <option value={""}>Select Icon Tags</option>
                  {tagIconList?.map((item, ind) => {
                    return (
                      <option key={ind} value={item?.id}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
              </p>
              <p className="tag_filter d-flex primary ms-2">
                Event Categories{" "}
                {/* <button>
                All <KeyboardArrowDownIcon />
              </button> */}
                <select
                  style={{ border: "0", width: "40px" }}
                  value={evenCategory}
                  onChange={(e) => getList("", "", e.target.value)}
                >
                  <option value={""}>Select Event Categorie</option>
                  {evenCategoryList?.map((item, ind) => {
                    return (
                      <option key={ind} value={item?.id}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
              </p>
            </div>
            <div className="d-flex mt-sm-3 border-search">
              <img src={searchIcon} alt="img" className="searchiConImg mt-1" />
              <input
                className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                placeholder="Search"
                onChange={(e) => getList(e.target.value, "", "")}
                value={searchValue}
              />
            </div>
          </div>
          <div className="d-flex justify-content-start w-100 mt-2">
            {" "}
            <button
              onClick={() => submitPriorityHandle()}
              className="cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Set Priority
            </button>
          </div>
          <div className="d-flex mt-2 ac-jc w-100 flex-wrap me-md-3 rk2">
            {/* <EmptyProductComp /> */}
          </div>
          <OurProductListComp
            data={list}
            statusChange={statusChange}
            setSortedList={setSortedList}
            sortedList={sortedList}
          />
        </div>
        {/* <div className="d-flex justify-content-between mt-3">
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Previous
        </button>
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Next
        </button>
      </div> */}
      </div>
    </>
  );
};

export default OurPackScreen;
