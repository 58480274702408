import React, { useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import SalesForecastTab from "../components/Sales/SalesForecastTab";
import SalesTasks from "../components/Sales/SalesTasks";
import SalesProgress from "../components/Sales/SalesProgress";
import AddClientContactPopup from "../components/Projects/AddClientContactPopup";
import AddProjectPoppupComp from "../components/Projects/AddProjectPoppupComp";

const ProjectsScreen = () => {
  const [activeTab, setActiveTab] = useState("sales");
  const [addProject, setAddProject] = useState(false);

  const onTabClick = (tab) => {
    setActiveTab(tab);
  };

  const closeToggle = () => {
    setAddProject(!addProject);
  };

  return (
    <div className="dashRightView  p-5 home_section trans">
      <ProductSearchHeader />
      <div className="d-flex ac-jb flex-wrap rk2">
        <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary ">
          Projects
        </p>
        {/* <button
          onClick={() => closeToggle()}
          className="cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
        >
          + Add Project
        </button> */}
      </div>
      {addProject && <AddProjectPoppupComp closeToggle={closeToggle} />}
      <div className="d-flex mt-2 ac-jb flex-wrap me-md-3 rk2">
        <div className="d-flex mt-4 rk">
          <button
            onClick={() => onTabClick("sales")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "sales"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "sales" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Sales Forecast
            </p>
          </button>
          {/* <button
            onClick={() => onTabClick("tasks")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "tasks"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}>
            <p
              className={`${
                activeTab === "tasks" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}>
              Tasks
            </p>
          </button> */}
          <button
            onClick={() => onTabClick("progress")}
            style={{ cursor: "pointer" }}
            className={`${
              activeTab === "progress"
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                activeTab === "progress" ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Progress
            </p>
          </button>
        </div>
      </div>
      {activeTab === "sales" && <SalesForecastTab />}
      {activeTab === "tasks" && <SalesTasks />}
      {activeTab === "progress" && <SalesProgress />}
    </div>
  );
};

export default ProjectsScreen;
