import React, { useEffect, useState } from "react";
import { animatedImg, product, toggleOff, toggleOn } from "../../assets/img";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import {
  useLazyCategoriesQuery,
  useProdBulkStatusMutation,
} from "../../redux/api/api";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../redux/slice/loaderNoti";

const ProductListLibraryComp = ({
  productList,
  setParoductList,
  list,
  searchValue,
  setSearchValue,
  pageRow,
  setPageRow,
  page,
  setPage,
  pagenation,
  setPagination,
  getProducts,
  statusValue,
  categoryID,
  vendorID,
  tagID,
  productName,
  netMin,
  netMax,
}) => {
  const dispatch = useDispatch();
  // const [productDetail, setProductDetail] = useState(false)
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [listCat, setListCat] = useState([]);
  const [btn, setBtn] = useState(false);

  // RTK QUERY
  const [productBulkStatusApi] = useProdBulkStatusMutation();
  const [listCategory] = useLazyCategoriesQuery();

  const getList = () => {
    listCategory()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setListCat(res?.categories);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const rolefunction = (id) => {
    let roleId = [...permissions];
    let ids = roleId.indexOf(id);

    if (roleId.includes(id)) {
      roleId.splice(ids, 1);
    } else {
      roleId.push(id);
    }

    if (roleId.length == [1, 2, 3, 4].length) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }

    setPermissions(roleId);
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setPermissions(list?.map((li) => li?.id));
    if (isCheckAll) {
      setPermissions([]);
    }
  };

  const submitBulkStatus = (status, type, id) => {
    let formdata = new FormData();
    formdata.append("status", status);

    let temp = [];
    temp.push(id);

    if (type == "one") {
      temp?.map((item, ind) => {
        formdata.append(`product_ids[${ind}]`, item);
      });
    } else {
      permissions?.map((item, ind) => {
        formdata.append(`product_ids[${ind}]`, item);
      });
    }
    dispatch(saveLoader(true));
    setBtn(true);
    productBulkStatusApi(formdata)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        setPermissions([]);
        setIsCheckAll(false);
        getProducts();
        dispatch(saveLoader(false));
        setBtn(false);
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setBtn(false);
        console.log("err", err);
      });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <div className="d-flex ac-jb flex-column flex-md-row w-100 mt-5">
        <div className="d-flex gap-3">
          <select
            className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3"
            onChange={(e) =>
              getProducts(
                searchValue,
                statusValue,
                e.target.value,
                20,
                productName,
                vendorID,
                tagID,
                netMin,
                netMax
              )
            }
            value={categoryID}
          >
            <option value={""}>Select Categories</option>
            {listCat?.map((item, ind) => {
              return (
                <option key={ind} value={item?.id}>
                  {item?.name}
                </option>
              );
            })}
          </select>
          <select
            className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3"
            onChange={(e) =>
              getProducts(
                searchValue,
                e.target.value,
                categoryID,
                20,
                productName,
                vendorID,
                tagID,
                netMin,
                netMax
              )
            }
            value={statusValue}
          >
            <option value={""}>Select Status</option>
            <option value={1}>Active</option>
            <option value={0}>Deactive</option>
          </select>
        </div>
      </div>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select
            className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary"
            onChange={(e) =>
              getProducts(
                searchValue,
                statusValue,
                categoryID,
                e.target.value,
                productName,
                vendorID,
                tagID,
                netMin,
                netMax
              )
            }
            value={pageRow}
          >
            <option value="20">20</option>
            <option value="40">40</option>
            <option value="60">60</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont">
            Total Count : {pagenation?.total}
          </p>
        </div>
      </div>
      <div className="w-95 d-flex flex-md-row flex-column mt-4 gap-2">
        <div
          className="d-flex gap-1 mt-4"
          onClick={() => handleSelectAll()}
          role={"button"}
        >
          <DoneIcon
            className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 mt-3 ${
              isCheckAll ? "checkIcon" : "checkIcon1"
            }`}
          />
          <p
            className={`f1 ms-2 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 mt-3 ${
              isCheckAll ? "black" : "primary1"
            }`}
          >
            Select All
          </p>
        </div>
        {permissions?.length > 0 && (
          <div className="d-flex gap-2 mt-3">
            <button
              className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
              onClick={() => submitBulkStatus(1)}
            >
              Active
            </button>
            <button
              className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
              onClick={() => submitBulkStatus(0)}
            >
              Deactive
            </button>
          </div>
        )}
      </div>
      <div className="w-100 d-flex ac-js mt-5 flex-wrap">
        {list?.map((item, ind) => {
          return (
            <div
              className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 position-relative"
              key={ind}
            >
              <div className="prod-list-box">
                <div
                  // onClick={() =>
                  //   navigate("/product-library-details", {
                  //     state: { type: "productList" },
                  //   })
                  // }
                  onClick={() =>
                    navigate("/product-library-details", {
                      state: { data: item, type: "edit" },
                    })
                  }
                >
                  <div className="cust-btn d-flex ac-jc rounded-3">
                    <img
                      src={item?.defaultImage ? item?.defaultImage : product}
                      alt=""
                      className="custom-w-h rounded-3"
                    />
                  </div>
                  <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                    {item?.name}
                  </p>
                  <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
                    {item?.primaryCategory?.name}
                  </p>
                  <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">
                    {item?.sku_code}
                    {/* <span>
                    <div
                      className="f4 fs-15"
                      style={{
                        color: "greenyellow",
                      }}
                    >
                      Customer
                    </div>
                  </span> */}
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
                    Visible Users
                  </p>{" "}
                  <p
                    className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2"
                    role={"button"}
                    onClick={() => {
                      if (!btn) {
                        submitBulkStatus(
                          item?.status == 1 ? 0 : 1,
                          "one",
                          item?.id
                        );
                      }
                    }}
                  >
                    <img
                      src={item?.status == 1 ? toggleOn : toggleOff}
                      className="toggleOnDes"
                      alt="icon"
                    />
                  </p>
                </div>
                <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
                  {item?.is_ecommerce == 1 && "E-commerce"}{" "}
                  {item?.is_enterprises == 1 && "Enterprises"}{" "}
                  {item?.is_reseller == 1 && "Reseller"}{" "}
                  {item?.show_to_vendor == 1 && "Vendor"}
                </p>
              </div>
              <div className="d-flex mt-4 ms-1 position-absolute top-0 end-0 px-4">
                <DoneIcon
                  onClick={() => rolefunction(item?.id)}
                  className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 ${
                    permissions?.includes(item?.id) ? "checkIcon" : "checkIcon1"
                  }`}
                />
                <p
                  className={`f1 ms-2 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ${
                    permissions?.includes(item?.id) ? "black" : "primary1"
                  }`}
                ></p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="d-flex justify-content-between mt-3">
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            page == 1 && "opacity-50"
          }`}
          onClick={() => setPage(page - 1)}
          disabled={page == 1 ? true : false}
        >
          Previous
        </button>
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            page == pagenation?.last_page && "opacity-50"
          }`}
          onClick={() => setPage(page + 1)}
          disabled={page == pagenation?.last_page ? true : false}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default ProductListLibraryComp;
