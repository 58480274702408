import { useNavigate } from "react-router-dom";
import {
  editIcon,
  toggleOff,
  toggleOn,
  viewIcon,
  searchIcon,
} from "../../assets/img";
import {
  customerDecoList,
  noncustomerDecoList,
} from "../../redux/api/DummyJson";
import { Search } from "@mui/icons-material";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
  useAdminExportsMutation,
  useLazyCustomerRequestListTagsQuery,
} from "../../redux/api/api";
import CachedIcon from "@mui/icons-material/Cached";
import { useEffect, useState } from "react";

const RejectedRegisteredReqList = ({
  clientRep,
  orderRep,
  data,
  pagenation,
  searchNamee,
  tabData,
  getNonList,
  searchVal,
  pageRows,
  orderReff,
  clientReff,
  orderStatuss,
  tagStatuss,
  filterr,
  setPages,
  pages,
  pagenations,
}) => {
  const navigate = useNavigate();
  const [tags, setTags] = useState("");

  // RTK QUERY
  const [exportsApi] = useAdminExportsMutation();
  const [customerTagsApi] = useLazyCustomerRequestListTagsQuery();

  // CUSTOMER TAGS LIST
  const getTagList = (tagStatus) => {
    // let formdata = new FormData();

    customerTagsApi()
      .unwrap()
      .then((ress) => {
        if (ress?.status == "success") {
          setTags(ress?.normalTag);
        }
        console.log(ress);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    // if (location?.state?.data?.id) {
    getTagList();
    // }
  }, []);

  // EXPORT EXCEL
  const getAdminExports = () => {
    let formdata = new FormData();
    formdata.append("type", 15);
    exportsApi(formdata)
      .unwrap()
      .then((res) => {
        if (res?.download_link) {
          const link = document.createElement("a");
          link.href = res?.download_link;
          link.setAttribute("download", "");
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="w-100 d-flex flex-md-row flex-column">
          <div className=" d-flex ac-jc place-holde justify-content-md-start gap-3 flex-wrap w-100 w-md-90 mb-md-0  mb-4">
            <input
              type="text"
              className="w-15 get-start-btn respon-width  bg-lt-blue primary1 border-0 my-0 py-4 px-3"
              placeholder="Customer Name"
              onChange={(e) =>
                getNonList(
                  searchVal,
                  pageRows,
                  orderReff,
                  clientReff,
                  orderStatuss,
                  tagStatuss,
                  e.target.value,
                  filterr
                )
              }
              value={searchNamee}
            />
            <button class="w-17 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
              <select
                className="bg-transparent border-0 py-3 primary1"
                onChange={(e) =>
                  getNonList(
                    searchVal,
                    pageRows,
                    e.target.value,
                    clientReff,
                    orderStatuss,
                    tagStatuss,
                    searchNamee,
                    filterr
                  )
                }
                value={orderReff}
              >
                <option>Order Rep</option>
                {orderRep?.map((adm) => (
                  <option key={adm?.id} value={adm?.id}>
                    {adm?.first_name}
                  </option>
                ))}
                {/* <option value="1">Order Rep</option>
                <option value="Order Rep Admin1">Order Rep Admin1</option>
                <option value="Order Rep Admin2">Order Rep Admin2</option> */}
              </select>
            </button>
            <button class="w-17 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
              <select
                className="bg-transparent border-0 py-3 primary1"
                onChange={(e) =>
                  getNonList(
                    searchVal,
                    pageRows,
                    orderReff,
                    e.target.value,
                    orderStatuss,
                    tagStatuss,
                    searchNamee,
                    filterr
                  )
                }
                value={clientReff}
              >
                <option>Client Rep</option>
                {clientRep?.map((admins) => (
                  <option key={admins?.id} value={admins?.id}>
                    {admins?.first_name}
                  </option>
                ))}
                {/* <option value="Client Rep Admin1">Client Rep Admin1</option>
                <option value="Client Rep Admin2">Client Rep Admin2</option> */}
              </select>
            </button>
            <button class="w-17 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
              <select
                className="bg-transparent border-0 py-3 primary1"
                onChange={(e) =>
                  getNonList(
                    searchVal,
                    pageRows,
                    orderReff,
                    clientReff,
                    e.target.value,
                    tagStatuss,
                    searchNamee,
                    filterr
                  )
                }
                value={orderStatuss}
              >
                <option>Order Status</option>
                {/* {clientRep?.map((admins) => (
                  <option key={admins?.id} value={admins?.id}>
                    {admins?.first_name}
                  </option>
                ))} */}
                <option value="1">Requested</option>
                {/* <option value="2">In progress</option>
                <option value="3">Collabrate</option> */}
                <option value="2">Start</option>
                <option value="4"> Presentation ready</option>
                <option value="5">Presentation accept</option>
                <option value="6">Presentation reject</option>
                <option value="7">Estimation ready</option>
                <option value="8">Estimation accept</option>
                <option value="9">Estimation reject</option>
                <option value="10">Customer paid</option>
              </select>
            </button>
            <button class="w-17 mx-3 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4 position-relative">
              <select
                className="bg-transparent border-0 py-3 primary1 position-absolute top-0"
                onChange={(e) =>
                  getNonList(
                    searchVal,
                    pageRows,
                    orderReff,
                    clientReff,
                    orderStatuss,
                    e.target.value,
                    searchNamee,
                    filterr
                  )
                }
                value={tagStatuss}
              >
                <option>Tags</option>
                {/* {tags?.map((admins) => (
                  <option key={admins?.id} value={admins?.id}>
                    {admins?.name}
                  </option>
                ))} */}
                <option value="1">Event Tags</option>
                <option value="2">Icon Tags</option>
                <option value="3">Normal Tags</option>
              </select>
            </button>
          </div>
        </div>
        <div className="d-flex">
          <button className="mx-2 cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded">
            <Search />
          </button>
          <button
            className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
            onClick={() => getNonList("", "", "", "", "", "", "", "")}
          >
            <CachedIcon />
          </button>
        </div>
      </div>
      <div className="d-flex ac-jb mt-4 flex-wrap">
        <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Non-Registered Request List
        </p>
      </div>
      <div className="d-flex ac-jb mt-4 flex-wrap">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select
            className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary"
            onChange={(e) =>
              getNonList(
                searchVal,
                e.target.value,
                orderReff,
                clientReff,
                orderStatuss,
                tagStatuss,
                searchNamee,
                filterr
              )
            }
            value={pageRows}
          >
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div>
          <div>
            <p className="ms-3 w-100 f3 primary fs-15 fs-xl-15  fs-xs-13 fs-sm-15 fs-md-15 fs-lg-15  fs-xxl-15 d-flex as-jc text-nowrap">
              Filter
            </p>
          </div>
          <div className="w-100 mx-3 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4 position-relative">
            <select
              placeholder="Filter"
              className="w-100 bg-transparent border-0 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 rounded-3 "
              name=""
              id=""
              value={filterr}
              onChange={(e) =>
                getNonList(
                  searchVal,
                  pageRows,
                  orderReff,
                  clientReff,
                  orderStatuss,
                  tagStatuss,
                  searchNamee,
                  e.target.value
                )
              }
            >
              {/* {data?.map((admins) => (
                <option key={admins?.id} value={admins?.id}>
                  {admins?.request_type}
                </option>
              ))} */}
              <option value="1">Preset</option>
              <option value="2">Bulk</option>
              <option value="3">Individual</option>
              <option value="4">design pack</option>
            </select>
          </div>
        </div>
        <div className="d-flex ac-jb flex-md-row flex-column">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont me-3 mt-4">
            Total Count :{pagenation?.total}
          </p>
          <div
            className="pointerView d-flex mt-4 me-3"
            role={"button"}
            onClick={getAdminExports}
          >
            <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Export
            </p>
          </div>
          <div className="d-flex mt-sm-3 border-search">
            <img src={searchIcon} alt="search" className="searchiConImg" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
              onChange={(e) =>
                getNonList(
                  e.target.value,
                  pageRows,
                  orderReff,
                  clientReff,
                  orderStatuss,
                  tagStatuss,
                  searchNamee,
                  filterr
                )
              }
              value={searchVal}
            />
          </div>
          {/* <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
            + Add Non-Registered Request List
          </button> */}
        </div>
      </div>
      <div className="mt-5" style={{ width: "100%", overflow: "auto" }}>
        <table className="table-design-new" style={{ width: "100%" }}>
          <thead>
            <tr className="border-bottom w-100">
              <th>
                <p className="w-100 f2 fs-15 fs-xl-15  fs-xs-13 fs-sm-15 fs-md-15 fs-lg-15  fs-xxl-15 d-flex as-jc text-nowrap">
                  S.No
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xl-15  fs-xs-13 fs-sm-15 fs-md-15 fs-lg-15  fs-xxl-15 d-flex as-jc text-nowrap">
                  E-Commerce ID
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xl-15  fs-xs-13 fs-sm-15 fs-md-15 fs-lg-15  fs-xxl-15 d-flex as-jc text-nowrap">
                  Customer Name
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xl-15  fs-xs-13 fs-sm-15 fs-md-15 fs-lg-15  fs-xxl-15 d-flex as-jc text-nowrap">
                  Request ID
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xl-15  fs-xs-13 fs-sm-15 fs-md-15 fs-lg-15  fs-xxl-15 d-flex as-jc text-nowrap">
                  Order Rep name
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xl-15  fs-xs-13 fs-sm-15 fs-md-15 fs-lg-15  fs-xxl-15 d-flex as-jc text-nowrap">
                  Client Rep Name
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xl-15  fs-xs-13 fs-sm-15 fs-md-15 fs-lg-15  fs-xxl-15 d-flex as-jc text-nowrap">
                  Request Type
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xl-15  fs-xs-13 fs-sm-15 fs-md-15 fs-lg-15  fs-xxl-15 d-flex as-jc text-nowrap">
                  Order Status
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 f2 fs-15 fs-xl-15  fs-xs-13 fs-sm-15 fs-md-15 fs-lg-15  fs-xxl-15 d-flex as-jc text-nowrap">
                  Status
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 f2 fs-15 fs-xl-15  fs-xs-13 fs-sm-15 fs-md-15 fs-lg-15  fs-xxl-15 d-flex as-jc text-nowrap">
                  Options
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => {
              return (
                <tr>
                  <td>
                    <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {index + 1}
                    </p>
                  </td>
                  <td>
                    <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {item?.non_reg_user_id}
                    </p>
                  </td>
                  <td>
                    <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {item?.first_name}
                    </p>
                  </td>
                  <td>
                    <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                      {item?.project_request_id}
                    </p>
                  </td>
                  <td>
                    <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {item?.order_rep?.first_name}
                    </p>
                  </td>
                  <td>
                    <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {item?.client_rep?.first_name}
                    </p>
                  </td>

                  <td>
                    <div className="w-100 editBtnSelect f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 rounded-3 mt-2">
                      {item?.request_type === 1 ? (
                        <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                          Individual pack
                        </p>
                      ) : item?.request_type === 2 ? (
                        <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                          Preset pack
                        </p>
                      ) : item?.request_type === 3 ? (
                        <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                          Bulk pack
                        </p>
                      ) : item?.request_type === 4 ? (
                        <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                          Ecommerce pack
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </td>

                  <td>
                    <div className="w-100 editBtnSelect f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 rounded-3 mt-2">
                      {"Rejected"}
                    </div>
                  </td>
                  <td>
                    <div className="w-15 d-flex ac-jc ms-4">
                      <div>
                        {item?.status == 0 ? (
                          <img src={toggleOff} className="toggleOnDes" />
                        ) : (
                          <img src={toggleOn} className="toggleOnDes" />
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className=" d-flex ac-jc primary1">
                      <div
                        onClick={() =>
                          navigate("/view-request-details-customer", {
                            state: {
                              type: "view",
                              data: item,
                              listts: "nonRegisteredCustomerList",
                            },
                          })
                        }
                        className="viewBoxDes pointerView p-2 bg-white rounded-2"
                      >
                        <img src={viewIcon} className="viewDes" />
                      </div>

                      <div
                        onClick={() =>
                          navigate("/view-request-details-customer", {
                            state: {
                              type: "edit",
                              data: item,
                              listts: "nonRegisteredCustomerList",
                            },
                          })
                        }
                        className="viewBoxDes pointerView ms-2 p-2 bg-white rounded-2"
                      >
                        <img src={editIcon} className="viewDes" />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            pages == 1 && "opacity-50"
          }`}
          onClick={() => setPages(pages - 1)}
          disabled={pages == 1 ? true : false}
        >
          Previous
        </button>
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            pages == pagenations?.last_page && "opacity-50"
          }`}
          onClick={() => setPages(pages + 1)}
          disabled={pages == pagenations?.last_page ? true : false}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default RejectedRegisteredReqList;
