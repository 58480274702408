import React from "react";
import { useNavigate } from "react-router-dom";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";

const PricingPage = () => {
  const navigate = useNavigate();
  return (
    <div className="dashRightView  p-5 home_section trans">
      <ProductSearchHeader />
      <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5">
        Pricing Page
      </p>
      <div className="h-auto mt-4">
        <p class="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary mt-5">
          Vendor Pricing
        </p>
        <div className="">
          <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-3">
            Charles Supplier
          </p>
          <div className="overflow-scroll">
            <table className="w-100">
              <tr className="mt-4">
                <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Qty
                </th>
                <div className="my-3">
                  <td>
                    <div className="d-flex w-100">
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="button"
                          value="25"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="button"
                          value="50"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="button"
                          value="100"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="button"
                          value="150"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="button"
                          value="250"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="button"
                          value="500"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="button"
                          value="1000"
                        />
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
              <tr>
                <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Net
                </th>
                <div className="mt-2">
                  <td>
                    <div className="d-flex w-100">
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="number"
                          placeholder="₹90.00"
                          maxlength={3}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹85.00"
                          type="number"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹85.00"
                          type="number"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹75.00"
                          type="number"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹75.00"
                          type="number"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹75.00"
                          type="number"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹75.00"
                          type="number"
                        />
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div className="d-flex as-jb w-100 h-50 mt-5">
        <button
          class="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded "
          onClick={() => navigate(-1)}>
          Back
        </button>{" "}
        <button
          class="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded "
          onClick={() => navigate("/product-library")}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default PricingPage;
