import ProfileNavBar from "../ProfileNavBar";
import { Search } from "@mui/icons-material";
import manImage from "../../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import ImageCroper from "../Popup/ImageCroper";
import { useEffect, useRef, useState } from "react";
import intelImg from "../../assets/img/download.jpeg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Select from "react-select";
import {
  useActiveRolesQuery,
  useAdminAddMutation,
  useAdminEditMutation,
  useLazyAdminViewQuery,
  useLazyDepartmentActiveRoleListQuery,
  useMasterActiveListMutation,
} from "../../redux/api/api";
import {
  danger_sm,
  product,
  profilePic,
  uploadIcon,
  vendorBannerImg,
} from "../../assets/img";
import {
  ArrowBackIos,
  ArrowForwardIos,
  CloseOutlined,
  Download,
  UploadFile,
} from "@mui/icons-material";
import ErrorIcon from "@mui/icons-material/Error";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { saveLoader } from "../../redux/slice/loaderNoti";
import ProductSearchHeader from "../ProductComp/ProductSearchHeader";

const ViewTask = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;
  const [image, setImage] = useState();
  const [roles, setRoles] = useState([]);
  const [selectBox, setSelectBox] = useState([]);
  const [post, setPost] = useState("");
  const [showAllImg, setShowAllImg] = useState(false);

  const hiddenFileInput = useRef(null);

  const handleChange = (event) => {
    const selectedFIles = [];
    const targetFiles = event.target.files;
    const targetFilesObject = [...targetFiles];
    targetFilesObject.map((file) => {
      return selectedFIles.push(URL.createObjectURL(file));
    });
    setImages(selectedFIles);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  let options = [
    { value: "ab prime", label: "ab Prime" },
    { value: "kti promos", label: "KTI  Promos" },
  ];

  let showImages = [
    [
      {
        title: "images",
        date: "12 dec 2023 - 12:23PM",
        desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ratione eligendi animi excepturi dolore quasi maxime, assumenda voluptas laboriosam consequuntur perspiciatis expedita numquam placeat, et harum deleniti praesentium. Aspernatur, non molestiae.",
      },
      [
        {
          img: uploadIcon,
        },
        {
          img: profilePic,
        },
        {
          img: danger_sm,
        },
        {
          img: "https://nestasia.in/cdn/shop/products/DSCF0635.jpg?v=1678173254",
        },
        {
          img: product,
        },
      ],
    ],
  ];

  // const getView = () => {
  //   viewAdmin(location?.state?.data?.id)
  //     .unwrap()
  //     .then((res) => {
  //       if (res?.status == "success" || res?.data?.status == "success") {
  //         let data = res?.showData || res?.data?.showData;
  //         setFirst_name(data?.first_name);
  //         setLast_name(data?.last_name);
  //         setEmail(data?.email);
  //         setRole(data?.role?.id);
  //         setImage(data?.image_url ? data?.image_url : intelImg);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err");
  //     });
  // };

  // useEffect(() => {
  //   if (location?.state?.data?.id) {
  //     getView();
  //   }
  // }, []);

  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [Email, setEmail] = useState("");
  const [Role, setRole] = useState("");
  const [Password, setPassword] = useState("");
  const [CPassword, setCPassword] = useState("");
  const [images, setImages] = useState([]);

  const [first_nameErr, setFirst_nameErr] = useState(false);
  const [last_nameErr, setLast_nameErr] = useState(false);
  const [EmailErr, setEmailErr] = useState(false);
  const [RoleErr, setRoleErr] = useState(false);
  const [PasswordErr, setPasswordErr] = useState(false);
  const [CPasswordErr, setCPasswordErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const submitHandler = () => {
    if (
      first_name?.length == 0 ||
      last_name?.length == 0 ||
      Email?.length == 0 ||
      Role?.length == 0 ||
      (type == "add" && Password?.length == 0) ||
      (type == "add" && Password !== CPassword)
    ) {
      setEmailErr(true);
      setFirst_nameErr(true);
      setLast_nameErr(true);
      setRoleErr(true);
      type == "add" && setPasswordErr(true);
      type == "add" && setCPasswordErr(true);
    } else if (errorMessage?.length == 0) {
      let formdata = new FormData();

      formdata.append("first_name", first_name);
      formdata.append("last_name", last_name);
      formdata.append("email", Email);
      formdata.append("role_id", Role);
      if (image?.name) {
        formdata.append("image", image);
      }
      if (Password && CPassword) {
        formdata.append("password", Password);
        formdata.append("password_confirmation", CPassword);
      }
      setBtn(true);
      dispatch(saveLoader(true));
      if (type == "edit") {
        // formdata.append("_method", "PUT");
        // updateAdmin({ id: location?.state?.data?.id, payload: formdata })
        //   .unwrap()
        //   .then((res) => {
        //     if (res?.status == "success") {
        //       toast.success(res?.data?.message || res?.message);
        //       navigate("/profile");
        //     }
        //     setBtn(false);
        //     dispatch(saveLoader(false));
        //   })
        //   .catch((err) => {
        //     console.log("err");
        //     dispatch(saveLoader(false));
        //     setBtn(false);
        //   });
      } else {
        // createAdmin(formdata)
        //   .unwrap()
        //   .then((res) => {
        //     if (res?.status == "success") {
        //       toast.success(res?.data?.message || res?.message);
        //       navigate("/profile");
        //     }
        //     setBtn(false);
        //     dispatch(saveLoader(false));
        //   })
        //   .catch((err) => {
        //     console.log("err");
        //     dispatch(saveLoader(false));
        //     setBtn(false);
        //   });
      }
    }
  };

  const handlePassword = (event) => {
    let new_pass = event.target.value;
    if (new_pass?.length !== 0) {
      setPassword(new_pass);

      // regular expressions to validate password
      var lowerCase = /[a-z]/g;
      var upperCase = /[A-Z]/g;
      var numbers = /[0-9]/g;
      var special = /[*@!#%&()^~{}]+/g;
      if (!new_pass.match(lowerCase)) {
        setErrorMessage("Password should contains lowercase letters!");
      } else if (!new_pass.match(upperCase)) {
        setErrorMessage("Password should contain uppercase letters!");
      } else if (!new_pass.match(numbers)) {
        setErrorMessage("Password should contains numbers also!");
      } else if (!new_pass.match(special)) {
        setErrorMessage("Password should contains special character also!");
      } else if (new_pass.length < 8) {
        setErrorMessage("Password length should be more than 8.");
      } else {
        // setErrorMessage("Password is strong!");
        setErrorMessage("");
      }
    } else {
      setErrorMessage("");
      setPassword(new_pass);
    }
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      <ProductSearchHeader />
      <p
        className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon /> View Project Task
      </p>
      <div className="d-flex gap-4 as-jb mt-3 flex-column flex-md-row w-100">
        <div className="w-100">
          <div className="py-0 rounded-3 bg-lt-blue2 my-0 py-0 rounded-3">
            <div className="acti_box p-3">
              <div className="d-flex as-jb flex-wrap w-100 ">
                <div className="w-50 px-2 mb-4">
                  <div className="w-100">
                    <p className="f3 fs-12 fs-sm-15 fs-md-16">Task Date</p>
                    <input
                      placeholder="Task Deadline"
                      className=" editBtn rounded-3  p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      onChange={(e) => setLast_name(e.target.value)}
                      value={last_name}
                      type="date"
                      readOnly
                      // disabled={type == "view" ? true : false}
                    />
                  </div>
                </div>
                <div className="w-50 px-2 mb-4">
                  <div className="w-100">
                    <p className="f3 fs-12 fs-sm-15 fs-md-16"> Task Deadline</p>
                    <input
                      placeholder="Task Deadline"
                      className=" editBtn rounded-3  p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      onChange={(e) => setLast_name(e.target.value)}
                      value={last_name}
                      type="date"
                      readOnly
                      // disabled={type == "view" ? true : false}
                    />
                  </div>
                </div>
                <div className="w-50 px-2 mb-4">
                  <div className="w-100">
                    <p className="f3 fs-12 fs-sm-15 fs-md-16">
                      {" "}
                      Project Associated
                    </p>

                    <select
                      className="editBtn  rounded-3 mt-0 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      value={2}
                      disabled
                    >
                      <option hidden>Select Project Associated</option>
                      {/* {roles?.map((item, ind) => {
              return (
                <option value={item?.id} key={ind}>
                  {item?.role}
                </option>
              );
            })} */}
                      <option>Project 1</option>
                      <option>Project 2</option>
                    </select>
                  </div>
                </div>
                <div className="w-50 px-2 supp-select">
                  <p className="f3 fs-12 fs-sm-15 fs-md-16">Assigned By</p>
                  <select
                    className="editBtn  rounded-3 mt-0 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={2}
                    disabled
                  >
                    <option hidden>Select Assigned By</option>
                    <option>Roger</option>
                    <option>Sakthi</option>
                  </select>
                </div>

                <div className="w-50 px-2 mb-4">
                  <div className="w-100">
                    <p className="f3 fs-12 fs-sm-15 fs-md-16">
                      Department/ Team
                    </p>
                    <input
                      placeholder="Department/ Team"
                      className=" editBtn rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      // onChange={(e) => setLast_name(e.target.value)}
                      // value={last_name}
                      // disabled={type == "view" ? true : false}
                      readOnly
                    />
                  </div>
                </div>
                <div className="w-50 px-2 supp-select">
                  <p className="f3 fs-12 fs-sm-15 fs-md-16">User</p>
                  <select
                    className="editBtn  rounded-3 mt-0 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={2}
                    disabled
                  >
                    <option hidden>Select User</option>
                    <option>Roger</option>
                    <option>Sakthi</option>
                  </select>
                </div>
                <div className="w-50 px-2 supp-select">
                  <p className="f3 fs-12 fs-sm-15 fs-md-16">Status</p>
                  <select
                    className="editBtn  rounded-3 mt-0 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={1}
                    disabled
                  >
                    <option>Yet to start</option>
                    <option>In Process</option>
                    <option>Completed</option>
                  </select>
                </div>
                <div className="w-50 px-2 mb-4">
                  <div className="w-100">
                    <p className="f3 fs-12 fs-sm-15 fs-md-16">
                      Date of completion
                    </p>
                    <input
                      placeholder="Date of completion"
                      className=" editBtn rounded-3  p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      // onChange={(e) => setLast_name(e.target.value)}
                      // value={last_name}
                      type="date"
                      // disabled={type == "view" ? true : false}
                      readOnly
                    />
                  </div>
                </div>
                <div className="w-50 px-2 mb-4">
                  <div className="w-100">
                    <p className="f3 fs-12 fs-sm-15 fs-md-16">Breach of SLA</p>
                    <input
                      placeholder="Breach of SLA"
                      className=" editBtn rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      // onChange={(e) => setLast_name(e.target.value)}
                      // value={last_name}
                      // disabled={type == "view" ? true : false}
                      readOnly
                    />
                  </div>
                </div>
                <div className="w-50 px-2 mb-4">
                  <div className="w-100">
                    <p className="f3 fs-12 fs-sm-15 fs-md-16">Descriptions</p>
                    <textarea
                      rows={4}
                      placeholder="Descriptions"
                      className=" editBtn rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      // onChange={(e) => setLast_name(e.target.value)}
                      // value={last_name}
                      // disabled={type == "view" ? true : false}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className=" w-100 px-2 mt-3">
                <p className="f3 fs-14 fs-sm-17 fs-md-19 text-center">
                  Comments
                </p>
              </div>

              {/* Profile Update View */}
              <div>
                <li className="d-flex flex-wrap mb-3">
                  <div className="d-flex as-jc mt-3 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                    <div className="d-flex-ac-jc mb-3 mb-md-0">
                      <img src={profilePic} className="sm_img_size" />
                    </div>
                    <div className="d-flex px-0 px-md-3 w-100">
                      <div className="mx-3 w-100">
                        <div className="d-flex ac-jb">
                          <h5 className="author">
                            <span className="f3 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17">
                              Sakthi
                            </span>
                            <span className="primary1 author_name mx-2 f2 fs-xs-11 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                              5.00 PM
                            </span>
                          </h5>
                        </div>
                        {/* <div>
                            <p className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark">
                              Status - Request
                            </p>
                          </div> */}
                        <div>
                          <div className="editBtn rounded-3 p-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 w-100">
                            <p>
                              like readable English. Many desktop publishing
                              packages and web page editors now use Lorem Ipsum
                              as their default readable English. Many desktop
                              publishing packages and web page editors now use
                              Lorem Ipsum as their default readable English.
                              Many desktop publishing packages and web page
                              editors now use Lorem Ipsum as their default model
                              text and a search for lorem ipsum will uncover
                              many web sites still in
                            </p>
                          </div>
                          <p className="text-start text_auth f2 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <ul>
                  <div className="w-100 line my-4" />
                  <div className="w-100 d-flex ac-jc">
                    <div className="bg_today px-3 py-2 rounded-3">
                      <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                        Yesterday
                      </p>
                    </div>
                  </div>
                  <li className="d-flex flex-wrap">
                    <div className="d-flex as-jc mt-3 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                      <div className="d-flex-ac-jc mb-3 mb-md-0">
                        <img src={profilePic} className="sm_img_size" />
                      </div>
                      <div className="d-flex px-0 px-md-3 w-100">
                        <div className="mx-3 w-100">
                          <div className="d-flex ac-jb">
                            <h5 className="author">
                              <span className="f3 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17">
                                Sakthi
                              </span>
                              <span className="primary1 author_name mx-2 f2 fs-xs-11 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                                5.00 PM
                              </span>
                            </h5>
                          </div>
                          {/* <div>
                            <p className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark">
                              Status - Request
                            </p>
                          </div> */}
                          <div>
                            <div className="editBtn rounded-3 p-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 w-100">
                              <p>
                                like readable English. Many desktop publishing
                                packages and web page editors now use Lorem
                                Ipsum as their default readable English. Many
                                desktop publishing packages and web page editors
                                now use Lorem Ipsum as their default readable
                                English. Many desktop publishing packages and
                                web page editors now use Lorem Ipsum as their
                                default model text and a search for lorem ipsum
                                will uncover many web sites still in
                              </p>
                            </div>
                            <p className="text-start text_auth f2 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul>
                  <div className="w-100 line my-4" />
                  <div className="w-100 d-flex ac-jc">
                    <div className="bg_today px-3 py-2 rounded-3">
                      <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
                        Today
                      </p>
                    </div>
                  </div>
                  <li className="d-flex flex-wrap">
                    <div className="d-flex as-jc mt-3 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                      <div className="d-flex-ac-jc mb-3 mb-md-0">
                        <img src={profilePic} className="sm_img_size" />
                      </div>
                      <div className="d-flex px-0 px-md-3 w-100">
                        <div className="mx-3 w-100">
                          <div className="d-flex ac-jb">
                            <h5 className="author">
                              <span className="f3 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17">
                                Viswa
                              </span>
                              <span className="primary1 author_name mx-2 f2 fs-xs-11 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                                5.00 PM
                              </span>
                            </h5>
                          </div>
                          {/* <div>
                            <p className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark">
                              Status - Request
                            </p>
                          </div> */}
                          <div>
                            <div className="editBtn rounded-3 p-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 w-100">
                              <p>
                                like readable English. Many desktop publishing
                                packages and web page editors now use Lorem
                                Ipsum as their default readable English. Many
                                desktop publishing packages and web page editors
                                now use Lorem Ipsum as their default readable
                                English. Many desktop publishing packages and
                                web page editors now use Lorem Ipsum as their
                                default model text and a search for lorem ipsum
                                will uncover many web sites still in
                              </p>
                            </div>
                            <p className="text-start text_auth f2 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="d-flex flex-wrap mt-5">
                    <div className="d-flex as-jc mt-3 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                      <div className="d-flex-ac-jc mb-3 mb-md-0">
                        <img src={profilePic} className="sm_img_size" />
                      </div>
                      <div className="d-flex px-0 px-md-3 w-100">
                        <div className="mx-3 w-100">
                          <div className="d-flex ac-jb">
                            <h5 className="author">
                              <span className="f3 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17">
                                Krishna Vendor
                              </span>
                              <span className="primary1 author_name mx-2 f2 fs-xs-11 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                                5.00 PM
                              </span>
                            </h5>
                          </div>
                          {/* <div>
                            <p className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark">
                              Status - Request
                            </p>
                          </div> */}
                          <div>
                            <div className="editBtn rounded-3 p-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 w-100">
                              <p>
                                like readable English. Many desktop publishing
                                packages and web page editors now use Lorem
                                Ipsum as their default readable English. Many
                                desktop publishing packages and web page editors
                                now use Lorem Ipsum as their default readable
                                English. Many desktop publishing packages and
                                web page editors now use Lorem Ipsum as their
                                default model text and a search for lorem ipsum
                                will uncover many web sites still in
                              </p>
                            </div>
                            <p className="text-start text_auth f2 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              {/* Profile Update View */}

              <div className="d-flex ac-jb w-100">
                <textarea
                  className="editBtn rounded-3 p-2 my-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 w-80"
                  placeholder="Text Here..."
                />

                <div className=" d-flex flex-wrap mt-0 mb-3">
                  <button className="cust-btn addbtn ms-1  bg-primar text-white f3 fs-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-0 rounded">
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-90 ac-jb flex-md-row flex-column d-flex">
        <button
          onClick={() => navigate(-1)}
          className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default ViewTask;
