import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ImageUplodeComp from "../AddNewProductComps/ImageUplodeComp";
import FormComp from "../AddNewProductComps/FormComp";
import { Col } from "react-bootstrap";
import { currency_list, DefaultTax, sub_menu } from "../../redux/api/DummyJson";
import PaymentDetails from "./Poppup/PaymentDetails";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import VisibilityIcon from "@mui/icons-material/Visibility";

const SalesOrderHeadComp = ({
  setAddContComp,
  poppupAddressToggle,
  collaboratClick,
  onCollaborat,
  PaymenDetTogg,
  paymentDetail,
  setPaymentDeta,
  setBoxRadioBtn,
  boxRadioBtn,
  type,
  setCheckBox,
  checkBox,
  poppupPoToggle,
  poppupViewToggle,
  poppupEmailToggle,
  poppupEmailViewToggle,
}) => {
  const [dropDown, setDropDown] = useState(undefined);

  const [status, setStatus] = useState("");

  const option = ["Client Paid", "Payment Pending"];

  // input states

  const [billingAddress, setBillingAddress] = useState("");
  const [billingAddress2, setBillingAddress2] = useState("");
  const [mainShipAddress, setMainShipAddress] = useState("");
  const [mainShipAddress2, setMainShipAddress2] = useState("");
  const [terms, setTerms] = useState("");
  const [currency, setCurrency] = useState("");
  const [defaultTax, setDefaultTax] = useState("");
  const [otherText, setOtherText] = useState([]);

  const onOptionChangeHandler = (event) => {
    setPaymentDeta(true);
    setStatus(event.target.value);
    console.log("User Selected Value - ", event.target.value);
  };

  const addOtherInfo = (text) => {
    if (+text !== 0) {
      let temp = [...otherText];
      temp.push(text);
      setOtherText(temp);
    }
  };

  return (
    <div className="w-100">
      <div className="w-100 d-flex ac-je">
        <button
          onClick={onCollaborat}
          className="d-flex me-3 pointerView btn_border py-2 px-3 bg-transparent f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
        >
          - {collaboratClick ? "Collapse Info" : "Expand Info"}
        </button>
      </div>

      {status === "Client Paid" && paymentDetail && (
        <PaymentDetails
          PaymenDetTogg={PaymenDetTogg}
          setBoxRadioBtn={setBoxRadioBtn}
          boxRadioBtn={boxRadioBtn}
        />
      )}
      <div className="d-flex flex-column flex-md-row flex-wrap">
        <Col sm={12} md={12} lg={3} className="me-md-3">
          <p
            className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
            style={{ marginLeft: "0px" }}
          >
            Payment Status
          </p>

          <select
            // value={status}
            onChange={onOptionChangeHandler}
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
          >
            <option selected disabled>
              select option
            </option>
            {option.map((option, index) => {
              return <option key={index}>{option}</option>;
            })}
          </select>
        </Col>
        <Col sm={12} md={12} lg={3} className="">
          <p
            className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
            style={{ marginLeft: "0px" }}
          >
            {type == "invoice" ? "Invoice" : "Sales"} Order Date
          </p>

          <input
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            type="date"
          />
        </Col>
      </div>
      {collaboratClick && (
        <>
          <div className="w-100 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
            <p
              className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
              style={{ marginLeft: "0px" }}
            >
              {" "}
              Introduction
            </p>
            <textarea
              type="text"
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
          <div className="w-100 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
            {" "}
            <p
              className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
              style={{ marginLeft: "0px" }}
            >
              Terms and conditions
            </p>
            <textarea
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              placeholder=""
              rows={2}
            />
          </div>
        </>
      )}
      {collaboratClick && (
        <div className="d-flex flex-wrap flex-md-row gap-3">
          <div className="w-100">
            <p
              className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
              style={{ marginLeft: "0px" }}
            >
              Other Information
            </p>

            <select
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
              onChange={(e) => addOtherInfo(e.target.value)}
            >
              <option value={0}>Select Other Information</option>
              <option value={1}>Other Information 1</option>
              <option value={2}>Other Information 2</option>
            </select>
          </div>
          {otherText?.map((item, ind) => {
            return (
              <div key={ind} className="w-45">
                <p className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 py-2">
                  {item == 1
                    ? "Other Information 1"
                    : item == 2
                    ? "Other Information 2"
                    : ""}
                </p>

                <input className="editBtn w-100 rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2" />
              </div>
            );
          })}
        </div>
      )}
      {collaboratClick && (
        <div className="w-100 d-flex as-jb gap-3 flex-column flex-md-row">
          <div className="d-flex as-jb gap-3 w-100 w-md-50 res-flex">
            {/* <div className=''> */}
            <div className="w-100">
              <div className="w-100 drop-dow position-relative ">
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                  style={{ marginLeft: "0px" }}
                >
                  Billing Address
                </p>
                <div className="d-flex position-relative">
                  <input
                    type="text"
                    value={billingAddress}
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                  <button
                    className="drop_down3 cust-btn "
                    onClick={() => setDropDown(1)}
                  >
                    <KeyboardArrowDownIcon className="primary" />
                  </button>
                  <input
                    onClick={() => setAddContComp(true)}
                    type="button"
                    value={"+"}
                    placeholder="Required Field"
                    className="editBtn rounded-3 ms-2 mt-2 px-3 bg-transparent primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 z-0"
                  />
                  {/* <div className='ms-2 bg-transparent editBtn rounded-2 d-flex ac-jc'><p className='p-3 py-0 primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16'>+</p></div> */}
                </div>
                {dropDown === 1 && (
                  <div
                    className="invisible-cont2 z-1"
                    onClick={() => setDropDown(undefined)}
                  />
                )}
                <div
                  className={`${
                    dropDown === 1 && "submenu_1"
                  } submenu_cont_1 overflow-scroll z-2`}
                >
                  {sub_menu?.map((item) => {
                    return (
                      <div className="d-flex hover-cust">
                        <button
                          className="px-2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 f2 cust-btn text-start py-1 w-100"
                          onClick={() => {
                            setBillingAddress(item?.list);
                            setDropDown(undefined);
                          }}
                        >
                          {item?.list}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* <div className="mt-3 w-100 drop-dow position-relative ">
                <div className="d-flex position-relative">
                  <input
                    type="text"
                    value={billingAddress2}
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                  <button
                    className="drop_down3 cust-btn "
                    onClick={() => setDropDown(3)}
                  >
                    <KeyboardArrowDownIcon className="primary" />
                  </button>
                  <input
                    type="button"
                    value={"+"}
                    onClick={poppupAddressToggle}
                    placeholder="Required Field"
                    className="editBtn rounded-3 ms-2 mt-2 px-3 bg-transparent primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 z-0"
                  />
                  {/* <div className='ms-2 bg-transparent editBtn rounded-2 d-flex ac-jc'><p className='p-3 py-0 primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16'>+</p></div> commended 
                </div>
                {dropDown === 3 && (
                  <div
                    className="invisible-cont2 z-1"
                    onClick={() => setDropDown(undefined)}
                  />
                )}
                <div
                  className={`${
                    dropDown === 3 && "submenu_1"
                  } submenu_cont_1 overflow-scroll z-2`}
                >
                  {sub_menu?.map((item) => {
                    return (
                      <div className="d-flex hover-cust">
                        <button
                          className="px-2 cust-btn text-start py-1 w-100 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 f2 "
                          onClick={() => {
                            setBillingAddress2(item?.list);
                            setDropDown(undefined);
                          }}
                        >
                          {item?.list}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div> */}
              <div className="w-100 drop-dow mt-3">
                <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  3SK billa
                </p>
                <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  Forum Mall
                </p>
                <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  Arcot road,Vadapalani
                </p>
                <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  Chennai
                </p>
                <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  Chennai, India
                </p>
              </div>
              <div className="w-100 drop-dow mt-3">
                <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  sakthivelsninos@gmail.com
                </p>
                <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  W: 7358144805
                </p>
              </div>
            </div>
            <div className="w-100">
              <div className="w-100 drop-dow position-relative ">
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                  style={{ marginLeft: "0px" }}
                >
                  Main Shipping Address
                </p>
                <div className="d-flex position-relative">
                  <input
                    type="text"
                    value={mainShipAddress}
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                  <button
                    className="drop_down3 cust-btn "
                    onClick={() => setDropDown(2)}
                  >
                    <KeyboardArrowDownIcon className="primary" />
                  </button>
                  <input
                    onClick={() => setAddContComp(true)}
                    type="button"
                    value={"+"}
                    placeholder="Required Field"
                    className="editBtn rounded-3 ms-2 mt-2 px-3 bg-transparent primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 z-0"
                  />
                  {/* <div className='ms-2 bg-transparent editBtn rounded-2 d-flex ac-jc'><p className='p-3 py-0 primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16'>+</p></div> */}
                </div>
                {dropDown === 2 && (
                  <div
                    className="invisible-cont2 z-1"
                    onClick={() => setDropDown(undefined)}
                  />
                )}
                <div
                  className={`${
                    dropDown === 2 && "submenu_1"
                  } submenu_cont_1 overflow-scroll z-2`}
                >
                  {sub_menu?.map((item) => {
                    return (
                      <div className="d-flex hover-cust">
                        <button
                          className="px-2 cust-btn text-start py-1 w-100 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 f2 "
                          onClick={() => {
                            setMainShipAddress(item?.list);
                            setDropDown(undefined);
                          }}
                        >
                          {item?.list}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* <div className="w-100 drop-dow position-relative mt-3">
                <div className="d-flex position-relative">
                  <input
                    type="text"
                    value={mainShipAddress2}
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                  <button
                    className="drop_down3 cust-btn "
                    onClick={() => setDropDown(4)}
                  >
                    <KeyboardArrowDownIcon className="primary" />
                  </button>
                  <input
                    type="button"
                    value={"+"}
                    onClick={poppupAddressToggle}
                    placeholder="Required Field"
                    className="editBtn rounded-3 ms-2 mt-2 px-3 bg-transparent primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 z-0"
                  />
                  {/* <div className='ms-2 bg-transparent editBtn rounded-2 d-flex ac-jc'><p className='p-3 py-0 primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16'>+</p></div> commended
                </div>
                {dropDown === 4 && (
                  <div
                    className="invisible-cont2 z-1"
                    onClick={() => setDropDown(undefined)}
                  />
                )}
                <div
                  className={`${
                    dropDown === 4 && "submenu_1"
                  } submenu_cont_1 overflow-scroll z-2`}
                >
                  {sub_menu?.map((item) => {
                    return (
                      <div className="d-flex hover-cust">
                        <button
                          className="px-2 cust-btn text-start py-1 w-100 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 f2 "
                          onClick={() => {
                            setMainShipAddress2(item?.list);
                            setDropDown(undefined);
                          }}
                        >
                          {item?.list}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div> */}
              <div className="w-100 d-flex flex-column flex-sm-row">
                <div className="ps-sm-4 w-100 drop-dow mt-3">
                  <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    3SK billa
                  </p>
                  <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Forum Mall
                  </p>
                  <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Arcot road,Vadapalani
                  </p>
                  <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Chennai
                  </p>
                  <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Chennai, India
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex flex-column flex-sm-row">
                <div className="ps-sm-4 w-100 drop-dow mt-3">
                  <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    sakthivelsninos@gmail.com
                  </p>
                  <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    W: 7358144805
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex as-jb gap-3 flex-column flex-md-row w-100 w-md-50 res-flex">
            <div className="w-100">
              <div className="d-flex flex-wrap ac-jb flex-m-r">
                <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                  <p
                    className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                    style={{ marginLeft: "0px" }}
                  >
                    Terms
                  </p>
                  <div className="position-relative">
                    <input
                      value={terms}
                      type="text"
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    />
                    <button
                      onClick={() => setDropDown(5)}
                      className="drop_down cust-btn"
                    >
                      <KeyboardArrowDownIcon />
                    </button>
                  </div>
                  {dropDown === 5 && (
                    <div
                      className="invisible-cont2 z-1"
                      onClick={() => setDropDown(undefined)}
                    />
                  )}
                  <div
                    className={`${
                      dropDown === 5 && "submenu_1"
                    } submenu_cont_1 overflow-scroll z-2`}
                  >
                    {sub_menu?.map((item) => {
                      return (
                        <div className="d-flex hover-cust">
                          <button
                            className="px-2 cust-btn text-start py-1 w-100 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 f2 "
                            onClick={() => {
                              setTerms(item?.list);
                              setDropDown(undefined);
                            }}
                          >
                            {item?.list}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                  <p
                    className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                    style={{ marginLeft: "0px" }}
                  >
                    Currency
                  </p>
                  <div className="position-relative">
                    <input
                      type="text"
                      value={currency}
                      placeholder="Required Field"
                      disabled
                      className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    />
                    <button
                      className="drop_down cust-btn"
                      onClick={() => setDropDown(6)}
                    >
                      <KeyboardArrowDownIcon />
                    </button>
                  </div>
                  {dropDown === 6 && (
                    <div
                      className="invisible-cont2 z-1"
                      onClick={() => setDropDown(undefined)}
                    />
                  )}
                  <div
                    className={`${
                      dropDown === 6 && "submenu_1 h-auto"
                    } submenu_cont_1 overflow-scroll z-2`}
                  >
                    {currency_list?.map((item) => {
                      return (
                        <div className="d-flex hover-cust">
                          <button
                            className="px-2 cust-btn text-start py-1 w-100 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 f2 "
                            onClick={() => {
                              setCurrency(item?.list);
                              setDropDown(undefined);
                            }}
                          >
                            {item?.list}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                  <p
                    className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                    style={{ marginLeft: "0px" }}
                  >
                    In Hands Date
                  </p>
                  <div className="position-relative">
                    <input
                      type="date"
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    />
                    {/* <button className='drop_down cust-btn'>
                                        <KeyboardArrowDownIcon />
                                    </button> */}
                  </div>
                </div>
                <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                  <p
                    className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                    style={{ marginLeft: "0px" }}
                  >
                    Expiry Date
                  </p>
                  <div className="position-relative">
                    <input
                      type="date"
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    />
                  </div>
                </div>
                <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                  <p
                    className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                    style={{ marginLeft: "0px" }}
                  >
                    Default Margin
                  </p>
                  <div className="position-relative">
                    <input
                      type="text"
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    />
                  </div>
                </div>
                <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                  <p
                    className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                    style={{ marginLeft: "0px" }}
                  >
                    Tax Type
                  </p>
                  <div className="position-relative">
                    <input
                      value={defaultTax}
                      type="text"
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    />
                    <button
                      onClick={() => setDropDown(7)}
                      className="drop_down cust-btn"
                    >
                      <KeyboardArrowDownIcon />
                    </button>
                  </div>
                  {dropDown === 7 && (
                    <div
                      className="invisible-cont2 z-1"
                      onClick={() => setDropDown(undefined)}
                    />
                  )}
                  <div
                    className={`${
                      dropDown === 7 && "submenu_1"
                    } submenu_cont_1 overflow-scroll z-2 h-auto`}
                  >
                    {DefaultTax?.map((item) => {
                      return (
                        <div className="d-flex hover-cust">
                          <button
                            className="px-2 cust-btn text-start py-1 w-100 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 f2 "
                            onClick={() => {
                              setDefaultTax(item?.list);
                              setDropDown(undefined);
                            }}
                          >
                            {item?.list}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                  <p
                    className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                    style={{ marginLeft: "0px" }}
                  >
                    Customer PO
                  </p>
                  <div className="position-relative">
                    <input
                      type="text"
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    />
                  </div>
                </div>

                {/* <div className="d-flex ae-je w-100 py-3">
                                <div className="d-flex gap-2">
                                    <input type="checkbox" className='cp' />
                                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">This is a sample order</p>
                                </div>
                            </div> */}

                {type == "invoice" && (
                  <div className="d-flex ae-je w-100 py-3">
                    <div>
                      <p
                        className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                        style={{ marginLeft: "0px" }}
                      >
                        Invoice View
                      </p>
                      <div className="d-flex gap-3 mt-1">
                        <div className="d-flex gap-2">
                          <input
                            type="checkbox"
                            className="cp"
                            checked={checkBox == 0}
                            onClick={() => setCheckBox(0)}
                          />
                          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                            Detail
                          </p>
                        </div>
                        <div className="d-flex gap-2">
                          <input
                            type="checkbox"
                            className="cp"
                            checked={checkBox == 1}
                            onClick={() => setCheckBox(1)}
                          />
                          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                            Summary
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {boxRadioBtn && (
                <div className="w-xl-100 mt-4 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                  <p
                    className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                    style={{ marginLeft: "0px" }}
                  >
                    Payment Type
                  </p>
                  <div
                    onClick={() => setBoxRadioBtn(1)}
                    className={`${
                      boxRadioBtn === 1 ? "ps-2 bg-transparent" : " "
                    } ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 `}
                  >
                    <button className="d-flex gap-3  ac-jb cust-btn w-100">
                      <div className="d-flex ac-jc gap-3">
                        <div className="radio_btn d-flex ac-jc">
                          <div
                            className={`${
                              boxRadioBtn === 1 ? "sm_radio" : "sm_radio1"
                            } `}
                          />
                        </div>
                        <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          PO
                        </p>
                      </div>
                      {boxRadioBtn == 1 && (
                        <input
                          type="text"
                          placeholder="PO Number"
                          className="editBtn w-50 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        />
                      )}
                    </button>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      onClick={() => poppupPoToggle()}
                      className="cust-btn addbtn mt-sm-3 ms-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                    >
                      Upload <FileUploadIcon />
                    </button>
                    <button
                      onClick={() => poppupViewToggle(true)}
                      className="primary editBtn  rounded-3 mt-sm-3 ms-3 mt-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    >
                      view <VisibilityIcon />
                    </button>
                  </div>
                  <div
                    onClick={() => setBoxRadioBtn(2)}
                    className={`${
                      boxRadioBtn === 2 ? "ps-2 bg-transparent" : " "
                    } ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 `}
                  >
                    <button className="d-flex gap-3  ac-jb cust-btn w-100">
                      <div className="d-flex ac-jc gap-3">
                        <div className="radio_btn d-flex ac-jc">
                          <div
                            className={`${
                              boxRadioBtn === 2 ? "sm_radio" : "sm_radio1"
                            } `}
                          />
                        </div>
                        <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          Email
                        </p>
                      </div>
                      {boxRadioBtn == 2 && (
                        <input
                          type="date"
                          placeholder="PO Number"
                          className="editBtn w-50 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        />
                      )}
                      {boxRadioBtn == 1 && (
                        <input
                          type="date"
                          placeholder="PO Number"
                          className="editBtn w-50 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        />
                      )}
                    </button>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      onClick={() => poppupEmailToggle()}
                      className="cust-btn addbtn mt-sm-3 ms-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                    >
                      Upload <FileUploadIcon />
                    </button>
                    <button
                      onClick={() => poppupEmailViewToggle(true)}
                      className="primary editBtn  rounded-3 mt-sm-3 ms-3 mt-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    >
                      view <VisibilityIcon />
                    </button>
                  </div>
                  <div
                    onClick={() => setBoxRadioBtn(3)}
                    className={`${
                      boxRadioBtn === 2 ? "ps-2 bg-transparent" : " "
                    } ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 `}
                  >
                    <button className="d-flex gap-3  ac-js cust-btn">
                      <div className="radio_btn d-flex ac-jc">
                        <div
                          className={`${
                            boxRadioBtn === 3 ? "sm_radio" : "sm_radio1"
                          }`}
                        />
                      </div>
                      <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        Payment gateway
                      </p>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {/* </div> */}
    </div>
  );
};

export default SalesOrderHeadComp;
