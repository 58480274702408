import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import {
  sub_menu,
  Product_Name,
  Hsn_type,
  Hsn_type_Child2,
  Hsn_type_Child3,
  Hsn_type_Child4,
  catogory_setting,
  counting,
} from "../../redux/api/DummyJson";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PricingComp from "./PricingComp";
const FormComp = ({
  priceErr,
  vendor,
  list,
  setDescription,
  description,
  setAdminPrice,
  adminPrice,
  decorativeId,
  setDecorativeId,
  onSubmit,
  descriptionErr,
  btn,
  setDecorativeMethod,
  decorativeMethod,
  decorativeMethodErr,
  setPriceErr,
  type,
  adminPriceErr,
  setShowPricing,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [priceName, setPriceName] = useState("");
  // Toggle List
  const [catogoryShow, setCatogoryShow] = useState(false);
  const [catogoryShow2, setCatogoryShow2] = useState(false);
  const [catogoryShow3, setCatogoryShow3] = useState(false);
  const [productNameShow, setProductNameShow] = useState(false);
  const [childMenu, setChildMenu] = useState(false);
  const [childMenu2, setChildMenu2] = useState(false);
  const [decorativeShow, setDecorativeShow] = useState(false);
  const [hsnTypeShow, setHsnTypeShow] = useState(false);
  const [PackingSizeShow, setPackingSizeShow] = useState(false);
  const [countryShow, setCountryShow] = useState(false);
  const [uomShow, setuomShow] = useState(false);
  const [dropDown, setDropDown] = useState(null);
  const [decorativeList, setDecorativeList] = useState([]);

  //

  const [subcatogory, setSubcatogory] = useState(false);
  const [subcatogory2, setSubcatogory2] = useState(false);
  const [subcatogory3, setSubcatogory3] = useState(false);

  const [Dropdown, setDrop] = useState(0);

  // input box
  const [catogory, setCatogory] = useState("");
  const [catogory2, setCatogory2] = useState("");
  const [catogory3, setCatogory3] = useState("");
  const [productName, setProductName] = useState("");
  const [productSuplierCode, setProductSuplierCode] = useState("");
  // const [decorativeMethod, setDecorativeMethod] = useState("");
  const [hsnType, sethsnType] = useState("");
  const [packingSize, setPackingSize] = useState("");
  const [country, setCountry] = useState("");
  const [uomCont, setuomCont] = useState("");
  const [ISCount, setISCount] = useState("");
  const [color1, setColor1] = useState("");
  const [color2, setColor2] = useState("");
  const [color3, setColor3] = useState("");
  const [productLink, setProductLink] = useState("");

  // Select Fuctction

  const [selectCondtion, setSelectCondtion] = useState("");
  const [check, setCheck] = useState([]);
  const [searchValueSub, setSearchValueSub] = useState("");
  const [selectALLSub, setSelectALLSub] = useState(true);

  useEffect(() => {
    if (list?.length > 0) {
      setDecorativeList(list);
    }
  }, [list]);

  // Decoration Method START
  const allSelectSub = () => {
    let decorativeListId = [];
    let decorativeListName = [];
    if (selectALLSub) {
      decorativeList?.map((item, ind) => {
        decorativeListId.push(item?.id);
        decorativeListName.push(item?.name);
        // setDecorationMethod(item?.id);
      });

      setDecorativeId(decorativeListId);
      setDecorativeMethod(decorativeListName);
    } else {
      setDecorativeId(decorativeListId);
      setDecorativeMethod(decorativeListName);
    }
  };

  const searchSub = (event) => {
    let temp = [...list];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setDecorativeList(search);
    } else {
      setDecorativeList(list);
    }

    setSearchValueSub(event);
  };

  const onSubSelect = (event, id, type) => {
    setDecorativeId(id);
    setDecorativeMethod(event);
    setSearchValueSub("");
    setDecorativeList(list);
    setPriceName(event);
    setDropDown(null);
  };
  // Decoration Method END

  return (
    <div className="w-100">
      <div className="d-flex flex-wrap ac-jb flex-m-r">
        <div className="w-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Decoration Method
          </p>
          <div className="position-relative">
            <input
              placeholder="Decorative method"
              type="text"
              value={
                decorativeMethod?.length > 0
                  ? decorativeMethod?.toString()
                  : "Select Decorative method"
              }
              onClick={() => {
                setDropDown(0);
              }}
              className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
            />
            <button
              className="drop_down cust-btn"
              onClick={() => {
                setDropDown(0);
              }}
            >
              <KeyboardArrowDownIcon />
            </button>
            {dropDown == 0 && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => {
                  setDropDown(null);
                }}
              />
            )}
            <div
              className={`${
                dropDown == 0 && "submenu_1 py-2"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              <div className="d-flex gap-1">
                <input
                  className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                  placeholder="Search..."
                  onChange={(e) => searchSub(e.target.value)}
                  value={searchValueSub}
                />
              </div>
              {decorativeList?.map((item, ind) => {
                return (
                  <button
                    className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                    key={ind}
                  >
                    <button
                      className="px-2 cust-btn text-start py-1 w-100 "
                      onClick={() => {
                        onSubSelect(item?.name, item?.id);
                      }}
                    >
                      {item?.name}
                    </button>
                  </button>
                );
              })}
            </div>
          </div>

          {decorativeMethod?.length == "" && decorativeMethodErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter DecorativeMethod</p>
            </div>
          )}
        </div>

        <div className="w-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
            Decoration Description
          </p>
          <textarea
            placeholder="Decoration Description"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {description?.length == "" && descriptionErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Description</p>
            </div>
          )}
        </div>
      </div>
      <PricingComp
        priceErr={priceErr}
        vendor={vendor}
        priceName={priceName}
        setAdminPrice={setAdminPrice}
        adminPrice={adminPrice}
        setPriceErr={setPriceErr}
        decorativeMethod={decorativeMethod}
        adminPriceErr={adminPriceErr}
        setShowPricing={setShowPricing}
      />
      <div className="d-flex as-jb w-10 h-50 mt-5">
        <button
          className="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
        <button
          className={`cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            btn && "opacity-50"
          }`}
          disabled={btn ? true : false}
          onClick={() => onSubmit()}
        >
          {type == "edit" ? "Edit" : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default FormComp;
