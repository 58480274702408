// @ts-nocheck
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ImageCroper from "../../../components/Popup/ImageCroper";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useHqEditMutation, useLazyHq_viewQuery } from "../../../redux/api/api";
import { noLogo } from "../../../assets/img";
import ErrorIcon from "@mui/icons-material/Error";
import JoditEditor from "jodit-react";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../../redux/slice/loaderNoti";
import DOMPurify from "dompurify";

const SpecialOfferEdit = ({ placeholder }) => {
  const editor = useRef(null);
  const imgRef = useRef();
  const [content, setContent] = useState("");
  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: placeholder || "Start typings...",
    }),
    [placeholder]
  );
  console.log(content, "text");

  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  const id = location?.state?.data[0]?.id;
  const [data, setData] = useState("");
  const [action, setAction] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [croper, setCroper] = useState(false);
  const [btn, setBtn] = useState(false);

  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [buttonName, setButtonName] = useState("");
  const [link, SetLink] = useState("");
  const dispatch = useDispatch();

  const [nameErr, setNameErr] = useState(false);
  const [imageErr, setImageErr] = useState(false);
  const [descriptionErr, setDescriptionErr] = useState(false);
  const [buttonNameErr, setButtonNameErr] = useState(false);
  const [linkErr, SetLinkErr] = useState(false);
  const type = location?.state?.type;
  console.log(location?.state?.type);

  useEffect(() => {
    setData(location?.state?.data);
  }, [location]);

  console.log(data);
  //Rtk

  const [hqEditApi] = useHqEditMutation();
  const [hqViewApi] = useLazyHq_viewQuery();

  const hqView = () => {
    hqViewApi(id)
      .unwrap()
      .then((res) => {
        const json = JSON.parse(res?.setting_detail?.contents);
        console.log(res, "oo");
        setName(json?.name);
        setImage(res?.setting_detail?.image_url);
        setDescription(json?.description);
        setButtonName(json?.button_name);
        SetLink(json?.link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(image, "imafe");

  useEffect(() => {
    if (id) {
      hqView();
    }
  }, []);

  // useEffect(()=>{
  //   if(croperImage){
  //     fetch(croperImage)
  //     .then((res) => res.blob())
  //     .then((blob) => {
  //       const file = new File([blob], "File name", { type: "image/png" });
  //       setImage(file);
  //     });
  //   }

  // },[croperImage])
  const submitHandle = () => {
    if (
      name == "" ||
      description == "" ||
      buttonName == "" ||
      link == "" ||
      image == ""
    ) {
      setNameErr(true);
      setImageErr(true);
      setDescriptionErr(true);
      setButtonNameErr(true);
      SetLinkErr(true);
    } else {
      const formdata = new FormData();
      if (id) {
        formdata.append("setting_id", id);
      }
      formdata.append("name", name);
      formdata.append("description", description);
      formdata.append("button_name", buttonName);
      formdata.append("link", link);
      formdata.append("type", 3);
      if (image?.name) {
        formdata.append("image", image);
      }
      // formData.append("priority",)
      setBtn(true);
      dispatch(saveLoader(true));
      hqEditApi(formdata)
        .unwrap()
        .then((res) => {
          setBtn(false);
          dispatch(saveLoader(false));
          navigate("/special-offers-detail");
        })
        .catch((err) => {
          setBtn(false);
          dispatch(saveLoader(false));
          console.log(err);
        });
    }
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <p
        className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-0 mb-3 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon /> Special Offer Edit
      </p>
      <div className="w-100 searchField mt-0 bg-white ">
        <div className="w-100 d-flex flex-xxl-row flex-xl-row flex-lg-row flex-column">
          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Name:<span style={{ color: "#f82a2a" }}>(100words only)</span>
                {type == "add" && type !== "view" && <span>*</span>}
              </p>
              <div className="w-50">
                <input
                  className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  type="text"
                  placeholder="The HQ exclusive."
                  value={name?.substring(0, 100)}
                  onChange={(e) => setName(e.target.value)}
                  maxLength={100}
                />

                {name?.length == 0 && nameErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Name</p>
                  </div>
                )}
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Special Offer Image:
                <span style={{ color: "#f82a2a" }}>(100px*250px)</span>
                {type == "add" && type !== "view" && <span>*</span>}
              </p>

              <div className="w-70 ">
                <div className="w-50">
                  <img
                    src={
                      image?.name
                        ? URL.createObjectURL(image)
                        : image
                        ? image
                        : noLogo
                    }
                    className="imgDashboard"
                    role={"button"}
                    onClick={() => imgRef.current.click()}
                  />
                  {image?.length == 0 && imageErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter Image</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Description:
                <span style={{ color: "#f82a2a" }}>(250words only)</span>
                {type == "add" && type !== "view" && <span>*</span>}
              </p>
              <div className="w-100">
                <JoditEditor
                  className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  ref={editor}
                  value={description}
                  config={config}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={(newContent) => setDescription(newContent)} // preferred to use only this option to update the content for performance reasons
                  onChange={(newContent) => {
                    setDescription(newContent);
                  }}
                />

                {/* <textarea
                className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                type="text"
                placeholder="Here is our monthly product spotlight! Unlock this exceptional deal available only for this month. Act quickly and don’t miss out on this exclusive offer!"
                value={description}
                onChange={(e)=>setDescription(e.target.value)}
              /> */}
                {description?.length == 0 && descriptionErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Description</p>
                  </div>
                )}
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Button Name:{type == "add" && type !== "view" && <span>*</span>}
              </p>
              <div className="w-50">
                <input
                  className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  type="text"
                  placeholder="Order now"
                  value={buttonName}
                  onChange={(e) => setButtonName(e.target.value)}
                />
                {buttonName?.length == 0 && buttonNameErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Button Name</p>
                  </div>
                )}
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Button Link:{type == "add" && type !== "view" && <span>*</span>}
              </p>
              <div className="w-50">
                <input
                  className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  type="text"
                  placeholder="https://dev.merchhq.io/"
                  value={link}
                  onChange={(e) => SetLink(e.target.value)}
                />
                {/* https://dev.merchhq.io/ */}
                {link?.length == 0 && linkErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Link</p>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Priority Status:
              </p>

              <input
                className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                type="number"
              />
            </div> */}

            {/* <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Status
              </p>

              <select
                placeholder=""
                className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                name=""
                id=""
              >
                <option>Active</option>
                <option>De Active</option>
              </select>
            </div> */}
          </div>
        </div>
        <input
          className="d-none"
          ref={imgRef}
          type={"file"}
          onChange={(e) => setImage(e.target.files[0])}
        />
      </div>
      <div className="d-flex ac-jb">
        <button
          onClick={() => navigate(-1)}
          className="pointerView cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
        >
          Back
        </button>
        {type !== "view" && (
          <button
            className={`cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${
              btn && "opacity-50"
            }`}
            onClick={submitHandle}
            disabled={btn ? true : false}
          >
            submit
          </button>
        )}
      </div>
    </div>
  );
};

export default SpecialOfferEdit;
