import React from "react";
import LeftComp from "./LeftComp";
import RightComp from "./RightComp";
import ImageRightComp from "./ImageRightComp";
import PricingPage from "../../routes/PricingPage";
import PricingBoxComp from "./PricingBoxComp";

const OverViewComp = ({ edit, types }) => {
  return (
    <>
      <div className="d-flex as-jb w-100 mt-5 res-flex gap-5">
        <LeftComp edit={edit} types={types} />
        <RightComp types={types} edit={edit} />
        {/* <ImageRightComp /> */}
      </div>
      <PricingBoxComp />
      {/* <div className="h-auto mt-4">
        <p class="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary mt-5">
          Vendor Pricing
        </p>
        <div className="">
          <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-3">
            Charles Supplier
          </p>
          <div className="overflow-scroll">
            <table className="w-100">
              <tr className="mt-4">
                <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Qty
                </th>
                <div className="my-3">
                  <td>
                    <div className="d-flex w-100">
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="button"
                          value="25"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="button"
                          value="50"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="button"
                          value="100"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="button"
                          value="150"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="button"
                          value="250"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="button"
                          value="500"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="button"
                          value="1000"
                        />
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
              <tr>
                <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  Net
                </th>
                <div className="mt-2">
                  <td>
                    <div className="d-flex w-100">
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          type="number"
                          placeholder="₹90.00"
                          maxlength={3}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹85.00"
                          type="number"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹85.00"
                          type="number"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹75.00"
                          type="number"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹75.00"
                          type="number"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹75.00"
                          type="number"
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="₹75.00"
                          type="number"
                        />
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
            </table>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default OverViewComp;
