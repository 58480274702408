import { Search } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import manImage from "../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import DropdownTap from "../components/ProductComp/ProductDropdownTap";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import ProductListComp from "../components/ProductComp/ProductListComp";
import ProductLibraryComp from "../components/ProductLibrary/ProductLibraryComp";
import ProductListLibraryComp from "../components/ProductLibrary/ProductListLibraryComp";
import { searchIcon, uploadIcon } from "../assets/img";
import UploadIcon from "@mui/icons-material/Upload";
import { useDispatch } from "react-redux";
import { saveAlertMessage } from "../redux/slice/alertMessage";
import ErrorIcon from "@mui/icons-material/Error";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  useDownloadAdminProfileMutation,
  useDownloadProductLibraryMutation,
  useDownloadSampleExcelsMutation,
  useProdBulkStatusMutation,
  useProductListMutation,
} from "../redux/api/api";
import { saveLoader } from "../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";
import { Col, Modal, Row } from "react-bootstrap";

const ProductLibrary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(Path?.state);
  // console.log(location);
  const [importList, setImportList] = useState(false);
  const [product, setProduct] = useState(true);
  const [decorative, setDecorative] = useState(false);
  const [both, setBoth] = useState(false);
  const [productList, setParoductList] = useState("");

  const [list, setList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [pageRow, setPageRow] = useState(20);
  const [page, setPage] = useState(1);
  const [pagenation, setPagination] = useState({});
  const [categoryID, setCategoryID] = useState("");
  const [vendorID, setVendorID] = useState("");
  const [tagID, setTagID] = useState("");
  const [productName, setProductName] = useState("");
  const [netMin, setNetMin] = useState("");
  const [netMax, setNetMax] = useState("");

  const fileRef = useRef();
  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState(false);
  const [file, setFile] = useState("");
  const [btn, setBtn] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState([]);

  // RTK QUERY
  const [productListApi] = useProductListMutation();
  const [productBulkStatusApi] = useProdBulkStatusMutation();
  const [importApi] = useDownloadSampleExcelsMutation();
  const [uploadApi] = useDownloadProductLibraryMutation();

  const getProducts = (
    event,
    status,
    category,
    row,
    product_name,
    vendor_id,
    tag_id,
    net_min,
    net_max
  ) => {
    let params = `?page=${page}`;
    let formdata = new FormData();

    if (event?.length > 0) {
      formdata.append("search", event);
    }

    if (status) {
      formdata.append("status", status);
    }

    if (category) {
      formdata.append("category_id", category);
    }

    if (row) {
      formdata.append("rows", row);
    }

    if (product_name) {
      formdata.append("productName", product_name);
    }

    if (vendor_id) {
      formdata.append("vendor_id", vendor_id);
    }

    if (tag_id) {
      formdata.append("tag_id", tag_id);
    }

    if (net_min) {
      formdata.append("min_price", net_min);
    }

    if (net_max) {
      formdata.append("max_price", net_max);
    }

    setSearchValue(event);
    setStatusValue(status);
    setCategoryID(category);
    setPageRow(row);
    setProductName(product_name);
    setVendorID(vendor_id);
    setTagID(tag_id);
    setNetMin(net_min);
    setNetMax(net_max);

    dispatch(saveLoader(true));
    productListApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        setList(res?.lists);
        setPagination(res?.pagination_meta);
        dispatch(saveLoader(false));
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  useEffect(() => {
    getProducts("", "", "", 20);
  }, [page]);

  // useEffect(() => {
  //   product && setParoductList("product");
  //   decorative && setParoductList("decorative");
  //   both && setParoductList("both");
  // }, []);
  // console.log("locationtwo", locationtwo);

  const showPopupHander = () => {
    setImportList(false);
    setImage("");
    setImageErr(false);
  };

  // IMPORT EXCEL
  const getAdminImports = () => {
    let formdata = new FormData();
    formdata.append("type", 10);
    importApi(formdata)
      .unwrap()
      .then((res) => {
        if (res?.download_link) {
          const link = document.createElement("a");
          link.href = res?.download_link;
          link.setAttribute("download", "");
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
        }
        console.log("res", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // UPLOAD EXCEL
  const uploadExcel = () => {
    if (image?.length == 0) {
      setImageErr(true);
    } else {
      let formdata = new FormData();
      if (image?.name) {
        formdata.append("file", image);
      }

      setBtn(true);
      dispatch(saveLoader(true));
      uploadApi(formdata)
        .unwrap()
        .then((res) => {
          if (res?.message == "Product added with some failures") {
            let json = JSON.parse(res?.failed_rows);
            console.log("json", json);
            setModalData(json);
            setModal(true);
            setImportList(false);
            setShowPopup(false);
            toast.error(res?.message);
            setBtn(false);
            dispatch(saveLoader(false));
          } else {
            toast.success("Uploaded Successfully");
            setImage("");
            setImageErr(false);
            setImportList(false);
            setShowPopup(false);
          }
        })
        .catch((err) => {
          setBtn(false);
          dispatch(saveLoader(false));
          setImageErr(false);
          console.log("err", err);
        });
    }
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      {importList && (
        <div className="modal-popup">
          <div onClick={showPopupHander} className="back-close" />
          <div className="center-content p-4">
            <div className=" d-flex justify-content-end">
              <span>
                <button
                  onClick={() => {
                    showPopupHander();
                    setImage("");
                    setImageErr(false);
                  }}
                  className="cust-btn"
                >
                  <HighlightOffIcon />
                </button>
              </span>
            </div>
            <div>
              <button
                className={`cust-btn addbtn  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded `}
                onClick={() => getAdminImports()}
                // disabled={page == pagenation?.last_page ? true : false}
              >
                Sample Download
              </button>
            </div>
            <p className="d-flex ac-jc f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Import Product
            </p>
            <div className="pointerView w-100 mt-4 ac-jc">
              <div
                onClick={() => fileRef.current.click()}
                className="w-100 d-flex ac-jc"
              >
                {file === "" ? (
                  <img
                    src={uploadIcon}
                    onChange={(e) => setImage(e.target.files[0])}
                    className="mailImg"
                  />
                ) : (
                  <iframe
                    onClick={() => fileRef.current.click()}
                    src={file}
                    className="importExcel"
                  />
                )}
              </div>
              {file === "" && (
                <div
                  onClick={() => {
                    fileRef.current.click();
                  }}
                  className="w-100 d-flex ac-jc"
                >
                  <p className="primary1 w-100 f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 mt-1 d-flex ac-jc">
                    +Import List
                  </p>
                </div>
              )}
              {image?.length == 0 && imageErr && (
                <div className="d-flex ac-jc gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Upload File</p>
                </div>
              )}
              <div className="d-flex ac-jc">
                <button
                  onClick={() => uploadExcel()}
                  disabled={btn}
                  className={`
                  cust-btn addbtn mt-sm-4 ms-1 mt-4 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                    btn && "opacity-50"
                  }`}
                >
                  Submit
                </button>
              </div>
            </div>
            <input
              type="file"
              accept=".xlsx,"
              onChange={(e) => {
                setImage(e.target.files[0]);
              }}
              className="d-none"
              ref={fileRef}
            />
          </div>
        </div>
      )}
      <ProductSearchHeader />
      <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5">
        Product Library
      </p>
      <div className="d-flex mt-2 ac-jb flex-wrap me-md-3 rk2">
        <div className="d-flex mt-3 rk">
          {/* <button
            onClick={() => {
              setProduct(false);
              setDecorative(false);
              setBoth(true);
              setParoductList("both");
            }}
            style={{ cursor: "pointer" }}
            className={`${
              both
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}>
            <p
              className={`${
                both ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12  fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}>
              Both
            </p>
          </button> */}
          <button
            onClick={() => {
              setProduct(true);
              setDecorative(false);
              setBoth(false);
              setParoductList("product");
            }}
            style={{ cursor: "pointer" }}
            className={`${
              product
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                product ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Library List
            </p>
          </button>
          {/* <button
            onClick={() => {
              setProduct(false);
              setDecorative(true);
              setBoth(false);
              setParoductList("decorative");
            }}
            style={{ cursor: "pointer" }}
            className={`${
              decorative
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}>
            <p
              className={`${
                decorative ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}>
              Decorative
            </p>
          </button> */}
        </div>
        <div className="d-flex gap-2">
          {/* <button
            onClick={() => {
              navigate("/add-collaborate");
              // console.log("ll");
            }}
            className="cust-btn orangebtn mt-sm-3 ms-1 mt-2 bg-orange text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            + Add Collaborate
          </button> */}
          <div
            onClick={() => setImportList(!importList)}
            className="pointerView d-flex mt-4 me-3"
          >
            <UploadIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Import
            </p>
          </div>
          <div className="d-flex mt-sm-3 border-search mx-1">
            <img src={searchIcon} alt="search" className="searchiConImg mt-1" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
              onChange={(e) =>
                getProducts(
                  e.target.value,
                  statusValue,
                  categoryID,
                  20,
                  productName,
                  vendorID,
                  tagID,
                  netMin,
                  netMax
                )
              }
              value={searchValue}
            />
          </div>

          <button
            onClick={() => navigate("/products/add-new-product")}
            className="cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            + Add Library
          </button>
        </div>
      </div>
      <ProductLibraryComp
        method={"product-library"}
        getProducts={getProducts}
        statusValue={statusValue}
        categoryID={categoryID}
        vendorID={vendorID}
        tagID={tagID}
        productName={productName}
        netMin={netMin}
        netMax={netMax}
        pageRow={pageRow}
        searchValue={searchValue}
      />
      <ProductListLibraryComp
        productList={productList}
        setParoductList={setParoductList}
        list={list}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        pageRow={pageRow}
        setPageRow={setPageRow}
        page={page}
        setPage={setPage}
        pagenation={pagenation}
        setPagination={setPagination}
        getProducts={getProducts}
        statusValue={statusValue}
        categoryID={categoryID}
        vendorID={vendorID}
        tagID={tagID}
        productName={productName}
        netMin={netMin}
        netMax={netMax}
      />

      <Modal
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16">
            Errors
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div>
              {modalData?.map((item, index) => {
                return (
                  <Row key={index} className="d-flex">
                    <Row>
                      <Col className="d-flex justify-content-between">
                        <h5 style={{ textDecoration: "underline" }}>
                          Failure Reason: {item?.failure_reason}
                        </h5>
                        <h6>S.No. {index + 1}</h6>
                      </Col>
                    </Row>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Primary Category:
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.primary_category}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Secondary Category:{" "}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.secondary_category}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      category:{" "}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.category}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Product Name:
                    </Col>{" "}
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.name}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      MRP:{" "}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.mrp}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Decoration Method:{" "}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.decoration_methods}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Country:{" "}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.country_origin}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Gender:
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.product_for}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Product Description:
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.description}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {" "}
                      HSN Code:
                    </Col>{" "}
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.hsn_code}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Tags:{" "}
                    </Col>{" "}
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.tags}
                    </Col>
                    {/* <Col>Event Tags: {item?.attributes}</Col> */}
                    {/* <Col>Icon Tags: {item?.tags}</Col> */}
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Product Packaging Size:{" "}
                    </Col>{" "}
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.package_size}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {" "}
                      Attributes:{" "}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.attributes}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Colors:{" "}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.colors}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Size:{" "}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.size}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Materials:{" "}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.materials}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      styles:{" "}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.styles}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Product Images:{" "}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.product_images}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      Mockup Images:{" "}
                    </Col>
                    <Col md={6} lg={6} sm={6} className="mt-2 mb-2">
                      {item?.mockup_images}
                    </Col>
                    <Row>
                      <div
                        className="mt-2 mb-2"
                        style={{ borderBottom: "1px solid black" }}
                      ></div>
                    </Row>
                  </Row>
                );
              })}
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProductLibrary;
