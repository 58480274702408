import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const DecorativePricing = ({ decorPriceList, viewData, getDecorPriceList }) => {
  const navigate = useNavigate();
  const [selectedMethod, setSelectedMethod] = useState("");
  const [showPricing, setShowPricing] = useState(true);
  const [decorativeTab, setDecorativeTab] = useState([]);
  const [adminPriceList, setAdminPriceList] = useState([]);
  const [vendorPriorityList, setVendorPriorityList] = useState([]);
  const [decorList, setDecorList] = useState({});

  const getList = () => {
    setSelectedMethod(decorPriceList?.[0]?.decorative_methods?.name);
    setAdminPriceList(
      decorPriceList?.[0]?.decorative_methods?.admin_decor_method_price
    );

    setDecorList(decorPriceList?.[0]?.decorative_methods);

    setDecorativeTab(decorPriceList);

    let temp = [];
    decorPriceList?.[0]?.decorative_methods?.decor_method_price?.map(
      (item, ind) => {
        if (item?.priority) {
          temp.push(item);
        } else {
          temp.push(item);
        }
      }
    );

    const ids = temp.map((item) => item?.vendor?.id);
    const filtered = temp.filter(
      (item, index) => !ids.includes(item?.vendor?.id, index + 1)
    );

    filtered.sort((a, b) => a.priority - b.priority);
    setVendorPriorityList(filtered);
  };

  useEffect(() => {
    if (decorPriceList?.length > 0) {
      getList();
    }
  }, [decorPriceList]);

  const getItem = (item) => {
    let temp = [];
    item?.decorative_methods?.decor_method_price?.map((itemx, indx) => {
      if (itemx?.priority) {
        temp.push(itemx);
      } else {
        temp.push(itemx);
      }
    });

    const ids = temp.map((item) => item?.vendor?.id);
    const filtered = temp.filter(
      (item, index) => !ids.includes(item?.vendor?.id, index + 1)
    );

    filtered.sort((a, b) => a.priority - b.priority);
    setVendorPriorityList(filtered);
    setSelectedMethod(item?.decorative_methods?.name);
    setAdminPriceList(item?.decorative_methods?.admin_decor_method_price);
    setDecorList(item?.decorative_methods);
  };

  // console.log("adminPriceList", adminPriceList);

  return (
    <div className="h-auto mt-4">
      <p class="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
        Supplier Priority List
      </p>
      <div style={{ width: "250px" }} className="my-3 f2 fs-14">
        {vendorPriorityList?.map((item, ind) => {
          return (
            <div className="d-flex my-3 ac-jb" key={ind}>
              <label
                className=""
                role={"button"}
                onClick={() =>
                  navigate(`/product-vendor-details`, {
                    state: {
                      data: item?.vendor,
                    },
                  })
                }
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "royalblue",
                }}
              >
                {item?.vendor?.name}
              </label>
              <div
                className="cust-btn primary p-1 mx-2 text-center"
                style={{
                  border: "1px solid #07679c",
                  borderRadius: "10px",
                  width: "50px",
                }}
              >
                <p>{item?.priority ? item?.priority : "-"}</p>
              </div>
            </div>
          );
        })}
      </div>

      <p className="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
        Vendor Pricing
      </p>
      <div className="mt-3">
        <div role="button" className="d-flex">
          {decorativeTab?.map((item, ind) => {
            return (
              <div
                onClick={() => getItem(item)}
                className={
                  selectedMethod == item?.decorative_methods?.name
                    ? "mx-3 border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                    : "mx-3"
                }
                key={ind}
              >
                <p
                  className={`${
                    selectedMethod === item?.decorative_methods?.name
                      ? "f4 primary"
                      : "f3  gray"
                  }  fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17 `}
                >
                  {item?.decorative_methods?.name}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <button
        class="cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded mt-4"
        // onClick={() => setShowPricing(!showPricing)}
        // disabled
        onClick={() =>
          navigate("/decorative-method-edit", {
            state: { data: decorList, type: "edit" },
          })
        }
      >
        {/* {showPricing ? "Hide Pricing" : "View Pricing"} */}
        Admin Pricing
      </button>
      {showPricing && (
        <div className="overflow-scroll mt-4">
          <table className="w-100">
            <tr className="mt-4">
              <div className="">
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        UOM
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "150px" }}
                      >
                        Inches
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        Color 1 Rate
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        Color 2 Rate
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        Color 3 Rate
                      </p>
                    </div>
                  </div>
                </td>
              </div>
            </tr>
            <tr className="mt-2">
              {adminPriceList?.map((item, ind) => {
                return (
                  <div className="my-3" key={ind}>
                    <td>
                      <div className="d-flex w-100">
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            disabled
                            value={
                              item?.units_of_measurement
                                ? item?.units_of_measurement
                                : ""
                            }
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value={
                              item?.units_of_measurement_count
                                ? item?.units_of_measurement_count
                                : " "
                            }
                            disabled
                            style={{ width: "150px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value={item?.color1_rate ? item?.color1_rate : " "}
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value={item?.color2_rate ? item?.color2_rate : " "}
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div className="mx-2">
                          <input
                            className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                            type="number"
                            value={item?.color3_rate ? item?.color3_rate : " "}
                            disabled
                            style={{ width: "100px" }}
                          />
                        </div>
                      </div>
                    </td>
                  </div>
                );
              })}
            </tr>
          </table>
        </div>
      )}
    </div>
  );
};

export default DecorativePricing;
