import React, { useEffect, useState } from "react";
import {
  editIcon,
  searchIcon,
  toggleOff,
  toggleOn,
  viewIcon,
} from "../assets/img";
import {
  attributeList,
  catRolesList,
  decorativeMethodList,
  relesList,
} from "../redux/api/DummyJson";
import { Search } from "@mui/icons-material";
import CachedIcon from "@mui/icons-material/Cached";
import AddAttributesPopup from "../components/Popup/AddAttributesPopup";
import ImageCroper from "../components/Popup/ImageCroper";
import GetAppIcon from "@mui/icons-material/GetApp";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  useDepartmentRoleMutation,
  useLazyRoleStatusQuery,
} from "../redux/api/api";
import { toast } from "react-hot-toast";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";

const CategoryRoleView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.data;
  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [list, setList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageRow, setPageRow] = useState(10);
  const [page, setPage] = useState(1);

  const toggleShowPopup = (type) => {
    setShowPopup(!showPopup);
    if (type) setPopupType(type);
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  // RTK QUERY
  const [departmentRoleListApi] = useDepartmentRoleMutation();
  const [status] = useLazyRoleStatusQuery();

  const getList = (event, row) => {
    let formdata = new FormData();
    let params = `?page=${page}`;

    setSearchValue(event);

    if (event?.length > 0) {
      formdata.append("search", event);
    }

    if (row) {
      setPageRow(row);
      formdata.append("rows", row);
    }

    departmentRoleListApi({ payload: formdata, params: params, id: data?.id })
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.lists);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  useEffect(() => {
    getList();
  }, [page]);

  const change = (id) => {
    status(id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          toast.success(res?.data?.message || res?.message);
          getList();
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  return (
    <>
      <div className="dashRightView p-5 home_section trans">
        <ProductSearchHeader />
        <div className="d-flex ac-jb mt-2 flex-wrap">
          <div>
            <p
              className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary d-flex ac-jc gap-2"
              role={"button"}
              onClick={() => navigate(-1)}
            >
              <KeyboardBackspaceIcon /> Category Role View
            </p>
            <p
              className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-3 gap-2"
              style={{ textTransform: "capitalize" }}
            >
              {data?.name}
            </p>
          </div>
          <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
            <div className="d-flex">
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
                Show
              </p>
              <select
                className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary"
                onChange={(e) => getList("", e.target.value)}
                value={pageRow}
              >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                Entries
              </p>
            </div>
            <div className="d-flex flex-md-row flex-column">
              {/* <div className="d-flex mt-sm-3">
              <img src={searchIcon} className="searchiConImg" alt="icon" />
              <input
                className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                placeholder="Search"
              />
            </div> */}
              <div className="d-flex ac-jb flex-md-row flex-column">
                <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont me-3 mt-4 ">
                  Total Count : {list?.length}
                </p>
                <div className="pointerView d-flex mt-4 me-3">
                  <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
                  <p
                    className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
                  >
                    Export
                  </p>
                </div>
                <div className="d-flex mt-sm-3 border-search mx-1">
                  <img
                    src={searchIcon}
                    alt="search"
                    className="searchiConImg"
                  />
                  <input
                    className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                    placeholder="Search"
                    onChange={(e) => getList(e.target.value)}
                    value={searchValue}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3" style={{ width: "100%", overflow: "auto" }}>
          <table className="table-design-new" style={{ width: "100%" }}>
            <thead>
              <tr className="border-bottom w-100">
                <th>
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    S.No
                  </p>
                </th>
                <th>
                  {" "}
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Department/ Role Name
                  </p>
                </th>

                <th>
                  {" "}
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Status
                  </p>
                </th>
                <th>
                  {" "}
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Actions
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item, index) => {
                return (
                  <tr>
                    <td>
                      <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                        {index + 1}
                      </p>
                    </td>
                    <td>
                      <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                        {item?.role}
                      </p>
                    </td>

                    <td>
                      <div
                        className="d-flex ac-jc"
                        role={"button"}
                        onClick={() => change(item?.id)}
                      >
                        {item?.status == 1 ? (
                          <div>
                            <img src={toggleOn} className="toggleOnDes" />
                          </div>
                        ) : (
                          <div>
                            <img src={toggleOff} className="toggleOnDes" />
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex ac-jc primary1">
                        <div
                          onClick={() =>
                            navigate("/role-profiles", {
                              state: { type: "view", data: item },
                            })
                          }
                          className="viewBoxDes p-2 mb-2 bg-white rounded-2"
                        >
                          <img src={viewIcon} className="viewDes" />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <button
            className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
              page == 1 && "opacity-50"
            }`}
            onClick={() => setPage(page - 1)}
            disabled={page == 1 ? true : false}
          >
            Previous
          </button>
          <button
            className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            onClick={() => setPage(page + 1)}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default CategoryRoleView;
