import ProfileNavBar from "../../components/ProfileNavBar";
import { Search } from "@mui/icons-material";
import manImage from "../../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import ImageCroper from "../../components/Popup/ImageCroper";
import { useEffect, useState } from "react";
import intelImg from "../../assets/img/download.jpeg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  useActiveRolesQuery,
  useAdminAddMutation,
  useAdminEditMutation,
  useLazyAdminViewQuery,
  useLazyDepartmentActiveRoleListQuery,
  useMasterActiveListMutation,
} from "../../redux/api/api";
import ErrorIcon from "@mui/icons-material/Error";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { saveLoader } from "../../redux/slice/loaderNoti";
import ProductSearchHeader from "../ProductComp/ProductSearchHeader";
import moment from "moment/moment";

const AddTask = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;
  const [image, setImage] = useState();
  const [roles, setRoles] = useState([]);

  // const getView = () => {
  //   viewAdmin(location?.state?.data?.id)
  //     .unwrap()
  //     .then((res) => {
  //       if (res?.status == "success" || res?.data?.status == "success") {
  //         let data = res?.showData || res?.data?.showData;
  //         setFirst_name(data?.first_name);
  //         setLast_name(data?.last_name);
  //         setEmail(data?.email);
  //         setRole(data?.role?.id);
  //         setImage(data?.image_url ? data?.image_url : intelImg);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err");
  //     });
  // };

  // useEffect(() => {
  //   if (location?.state?.data?.id) {
  //     getView();
  //   }
  // }, []);

  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [Email, setEmail] = useState("");
  const [Role, setRole] = useState(1);
  const [status, setStatus] = useState(1);
  const [Password, setPassword] = useState("");
  const [CPassword, setCPassword] = useState("");

  const [first_nameErr, setFirst_nameErr] = useState(false);
  const [last_nameErr, setLast_nameErr] = useState(false);
  const [EmailErr, setEmailErr] = useState(false);
  const [RoleErr, setRoleErr] = useState(false);
  const [PasswordErr, setPasswordErr] = useState(false);
  const [CPasswordErr, setCPasswordErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const submitHandler = () => {
    if (
      first_name?.length == 0 ||
      last_name?.length == 0 ||
      Email?.length == 0 ||
      Role?.length == 0 ||
      (type == "add" && Password?.length == 0) ||
      (type == "add" && Password !== CPassword)
    ) {
      setEmailErr(true);
      setFirst_nameErr(true);
      setLast_nameErr(true);
      setRoleErr(true);
      type == "add" && setPasswordErr(true);
      type == "add" && setCPasswordErr(true);
    } else if (errorMessage?.length == 0) {
      let formdata = new FormData();

      formdata.append("first_name", first_name);
      formdata.append("last_name", last_name);
      formdata.append("email", Email);
      formdata.append("role_id", Role);
      if (image?.name) {
        formdata.append("image", image);
      }
      if (Password && CPassword) {
        formdata.append("password", Password);
        formdata.append("password_confirmation", CPassword);
      }
      setBtn(true);
      dispatch(saveLoader(true));
      if (type == "edit") {
        // formdata.append("_method", "PUT");
        // updateAdmin({ id: location?.state?.data?.id, payload: formdata })
        //   .unwrap()
        //   .then((res) => {
        //     if (res?.status == "success") {
        //       toast.success(res?.data?.message || res?.message);
        //       navigate("/profile");
        //     }
        //     setBtn(false);
        //     dispatch(saveLoader(false));
        //   })
        //   .catch((err) => {
        //     console.log("err");
        //     dispatch(saveLoader(false));
        //     setBtn(false);
        //   });
      } else {
        // createAdmin(formdata)
        //   .unwrap()
        //   .then((res) => {
        //     if (res?.status == "success") {
        //       toast.success(res?.data?.message || res?.message);
        //       navigate("/profile");
        //     }
        //     setBtn(false);
        //     dispatch(saveLoader(false));
        //   })
        //   .catch((err) => {
        //     console.log("err");
        //     dispatch(saveLoader(false));
        //     setBtn(false);
        //   });
      }
    }
  };

  const handlePassword = (event) => {
    let new_pass = event.target.value;
    if (new_pass?.length !== 0) {
      setPassword(new_pass);

      // regular expressions to validate password
      var lowerCase = /[a-z]/g;
      var upperCase = /[A-Z]/g;
      var numbers = /[0-9]/g;
      var special = /[*@!#%&()^~{}]+/g;
      if (!new_pass.match(lowerCase)) {
        setErrorMessage("Password should contains lowercase letters!");
      } else if (!new_pass.match(upperCase)) {
        setErrorMessage("Password should contain uppercase letters!");
      } else if (!new_pass.match(numbers)) {
        setErrorMessage("Password should contains numbers also!");
      } else if (!new_pass.match(special)) {
        setErrorMessage("Password should contains special character also!");
      } else if (new_pass.length < 8) {
        setErrorMessage("Password length should be more than 8.");
      } else {
        // setErrorMessage("Password is strong!");
        setErrorMessage("");
      }
    } else {
      setErrorMessage("");
      setPassword(new_pass);
    }
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      <ProductSearchHeader />
      <p
        className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon /> Add Project Task
      </p>
      <div className="w-90 mt-lg-5 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Task Date<span>*</span>
          </p>
          <input
            placeholder="Task Date"
            className="editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            // onChange={(e) => setFirst_name(e.target.value)}
            value={moment(new Date()).format("YYYY-MM-DD")}
            type="date"
            readOnly
            // disabled={type == "view" ? true : false}
          />
          {first_name?.length == 0 && first_nameErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter First Name</p>
            </div>
          )}
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Task Deadline<span>*</span>
          </p>
          <input
            placeholder="Task Deadline"
            className=" editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            onChange={(e) => setLast_name(e.target.value)}
            value={last_name}
            type="date"
            // disabled={type == "view" ? true : false}
          />
          {first_name?.length == 0 && last_nameErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Last Name``</p>
            </div>
          )}
        </div>
      </div>
      <div className="w-90 mt-lg-5 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Project Associated<span>*</span>
          </p>
          <select
            placeholder=""
            className=" editBtn mt-2 rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            name=""
            id=""
            // onChange={(e) => setRole(e.target.value)}
            value={1}
            // disabled={type == "view" ? true : false}
          >
            <option hidden>Select Project Associated</option>
            {/* {roles?.map((item, ind) => {
              return (
                <option value={item?.id} key={ind}>
                  {item?.role}
                </option>
              );
            })} */}
            <option value={1}>Project 1</option>
            <option value={2}>Project 2</option>
          </select>
          {Role?.length == 0 && RoleErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Select Department/ Role</p>
            </div>
          )}
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Assigned By<span>*</span>
          </p>
          <select
            placeholder=""
            className=" editBtn mt-2 rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            name=""
            id=""
            onChange={(e) => setRole(e.target.value)}
            value={1}
            disabled
            // disabled={type == "view" ? true : false}
          >
            <option hidden>Select Assigned By</option>
            {/* {roles?.map((item, ind) => {
              return (
                <option value={item?.id} key={ind}>
                  {item?.role}
                </option>
              );
            })} */}
            <option value={1}>Roger</option>
            <option>Sakthi</option>
          </select>
          {Role?.length == 0 && RoleErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Select Department/ Role</p>
            </div>
          )}
        </div>
      </div>
      <div className="w-90 mt-lg-5 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Department/ Team<span>*</span>
          </p>
          <input
            placeholder="Department/ Team"
            className=" editBtn mt-2 rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value={"Department1"}
            readOnly
          />
          {Password?.length == 0 && PasswordErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Password</p>
            </div>
          )}
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            User<span>*</span>
          </p>
          <select
            placeholder=""
            className=" editBtn mt-2 rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            name=""
            id=""
            onChange={(e) => setRole(e.target.value)}
            value={Role}
            // disabled={type == "view" ? true : false}
          >
            <option hidden>Select User</option>
            {/* {roles?.map((item, ind) => {
              return (
                <option value={item?.id} key={ind}>
                  {item?.role}
                </option>
              );
            })} */}
            <option>User 1</option>
            <option>User 2</option>
          </select>
          {Role?.length == 0 && RoleErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Select Department/ Role</p>
            </div>
          )}
        </div>
      </div>

      <div className="w-90 mt-lg-5 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Status<span>*</span>
          </p>
          <select
            placeholder=""
            className=" editBtn mt-2 rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            name=""
            id=""
            onChange={(e) => setStatus(e.target.value)}
            value={status}
            // disabled={type == "view" ? true : false}
          >
            {/* {roles?.map((item, ind) => {
              return (
                <option value={item?.id} key={ind}>
                  {item?.role}
                </option>
              );
            })} */}
            <option value={1}>Yet to start</option>
            <option value={2}>In Process</option>
            <option value={3}>Completed</option>
          </select>
          {Password?.length == 0 && PasswordErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Password</p>
            </div>
          )}
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Date of completion<span>*</span>
          </p>
          <input
            placeholder="Date of completion"
            className="editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            // onChange={(e) => setFirst_name(e.target.value)}
            value={moment(new Date()).format("YYYY-MM-DD")}
            readOnly
            type="date"
            // disabled={type == "view" ? true : false}
          />
          {first_name?.length == 0 && first_nameErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter First Name</p>
            </div>
          )}
        </div>
      </div>

      <div className="w-90 mt-lg-5 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Breach of SLA<span>*</span>
          </p>
          <input
            placeholder="Breach of SLA"
            className="editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            // onChange={(e) => setFirst_name(e.target.value)}
            value={"-3"}
            // disabled={type == "view" ? true : false}
            readOnly
          />
          {first_name?.length == 0 && first_nameErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter First Name</p>
            </div>
          )}
        </div>

        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Description<span>*</span>
          </p>

          <textarea
            placeholder="Description"
            className="editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />

          {Password?.length == 0 && PasswordErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Password</p>
            </div>
          )}
        </div>
      </div>

      <div className="w-90 ac-jb flex-md-row flex-column d-flex">
        <button
          onClick={() => navigate(-1)}
          className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
        >
          Back
        </button>
        {type !== "view" && (
          <button
            className={`cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${
              btn && "opacity-50"
            }`}
            onClick={() => navigate(-1)}
            disabled={btn ? true : false}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
};

export default AddTask;
