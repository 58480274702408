import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import category_img from "../../assets/img/Pack_New_Hire.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Nav_tags, icon_tags, sub_menu } from "../../redux/api/DummyJson";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  useCategoriesAllActiveListMutation,
  useLazyCategoriesQuery,
  useLazyCategoryActiveViewQuery,
  useLazyCategoryViewQuery,
  useLazyNavigationView2Query,
  useLazyNavigationViewQuery,
  useLazyTagsListQuery,
  useLazyTagsProductListQuery,
  useNavigationCreateMutation,
  useNavigationEditMutation,
} from "../../redux/api/api";
import ErrorIcon from "@mui/icons-material/Error";
import { noLogo } from "../../assets/img";
import { Col, Row, Spinner } from "react-bootstrap";
import ImageCroper from "../Popup/ImageCroper";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";

const Navigation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  // RTK QUERY
  const [navigationCreateApi] = useNavigationCreateMutation();
  const [navigationUpdateApi] = useNavigationEditMutation();
  const [navigationViewApi] = useLazyNavigationViewQuery();
  const [navigationViewsApi] = useLazyNavigationView2Query();
  const [tagsListApi] = useLazyTagsProductListQuery();
  const [listCategory] = useLazyCategoriesQuery();
  const [viewCategory] = useLazyCategoryActiveViewQuery();
  const [categoriesListApi] = useCategoriesAllActiveListMutation();

  const [tagNormalList, setTagNormalList] = useState([]);
  const [tagIconList, setTagIconList] = useState([]);
  const [tagNormalsList, setTagNormalsList] = useState([]);
  const [tagIconsList, setTagIconsList] = useState([]);
  const [tagEventList, setTagEventList] = useState([]);
  const [tagEventsList, setTagEventsList] = useState([]);
  const [normalTag, setNormalTag] = useState([]);
  const [iconsTag, setIconTag] = useState([]);
  const [list, setList] = useState([]);
  const [lists, setLists] = useState([]);
  const [multiple, setMutiple] = useState([]);
  const [parent_id, setParent_id] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedCat, setSelectedCat] = useState("");
  const [selectedSubCat, setSelectedSubCat] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [subCategoryColor, setSubCategoryColor] = useState([]);
  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();

  const [name, setName] = useState("");
  const [tagMethod, setTagMethod] = useState("");
  const [tagMethods, setTagMethods] = useState("");
  const [priority, setPriority] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [iconImage, setIconImage] = useState("");
  const [category_id, setCategory_id] = useState("");

  const [iconImageErr, setIconImageErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [tagMethodErr, setTagMethodErr] = useState(false);
  const [priorityErr, setPriorityErr] = useState(false);
  const [titleErr, setTitleErr] = useState(false);
  const [descriptionErr, setDescriptionErr] = useState(false);
  const [category_idErr, setCategory_idErr] = useState(false);

  const [tagEnable, setTagEnable] = useState(true);
  const [iconTagEnable, setIconTagEnable] = useState(true);
  const [eventTagEnable, setEventTagEnable] = useState(true);
  const [categoryShow, setCategoryShow] = useState(false);
  const [inputValue, setInputValue] = useState([]);
  const [IconTags, setIconTags] = useState("");
  const [evntTags, setEventTags] = useState("");
  const [evntTag, setEventTag] = useState("");
  const [IconTagShow, setIconTagShow] = useState(false);
  const [Tags, setTags] = useState("");
  const [TagShow, setTagShow] = useState(false);
  const [btn, setBtn] = useState(false);

  // CATEGORY
  const [catogoryShow, setCatogoryShow] = useState(false);
  const [catogoryShow2, setCatogoryShow2] = useState(null);
  const [productNameShow, setProductNameShow] = useState(false);
  const [catogory, setCatogory] = useState("");
  const [catogory2, setCatogory2] = useState("");
  const [catogory3, setCatogory3] = useState("");
  const [dropDown, setDropDown] = useState(null);
  const [catogoryShow3, setCatogoryShow3] = useState(false);
  const [childMenu, setChildMenu] = useState(false);
  const [childMenu2, setChildMenu2] = useState(false);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCategoryLists, setSubCategoryLists] = useState([]);
  const [subcatogory, setSubcatogory] = useState(false);
  const [selectALLTags, setSelectALLTags] = useState(false);
  const [selectALLIconTags, setSelectALLIconTags] = useState(false);
  const [selectALLEventTags, setSelectALLEventTags] = useState(false);
  const [productSuplierCode, setProductSuplierCode] = useState("");
  const [searchTags, setSearchTags] = useState("");
  const [searchIconTags, setSearchIconTags] = useState("");
  const [searchEventTags, setSearchEventTags] = useState("");
  const [searchValueCategory, setSearchValueCategory] = useState("");
  const [searchValueSubCategory, setSearchSubValueCategory] = useState({});
  const [parentCategory, setParentCategory] = useState("");
  const [catogoryName, setCatogoryName] = useState({});

  useEffect(() => {
    tagsListApi()
      .unwrap()
      .then((res) => {
        setTagNormalList(res?.normalTag);
        setTagNormalsList(res?.normalTag);
        setTagIconList(res?.iconTag);
        setTagIconsList(res?.iconTag);
        setTagEventList(res?.eventTag);
        setTagEventsList(res?.eventTag);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  useEffect(() => {
    if (croperImage) {
      fetch(croperImage)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "File name", { type: "image/png" });
          setIconImage(file);
        });
    }
  }, [croperImage]);

  const getList = () => {
    let params = `?page=${1}`;
    let formdata = new FormData();
    // categoriesListApi({ payload: formdata, params: params })
    listCategory()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          // setList(res?.lists);
          setList(res?.categories);
          setLists(res?.categories);
          if (parent_id) {
            // getView(parent_id);
          }

          if (selectedId) {
            // getView(selectedId);
          }
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  useEffect(() => {
    getList();
  }, []);

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  //   event
  const handleOnchange = (e) => {
    const { value } = e && e.target;
    if (value?.length >= 2) {
      setCategoryShow(true);
    } else {
      setCategoryShow(false);
    }
  };

  // VIEW API
  const getViewNavigation = () => {
    navigationViewsApi(location?.state?.data?.id)
      .unwrap()
      .then((res) => {
        let data = res?.data;
        setName(data?.name);
        setPriority(data?.priority);
        setTitle(data?.title);
        setDescription(data?.description);
        setIconImage(data?.icon);
        if (data?.tag_method) {
          setTagMethod(data?.tag_method);
          setTagMethods(data?.tag_method);
        }

        if (data?.category?.id) {
          setCategoryShow(true);
        }

        if (data?.category?.parentCategory?.id) {
          getView(
            data?.category?.parentCategory?.id,
            "",
            "",
            "",
            "",
            data?.category
          );
          let temp = [];
          let temps = [];
          temp.push(data?.category?.parentCategory?.id);
          temps.push(data?.category?.id);
          if (data?.category?.id) {
            setCategory_id(data?.category?.id);
          }
          setCatogory(data?.category?.parentCategory?.name);
          setCategoryList(temp);
          setSubCategory(temps);
          setSubCategoryColor(temps);
          setSubcatogory(true);
          setCatogoryName((val) => ({
            ...val,
            [data?.category?.parentCategory?.id]: data?.category?.name,
          }));
        } else if (data?.category?.id) {
          let temps = [];
          temps.push(data?.category?.id);
          getView(data?.category?.id);
          if (data?.category?.id) {
            setCategory_id(data?.category?.id);
          }
          setCatogory(data?.category?.name);
          setCategoryList(temps);
        }

        let iconTagName = [];
        let iconTagID = [];
        let EvntTagName = [];
        let EventTagID = [];
        let normalTagName = [];
        let normalTagID = [];

        data?.normalTags?.map((item, ind) => {
          normalTagName.push(item?.name);
          normalTagID.push(item?.id);
        });

        data?.iconTags?.map((item, ind) => {
          iconTagName.push(item?.name);
          iconTagID.push(item?.id);
        });

        data?.eventTags?.map((item, ind) => {
          EvntTagName.push(item?.name);
          EventTagID.push(item?.id);
        });

        if (normalTagID?.length > 0) {
          setTagEnable(false);
        }

        if (iconTagID?.length > 0) {
          setIconTagEnable(false);
        }

        if (EventTagID?.length > 0) {
          setEventTagEnable(false);
        }

        setTags(normalTagName);
        setIconTags(iconTagName);
        setIconTag(iconTagID);
        setNormalTag(normalTagID);
        setEventTag(EventTagID);
        setEventTags(EvntTagName);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (location?.state?.data?.id) {
      getViewNavigation();
    }
  }, []);

  // console.log("multiple", multiple);

  const onCatogory = (e) => {
    setCatogory(e.target.value);
    if (catogory.length >= 1) {
      setCatogoryShow(true);
      setProductNameShow(false);
    } else {
      if (catogory.length >= 0) setCatogoryShow(false);
    }
  };

  // TAGS START
  const allSelectTags = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLTags) {
      tagNormalsList?.map((item, ind) => {
        ListId.push(item?.id);
        ListName.push(item?.name);
      });

      setNormalTag(ListId);
      setTags(ListName);
    } else {
      setNormalTag(ListId);
      setTags(ListName);
    }

    setSelectALLTags(!selectALLTags);
  };

  const onTagsSelect = (event, id, type) => {
    let temp = [...normalTag];
    let temps = [...Tags];

    if (temp.includes(id)) {
      const listtemp = temp.indexOf(id);
      const list = [...temp];
      list.splice(listtemp, 1);
      setNormalTag(list);
    } else {
      setNormalTag([...temp, id]);
    }

    if (temps.includes(event)) {
      const listtemp = temps.indexOf(event);
      const list = [...temps];
      list.splice(listtemp, 1);
      setTags(list);
    } else {
      setTags([...temps, event]);
    }

    if (type !== "check") {
      setDropDown(null);
    }

    setSearchTags("");
    setTagNormalList(tagNormalsList);
  };

  const searchTagsFun = (event) => {
    let temp = [...tagNormalsList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setTagNormalList(search);
    } else {
      setTagNormalList(tagNormalsList);
    }

    setSearchTags(event);
  };
  // TAGS END

  // TAGS ICON START
  const allSelectTagsIcon = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLIconTags) {
      tagIconsList?.map((item, ind) => {
        ListId.push(item?.id);
        ListName.push(item?.name);
      });

      setIconTag(ListId);
      setIconTags(ListName);
    } else {
      setIconTag(ListId);
      setIconTags(ListName);
    }

    setSelectALLIconTags(!selectALLIconTags);
  };

  const onTagsSelectIcon = (event, id, type) => {
    let temp = [...iconsTag];
    let temps = [...IconTags];

    if (temp.includes(id)) {
      const listtemp = temp.indexOf(id);
      const list = [...temp];
      list.splice(listtemp, 1);
      setIconTag(list);
    } else {
      setIconTag([...temp, id]);
    }

    if (temps.includes(event)) {
      const listtemp = temps.indexOf(event);
      const list = [...temps];
      list.splice(listtemp, 1);
      setIconTags(list);
    } else {
      setIconTags([...temps, event]);
    }

    if (type !== "check") {
      setDropDown(null);
    }

    setSearchIconTags("");
    setTagIconList(tagIconsList);
  };

  const searchTagsFunIcon = (event) => {
    let temp = [...tagIconsList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setTagIconList(search);
    } else {
      setTagIconList(tagIconsList);
    }

    setSearchIconTags(event);
  };
  // TAGS ICON END

  // TAGS EVENT START
  const allSelectTagsEvent = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLEventTags) {
      tagEventsList?.map((item, ind) => {
        ListId.push(item?.id);
        ListName.push(item?.name);
      });

      setEventTag(ListId);
      setEventTags(ListName);
    } else {
      setEventTag(ListId);
      setEventTags(ListName);
    }

    setSelectALLEventTags(!selectALLEventTags);
  };

  const onTagsSelectEvent = (event, id, type) => {
    let temp = [...evntTag];
    let temps = [...evntTags];

    if (temp.includes(id)) {
      const listtemp = temp.indexOf(id);
      const list = [...temp];
      list.splice(listtemp, 1);
      setEventTag(list);
    } else {
      setEventTag([...temp, id]);
    }

    if (temps.includes(event)) {
      const listtemp = temps.indexOf(event);
      const list = [...temps];
      list.splice(listtemp, 1);
      setEventTags(list);
    } else {
      setEventTags([...temps, event]);
    }

    if (type !== "check") {
      setDropDown(null);
    }

    setSearchEventTags("");
    setTagEventList(tagEventsList);
  };

  const searchTagsFunEvent = (event) => {
    let temp = [...tagEventsList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setTagEventList(search);
    } else {
      setTagEventList(tagEventsList);
    }

    setSearchEventTags(event);
  };
  // TAGS EVENT END

  // SEARCH CATEGROY
  const searchCategory = (event) => {
    let temp = [...lists];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setList(search);
    } else {
      setList(lists);
    }

    setSearchValueCategory(event);
  };

  // SELECT CATEGORY
  const onCategorySelct = (item) => {
    setSubCategoryList([]);
    setSubCategoryLists([]);
    setCatogoryShow(false);
    setCatogory(item?.name);
    setChildMenu(false);
    setSubcatogory(true);
    setProductSuplierCode("");
    getCategoryId(item?.id, "category");
    setList(lists);
    setSearchValueCategory("");
    setDropDown(null);
    // setParentCategory(item?.id);
  };

  // GET PARENT ID FOR SUB CATEGORY LIST
  const getCategoryId = (id, type, ind, index, data) => {
    if (type == "category") {
      setCategory_id(id);
      setParentCategory(id);
      getView(id, "", "", type, "");
    } else {
      // console.log("lll");
      if (index == 0) {
        setCategory_id(id);
        // console.log("kkkkk");
      }
      setSubCategory(id);
      onSubCatSelect(id, ind, index, data);
    }
  };

  // SLICE ARRAY
  const onSubCatSelect = (id, ind, index, data) => {
    if (index < data?.length - 1) {
      let temp = [...data];
      let slicer = temp.slice(0, index + 1);
      setSubCategoryList(slicer);

      let temps = [...subCategoryLists];
      let slicers = temps.slice(0, index + 1);
      setSubCategoryLists(slicers);

      getView(id, ind, index, "sub_category", slicer);
    } else {
      getView(id, ind, index, "", data);
    }
  };

  // CHILD CATEGORY VIEW API AND 2D DIMENTION ARRAY CREATE
  const getView = (id, ind, index, type, data) => {
    setLoading(true);
    viewCategory(id)
      .unwrap()
      .then((res) => {
        let child = [];
        res?.child_categories?.map((item, ind) => {
          child.push({
            ...item,
            category: {
              ...res?.category,
            },
          });
        });

        let responce = {
          category: res?.category,
          child_categories: child,
        };

        let temp = [];
        let temps = [];
        if (data?.length > 0) {
          temp = [...data];
          temps = [...data];
        } else if (type !== "category") {
          temp = [...subCategoryList];
          temps = [...subCategoryLists];
        }

        if (type == "subSumit") {
          let temX = responce?.child_categories;

          let obj = {
            ...temp[index],
            child_categories: temX,
          };

          let final = subCategoryList?.filter((i) => i?.category?.id !== id);
          final.splice(index, 0, obj);

          let finals = subCategoryLists?.filter((i) => i?.category?.id !== id);
          finals.splice(index, 0, obj);

          setSubCategoryList(final);
          setSubCategoryLists(finals);
          setLoading(false);
        } else {
          let finder = temp.indexOf(id);

          if (temp?.includes(responce)) {
            temp.slice(0, 1);
            temp.push(responce);
          } else {
            temp.push(responce);
          }

          if (temps?.includes(responce)) {
            temps.slice(0, 1);
            temps.push(responce);
          } else {
            temps.push(responce);
          }

          setSubCategoryList(temp);
          setSubCategoryLists(temps);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  // SUB CATEGORY SEARCH
  const searchSubCatFun = (event, ind) => {
    let temp = [...subCategoryLists];
    let tempX = temp[ind];
    let temps = [...subCategoryList];
    let tempXs = temps[ind];
    let search = tempX?.child_categories?.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    // console.log("search", search);

    tempXs = search;

    let obj = {
      ...subCategoryList[ind],
      child_categories: tempXs,
    };

    let arr = [...subCategoryList];
    arr[ind] = obj;

    // console.log("tempXs", obj, arr);

    if (event?.length > 0) {
      setSubCategoryList(arr);
    } else {
      // console.log("jjj");
      setSubCategoryList(subCategoryLists);
    }

    setSearchSubValueCategory((val) => ({ ...val, [ind]: event }));
  };

  // SUBMIT HANDLER
  const submitHandle = () => {
    if (
      name?.length == 0 ||
      // priority?.length == 0 ||
      // title?.length == 0 ||
      // description?.length == 0 ||
      iconImage?.length == 0
      // ||
      // category_id?.length == 0
    ) {
      setNameErr(true);
      // setPriorityErr(true);
      // setTitleErr(true);
      // setDescriptionErr(true);
      setIconImageErr(true);
      // setCategory_idErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("name", name);

      if (category_id) {
        formdata.append("category_id", category_id);
      }

      if (title) {
        formdata.append("title", title);
      }

      if (description) {
        formdata.append("description", description);
      }

      if (priority) {
        formdata.append("priority", priority);
      }

      if (iconImage?.name) {
        formdata.append("icon", iconImage);
      }

      if (!tagEnable && normalTag?.length > 0) {
        normalTag?.map((item, ind) => {
          formdata.append(`normal_tag_ids[${ind}]`, item);
        });
      }

      if (!iconTagEnable && iconsTag?.length > 0) {
        iconsTag?.map((item, ind) => {
          formdata.append(`icon_tag_ids[${ind}]`, item);
        });
      }

      if (!eventTagEnable && evntTag?.length > 0) {
        evntTag?.map((item, ind) => {
          formdata.append(`event_tag_ids[${ind}]`, item);
        });
      }

      let conut = 1;

      if (tagMethod == "" && category_id == "") {
        conut = conut + 1;
        toast.error("Select Tag or Category");
      }

      if (tagMethod !== "") {
        formdata.append("tag_method", tagMethod);

        if (
          evntTag?.length == 0 &&
          iconsTag?.length == 0 &&
          normalTag?.length == 0
        ) {
          conut = conut + 1;
          toast.error("Select Anyone Tag");
        }
      }

      if (conut == 1) {
        dispatch(saveLoader(true));
        setBtn(true);
        if (location?.state?.data?.id) {
          navigationUpdateApi({
            payload: formdata,
            id: location?.state?.data?.id,
          })
            .unwrap()
            .then((res) => {
              dispatch(saveLoader(false));
              setBtn(false);
              navigate("/navigation-list");
            })
            .catch((err) => {
              dispatch(saveLoader(false));
              setBtn(false);
              console.log("err", err);
            });
        } else {
          navigationCreateApi(formdata)
            .unwrap()
            .then((res) => {
              dispatch(saveLoader(false));
              setBtn(false);
              navigate("/navigation-list");
            })
            .catch((err) => {
              dispatch(saveLoader(false));
              setBtn(false);
              console.log("err", err);
            });
        }
      }
    }
  };

  // SELECT CHILD CATEGORY
  const onSubCategorySelect = (subItem, indx, ind) => {
    setSearchSubValueCategory((val) => ({ ...val, [ind]: "" }));
    let temp = [...subCategoryList];
    // console.log("temp[ind]", temp[ind], subCategoryLists[ind]);
    temp[ind] = subCategoryLists[ind];
    // console.log("temp", temp);
    setSubCategoryList(temp);
    setCatogoryShow2(null);
    getCategoryId(subItem?.id, "sub_category", indx, ind, temp);
    setCatogoryName((val) => ({
      ...val,
      [subItem?.category?.id]: subItem?.name,
    }));
    setProductSuplierCode(subItem?.code);
  };

  // console.log("subCategoryLists", subCategoryLists);
  // console.log("subCategoryList", subCategoryList);

  // console.log("category_id", category_id);

  const handleToggleChange = (type) => {
    if (tagMethod) {
      if (type == "tag") {
        setTagEnable(!tagEnable);
        setEventTagEnable(true);
        setIconTagEnable(true);
        setIconTag([]);
        setIconTags([]);
        setEventTag([]);
        setEventTags([]);
      } else if (type == "event") {
        setEventTagEnable(!eventTagEnable);
        setIconTagEnable(true);
        setTagEnable(true);
        setIconTag([]);
        setIconTags([]);
        setNormalTag([]);
        setTags([]);
      } else if (type == "icon") {
        setTagEnable(true);
        setEventTagEnable(true);
        setIconTagEnable(!iconTagEnable);
        setEventTag([]);
        setEventTags([]);
        setNormalTag([]);
        setTags([]);
      }
    }
  };

  const tagMethodHandler = (event) => {
    setCategoryShow(false);
    setSubcatogory(false);
    setDropDown(null);
    setCatogory("");
    setCategory_id("");
    setCatogoryName({});
    setCatogoryShow2(null);
    setTagEnable(true);
    setEventTagEnable(true);
    setIconTagEnable(true);
    setEventTag([]);
    setEventTags([]);
    setNormalTag([]);
    setTags([]);
    setIconTag([]);
    setIconTags([]);
    setTagMethod(event);
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      {loading && (
        <div
          style={{ zIndex: 1500, position: "fixed", top: "45%", left: "49%" }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <div className="d-flex mt-2 ac-jb flex-wrap me-md-3 rk2">
        <div className="d-flex mt-3 w-100 ac-jb pt-4">
          <p
            className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary d-flex align-items-center gap-1"
            role={"button"}
            onClick={() => navigate(-1)}
          >
            {" "}
            <KeyboardBackspaceIcon />
            Navigation{" "}
            {location?.state?.type == "view"
              ? "View"
              : location?.state?.type == "edit"
              ? "Edit"
              : ""}
          </p>
        </div>
      </div>
      <div className="d-flex mt-2 ac-jb flex-wrap me-md-3 rk2">
        <div className="col-md-5 col-lg-5 col-12">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Name
          </p>
          <input
            type="text"
            placeholder="Enter Name"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            onChange={(e) => setName(e.target.value)}
            value={name}
            disabled={location?.state?.type == "view" ? true : false}
          />
          {name?.length == 0 && nameErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Name</p>
            </div>
          )}
        </div>
        {/* <div className="col-md-1 col-lg-1 col-12">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Priority
          </p>
          <input
            type="number"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            onChange={(e) => setPriority(e.target.value)}
            value={priority}
            disabled={location?.state?.type == "view" ? true : false}
          />
          {priority?.length == 0 && priorityErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Priority</p>
            </div>
          )}
        </div> */}
        <div
          className="col-md-5 col-lg-5 col-12"
          onClick={() =>
            location?.state?.type == "view"
              ? console.log("e")
              : toggleImagePopup()
          }
        >
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Icon
          </p>
          <input
            style={{ cursor: "pointer" }}
            value={
              iconImage ? "Icon Uploaded Successfully" : "Browse or Drag a file"
            }
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
          {iconImage?.length == 0 && iconImageErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Upload Image</p>
            </div>
          )}
        </div>
        <div className="col-md-5 col-lg-5 col-12">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Tag Method
          </p>
          <select
            disabled={
              location?.state?.type == "view"
                ? true
                : location?.state?.data?.id && category_id
                ? true
                : false
            }
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value={tagMethod}
            onChange={(e) => tagMethodHandler(e.target.value)}
          >
            <option value={""}>Select Tag Method</option>
            <option value={1}>AND</option>
            <option value={0}>OR</option>
          </select>

          {tagMethod?.length == 0 && tagMethodErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Select Tag Method</p>
            </div>
          )}
        </div>
        <div className="col-md-5 col-lg-5 col-12">
          <div className="d-flex my-2">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Tags
            </p>
            <button type="button" className="100 cust-btn ps-1 ">
              <div className="toggle_btn">
                <input
                  type="checkbox"
                  className="checkbox"
                  style={{ background: "#26D497" }}
                  onChange={(e) => {
                    handleToggleChange("tag");
                  }}
                  checked={tagEnable == true ? false : true}
                  disabled={location?.state?.type == "view" ? true : false}
                />
                <div className="yes"></div>
                <div className="no"></div>
              </div>
            </button>
          </div>

          <fieldset disabled={tagEnable}>
            <div className="position-relative">
              <input
                placeholder="Tags"
                type="text"
                value={Tags?.length > 0 ? Tags?.toString() : "Select Tags"}
                onClick={() => {
                  setDropDown(3);
                }}
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(3);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 3 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDown(null)}
                />
              )}
              <div
                className={`${
                  dropDown == 3 && "submenu_1  py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchTagsFun(e.target.value)}
                    value={searchTags}
                  />
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => {
                      allSelectTags();
                    }}
                  >
                    All
                  </button>
                </div>
                {tagNormalList?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      key={ind}
                    >
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          onTagsSelect(item?.name, item?.id);
                        }}
                      >
                        {item?.name}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                        style={{ fontSize: "large" }}
                        onClick={() => {
                          onTagsSelect(item?.name, item?.id, "check");
                        }}
                      >
                        {Tags?.includes(item?.name) ? (
                          <CheckCircleIcon className="primary" />
                        ) : (
                          <RadioButtonUncheckedIcon className="primary" />
                        )}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
          </fieldset>
        </div>
        <div className="col-md-5 col-lg-5 col-12">
          <div className="d-flex my-2">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Event Tags
            </p>
            <button type="button" className="100 cust-btn ps-1 ">
              <div className="toggle_btn">
                <input
                  type="checkbox"
                  className="checkbox"
                  onChange={(e) => {
                    handleToggleChange("event");
                  }}
                  checked={eventTagEnable == true ? false : true}
                  disabled={location?.state?.type == "view" ? true : false}
                />

                <div className="yes"></div>
                <div className="no"></div>
              </div>
            </button>
          </div>
          <fieldset disabled={eventTagEnable}>
            <div className="position-relative">
              <input
                placeholder="Tags"
                type="text"
                value={
                  evntTags?.length > 0 ? evntTags?.toString() : "Select Tags"
                }
                onClick={() => {
                  setDropDown(8);
                }}
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(8);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 8 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDown(null)}
                />
              )}
              <div
                className={`${
                  dropDown == 8 && "submenu_1  py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchTagsFunEvent(e.target.value)}
                    value={searchEventTags}
                  />
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => {
                      allSelectTagsEvent();
                    }}
                  >
                    All
                  </button>
                </div>
                {tagEventList?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      key={ind}
                    >
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          onTagsSelectEvent(item?.name, item?.id);
                        }}
                      >
                        {item?.name}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                        style={{ fontSize: "large" }}
                        onClick={() => {
                          onTagsSelectEvent(item?.name, item?.id, "check");
                        }}
                      >
                        {evntTags?.includes(item?.name) ? (
                          <CheckCircleIcon className="primary" />
                        ) : (
                          <RadioButtonUncheckedIcon className="primary" />
                        )}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
          </fieldset>
        </div>

        <div className="col-md-5 col-lg-5 col-12">
          <div className="d-flex my-2">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Icon Tags
            </p>
            <button type="button" className="100 cust-btn ps-1 ">
              <div className="toggle_btn">
                <input
                  type="checkbox"
                  className="checkbox"
                  onChange={(e) => {
                    handleToggleChange("icon");
                  }}
                  checked={iconTagEnable == true ? false : true}
                  disabled={location?.state?.type == "view" ? true : false}
                />

                <div className="yes"></div>
                <div className="no"></div>
              </div>
            </button>
          </div>
          <fieldset disabled={iconTagEnable}>
            <div className="position-relative">
              <input
                placeholder="Tags"
                type="text"
                value={
                  IconTags?.length > 0 ? IconTags?.toString() : "Select Tags"
                }
                onClick={() => {
                  setDropDown(4);
                }}
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(4);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 4 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDown(null)}
                />
              )}
              <div
                className={`${
                  dropDown == 4 && "submenu_1  py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchTagsFunIcon(e.target.value)}
                    value={searchIconTags}
                  />
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => {
                      allSelectTagsIcon();
                    }}
                  >
                    All
                  </button>
                </div>
                {tagIconList?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      key={ind}
                    >
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          onTagsSelectIcon(item?.name, item?.id);
                        }}
                      >
                        {item?.name}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                        style={{ fontSize: "large" }}
                        onClick={() => {
                          onTagsSelectIcon(item?.name, item?.id, "check");
                        }}
                      >
                        {IconTags?.includes(item?.name) ? (
                          <CheckCircleIcon className="primary" />
                        ) : (
                          <RadioButtonUncheckedIcon className="primary" />
                        )}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
          </fieldset>
        </div>

        <div className="col-md-7 col-lg-7 col-12  position-relative my-1">
          <fieldset
            disabled={
              tagMethod !== ""
                ? true
                : location?.state?.data?.id && tagMethods
                ? true
                : false
            }
          >
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Select Categories
            </p>
            <input
              type="text"
              placeholder="Required Field"
              value={
                inputValue?.length > 0
                  ? inputValue.join(",")
                  : inputValue[0] || ""
              }
              // onChange={handleOnchange}
              readOnly
              onClick={() => {
                setCategoryShow(!categoryShow);
              }}
              disabled={location?.state?.type == "view" ? true : false}
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
            />
            <button
              className="drop_down position-absolute custom-btn cust-btn"
              onClick={() => {
                setCategoryShow(!categoryShow);
              }}
            >
              <KeyboardArrowDownIcon />
            </button>
          </fieldset>
        </div>
        <div className="col-md-4 col-lg-4 col-12 d-flex align-items-center mt-4">
          <div className="px-2">
            <button
              type="button"
              className=" cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
          </div>
          <div className="px-2">
            {location?.state?.type !== "view" && (
              <button
                disabled={btn}
                onClick={() => submitHandle()}
                type="button"
                className={`cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                  btn && "opacity-50"
                }`}
              >
                Submit
              </button>
            )}
          </div>
        </div>

        {categoryShow && (
          <>
            <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Category
              </p>
              <div className="position-relative">
                <input
                  placeholder="Catogory"
                  type="text"
                  value={
                    catogory?.length > 0
                      ? catogory?.toString()
                      : "Select Catogory"
                  }
                  onClick={() => {
                    setDropDown(1);
                  }}
                  className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  disabled={location?.state?.type == "view" ? true : false}
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setDropDown(1);
                  }}
                  disabled={location?.state?.type == "view" ? true : false}
                >
                  <KeyboardArrowDownIcon />
                </button>
                {dropDown == 1 && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setDropDown(null)}
                  />
                )}
                <div
                  className={`${
                    dropDown == 1 && "submenu_1 py-2"
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  <div className="d-flex gap-1">
                    <input
                      className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                      placeholder="Search..."
                      onChange={(e) => searchCategory(e.target.value)}
                      value={searchValueCategory}
                    />
                  </div>
                  {list?.map((item, ind) => {
                    return (
                      <button
                        className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                        onClick={() => {
                          onCategorySelct(item);
                        }}
                        key={ind}
                      >
                        <button className="px-2 cust-btn text-start py-1 w-100 ">
                          {item?.name}
                        </button>
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        )}
        {subcatogory && (
          <>
            {subCategoryList?.map((item, ind) => {
              return (
                <div
                  className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative"
                  key={ind}
                >
                  <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                    {item?.category?.name}
                  </p>
                  <div className="position-relative">
                    <input
                      placeholder="Required Field"
                      className={`editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust ${
                        ind !== 0 && "opacity-75"
                      }`}
                      value={catogoryName?.[item?.category?.id]}
                      // onChange={onCatogory2}
                      onClick={() => setCatogoryShow2(ind)}
                      readOnly
                    />
                    <button
                      className="drop_down cust-btn"
                      onClick={() => {
                        setCatogoryShow2(ind);
                        setDropDown(null);
                        setCatogoryShow(false);
                        setCatogoryShow3(false);
                        setProductNameShow(false);
                      }}
                    >
                      <KeyboardArrowDownIcon />
                    </button>
                  </div>
                  {catogoryShow2 == ind && (
                    <div
                      className="invisible-cont2 z-0"
                      onClick={() => setCatogoryShow2(null)}
                    />
                  )}
                  <div
                    className={`${
                      catogoryShow2 == ind && "submenu_1"
                    } submenu_cont_1 overflow-scroll z-3`}
                  >
                    <div className="d-flex gap-1">
                      <input
                        className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                        placeholder="Search..."
                        onChange={(e) => searchSubCatFun(e.target.value, ind)}
                        value={searchValueSubCategory[ind]}
                      />
                    </div>
                    {item?.child_categories?.map((subItem, indx) => {
                      return (
                        <div className="d-flex hover-cust" key={indx}>
                          <button
                            className={`px-2 cust-btn text-start py-1 w-100 `}
                            onClick={() => {
                              onSubCategorySelect(subItem, indx, ind);
                            }}
                          >
                            {subItem?.name}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
      <Col md={12} sm={12} lg={12}>
        {category_id?.length == 0 && category_idErr && (
          <div className="d-flex justify-content-start gap-1 mt-2">
            <ErrorIcon className="svg_log" />
            <p className="err-input_log mt-1">Select Category</p>
          </div>
        )}
      </Col>

      <div className="col-md-7 col-lg-7 col-12">
        <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
          Title
        </p>
        <input
          type="text"
          placeholder="Enter Tittle"
          className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          disabled={location?.state?.type == "view" ? true : false}
        />
        {title?.length == 0 && titleErr && (
          <div className="d-flex gap-1 mt-2">
            <ErrorIcon className="svg_log" />
            <p className="err-input_log mt-1">Enter Title</p>
          </div>
        )}
      </div>
      <div className="col-md-7 col-lg-7 col-12 mt-1">
        <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
          Description
        </p>
        <textarea
          cols={5}
          placeholder="Description.."
          className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          disabled={location?.state?.type == "view" ? true : false}
        />
        {description?.length == 0 && descriptionErr && (
          <div className="d-flex gap-1 mt-2">
            <ErrorIcon className="svg_log" />
            <p className="err-input_log mt-1">Enter Description</p>
          </div>
        )}
      </div>
      {/* <div className="my-5 col-12">
        <button
          type="button"
          className="  cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
        >
          Submit
        </button>
      </div> */}
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
    </div>
  );
};

export default Navigation;
