// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Search } from "@mui/icons-material";
import { animatedImg, searchIcon } from "../../assets/img";
import { useLocation, useNavigate } from "react-router-dom";
import BannerManagementList from "../../components/BannerManagement/BannerManagementList";
import GetAppIcon from "@mui/icons-material/GetApp";
import { useBannerListMutation, useLazyBannerStatusQuery } from "../../redux/api/api";
import { toast } from "react-hot-toast";

const BannerManagementScreen = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageRow, setPageRow] = useState(10);
  const [page, setPage] = useState(1);
  const [pagenation, setPagination] = useState({});


//RTK QUERY

const [listBannerApi]= useBannerListMutation();
const[status]=useLazyBannerStatusQuery();

//GET LIST
const getList = (event, row) => {
  let formdata = new FormData();
  let params = `?page=${page}`;

  setSearchValue(event);

  if (event?.length > 0) {
    formdata.append("search", event);
  }

  formdata.append("type", 1);

  if (row) {
    setPageRow(row);
    formdata.append("rows", row);
  }
  

  listBannerApi({ payload: formdata, params: params })
    .unwrap()
    .then((res) => {

      console.log('resbanner',res);
      if (res?.status == "success") {
        const temp=[]
        res?.lists.map((item,ind)=>{
          const json =JSON.parse(item?.contents)
          console.log(json)
          temp.push({
            ...json,
            ...item
          })
        })
        setList(temp);
        console.log(temp,"temp")
        setPagination(res?.pagination_meta);
      }
    })
    .catch((err) => {
      console.log("err");
    });
};

 // CHANGE STATUS
 const change = (id) => {
  status(id)
    .unwrap()
    .then((res) => {
      if (res?.status == "success") {
        toast.success(res?.data?.message || res?.message);
        getList();
      }
    })
    .catch((err) => {
      console.log("err");
    });
};

useEffect(() => {
  getList();
}, [page]);


  return (
    <div className="dashRightView p-5 home_section trans">
      <div className="d-flex w-100 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, PO# to search"
        />
        <img src={animatedImg} alt="img" className="searchManImg" />
      </div>
      <div className="d-flex ac-jb mt-4 flex-wrap">
        <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Banner List
        </p>
      </div>
      <div className="d-flex ac-jb mt-4 flex-wrap align-items-center">
        <div className="d-flex mt-4">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary"
           onChange={(e) => getList(searchValue, e.target.value)}>

            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="d-flex ac-jb flex-md-row flex-column align-items-center">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont px-2 mt-4  px-3">
            Total Count : {pagenation?.total}
          </p>
          {/* <div className="pointerView d-flex mt-4 me-3">
            <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            <p
              className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
            >
              Export
            </p>
          </div> */}
          <div className="d-flex mt-sm-3 border-search">
            <img src={searchIcon} alt="search" className="searchiConImg" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
              onChange={(e) => getList(e.target.value, pageRow)}
              value={searchValue}
            />
          </div>
          <button
            onClick={() => navigate("/banner-management-edit" ,{ state: { type: "add" } })}
            className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            + Add Banner
          </button>
        </div>
      </div>
      <BannerManagementList list={list} change={change} />
      <div className="d-flex justify-content-between mt-3">
      <button className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${page == 1 && "opacity-50"
          }`} onClick={() => setPage(page - 1)}
          disabled={page == 1 ? true : false} >
          Previous
        </button>
        <button className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${page == pagenation?.last_page && "opacity-50"
          }`}
          onClick={() => setPage(page + 1)}
          disabled={page == pagenation?.last_page ? true : false}>
          Next
        </button> 
      </div>
    </div>
  );
};

export default BannerManagementScreen;
