import React, { useEffect, useRef, useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import { uploadIcon } from "../assets/img";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageCroper from "../components/Popup/ImageCroper";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Select from "react-select";
import ErrorIcon from "@mui/icons-material/Error";
import {
  useLazyPresetPackViewQuery,
  useLazyTagsListQuery,
  usePresetPackEditMutation,
  usePresetPackStoreMutation,
} from "../redux/api/api";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";

const AddPackDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const locationData = location?.state?.data;
  const [pointsArr, setPointsArr] = useState([{ value: "" }]);
  const [packImg, setPackImg] = useState("");
  const [packImgErr, setPackImgErr] = useState("");
  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [btn, setBtn] = useState(false);
  const imgRef = useRef();

  const onAddPoints = () => {
    let tmp = [...pointsArr];
    tmp.push({ value: "" });
    setPointsArr([...tmp]);
  };

  const onDeletePoints = (ind) => {
    let tmp = [...pointsArr];
    tmp.splice(ind, 1);
    setPointsArr([...tmp]);
  };

  const handlePoints = (event, ind) => {
    let temp = [...pointsArr];

    temp[ind] = {
      value: event,
    };

    setPointsArr(temp);
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  const [list, setList] = useState([]);
  const [lists, setLists] = useState([]);
  const [parent_id, setParent_id] = useState("");
  const [selectedId, setSelectedId] = useState("");

  const [title, setTitle] = useState("");
  const [bundleID, setBundleID] = useState("");
  const [price, setPrice] = useState("");
  const [discount, setDiscount] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [description, setDescription] = useState("");
  const [notes, setNotes] = useState("");

  const [titleErr, setTitleErr] = useState(false);
  const [bundleIDErr, setBundleIDErr] = useState(false);
  const [priceErr, setPriceErr] = useState(false);
  const [discountErr, setDiscountErr] = useState(false);
  const [totalPriceErr, setTotalPriceErr] = useState(false);
  const [descriptionErr, setDescriptionErr] = useState(false);
  const [notesErr, setNotesErr] = useState(false);

  const [tagNormalList, setTagNormalList] = useState([]);
  const [tagIconList, setTagIconList] = useState([]);
  const [tagNormalsList, setTagNormalsList] = useState([]);
  const [tagIconsList, setTagIconsList] = useState([]);
  const [tagEventList, setTagEventList] = useState([]);
  const [tagEventLists, setTagEventLists] = useState([]);
  const [normalTag, setNormalTag] = useState([]);
  const [iconsTag, setIconTag] = useState([]);
  const [IconTags, setIconTags] = useState([]);
  const [eventTag, setEventTag] = useState([]);
  const [eventTags, setEventTags] = useState([]);
  const [Tags, setTags] = useState("");
  const [selectALLTags, setSelectALLTags] = useState(false);
  const [selectALLIconTags, setSelectALLIconTags] = useState(false);
  const [selectALLEventTags, setSelectALLEventTags] = useState(false);
  const [searchTags, setSearchTags] = useState("");
  const [searchIconTags, setSearchIconTags] = useState("");
  const [searchEventTags, setSearchEventTags] = useState("");
  const [dropDown, setDropDown] = useState(null);
  const [tagID, setTagID] = useState("");
  const [tagEventID, setTagEventID] = useState("");
  const [tagIconID, setTagIconID] = useState("");
  const [tagIDErr, setTagIDErr] = useState(false);
  const [tagEventIDErr, setTagEventIDErr] = useState(false);
  const [tagIconIDErr, setTagIconIDErr] = useState(false);

  // RTK QUERY
  const [tagsListApi] = useLazyTagsListQuery();
  const [presetStoreApi] = usePresetPackStoreMutation();
  const [presetEditApi] = usePresetPackEditMutation();
  const [eventCategoryViewApi] = useLazyPresetPackViewQuery();

  const getView = () => {
    let data = locationData?.data;
    setTitle(data?.title);
    setBundleID(data?.bundle_id);
    // setPrice(data?.price);
    setDiscount(data?.discount);
    // setTotalPrice(data?.total_price);
    setDescription(data?.description);
    setNotes(data?.notes);

    if (Array.isArray(Object.values(locationData?.prods))) {
      let total_value = Object.values(locationData?.prods)?.reduce(
        (accumulator, currentValue) => +accumulator + +currentValue?.for_25,
        0
      );

      // console.log("total_value", total_value);
      setPrice(total_value);
      calculateTotalPrice(total_value, data?.discount);
    }

    // FOR TAGS ID
    let TagsArr = [];
    let normalTagArr = [];
    data?.normalTags?.map((item, ind) => {
      TagsArr.push(item?.id);
      normalTagArr.push(item?.name);
      setTagID(item?.id);
    });
    setTags(normalTagArr);
    setNormalTag(TagsArr);

    // FOR TAGS ID
    let TagsEvenArr = [];
    let EventTagArr = [];
    data?.eventTags?.map((item, ind) => {
      TagsEvenArr.push(item?.id);
      EventTagArr.push(item?.name);
      setTagEventID(item?.id);
    });

    setEventTag(TagsEvenArr);
    setEventTags(EventTagArr);

    // FOR TAGS ID
    let TagsIconArr = [];
    let IconArr = [];
    data?.iconTags?.map((item, ind) => {
      TagsIconArr.push(item?.id);
      IconArr.push(item?.name);
      setTagIconID(item?.id);
    });

    setIconTag(TagsIconArr);
    setIconTags(IconArr);

    // FOR POINTS
    let pointArr = [];
    data?.points?.map((item, ind) => {
      pointArr.push({
        value: item,
      });
    });

    setPackImg(data?.preset_image_url?.image_url);
    setPointsArr(pointArr);
  };

  // console.log("locationData", locationData?.prods);

  useEffect(() => {
    if (locationData?.data?.id) {
      getView();
    }

    if (
      location?.state?.type == undefined &&
      Array.isArray(Object.values(locationData?.prods))
    ) {
      let total_value = Object.values(locationData?.prods)?.reduce(
        (accumulator, currentValue) => accumulator + currentValue?.for_25,
        0
      );
      setPrice(total_value);
    }
  }, []);

  useEffect(() => {
    tagsListApi()
      .unwrap()
      .then((res) => {
        setTagNormalList(res?.normalTag);
        setTagNormalsList(res?.normalTag);
        setTagIconList(res?.iconTag);
        setTagIconsList(res?.iconTag);
        setTagEventList(res?.eventTag);
        setTagEventLists(res?.eventTag);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  // TAGS START
  const allSelectTags = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLTags) {
      tagNormalsList?.map((item, ind) => {
        ListId.push(item?.id);
        ListName.push(item?.name);
        setTagID(item?.id);
      });

      setNormalTag(ListId);
      setTags(ListName);
    } else {
      setNormalTag(ListId);
      setTags(ListName);
    }

    setSelectALLTags(!selectALLTags);
  };

  const onTagsSelect = (event, id, type) => {
    let temp = [...normalTag];
    let temps = [...Tags];

    if (temp.includes(id)) {
      const listtemp = temp.indexOf(id);
      const list = [...temp];
      list.splice(listtemp, 1);
      setNormalTag(list);
    } else {
      setNormalTag([...temp, id]);
    }

    if (temps.includes(event)) {
      const listtemp = temps.indexOf(event);
      const list = [...temps];
      list.splice(listtemp, 1);
      setTags(list);
    } else {
      setTags([...temps, event]);
    }

    if (type !== "check") {
      setDropDown(null);
    }

    setSearchTags("");
    setTagNormalList(tagNormalsList);
    if (id == tagID) {
      setTagID("");
    } else {
      setTagID(id);
    }
  };

  const searchTagsFun = (event) => {
    let temp = [...tagNormalsList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setTagNormalList(search);
    } else {
      setTagNormalList(tagNormalsList);
    }

    setSearchTags(event);
  };
  // TAGS END

  // TAGS ICON START
  const allSelectTagsIcon = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLIconTags) {
      tagIconsList?.map((item, ind) => {
        ListId.push(item?.id);
        ListName.push(item?.name);
        setTagIconID(item?.id);
      });

      setIconTag(ListId);
      setIconTags(ListName);
    } else {
      setIconTag(ListId);
      setIconTags(ListName);
    }

    setSelectALLIconTags(!selectALLIconTags);
  };

  const onTagsSelectIcon = (event, id, type) => {
    let temp = [...iconsTag];
    let temps = [...IconTags];

    if (temp.includes(id)) {
      const listtemp = temp.indexOf(id);
      const list = [...temp];
      list.splice(listtemp, 1);
      setIconTag(list);
    } else {
      setIconTag([...temp, id]);
    }

    if (temps.includes(event)) {
      const listtemp = temps.indexOf(event);
      const list = [...temps];
      list.splice(listtemp, 1);

      setIconTags(list);
    } else {
      setIconTags([...temps, event]);
    }

    if (type !== "check") {
      setDropDown(null);
    }

    setSearchIconTags("");
    setTagIconList(tagIconsList);
    if (id == tagIconID) {
      setTagIconID("");
    } else {
      setTagIconID(id);
    }
  };

  const searchTagsFunIcon = (event) => {
    let temp = [...tagIconsList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setTagIconList(search);
    } else {
      setTagIconList(tagIconsList);
    }

    setSearchIconTags(event);
  };
  // TAGS ICON END

  // TAGS EVENT START
  const allSelectTagsEvent = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLEventTags) {
      tagEventList?.map((item, ind) => {
        ListId.push(item?.id);
        ListName.push(item?.name);
        setTagEventID(item?.id);
      });

      setEventTag(ListId);
      setEventTags(ListName);
    } else {
      setEventTag(ListId);
      setEventTags(ListName);
    }

    setSelectALLEventTags(!selectALLEventTags);
  };

  const onTagsSelectEvent = (event, id, type) => {
    let temp = [...eventTag];
    let temps = [...eventTags];

    if (temp.includes(id)) {
      const listtemp = temp.indexOf(id);
      const list = [...temp];
      list.splice(listtemp, 1);
      setEventTag(list);
    } else {
      setEventTag([...temp, id]);
    }

    if (temps.includes(event)) {
      const listtemp = temps.indexOf(event);
      const list = [...temps];
      list.splice(listtemp, 1);
      setEventTags(list);
    } else {
      setEventTags([...temps, event]);
    }

    if (type !== "check") {
      setDropDown(null);
    }

    setSearchEventTags("");
    setTagEventLists(tagEventList);
    if (id == tagEventID) {
      setTagEventID("");
    } else {
      setTagEventID(id);
    }
  };

  const searchTagsFunEvent = (event) => {
    let temp = [...tagEventList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setTagEventLists(search);
    } else {
      setTagEventLists(tagEventList);
    }

    setSearchEventTags(event);
  };

  console.log("locationData", location?.state);

  // TAGS EVENT END
  const submitHandle = () => {
    if (
      title?.length == 0 ||
      // bundleID?.length == 0 ||
      price?.length == 0 ||
      discount?.length == 0 ||
      totalPrice?.length == 0 ||
      tagID?.length == 0 ||
      tagEventID?.length == 0 ||
      tagIconID?.length == 0 ||
      description?.length == 0 ||
      notes?.length == 0 ||
      packImg?.length == 0
    ) {
      setTitleErr(true);
      // setBundleIDErr(true);
      setPriceErr(true);
      setDiscountErr(true);
      setTotalPriceErr(true);
      setTagIDErr(true);
      setTagEventIDErr(true);
      setTagIconIDErr(true);
      setDescriptionErr(true);
      setNotesErr(true);
      setPackImgErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("event_category_id", locationData?.event_id);
      formdata.append("title", title);
      formdata.append("bundle_id", bundleID);
      formdata.append("notes", notes);
      formdata.append("price", price);
      formdata.append("total_price", totalPrice);
      formdata.append("discount", discount);
      formdata.append("description", description);

      let tagArr = [...normalTag, ...eventTag, ...iconsTag];

      if (packImg?.name) {
        formdata.append("image", packImg);
      }

      tagArr.map((item, ind) => {
        formdata.append(`tags[${ind}]`, item);
      });

      locationData?.project_id?.map((item, ind) => {
        // formdata.append(`product_ids[${ind}]`, item?.id);
        formdata.append(`product_ids[${ind}][id]`, item?.id);
        if (locationData?.sku?.[ind]?.id) {
          formdata.append(
            `product_ids[${ind}][product_sku_id]`,
            locationData?.sku?.[ind]?.id
          );
        }
        // else{
        //   formdata.append(`product_ids[${ind}][product_sku_id]`, '');
        // }
      });

      // if (locationData?.sku?.length > 0) {
      // locationData?.sku?.map((item, ind) => {
      //   formdata.append(`product_ids[${ind}]`, item?.id);
      // });
      // }

      pointsArr.map((item, ind) => {
        formdata.append(`points[${ind}]`, item?.value);
      });

      // console.log("tagArr", tagArr);
      dispatch(saveLoader(true));
      setBtn(true);
      if (locationData?.data?.id) {
        presetEditApi({ payload: formdata, id: locationData?.data?.id })
          .unwrap()
          .then((res) => {
            dispatch(saveLoader(false));
            setBtn(false);
            toast.success(res?.message);
            navigate("/preset-packs");
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setBtn(false);
            console.log("err", err);
          });
      } else {
        presetStoreApi(formdata)
          .unwrap()
          .then((res) => {
            dispatch(saveLoader(false));
            setBtn(false);
            toast.success(res?.message);
            navigate("/preset-packs");
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setBtn(false);
            console.log("err", err);
          });
      }
    }
  };

  // console.log("locationData", locationData);
  const calculateTotalPrice = (price = 1, discount = 1) => {
    setPrice(price);
    setDiscount(discount);
    const total = price - (price * discount) / 100;
    setTotalPrice(Number(total).toFixed(2)); // To keep two decimal places
  };

  const onGoBack = () => {
    let obj = {
      data: {
        project_id: location?.state?.data?.project_id,
        event_id: location?.state?.data?.event_id,
        prods: location?.state?.data?.prods,
        sku: location?.state?.data?.sku,
      },
    };

    if (location?.state?.type == "edit") {
      obj.type = location?.state?.type;
      obj.data.data = location?.state?.data?.data;
    }

    navigate("/add-our-packs", {
      state: obj,
    });
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <ProductSearchHeader />
      <div className="d-flex ac-jb  mt-5">
        <div
          className="d-flex align-items-center gap-1"
          role={"button"}
          onClick={() => onGoBack()}
        >
          <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary ">
            <KeyboardBackspaceIcon />
          </p>
          <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
            Pack Details
          </p>
        </div>
      </div>
      <div className="d-flex as-jb w-100 mt-5 res-flex gap-5">
        <div className="w-100 d-flex flex-wrap ac-jb flex-m-r">
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Title
            </p>
            <input
              placeholder="Required"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
            {title?.length == 0 && titleErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Title</p>
              </div>
            )}
          </div>
          {locationData?.data?.id && (
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Bundle ID
              </p>
              <input
                placeholder="Required Field"
                onChange={(e) => setBundleID(e.target.value)}
                value={bundleID}
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                // type={"number"}
                disabled
              />
              {bundleID?.length == 0 && bundleIDErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Bundle ID</p>
                </div>
              )}
            </div>
          )}
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Price
            </p>
            <input
              onChange={(e) => {
                calculateTotalPrice(e.target.value, discount);
              }}
              value={price}
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              type={"number"}
            />
            {price?.length == 0 && priceErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Price</p>
              </div>
            )}
          </div>
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Discount
            </p>
            <input
              placeholder="Required"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              type={"number"}
              onChange={(e) => calculateTotalPrice(price, e.target.value)}
              value={discount}
            />
            {discount?.length == 0 && discountErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Discount</p>
              </div>
            )}
          </div>
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Total Price
            </p>
            <input
              placeholder="Required"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              type={"number"}
              // onChange={(e) => setTotalPrice(e.target.value)}
              value={totalPrice}
              readOnly
            />
            {totalPrice?.length == 0 && totalPriceErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Total Price</p>
              </div>
            )}
          </div>
          <div className="w-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Tags
            </p>
            <div className="position-relative">
              <input
                placeholder="Tags"
                type="text"
                value={Tags?.length > 0 ? Tags?.toString() : "Select Tags"}
                onClick={() => {
                  setDropDown(3);
                }}
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(3);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 3 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDown(null)}
                />
              )}
              <div
                className={`${
                  dropDown == 3 && "submenu_1  py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchTagsFun(e.target.value)}
                    value={searchTags}
                  />
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => {
                      allSelectTags();
                    }}
                  >
                    All
                  </button>
                </div>
                {tagNormalList?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      key={ind}
                    >
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          onTagsSelect(item?.name, item?.id);
                        }}
                      >
                        {item?.name}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                        style={{ fontSize: "large" }}
                        onClick={() => {
                          onTagsSelect(item?.name, item?.id, "check");
                        }}
                      >
                        {Tags?.includes(item?.name) ? (
                          <CheckCircleIcon className="primary" />
                        ) : (
                          <RadioButtonUncheckedIcon className="primary" />
                        )}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
            {tagID?.length == 0 && tagIDErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select Tags</p>
              </div>
            )}
          </div>
          <div className="w-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Event Tags
            </p>
            <div className="position-relative">
              <input
                placeholder="Event Tags"
                type="text"
                value={
                  eventTags?.length > 0
                    ? eventTags?.toString()
                    : "Select Event Tags"
                }
                onClick={() => {
                  setDropDown(2);
                }}
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(2);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 2 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDown(null)}
                />
              )}
              <div
                className={`${
                  dropDown == 2 && "submenu_1  py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchTagsFunEvent(e.target.value)}
                    value={searchEventTags}
                  />
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => {
                      allSelectTagsEvent();
                    }}
                  >
                    All
                  </button>
                </div>
                {tagEventLists?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      key={ind}
                    >
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          onTagsSelectEvent(item?.name, item?.id);
                        }}
                      >
                        {item?.name}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                        style={{ fontSize: "large" }}
                        onClick={() => {
                          onTagsSelectEvent(item?.name, item?.id, "check");
                        }}
                      >
                        {eventTags?.includes(item?.name) ? (
                          <CheckCircleIcon className="primary" />
                        ) : (
                          <RadioButtonUncheckedIcon className="primary" />
                        )}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
            {tagEventID?.length == 0 && tagEventIDErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select Event Tags</p>
              </div>
            )}
          </div>
          <div className="w-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Icons
            </p>
            <div className="position-relative">
              <input
                placeholder="Icon Tags"
                type="text"
                value={
                  IconTags?.length > 0
                    ? IconTags?.toString()
                    : "Select Icon Tags"
                }
                onClick={() => {
                  setDropDown(4);
                }}
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(4);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 4 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDown(null)}
                />
              )}
              <div
                className={`${
                  dropDown == 4 && "submenu_1  py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchTagsFunIcon(e.target.value)}
                    value={searchIconTags}
                  />
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => {
                      allSelectTagsIcon();
                    }}
                  >
                    All
                  </button>
                </div>
                {tagIconList?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      key={ind}
                    >
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          onTagsSelectIcon(item?.name, item?.id);
                        }}
                      >
                        {item?.name}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                        style={{ fontSize: "large" }}
                        onClick={() => {
                          onTagsSelectIcon(item?.name, item?.id, "check");
                        }}
                      >
                        {IconTags?.includes(item?.name) ? (
                          <CheckCircleIcon className="primary" />
                        ) : (
                          <RadioButtonUncheckedIcon className="primary" />
                        )}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
            {tagIconID?.length == 0 && tagIconIDErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select Icons Tags</p>
              </div>
            )}
          </div>
          <div className="w-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Description
            </p>
            <textarea
              placeholder="Required"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
            {description?.length == 0 && descriptionErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Description</p>
              </div>
            )}
          </div>
          <div className="w-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Notes
            </p>
            <textarea
              placeholder="optional"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              onChange={(e) => setNotes(e.target.value)}
              value={notes}
            />
            {notes?.length == 0 && notesErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Notes</p>
              </div>
            )}
          </div>
          <div className="w-100 mt-3">
            <p className="d-flex ac-jb black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Points
              <span>
                <button
                  onClick={onAddPoints}
                  className="  cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                >
                  + Add
                </button>
              </span>
            </p>
            {pointsArr?.map((item, ind) => {
              return (
                <div className="d-flex mt-3 ac-jc">
                  <p className="f3 mx-2 fs-18 primary">{ind + 1}.</p>
                  <input
                    placeholder="Required Field"
                    className="editBtn w-80 rounded-3  p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={item?.value}
                    onChange={(e) => handlePoints(e.target.value, ind)}
                  />
                  {ind === 0 ? (
                    <DeleteIcon className="text-white fs-24 mx-2 " />
                  ) : (
                    <DeleteIcon
                      onClick={() => onDeletePoints(ind)}
                      className="text-danger fs-24 mx-2"
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div className="my-5">
            <button
              onClick={() => submitHandle()}
              className={`cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                btn && "opacity-50"
              }`}
              disabled={btn}
            >
              Submit
            </button>
          </div>
        </div>
        <div className="w-100">
          <p class="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 text-center mb-3">
            Pack Image (161 x 161)
          </p>
          <div className="d-flex gap-3 ac-jc w-100 res-img">
            <div className="file-upload" style={{ cursor: "pointer" }}>
              <div className="file-icon d-flex ac-jc">
                <div className="d-flex flex-column ac-jc">
                  <label
                    className="upload-btn"
                    onClick={() => imgRef.current.click()}
                  >
                    <img
                      className={
                        packImg?.name
                          ? "placeholder_icon1 object-fit-contain"
                          : packImg
                          ? "placeholder_icon1 object-fit-contain"
                          : "placeholder_icon object-fit-contain"
                      }
                      src={
                        packImg?.name
                          ? URL.createObjectURL(packImg)
                          : packImg
                          ? packImg
                          : uploadIcon
                      }
                      alt="logo"
                    />
                    {packImg ? null : (
                      <p className="fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 px-5 text-center">
                        Drag and drop to upload or Click here to upload
                      </p>
                    )}
                  </label>
                </div>
              </div>
            </div>

            <input
              type={"file"}
              className="d-none"
              ref={imgRef}
              onChange={(e) => setPackImg(e.target.files[0])}
              accept="image/png, image/gif, image/jpeg"
            />
          </div>
          {packImg?.length == 0 && packImgErr && (
            <div className="d-flex ac-jc w-100 gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Upload Pack Image</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddPackDetails;
