import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../redux/api/api";
import ErrorIcon from "@mui/icons-material/Error";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";

const SignInScreen = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [nameErr, setNameErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  //  RTK QUERY

  const [login] = useLoginMutation();

  const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);

  const handlePassword = (event) => {
    let new_pass = event.target.value;
    if (new_pass?.length !== 0) {
      setPassword(new_pass);

      // regular expressions to validate password
      var lowerCase = /[a-z]/g;
      var upperCase = /[A-Z]/g;
      var numbers = /[0-9]/g;
      var special = /[*@!#%&()^~{}]+/g;
      if (!new_pass.match(lowerCase)) {
        setErrorMessage("Password should contains lowercase letters!");
      } else if (!new_pass.match(upperCase)) {
        setErrorMessage("Password should contain uppercase letters!");
      } else if (!new_pass.match(numbers)) {
        setErrorMessage("Password should contains numbers also!");
      } else if (!new_pass.match(special)) {
        setErrorMessage("Password should contains special character also!");
      } else if (new_pass.length < 8) {
        setErrorMessage("Password length should be more than 8.");
      } else {
        // setErrorMessage("Password is strong!");
        setErrorMessage("");
      }
    } else {
      setErrorMessage("");
      setPassword(new_pass);
    }
  };

  const submitHandler = () => {
    if (name?.length == 0 || password?.length == 0) {
      setNameErr(true);
      setPasswordErr(true);
      // } else if (errorMessage?.length == 0) {
    } else if (errorMessage?.length == 0) {
      let formdata = new FormData();

      formdata.append("email", name);
      formdata.append("password", password);
      setBtn(true);
      dispatch(saveLoader(true));
      login(formdata)
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            toast.success(res?.message);
            localStorage.setItem("token", res?.token);
            localStorage.setItem(
              "permissions",
              JSON.stringify(res?.permissions)
            );
            localStorage.setItem("userDetails", JSON.stringify(res?.admin));
            setTimeout(() => {
              dispatch(saveLoader(false));
              setBtn(false);
              navigate("/home");
              window.location.reload();
            }, 500);
          }
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          console.log("err");
          setBtn(false);
        });
    }
  };

  const keydownHandler = (event) => {
    if (event.key == "Enter") {
      submitHandler();
    }
  };

  return (
    <div className="signInDes d-flex">
      <div className="w-50 h-100 p-4 bg-back-blue">
        <p className="w-100 f4 fs-sm-26 fs-md-27 fs-lg-28 fs-xl-29 fs-xxl-30 primary">
          Merchhq
        </p>
        <div className="signLeftDes" />
      </div>
      <div className="w-50 position-relative">
        <div className="viewBoxDesSign w-sm-100 w-md-90 w-lg-80 w-xl-70 p-5 rounded-5 bg-white">
          <p className="f2 fs-xs-10 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20">
            Welcome to MerchHQ
          </p>
          <p className="w-100 f4 fs-sm-26 fs-md-32 fs-lg-33 fs-xl-34 fs-xxl-35 primary">
            Sign In
          </p>
          <div className="mt-4 position-relative">
            <p className="f2 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-17">
              Enter your Email address
            </p>
            <input
              placeholder="Email address"
              value={name}
              className="editBtn w-100 mt-2 f2 py-2 ps-2 rounded-3 fs-xs-12 fs-sm-13 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18"
              onChange={(e) => setName(e.target.value)}
              onKeyDown={keydownHandler}
            />
            {validEmail.test(name) == false && nameErr && (
              <div className="d-flex gap-1 mt-2">
                {" "}
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Valid Email</p>
              </div>
            )}
          </div>

          <div className="mt-4 ">
            {" "}
            <div className="position-relative">
              <p className="f2 fs-xs-10 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-17">
                Enter your Password
              </p>
              <input
                placeholder="Password"
                type={visible ? "text" : "password"}
                className="editBtn mt-2 f2 w-100 py-2 rounded-3 ps-2 fs-xs-12 fs-sm-13 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18"
                onChange={(e) => handlePassword(e)}
                value={password}
                onKeyDown={keydownHandler}
              />
              <div className="visibleDes">
                {visible && (
                  <VisibilityIcon
                    className="fs-xs-18 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-23 fs-xxl-24"
                    onClick={() => setVisible(!visible)}
                  />
                )}
                {!visible && (
                  <VisibilityOffIcon
                    className="fs-xs-18 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-23 fs-xxl-24"
                    onClick={() => setVisible(!visible)}
                  />
                )}
              </div>
            </div>
            {password?.length == 0 && passwordErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Password</p>
              </div>
            )}
            {errorMessage && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">{errorMessage}</p>
              </div>
            )}
          </div>
          <div className="w-100 forgotPassword">
            <p
              onClick={() => navigate("/forgot-password")}
              className={`text-danger ${
                errorMessage
                  ? "mt-4"
                  : password?.length == 0 && passwordErr
                  ? "mt-4"
                  : "mt-2"
              } fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15`}
            >
              Forgot Password
            </p>
          </div>
          <button
            onClick={submitHandler}
            disabled={btn ? true : false}
            className={`cust-btn addbtn w-100 mt-3 mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${
              btn && "opacity-50"
            }`}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignInScreen;
