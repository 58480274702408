import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { product } from "../../assets/img";
import ViewStockPoppup from "../EstimateComp/ViewStockPoppup";
import AddFilesPopup from "../Popup/AddFilesOverview";
import DeletePopup from "../Popup/DeletePopup";
import AddArtWorker from "./AddArtWorker";

const PresentaionList = () => {
  const navigate = useNavigate();
  const [detGrid, setDetGrid] = useState(0);
  const [actionBtn, setActionBtn] = useState(false);
  const [showAll, setShowAll] = useState(0);
  const [tabBtn, setTabBtn] = useState(0);
  const [addArt, setAddArt] = useState(false);
  const [addClient, setAddClient] = useState(false);
  const [AddFilesOverview, setAddFilesOverview] = useState(false);
  const [addShow, setAddShow] = useState(false);
  const [addTitle, setAddTitle] = useState(false);
  const [deletes, setDeletes] = useState(false);
  const [actDect, setActDect] = useState(false);
  const [viewStock, setViewStock] = useState(false);
  const [hide, setHide] = useState(0);
  const [status, setStatus] = useState("In Process");

  const artWork = () => {
    toggleShowPopup();
  };

  const toggleShowPopup = () => {
    setAddClient(!addClient);
  };

  const toggleShowPopups = () => {
    setAddFilesOverview(!AddFilesOverview);
  };

  const deleteToggle = () => {
    setDeletes(!deletes);
  };
  const viewStockToggle = () => {
    setViewStock(!viewStock);
  };

  const hider = (num, type) => {
    if (type == "one") {
      if (hide == num) {
        setHide(0);
      } else {
        setHide(num);
      }
    } else {
      if (hide == num) {
        setHide(0);
      } else {
        setHide(num);
      }
    }
  };

  return (
    <Col sm={12}>
      {viewStock && <ViewStockPoppup viewStockToggle={viewStockToggle} />}
      <div className="presen-list">
        <div className="mt-3 d-flex ac-jb mt-0 mt-md-3 flex-column flex-md-row presen-detail py-4 px-2">
          <div className="d-flex ac-js">
            <button
              onClick={() => setTabBtn(0)}
              className={`${
                tabBtn == 0 ? "border_click_cust" : "cust-btn "
              } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
            >
              Products
            </button>
            <button
              onClick={() => setTabBtn(1)}
              className={`${
                tabBtn == 1 ? "border_click_cust" : "cust-btn "
              } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
            >
              Artwork
            </button>
          </div>
          <div className="d-flex ac-jc gap-3 mt-3 mt-md-0 flex-column flex-sm-row ">
            {tabBtn == 0 && (
              <div className="d-flex gap-2">
                <div className="d-flex justify-conten-center align-items-center gap-2">
                  <input type={"checkbox"} />
                  <p className="text-nowrap">Show to Customer</p>
                </div>

                <button
                  onClick={() => setDetGrid(0)}
                  className={` ${
                    detGrid == 0 && "primary bg-lt-blue"
                  } editBtn bg-transparent text-nowrap rounded_cust_left py-2 px-3 f1 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 `}
                >
                  Detailed
                </button>
                {/* <button
                  onClick={() => setDetGrid(1)}
                  className={` ${
                    detGrid == 1 && "primary bg-lt-blue"
                  } editBtn bg-transparent text-nowrap rounded_cust_right border-start-0 py-2 px-3 f1 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 `}
                >
                  Grid
                </button> */}
              </div>
            )}
            <div className="d-flex">
              <button
                onClick={() => setShowAll(0)}
                className={` ${
                  showAll == 0 && "primary bg-lt-blue"
                } editBtn bg-transparent text-nowrap rounded_cust_left py-2 px-3 f1 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 `}
              >
                Show All
              </button>
              <button
                onClick={() => setShowAll(1)}
                className={` ${
                  showAll == 1 && "primary bg-lt-blue"
                } editBtn bg-transparent text-nowrap rounded_cust_right border-start-0 py-2 px-3 f1 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 `}
              >
                Show Active
              </button>
            </div>
          </div>
        </div>
        {/* <div className="d-flex justify-content-end ">
          {tabBtn == 0 && (
            <div className="mx-1">
              <button
                className={
                  detGrid == 0 ? "presen-detail active " : "presen-detail"
                }
                onClick={() => setDetGrid(0)}
                style={{ marginRight: "0" }}
              >
                Detailed
              </button>
              <button
                className={
                  detGrid == 1 ? "presen-detail active" : "presen-detail"
                }
                onClick={() => setDetGrid(1)}
                style={{ marginRight: "0" }}
              >
                Grid
              </button>
            </div>
          )}
          <div>
            <button
              className={
                showAll == 0 ? "presen-detail active " : "presen-detail"
              }
              onClick={() => setShowAll(0)}
              style={{ marginRight: "0" }}
            >
              Show All
            </button>
            <button
              className={
                showAll == 1 ? "presen-detail active" : "presen-detail"
              }
              onClick={() => setShowAll(1)}
              style={{ marginRight: "0" }}
            >
              Show Active
            </button>
          </div>
        </div>
        <div className="d-flex">
          <button
            className={
              tabBtn == 0
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 border-slide_tab_line"
                : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            }
            onClick={() => setTabBtn(0)}
          >
            Products
          </button>
          <button
            className={
              tabBtn == 1
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-slide_tab border-slide_tab_line f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                : "border-bottom-0 border-top-0 border-start-0 border-end-0 border-slide_tab f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            }
            onClick={() => setTabBtn(1)}
          >
            Artwork
          </button>
        </div> */}
        {tabBtn == 0 && (
          <div className="w-100 overflow-scroll table_border mt-3">
            <table className="w-100 presen-tables">
              <thead className="presen-table">
                <tr>
                  <th style={{ textAlign: "left", width: "600px" }}>
                    <div className="dropdown mx-2">
                      <button
                        className="dropdown-toggle cust-btn addbtn  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        + Add
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <span
                            className="dropdown-item f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                            onClick={() => navigate("/product-library")}
                          >
                            Product
                          </span>
                        </li>
                        <li>
                          <span
                            className="dropdown-item f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                            onClick={() => setAddTitle(true)}
                          >
                            Tittle
                          </span>
                        </li>
                      </ul>
                    </div>
                  </th>
                  {detGrid == 0 && (
                    <>
                      <th>
                        <div>Additional Cost</div>
                      </th>
                      <th className="text-center f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        <div style={{ width: "100px" }}>Qty</div>
                      </th>
                      <th className="text-center f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        <div style={{ width: "100px" }}>Margin</div>
                      </th>
                      <th className="text-center f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        <div style={{ width: "100px" }}>Amount</div>
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              {detGrid == 0 ? (
                <>
                  <tbody className={`pres-body ${hide == 1 && "opacity-50"}`}>
                    <tr className="position-relative color-for-hecd ">
                      <td rowspan={5} className="">
                        <div className="ps-3" style={{ width: "600px" }}>
                          <div>
                            <div className="d-flex cp">
                              <img
                                src={product}
                                className="w-15 mt-2"
                                onClick={() => navigate("/presentation-edit")}
                              />
                              <div
                                className="d-flex flex-column mt-4 cp"
                                onClick={() =>
                                  navigate("/product-library-details")
                                }
                              >
                                <span
                                  onClick={() =>
                                    navigate("/product-library-details")
                                  }
                                  className="cp text-info f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 "
                                >
                                  301WH1026
                                </span>
                                {/* <span
                                  onClick={() =>
                                    navigate("/product-library-details")
                                  }
                                  className="f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14"
                                >
                                  Sample(0571 x2415039)
                                </span> */}
                                <span
                                  onClick={() =>
                                    navigate("/product-library-details")
                                  }
                                  className="f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14"
                                >
                                  stainless street Hot N Cold Bottle
                                </span>
                              </div>
                            </div>
                            <div className="d-flex">
                              <button
                                className="text-nowrap px-2"
                                onClick={() => hider(1, "one")}
                              >
                                Hide
                              </button>
                              <button
                                className="text-nowrap px-2 "
                                onClick={() => navigate("/presentation-edit")}
                              >
                                Edit
                              </button>
                              {/* <button className="text-nowrap px-2">Copy</button> */}
                              {/* <button
                              className={actDect && "del-colo-d"}
                              onClick={() => setActDect(!actDect)}
                            >
                              {actDect ? "DeActive" : "Active"}
                            </button> */}
                              <button
                                className="text-nowrap px-2  del-colo-d"
                                onClick={() => setDeletes(!deletes)}
                              >
                                Delete
                              </button>

                              <button
                                className="text-nowrap px-2"
                                onClick={viewStockToggle}
                              >
                                View Stock
                              </button>
                              <button
                                // onClick={() =>
                                //   navigate("/upload-artwork-inprocess")
                                // }
                                className="text-nowrap px-2 artwor_btn"
                              >
                                Artwork Pending
                              </button>
                            </div>
                            <div className="mt-4">
                              <p className="f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                                Material: Lorem Ipsum has been the industry's
                                standard dummy text ever since the 1500s,
                              </p>
                            </div>
                          </div>
                          <div
                            className="status_btn w-auto "
                            style={{
                              bottom: 0,
                            }}
                          >
                            <div
                              className={`${
                                status == "In Process"
                                  ? "process_btn"
                                  : "accepted_btn"
                              } rounded-3 d-flex mb-2 ac-jc px-4 py-2 position-relative`}
                              style={{ width: "140px" }}
                              role={"button"}
                              onClick={() => setActionBtn(!actionBtn)}
                            >
                              <p className="text_btn">{status}</p>
                              {actionBtn && (
                                <div
                                  className="action_btn ml-1 position-absolute"
                                  style={{
                                    width: "140px",
                                    zIndex: 300,
                                    top: "110%",
                                    left: 0,
                                  }}
                                >
                                  <ul
                                    style={{
                                      border: "1px solid #6c757d",
                                      borderRadius: "3px",
                                    }}
                                  >
                                    <li
                                      className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
                                      onClick={() => {
                                        setActionBtn(false);
                                        setStatus(
                                          status == "In Process"
                                            ? "Ready"
                                            : "In Process"
                                        );
                                      }}
                                    >
                                      {status == "In Process"
                                        ? "Ready"
                                        : "In Process"}
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td></td>
                      <td style={{ width: "200px" }} className="text-center">
                        100
                      </td>
                      <td style={{ width: "200px" }} className="text-center">
                        5.00%
                      </td>
                      <td style={{ width: "200px" }} className="text-center">
                        {" "}
                        ₹105.26
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td style={{ width: "200px" }} className="text-center">
                        200
                      </td>
                      <td style={{ width: "200px" }} className="text-center">
                        {/* 0.00% */}
                      </td>
                      <td style={{ width: "200px" }} className="text-center">
                        {" "}
                        ₹0.00
                      </td>
                    </tr>{" "}
                    <tr>
                      <td style={{ width: "200px" }} className="text-center">
                        set cost
                      </td>
                      <td style={{ width: "200px" }}></td>
                      <td style={{ width: "200px" }} className="text-center">
                        {/* 0.00% */}
                      </td>
                      <td className="text-center"> ₹0.00</td>
                    </tr>{" "}
                    <tr className="w-100">
                      <td style={{ width: "200px" }} className="text-center">
                        fix 2
                      </td>
                      <td style={{ width: "200px" }}></td>
                      <td style={{ width: "200px" }} className="text-center">
                        {/* 0.00% */}
                      </td>
                      <td style={{ width: "200px" }} className="text-center">
                        {" "}
                        ₹0.00
                      </td>
                    </tr>
                  </tbody>
                  <tbody className={`pres-body ${hide == 2 && "opacity-50"}`}>
                    <tr className="position-relative color-for-hecd">
                      <td rowspan={5} className="">
                        <div className="ps-3">
                          <div>
                            <div className="d-flex cp">
                              <img
                                src={product}
                                className="w-15 mt-2"
                                onClick={() => navigate("/presentation-edit")}
                              />
                              <div
                                className="cp d-flex flex-column mt-4"
                                onClick={() =>
                                  navigate("/product-library-details")
                                }
                              >
                                <span className="cp text-info f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                                  301WH1026
                                </span>
                                {/* <span className="f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                                  Sample(0571 x2415039)
                                </span> */}
                                <span className="f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                                  stainless street Hot N Cold Bottle
                                </span>
                              </div>
                            </div>
                            <div className="d-flex ">
                              <button
                                className="text-nowrap px-2"
                                onClick={() => hider(2, "two")}
                              >
                                Hide
                              </button>
                              <button
                                className="text-nowrap px-2"
                                onClick={() => navigate("/presentation-edit")}
                              >
                                Edit
                              </button>
                              {/* <button className="text-nowrap px-2">Copy</button> */}
                              <button
                                className="text-nowrap px-2  del-colo-d"
                                onClick={() => setDeletes(!deletes)}
                              >
                                Delete
                              </button>
                              {/* <button
                              className={actDect && "del-colo-d"}
                              onClick={() => setActDect(!actDect)}
                            >
                              {actDect ? "DeActive" : "Active"}
                            </button> */}

                              <button
                                className="text-nowrap px-2"
                                onClick={viewStockToggle}
                              >
                                View Stock
                              </button>
                              <button
                                // onClick={() =>
                                //   navigate("/upload-artwork-inprocess")
                                // }
                                className="text-nowrap px-2 artwor_btn"
                              >
                                Artwork Pending
                              </button>
                            </div>
                            <div className="mt-4">
                              <p className="f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                                Material: Lorem Ipsum has been the industry's
                                standard dummy text ever since the 1500s,
                              </p>
                            </div>
                          </div>
                          <div
                            className="status_btn w-auto"
                            style={{
                              bottom: 0,
                            }}
                          >
                            <div
                              className=" accepted_btn rounded-3 d-flex mb-2 ac-jc px-4 py-2"
                              style={{ width: "100px" }}
                            >
                              <p className="text_btn">Ready</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </>
              ) : (
                <div>
                  <div className="d-flex py-3 px-4 gap-3 ">
                    <div
                      className="grid-view-presen cp position-relative ac-jc"
                      onClick={() => navigate("/presentation-edit")}
                    >
                      <img src={product} className="w-100" />
                      <div className="  product_text tranc ">
                        <p className=" text-center black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          Product
                        </p>
                      </div>
                    </div>
                    <div
                      className="grid-view-presen cp position-relative ac-jc"
                      onClick={() => navigate("/presentation-edit")}
                    >
                      <img src={product} className="w-100" />
                      <div className="  product_text  tranc ">
                        <p className=" text-center black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                          Product
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </table>
          </div>
        )}
        {tabBtn == 1 && (
          <div className="mt-3 w-100 overflow-scroll table_border">
            <table className="w-100 presen-tables">
              <thead className="presen-table">
                <tr className="">
                  <th style={{ textAlign: "left", width: "0%" }}>
                    <div className="dropdown mx-2">
                      <button
                        className="dropdown-toggle cust-btn addbtn  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        + Add
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <span
                            className="dropdown-item f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                            onClick={() => navigate("/product-library")}
                          >
                            Product
                          </span>
                        </li>
                        <li>
                          <span
                            className="dropdown-item f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                            onClick={() => setAddTitle(true)}
                          >
                            Tittle
                          </span>
                        </li>
                      </ul>
                    </div>
                  </th>
                  <th
                    className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center"
                    colSpan={3}
                  >
                    Artwork
                  </th>
                </tr>
              </thead>
              <tbody className="overflow-scroll">
                <tr className="art-work-tab1">
                  <td className="text-left">
                    <div style={{ width: "300px" }} className="d-flex ac-js">
                      <div
                        className="artwork_image cp"
                        onClick={() => navigate("/presentation-edit")}
                      >
                        <img src={product} className="w-100 mt-2" />
                      </div>
                      <span
                        onClick={() => navigate("/presentation-edit")}
                        className="d-block cp mt-3 text-info f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary"
                      >
                        Brand T-Shirt
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="d-inline">
                      <label className="f3 mt-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                        Location 1:
                      </label>
                      <div className="" style={{ width: "300px" }}>
                        <div
                          className="artwork_images d-flex ac-jc"
                          onClick={() => artWork()}
                        >
                          {addArt ? (
                            <div>
                              <img src={""} />
                            </div>
                          ) : (
                            <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                              {" "}
                              Add Artwork
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-inline">
                      <label className="f3 mt-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                        Location 2:
                      </label>
                      <div className="" style={{ width: "300px" }}>
                        <div
                          className="artwork_images d-flex ac-jc"
                          onClick={() => artWork()}
                        >
                          {addArt ? (
                            <div>
                              <img src={""} />
                            </div>
                          ) : (
                            <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                              {" "}
                              Add Artwork
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-inline">
                      <label className="f3 mt-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                        Location 3:
                      </label>
                      <div className="" style={{ width: "300px" }}>
                        <div
                          className="artwork_images d-flex ac-jc"
                          onClick={() => artWork()}
                        >
                          {addArt ? (
                            <div>
                              <img src={""} />
                            </div>
                          ) : (
                            <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                              {" "}
                              Add Artwork
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="art-work-tab1">
                  <td className="w-40 text-left">
                    <div style={{ width: "300px" }} className="d-flex ac-js">
                      <div
                        onClick={() => navigate("/presentation-edit")}
                        className="artwork_image"
                      >
                        <img src={product} className="w-100 mt-2" />
                      </div>
                      <span
                        onClick={() => navigate("/presentation-edit")}
                        className="cp d-block mt-3 text-info f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary"
                      >
                        Brand T-Shirt
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="d-inline w-45">
                      <div className="d-inline">
                        <label className="f3 mt-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                          Location 1:
                        </label>
                        <div className="" style={{ width: "300px" }}>
                          <div
                            className="artwork_images d-flex ac-jc"
                            onClick={() => artWork()}
                          >
                            {addArt ? (
                              <div>
                                <img src={""} />
                              </div>
                            ) : (
                              <p className="f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                {" "}
                                Add Artwork
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {addTitle && (
          <div className="add-tit-btn mt-3">
            <div className="m-2">
              <input
                placeholder="Add a title"
                className="w-100 b-color-add mt-3"
              />
              <div className="d-flex mt-2 position-absolute m-2">
                <div className="dropdown mx-2">
                  <button
                    className="add-tit-btn2 mx-1 bottom_show_btn"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    + Add
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <span className="dropdown-item">Product</span>
                    </li>
                    <li>
                      <span className="dropdown-item">Tittle</span>
                    </li>
                  </ul>
                </div>
                {/* <button className="add-tit-btn1">+ Add</button> */}
                <button
                  className="add-tit-btn2 mx-1 bottom_show_btn"
                  onClick={() => setAddShow(!addShow)}
                >
                  {addShow ? "Show" : "hide"}
                </button>
                <button
                  className="add-tit-btn3 mx-1 bottom_delete_btn"
                  onClick={() => setDeletes(!deletes)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {addClient && (
        <AddArtWorker
          toggleShowPopup={toggleShowPopup}
          setAddArt={setAddArt}
          toggleShowPopups={toggleShowPopups}
          statusOption={true}
        />
      )}
      {AddFilesOverview && (
        <AddFilesPopup
          toggleShowPopup={toggleShowPopups}
          popupType={"addClient"}
        />
      )}
      {deletes && (
        <DeletePopup deleteToggle={deleteToggle} setAddTitle={setAddTitle} />
      )}
    </Col>
  );
};

export default PresentaionList;
