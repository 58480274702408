import React, { useEffect, useState } from "react";
import {
  editIcon,
  noLogo,
  searchIcon,
  toggleOff,
  toggleOn,
  viewIcon,
} from "../assets/img";
import { decorativeMethodList } from "../redux/api/DummyJson";
import AddDecorativePopup from "../components/Popup/AddDecorativePopup";
import { Search } from "@mui/icons-material";
import CachedIcon from "@mui/icons-material/Cached";
import { useNavigate } from "react-router-dom";
import ImageCroper from "../components/Popup/ImageCroper";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
  useAdminExportsMutation,
  useDecorativeCreateMutation,
  useDecorativeEditMutation,
  useDecorativeListsMutation,
  useLazyDecorativeListQuery,
  useLazyDecorativeStatusQuery,
  useLazyDecorativeViewQuery,
} from "../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";

const DecorativeMethod = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [list, setList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageRow, setPageRow] = useState(10);
  const [page, setPage] = useState(1);
  const [pagenation, setPagination] = useState({});
  const [btn, setBtn] = useState(false);
  const [update, setUpdate] = useState({});

  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [nameErr, setNameErr] = useState(false);

  // RTK QUERY
  const [decorativeCreateApi] = useDecorativeCreateMutation();
  const [decorativeEditpi] = useDecorativeEditMutation();
  const [decorativeListsApi] = useDecorativeListsMutation();
  const [decorativeListApi] = useLazyDecorativeListQuery();
  const [decorativeStatusApi] = useLazyDecorativeStatusQuery();
  const [decorativeViewApi] = useLazyDecorativeViewQuery();
  const [exportsApi] = useAdminExportsMutation();


  const toggleShowPopup = (type) => {
    setShowPopup(!showPopup);
    if (type) setPopupType(type);
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  useEffect(() => {
    if (croperImage) {
      fetch(croperImage)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "File name", { type: "image/png" });
          setImage(file);
        });
    }
  }, [croperImage]);

  const getList = (event, row) => {
    let formdata = new FormData();
    let params = `?page=${page}`;

    setSearchValue(event);

    if (event?.length > 0) {
      formdata.append("search", event);
    }

    if (row) {
      setPageRow(row);
      formdata.append("rows", row);
    }

    decorativeListsApi({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.lists);
          setPagination(res?.pagination_meta);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const change = (id) => {
    decorativeStatusApi(id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          toast.success(res?.data?.message || res?.message);
          getList();
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  useEffect(() => {
    getList();
  }, [page]);

  const submitHandle = () => {
    if (name?.length == 0) {
      setNameErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("name", name);
      if (image?.name) {
        formdata.append("icon", image);
      }

      setBtn(true);
      dispatch(saveLoader(true));
      if (update?.id) {
        decorativeEditpi({ payload: formdata, id: update?.id })
          .unwrap()
          .then((res) => {
            setBtn(false);
            dispatch(saveLoader(false));
            toggleShowPopup();
            setName("");
            setImage("");
            setNameErr(false);
            setUpdate({});
            getList();
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });
      } else {
        decorativeCreateApi(formdata)
          .unwrap()
          .then((res) => {
            setBtn(false);
            dispatch(saveLoader(false));
            toggleShowPopup();
            setName("");
            setUpdate({});
            setImage("");
            setNameErr(false);
            getList();
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false));
            console.log("err", err);
          });
      }
    }
  };
     // EXPORT EXCEL
const getAdminExports = () => {
  let formdata = new FormData();
  formdata.append("type", 6);
  exportsApi(formdata)
    .unwrap()
    .then((res) => {
      if (res?.download_link) {
        const link = document.createElement("a");
        link.href = res?.download_link;
        link.setAttribute("download", "");
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch((err) => {
      console.log("err", err);
    });
};

  return (
    <>
      {showPopup && (
        <AddDecorativePopup
          toggleShowPopup={toggleShowPopup}
          popupType={popupType}
          toggleImagePopup={toggleImagePopup}
          name={name}
          setName={setName}
          image={image}
          nameErr={nameErr}
          btn={btn}
          submitHandle={submitHandle}
        />
      )}
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <div className="dashRightView p-5 home_section trans">
        <div className="d-flex ac-jb mt-2 flex-wrap">
          <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
            Decoration Method
          </p>
          <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
            <div className="d-flex">
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
                Show
              </p>
              <select
                className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary"
                onChange={(e) => getList(searchValue, e.target.value)}
                value={pageRow}
              >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
              </select>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                Entries
              </p>
            </div>
            <div className="d-flex flex-md-row flex-column">
              <div className="d-flex ac-jb flex-md-row flex-column">
                <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont me-3 ">
                  Total Count : {pagenation?.total}
                </p>
                <div className="pointerView d-flex my-1 me-3 mb-sm-2 mb-md-0" role={"button"} onClick={getAdminExports}>
                  <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
                  <p
                    className={`gray f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14`}
                  >
                    Export
                  </p>
                </div>
                <div className="d-flex  mb-2 mx-1 my-1 border-search">
                  <img
                    src={searchIcon}
                    alt="search"
                    className="searchiConImg"
                  />
                  <input
                    className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
                    placeholder="Search"
                    onChange={(e) => getList(e.target.value, pageRow)}
                    value={searchValue}
                  />
                </div>
                <button className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-3 rounded">
                  <CachedIcon />
                </button>
                <button
                  onClick={() => toggleShowPopup("add")}
                  className="cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                >
                  + Add Decoration Method
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5" style={{ width: "100%", overflow: "auto" }}>
          <table className="table-design-new" style={{ width: "100%" }}>
            <thead>
              <tr className="border-bottom w-100">
                <th>
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    S.No
                  </p>
                </th>
                <th>
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Icon
                  </p>
                </th>
                <th>
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Name
                  </p>
                </th>
                <th>
                  {" "}
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Status
                  </p>
                </th>
                <th>
                  {" "}
                  <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                    Action
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item, index) => {
                return (
                  <tr>
                    <td>
                      <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                        {index + 1}
                      </p>
                    </td>
                    <td>
                      <div className="text-center">
                        <img
                          src={item?.icon_url ? item?.icon_url : noLogo}
                          className="imgDashboard1 object-fit-contain"
                          alt="product"
                        />
                      </div>
                    </td>
                    <td>
                      <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                        {item?.name}
                      </p>
                    </td>
                    <td>
                      <div
                        className="d-flex ac-jc"
                        onClick={() => change(item?.id)}
                      >
                        {item?.status === 1 ? (
                          <div>
                            <img src={toggleOn} className="toggleOnDes" />
                          </div>
                        ) : (
                          <div>
                            <img src={toggleOff} className="toggleOnDes" />
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      <div
                        onClick={() =>
                          navigate("/decorative-method-edit", {
                            state: { data: item, type: "edit" },
                          })
                        }
                        className="ms-2 p-2 bg-white rounded-2 d-flex justify-content-center"
                      >
                        <img src={editIcon} className="viewDes" alt="icon" />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <button
            className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
              page == 1 && "opacity-50"
            }`}
            onClick={() => setPage(page - 1)}
            disabled={page == 1 ? true : false}
          >
            Previous
          </button>
          <button
            className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
              page == pagenation?.last_page && "opacity-50"
            }`}
            onClick={() => setPage(page + 1)}
            disabled={page == pagenation?.last_page ? true : false}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default DecorativeMethod;
